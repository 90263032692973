import React from 'react';
import get from 'lodash/get';

function Rate({ className, value = {}, text = '', onClick = () => {} }) {
  return (
    <div
      className={'d-flex align-items-center cursor-pointer ' + className}
      onClick={() => {
        onClick();
      }}>
      <div>
        <img
          src={get(value, 'provider.logo')}
          alt=""
          width={44}
          style={{ maxHeight: '44px' }}
          className=" mr-2"
        />
      </div>
      <div>
        <div className="font-size-14 font-weight-light">Best rate via</div>
        <div>
          <span className="font-size-16 font-weight-medium text-capitalize mr-2">
            {get(value, 'provider.provider')}
          </span>
          <i className="fas fa-chevron-down font-size-12"></i>
        </div>
      </div>
      <div className="ml-auto text-right">
        <div className="font-size-16 text-uppercase">{text}</div>
        <div className="font-weight-light">Includes all fees</div>
      </div>
    </div>
  );
}

export default Rate;
