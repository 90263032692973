import React from 'react';
import SimpleBar from 'simplebar-react';
import { stringify } from 'query-string';
import { Link, useHistory } from 'react-router-dom';
import get from 'lodash/get';

function ProvidersTokens({ providers = [] }) {
  let history = useHistory();
  return (
    <div className='card p-4'>
      <div className='row align-items-stretch'>
        {providers
          .filter((provider) => get(provider, 'crypto_assets.length', 0))
          .map((provider, i) => (
            <div key={i} className='col-12 col-md-6 col-xl-4 col-xxl-3 pb-3'>
              <div className='border rounded p-3 h-100'>
                <div
                  className='d-flex align-items-center border-bottom pb-3 mb-2'
                  style={{ minHeight: 52 }}>
                  {!!provider.logo && (
                    <div className='pr-2'>
                      <img
                        src={provider.logo}
                        alt={provider.provider_name}
                        width={40}
                        style={{ maxHeight: '40px' }}
                        className='rounded-circle'
                      />
                    </div>
                  )}
                  <div className='flex-fill font-size-18 font-weight-medium text-truncate'>
                    {provider.provider_name} Summary
                  </div>
                </div>
                <SimpleBar style={{ maxHeight: '200px' }} className='pt-1'>
                  {get(provider, 'crypto_assets', []).map((asset, i) => (
                    <div key={i} className={`d-flex align-items-center mb-2 pb-1`}>
                      <Link
                        onClick={() => {
                          // AddOnRampTrial({ crypto_id: asset.coin_id })
                          //   .then((res) => {
                          history.push(
                            `/crypto?${stringify({
                              crypto_id: (asset.cryptoExist && asset.coin_id) || null,
                              coin_gecko_id: asset.coin_gecko_id,
                              coin_name: asset.news_ticker
                            })}`
                          );
                          // })
                          // .catch((err) => {});
                        }}
                        className='pr-2 flex-fill font-size-16 text-truncate'>
                        {asset.crypto_name}
                      </Link>
                      <div className=''>
                        <img
                          src={asset.logo}
                          alt={asset.crypto_symbol}
                          width={24}
                          style={{ maxHeight: '24px' }}
                          className='rounded-circle'
                        />
                      </div>
                    </div>
                  ))}
                </SimpleBar>
              </div>
            </div>
          ))}
      </div>
    </div>
  );
}

export default ProvidersTokens;
