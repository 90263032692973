import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { GetAllApplications } from '../../services/Business/Application';
import { Card, Row, Col } from 'reactstrap';
import { Paginator } from 'primereact/paginator';
import GetApplication from './GetApplication';

const LoadingIcon = () => (
  <div className='h-100 d-flex align-items-center justify-content-center py-4'>
    <i className='fas fa-circle-notch fa-spin text-muted' style={{ fontSize: 48 }}></i>
  </div>
);

function ApplicationsList({ history }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [allApplications, setAllApplications] = useState([]);
  const [activeId, setActiveId] = useState(null);
  const [order, setOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [isTypeSectionCollapsed, setIsTypeSectionCollapsed] = useState(false);
  const [isOrderSectionCollapsed, setIsOrderSectionCollapsed] = useState(false);
  const [suggestions, setSuggestions] = useState([
    { id: 'EXCHANGE', name: 'Exchange', checked: false },
    { id: 'ONRAMP', name: 'Onramp', checked: false }
  ]);
  const [search, setSearch] = useState(null);
  const [applicationDelete, setApplicationDelete] = useState(false);

  const orderOptions = [
    { id: 'status_asc', name: 'ASC' },
    { id: 'status_desc', name: 'DESC' }
  ];

  const handleRadioChange = (orderId) => {
    setOrder(orderId);
  };
  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
  };

  const toggleTierSection = () => {
    setIsTypeSectionCollapsed(!isTypeSectionCollapsed);
  };

  const toggleOrderSection = () => {
    setIsOrderSectionCollapsed(!isOrderSectionCollapsed);
  };
  useEffect(() => {
    const type = suggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);

    GetAllApplications({ page, order, search, type, limit: 10 })
      .then((res) => {
        const newArray = get(res, 'data.data.data', []);
        newArray.forEach((responseData) => {
          if (responseData.app_fields_per === 0) {
            const percentage =
              (responseData.exchange_application.percentage_complete / 42) * 100;
            responseData.completepercentage = Math.round(percentage);
          } else {
            responseData.completepercentage = responseData.app_fields_per;
          }
        });
        setApplicationDelete(false);
        setTotalCount(res.data.data.count);
        setAllApplications(newArray);
        setIsLoaded(true);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, [page, search, order, suggestions, applicationDelete]);

  const onClick = (id) => {
    if (activeId === id) {
      return setActiveId(null);
    }

    setActiveId(id);
  };

  const handleCheckboxChange = (id) => {
    setPage(1);
    const updatedSuggestions = suggestions.map((suggestion) => {
      if (suggestion.id === id) {
        return { ...suggestion, checked: !suggestion.checked };
      }
      return suggestion;
    });

    setSuggestions(updatedSuggestions);
  };
  const searchFunction = (search) => {
    setSearch(search);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row>
          <Col lg={2} md={2} sm={12}>
            <div className='card'>
              <div className='px-3 pt-3'>
                <span className='font-size-16'>
                  <i className='mdi mdi-filter'></i> Filters
                </span>
                <br />
                <hr />
                <span className='font-size-14 cursor-pointer' onClick={toggleTierSection}>
                  <i
                    className={`mdi mdi-chevron-${
                      isTypeSectionCollapsed === true ? 'right' : 'down'
                    }`}
                  />{' '}
                  Select Application
                </span>
                <div
                  className={`filter-section ${
                    isTypeSectionCollapsed ? 'collapsed' : ''
                  }`}>
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className='d-flex align-items-center ml-3'
                      style={{ wordBreak: 'break-all' }}>
                      <input
                        type='checkbox'
                        checked={suggestion.checked}
                        style={{ height: 16, width: 16 }}
                        onChange={() => {
                          setPage(1);
                          handleCheckboxChange(suggestion.id);
                        }}
                      />
                      <span className='font-size-12 mr-2 ml-1'>{suggestion.name}</span>
                    </div>
                  ))}
                </div>
                <hr />
                <span
                  className='font-size-14 cursor-pointer'
                  onClick={toggleOrderSection}>
                  <i
                    className={`mdi mdi-chevron-${
                      isOrderSectionCollapsed === true ? 'right' : 'down'
                    }`}
                  />{' '}
                  Completion Order
                </span>
                <div
                  className={`mb-3 filter-section ${
                    isOrderSectionCollapsed ? 'collapsed' : ''
                  }`}>
                  {orderOptions.map((option) => (
                    <div
                      key={option.id}
                      className='d-flex align-items-center ml-3'
                      style={{ wordBreak: 'break-all' }}>
                      <input
                        type='radio'
                        name='order'
                        value={option.id}
                        checked={order === option.id}
                        style={{ height: 16, width: 16 }}
                        onChange={() => handleRadioChange(option.id)}
                      />
                      <span className='font-size-12 mr-2 ml-1'>{option.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={10} md={10} sm={12}>
            <Card>
              <div className='col-12 col-sm-auto font-size-24 font-weight-medium pb-2 pb-sm-0'>
                <div className='p-4 d-flex justify-content-between'>
                  <span className='text-black'>Applications</span>
                </div>

                <Row className='pl-2 pr-2'>
                  <Col lg={12} md={12} sm={12} className='mb-2 pl-4 pr-4'>
                    <input
                      type='text'
                      className='form-control form-control-search'
                      placeholder='Search by Project Name, Ticker Name, Token Address'
                      onKeyPress={(event) => {
                        if (event.key === 'Enter') {
                          searchFunction(event.target.value);
                        }
                      }}
                      autoFocus={true}
                    />
                  </Col>
                </Row>
                <div>
                  {!isLoaded ? (
                    <LoadingIcon />
                  ) : allApplications && allApplications?.length > 0 ? (
                    <>
                      {allApplications &&
                        allApplications?.length > 0 &&
                        allApplications.map((application, index) => (
                          <GetApplication
                            application={application}
                            index={index}
                            activeId={activeId}
                            onClick={() => onClick(index)}
                            history={history}
                            setFirst={setFirst}
                            setApplicationDelete={setApplicationDelete}
                          />
                        ))}
                    </>
                  ) : (
                    <span className='d-flex justify-content-center mt-4'>
                      No data found.
                    </span>
                  )}
                </div>
                <Paginator
                  rows={10}
                  totalRecords={totalCount}
                  first={first}
                  onPageChange={(e) => onPageChange(e)}
                />
              </div>
            </Card>
          </Col>
        </Row>
      </div>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default ApplicationsList;
