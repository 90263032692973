import React, { useState } from 'react';
import ResponseList from './ResponsePage';
import ViewOnRampApplication from './ViewOnRampApplication';
import { Col, Nav, NavItem, Row, NavLink, TabPane, TabContent, Card } from 'reactstrap';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';
import { useHistory } from 'react-router-dom';
import ChangeRequests from './ChangeRequests';
import { GetApplicationResponse } from '../../../services/Onramp/Application';
import { get } from 'lodash';
import { NotificationToastContainer, notifyError } from '../../../Custom/notification';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import ResponseFilters from './ResponseFilters';

function ViewApplication(props) {
  const [applicationId, setApplicationId] = useState('application_id=');
  const [page, setPage] = useState('application');
  const [responsePage, setResponsePage] = useState(1);
  const [addQuestions, setAddQuestions] = useState([]);
  const [loading, setLoading] = useState(true);
  const [order, setOrder] = useState(null);
  const [first, setFirst] = useState(0);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [selectedStatus, setSelectedStatusValues] = useState('');
  const [modal, setModal] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [statusChangeModal, setStatusChangeModal] = useState(false);
  const [textareaValues, setTextareaValues] = useState({});
  const [totalCount, setTotalCount] = useState(0);
  const [allResponses, setAllResponses] = useState([]);
  const [beneficialusersValues, setBeneficialusersValues] = useState([]);
  const [clickedLabels, setClickedLabels] = useState([]);

  const handleModal = () => {
    setModal(!modal);
  };

  const handleChangeStatus = (values) => {
    setSelectedStatusValues(values);
    setStatusChangeModal(!statusChangeModal);
    handleModal();
  };

  const history = useHistory();
  const currentComponent = () => {
    switch (page) {
      case 'application':
        return <ViewOnRampApplication props={props} />;

      case 'response':
        return <ResponseList props={props} />;
      default:
    }
  };
  const [statusSuggestions, setStatusSuggestions] = useState([
    {
      id: 'REQUEST_ACTION',
      name: 'Action',
      icon: 'fas fa-unlock',
      color: 'black',
      checked: false
    },
    {
      id: 'ACCEPT',
      name: 'Accept',
      icon: 'fas fa-check-circle',
      color: 'green',
      checked: false
    },
    { id: 'KYB', name: 'KYB', icon: 'fas fa-lock', color: 'black', checked: false },
    {
      id: 'QUOTE',
      name: 'Quote',
      icon: 'fas fa-quote-left',
      color: 'black',
      checked: false
    },
    {
      id: 'PAID',
      name: 'Paid',
      icon: 'fas fa-thumbs-up',
      color: 'green',
      checked: false
    },
    {
      id: 'ADD_QUESTIONS',
      name: 'Add Question',
      icon: 'fas fa-question',
      color: 'black',
      checked: false
    }
  ]);

  const GetApplicationResponseFunc = (applicationId) => {
    setLoading(true);

    const status = statusSuggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);
    GetApplicationResponse({ applicationId, order, status })
      .then((res) => {
        const responses = get(res, 'data.data.data.applicationResponse', []);
        const count = get(res, 'data.data.data.count', []);
        const slicedData = get(res, 'data.data.data.applicationResponse', []).slice(
          first,
          first + 10
        );
        setCurrentPageData(slicedData);
        setTotalCount(count);
        setLoading(false);
        setAllResponses(responses);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row className='px-2'>
          <Col
            lg={page === 'application' ? 8 : page === 'response' ? 9 : 10}
            md={page === 'application' ? 8 : page === 'response' ? 9 : 10}
            sm={12}>
            <Card>
              <div className='pt-3 pl-3'>
                <button
                  type='button'
                  className='btn btn-light p-1 mb-2'
                  style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
                  onClick={() => history.push('/applications')}>
                  <ArrowLeftIcon width={18} height={18} />
                </button>
              </div>
              <Nav tabs className='nav-tabs-custom nav-justified'>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'application' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('application');
                    }}>
                    <span className='d-none d-sm-block'>Application Page</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'response' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('response');
                    }}>
                    <span className='d-none d-sm-block'>Responses</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={page}>
                <TabPane tabId='application'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <ViewOnRampApplication
                        props={props}
                        setClickedLabels={setClickedLabels}
                        setPage={setPage}
                        setAddQuestions={setAddQuestions}
                        setApplicationId={setApplicationId}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='response'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <ResponseList
                        props={props}
                        allResponses={allResponses}
                        loading={loading}
                        totalCount={totalCount}
                        GetApplicationResponseFunc={GetApplicationResponseFunc}
                        statusSuggestions={statusSuggestions}
                        responsePage={responsePage}
                        updatePage={updatePage}
                        order={order}
                        setOrder={setOrder}
                        first={first}
                        setFirst={setFirst}
                        setResponsePage={setResponsePage}
                        setCurrentPageData={setCurrentPageData}
                        currentPageData={currentPageData}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
          {page === 'application' && (
            <Col lg={4} md={4} sm={12}>
              <ChangeRequests
                setAddQuestions={setAddQuestions}
                applicationId={applicationId}
                selectedStatus={selectedStatus}
                modal={modal}
                handleModal={handleModal}
                setUpdatePage={setUpdatePage}
                updatePage={updatePage}
                handleChangeStatus={handleChangeStatus}
                setTextareaValues={setTextareaValues}
                clickedLabels={clickedLabels}
                textareaValues={textareaValues}
                setClickedLabels={setClickedLabels}
                beneficialusersValues={beneficialusersValues}
                GetApplicationResponseFunc={GetApplicationResponseFunc}
                setPage={setPage}
              />
            </Col>
          )}
          {page === 'response' && (
            <Col lg={3} md={3} sm={6}>
              <ResponseFilters
                statusSuggestions={statusSuggestions}
                setStatusSuggestions={setStatusSuggestions}
                setResponsePage={setResponsePage}
                setUpdatePage={setUpdatePage}
                updatePage={updatePage}
                applicationId={applicationId}
                GetApplicationResponseFunc={GetApplicationResponseFunc}
              />
            </Col>
          )}
        </Row>
      </div>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default ViewApplication;
