import React, { useEffect, useState } from 'react';

import { Row, Col, Container, FormGroup, Button, Label, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import AuthInput from '../../Custom/AuthInput';
import { constants as PLACE_CONST } from '../../constants/placeholder';
import { constants as CONST } from '../../constants/constant';
import { constants as ROUTE_CONST } from '../../constants/routes';
import { constants as BUTTON_CONST } from '../../constants/button';
import { constants as ERROR_CONST } from '../../constants/errorMessage';
import { constants as SUCCESS_CONST } from '../../constants/successMessage';
import { notificationMessageFunction } from '../../constants/notificationConst';
import * as Yup from 'yup';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../Custom/notification';
import { RampListAPI, userAuthRegister, ExchangeListAPI } from '../../services/Auth';
import Select from 'react-select';
import Cookies from 'js-cookie';
import RightLogo from './RightLogo';

function Register(props) {
  const [accountName, setAccountName] = useState(null);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let referral = urlParams.get('referral');
    setAccountName(urlParams.get('account_name'));
    if (referral) {
      Cookies.set('referral_code', referral);
      localStorage.setItem('referral_code', referral);
    }
  }, []);
  const RegisterSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERROR_CONST.VALID_EMAIL)
      .required(ERROR_CONST.EMAIL_REQUIRED),
    password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
    confirm_password: Yup.string()
      .required(ERROR_CONST.CONFIRM_PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
    account_type: Yup.string().required(ERROR_CONST.SELECT_ACCOUNT_TYPE),
    on_ramp_name: Yup.string().when('account_type', {
      is: '1',
      then: Yup.string().required(ERROR_CONST.ON_RAMP_NAME)
    }),
    on_ramp_services: Yup.array().when('account_type', {
      is: (values) => values === '1' || values === '4',
      then: Yup.array().min(1, ERROR_CONST.PERMITTED_SERVICES)
    }),
    company_name: Yup.string().when('account_type', {
      is: '2',
      then: Yup.string().required(ERROR_CONST.COMPANY_NAME)
    }),
    exchange_name: Yup.string().when('account_type', {
      is: '4',
      then: Yup.string().required(ERROR_CONST.EXCHANGE_NAME)
    })
  });

  const [rampList, setRampList] = useState([]);
  const [exchangeList, setExchangeList] = useState([]);
  const [selectedAccountType, setSelectedAccountType] = useState(2);

  useEffect(() => {
    setSelectedAccountType(
      accountName === 'business'
        ? 2
        : accountName === 'exchange'
        ? 4
        : accountName === 'channel_partner'
        ? 5
        : 2
    );
  }, [accountName]);

  const handleSubmit = (values) => {
    const data = {
      email: values.email.toLowerCase(),
      password: values.password,
      confirm_password: values.confirm_password,
      account_type: Number(values.account_type),
      company_name: values.company_name,
      on_ramp_name: values.on_ramp_name,
      exchange_name: values.exchange_name,
      on_ramp_services: values.on_ramp_services,
      business_list_token: values.business_list_token,
      referral_code: (Cookies.get() && Cookies.get().referral_code) || '',
      is_channel_partner: values.is_channel_partner
    };

    userAuthRegister(data)
      .then((res) => {
        setTimeout(() => {
          notifySuccess(SUCCESS_CONST.REGISTER_SUCCESSFULLY);
        }, 100);
        localStorage.setItem(CONST.EMAIL, data.email);
        props.history.push({
          pathname: ROUTE_CONST.VERIFY_ACCOUNT
        });
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const rampListApiFunction = () => {
    RampListAPI()
      .then((res) => {
        setRampList(res.data.data.data);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  const exchangeListApiFunction = () => {
    ExchangeListAPI()
      .then((res) => {
        const newData = res.data.data.data.map((item) => {
          return { label: item.exchange_name, value: item.exchange_name };
        });
        setExchangeList(newData);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    rampListApiFunction();
    exchangeListApiFunction();
  }, []);

  return (
    <React.Fragment>
      <div className='home-btn d-none d-sm-block'>
        <Link to='/'>
          <i className='mdi mdi-home-variant h2 text-white'></i>
        </Link>
      </div>

      <div>
        <Container fluid className='p-0'>
          <Row className='no-gutters'>
            <Col lg={4}>
              <div className='authentication-page-content p-4 d-flex align-items-top min-vh-100'>
                <div className='w-100'>
                  <Row className='justify-content-center'>
                    <Col lg={9}>
                      <div>
                        <div className='text-left'>
                          <h4 className='font-size-32 mt-4 text-black'>
                            {CONST.WELCOME_RAMPNALYSIS}
                          </h4>
                          <p className='text-muted'>
                            <span className='text-black'>
                              {CONST.ENTER_YOUR_EMAIL_PASSWORD}
                            </span>
                          </p>
                        </div>

                        <div className='p-2 mt-5'>
                          <Formik
                            enableReinitialize
                            initialValues={{
                              email: '',
                              password: '',
                              confirm_password: '',
                              company_name: '',
                              on_ramp_name: '',
                              exchange_name: '',
                              account_type:
                                selectedAccountType === 5
                                  ? '2'
                                  : selectedAccountType + '',
                              on_ramp_services: [],
                              business_list_token: true,
                              is_channel_partner: false
                            }}
                            validationSchema={RegisterSchema}
                            onSubmit={handleSubmit}>
                            {({
                              errors,
                              touched,
                              values,
                              handleChange,
                              setFieldValue
                            }) => (
                              <Form>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='email' className='text-black'>
                                      {CONST.EMAIL_ADDRESS}
                                    </Label>
                                    <AuthInput
                                      type='text'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_EMAIL}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      name='email'
                                    />
                                  </FormGroup>
                                </div>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='account_type' className='text-black'>
                                      {CONST.SELECT_ACCOUNT_TYPE}
                                    </Label>

                                    <Row>
                                      {(accountName === null ||
                                        accountName === 'business') && (
                                        <Col className='mt-2'>
                                          <div
                                            className={`d-flex justify-content-left cursor-pointer`}
                                            onClick={() => {
                                              setFieldValue('account_type', 2);
                                              setSelectedAccountType(2);
                                            }}>
                                            <div
                                              id='create-onramp-app '
                                              className={`account-type-frame new-user-account-type ${
                                                selectedAccountType === 2
                                                  ? 'selected-border shadow-effect'
                                                  : ''
                                              }`}>
                                              <div className='d-flex justify-content-center'>
                                                <lord-icon
                                                  src='https://cdn.lordicon.com/pimvysaa.json'
                                                  trigger='hover'
                                                  target='#create-onramp-app'
                                                  colors='outline:#121331,primary:#3a3347,secondary:#ee8f66,tertiary:#ffc738,quaternary:#ebe6ef'
                                                  style={{
                                                    width: '70px',
                                                    height: '70px'
                                                  }}></lord-icon>
                                              </div>
                                              <p className='account-type-name'>
                                                Business
                                              </p>
                                              <p className='hidden-text-account'>
                                                You're a business looking for on-ramp or
                                                listing
                                              </p>
                                            </div>
                                          </div>
                                        </Col>
                                      )}
                                      {(accountName === null ||
                                        accountName === 'exchange') && (
                                        <Col className='mt-2'>
                                          <div
                                            className={`d-flex justify-content-left cursor-pointer`}
                                            onClick={() => {
                                              setFieldValue('account_type', 4);
                                              setSelectedAccountType(4);

                                              setFieldValue('on_ramp_services', [
                                                'Exchange',
                                                'CentralizedTokenListing'
                                              ]);
                                            }}>
                                            <div
                                              id='create-onramp-app'
                                              className={`account-type-frame new-user-account-type ${
                                                selectedAccountType === 4
                                                  ? 'selected-border shadow-effect'
                                                  : ''
                                              }`}>
                                              <div className='d-flex justify-content-center'>
                                                <lord-icon
                                                  src='https://cdn.lordicon.com/pqxdilfs.json'
                                                  trigger='hover'
                                                  target='#create-onramp-app'
                                                  colors='outline:#121331,primary:#3a3347,secondary:#ee8f66,tertiary:#ffc738,quaternary:#ebe6ef'
                                                  style={{
                                                    width: '70px',
                                                    height: '70px'
                                                  }}></lord-icon>
                                              </div>
                                              <p className='account-type-name'>
                                                Exchange
                                              </p>
                                              <p className='hidden-text-account'>
                                                You're an exchange looking to streamline
                                                your listing process
                                              </p>
                                            </div>
                                          </div>
                                        </Col>
                                      )}
                                      {(accountName === null ||
                                        accountName === 'channel_partner') && (
                                        <Col className='mt-2'>
                                          <div
                                            className={`d-flex justify-content-left cursor-pointer`}
                                            onClick={() => {
                                              setFieldValue('account_type', 2);
                                              setFieldValue('business_list_token', true);
                                              setFieldValue('is_channel_partner', true);
                                              setSelectedAccountType(5);
                                            }}>
                                            <div
                                              id='create-onramp-app '
                                              className={`account-type-frame new-user-account-type ${
                                                selectedAccountType === 5
                                                  ? 'selected-border shadow-effect'
                                                  : ''
                                              }`}>
                                              <div className='d-flex justify-content-center'>
                                                <lord-icon
                                                  src='https://cdn.lordicon.com/eodavnff.json'
                                                  trigger='hover'
                                                  target='#create-onramp-app'
                                                  colors='outline:#121331,primary:#3a3347,secondary:#ee8f66,tertiary:#ffc738,quaternary:#ebe6ef'
                                                  style={{
                                                    width: '70px',
                                                    height: '70px'
                                                  }}></lord-icon>
                                              </div>
                                              <p className='account-type-name'>
                                                Channel Partners
                                              </p>
                                              <p className='hidden-text-account'>
                                                You're a channel partner looking to
                                                provide listing and marketing services
                                              </p>
                                            </div>
                                          </div>
                                        </Col>
                                      )}
                                    </Row>

                                    {errors.account_type && touched.account_type && (
                                      <div
                                        style={{ fontSize: 14 }}
                                        className='text-left mt-1 text-danger'>
                                        {errors.account_type}
                                      </div>
                                    )}
                                  </FormGroup>
                                </div>

                                {Number(values.account_type) === 1 && (
                                  <div className='mb-3'>
                                    <FormGroup>
                                      <Label
                                        htmlFor='on_ramp_name'
                                        className='text-black'>
                                        {CONST.ON_RAMP_NAME}
                                      </Label>

                                      <Input
                                        type='select'
                                        name='on_ramp_name'
                                        className={
                                          errors.on_ramp_name
                                            ? 'border-danger'
                                            : 'border-dark'
                                        }
                                        value={values.on_ramp_name}
                                        onChange={(e) => handleChange(e)}>
                                        {rampList &&
                                          rampList.map((ramp, i) => {
                                            return (
                                              <option key={i} value={ramp.on_ramp_name}>
                                                {ramp.on_ramp_name}
                                              </option>
                                            );
                                          })}
                                      </Input>
                                    </FormGroup>
                                  </div>
                                )}

                                {Number(values.account_type) === 2 && (
                                  <div className='mb-3'>
                                    <FormGroup>
                                      <Label
                                        htmlFor='company_name'
                                        className='text-black'>
                                        {CONST.COMPANY_NAME}
                                      </Label>
                                      <AuthInput
                                        type='text'
                                        values={values}
                                        placeholder={PLACE_CONST.ENTER_COMPANY_NAME}
                                        handleChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        name='company_name'
                                      />
                                    </FormGroup>
                                  </div>
                                )}
                                {Number(values.account_type) === 4 && (
                                  <div className='mb-3'>
                                    <FormGroup>
                                      <Label
                                        htmlFor='exchange_name'
                                        className='text-black'>
                                        {CONST.EXCHANGE_NAME}
                                      </Label>
                                      {/* <AuthInput
                                        type="text"
                                        values={values}
                                        placeholder={PLACE_CONST.ENTER_EXCHANGE_NAME}
                                        handleChange={(e) =>
                                          setSearchQuery(e.target.value)
                                        }
                                        errors={errors}
                                        touched={touched}
                                        name="exchange_name"
                                      /> */}
                                      <Select
                                        className='select-input border-dark'
                                        style={{
                                          paddingRight: '30px',
                                          borderRadius: '8px'
                                        }}
                                        placeholder='Select Exchange'
                                        isClearable
                                        options={exchangeList}
                                        value={exchangeList.find(
                                          (c) => c.value === values
                                        )}
                                        onChange={(val) => {
                                          if (val)
                                            setFieldValue('exchange_name', val.value);
                                        }}
                                        name='exchange_name'
                                      />
                                      {/* <ul>
                                        {filteredExchanges
                                          .slice(0, 10)
                                          .map((exchange) => (
                                            <li key={exchange._id}>
                                              {exchange.exchange_name}
                                            </li>
                                          ))}
                                      </ul> */}
                                    </FormGroup>
                                  </div>
                                )}
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='password' className='text-black'>
                                      {CONST.PASSWORD}
                                    </Label>
                                    <AuthInput
                                      type='password'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_PASSWORD}
                                      handleChange={handleChange}
                                      isPassword
                                      errors={errors}
                                      touched={touched}
                                      name='password'
                                    />
                                  </FormGroup>
                                </div>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label
                                      htmlFor='confirm_password'
                                      className='text-black'>
                                      {CONST.CONFIRM_PASSWORD}
                                    </Label>
                                    <AuthInput
                                      type='password'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_CONFIRM_PASSWORD}
                                      handleChange={handleChange}
                                      isPassword
                                      errors={errors}
                                      touched={touched}
                                      name='confirm_password'
                                    />
                                  </FormGroup>
                                </div>
                                {/* <div className='mb-3'>
                                  {Number(values.account_type) === 2 && (
                                    <>
                                      <Input
                                        name='business_list_token'
                                        type='checkbox'
                                        value={values.business_list_token}
                                        className='form-check-input ml-1'
                                        onChange={handleChange}
                                        checked={values.business_list_token}
                                      />

                                      <label className='ml-4'>
                                        Do you want to get your Token listed?
                                      </label>
                                    </>
                                  )}
                                </div> */}
                                <div className='mt-3 text-center w-100 waves-effect waves-light'>
                                  <Button
                                    type='submit'
                                    name='btn'
                                    className={`btn btn-auth`}
                                    onSubmit={(values) => handleSubmit(values)}>
                                    {BUTTON_CONST.SIGN_UP}
                                  </Button>
                                </div>
                                <div className='mt-4 text-left'>
                                  <span className='text-black'>
                                    {CONST.ALREADY_HAS_AN_ACCOUNT}{' '}
                                    <Link to='/login' className='text-muted'>
                                      <span className='text-black'>
                                        <u>{CONST.CLICK_HERE}</u>
                                      </span>
                                    </Link>
                                  </span>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <RightLogo />
            </Col>
          </Row>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default Register;
