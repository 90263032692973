import moment from 'moment';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { stringify } from 'query-string';
import PayConfirm from './PayQuoteModal';
import RequestAction from './RequestAction';
import AddQuestionAnswer from './add_questions_answer';

function GetResponse({
  application,
  index,
  page,
  GetApplicationResponseFunc,
  applicationId
}) {
  const [subAppId, setSubApplication] = useState('');
  const [responseId, setResponseId] = useState('');
  const [modal, setModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [answerModal, setAnswerModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };

  const actionToggle = () => {
    setRequestModal(!requestModal);
  };

  const answerToggle = () => {
    setAnswerModal(!answerModal);
  };
  const getSeverity = (status) => {
    const statusToSeverity = {
      REJECT: 'danger',
      ACCEPT: 'success',
      ADD_QUESTIONS: 'info',
      negotiation: 'warning',
      COMPLETE: 'success',
      QUOTE: 'secondary',
      renewal: 'secondary'
    };

    return statusToSeverity[status] || 'secondary';
  };
  const statusSuggestions = [
    { id: 'REQUEST_ACTION', name: 'Action' },
    { id: 'ACCEPT', name: 'Accept' },
    { id: 'REJECT', name: 'Reject' },
    { id: 'KYB', name: 'KYB' },
    { id: 'QUOTE', name: 'Quote' },
    { id: 'PAID', name: 'Paid' },
    { id: 'ADD_QUESTIONS', name: 'Add Question' }
  ];
  const actionBodyTemplate = (application) => {
    const applicationResponse = application.applicationResponse;

    if (applicationResponse.field) {
      const fieldKeys = Object.keys(applicationResponse?.field);
      const completedFieldKeys = applicationResponse.completed_field;

      if (fieldKeys.length === completedFieldKeys?.length) {
        return <div>Complete Action</div>;
      } else {
        return (
          <Link
            to={{
              pathname: '/sub-application/edit',
              search: `?${stringify({
                sub_application_id: applicationResponse.onramp_sub_application_id
              })}`,
              state: applicationResponse
            }}>
            <button
              className='btn btn-status-change font-size-12 font-weight-bold'
              style={{ width: 150 }}>
              Edit Application
            </button>
          </Link>
        );
      }
    } else if (applicationResponse.status === 'ADD_QUESTIONS') {
      return applicationResponse.response_complete ? (
        <div>Complete Action</div>
      ) : (
        <div
          onClick={() => {
            answerToggle();
            setSubApplication(application._id);
            setResponseId(applicationResponse._id);
          }}
          className='btn btn-status-change font-size-12 font-weight-bold'>
          Add Answer
        </div>
      );
    } else if (
      applicationResponse.status === 'QUOTE' &&
      applicationResponse.quote_price
    ) {
      if (applicationResponse.quote_price.currency) {
        return (
          <div
            onClick={() => {
              toggle();
              setSubApplication(application._id);
            }}
            className='btn btn-status-change font-size-12 font-weight-bold'>
            Pay ${applicationResponse.quote_price.amount}
          </div>
        );
      } else if (applicationResponse.quote_price.crypto_currency) {
        return (
          <div
            onClick={() => {
              setSubApplication(application._id);
              const externalURL = applicationResponse.payments_txs[0]?.invoiceLinks?.pay;
              const newTab = window.open(externalURL, '_blank');
              newTab.focus();
            }}
            className='btn btn-status-change font-size-12 font-weight-bold'>
            Pay ${applicationResponse.quote_price.amount} in $
            {applicationResponse.quote_price.crypto_currency}
          </div>
        );
      }
    } else if (
      applicationResponse.status === 'REQUEST_ACTION' &&
      !applicationResponse.response_complete
    ) {
      return (
        <div
          onClick={() => {
            actionToggle();
            setResponseId(applicationResponse._id);
          }}
          className='btn font-size-12 font-weight-bold'>
          Complete Request Action
        </div>
      );
    } else {
      return 'Action Completed';
    }
  };

  const ProfileIcon = ({ iconUrl }) => (
    <div style={{ padding: '5px' }}>
      {iconUrl && iconUrl.length > 0 ? (
        <img
          src={iconUrl}
          style={{ borderRadius: '50%' }}
          width={40}
          height={40}
          alt='user'
        />
      ) : (
        <i
          className='fa fa-user'
          style={{
            borderRadius: '50%',
            border: '1px solid #f0f0f0',
            width: '40px',
            height: '40px',
            background: '#f0f0f0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}></i>
      )}
    </div>
  );
  return (
    <>
      <td className='pl-4'>{index + 1 + (page - 1) * 10}</td>
      <td className='text-center'>
        <div>
          <ProfileIcon
            iconUrl={
              application &&
              application.applicationResponse &&
              application.applicationResponse.logo_url
                ? application.applicationResponse.logo_url
                : ''
            }
          />
          <span>
            {application.applicationResponse &&
            application.applicationResponse.on_ramp_name
              ? application.applicationResponse.on_ramp_name
              : ''}
          </span>
        </div>
      </td>
      <td className='text-left'>
        {application &&
        application.applicationResponse.field &&
        Object.keys(application.applicationResponse.field).length > 0 ? (
          Object.keys(application.applicationResponse.field).map((value, index) => (
            <div
              className={
                application.applicationResponse.completed_field.includes(value)
                  ? 'text-success'
                  : 'text-warning'
              }
              key={index}>
              {index + 1}. {value}
            </div>
          ))
        ) : (
          <div>No fields available</div>
        )}
      </td>
      <td className='text-left'>
        {application &&
        application.applicationResponse &&
        application.applicationResponse.field &&
        Object.keys(application.applicationResponse.field).length > 0 ? (
          Object.entries(application.applicationResponse.field).map(([key, value]) => {
            if (value.length > 0) {
              const truncatedValue =
                String(value).length > 20
                  ? `${String(value).substring(0, 20)}...`
                  : String(value);
              return (
                <div key={key} data-tip={value}>
                  &#8226; {truncatedValue}
                </div>
              );
            } else {
              return <div key={key} data-tip={value}></div>;
            }
          })
        ) : application.applicationResponse && application.applicationResponse.notes ? (
          String(application.applicationResponse.notes).length > 20 ? (
            `${String(application.applicationResponse.notes).substring(0, 20)}...`
          ) : (
            String(application.applicationResponse.notes)
          )
        ) : (
          <div>No notes available</div>
        )}
      </td>
      <td className='text-left' width={200}>
        {moment(application.applicationResponse.created_at).format('DD/MM/YYYY hh:mm A')}
      </td>
      <td className='center-text'>
        <span
          className={`p-1 font-size-12 badge badge-pill badge-${getSeverity(
            application.applicationResponse.status
          )}`}>
          {
            statusSuggestions.find(
              (status) => status.id === application.applicationResponse.status
            ).name
          }
        </span>
      </td>
      <td className='pl-4'>{actionBodyTemplate(application)}</td>
      <PayConfirm modal={modal} handleModal={toggle} subAppId={subAppId} />
      <RequestAction
        modal={requestModal}
        handleModal={actionToggle}
        responseId={responseId}
      />
      <AddQuestionAnswer
        modal={answerModal}
        handleModal={answerToggle}
        subAppId={subAppId}
        responseId={responseId}
      />
    </>
  );
}

export default GetResponse;
