import React, { useState } from 'react';
import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { Card } from 'reactstrap';
import { stringify } from 'query-string';
import { GetProviderAllAssets } from '../../services/Providers/Provider';
import { constants as CONST } from '../../constants/constant';
import { AddOnRampTrial } from '../../services/Business/Crypto';

function SupportedAssets({ provider }) {
  let history = useHistory();
  const [cryptoAssets, setCryptoAssets] = useState([]);
  const [assetType, setAssetType] = useState('crypto');
  const [limit, setLimit] = useState(6);
  const [search, setSearch] = useState('');
  const perPage = 6;

  const providerAssetsFunction = (provider) => {
    GetProviderAllAssets(provider)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        if (data && data.length > 0) {
          setCryptoAssets(data);
        } else {
          setCryptoAssets([]);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    providerAssetsFunction(provider);
  }, [provider]);
  const searchLowerCased = search.toLowerCase();
  const filteredCryptoAssets = (Array.isArray(cryptoAssets) ? cryptoAssets : [])
    .filter(({ coin_name = '', crypto_name = '' }) =>
      ('' + coin_name + crypto_name).toLowerCase().includes(searchLowerCased)
    )
    .slice(0, limit);

  return (
    <>
      <Card className="">
        <div className="p-4">
          <div className="row align-items-center">
            <div className="col-12 col-sm font-size-20 pb-2 pb-sm-0">
              {CONST.SUPPORTED_CRYPTO_ASSETS}
              <span className="ml-1 badge badge-pill badge-light">
                {cryptoAssets.length}
              </span>
            </div>
            <div className="col-12 col-sm-auto d-flex font-size-16 text-black">
              <a
                href="!#"
                className={
                  'text-black ' +
                  (assetType === 'crypto' ? 'font-weight-bolder' : 'font-weight-medium')
                }
                onClick={(evt) => {
                  evt.preventDefault();
                  setAssetType('crypto');
                }}>
                Crypto
              </a>
              <a
                href="!#"
                className={
                  'text-black mx-2 ' +
                  (assetType === 'fiat' ? 'font-weight-bolder' : 'font-weight-medium')
                }
                onClick={(evt) => {
                  evt.preventDefault();
                  setAssetType('fiat');
                }}>
                Fiat
              </a>
            </div>
          </div>
        </div>
        <div>
          <div className="form-group px-4 d-flex">
            <input
              type="text"
              className="form-control form-control-search"
              placeholder="Search"
              value={search}
              onInput={(e) => setSearch(e.target.value)}
              onChange={(e) => setSearch(e.target.value)}
              style={{ maxWidth: '368px' }}
            />
          </div>
        </div>
        {cryptoAssets.length > 0 && (
          <div className="table-responsive">
            <table className="table table-centered table-hover">
              <thead>
                <tr className="font-size-16 text-muted">
                  <th className="pl-4 pb-4 border-0">#</th>
                  <th className="pb-4 border-0">Collection</th>
                  <th className="pb-4 border-0">Market Cap</th>
                  <th className="pb-4 border-0">Market Cap(24h)</th>
                  <th className="pb-4 border-0">Volume(24h)</th>
                  <th className="pr-4 pb-4 border-0">Volume(24h change)</th>
                </tr>
              </thead>
              <tbody>
                {filteredCryptoAssets.map((cryptoAsset, key) => (
                  <tr
                    key={key}
                    className="font-size-16 font-weight-medium cursor-pointer"
                    onClick={() => {
                      // AddOnRampTrial({ crypto_id: cryptoAsset.coin_id })
                      //   .then((res) => {
                      history.push(
                        `/crypto?${stringify({
                          crypto_id:
                            (cryptoAsset.cryptoExist && cryptoAsset.coin_id
                              ? cryptoAsset.coin_id
                              : cryptoAsset._id) || null,
                          coin_gecko_id: cryptoAsset.coin_gecko_id,
                          coin_name: cryptoAsset.news_ticker
                        })}`
                      );
                      // })
                      // .catch((err) => {});
                    }}>
                    <td className="pl-4">{key + 1}</td>
                    <td>
                      <div key={key} className="d-flex align-items-center">
                        <div className="" style={{ minWidth: '43px' }}>
                          {cryptoAsset.image ? (
                            <img
                              src={cryptoAsset.image}
                              height={32}
                              className="rounded-circle"
                              alt={cryptoAsset.name}
                            />
                          ) : cryptoAsset.logo ? (
                            <img
                              src={cryptoAsset.logo}
                              height={32}
                              className="rounded-circle"
                              alt={cryptoAsset.name}
                            />
                          ) : (
                            ''
                          )}
                        </div>
                        <div className="flex-fill ">
                          <div className="text-uppercase">{cryptoAsset.coin_name}</div>
                          <div className="font-size-10 font-weight-light">
                            {cryptoAsset.crypto_name}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td>
                      {cryptoAsset.market_cap ? (
                        <span>
                          $
                          {new Intl.NumberFormat('en-US', {
                            style: 'decimal'
                          }).format(cryptoAsset.market_cap)}
                        </span>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td>{cryptoAsset.market_cap_change_percentage_24h}%</td>
                    <td>
                      {cryptoAsset.total_volume ? (
                        <span>
                          $
                          {new Intl.NumberFormat('en-US', {
                            style: 'decimal'
                          }).format(cryptoAsset.total_volume)}
                        </span>
                      ) : (
                        '-'
                      )}
                    </td>
                    <td className="pr-4">-</td>
                  </tr>
                ))}
              </tbody>
            </table>
            {limit < cryptoAssets.length && (
              <div className="d-flex justify-content-center px-3 pb-3">
                <button
                  className="btn font-size-18 font-weight-medium text-reset"
                  style={{
                    textDecoration: 'underline',
                    textUnderlineOffset: '0.2rem'
                  }}
                  onClick={(evt) => {
                    evt.preventDefault();
                    setLimit(limit + perPage);
                  }}>
                  View More
                </button>
              </div>
            )}
          </div>
        )}
      </Card>
    </>
  );
}

export default SupportedAssets;
