import React, { useContext } from 'react';
import { Col, FormGroup, Input, Modal, ModalHeader } from 'reactstrap';
import { ChangeApplicationStatus } from '../../../../services/Onramp/Application';
import { ApplicationContext } from '../applicationContext';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { Formik, Form, ErrorMessage } from 'formik';

function AcceptApplication(props) {
  let applicationId = useContext(ApplicationContext);
  if (applicationId === null) {
    applicationId = props.applicationId;
  }
  const handleSubmit = (values) => {
    const data = {
      status: 'ACCEPT',
      notes: values.notes
    };

    if (applicationId) {
      ChangeApplicationStatus(applicationId, data)
        .then((res) => {
          props.setUpdatePage(!props.updatePage);
          props.handleOpenModal();

          props.StreamSystemMessageFunc(
            `${applicationId}-${props.applicationAll.business_user_id}`,
            'Exchange has accepted the Application'
          );
          props.GetApplicationResponseFunc(props.applicationId);
          notifySuccess('Application is accepted');
        })
        .catch((error) => {
          notifyError(error.data?.error?.message);
        });
    }
  };

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.handleOpenModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        style={{ borderRadius: '8px' }}
        className='w-100'>
        <ModalHeader toggle={props.handleOpenModal} className=''>
          <div className='col-12 col-sm-auto font-size-20 text-center'>
            <span className='text-black'>Accept Application</span>
          </div>
        </ModalHeader>

        <div className='font-size-20 mt-2'>
          <Col lg={12}>
            <Formik
              initialValues={{ notes: '' }}
              validate={(values) => {
                const errors = {};
                if (!values.notes.trim()) {
                  errors.notes = 'Notes is required';
                }
                return errors;
              }}
              onSubmit={(values) => {
                handleSubmit(values);
              }}>
              {({ handleChange, values }) => (
                <Form>
                  <FormGroup className='mb-4 ml-4 mr-4'>
                    <Input
                      type='textarea'
                      rows='6'
                      name='notes'
                      placeholder='Write a Notes here'
                      className='border-dark'
                      onChange={handleChange}
                      value={values.notes}
                    />

                    <ErrorMessage
                      name='notes'
                      component='div'
                      className='text-danger font-size-12'
                    />
                  </FormGroup>
                  <div className='d-flex justify-content-center mt-4 mb-4'>
                    <button className='btn btn-auth' type='submit' style={{ width: 120 }}>
                      Next
                    </button>
                  </div>
                </Form>
              )}
            </Formik>
          </Col>
        </div>
      </Modal>
    </div>
  );
}

export default AcceptApplication;
