import React from 'react';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';

function UpdateProvider({ modalOpen, setModalOpen, provider, updateProvider }) {
  return (
    <Modal isOpen={modalOpen} toggle={() => setModalOpen(!modalOpen)} centered>
      <ModalHeader
        toggle={() => {
          setModalOpen(!modalOpen);
        }}>
        Update Provider
      </ModalHeader>
      <ModalBody>
        <div className='d-flex align-items-center justify-content-center flex-column'>
          <span className='font-size-14'>
            Do you want to {provider.is_active ? 'block' : 'active'} this provider?
          </span>
          <div className='mt-4'>
            <Button
              color='primary'
              onClick={() =>
                updateProvider({
                  is_active: !provider.is_active,
                  provider: provider.provider
                })
              }>
              Yes
            </Button>
            <Button
              className='ml-2'
              color='danger'
              onClick={() => {
                setModalOpen(!modalOpen);
              }}>
              Cancel
            </Button>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

export default UpdateProvider;
