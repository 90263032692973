import moment from 'moment';
import React from 'react';

function GetApplication({ application, index }) {
  return (
    <React.Fragment>
      <td className='pl-4'>{index + 1}</td>
      <td className='pl-4'>{application.project_name}</td>
      <td className='pl-4'>{application.legal_entity_name}</td>
      <td className='pl-4'>{application.business_category}</td>
      <td className='pl-4'>{application.network}</td>

      <td className='pl-4'>
        {application.sub_application ? (
          application.sub_application.application_status === 'PROCESSING' &&
          application.sub_application.response_complete ? (
            <span className='badge badge-pill badge-warning'>Action Needed</span>
          ) : application.sub_application.application_status === 'PROCESSING' ? (
            <span className='badge badge-pill badge-success'>Processing</span>
          ) : application.sub_application.application_status === 'ACCEPT' ? (
            <span className='badge badge-pill badge-success'>Accepted</span>
          ) : application.sub_application.application_status === 'REJECT' ? (
            <span className='badge badge-pill badge-danger'>Rejected</span>
          ) : application.sub_application.application_status === 'KYB' ? (
            <span className='badge badge-pill badge-primary'>KYB</span>
          ) : application.sub_application.application_status === 'ADD_QUESTIONS' ? (
            <span className='badge badge-pill badge-primary'>Add Questions</span>
          ) : application.sub_application.application_status === 'REQUEST_ACTION' ? (
            <span className='badge badge-pill badge-primary'>Request Action</span>
          ) : application.sub_application.application_status === 'QUOTE' ? (
            <span className='badge badge-pill badge-dark'>Quote</span>
          ) : application.sub_application.application_status === 'PAID' ? (
            <span className='badge badge-pill badge-info'>Paid</span>
          ) : (
            '-'
          )
        ) : (
          <span className='badge badge-pill badge-secondary'>Unread</span>
        )}
      </td>
      <td className='pl-1'>
        {moment(application.application_date).format('DD MMMM YYYY')}
      </td>
    </React.Fragment>
  );
}

export default GetApplication;
