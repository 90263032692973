import React, { useEffect, useState } from 'react';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { GetAllCountries } from '../../services/Business/Search';
import countriesAPI from 'countries-api';

import AllCountriesMap from './AllCountriesMap';
import AllCountriesGrid from './AllCountriesGrid';

function AllCountries(props) {
  const [allCountries, setAllCountries] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    GetAllCountries()
      .then((res) => {
        setIsLoaded(true);
        const data = res && res.data && res.data.data && res.data.data.data;
        data.forEach((country) => {
          const methods = {};
          country.supportedProvider.forEach(({ supportedPaymentMethods }) =>
            supportedPaymentMethods.forEach((method) => {
              if (!methods[method.common_name]) {
                methods[method.common_name] = method;
              }
            })
          );
          country.methods = Object.values(methods);

          country.logo = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.iso}.svg`;
          country.logo_square = `https://purecatamphetamine.github.io/country-flag-icons/1x1/${country.iso}.svg`;
          const resp = countriesAPI.findByCountryCode(country.iso);
          const countryData = (resp && resp.data && resp.data[0]) || null;
          if (countryData) {
            if (Array.isArray(countryData.latlng) && countryData.latlng.length === 2) {
              const [lat, lng] = countryData.latlng;
              //if (!lat || !lng) debugger;
              country.position = { lat, lng };
            }
            const nativeCurrency = countryData.currency && countryData.currency[0];
            country.nativeCurrency = nativeCurrency;
          }
        });
        setAllCountries(data);
        return data;
      })
      .catch((err) => {
        setIsLoaded(true);
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, []);

  return (
    <React.Fragment>
      <div className={'page-content ' + (!isLoaded && 'page-content-loading')}>
        {!isLoaded ? (
          <div className="h-100 d-flex align-items-center justify-content-center py-4">
            <i
              className="fas fa-circle-notch fa-spin text-muted"
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <>
            {allCountries && (
              <>
                <AllCountriesMap countries={allCountries} />
                <AllCountriesGrid allCountries={allCountries} />
              </>
            )}
            <NotificationToastContainer />
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default AllCountries;
