import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import {
  dashboardOrderCountAPI,
  dashboardTotalRevenueAPI
} from '../../services/Admin/orders';
import get from 'lodash/get';

function AdminDashboard() {
  const [pendingCount, setPendingCount] = useState(0);
  const [processingCount, setProcessingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [allOrdersCount, setAllOrdersCount] = useState(0);
  const [completedRevenueCount, setCompletedRevenueCount] = useState(0);
  const [completedBusinessRevenueCount, setCompletedBusinessRevenueCount] = useState(0);
  const [completed30dRevenueCount, setCompleted30dRevenueCount] = useState(0);
  const [completedBusiness30dRevenueCount, setCompletedBusiness30dRevenueCount] =
    useState(0);

  const ordersFunc = ({ status }) => {
    dashboardOrderCountAPI(status)
      .then((res) => {
        const count = get(res, 'data.data.count');
        if (status === 'PENDING') {
          setPendingCount(count);
        } else if (status === 'PROCESSING') {
          setProcessingCount(count);
        } else if (status === 'COMPLETED') {
          setCompletedCount(count);
        } else if (status === 'FAILED') {
          setFailedCount(count);
        } else {
          setAllOrdersCount(count);
        }
      })
      .catch(() => {});
  };

  const dashboardRevenueFunc = ({ status, days }) => {
    dashboardTotalRevenueAPI({ status, days })
      .then((res) => {
        const totalUSDAmount = get(res, 'data.data.totalUSDAmount', []);
        const totalBusinessUSDAmount = get(res, 'data.data.totalBusinessUSDAmount', []);
        if (status === 'COMPLETED' && days === '30d') {
          setCompleted30dRevenueCount(Number(totalUSDAmount).toFixed(2));
          setCompletedBusiness30dRevenueCount(Number(totalBusinessUSDAmount).toFixed(2));
        } else if (status === 'COMPLETED') {
          setCompletedRevenueCount(Number(totalUSDAmount).toFixed(2));
          setCompletedBusinessRevenueCount(Number(totalBusinessUSDAmount).toFixed(2));
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    ordersFunc({ status: 'PENDING' });
    ordersFunc({ status: 'PROCESSING' });
    ordersFunc({ status: 'COMPLETED' });
    ordersFunc({ status: 'FAILED' });
    ordersFunc({ status: 'ALL' });
  }, []);

  useEffect(() => {
    dashboardRevenueFunc({ status: 'COMPLETED' });
    dashboardRevenueFunc({ status: 'COMPLETED', days: '30d' });
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <div>
            <h1>Orders</h1>
            <Row>
              <CommonCardFunction counts={allOrdersCount} cardDetail={'Total Orders'} />
              <CommonCardFunction
                counts={completedCount}
                cardDetail={'Orders Completed'}
              />
              <CommonCardFunction counts={failedCount} cardDetail={'Orders Failed'} />
              <CommonCardFunction
                counts={processingCount}
                cardDetail={'Orders Processing'}
              />
              <CommonCardFunction counts={pendingCount} cardDetail={'Orders Pending'} />
            </Row>
          </div>
          <div>
            <h1>Revenue</h1>
            <Row>
              <CommonCardFunction
                counts={completedRevenueCount}
                cardDetail={'Total Revenue'}
                usd={true}
              />
              <CommonCardFunction
                counts={completed30dRevenueCount}
                cardDetail={'30d Revenue'}
                usd={true}
              />
              <CommonCardFunction
                counts={completedBusinessRevenueCount}
                cardDetail={'Total Business Revenue'}
                usd={true}
              />
              <CommonCardFunction
                counts={completedBusiness30dRevenueCount}
                cardDetail={'30d Business Revenue'}
                usd={true}
              />
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default AdminDashboard;

export const CommonCardFunction = ({ counts, cardDetail, color, usd = false }) => {
  return (
    <Col md={4}>
      <Card style={{ background: color }}>
        <div
          className='d-flex justify-content-between align-items-center m-3'
          style={{ height: '50px' }}>
          <h4>{cardDetail}</h4>
          <span className='d-flex'>
            <h2>{counts}</h2> {usd && 'USD'}
          </span>
        </div>
      </Card>
    </Col>
  );
};
