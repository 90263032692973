import React, { useEffect, useRef, useState } from 'react';
import { HexColorInput, HexColorPicker } from 'react-colorful';

function ColorSelection({
  primaryColor,
  secondaryColor,
  containerColor,
  primaryText,
  secondaryText,
  cardColor,
  setPrimaryColor,
  setSecondaryColor,
  setContainerColor,
  setPrimaryText,
  setSecondaryText,
  setCardColor,
  applyColorChanges
}) {
  const colorPickerRef = useRef();
  const [pickerVisible, setPickerVisible] = useState(false);
  const [pickerSecondaryColor, setPickerSecondaryColor] = useState(false);
  const [pickerContainerVisible, setPickerContainerVisible] = useState(false);
  const [pickerPrimaryText, setPickerPrimaryText] = useState(false);
  const [pickerCardColor, setPickerCardColor] = useState(false);
  const [pickerSecondaryText, setPickerSecondaryText] = useState(false);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (colorPickerRef.current && !colorPickerRef.current.contains(event.target)) {
        setPickerVisible(false);
        setPickerContainerVisible(false);
        setPickerPrimaryText(false);
        setPickerSecondaryText(false);
        setPickerCardColor(false);
        setPickerSecondaryColor(false);
      }
    };

    document.addEventListener('click', handleClickOutside);

    return () => {
      document.removeEventListener('click', handleClickOutside);
    };
  }, []);

  const togglePicker = () => {
    setPickerVisible(true);
    setPickerSecondaryColor(false);
    setPickerContainerVisible(false);
    setPickerPrimaryText(false);
    setPickerCardColor(false);
  };

  const toggleSecondaryPicker = () => {
    setPickerVisible(false);
    setPickerSecondaryColor(true);
    setPickerContainerVisible(false);
    setPickerPrimaryText(false);
    setPickerCardColor(false);
  };

  const toggleContainerPicker = () => {
    setPickerContainerVisible(true);
    setPickerSecondaryColor(false);
    setPickerVisible(false);
    setPickerPrimaryText(false);
    setPickerCardColor(false);
  };

  const togglePrimaryText = () => {
    setPickerPrimaryText(true);
    setPickerSecondaryColor(false);
    setPickerVisible(false);
    setPickerContainerVisible(false);
    setPickerCardColor(false);
  };

  const toggleCardColor = () => {
    setPickerCardColor(true);
    setPickerSecondaryColor(false);
    setPickerVisible(false);
    setPickerContainerVisible(false);
    setPickerPrimaryText(false);
  };

  const toggleSecondaryText = () => {
    setPickerSecondaryText(!pickerSecondaryText);
    setPickerVisible(false);
    setPickerContainerVisible(false);
    setPickerPrimaryText(false);
  };

  return (
    <React.Fragment>
      <div ref={colorPickerRef}>
        <div className='d-flex justify-content-around ml-2 mt-1'>
          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <div
              className='color-picker-class'
              onClick={togglePicker}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: primaryColor,
                zIndex: 101
              }}>
              {pickerVisible && (
                <div style={{ position: 'absolute' }}>
                  <HexColorPicker
                    color={primaryColor}
                    onChange={(e) => setPrimaryColor(e)}
                  />
                  <div className='mt-2'>
                    <HexColorInput
                      color={primaryColor}
                      onChange={(e) => setPrimaryColor(e)}
                      placeholder='Type a color'
                    />
                  </div>
                </div>
              )}
            </div>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              {primaryColor}
            </p>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              Primary Color
            </p>
          </div>

          <div
            className='d-flex align-items-center cursor-pointer ml-2'
            style={{ flexDirection: 'column' }}>
            <div
              className='color-picker-class'
              onClick={toggleSecondaryPicker}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: secondaryColor,
                zIndex: 100
              }}>
              {pickerSecondaryColor && (
                <div style={{ position: 'absolute' }}>
                  <HexColorPicker
                    color={secondaryColor}
                    onChange={(e) => setSecondaryColor(e)}
                  />
                  <div className='mt-2'>
                    <HexColorInput
                      color={secondaryColor}
                      onChange={(e) => setSecondaryColor(e)}
                      placeholder='Type a color'
                    />
                  </div>
                </div>
              )}
            </div>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              {secondaryColor}
            </p>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              Secondary Color
            </p>
          </div>

          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <div
              className='color-picker-class'
              onClick={toggleContainerPicker}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: containerColor,
                zIndex: 99
              }}>
              {pickerContainerVisible && (
                <div style={{ position: 'absolute' }}>
                  <HexColorPicker
                    color={containerColor}
                    onChange={(e) => setContainerColor(e)}
                  />
                  <div className='mt-2'>
                    <HexColorInput
                      color={containerColor}
                      onChange={(e) => setContainerColor(e)}
                      placeholder='Type a color'
                    />
                  </div>
                </div>
              )}
            </div>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              {containerColor}
            </p>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              Container Color
            </p>
          </div>
        </div>
        <div className='d-flex justify-content-around mt-3'>
          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <div
              className='color-picker-class'
              onClick={togglePrimaryText}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: primaryText,
                zIndex: 98
              }}>
              {pickerPrimaryText && (
                <div style={{ position: 'absolute' }}>
                  <HexColorPicker
                    color={primaryText}
                    onChange={(e) => setPrimaryText(e)}
                  />
                  <div className='mt-2'>
                    <HexColorInput
                      color={primaryText}
                      onChange={(e) => setPrimaryText(e)}
                      placeholder='Type a color'
                    />
                  </div>
                </div>
              )}
            </div>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              {primaryText}
            </p>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              Primary Text
            </p>
          </div>
          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <div
              className='color-picker-class'
              onClick={toggleSecondaryText}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: secondaryText,
                zIndex: 97
              }}>
              {pickerSecondaryText && (
                <div style={{ position: 'absolute' }}>
                  <HexColorPicker
                    color={secondaryText}
                    onChange={(e) => setSecondaryText(e)}
                  />
                  <div className='mt-2'>
                    <HexColorInput
                      color={secondaryText}
                      onChange={(e) => setSecondaryText(e)}
                      placeholder='Type a color'
                    />
                  </div>
                </div>
              )}
            </div>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              {secondaryText}
            </p>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>
              Secondary Text
            </p>
          </div>
          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <div
              className='color-picker-class'
              onClick={toggleCardColor}
              style={{
                width: '50px',
                height: '50px',
                borderRadius: '50%',
                backgroundColor: cardColor,
                zIndex: 96
              }}>
              {pickerCardColor && (
                <div style={{ position: 'absolute' }}>
                  <HexColorPicker color={cardColor} onChange={(e) => setCardColor(e)} />
                  <div className='mt-2'>
                    <HexColorInput
                      color={cardColor}
                      onChange={(e) => setCardColor(e)}
                      placeholder='Type a color'
                    />
                  </div>
                </div>
              )}
            </div>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>{cardColor}</p>
            <p className='mt-1 font-size-12 text-black font-wright-medium'>Card Color</p>
          </div>
        </div>
      </div>
      <div className='mb-3 ml-3 mt-3'>
        <button
          type='button'
          name='btn'
          className={`btn btn-auth`}
          onClick={() => applyColorChanges()}
          style={{ width: 120, marginLeft: 0 }}>
          Apply
        </button>
      </div>
    </React.Fragment>
  );
}

export default ColorSelection;
