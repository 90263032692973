import React, { useState } from 'react';
import Congratulations from './Congratulations';
import StepOne from './Step1';
import StepTwo from './Step2';
import StepThree from './Step3';
import StepFour from './Step4';
import StepFive from './Step5';
import StepSix from './Step6';
import StepSeven from './Step7';
import StepEight from './Step8';
import StepNine from './Step9';
import StepTen from './Step10';
import StepEleven from './Step11';
import StepTwelve from './Step12';
import StepThirteen from './Step13';
import StepFourteen from './Step14';
import StepFifteen from './Step15';
import StepSixteen from './Step16';
import StepSeventeen from './Step17';
import StepEighteen from './Step18';
import StepNineteen from './Step19';
import StepTwenty from './Step20';
import StepTwentyOne from './Step21';
import StepTwentyTwo from './Step22';
import StepTwentyThree from './Step23';
import { NotificationToastContainer } from '../../../Custom/notification';
import { Col, Row } from 'reactstrap';
import VerticalStepper from '../../../Custom/VerticalStepper';
import SimpleBar from 'simplebar-react';
import TeamMembers from './TeamMembers';

function CreateExchangeApplication() {
  const [step, setStep] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [exchangeCompleteStep, setExchangeCompleteStep] = useState([0]);
  const [applicationId, setApplicationId] = useState('');
  const [applicationData, setApplicationData] = useState({});
  const [fillFieldLength, setFillFieldLength] = useState(0);
  const prevPage = () => {
    if (step) setStep(step - 1);
  };
  const nextPage = () => {
    if (step < 27) setStep(step + 1);
  };

  const currentComponent = () => {
    switch (step) {
      case 0:
        return (
          <StepOne
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            setApplicationId={setApplicationId}
            nextPage={nextPage}
            setApplicationData={setApplicationData}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 1:
        return (
          <StepTwo
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
            fillFieldLength={fillFieldLength}
          />
        );
      case 2:
        return (
          <TeamMembers
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
            fillFieldLength={fillFieldLength}
          />
        );
      case 3:
        return (
          <StepThree
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
            fillFieldLength={fillFieldLength}
          />
        );
      case 4:
        return (
          <StepFour
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
            fillFieldLength={fillFieldLength}
          />
        );
      case 5:
        return (
          <StepFive
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 6:
        return (
          <StepSix
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 7:
        return (
          <StepSeven
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 8:
        return (
          <StepEight
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 9:
        return (
          <StepNine
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 10:
        return (
          <StepTen
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 11:
        return (
          <StepEleven
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 12:
        return (
          <StepTwelve
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 13:
        return (
          <StepThirteen
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 14:
        return (
          <StepFourteen
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 15:
        return (
          <StepFifteen
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 16:
        return (
          <StepSixteen
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 17:
        return (
          <StepSeventeen
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 18:
        return (
          <StepEighteen
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 19:
        return (
          <StepNineteen
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 20:
        return (
          <StepTwenty
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 21:
        return (
          <StepTwentyOne
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 22:
        return (
          <StepTwentyTwo
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 23:
        return (
          <StepTwentyThree
            setIsLoading={setIsLoading}
            setExchangeCompleteStep={setExchangeCompleteStep}
            applicationId={applicationId}
            applicationData={applicationData}
            setApplicationData={setApplicationData}
            prevPage={prevPage}
            nextPage={nextPage}
            setFillFieldLength={setFillFieldLength}
          />
        );
      case 24:
        return <Congratulations />;
      default:
      // do nothing
    }
  };

  const stepsContent = [
    'Application Background',
    'Project Background',
    'Team Members',
    'Social Media',
    'Coin Marketcap',
    'Chains',
    'Active Users',
    'Trading Volume',
    'Market Makers',
    'Influencers or Marketing Partners',
    'Promotional Strategies',
    'Exchange Marketing Budget',
    'Project Docs',
    'Programs of Interest',
    'Listing Date',
    'Listing Price',
    'Auditing report',
    'Tokenomics',
    'Investment History',
    'Roadmap',
    'Project Uniqueness',
    'TargetGeo',
    'Demo',
    'Finish'
  ];
  return (
    <React.Fragment>
      <div className='page-content'>
        <Row>
          <Col lg={3} md={3} sm={3}>
            <SimpleBar style={{ height: '80vh' }}>
              <div className='card p-3'>
                <VerticalStepper
                  steps={stepsContent}
                  currentStep={step}
                  completedSteps={exchangeCompleteStep}
                  onSelectStep={setStep}
                />
              </div>
            </SimpleBar>
          </Col>
          <Col lg={9} md={9} sm={9} style={{ height: '80vh' }}>
            {isLoading ? (
              <div className='card'>
                <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                  <i
                    className='fas fa-circle-notch fa-spin text-muted'
                    style={{ fontSize: 48 }}></i>
                </div>
              </div>
            ) : (
              <div className='card'>
                <div className='p-4'>{currentComponent()}</div>
              </div>
            )}
          </Col>
        </Row>
      </div>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default CreateExchangeApplication;
