import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import documentUploadIcon from '../../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../../assets/images/documentuploaded.png';
import { AuditingReportInputName } from './helper';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';

function AuditingReportApp({
  allResponses = [],
  values = {},
  fileUrls = {},
  errors = {},
  touched = {},
  setFieldValue = () => {},
  isSectionCollapsed,
  toggleSection,
  applicationId,
  setInitialValuesFunc
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);

  useEffect(() => {
    const res = Object.keys(errors).find((i) => AuditingReportInputName.includes(i));
    setErrorShow(!!res);
  }, [errors]);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          AuditingReportInputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);
  const handleSubmit = () => {
    if (
      !AuditingReportInputName.includes(
        Object.keys(errors).find((i) => AuditingReportInputName.includes(i))
      )
    ) {
      const data = new FormData();
      setIsLoading(true);
      data.append('interested_in_audit', values.interested_in_audit);
      data.append('audit_doc', values.audit_doc);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          notifySuccess(`Audit updated successfully`);
          setIsLoading(false);
          setInitialValuesFunc(application.exchange_application);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };
  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Auditing Report</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <Row>
                <Col lg={6}>
                  <FormGroup check className='d-flex align-items-center mt-3 mb-3'>
                    <Label check>
                      <Input
                        value={values}
                        rows='6'
                        name='interested_in_audit'
                        type='checkbox'
                        onChange={() => {
                          setFieldValue(
                            'interested_in_audit',
                            !values.interested_in_audit
                          );
                        }}
                        checked={values.interested_in_audit}
                      />{' '}
                      <span className='font-size-12'>
                        Are you interested in receiving a Smart Contract audit.
                      </span>
                    </Label>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <div
                    style={{
                      borderRadius: '8px',
                      border: '1px solid',
                      height: 47
                    }}
                    className='d-flex direction-row justify-content-between'>
                    <div className='d-flex direction-row justify-content-between file-upload-wrapper pr-2'>
                      <div>
                        <Label htmlFor='audit' id='audit_doc' className='mb-0 pl-2'>
                          <span
                            className='p-1 mb-2 rounded-circle'
                            style={{
                              color: '#134a87',
                              fontSize: 15,
                              cursor: 'pointer'
                            }}>
                            <img
                              src={
                                !values?.audit_doc
                                  ? documentUploadIcon
                                  : documentUploadedIcon
                              }
                              alt='audit'></img>
                          </span>
                          <span className='font-size-12'>Upload your Audit</span>
                        </Label>
                        {fileUrls.audit_doc && (
                          <a
                            href={fileUrls.audit_doc}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='font-size-12 font-weight-bold text-wrap text-primary pl-4'>
                            View File
                          </a>
                        )}
                        <Input
                          type='file'
                          id='audit'
                          name='audit_doc'
                          onChange={(event) => {
                            setFieldValue('audit_doc', event.currentTarget.files[0]);
                          }}
                          style={{ display: 'none' }}
                        />
                        <ReactTooltip
                          anchorId='audit_doc'
                          place='bottom'
                          content={fileUrls['audit_doc'] && fileUrls['audit_doc'].name}
                        />
                      </div>
                    </div>
                  </div>
                  {errors.audit_doc && touched.audit_doc && (
                    <div style={{ fontSize: 12 }} className='text-left mt-2 text-danger'>
                      {errors.audit_doc.split('.')[0]}
                    </div>
                  )}
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default AuditingReportApp;
