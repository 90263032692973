import React from 'react';

import config from '../../../utils/config.json';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import PayQuote from './PayQuote';

const stripePromise = loadStripe(config.stripePublishableKey);
export default function Stripe(props) {
  const options = {
    clientSecret: props.client_secret
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <PayQuote />
    </Elements>
  );
}
