import React from 'react';
import { Box, Slider } from '@mui/material';
import { REGION, S3_BUCKET } from '../../WidgetCustomization1Buy.js/AWSConstants';
import AWS from 'aws-sdk';
import { decryptText } from '../../../services/Auth';

function BGImages({
  bgImage,
  setBgImage,
  isUpdate,
  setIsUpdate,
  imageName,
  setImageName,
  selectedFile,
  setSelectedFile,
  backgroundSize,
  opacity,
  handleOpacityChange,
  setBackgroundImage,
  backgroundRepeat,
  setBackgroundSize,
  setBackgroundRepeat,
  applyBgImages
}) {
  const handleFileInput = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      setImageName(e.target.files[0].name);
      setSelectedFile(e.target.files[0]);
    }
  };

  AWS.config.update({
    accessKeyId: decryptText(process.env.REACT_APP_S3_ACCESS_KEY),
    secretAccessKey: decryptText(process.env.REACT_APP_S3_SECRET_KEY)
  });

  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION
  });

  const uploadFile = (file) => {
    if (file && file.name) {
      let randomNumber = Math.floor(Math.random() * 100000 + 1);
      let currentDate = Date.now();
      let randomString = `${randomNumber}-${currentDate}`;
      const fileName = `${randomString}.${
        file.name.split('.')[file.name.split('.').length - 1]
      }`;
      const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: fileName
      };

      myBucket
        .putObject(params)
        .on('httpUploadProgress', (evt) => {})
        .send((err, data) => {
          if (err) {
            console.error(err);
          } else {
            setIsUpdate(!isUpdate);
            const uploadedUrl = `https://${S3_BUCKET}.s3.amazonaws.com/${fileName}`;
            setBackgroundImage(uploadedUrl);
          }
        });
    }
  };

  return (
    <React.Fragment>
      <div className='ml-2'>
        <div className='d-flex align-items-center'>
          <label className='switch-fun-mode'>
            <input
              type='checkbox'
              checked={bgImage}
              onChange={() => setBgImage(!bgImage)}
            />
            <span className='slider-fun-mode'></span>
          </label>
          <span className='font-size-12 text-black font-weight-medium mb-2 ml-2'>
            Show Background
          </span>
        </div>
        {bgImage && (
          <div className='mb-3'>
            <div className='d-flex flex-row'>
              <div>
                <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
                  Set Background Image
                </p>
                <div className='file-upload-form d-flex flex-column'>
                  <label for='file' className='file-upload-label p-2 font-size-12'>
                    <div class='file-upload-design'>
                      <span className='browse-button'>Browse file</span> <p>or</p>
                      <p>Drag and Drop</p>
                    </div>
                    <input
                      id='file'
                      type='file'
                      accept='image/*'
                      onChange={handleFileInput}
                    />
                  </label>
                </div>
                <div className='d-flex align-items-left justify-content-start'>
                  <span className='font-size-10'>{extractFromAddress(imageName)}</span>
                </div>
                <div className='mb-2 mt-3'>
                  <button
                    name='btn'
                    className={`btn btn-auth`}
                    style={{ width: 120, marginLeft: 0 }}
                    onClick={() => uploadFile(selectedFile)}>
                    Set Image
                  </button>
                </div>
              </div>
            </div>
            <div className='d-flex flex-row mt-2'>
              <div
                className='d-flex align-items-center cursor-pointer mr-4'
                style={{ flexDirection: 'column' }}>
                <span className='font-size-12 text-black'>Background Size</span>
                <select
                  value={backgroundSize}
                  onChange={(e) => {
                    setBackgroundSize(e.target.value);
                  }}
                  className='form-control'>
                  <option key='Cover' value='cover'>
                    cover
                  </option>
                  <option key='Contain' value='contain'>
                    contain
                  </option>
                  <option key='Auto' value='auto'>
                    auto
                  </option>
                </select>
              </div>
              <div
                className='d-flex align-items-center cursor-pointer mr-4'
                style={{ flexDirection: 'column' }}>
                <span className='font-size-12 text-black'>Background Repeat</span>
                <select
                  value={backgroundRepeat}
                  onChange={(e) => {
                    setBackgroundRepeat(e.target.value);
                  }}
                  className='form-control'>
                  <option key='repeat' value='repeat'>
                    repeat
                  </option>
                  <option key='no-repeat' value='no-repeat'>
                    no-repeat
                  </option>
                  <option key='repeat-x' value='repeat-x'>
                    repeat-x
                  </option>
                  <option key='repeat-y' value='repeat-y'>
                    repeat-y
                  </option>
                  <option key='round' value='round'>
                    round
                  </option>
                  <option key='space' value='space'>
                    space
                  </option>
                </select>
              </div>
              <div
                className='d-flex align-items-center cursor-pointer'
                style={{ flexDirection: 'column' }}>
                <p className='mt-1 font-size-12 text-black font-wright-medium'>
                  Card Opacity
                </p>
                <Box sx={{ width: 100 }}>
                  <Slider
                    value={opacity}
                    onChange={handleOpacityChange}
                    aria-label='Default'
                    valueLabelDisplay='auto'
                    min={0.1}
                    max={1}
                    step={0.1}
                  />
                </Box>
                <p className='mt-1 font-size-12 text-black font-wright-medium'>
                  Opacity: {opacity}
                </p>
              </div>
            </div>
            <div className='mb-3 ml-2 mt-3'>
              <button
                type='button'
                name='btn'
                className={`btn btn-auth`}
                onClick={() => applyBgImages()}
                style={{ width: 120, marginLeft: 0 }}>
                Apply
              </button>
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default BGImages;

export function extractFromAddress(address) {
  if (address) {
    const firstSix = address.substring(0, 6);
    const lastFour = address.substring(address.length - 4);

    return `${firstSix}...${lastFour}`;
  }
}
