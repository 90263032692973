import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFiles } from './ImageUploadCommon';
import { extractFromAddress } from '../BusinessDashboard/WidgetOnboardingSteps/BGImages';

function ConfettiDrop({
  isUpdate,
  setIsUpdate,
  imageFiles,
  setImageFiles,
  setFunModeImages,
  ReactTooltip
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const onDrop = useCallback(
    async (acceptedFiles) => {
      setErrorMessage('');
      const newFiles = acceptedFiles.slice(0, 5 - imageFiles.length);
      const filteredFiles = await Promise.all(
        newFiles.map(async (file) => {
          if (file.size <= 1024000) {
            return file;
          } else {
            setErrorMessage(`Image ${file.name} exceeds size limit (1MB)`);
            return null;
          }
        })
      );
      setImageFiles((prevFiles) => [...prevFiles, ...filteredFiles.filter(Boolean)]);
    },
    [imageFiles, setImageFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true
  });

  const removeImage = (index) => {
    const updatedFiles = [...imageFiles];
    updatedFiles.splice(index, 1);
    setImageFiles(updatedFiles);
  };

  const uploadConfettiFiles = async () => {
    setIsLoading(true);
    await uploadFiles({ filesArr: imageFiles, setState: setFunModeImages });
    setIsLoading(false);
    setIsUpdate(!isUpdate);
  };

  return (
    <div>
      <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
        Fun mode Confetti{' '}
        <i
          data-tooltip-id='confetti'
          data-tip
          data-for='confetti'
          className='font-size-14 mdi mdi-information-outline ml-2'
        />
        <ReactTooltip
          id='confetti'
          place='top'
          effect='solid'
          content='Throws selected favicon images for fun effect.'
          style={{ width: '200px', fontSize: '12px' }}
        />
      </p>
      <div className='d-flex justify-content-start'>
        <div>
          <div className='d-flex flex-row'>
            <div {...getRootProps()} className='file-upload-form'>
              <input {...getInputProps()} />
              <label
                className={`font-size-12 p-2 file-upload-label ${
                  isDragActive ? 'file-upload-drag-active' : ''
                }`}>
                <div className='file-upload-design'>
                  <div class='file-upload-design'>
                    <span className='browse-button'>Browse file</span> <p>or</p>
                    <p>Drag and Drop</p>
                  </div>
                </div>
              </label>
            </div>
            <div className='d-flex align-items-center'>
              <button
                name='btn'
                className={`btn btn-auth ml-2`}
                style={{ width: 120 }}
                onClick={uploadConfettiFiles}>
                {isLoading ? 'Loading...' : 'Set Images'}
              </button>
            </div>
          </div>
          {errorMessage && (
            <p className='error-message font-size-10' style={{ color: 'red' }}>
              {errorMessage}
            </p>
          )}
          {imageFiles.map((file, index) => (
            <div key={index} className='d-flex align-items-center mt-2'>
              <div>
                <img
                  src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                  alt={`Preview ${file.name}`}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '10px'
                  }}
                />
              </div>
              <span className='font-size-14'>
                {extractFromAddress(typeof file === 'string' ? file : file.name)}
              </span>
              <span
                className='ml-2 text-danger cursor-pointer'
                onClick={() => removeImage(index)}>
                x
              </span>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}

export default ConfettiDrop;
