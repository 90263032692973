import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../constants/application';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import {
  GetApplication,
  UpdateApplication,
  UploadApplicationDocs
} from '../../../services/Business/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import get from 'lodash/get';
import OnRampHandler from './OnRampHandler';

function IntegrationDetailsPage2({
  setCompletedSteps,
  nextPage,
  applicationId,
  prevPage
}) {
  const [initialValue, setInitialValue] = useState({
    regulatory_auth: '',
    AML_CTF_compliance: '',
    KYC_checks_on_users: '',
    users_screening: '',
    services_to_minors: '',
    corporate_registration_doc: '',
    terms_of_service_doc: '',
    privacy_policy_doc: '',
    AML_CTF_policy_doc: '',
    licence_doc: ''
  });

  const [optionalFile, setOptionalFile] = useState({ additional_docs: [] });
  const changeHandler = (event, setFieldValue) => {
    if (event.target.files[0])
      if (event.target.name === 'additional_docs') {
        setOptionalFile({
          ...optionalFile,
          [event.target.name]: [...optionalFile['additional_docs'], event.target.files]
        });
      } else {
        !!setFieldValue && setFieldValue(event.target.name, event.target.files[0]);
        setOptionalFile({ ...optionalFile, [event.target.name]: event.target.files[0] });
      }
  };

  const [fileUrls, setFileUrls] = useState({});

  useEffect(() => {
    if (applicationId !== '') {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        const {
          regulatory_authorisation_requirement,
          AML_CTF_compliance,
          KYC_checks_on_users,
          sanctions_screening_of_users,
          services_to_minors
        } = application;

        if (
          regulatory_authorisation_requirement !== undefined &&
          AML_CTF_compliance !== undefined &&
          KYC_checks_on_users !== undefined &&
          sanctions_screening_of_users !== undefined &&
          services_to_minors !== undefined
        ) {
          setInitialValue({
            regulatory_auth: regulatory_authorisation_requirement.toString(),
            users_screening:
              sanctions_screening_of_users !== null
                ? sanctions_screening_of_users.toString()
                : 'null',
            AML_CTF_compliance:
              AML_CTF_compliance !== null ? AML_CTF_compliance.toString() : 'null',
            KYC_checks_on_users:
              KYC_checks_on_users !== null ? KYC_checks_on_users.toString() : 'null',
            services_to_minors: services_to_minors.toString(),
            corporate_registration_doc: application.corporate_registration_doc,
            terms_of_service_doc: application.terms_of_service_doc,
            privacy_policy_doc: application.privacy_policy_doc,
            AML_CTF_policy_doc: application.AML_CTF_policy_doc,
            licence_doc: application.licence_doc
          });
        }
        setFileUrls({
          corporate_registration_doc: application.corporate_registration_doc,
          terms_of_service_doc: application.terms_of_service_doc,
          privacy_policy_doc: application.privacy_policy_doc,
          AML_CTF_policy_doc: application.AML_CTF_policy_doc,
          licence_doc: application.licence_doc,
          additional_docs: application.additional_docs,
          legal_opinion_doc: application.legal_opinion_doc,
          policy_procedures_doc: application.policy_procedures_doc
        });
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const data = {
      regulatory_authorisation_requirement: values.regulatory_auth,
      AML_CTF_compliance:
        values.AML_CTF_compliance !== 'null' ? values.AML_CTF_compliance : null,
      KYC_checks_on_users:
        values.KYC_checks_on_users !== 'null' ? values.KYC_checks_on_users : null,
      sanctions_screening_of_users:
        values.users_screening !== 'null' ? values.users_screening : null,
      services_to_minors:
        values.services_to_minors !== 'null' ? values.services_to_minors : null
      // corporate_registration_doc: values.corporate_registration_doc,
      // terms_of_service_doc: values.terms_of_service_doc,
      // privacy_policy_doc: values.privacy_policy_doc
      // AML_CTF_policy_doc: values.AML_CTF_compliance==="true" ? values.AML_CTF_policy_doc : '',
      // licence_doc: values.regulatory_auth ==="true"? values.licence_doc : ''
    };
    const payloadData = new FormData();
    Object.keys(optionalFile).forEach((key) => {
      if (key === 'additional_docs' && optionalFile.additional_docs[0]) {
        let docs = Array.from(optionalFile.additional_docs);
        for (let i = 0; i < docs.length; i++) {
          payloadData.append('additional_docs', docs[i][0]);
        }
      } else {
        payloadData.append(key, optionalFile[key]);
      }
    });
    UploadApplicationDocs({ application_id: applicationId }, payloadData)
      .then((res) => {
        // const application = get(res, 'data.data.data', '');
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    UpdateApplication(applicationId, data)
      .then((res) => {})
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    setCompletedSteps([0, 1, 2, 3]);

    nextPage();
  };

  const integrationDetailSchema = Yup.object().shape({
    regulatory_auth: Yup.string().required('Select one option'),
    AML_CTF_compliance: Yup.string().required('Select one option'),
    KYC_checks_on_users: Yup.string().required('Select one option'),
    users_screening: Yup.string().required('Select one option'),
    services_to_minors: Yup.string().required('Select one option'),
    licence_doc: Yup.mixed().when('regulatory_auth', {
      is: (value) => value == 'true',
      then: Yup.mixed().required('License document is required')
    }),
    AML_CTF_policy_doc: Yup.mixed().when('AML_CTF_compliance', {
      is: (value) => value == 'true',
      then: Yup.mixed().required('AML / CTF Compliance is required')
    }),
    corporate_registration_doc: Yup.mixed().test(
      'Corporate registration document is required',
      function () {
        if (
          !optionalFile['corporate_registration_doc'] &&
          !fileUrls.terms_of_service_doc
        ) {
          return false; // Validation error
        }
        return true; // Validation success
      }
    ),
    terms_of_service_doc: Yup.mixed().test('Terms of Service is required', function () {
      if (!optionalFile['terms_of_service_doc'] && !fileUrls.terms_of_service_doc) {
        return false; // Validation error
      }
      return true; // Validation success
    }),
    privacy_policy_doc: Yup.mixed().test('Privacy Policy is required', function () {
      if (!optionalFile['privacy_policy_doc'] && !fileUrls.privacy_policy_doc) {
        return false;
      }
      return true;
    })
  });

  const removeFile = (index) => {
    const updatedFiles = [...optionalFile['additional_docs']];
    updatedFiles.splice(index, 1);

    setOptionalFile({
      ...optionalFile,
      additional_docs: updatedFiles
    });
  };

  return (
    <React.Fragment>
      <div className='p-4 p-sm-1'>
        <div className='col-12 col-sm-auto font-size-22 pb-2'>
          <span className='text-black'>Partner Entity Compliance and Documentation</span>
        </div>
        <Container className='col-12 col-sm-auto pt-4'>
          <div>
            <Formik
              initialValues={initialValue}
              enableReinitialize={true}
              validationSchema={integrationDetailSchema}
              onSubmit={handleSubmit}>
              {({ errors, touched, values, handleChange, setFieldValue }) => (
                <Form>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='regulatory_auth'
                            className='text-black font-weight-normal'>
                            Is the partner entity subject to any regulatory authorization
                            or licensing requirements?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label className='mt-2 d-flex align-items-center font-size-12'>
                              <Field
                                name='regulatory_auth'
                                type='radio'
                                value='true'
                                onClick={() => {
                                  setFieldValue('licence_doc', '');
                                  setFileUrls({ ...fileUrls, licence_doc: '' });
                                  setOptionalFile({
                                    ...optionalFile,
                                    licence_doc: ''
                                  });
                                }}
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center  ml-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='regulatory_auth'
                                type='radio'
                                value='false'
                                onClick={() => {
                                  setFieldValue('licence_doc', '');
                                  setFileUrls({ ...fileUrls, licence_doc: '' });
                                  setOptionalFile({
                                    ...optionalFile,
                                    licence_doc: ''
                                  });
                                }}
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                        </div>

                        {errors['regulatory_auth'] && touched['regulatory_auth'] && (
                          <span
                            style={{ fontSize: 12 }}
                            className='text-left text-danger'>
                            {errors['regulatory_auth']}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='AML_CTF_compliance'
                            className='text-black font-weight-normal'>
                            Does the partner entity maintain an AML/CTF compliance
                            program?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label className='mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='AML_CTF_compliance'
                                type='radio'
                                value='true'
                                onClick={() => {
                                  setFieldValue('AML_CTF_policy_doc', '');
                                  setFileUrls({
                                    ...fileUrls,
                                    AML_CTF_policy_doc: ''
                                  });
                                  setOptionalFile({
                                    ...optionalFile,
                                    AML_CTF_policy_doc: ''
                                  });
                                }}
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='AML_CTF_compliance'
                                type='radio'
                                value='false'
                                onClick={() => {
                                  setFieldValue('AML_CTF_policy_doc', '');
                                  setFileUrls({
                                    ...fileUrls,
                                    AML_CTF_policy_doc: ''
                                  });
                                  setOptionalFile({
                                    ...optionalFile,
                                    AML_CTF_policy_doc: ''
                                  });
                                }}
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='AML_CTF_compliance'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['AML_CTF_compliance'] &&
                          touched['AML_CTF_compliance'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['AML_CTF_compliance']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='KYC_checks_on_users'
                            className='text-black font-weight-normal'>
                            Does the partner entity perform KYC checks on its users?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label className='mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='KYC_checks_on_users'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='KYC_checks_on_users'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='KYC_checks_on_users'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['KYC_checks_on_users'] &&
                          touched['KYC_checks_on_users'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['KYC_checks_on_users']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='users_screening'
                            className='text-black font-weight-normal'>
                            Does the partner entity perform sanctions screening of its
                            users?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='users_screening'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='users_screening'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='users_screening'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['users_screening'] && touched['users_screening'] && (
                          <span
                            style={{ fontSize: 12 }}
                            className='text-left text-danger'>
                            {errors['users_screening']}
                          </span>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='services_to_minors'
                            className='text-black font-weight-normal'>
                            Does the partner entity provide services to minors (under 18
                            years of age)?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label className='mt-2 d-flex align-items-center font-size-12'>
                              <Field
                                name='services_to_minors'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='services_to_minors'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2 mt-2  d-flex align-items-center font-size-12'>
                              <Field
                                name='services_to_minors'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['services_to_minors'] &&
                          touched['services_to_minors'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['services_to_minors']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='description'
                            className='text-black font-weight-normal'>
                            Required
                          </Label>
                        </div>
                        <div
                          className='d-flex justify-content-between'
                          style={{ flexDirection: 'column' }}>
                          <div>
                            <Label
                              htmlFor='corporate_registration_doc'
                              id='registration_doc'>
                              <span
                                className='p-1 mb-2 rounded-circle'
                                style={{
                                  color: '#134a87',
                                  fontSize: 14,
                                  cursor: 'pointer'
                                }}>
                                <img
                                  src={
                                    optionalFile['corporate_registration_doc'] ||
                                    fileUrls.corporate_registration_doc
                                      ? documentUploadedIcon
                                      : documentUploadIcon
                                  }
                                  alt='Corporate Registration Documents'></img>
                              </span>
                              <span className='font-size-12'>
                                Corporate Registration Documents
                              </span>
                            </Label>
                            {fileUrls.corporate_registration_doc && (
                              <a
                                href={fileUrls.corporate_registration_doc}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                View File
                              </a>
                            )}
                            {!optionalFile['corporate_registration_doc'] &&
                              touched['corporate_registration_doc'] && (
                                <div
                                  style={{ fontSize: 12 }}
                                  className='text-left text-danger pl-2'>
                                  {errors.corporate_registration_doc}
                                </div>
                              )}
                            <Input
                              type='file'
                              id='corporate_registration_doc'
                              name='corporate_registration_doc'
                              onChange={changeHandler}
                              style={{ display: 'none' }}
                            />
                            <ReactTooltip
                              anchorId='registration_doc'
                              place='bottom'
                              content={
                                optionalFile['corporate_registration_doc'] &&
                                optionalFile['corporate_registration_doc'].name
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor='terms_of_service_doc' id='terms_of_service'>
                              <span
                                className='p-1 mb-2 rounded-circle'
                                style={{
                                  color: '#134a87',
                                  fontSize: 15,
                                  cursor: 'pointer'
                                }}>
                                <img
                                  src={
                                    optionalFile['terms_of_service_doc'] ||
                                    fileUrls.terms_of_service_doc
                                      ? documentUploadedIcon
                                      : documentUploadIcon
                                  }
                                  alt='Terms of Service'></img>
                              </span>
                              <span className='font-size-12'>Terms of Service</span>
                            </Label>
                            {fileUrls.terms_of_service_doc && (
                              <a
                                href={fileUrls.terms_of_service_doc}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                View File
                              </a>
                            )}
                            {!optionalFile['terms_of_service_doc'] &&
                              touched['terms_of_service_doc'] && (
                                <div
                                  style={{ fontSize: 12 }}
                                  className='text-left text-danger pl-2'>
                                  {errors.terms_of_service_doc}
                                </div>
                              )}
                            <Input
                              type='file'
                              id='terms_of_service_doc'
                              name='terms_of_service_doc'
                              onChange={changeHandler}
                              style={{ display: 'none' }}
                            />
                            <ReactTooltip
                              anchorId='terms_of_service'
                              place='bottom'
                              content={
                                optionalFile['terms_of_service_doc'] &&
                                optionalFile['terms_of_service_doc'].name
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor='privacy_policy_doc' id='privacy_policy'>
                              <span
                                className='p-1 mb-2 rounded-circle'
                                style={{
                                  color: '#134a87',
                                  fontSize: 15,
                                  cursor: 'pointer'
                                }}>
                                <img
                                  src={
                                    optionalFile['privacy_policy_doc'] ||
                                    fileUrls.privacy_policy_doc
                                      ? documentUploadedIcon
                                      : documentUploadIcon
                                  }
                                  alt='Privacy Policy'></img>
                              </span>
                              <span className='font-size-12'>Privacy Policy</span>
                            </Label>
                            {fileUrls.privacy_policy_doc && (
                              <a
                                href={fileUrls.privacy_policy_doc}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                View File
                              </a>
                            )}
                            {!optionalFile['privacy_policy_doc'] &&
                              touched['privacy_policy_doc'] && (
                                <div
                                  style={{ fontSize: 12 }}
                                  className='text-left text-danger pl-2'>
                                  {errors.privacy_policy_doc}
                                </div>
                              )}
                            <Input
                              type='file'
                              id='privacy_policy_doc'
                              name='privacy_policy_doc'
                              onChange={changeHandler}
                              style={{ display: 'none' }}
                            />
                            <ReactTooltip
                              anchorId='privacy_policy'
                              place='bottom'
                              content={
                                optionalFile['privacy_policy_doc'] &&
                                optionalFile['privacy_policy_doc'].name
                              }
                            />
                          </div>
                          {values.AML_CTF_compliance === 'true' && (
                            <div>
                              <Label htmlFor='AML_CTF_policy_doc' id='aml_ctf_policy'>
                                <span
                                  className='p-1 mb-2 rounded-circle'
                                  style={{
                                    color: '#134a87',
                                    fontSize: 15,
                                    cursor: 'pointer'
                                  }}>
                                  <img
                                    src={
                                      optionalFile['AML_CTF_policy_doc'] ||
                                      fileUrls.AML_CTF_policy_doc
                                        ? documentUploadedIcon
                                        : documentUploadIcon
                                    }
                                    alt='AML CTF Compliance'></img>
                                </span>
                                AML / CTF Compliance
                              </Label>
                              {!optionalFile['AML_CTF_policy_doc'] &&
                                touched['AML_CTF_policy_doc'] && (
                                  <div
                                    style={{ fontSize: 14 }}
                                    className='text-left text-danger pl-4'>
                                    {errors.AML_CTF_policy_doc}
                                  </div>
                                )}
                              {fileUrls.AML_CTF_policy_doc && (
                                <a
                                  href={fileUrls.AML_CTF_policy_doc}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                  View File
                                </a>
                              )}
                              <Input
                                type='file'
                                id='AML_CTF_policy_doc'
                                name='AML_CTF_policy_doc'
                                onChange={(e) => changeHandler(e, setFieldValue)}
                                style={{ display: 'none' }}
                              />
                              <ReactTooltip
                                anchorId='aml_ctf_policy'
                                place='bottom'
                                content={
                                  optionalFile['AML_CTF_policy_doc'] &&
                                  optionalFile['AML_CTF_policy_doc'].name
                                }
                              />
                            </div>
                          )}
                          {values.regulatory_auth === 'true' && (
                            <div>
                              <Label htmlFor='licence_doc' id='licence'>
                                <span
                                  className='p-1 mb-2 rounded-circle'
                                  style={{
                                    color: '#134a87',
                                    fontSize: 15,
                                    cursor: 'pointer'
                                  }}>
                                  <img
                                    src={
                                      optionalFile['licence_doc'] || fileUrls.licence_doc
                                        ? documentUploadedIcon
                                        : documentUploadIcon
                                    }
                                    alt='Copy of License'></img>
                                </span>
                                <span className='font-size-12'>Copy of Licenses</span>
                              </Label>
                              {!optionalFile['licence_doc'] && touched['licence_doc'] && (
                                <div
                                  style={{ fontSize: 14 }}
                                  className='text-left text-danger pl-4'>
                                  {errors.licence_doc}
                                </div>
                              )}
                              {fileUrls.licence_doc && (
                                <a
                                  href={fileUrls.licence_doc}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                  View File
                                </a>
                              )}
                              <Input
                                type='file'
                                id='licence_doc'
                                name='licence_doc'
                                onChange={(e) => changeHandler(e, setFieldValue)}
                                style={{ display: 'none' }}
                              />
                              <ReactTooltip
                                anchorId='licence'
                                place='bottom'
                                content={
                                  optionalFile['licence_doc'] &&
                                  optionalFile['licence_doc'].name
                                }
                              />
                            </div>
                          )}
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='description'
                            className='text-black font-weight-normal'>
                            Optional Documents
                          </Label>
                        </div>
                        <Col
                          style={{
                            padding: '0'
                          }}>
                          <div>
                            <Label htmlFor='legal_opinion_doc' id='legal_opinion'>
                              <span
                                className='p-1 mb-2 rounded-circle'
                                style={{
                                  color: '#134a87',
                                  fontSize: 15,
                                  cursor: 'pointer'
                                }}>
                                <img
                                  src={
                                    optionalFile['legal_opinion_doc'] ||
                                    fileUrls.legal_opinion_doc
                                      ? documentUploadedIcon
                                      : documentUploadIcon
                                  }
                                  alt='Legal Opinion'></img>
                              </span>
                              <span className='font-size-12'>Legal Opinion</span>
                            </Label>
                            {fileUrls.legal_opinion_doc && (
                              <a
                                href={fileUrls.legal_opinion_doc}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                View File
                              </a>
                            )}
                            <Input
                              type='file'
                              id='legal_opinion_doc'
                              name='legal_opinion_doc'
                              onChange={changeHandler}
                              style={{ display: 'none' }}
                            />
                            <ReactTooltip
                              anchorId='legal_opinion'
                              place='bottom'
                              content={
                                optionalFile['legal_opinion_doc'] &&
                                optionalFile['legal_opinion_doc'].name
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor='policy_procedures_doc' id='policy_procedures'>
                              <span
                                className='p-1 mb-2 rounded-circle'
                                style={{
                                  color: '#134a87',
                                  fontSize: 15,
                                  cursor: 'pointer'
                                }}>
                                <img
                                  src={
                                    optionalFile['policy_procedures_doc'] ||
                                    fileUrls.policy_procedures_doc
                                      ? documentUploadedIcon
                                      : documentUploadIcon
                                  }
                                  alt='PoliciesProcedures'></img>
                              </span>
                              <span className='font-size-12'>Policies / Procedures</span>
                            </Label>
                            {fileUrls.policy_procedures_doc && (
                              <a
                                href={fileUrls.policy_procedures_doc}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                View File
                              </a>
                            )}
                            <Input
                              type='file'
                              id='policy_procedures_doc'
                              name='policy_procedures_doc'
                              onChange={changeHandler}
                              style={{ display: 'none' }}
                            />
                            <ReactTooltip
                              anchorId='policy_procedures'
                              place='bottom'
                              content={
                                optionalFile['policy_procedures_doc'] &&
                                optionalFile['policy_procedures_doc'].name
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor='additional_doc' id='additional'>
                              <span
                                className='p-1 mb-2 rounded-circle'
                                style={{
                                  color: '#134a87',
                                  fontSize: 15,
                                  cursor: 'pointer'
                                }}>
                                <img
                                  src={
                                    optionalFile['additional_docs'].length > 0
                                      ? documentUploadedIcon
                                      : documentUploadIcon
                                  }
                                  alt='Additional Documents'></img>
                              </span>
                              <span className='font-size-12'>Additional Documents</span>
                            </Label>
                            {fileUrls.additional_docs &&
                              fileUrls.additional_docs.map((url, index) => {
                                return (
                                  <div key={index}>
                                    <a
                                      href={url}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='pl-4 font-size-14 font-weight-bold text-wrap text-primary'>
                                      View File
                                    </a>
                                  </div>
                                );
                              })}
                            <Input
                              type='file'
                              id='additional_doc'
                              name='additional_docs'
                              style={{ display: 'none' }}
                              onChange={changeHandler}
                              multiple
                            />
                            {optionalFile['additional_docs'].length > 0 &&
                              Array.from(optionalFile['additional_docs']).map(
                                (file, index) => (
                                  <div style={{ color: '#B9D794' }} key={index}>
                                    <Label className='pl-4'>{file[0].name}</Label>
                                    <i
                                      className='fas fa-times pl-2'
                                      style={{ color: 'red' }}
                                      onClick={() => removeFile(index)}></i>
                                  </div>
                                )
                              )}
                          </div>
                        </Col>
                      </FormGroup>
                    </Col>
                  </Row>
                  <OnRampHandler
                    ariaValueNow={initialValue.services_to_minors !== '' ? '80' : '60'}
                    condition={initialValue.services_to_minors !== '' ? '80%' : '60%'}
                    handleNext={(values) => handleSubmit(values)}
                    handlePre={prevPage}
                    type='submit'
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default IntegrationDetailsPage2;
