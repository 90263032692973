import React from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';

function ReorderTabs({ list, setList, applyOrderChanges }) {
  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newList = Array.from(list);
    const [reorderedItem] = newList.splice(result.source.index, 1);
    newList.splice(result.destination.index, 0, reorderedItem);
    setList(newList);
  };

  const handleCheckboxChange = (id) => {
    const newList = list.map((item) => {
      if (item.id === id) {
        return { ...item, is_active: !item.is_active };
      }
      return item;
    });
    setList(newList);
  };

  return (
    <React.Fragment>
      <div className='ml-2'>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='list'>
            {(provided) => (
              <div {...provided.droppableProps} ref={provided.innerRef}>
                {list.map((item, index) => (
                  <Draggable key={item.id} draggableId={item.id} index={index}>
                    {(provided) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}>
                        <div
                          className='d-flex align-items-center mt-2 p-1'
                          style={{
                            width: '50%',
                            borderRadius: '8px',
                            border: '1px solid'
                          }}>
                          <i className='mdi mdi-drag font-size-22' />
                          <label className='switch-fun-mode mt-2'>
                            <input
                              type='checkbox'
                              checked={item.is_active}
                              onChange={() => handleCheckboxChange(item.id)}
                            />
                            <span className='slider-fun-mode'></span>
                          </label>
                          <span className='font-size-12 text-black font-weight-bold ml-4'>
                            {item.content}
                          </span>
                        </div>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </div>
      <div className='mb-3 ml-3 mt-3'>
        <button
          type='button'
          name='btn'
          className={`btn btn-auth`}
          onClick={() => applyOrderChanges(list)}
          style={{ width: 120, marginLeft: 0 }}>
          Apply
        </button>
      </div>
    </React.Fragment>
  );
}

export default ReorderTabs;
