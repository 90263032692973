/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';

function MoonpayWidget({ backToWidget, buyData }) {
  return (
    <>
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light p-1 mb-2"
          style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
          onClick={backToWidget}>
          <ArrowLeftIcon width={18} height={18} />
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <iframe
          scrolling="no"
          style={{ height: '578px', width: '450px' }}
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          frameBorder="0"
          src={`https://buy${process.env.REACT_APP_MOONPAY_API}?apiKey=${process.env.REACT_APP_MOONPAY_API_KEY}&currencyCode=${buyData.cryptoCurrency}&baseCurrencyAmount=${buyData.fiat_value}&baseCurrencyCode=${buyData.fiat_currency}`}
        />
      </div>
    </>
  );
}

export default MoonpayWidget;
