import React, { useEffect, useMemo, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';
import ReactTags from 'react-tag-autocomplete';
import { allCountries } from 'country-region-data';
import { regions } from '../../../constants/regionConst';

function StepTwentyOne(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;
  const [tags, setTags] = useState([]);

  const allRegionOptions = useMemo(() => {
    return allCountries.reduce((acc, [countryName, countryCode, regions]) => {
      const countryRegions = regions.map(([region]) => ({
        country: countryName,
        countryCode,
        region,
        value: region.toLowerCase(),
        label: region
      }));
      return [...acc, ...countryRegions];
    }, []);
  }, []);
  const suggestionsRegion = [...allRegionOptions, ...regions].map(({ value, label }) => ({
    id: value,
    name: label
  }));

  const onDelete = (index) => {
    setTags(tags.filter((t, i) => i !== index));
  };

  const onAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const step = 11;
  const [initialValue, setInitialValue] = useState({
    target_geo: []
  });
  const ListingPriceSchema = Yup.object().shape({
    target_geo: Yup.array()
      .min(1, ERROR_CONST.TARGETGEO_REQUIRED)
      .required(ERROR_CONST.TARGETGEO_REQUIRED)
  });
  const [completeStep, setCompleteStep] = useState(0);
  const applicationData = props.applicationData.exchange_application;

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.target_geo) {
          setInitialValue({
            target_geo: application.exchange_application.target_geo
          });
          const initTags = suggestionsRegion.filter(({ name }) =>
            application.exchange_application.target_geo.includes(name)
          );
          setTags(initTags);
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 100));
    if (values.target_geo !== applicationData.target_geo) {
      const data = {
        target_geo: JSON.stringify(values.target_geo),
        percentage_complete: stepsRequired
      };
      setIsLoading(true);
      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          props.setApplicationData(application);
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    } else {
      nextPage();
    }
    setExchangeCompleteStep([
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21
    ]);
  };
  const calculateProgress = () => {
    return completeStep;
  };
  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>
          Primary focal areas or principal user base regions
        </span>
      </div>
      <Container className='col-12 col-sm-auto pt-2'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={ListingPriceSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div>
                  <Row>
                    <Col>
                      <FormGroup className='mb-2'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='target_geo'
                            className='text-black font-weight-normal'>
                            {CONST.TARGETGEO}
                          </Label>
                        </div>
                        <div>
                          <ReactTags
                            tags={tags}
                            classNames={{
                              root: 'font-size-12 border-dark custom-react-tags'
                            }}
                            name='target_geo'
                            placeholderText='Select Region'
                            suggestions={suggestionsRegion}
                            onDelete={(index) => {
                              onDelete(index);
                              const newTags = tags.filter((t, i) => i !== index);
                              setFieldValue(
                                'target_geo',
                                newTags.map((tag) => tag.name)
                              );
                            }}
                            onAddition={(tag) => {
                              onAddition(tag);
                              const newTags = [...tags, tag];
                              setFieldValue(
                                'target_geo',
                                newTags.map((tag) => tag.name)
                              );
                            }}
                          />
                        </div>
                        {errors.target_geo && touched.target_geo ? (
                          <div className='text-danger font-size-12'>
                            {errors.target_geo}
                          </div>
                        ) : null}
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepTwentyOne;
