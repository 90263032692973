import React, { useEffect, useState } from 'react';

import { Row, Col, Container, FormGroup, Button, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import AuthInput from '../../Custom/AuthInput';
import { constants as PLACE_CONST } from '../../constants/placeholder';
import { constants as CONST } from '../../constants/constant';
import { constants as ROUTE_CONST } from '../../constants/routes';
import { constants as BUTTON_CONST } from '../../constants/button';
import { constants as ERROR_CONST } from '../../constants/errorMessage';
import { notificationMessageFunction } from '../../constants/notificationConst';
import * as Yup from 'yup';

import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { userForgotPassword } from '../../services/Auth';
import RightLogo from './RightLogo';

function ForgetPassword(props) {
  const [emailAddress, setEmailAddress] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let email = urlParams.get('email');
    if (email) {
      setEmailAddress(email);
    }
  }, []);

  const ForgetPasswordSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERROR_CONST.VALID_EMAIL)
      .required(ERROR_CONST.EMAIL_REQUIRED)
  });

  const handleSubmit = (values) => {
    userForgotPassword(encodeURIComponent(values.email))
      .then((res) => {
        localStorage.setItem(CONST.EMAIL, values.email);
        props.history.push({ pathname: ROUTE_CONST.VERIFY_ACCOUNT_PASSWORD });
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  return (
    <React.Fragment>
      <div>
        <Container fluid className='p-0'>
          <Row className='no-gutters'>
            <Col lg={4}>
              <div className='authentication-page-content p-4 d-flex align-items-top min-vh-100'>
                <div className='w-100'>
                  <Row className='justify-content-center'>
                    <Col lg={9}>
                      <div>
                        <div className='text-left'>
                          <h4 className='font-size-32 mt-4 text-black'>
                            {CONST.ENTER_EMAIL_ADDRESS}
                          </h4>
                          <p className='text-muted'>
                            {CONST.FORGET_PASSWORD_DESCRIPTION}
                          </p>
                        </div>

                        <div className='p-2 mt-5'>
                          <Formik
                            enableReinitialize
                            initialValues={{ email: emailAddress }}
                            validationSchema={ForgetPasswordSchema}
                            onSubmit={handleSubmit}>
                            {({ errors, touched, values, handleChange }) => (
                              <Form>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='email' className='text-black'>
                                      {CONST.EMAIL_ADDRESS}
                                    </Label>
                                    <AuthInput
                                      type='email'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_EMAIL}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      name='email'
                                    />
                                  </FormGroup>
                                </div>

                                <div className='mt-3 text-center w-100 waves-effect waves-light'>
                                  <Button
                                    type='submit'
                                    name='btn'
                                    className={`btn btn-auth`}
                                    onSubmit={(values) => handleSubmit(values)}>
                                    {BUTTON_CONST.RESET_PASSWORD}
                                  </Button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <RightLogo />
            </Col>
          </Row>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default ForgetPassword;
