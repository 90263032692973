import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import AuthInput from '../../../Custom/AuthInput';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { Field, Form, Formik } from 'formik';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import Select from 'react-select';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import moment from 'moment';
import { get } from 'lodash';
import { notifyError } from '../../../Custom/notification';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { GetAllNetwork } from '../../../services/Business/Application';
import { GetApplication } from '../../../services/Onramp/Application';

function ViewOnrampApplication({ setApplicationId }) {
  const [applicationId] = useQueryParam('application_id', StringParam);
  useEffect(() => {
    setApplicationId(applicationId);
  }, []);

  const [isLoaded, setIsLoaded] = useState(false);
  const [loading, setLoading] = useState(false);
  const [buyCheck, setBuyCheck] = useState(false);
  const [sellCheck, setSellCheck] = useState(false);
  const [allNetworks, setAllNetworks] = useState([]);
  const [selectedFields, setSelectedFields] = useState([]);
  const [fileUrls, setFileUrls] = useState({});
  const [initialValue, setInitialValue] = useState({
    legal_entity_name: '',
    project_name: '',
    legal_entity_register_number: '',
    website: '',
    business_category: '',
    country_of_incorporation: '',
    token_address: '',
    product_description: '',
    product_custodial: '',
    user_access_to_wallet: '',
    pre_populate_wallet_address: '',
    crypto_to_other_party: '',
    describe_ramp_services: '',
    regulatory_auth: '',
    AML_CTF_compliance: '',
    KYC_checks_on_users: '',
    users_screening: '',
    services_to_minors: '',
    consent_info: '',
    consent_OFAC: '',
    consent_region: '',
    consent_region_name: '',
    consent_age: '',
    applicant_email: '',
    applicant_role: '',
    applicant_full_name: '',
    application_date: '',
    ticker: '',
    ticker_name: '',
    ticker_logo: '',
    network: ''
  });
  const [userData, setUserData] = useState([]);

  useEffect(() => {
    if (applicationId !== '') {
      setLoading(true);

      GetApplication(applicationId)
        .then((res) => {
          const application = get(res, 'data.data.data[0]', '');
          setInitialValue({
            legal_entity_name: application.legal_entity_name,
            project_name: application.project_name,
            legal_entity_register_number: application.legal_entity_register_number,
            website: application.website,
            business_category: application.business_category,
            country_of_incorporation: application.country_of_incorporation,
            token_address: application.token_address,
            ticker: application.ticker,
            ticker_name: application.ticker_name,
            product_description: application.product_description,
            product_custodial: application.product_custodial.toString(),
            user_access_to_wallet:
              application.user_access_to_wallet !== null
                ? application.user_access_to_wallet.toString()
                : 'null',
            pre_populate_wallet_address:
              application.pre_populate_wallet_address !== null
                ? application.pre_populate_wallet_address.toString()
                : 'null',
            crypto_to_other_party:
              application.crypto_to_other_party !== null
                ? application.crypto_to_other_party.toString()
                : 'null',
            describe_ramp_services: application.describe_ramp_services,
            regulatory_auth: application.regulatory_authorisation_requirement.toString(),
            users_screening:
              application.sanctions_screening_of_users !== null
                ? application.sanctions_screening_of_users.toString()
                : 'null',
            AML_CTF_compliance:
              application.AML_CTF_compliance !== null
                ? application.AML_CTF_compliance.toString()
                : 'null',
            KYC_checks_on_users:
              application.KYC_checks_on_users !== null
                ? application.KYC_checks_on_users.toString()
                : 'null',
            services_to_minors:
              application.services_to_minors !== null
                ? application.services_to_minors.toString()
                : 'null',
            consent_info: application.consent_info.toString(),
            consent_OFAC:
              application.consent_OFAC !== null
                ? application.consent_OFAC.toString()
                : 'null',
            consent_age:
              application.consent_age !== null
                ? application.consent_age.toString()
                : 'null',
            consent_region: application.consent_region.toString(),
            consent_region_name: application.consent_region_name,
            applicant_email: application.applicant_email,
            applicant_role: application.applicant_role,
            applicant_full_name: application.applicant_full_name,
            application_date: moment(application.application_date).format('YYYY-MM-DD')
          });
          const networkData =
            application.network !== 'undefined' ? application.network : '';
          const setFinalNetworkData = {
            label: networkData,
            value: networkData
          };
          setSelectedFields(setFinalNetworkData);
          setBuyCheck(application.product_enable_buy);
          setSellCheck(application.product_enable_sell);
          setUserData(application.beneficial_ownership);
          setFileUrls({
            whitepapers_doc: application.whitepapers_doc,
            integration_flows_doc: application.integration_flows_doc,
            flow_of_funds_diagram_doc: application.flow_of_funds_diagram_doc,
            corporate_registration_doc: application.corporate_registration_doc,
            terms_of_service_doc: application.terms_of_service_doc,
            privacy_policy_doc: application.privacy_policy_doc,
            AML_CTF_policy_doc: application.AML_CTF_policy_doc,
            licence_doc: application.licence_doc,
            additional_docs: application.additional_docs,
            legal_opinion_doc: application.legal_opinion_doc,
            policy_procedures_doc: application.policy_procedures_doc,
            ticker_logo: application.ticker_logo
          });
          setLoading(false);
          setIsLoaded(true);
        })
        .catch((error) => {
          console.log('error :', error);
          setLoading(false);
          setIsLoaded(true);
          const errorMessage = get(error, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));

        setAllNetworks(setFinalData);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, []);

  const csvExportData = () => {
    let productEnable = {
      buy: buyCheck,
      sell: sellCheck
    };
    const data = {
      'Legal Entity Name': initialValue.legal_entity_name,
      'Project Name': initialValue.project_name,
      'Business Category': initialValue.business_category,
      'Legal Entity Registration No': initialValue.legal_entity_register_number,
      Website: initialValue.website,
      'Country of Incorporation': initialValue.country_of_incorporation,
      'Products Enabled': JSON.stringify(productEnable),
      'Token Address if exists': initialValue.token_address,
      'Product Description': initialValue.product_description,
      product_custodial: initialValue.product_custodial,
      user_access_to_wallet: initialValue.user_access_to_wallet,
      Whitepapers: fileUrls.whitepapers_doc ? fileUrls.whitepapers_doc : '',
      'Integration Flows': fileUrls.integration_flows_doc
        ? fileUrls.integration_flows_doc
        : '',
      'Flow of Funds Diagram': fileUrls.flow_of_funds_diagram_doc
        ? fileUrls.flow_of_funds_diagram_doc
        : '',
      pre_populate_wallet_address: initialValue.pre_populate_wallet_address,
      crypto_to_other_party: initialValue.crypto_to_other_party,
      describe_ramp_services: initialValue.describe_ramp_services,
      regulatory_auth: initialValue.regulatory_auth,
      AML_CTF_compliance: initialValue.AML_CTF_compliance,
      KYC_checks_on_users: initialValue.KYC_checks_on_users,
      users_screening: initialValue.users_screening,
      'Corporate Registration Documents': fileUrls.corporate_registration_doc
        ? fileUrls.corporate_registration_doc
        : '',
      'Terms of Service': fileUrls.terms_of_service_doc
        ? fileUrls.terms_of_service_doc
        : '',
      'Privacy Policy': fileUrls.privacy_policy_doc ? fileUrls.privacy_policy_doc : '',
      'AML / CTF Compliance': fileUrls.AML_CTF_policy_doc
        ? fileUrls.AML_CTF_policy_doc
        : '',
      'Copy of License': fileUrls.licence_doc ? fileUrls.licence_doc : '',
      'Additional Documents': fileUrls.additional_docs ? fileUrls.additional_docs : '',
      services_to_minors: initialValue.services_to_minors,
      'Legal Opinion': fileUrls.legal_opinion_doc ? fileUrls.legal_opinion_doc : '',
      'Policies / Procedures': fileUrls.policy_procedures_doc
        ? fileUrls.policy_procedures_doc
        : '',
      consent_info: initialValue.consent_info,
      consent_OFAC: initialValue.consent_OFAC,
      consent_region: initialValue.consent_region,
      consent_region_name: initialValue.consent_region_name,
      consent_age: initialValue.consent_age,
      applicant_full_name: initialValue.applicant_full_name,
      applicant_email: initialValue.applicant_email,
      applicant_role: initialValue.applicant_role,
      application_date: initialValue.application_date,
      userData: JSON.stringify(userData)
    };

    const newRow = {
      Fields: 'Value'
    };

    const csvContent = Object.keys(data)
      .map((key) => `"${key}","${data[key]}"`)
      .join('\n');

    const newCsvContent = Object.keys(newRow)
      .map((key) => `"${key}","${newRow[key]}"`)
      .join('\n');

    const finalCsvContent = `${newCsvContent}\n${csvContent}`;
    const encodedURI = encodeURI(`data:text/csv;charset=utf-8,${finalCsvContent}`);
    const link = document.createElement('a');
    link.setAttribute('href', encodedURI);
    link.setAttribute('download', `${applicationId}.csv`);
    document.body.appendChild(link);
    link.click();
  };

  return (
    <React.Fragment>
      <div className='p-4'>
        {!isLoaded ? (
          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
            <i
              className='fas fa-circle-notch fa-spin text-muted'
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <Container className=''>
            <div>
              <Formik enableReinitialize={true} initialValues={initialValue}>
                {({ errors, touched, values, handleChange }) => (
                  <Form>
                    <CustomHeaders headerName='General Application' />

                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='legal_entity'
                                className='text-black font-weight-normal'>
                                {CONST.LEGAL_ENTITY}
                              </Label>
                            </div>

                            <div>
                              <AuthInput
                                type='text'
                                className='font-size-12'
                                name='legal_entity_name'
                                disabledField={true}
                                values={values}
                                handleChange={handleChange}
                                placeholder={CONST.ENTER_LEGAL_ENTITY}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='project_name'
                                className='text-black font-weight-normal'>
                                {CONST.PROJECT_NAME}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                className='font-size-12'
                                name='project_name'
                                disabledField={true}
                                placeholder={CONST.ENTER_PROJECT_NAME}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='business_category'
                                className='text-black font-weight-normal'>
                                {CONST.CATEGORY}
                              </Label>
                            </div>
                            <div>
                              <Input
                                type='select'
                                name='business_category'
                                value={values['business_category']}
                                disabled
                                className={
                                  errors['business_category']
                                    ? 'border-danger font-size-12 pr-2'
                                    : 'border-dark font-size-12 pr-2'
                                }
                                onChange={handleChange}>
                                <option hidden>Choose Category</option>
                                <option value='Blockchain'>Blockchain</option>
                                <option value='NFT'>NFT</option>
                                <option value='CeFi'>CeFi</option>
                                <option value='DeFi'>DeFi</option>
                                <option value='Gaming'>Gaming</option>
                                <option value='Metaverse'>Metaverse</option>
                                <option value='DAO'>DAO</option>
                                <option value='Ecommerce'>Ecommerce</option>
                                <option value='Enterprise'>Enterprise</option>
                                <option value='Infrastructure'>Infrastructure</option>
                                <option value='Wallet'>Wallet</option>
                                <option value='Other'>Other</option>
                              </Input>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='legal_entity_register_number'
                                className='text-black font-weight-normal'>
                                {CONST.REGISTRATION_NO}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                name='legal_entity_register_number'
                                disabledField={true}
                                className='font-size-12'
                                placeholder={CONST.ENTER_REGISTRATION_NO}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12 '>
                              <Label
                                htmlFor='website'
                                className='text-black font-weight-normal'>
                                {CONST.WEBSITE}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                name='website'
                                placeholder={CONST.ENTER_PROJECT_WEBSITE}
                                className='font-size-12'
                                disabledField={true}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='country'
                                className='text-blac font-weight-normal'>
                                {CONST.COUNTRY}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                className='font-size-12'
                                name='country_of_incorporation'
                                disabledField={true}
                                placeholder={CONST.ENTER_COUNTRY}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='products'
                                className='text-blac font-weight-normal'>
                                {CONST.PRODUCTS}
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='custom-control form-check custom-checkbox mr-3'>
                                <Input
                                  name='product_buy_enable'
                                  type='checkbox'
                                  onChange={() => false}
                                  checked={buyCheck}
                                  value={buyCheck}
                                  className='form-check-input font-size-12'
                                />
                                <Label
                                  className='form-check-label font-size-12'
                                  onClick={() => {
                                    return false;
                                  }}>
                                  {CONST.BUY}
                                </Label>
                              </div>
                              <div className='custom-control form-check custom-checkbox'>
                                <Input
                                  name='product_sell_enable'
                                  type='checkbox'
                                  value={sellCheck}
                                  className='form-check-input'
                                  onChange={() => false}
                                  checked={sellCheck}
                                />
                                <Label
                                  className='form-check-label font-size-12'
                                  onClick={() => {
                                    return false;
                                  }}>
                                  {CONST.SELL}
                                </Label>
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='tokens'
                                className='text-blac font-weight-normal'>
                                {CONST.TOKEN}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                name='token_address'
                                className='font-size-12'
                                disabledField={true}
                                placeholder={CONST.ENTER_TOKEN_ADDRESS_NAME}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='network'
                                className='text-blac font-weight-normal'>
                                {CONST.NETWORK}
                              </Label>
                            </div>
                            <div>
                              <Select
                                isClearable={false}
                                isSearchable={true}
                                className='font-size-12'
                                name='network'
                                options={allNetworks}
                                value={selectedFields}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='description'
                                className='text-blac font-weight-normal'>
                                {CONST.DESCRIPTION}
                              </Label>
                            </div>
                            <AuthInput
                              type='textarea'
                              name='product_description'
                              disabledField={true}
                              placeholder={CONST.ENTER_DESCRIPTION}
                              className={'border-dark font-size-12'}
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='ticker'
                                className='text-black font-weight-normal'>
                                {CONST.TICKER}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                name='ticker'
                                values={values}
                                disabledField={true}
                                placeholder={CONST.ENTER_TICKER}
                                handleChange={handleChange}
                                className={'border-dark font-size-12'}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='ticker_name'
                                className='text-black font-weight-normal'>
                                {CONST.TICKER_NAME}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                name='ticker_name'
                                values={values}
                                placeholder={CONST.TICKER_NAME}
                                disabledField={true}
                                handleChange={handleChange}
                                className={'border-dark font-size-12'}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor={'whitepapers'}
                                id='ticker_logo'
                                className='text-black font-weight-normal'>
                                Ticker Logo
                              </Label>
                            </div>
                            <div
                              style={{
                                borderRadius: '8px',
                                border: '1px solid',
                                height: 47
                              }}
                              className='d-flex direction-row justify-content-between align-items-center pl-1'>
                              <Label htmlFor='whitepapers' id='ticker_logo'>
                                <span
                                  className='p-1 mb-1 rounded-circle mt-2'
                                  style={{
                                    color: '#134a87',
                                    fontSize: 15,
                                    cursor: 'pointer'
                                  }}>
                                  <img
                                    src={
                                      fileUrls['ticker_logo']
                                        ? documentUploadedIcon
                                        : documentUploadIcon
                                    }
                                    alt='Whitepapers'></img>
                                </span>
                                <span className='font-size-12'>{CONST.ICON}</span>
                              </Label>
                              <div>
                                {fileUrls.ticker_logo && (
                                  <a
                                    href={fileUrls.ticker_logo}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                    View File
                                  </a>
                                )}
                              </div>

                              {/* <ReactTooltip
                          anchorId="whitepapers_doc"
                          place="bottom"
                          content={
                            optionalFile['whitepapers_doc'] &&
                            optionalFile['whitepapers_doc'].name
                          }
                        /> */}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <hr />
                    <CustomHeaders headerName='Beneficial Ownership' />
                    <div className='mt-4'>
                      <Container className='pt-4 pt-md-1 p-0'>
                        {userData && (
                          <div className=' align-items-center'>
                            <div className='col-12 col-sm-auto font-size-16 font-weight-medium '>
                              <div className='table-responsive'>
                                <table className='table table-centered table-hover'>
                                  <thead>
                                    <tr className='font-size-12 text-muted'>
                                      <th className='pt-3 pb-4 border-0'>Full Name</th>
                                      <th className='pt-3 pb-4 border-0'>
                                        Date Of Birth
                                      </th>
                                      <th className='pt-3 pb-4 border-0'>Country</th>
                                      <th className='pt-3 pb-4 border-0'>% Ownership</th>
                                      <th className='pt-3 pb-4 border-0'>Role</th>
                                      <th className='pt-3 pb-4 border-0'>
                                        Identity Documents
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {userData && userData.length > 0 ? (
                                      userData.map((user, key) => (
                                        <tr
                                          key={key}
                                          className='font-size-12 font-weight-medium'>
                                          <td className='pl-3'>{user.full_name}</td>

                                          <td className='pl-3'>
                                            {moment(user.date_of_birth).format(
                                              'DD/MM/YYYY'
                                            )}
                                          </td>
                                          <td className='pl-'>{user.country}</td>
                                          <td className='pl-'>
                                            {user.ownership_percent}
                                          </td>
                                          <td className='pl-'>{user.role}</td>
                                          <td className='pl-'>
                                            <a
                                              href={user.id_doc}
                                              target='_blank'
                                              rel='noopener noreferrer'>
                                              View Doc
                                            </a>
                                          </td>
                                        </tr>
                                      ))
                                    ) : (
                                      <tr>
                                        <td colSpan={8} className='center-text'>
                                          No user found
                                        </td>
                                      </tr>
                                    )}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        )}
                      </Container>
                    </div>

                    <hr />
                    <CustomHeaders headerName='Wallet and Fund Flow Details' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='product_custodial'
                                className='text-black font-weight-normal'>
                                {CONST.PRODUCT_CUSTODIAL_OR_NOT}
                              </Label>
                            </div>
                            <div
                              className='d-flex direction-row'
                              role='group'
                              aria-labelledby='my-radio-group'>
                              <div className='d-flex align-items-center mr-4'>
                                <Field
                                  name='product_custodial'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{
                                    height: '1.725rem',
                                    width: '1rem'
                                  }}
                                />
                                <Label className='mt-2 ml-2 font-size-12'>
                                  {CONST.CUSTODIAL}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4'>
                                <Field
                                  name='product_custodial'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='mt-2 ml-2 font-size-12'>
                                  {CONST.NON_CUSTODIAL}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['product_custodial'] &&
                                  touched['product_custodial'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger pl-4'>
                                      {errors['product_custodial']}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='user_access_to_wallet'
                                className='text-black font-weight-normal'>
                                {CONST.ACCESS_CONTROL_FUNDS}
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='d-flex align-items-center  mr-4'>
                                <Field
                                  name='user_access_to_wallet'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 mt-2 font-size-12'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='user_access_to_wallet'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2  mt-2 font-size-12'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center  ml-4 mr-4'>
                                <Field
                                  name='user_access_to_wallet'
                                  type='radio'
                                  value='null'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2  mt-2 font-size-12'>
                                  {CONST.NA}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['user_access_to_wallet'] &&
                                  touched['user_access_to_wallet'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger'>
                                      {errors['user_access_to_wallet']}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='pre_populate_wallet_address'
                                className='text-black font-weight-normal'>
                                Are you planning to auto-fill wallet addresses during
                                integration?
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='d-flex align-items-center  mr-4'>
                                <Field
                                  name='pre_populate_wallet_address'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 mt-2'>{CONST.YES}</Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='pre_populate_wallet_address'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center  ml-4 mr-4'>
                                <Field
                                  name='pre_populate_wallet_address'
                                  type='radio'
                                  value='null'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NA}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['pre_populate_wallet_address'] &&
                                  touched['pre_populate_wallet_address'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger'>
                                      {errors['pre_populate_wallet_address']}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='crypto_to_other_party'
                                className='text-black font-weight-normal'>
                                Will the cryptocurrency be directed somewhere other than
                                the user's designated wallet?
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='d-flex align-items-center  mr-4'>
                                <Field
                                  name='crypto_to_other_party'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='crypto_to_other_party'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center  ml-4 mr-4'>
                                <Field
                                  name='crypto_to_other_party'
                                  type='radio'
                                  value='null'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NA}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['crypto_to_other_party'] &&
                                  touched['crypto_to_other_party'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger'>
                                      {errors['crypto_to_other_party']}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='describe_ramp_services'
                                className='text-black font-weight-normal'>
                                Describe how you will be integrating an onramp
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='textarea'
                                name='describe_ramp_services'
                                rows='6'
                                className={'border-dark font-size-12'}
                                placeholder={CONST.ENTER_DESCRIPTION}
                                disabledField={true}
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='description'
                                className='text-black font-weight-normal'>
                                Optional
                              </Label>
                            </div>
                            <div
                              style={{
                                borderRadius: '8px',
                                border: '1px solid',
                                height: 47
                              }}
                              className='d-flex cursor-pointer direction-row justify-content-between align-items-center pl-1'>
                              <Label htmlFor='whitepapers' id='whitepapers_doc'>
                                <span
                                  className='p-1 mb-2 rounded-circle'
                                  style={{
                                    color: '#134a87',
                                    fontSize: 15,
                                    cursor: 'pointer'
                                  }}>
                                  <img
                                    src={documentUploadIcon}
                                    className='mt-2'
                                    alt='Whitepapers'></img>
                                </span>
                                <span className='font-size-12 mt-2'>Whitepapers</span>
                              </Label>
                              <div>
                                {fileUrls.whitepapers_doc && (
                                  <a
                                    href={fileUrls.whitepapers_doc}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                    View File
                                  </a>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-2 mt-2'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor={'integration_flow'}
                                id='integration_flows_doc'
                                className='text-black font-weight-normal'></Label>
                            </div>
                            <div
                              style={{
                                borderRadius: '8px',
                                border: '1px solid',
                                height: 47
                              }}
                              className='d-flex cursor-pointer direction-row justify-content-between align-items-center pl-1'>
                              <Label
                                htmlFor='integration_flow'
                                id='integration_flows_doc'>
                                <span
                                  className='p-1 mb-2 rounded-circle'
                                  style={{
                                    color: '#134a87',
                                    fontSize: 15,
                                    cursor: 'pointer'
                                  }}>
                                  <img
                                    src={documentUploadIcon}
                                    alt='IntegrationFlows'
                                    className='mt-2'></img>
                                </span>
                                <span className='font-size-12 mt-2'>
                                  Integration Flows
                                </span>
                              </Label>
                              <div>
                                {fileUrls.integration_flows_doc && (
                                  <a
                                    href={fileUrls.integration_flows_doc}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                    View File
                                  </a>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-3'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor={'flow_diagram'}
                                id='flow_of_funds_diagram_doc'
                                className='text-black font-weight-normal'></Label>
                            </div>
                            <div
                              style={{
                                borderRadius: '8px',
                                border: '1px solid',
                                height: 47
                              }}
                              className='d-flex cursor-pointer direction-row justify-content-between align-items-center pl-1'>
                              <Label
                                htmlFor='flow_diagram'
                                id='flow_of_funds_diagram_doc'>
                                <span
                                  className='p-1 mb-2 rounded-circle'
                                  style={{
                                    color: '#134a87',
                                    fontSize: 15,
                                    cursor: 'pointer'
                                  }}>
                                  <img
                                    src={documentUploadIcon}
                                    className='mt-2'
                                    alt='FlowOfFundsDiagram'></img>
                                </span>
                                <span className='font-size-12 mt-2'>
                                  Flow of Funds Diagram
                                </span>
                              </Label>
                              <div>
                                {fileUrls.flow_of_funds_diagram_doc && (
                                  <a
                                    href={fileUrls.flow_of_funds_diagram_doc}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                    View File
                                  </a>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <hr />
                    <CustomHeaders headerName='Partner Entity Compliance and Documentation' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='regulatory_auth'
                                className='text-black font-weight-normal'>
                                Is the partner entity subject to any regulatory
                                authorization or licensing requirements?
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='d-flex align-items-center mr-4'>
                                <Field
                                  name='regulatory_auth'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center  ml-4'>
                                <Field
                                  name='regulatory_auth'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['regulatory_auth'] &&
                                  touched['regulatory_auth'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger pl-4'>
                                      {errors['regulatory_auth']}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='AML_CTF_compliance'
                                className='text-black font-weight-normal'>
                                Does the partner entity maintain an AML/CTF compliance
                                program?
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='d-flex align-items-center mr-4'>
                                <Field
                                  name='AML_CTF_compliance'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='AML_CTF_compliance'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='AML_CTF_compliance'
                                  type='radio'
                                  value='null'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NA}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['AML_CTF_compliance'] &&
                                  touched['AML_CTF_compliance'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger pl-4'>
                                      {errors['AML_CTF_compliance']}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='KYC_checks_on_users'
                                className='text-black font-weight-normal'>
                                Does the partner entity perform KYC checks on its users?
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='d-flex align-items-center mr-4'>
                                <Field
                                  name='KYC_checks_on_users'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='KYC_checks_on_users'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='KYC_checks_on_users'
                                  type='radio'
                                  value='null'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NA}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['KYC_checks_on_users'] &&
                                  touched['KYC_checks_on_users'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger pl-4'>
                                      {errors['KYC_checks_on_users']}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='users_screening'
                                className='text-black font-weight-normal'>
                                Does the partner entity perform sanctions screening of its
                                users?
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='d-flex align-items-center mr-4'>
                                <Field
                                  name='users_screening'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='users_screening'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='users_screening'
                                  type='radio'
                                  value='null'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NA}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['users_screening'] &&
                                  touched['users_screening'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger pl-4'>
                                      {errors['users_screening']}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='services_to_minors'
                                className='text-black font-weight-normal'>
                                Does the partner entity perform sanctions screening of its
                                users?
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='d-flex align-items-center mr-4'>
                                <Field
                                  name='services_to_minors'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='services_to_minors'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center ml-4 mr-4'>
                                <Field
                                  name='services_to_minors'
                                  type='radio'
                                  value='null'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='ml-2 font-size-12 mt-2'>
                                  {CONST.NA}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['services_to_minors'] &&
                                  touched['services_to_minors'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger pl-4'>
                                      {errors['services_to_minors']}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-2 font-size-12'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='description'
                                className='text-black font-weight-normal'>
                                Required
                              </Label>
                            </div>
                            <div
                              className='pt-2 d-flex justify-content-between font-size-12'
                              style={{
                                flexDirection: 'column',
                                borderRadius: '8px',
                                border: '1px solid'
                              }}>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='corporate_registration_doc'
                                  id='registration_doc'
                                  className='cursor-pointer'>
                                  <span
                                    className='p-1 mb-1 rounded-circle'
                                    style={{
                                      color: '#134a87',
                                      fontSize: 15,
                                      cursor: 'pointer'
                                    }}>
                                    <img
                                      src={
                                        fileUrls['corporate_registration_doc']
                                          ? documentUploadedIcon
                                          : documentUploadIcon
                                      }
                                      alt='Corporate Registration Documents'></img>
                                  </span>
                                  <span className='font-size-12'>
                                    Corporate Registration Documents
                                  </span>
                                </Label>
                                {fileUrls.corporate_registration_doc && (
                                  <a
                                    href={fileUrls.corporate_registration_doc}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                    View File
                                  </a>
                                )}
                                {!fileUrls['corporate_registration_doc'] && (
                                  <div
                                    style={{ fontSize: 12 }}
                                    className='text-left text-danger pl-4'>
                                    {errors.corporate_registration_doc}
                                  </div>
                                )}
                              </div>
                              <div>
                                <Label
                                  htmlFor='terms_of_service_doc'
                                  id='terms_of_service'
                                  className='cursor-pointer'>
                                  <span
                                    className='p-1 mb-2 rounded-circle'
                                    style={{
                                      color: '#134a87',
                                      fontSize: 14,
                                      cursor: 'pointer'
                                    }}>
                                    <img
                                      src={
                                        fileUrls['terms_of_service_doc']
                                          ? documentUploadedIcon
                                          : documentUploadIcon
                                      }
                                      alt='Terms of Service'></img>
                                  </span>
                                  <span className='font-size-12'>Terms of Service</span>
                                </Label>
                                {fileUrls.terms_of_service_doc && (
                                  <a
                                    href={fileUrls.terms_of_service_doc}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                    View File
                                  </a>
                                )}
                                {!fileUrls['terms_of_service_doc'] && (
                                  <div
                                    style={{ fontSize: 12 }}
                                    className='text-left text-danger pl-4'>
                                    {errors.terms_of_service_doc}
                                  </div>
                                )}
                              </div>
                              <div>
                                <Label
                                  htmlFor='privacy_policy_doc'
                                  id='privacy_policy'
                                  className='cursor-pointer'>
                                  <span
                                    className='p-1 mb-2 rounded-circle'
                                    style={{
                                      color: '#134a87',
                                      fontSize: 14,
                                      cursor: 'pointer'
                                    }}>
                                    <img
                                      src={
                                        fileUrls['privacy_policy_doc']
                                          ? documentUploadedIcon
                                          : documentUploadIcon
                                      }
                                      alt='Privacy Policy'></img>
                                  </span>
                                  <span className='font-size-12'>Privacy Policy</span>
                                </Label>
                                {fileUrls.privacy_policy_doc && (
                                  <a
                                    href={fileUrls.privacy_policy_doc}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                    View File
                                  </a>
                                )}
                                {!fileUrls['privacy_policy_doc'] && (
                                  <div
                                    style={{ fontSize: 12 }}
                                    className='text-left text-danger pl-4'>
                                    {errors.privacy_policy_doc}
                                  </div>
                                )}
                              </div>
                              <div>
                                <Label
                                  htmlFor='AML_CTF_policy_doc'
                                  id='aml_ctf_policy'
                                  className='cursor-pointer'>
                                  <span
                                    className='p-1 mb-2 rounded-circle'
                                    style={{
                                      color: '#134a87',
                                      fontSize: 14,
                                      cursor: 'pointer'
                                    }}>
                                    <img
                                      src={
                                        fileUrls['AML_CTF_policy_doc']
                                          ? documentUploadedIcon
                                          : documentUploadIcon
                                      }
                                      alt='AML CTF Compliance'></img>
                                  </span>
                                  <span className='font-size-12'>
                                    AML / CTF Compliance
                                  </span>
                                </Label>
                                {fileUrls.AML_CTF_policy_doc &&
                                  values.AML_CTF_compliance === 'true' && (
                                    // {fileUrls.AML_CTF_policy_doc && (
                                    <a
                                      href={fileUrls.AML_CTF_policy_doc}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                      View File
                                    </a>
                                  )}
                                {!fileUrls['AML_CTF_policy_doc'] && (
                                  <div
                                    style={{ fontSize: 12 }}
                                    className='text-left text-danger'>
                                    {errors.AML_CTF_policy_doc}
                                  </div>
                                )}
                              </div>
                              <div>
                                <Label
                                  htmlFor='licence_doc'
                                  id='licence'
                                  className='cursor-pointer'>
                                  <span
                                    className='p-1 mb-2 rounded-circle'
                                    style={{
                                      color: '#134a87',
                                      fontSize: 15,
                                      cursor: 'pointer'
                                    }}>
                                    <img
                                      src={
                                        fileUrls['licence_doc']
                                          ? documentUploadedIcon
                                          : documentUploadIcon
                                      }
                                      alt='Copy of License'></img>
                                  </span>
                                  <span className='font-size-12'>Copy of License</span>
                                </Label>
                                {fileUrls.licence_doc &&
                                  values.regulatory_auth === 'true' && (
                                    <a
                                      href={fileUrls.licence_doc}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                      View File
                                    </a>
                                  )}
                                {!fileUrls['licence_doc'] && (
                                  <div
                                    style={{ fontSize: 12 }}
                                    className='text-left text-danger'>
                                    {errors.licence_doc}
                                  </div>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='description'
                                className='text-black clickable-label font-weight-normal'>
                                Optional Documents
                              </Label>
                            </div>
                            <div
                              className='pt-2 d-flex justify-content-between cursor-pointer font-size-12'
                              style={{
                                flexDirection: 'column',
                                borderRadius: '8px',
                                border: '1px solid'
                              }}>
                              <div>
                                <Label
                                  htmlFor='legal_opinion_doc'
                                  id='legal_opinion'
                                  className='cursor-pointer'>
                                  <span
                                    className='p-1 mb-2 rounded-circle'
                                    style={{
                                      color: '#134a87',
                                      fontSize: 14,
                                      cursor: 'pointer'
                                    }}>
                                    <img
                                      src={
                                        fileUrls['legal_opinion_doc']
                                          ? documentUploadedIcon
                                          : documentUploadIcon
                                      }
                                      alt='Legal Opinion'></img>
                                  </span>
                                  <span className='font-size-12'>Legal Opinion</span>
                                </Label>
                                {fileUrls.legal_opinion_doc && (
                                  <a
                                    href={fileUrls.legal_opinion_doc}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pl-2 font-size-12 cursor-pointer font-weight-bold text-wrap text-primary'>
                                    View File
                                  </a>
                                )}
                              </div>
                              <div>
                                <Label
                                  htmlFor='policy_procedures_doc'
                                  id='policy_procedures'
                                  className='cursor-pointer'>
                                  <span
                                    className='p-1 mb-2 rounded-circle'
                                    style={{
                                      color: '#134a87',
                                      fontSize: 14,
                                      cursor: 'pointer'
                                    }}>
                                    <img
                                      src={
                                        fileUrls['policy_procedures_doc']
                                          ? documentUploadedIcon
                                          : documentUploadIcon
                                      }
                                      alt='PoliciesProcedures'></img>
                                  </span>
                                  Policies / Procedures
                                </Label>
                                {fileUrls.policy_procedures_doc && (
                                  <a
                                    href={fileUrls.policy_procedures_doc}
                                    target='_blank'
                                    rel='noopener noreferrer'
                                    className='pl-2 font-size-12  font-weight-bold text-wrap text-primary'>
                                    View File
                                  </a>
                                )}
                              </div>
                              <div>
                                <Label
                                  htmlFor='additional_doc'
                                  id='additional'
                                  className='cursor-pointer'>
                                  <span
                                    className='p-1 mb-2 rounded-circle'
                                    style={{
                                      color: '#134a87',
                                      fontSize: 15,
                                      cursor: 'pointer'
                                    }}>
                                    <img
                                      src={
                                        fileUrls['additional_docs'] &&
                                        fileUrls['additional_docs'].length > 0
                                          ? documentUploadedIcon
                                          : documentUploadIcon
                                      }
                                      alt='Additional Documents'></img>
                                  </span>
                                  Additional Documents
                                </Label>
                                {fileUrls.additional_docs &&
                                  fileUrls.additional_docs.map((url, index) => {
                                    return (
                                      <div key={index}>
                                        <a
                                          href={url}
                                          target='_blank'
                                          rel='noopener noreferrer'
                                          className='pl-4 font-size-14 font-weight-bold text-wrap text-primary'>
                                          View File
                                        </a>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <hr />
                    <CustomHeaders headerName='Certifications' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='consent_info'
                                className='text-black font-weight-normal'>
                                I hereby certify, to the best of my knowledge, that the
                                information provided in this questionnaire is complete and
                                accurate.
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='custom-control font-size-12 form-check mr-4'>
                                <Field
                                  name='consent_info'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  className='form-check-input'
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='form-check-label ml-2 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='custom-control font-size-12 form-check ml-4'>
                                <Field
                                  name='consent_info'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  className='form-check-input'
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='form-check-label font-size-12 ml-2 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['consent_info'] && touched['consent_info'] && (
                                  <span
                                    style={{ fontSize: 12 }}
                                    className='text-left text-danger pl-4'>
                                    {errors['consent_info']}
                                  </span>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='consent_OFAC'
                                className='text-black font-weight-normal'>
                                I hereby certify that the partner entity does not interact
                                with or provide services to persons or entities subject to
                                sanctions administered by the US Office of Foreign Assets
                                Control ("OFAC") or those imposed by the UK Office of
                                Financial Sanctions Implementation ("OFSI").
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='custom-control form-check  mr-4'>
                                <Field
                                  name='consent_OFAC'
                                  type='radio'
                                  className='form-check-input'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='form-check-label font-size-12 ml-2 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='custom-control form-check ml-4 mr-4'>
                                <Field
                                  name='consent_OFAC'
                                  type='radio'
                                  className='form-check-input'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='form-check-label font-size-12 ml-2 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='custom-control form-check  ml-4 mr-4'>
                                <Field
                                  name='consent_OFAC'
                                  type='radio'
                                  className='form-check-input'
                                  value='null'
                                  onChange={() => {
                                    return false;
                                  }}
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='form-check-label font-size-12 ml-2 mt-2'>
                                  {CONST.NA}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['consent_OFAC'] && touched['consent_OFAC'] && (
                                  <span
                                    style={{ fontSize: 12 }}
                                    className='text-left text-danger pl-4'>
                                    {errors['consent_OFAC']}
                                  </span>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='consent_region'
                                className='text-black font-weight-normal'>
                                I hereby certify that the partner entity does not do
                                business in the following jurisdictions: Cuba, Iran, North
                                Korea, Syria, Crimea region of Ukraine, Donetsk region of
                                Ukraine, Luhansk region of Ukraine, or the Russian
                                Federation
                              </Label>
                            </div>
                            <div className='d-flex direction-row align-items-center'>
                              <div className='custom-control form-check  mr-4'>
                                <Field
                                  name='consent_region'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  className='form-check-input'
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='form-check-label font-size-12 ml-2 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['consent_region'] &&
                                  touched['consent_region'] && (
                                    <span
                                      style={{ fontSize: 12 }}
                                      className='text-left text-danger pl-4'>
                                      {errors['consent_region']}
                                    </span>
                                  )}
                              </div>

                              <div className='custom-control form-check  ml-4 mr-4'>
                                <Label className='form-check-label font-size-12 cursor-pointer mt-2'>
                                  I do business in these regions
                                </Label>
                              </div>
                              <AuthInput
                                name='consent_region_name'
                                type='text'
                                className={'font-size-12'}
                                values={values}
                                handleChange={handleChange}
                                placeholder={CONST.ENTER_REGION}
                                errors={errors}
                                touched={touched}
                              />
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={12} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='consent_age'
                                className='text-black font-weight-normal'>
                                I acknowledge that Rampnalysis and its Associated
                                businesses may not provide services to sanctioned
                                individuals, sanctioned jurisdictions, nor minors
                                (individuals under the age of 18).
                              </Label>
                            </div>
                            <div className='d-flex direction-row'>
                              <div className='custom-control form-check font-size-12 mr-4'>
                                <Field
                                  name='consent_age'
                                  type='radio'
                                  value='true'
                                  onChange={() => {
                                    return false;
                                  }}
                                  className='form-check-input'
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='form-check-label ml-2 mt-2'>
                                  {CONST.YES}
                                </Label>
                              </div>
                              <div className='custom-control form-check font-size-12 ml-4 mr-4'>
                                <Field
                                  name='consent_age'
                                  type='radio'
                                  value='false'
                                  onChange={() => {
                                    return false;
                                  }}
                                  className='form-check-input'
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='form-check-label ml-2 mt-2'>
                                  {CONST.NO}
                                </Label>
                              </div>
                              <div className='custom-control form-check font-size-12 ml-4 mr-4'>
                                <Field
                                  name='consent_age'
                                  type='radio'
                                  value='null'
                                  onChange={() => {
                                    return false;
                                  }}
                                  className='form-check-input'
                                  style={{ height: '1.725rem', width: '1rem' }}
                                />
                                <Label className='form-check-label font-size-12 ml-2 mt-2'>
                                  {CONST.NA}
                                </Label>
                              </div>
                              <div className='d-flex align-items-center'>
                                {errors['consent_age'] && touched['consent_age'] && (
                                  <span
                                    style={{ fontSize: 12 }}
                                    className='text-left text-danger pl-4'>
                                    {errors['consent_age']}
                                  </span>
                                )}
                              </div>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <div className='mt-2 ml-2'>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-6'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='applicant_full_name'
                                className='text-black font-weight-normal'>
                                {CONST.FULL_NAME}
                              </Label>
                            </div>

                            <div>
                              <AuthInput
                                type='text'
                                name='applicant_full_name'
                                values={values}
                                className='font-size-12'
                                handleChange={handleChange}
                                disabledField={true}
                                placeholder={CONST.ENTER_FULL_NAME}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='applicant_email'
                                className='text-black font-weight-normal'>
                                {CONST.EMAIL}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='text'
                                name='applicant_email'
                                className='font-size-12'
                                placeholder={CONST.ENTER_EMAIL}
                                values={values}
                                disabledField={true}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                      <Row>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='applicant_role'
                                className='text-black font-weight-normal'>
                                {CONST.ROLE}
                              </Label>
                            </div>

                            <div>
                              <AuthInput
                                type='text'
                                name='applicant_role'
                                values={values}
                                className='font-size-12'
                                disabledField={true}
                                handleChange={handleChange}
                                placeholder={CONST.ENTER_ROLE}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                        <Col lg={6} md={12} sm={12}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='application_date'
                                className='text-black font-weight-normal'>
                                {CONST.DATE}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                type='date'
                                name='application_date'
                                values={values}
                                className='font-size-12'
                                disabledField={true}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>

                    <div className='d-flex justify-content-center'>
                      <button
                        className='btn btn-auth'
                        type='submit'
                        style={{ width: 200, height: 40 }}
                        onClick={csvExportData}>
                        Export Application
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}

export default ViewOnrampApplication;

const CustomHeaders = ({ headerName }) => {
  return (
    <div className='col-12 col-sm-auto font-size-18 pb-2 pb-sm-0'>
      <span className='text-black'>
        <u>{headerName}</u>
      </span>
    </div>
  );
};
