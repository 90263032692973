import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import get from 'lodash/get';

function formatMessage(message) {
  const regexp = /^(decreased|increased|added|removed|[\d]+)$/gi;
  const tokens = message.split(/\b/gi);
  return (
    <>
      {tokens.map((token, i) => (
        <span key={i}>
          {regexp.test(token) ? <strong>{token}</strong> : <>{token}</>}
        </span>
      ))}
    </>
  );
}

function SummaryNotificationGroup({ title = '', notifications = [] }) {
  const [isOpen, setIsOpen] = useState(false);
  const toggle = () => setIsOpen(!isOpen);

  const count = notifications.reduce(
    (count, provider) => count + get(provider, 'summaryDetail.length', 0),
    0
  );

  return (
    <React.Fragment>
      <div>
        <div className="row align-items-center">
          <div className="col font-size-20 text-muted mt-3 mb-1">
            {title}
            {!!count && (
              <span className="ml-2 badge badge-pill badge-light">{count}</span>
            )}
          </div>
          {!!count && (
            <div className="col-auto">
              <button type="button" class="btn btn-link" onClick={toggle}>
                <i className={'fas fa-chevron-' + (isOpen ? 'down' : 'right')}></i>
              </button>
            </div>
          )}
        </div>
        <Collapse isOpen={isOpen}>
          {notifications.map((provider) => (
            <div key={provider.provider}>
              {get(provider, 'summaryDetail', []).map((notification, i) => (
                <div
                  key={i}
                  className="d-flex align-items-center"
                  style={{
                    backgroundColor: i % 2 ? 'rgba(148, 197, 255, 0.16)' : '#fff'
                  }}>
                  <div className="d-flex w-100 align-items-center border-bottom p-4">
                    {!!provider.logo && (
                      <div className="pr-4">
                        <img
                          src={provider.logo}
                          alt={provider.provider}
                          width={80}
                          style={{ maxHeight: '80px' }}
                          className="rounded-circle"
                        />
                      </div>
                    )}
                    <div className="flex-fill font-size-16 font-weight-medium mr-1">
                      <div className="row align-items-center">
                        <div className="col-12 col-lg font-size-24 wont-weight-medium mb-1 text-capitalize">
                          {provider.provider}
                        </div>
                        <div className="col-12 col-lg-auto font-size-16 text-muted mb-1">
                          {new Intl.DateTimeFormat('en-GB', {
                            dateStyle: 'full'
                          }).format(new Date(notification.timestamp))}
                        </div>
                      </div>
                      <div className="font-size-16">
                        {formatMessage(notification.message)}
                      </div>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </Collapse>
      </div>
    </React.Fragment>
  );
}

export default SummaryNotificationGroup;
