import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import SimpleBar from 'simplebar-react';
import { SizeMe } from 'react-sizeme';
import get from 'lodash/get';
import { GetNews } from '../../services/Business/News';

function News({
  fetchNews = () => Promise.resolve([]),
  onLoad = () => {},
  className,
  search
}) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [news, setNews] = useState([]);

  useEffect(() => {
    GetNews({ search })
      .then((data) => {
        setIsLoaded(true);
        onLoad();
        setNews(get(data, 'data', []));
        return data;
      })
      .catch((err) => {
        setIsLoaded(true);
        onLoad();
      });
  }, [search]);

  return !isLoaded ? (
    <div
      className={
        'card d-flex align-items-center justify-content-center py-5 ' + className
      }>
      <i className="fas fa-circle-notch fa-spin text-muted" style={{ fontSize: 48 }}></i>
    </div>
  ) : (
    <div className={'p-4 card  ' + className}>
      <div className="d-flex flex-wrap align-items-center">
        <div className="font-size-24 font-weight-medium text-dark flex-grow-1">
          Updates/News
        </div>
        <div className="font-size-16">
          <Link to={'/news?search=' + encodeURIComponent(search)} className="text-reset">
            Read all News
            <i className="fas fa-chevron-right ml-3"></i>
          </Link>
        </div>
      </div>

      <SizeMe monitorHeight>
        {({ size }) => (
          <div className="flex-grow-1 position-relative">
            {get(news, 'length', 0) > 0 ? (
              <SimpleBar
                className="position-absolute position-cover"
                style={{
                  maxHeight: size.height
                }}>
                {news.map((item, i) => (
                  <div key={i} className="row no-gutters py-3 mb-1">
                    <div className="col-auto">
                      <img
                        src={item.urlToImage || item.image_url}
                        alt=""
                        width="122"
                        style={{ maxHeight: '122px', borderRadius: '1rem' }}
                        className="mr-4"
                      />
                    </div>
                    <div className="col flex-grow-1 text-truncate">
                      <div className="font-size-14 text-muted mb-1">
                        {new Intl.DateTimeFormat('en-GB', {
                          dateStyle: 'long'
                        }).format(new Date(item.publishedAt || item.date || Date.now))}
                      </div>
                      <a
                        href={item.url || item.news_url}
                        className="font-size-18 font-weight-bold text-wrap mb-1">
                        {item.title}
                      </a>
                      <div className="font-size-14 text-muted text-truncate">
                        {item.description || item.text}
                      </div>
                    </div>
                  </div>
                ))}
              </SimpleBar>
            ) : (
              <div className="h-100 d-flex align-items-center justify-content-center">
                <span className="text-muted font-size-16 ">No news available</span>
              </div>
            )}
          </div>
        )}
      </SizeMe>
    </div>
  );
}

export default News;
