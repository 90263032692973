export const constants = {
  REGISTER_SUCCESSFULLY: 'Your email is registered successfully',
  VERIFICATION_SUCCESSFULLY: 'Your account verified successfully',
  PROFILE_UPDATED_SUCCESSFULLY: 'Your profile details updated successfully',
  API_KEY_SUCCESSFULLY: 'Your api key generated successfully',
  API_KEY_DELETE_SUCCESSFULLY: 'Your api key deleted successfully',
  API_KEY_COPIED: 'Your api key copied successfully',
  SECRET_KEY_COPIED: 'Your secret key copied successfully',
  APPLICATION_SETTING: 'Application Updated successfully'
};
