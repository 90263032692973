import React, { useCallback, useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { uploadFiles } from './ImageUploadCommon';
import { extractFromAddress } from '../BusinessDashboard/WidgetOnboardingSteps/BGImages';
import { giphyLogo } from '../BusinessDashboard/WidgetOnboardingSteps/Giphy';
import GifModal, { ShowGiphy } from '../BusinessDashboard/WidgetOnboardingSteps/GifModal';

function SwapLoadingComp({
  isUpdate,
  setIsUpdate,
  swapLoadingGifFiles,
  setSwapLoadingGifFiles,
  funModeSwapLoadingGifs,
  setFunModeSwapLoadingGifs,
  ReactTooltip
}) {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedGiphy, setSelectedGiphy] = useState([]);
  const [openGIFModal, setOpenGIFModal] = useState(false);

  const onDrop = useCallback(
    async (acceptedFiles) => {
      const newFiles = acceptedFiles.slice(0, 10 - swapLoadingGifFiles.length);
      const filteredFiles = await Promise.all(
        newFiles.map(async (file) => {
          return file;
        })
      );
      setSwapLoadingGifFiles((prevFiles) => [
        ...prevFiles,
        ...filteredFiles.filter(Boolean)
      ]);
    },
    [swapLoadingGifFiles.length, setSwapLoadingGifFiles]
  );

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: 'image/*',
    multiple: true
  });

  const removeLoadingGifsImage = (index) => {
    const updatedFiles = [...swapLoadingGifFiles];
    updatedFiles.splice(index, 1);
    setSwapLoadingGifFiles(updatedFiles);
  };

  const removeLoadingGiphyImage = (index) => {
    const updatedFiles = [...selectedGiphy];
    updatedFiles.splice(index, 1);
    setSelectedGiphy(updatedFiles);
  };

  const uploadLoadingScreenGIFsFiles = async () => {
    setIsLoading(true);
    await uploadFiles({
      filesArr: swapLoadingGifFiles,
      setState: setFunModeSwapLoadingGifs,
      selectedGiphy
    });
    setIsLoading(false);
    setIsUpdate(!isUpdate);
  };

  useEffect(() => {
    if (funModeSwapLoadingGifs && funModeSwapLoadingGifs.length > 0) {
      setSelectedGiphy([]);
    }
  }, [funModeSwapLoadingGifs]);
  return (
    <div>
      <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
        Loading Screen Meme Gif{' '}
        <i
          data-tooltip-id='swap-loading-screen'
          data-tip
          data-for='swap-loading-screen'
          className='font-size-14 mdi mdi-information-outline ml-2'
        />
        <ReactTooltip
          id='swap-loading-screen'
          place='top'
          effect='solid'
          content='Shows a randomly picked GIF for the during swap/stake loading screen.'
          style={{ width: '200px', fontSize: '12px' }}
          delayHide={300}
        />
      </p>
      <div className='d-flex justify-content-start'>
        <div>
          <div className='d-flex flex-row'>
            <div className='file-upload-form'>
              <input {...getInputProps()} />
              <label
                className={`font-size-12 p-2 file-upload-label ${
                  isDragActive ? 'file-upload-drag-active' : ''
                }`}>
                <div className='file-upload-design'>
                  <div class='file-upload-design'>
                    <span className='browse-button' {...getRootProps()}>
                      Browse file
                    </span>{' '}
                    <p>or</p>
                    <p onClick={() => setOpenGIFModal(true)}>{giphyLogo} Search Gifs</p>
                  </div>
                </div>
              </label>
            </div>
            <div className='d-flex align-items-center'>
              <button
                name='btn'
                className={`btn btn-auth ml-2`}
                style={{ width: 120 }}
                onClick={uploadLoadingScreenGIFsFiles}>
                {isLoading ? 'Loading...' : 'Set Images'}
              </button>
            </div>
          </div>
          {openGIFModal && (
            <GifModal
              onClose={() => setOpenGIFModal(false)}
              defaultSearch='Loading Crypto'
              setSelectedGiphy={setSelectedGiphy}
              selectedGiphy={selectedGiphy}
            />
          )}
          {swapLoadingGifFiles.map((file, index) => (
            <div key={index} className='d-flex align-items-center mt-2'>
              <div>
                <img
                  src={typeof file === 'string' ? file : URL.createObjectURL(file)}
                  alt={`Preview ${file.name}`}
                  style={{
                    width: '15px',
                    height: '15px',
                    marginRight: '10px'
                  }}
                />
              </div>
              <span className='font-size-14'>
                {extractFromAddress(typeof file === 'string' ? file : file.name)}
              </span>
              <span
                className='ml-2 text-danger cursor-pointer'
                onClick={() => removeLoadingGifsImage(index)}>
                x
              </span>
            </div>
          ))}

          <ShowGiphy selectedGiphy={selectedGiphy} removeGif={removeLoadingGiphyImage} />
        </div>
      </div>
    </div>
  );
}

export default SwapLoadingComp;
