import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import get from 'lodash/get';
import ReactTags from 'react-tag-autocomplete';
import { stringify } from 'query-string';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { AddOnRampTrial, GetTotalCoverage } from '../../services/Business/Crypto';
import { GetAllProviders } from '../../services/Providers/AdminProviders';
import { ReactComponent as InfoCircleIcon } from '../../assets/images/icons/info-circle.svg';
import TableSortButton from '../../components/TableSortButton';

const perPage = 50;

function AllCryptosPage({ history }) {
  const [allCryptos, setAllCryptos] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [assetType, setAssetType] = useState('crypto');
  const [order, setOrder] = useState(null);
  const [allProviders, setAllProviders] = useState([]);
  const [tags, setTags] = useState([]);
  // const accessError = useRef();
  useEffect(() => {
    GetAllProviders()
      .then((res) => {
        setAllProviders(get(res, 'data.data.data', []));
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    const providers = tags.map(({ id }) => id).join(',');
    GetTotalCoverage({ page, limit: perPage, order, providers })
      .then((res) => {
        setIsLoaded(true);
        setIsLoading(false);
        const data = get(res, 'data.data');
        const newData = {
          ...data,
          page,
          limit: perPage,
          order,
          providers
        };
        // check pagination

        if (
          get(allCryptos, 'data.length', 0) > 0 &&
          order === allCryptos.order &&
          providers === allCryptos.providers
        ) {
          newData.data = [...allCryptos.data, ...data.data];
        }
        setAllCryptos(newData);
        return newData;
      })
      .catch((err) => {
        setIsLoaded(true);
        setIsLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, [page, order, tags]);

  const suggestions = allProviders.map(({ reference_name, provider_name }) => ({
    id: reference_name,
    name: provider_name
  }));

  const onDelete = (index) => {
    setTags(tags.filter((t, i) => i !== index));
  };

  const onAddition = (tag) => {
    setTags([...tags, tag]);
  };

  return (
    <React.Fragment>
      <div className={'page-content ' + (!isLoaded && 'page-content-loading')}>
        {!isLoaded ? (
          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
            <i
              className='fas fa-circle-notch fa-spin text-muted'
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <>
            {allCryptos && (
              <div className='card'>
                <div className='p-4'>
                  <div className='row align-items-center'>
                    <div className='col-12 col-sm-auto font-size-24 font-weight-medium pb-2 pb-sm-0'>
                      <span className='text-black'>All Cryptocurrencies</span>

                      <span className='ml-1 badge badge-pill badge-light'>
                        {allCryptos.count}
                      </span>
                    </div>
                    <div className='col-12 col-sm'>
                      {isLoading && (
                        <div className='ml-4 d-inline-block text-muted font-size-20'>
                          <i className='fas fa-circle-notch fa-spin mr-2'></i>
                          <span>Loading...</span>
                        </div>
                      )}
                    </div>
                    <div className='col-12 col-sm-auto d-flex font-size-16 text-black'>
                      <a
                        href='!#'
                        className={
                          'text-black ' +
                          (assetType === 'crypto'
                            ? 'font-weight-bolder'
                            : 'font-weight-medium')
                        }
                        onClick={(evt) => {
                          evt.preventDefault();
                          setAssetType('crypto');
                        }}>
                        Crypto
                      </a>
                      <a
                        href='!#'
                        className={
                          'text-black mx-2 ' +
                          (assetType === 'fiat'
                            ? 'font-weight-bolder'
                            : 'font-weight-medium')
                        }
                        onClick={(evt) => {
                          evt.preventDefault();
                          setAssetType('fiat');
                        }}>
                        Fiat
                      </a>
                    </div>
                  </div>
                </div>
                <div className='mb-3 px-4'>
                  <ReactTags
                    placeholderText='Select provider'
                    tags={tags}
                    suggestions={suggestions}
                    onDelete={onDelete}
                    onAddition={onAddition}
                  />
                </div>

                {get(allCryptos, 'data.length', 0) > 0 && (
                  <div className='table-responsive'>
                    <table className='table table-centered table-hover'>
                      <thead>
                        <tr className='font-size-16 text-muted'>
                          <th className='pl-4 pt-3 pb-4 border-0'>#</th>
                          <th className='pt-3 pb-4 border-0'>Collection</th>
                          <th className='pt-3 pb-4 border-0'>
                            <div className='d-flex align-items-center'>
                              <span>Market Cap</span>
                              <TableSortButton
                                className='ml-1'
                                value={order}
                                topValue='market_cap_desc'
                                bottomValue='market_cap_asc'
                                onChange={(v) => {
                                  setPage(1);
                                  setOrder(v);
                                }}
                              />
                            </div>
                          </th>
                          <th className='pt-3 pb-4 border-0'>
                            Market Cap(24h)
                            <InfoCircleIcon alt='' className='ml-2' height={25} />
                          </th>
                          <th className='pt-3 pb-4 border-0'>
                            <div className='d-flex align-items-center'>
                              <span>Volume(24h)</span>
                              <TableSortButton
                                className='ml-1'
                                value={order}
                                topValue='total_volume_desc'
                                bottomValue='total_volume_asc'
                                onChange={(v) => {
                                  setPage(1);
                                  setOrder(v);
                                }}
                              />
                            </div>
                          </th>
                          <th className='pr-4 pt-3 pb-4 border-0'>Volume(24h change)</th>
                          <th className='pr-4 pt-3 pb-4 border-0'>Providers Available</th>
                          <th className='pt-3 pb-4 border-0'>
                            Floor Price(7d)
                            <InfoCircleIcon alt='' className='ml-2' height={25} />
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {get(allCryptos, 'data', []).map((cryptoAsset, key) => (
                          <tr
                            key={key}
                            className='font-size-16 font-weight-medium cursor-pointer'
                            onClick={() => {
                              // AddOnRampTrial({ crypto_id: cryptoAsset.coin_id })
                              //   .then((res) => {
                              history.push(
                                `/crypto?${stringify({
                                  crypto_id:
                                    (cryptoAsset.cryptoExist && cryptoAsset.coin_id) ||
                                    null,
                                  coin_gecko_id: cryptoAsset.coin_gecko_id,
                                  coin_name: cryptoAsset.news_ticker
                                })}`
                              );
                              // })
                              // .catch((err) => {});
                            }}>
                            <td className='pl-4'>{key + 1}</td>
                            <td>
                              <div key={key} className='d-flex align-items-center'>
                                <div className='' style={{ minWidth: '43px' }}>
                                  <img
                                    src={cryptoAsset.image}
                                    height={32}
                                    className='rounded-circle'
                                    alt={cryptoAsset.name}
                                  />
                                </div>
                                <div className='flex-fill '>
                                  <div className='text-uppercase text-reset'>
                                    {cryptoAsset.coin_name}
                                  </div>
                                  <div className='font-size-10 font-weight-light'>
                                    {cryptoAsset.crypto_name}
                                  </div>
                                </div>
                              </div>
                            </td>
                            <td>
                              {cryptoAsset.market_cap ? (
                                <span>
                                  $
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'decimal'
                                  }).format(cryptoAsset.market_cap)}
                                </span>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td>{cryptoAsset.market_cap_change_percentage_24h}%</td>
                            <td>
                              {cryptoAsset.total_volume ? (
                                <span>
                                  $
                                  {new Intl.NumberFormat('en-US', {
                                    style: 'decimal'
                                  }).format(cryptoAsset.total_volume)}
                                </span>
                              ) : (
                                '-'
                              )}
                            </td>
                            <td className=''>-</td>
                            <td className=''>
                              {get(cryptoAsset, 'supportedProviders', [])
                                .map(({ provider_name }) => provider_name)
                                .join(', ')}
                            </td>
                            <td className='pr-4'>-</td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                    {get(allCryptos, 'data.length', 0) < allCryptos.count && (
                      <div className='d-flex justify-content-center px-3 pb-3'>
                        {isLoading ? (
                          <i
                            className='fas fa-circle-notch fa-spin text-muted'
                            style={{ fontSize: 48 }}></i>
                        ) : (
                          <button
                            className='btn font-size-18 font-weight-medium text-reset'
                            style={{
                              textDecoration: 'underline',
                              textUnderlineOffset: '0.2rem'
                            }}
                            onClick={(evt) => {
                              evt.preventDefault();
                              setPage(page + 1);
                            }}>
                            View More
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
            <NotificationToastContainer />
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default AllCryptosPage;
