import { isNumber } from 'lodash';
import moment from 'moment';
import React, { useState } from 'react';
import { ProcessApplication } from '../../services/Onramp/Application';
import { notifySuccess } from '../../Custom/notification';
import PriorityModal from './PriorityModal';

function GetApplication({
  application,
  getApplications,
  page,
  setPage,
  handleApplication,
  applicationCheck,
  setApplicationCheck,
  massPriority
}) {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const changePriority = (priority) => {
    ProcessApplication(application._id, priority)
      .then((res) => {
        notifySuccess('Application Priority Changed Successfully');
        setPage(page);
        const params = {
          status: 'COMPLETE',
          application_type: 'EXCHANGE'
        };
        getApplications(params, { limit: 10 });
      })
      .catch((err) => {});
  };

  const formatNumber = () => {
    const totalExchange =
      application.exchange_application.existing_exchanges_listed.reduce(
        (accumulator, currentValue) => {
          if (isNumber(parseInt(currentValue.hour24_trading_volume))) {
            return (accumulator += parseInt(currentValue.hour24_trading_volume));
          } else {
            return (accumulator += 0);
          }
        },
        0
      );
    if (Math.abs(totalExchange) >= 1000000000) {
      return (totalExchange / 1000000000).toFixed(1) + 'B';
    } else if (Math.abs(totalExchange) >= 1000000) {
      return (totalExchange / 1000000).toFixed(1) + 'M';
    } else if (Math.abs(totalExchange) >= 1000) {
      return (totalExchange / 1000).toFixed(1) + 'K';
    }
    return totalExchange;
  };
  const handlePriorityClick = () => {
    setIsModalOpen(true);
  };

  let investorNames =
    application &&
    application.exchange_application.investment_stages &&
    application.exchange_application.investment_stages.reduce((accumulator, stage) => {
      let names = stage.all_investors.map((investor) => investor.name);
      return accumulator.concat(names);
    }, []);

  investorNames = [...new Set(investorNames)];

  let formattedResult;

  if (investorNames.length > 1) {
    formattedResult = `${investorNames[0]}, and ${investorNames.length - 1} other`;
  } else {
    if (investorNames && investorNames.length > 0) {
      formattedResult = investorNames[0];
    } else {
      formattedResult = '-';
    }
  }

  const ProfileIcon = ({ iconUrl }) => (
    <div>
      {iconUrl && iconUrl.length > 0 ? (
        <img
          src={iconUrl}
          style={{ borderRadius: '50%' }}
          width={40}
          height={40}
          alt='user'
        />
      ) : (
        <i
          className='mt-2 pt-2 text-center fa fa-user'
          style={{
            borderRadius: '50%',
            border: '1px solid #f0f0f0',
            width: '40px',
            height: '40px',
            background: '#f0f0f0'
          }}></i>
      )}
    </div>
  );

  const handleCheckboxChange = (application) => {
    const updatedApplicationCheck = applicationCheck.map((appCheck) => {
      if (appCheck.id === application._id) {
        return { ...appCheck, checked: !appCheck.checked };
      }
      return appCheck;
    });
    setApplicationCheck(updatedApplicationCheck);
  };

  return (
    <>
      {massPriority && (
        <td className='pl-2'>
          <input
            type='checkbox'
            className='cursor-pointer'
            checked={applicationCheck.find((app) => app.id === application._id).checked}
            style={{ height: 16, width: 16 }}
            onChange={() => handleCheckboxChange(application)}
          />
        </td>
      )}

      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application.exchange_application.ticker_logo ? (
          <img
            src={application.exchange_application.ticker_logo}
            height={45}
            width={45}
            style={{ borderRadius: '50%' }}
            alt='ticker-logo'
          />
        ) : (
          <ProfileIcon iconUrl={application.icon_url} />
        )}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application &&
        application.exchange_application &&
        application.exchange_application.project_name
          ? application.exchange_application.project_name
          : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application &&
        application.exchange_application &&
        application.exchange_application.token_name
          ? application.exchange_application.token_name
          : '-'}
      </td>

      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application &&
        application.application_status === 'COMPLETE' &&
        application.sub_application &&
        [
          'KYB',
          'ACCEPT',
          'COMPLETE',
          'REQUEST_ACTION',
          'ADD_QUESTIONS',
          'REJECT',
          'QUOTE',
          'PAID',
          'PROCESSING'
        ].includes(application.sub_application.application_status) &&
        application.exchange_application.applicant_email
          ? application.exchange_application.applicant_email
            ? application.exchange_application.applicant_email
            : '-'
          : '**************'}
      </td>

      <td
        className='pl-2'
        style={{ minWidth: 200 }}
        onClick={() => handleApplication(application)}>
        {application &&
        application.application_status === 'COMPLETE' &&
        application.sub_application &&
        [
          'KYB',
          'ACCEPT',
          'COMPLETE',
          'REQUEST_ACTION',
          'ADD_QUESTIONS',
          'REJECT',
          'QUOTE',
          'PAID',
          'PROCESSING'
        ].includes(application.sub_application.application_status) ? (
          <>
            {application &&
            application.exchange_application &&
            application.exchange_application.applicant_first_name
              ? application.exchange_application.applicant_first_name
              : '-'}{' '}
            {application &&
            application.exchange_application &&
            application.exchange_application.applicant_last_name
              ? application.exchange_application.applicant_last_name
              : '-'}
          </>
        ) : (
          '**************'
        )}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application &&
        application.exchange_application &&
        application.exchange_application.applicant_telegram
          ? application.exchange_application.applicant_telegram
          : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application &&
        application.exchange_application &&
        application.exchange_application.blockchain_network
          ? application.exchange_application.blockchain_network
          : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        $
        {application &&
        application.exchange_application &&
        application.exchange_application.existing_exchanges_listed &&
        application.exchange_application.existing_exchanges_listed.length > 0
          ? formatNumber()
          : 0}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application && application.dex_volume
          ? formatNumber(parseFloat(application.dex_volume))
          : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application &&
        application.exchange_application &&
        application.exchange_application.existing_exchanges_listed &&
        application.exchange_application.existing_exchanges_listed.length > 0 ? (
          <span className='badge badge-pill badge-success'>True</span>
        ) : (
          <span className='badge badge-pill badge-danger'>False</span>
        )}
      </td>
      <td
        className='pl-2'
        style={{ minWidth: 200 }}
        onClick={() => handleApplication(application)}>
        {formattedResult}
      </td>

      <td
        className='pl-2'
        style={{ minWidth: 150 }}
        onClick={() => handleApplication(application)}>
        {application.sub_application ? (
          application.sub_application.application_status === 'PROCESSING' &&
          application.sub_application.response_complete ? (
            <span className='badge badge-pill badge-warning'>Action Needed</span>
          ) : application.sub_application.application_status === 'PROCESSING' ? (
            <span className='badge badge-pill badge-success'>Processing</span>
          ) : application.sub_application.application_status === 'ACCEPT' ? (
            <span className='badge badge-pill badge-success'>Accepted</span>
          ) : application.sub_application.application_status === 'REJECT' ? (
            <span className='badge badge-pill badge-danger'>Rejected</span>
          ) : application.sub_application.application_status === 'KYB' ? (
            <span className='badge badge-pill badge-primary'>KYB</span>
          ) : application.sub_application.application_status === 'ADD_QUESTIONS' ? (
            <span className='badge badge-pill badge-primary'>Add Questions</span>
          ) : application.sub_application.application_status === 'REQUEST_ACTION' ? (
            <span className='badge badge-pill badge-primary'>Request Action</span>
          ) : application.sub_application.application_status === 'QUOTE' ? (
            <span className='badge badge-pill badge-dark'>Quote</span>
          ) : application.sub_application.application_status === 'PAID' ? (
            <span className='badge badge-pill badge-info'>Paid</span>
          ) : (
            '-'
          )
        ) : (
          <span className='badge badge-pill badge-secondary'>Unread</span>
        )}
      </td>
      <td
        className='pl-2'
        style={{ minWidth: 200 }}
        onClick={() => handleApplication(application)}>
        {application.application_status === 'COMPLETE'
          ? 'Complete Application'
          : 'Lite Application'}
      </td>
      <td
        className='pl-2'
        style={{ minWidth: 150 }}
        onClick={() => handleApplication(application)}>
        {application && application.created_at
          ? moment(application.created_at).format('DD MMM, YYYY')
          : '-'}
      </td>
      <td
        className='pl-2 cursor-pointer'
        style={{ minWidth: 150 }}
        onClick={handlePriorityClick}>
        {application.sub_application &&
        (application.sub_application.priority === 0 ||
          !application.sub_application.priority) ? (
          <span className='p-2 font-size-12 badge badge-secondary'>None</span>
        ) : application.sub_application && application.sub_application.priority === 1 ? (
          <span className='p-2 font-size-12 badge badge-success'>Low</span>
        ) : application.sub_application && application.sub_application.priority === 2 ? (
          <span className='p-2 font-size-12 badge badge-warning'>Medium</span>
        ) : application.sub_application && application.sub_application.priority === 3 ? (
          <span className='p-2 font-size-12 badge badge-danger'>High</span>
        ) : (
          <span className='p-2 font-size-12 badge badge-secondary'>None</span>
        )}
      </td>
      {isModalOpen && (
        <PriorityModal
          priorityApplicationChange={changePriority}
          isModalOpen={isModalOpen}
          setIsModalOpen={setIsModalOpen}
          application={application}
        />
      )}
    </>
  );
}

export default GetApplication;
