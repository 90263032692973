import React, { useEffect, useState } from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { useQueryParam, StringParam } from 'use-query-params';
import TableSortButton from '../../../components/TableSortButton';
import GetResponse from './GetResponse';
import { Paginator } from 'primereact/paginator';
import { Col, Row } from 'reactstrap';
import ResponseFilters from '../../BusinessApplication/ViewOnRampApplication/ResponseFilters';

function ResponseList({
  GetApplicationResponseFunc,
  page,
  setPage,
  setApplicationId,
  statusSuggestions,
  setResponsePage,
  setStatusSuggestions,
  setFirst,
  allResponses,
  totalCount,
  loading,
  currentPageData,
  setCurrentPageData,
  setOrder,
  order,
  first
}) {
  const [applicationId] = useQueryParam('application_id', StringParam);
  useEffect(() => {
    setApplicationId(applicationId);
  }, []);

  // New Start

  const [expandedRows, setExpandedRows] = useState([]);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    const newPageData = allResponses.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  useEffect(() => {
    GetApplicationResponseFunc(applicationId);
  }, [applicationId, order, statusSuggestions]);

  const toggleRowExpansion = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  return (
    <React.Fragment>
      <div className='card'>
        <Row>
          <Col lg={9} md={9} sm={12}>
            <div
              className='card p-3 ml-2 mr-2 mt-4'
              style={{
                border: `1px solid rgb(128 128 128 / 30%)`,
                cursor: 'pointer'
              }}>
              <div className='table-responsive'>
                <table className='table table-centered table-hover'>
                  <thead className='center-text'>
                    <tr className='font-size-12 text-muted center-text'>
                      <th className='pl-4 pt-3 border-0'>#</th>
                      <th className='pl-4 pt-3 border-0'>Requested By</th>
                      <th className='pt-3 border-0 text-left'>Field Changes Requested</th>
                      <th className='pt-3 border-0 text-left'>Notes</th>
                      <th className='pl-4 pt-3 border-0'>
                        <div className='d-flex align-items-center'>
                          <span>Date Submitted</span>
                          <TableSortButton
                            className='ml-1'
                            value={order}
                            topValue='created_at_desc'
                            bottomValue='created_at_asc'
                            onChange={(v) => {
                              setPage(1);
                              setOrder(v);
                            }}
                          />
                        </div>
                      </th>
                      <th className='pl-4 pt-3 border-0'>
                        <div className='d-flex align-items-center justify-content-center'>
                          <span>Status</span>
                          <TableSortButton
                            className='ml-1'
                            value={order}
                            topValue='status_desc'
                            bottomValue='status_asc'
                            onChange={(v) => {
                              setPage(1);
                              setOrder(v);
                            }}
                          />
                        </div>
                      </th>
                      <th className='pl-4 pt-3 border-0'>Needed Review</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={12}>
                          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                            <i
                              className='fas fa-circle-notch fa-spin text-muted'
                              style={{ fontSize: 48 }}></i>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {currentPageData && currentPageData.length > 0 ? (
                          currentPageData.map((application, key) => (
                            <React.Fragment key={key}>
                              <tr
                                className='center-text font-size-12 font-weight-medium cursor-pointer'
                                onClick={() => toggleRowExpansion(key)}>
                                <GetResponse
                                  application={application}
                                  index={key}
                                  page={page}
                                  GetApplicationResponseFunc={GetApplicationResponseFunc}
                                  applicationId={applicationId}
                                />
                              </tr>
                              {expandedRows.includes(key) && (
                                <tr className='font-size-12'>
                                  <td colSpan={7} className='p-0'>
                                    <div className='expanded-row'>
                                      {application &&
                                      application.applicationResponse.field &&
                                      Object.keys(application.applicationResponse.field)
                                        .length > 0 ? (
                                        <ul className='notes-list ml-5 pl-4'>
                                          {Object.keys(
                                            application.applicationResponse.field
                                          ).map((fItem, index) => (
                                            <li key={index} className='font-size-12'>
                                              <span className='font-weight-medium'>
                                                <u>{fItem.toUpperCase()}</u>:
                                              </span>
                                              {'  '}
                                              {
                                                application.applicationResponse.field[
                                                  fItem
                                                ]
                                              }
                                            </li>
                                          ))}
                                        </ul>
                                      ) : application &&
                                        application.applicationResponse.notes ? (
                                        <span className='notes-list font-size-12 ml-5 pl-4'>
                                          {application.applicationResponse.notes}
                                        </span>
                                      ) : application &&
                                        application.application_status ===
                                          'ADD_QUESTIONS' &&
                                        application.add_questions &&
                                        application.add_questions.length > 0 ? (
                                        <ul className='notes-list ml-5 pl-4'>
                                          {application.add_questions.map((que, index) => (
                                            <li key={index} className='font-size-12'>
                                              <span className='font-weight-medium'>
                                                <u>{que.type.toUpperCase()}</u>:
                                              </span>
                                              {'  '}
                                              {que.question}
                                            </li>
                                          ))}
                                        </ul>
                                      ) : (
                                        <div className='no-notes'>No notes available</div>
                                      )}
                                    </div>
                                  </td>
                                </tr>
                              )}
                            </React.Fragment>
                          ))
                        ) : (
                          <tr className='center-text'>
                            <td colSpan={7}>No response found</td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </Col>
          <Col lg={3} md={3} sm={12}>
            <div
              className='card ml-2 mr-2 mt-4'
              style={{
                border: `1px solid rgb(128 128 128 / 30%)`,
                cursor: 'pointer'
              }}>
              <ResponseFilters
                statusSuggestions={statusSuggestions}
                setStatusSuggestions={setStatusSuggestions}
                setResponsePage={setResponsePage}
                applicationId={applicationId}
              />
            </div>
          </Col>
        </Row>
      </div>
      <Paginator
        rows={10}
        totalRecords={totalCount}
        first={first}
        onPageChange={(e) => onPageChange(e)}
      />
    </React.Fragment>
  );
}

export default ResponseList;
