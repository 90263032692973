import React from 'react';
import ProviderMatrix from './ProviderMatrix';
import { Container } from 'reactstrap';

function OnRamps() {
  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <ProviderMatrix />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default OnRamps;
