import moment from 'moment';
import React, { useState } from 'react';
import { Collapse } from 'reactstrap';
import DeleteApplication from './DeleteApplication';
import { adminDeleteApplicationAPI } from '../../services/Admin/applicaton';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../Custom/notification';

const ProfileIcon = ({ iconUrl }) => (
  <div style={{ padding: '5px' }}>
    {iconUrl && iconUrl.length > 0 ? (
      <img
        src={iconUrl}
        style={{ borderRadius: '50%' }}
        width={40}
        height={40}
        alt='user'
      />
    ) : (
      <i
        className='fa fa-user'
        style={{
          borderRadius: '50%',
          border: '1px solid #f0f0f0',
          width: '40px',
          height: '40px',
          background: '#f0f0f0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}></i>
    )}
  </div>
);

function GetApplication({
  index,
  page,
  items,
  activeId,
  onClick,
  setLoading,
  listusApplicationListFunc
}) {
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [applicationData, setApplicationData] = useState({});
  const [accountPassword, setAccountPassword] = useState('');

  const deleteApplicationFunction = (application_id, accountPassword) => {
    const data = { password: accountPassword };
    setLoading(true);
    adminDeleteApplicationAPI(application_id, data)
      .then((res) => {
        setLoading(false);
        listusApplicationListFunc();
        setModalDeleteOpen(false);
        notifySuccess(`Application deleted successfully!`);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(errorMessage);
      });
  };

  return (
    <React.Fragment key={index}>
      <tr
        className='center-text font-size-14 font-weight-medium'
        onClick={() => onClick(index + 1 + (page - 1) * 10)}>
        <td>{index + 1 + (page - 1) * 10}</td>
        <td>
          <ProfileIcon iconUrl={items.brand_logo} />
        </td>
        <td>{items.referral_code ? items.referral_code : '-'}</td>
        <td>{items.domain ? items.domain : '-'}</td>
        <td>{items.email}</td>
        <td>{items.company_name}</td>
        <td>
          {items?.exchange_application?.project_name
            ? items?.exchange_application?.project_name
            : items?.project_name
            ? items?.project_name
            : 'N/A'}
        </td>
        <td>
          {items?.exchange_application?.applicant_email
            ? items?.exchange_application?.applicant_email
            : items.applicant_email
            ? items?.applicant_email
            : 'N/A'}
        </td>
        <td>{items.application_type}</td>
        <td>
          {' '}
          {items?.application_status === 'COMPLETE' ? (
            <span className='p-2 font-size-12 badge badge-success'>Complete</span>
          ) : items?.application_status === 'LITE_COMPLETE' ? (
            <span className='p-2 font-size-12 badge badge-warning'>Lite Complete</span>
          ) : (
            <span className='p-2 font-size-12 badge badge-danger'>
              {items?.application_status}
            </span>
          )}
        </td>
        <td>
          {items?.application_status === 'COMPLETE'
            ? '100'
            : items?.application_type === 'ONRAMP'
            ? items?.app_onramp_per
            : items?.application_type === 'EXCHANGE'
            ? items?.app_exchange_per
            : ''}{' '}
          %
        </td>
        <td>
          {items?.application_status === 'LITE_COMPLETE' &&
          items?.application_type === 'EXCHANGE'
            ? '100'
            : items?.application_type === 'EXCHANGE'
            ? items?.app_exchange_lite_per
            : '-'}{' '}
          %
        </td>
        <td>{moment(items.app_created_at).format('DD MMM YYYY')}</td>
        <td>
          <i
            className='font-size-20 fas fa-trash-alt cursor-pointer'
            style={{ color: 'red' }}
            onClick={() => {
              setModalDeleteOpen(true);
              setApplicationData(items);
            }}></i>
        </td>
        {modalDeleteOpen &&
          applicationData &&
          Object.keys(applicationData).length > 0 && (
            <DeleteApplication
              deleteApplicationFunction={deleteApplicationFunction}
              modalDeleteOpen={modalDeleteOpen}
              setModalDeleteOpen={setModalDeleteOpen}
              setAccountPassword={setAccountPassword}
              accountPassword={accountPassword}
              applicationData={applicationData}
            />
          )}
        <td>
          {items.sub_application &&
            items.sub_application.length > 0 &&
            items.sub_application.length >= 1 &&
            items.sub_application.filter(
              (app) =>
                app && app.onRampAppData && Object.keys(app.onRampAppData).length > 0
            ).length > 0 && (
              <label
                data-event={index}
                key={index}
                style={{ fontSize: '25px', cursor: 'pointer' }}
                className='pl-3 mt-1'>
                <i className={`mdi mdi-chevron-${activeId === index ? 'up' : 'down'}`} />
              </label>
            )}
        </td>
      </tr>

      {items.sub_application &&
        items.sub_application.length > 0 &&
        items.sub_application &&
        items.sub_application.filter(
          (app) => app && app.onRampAppData && Object.keys(app.onRampAppData).length > 0
        ).length > 0 && (
          <SubApplications
            applications={items.sub_application}
            activeId={activeId}
            page={page}
            index={index}
          />
        )}
    </React.Fragment>
  );
}

export default GetApplication;

const SubApplications = ({ applications, activeId, index, page }) => (
  <tr>
    <td colSpan={14}>
      <Collapse className='mt-2' isOpen={activeId === index + 1 + (page - 1) * 10}>
        <div className='card'>
          <div className='table-responsive'>
            <table className='table table-centered table-hover font-size-12'>
              <thead className='center-text'>
                <tr>
                  <th className='pl-4 pt-3 border-0'>#</th>
                  <th className='pl-4 pt-3 border-0'>Requested By</th>
                  <th className='pl-4 pt-3 border-0'>Status</th>
                  <th className='pl-4 pt-3 border-0 text-left'>Notes</th>
                  <th className='pl-4 pt-3 border-0 text-left'>
                    Field Changes Requested
                  </th>
                  <th className='pl-4 pt-3 border-0'>Date Submitted</th>
                </tr>
              </thead>
              <tbody>
                {applications && applications.length > 0 ? (
                  <>
                    {applications
                      .filter(
                        (app) =>
                          app &&
                          app.onRampAppData &&
                          Object.keys(app.onRampAppData).length > 0
                      )
                      .map((application, i) => (
                        <SubApplication key={i} application={application} index={i} />
                      ))}
                  </>
                ) : (
                  <tr>
                    <td colSpan={7}>
                      <span className='d-flex justify-content-center'>
                        No sub applications found
                      </span>
                    </td>
                  </tr>
                )}
              </tbody>
            </table>
          </div>
        </div>
      </Collapse>
    </td>
  </tr>
);

const SubApplication = ({ application, index }) => (
  <tr className='center-text'>
    <td className='pl-4'>{index + 1}</td>
    <td className='pl-4'>
      {' '}
      <div>
        <ProfileIcon
          iconUrl={
            application && application.onRampAppData && application.onRampAppData.logo_url
              ? application.onRampAppData.logo_url
              : ''
          }
        />
        <span>{application.onRampAppData && application.onRampAppData.on_ramp_name}</span>
      </div>
    </td>
    <td className='pl-4'>
      <span
        className={`p-1 font-size-12 badge badge-pill badge-${getSeverity(
          application.application_status
        )}`}>
        {application &&
          application.application_status &&
          statusSuggestions.find((status) => status.id === application.application_status)
            .name}
      </span>
    </td>
    <td className='pl-4 text-left'>
      {application &&
      application.onRampAppData &&
      application.onRampAppData.field &&
      Object.keys(application.onRampAppData.field).length > 0 ? (
        Object.entries(application.onRampAppData.field).map(([key, value]) => {
          if (value.length > 0) {
            const truncatedValue =
              String(value).length > 20
                ? `${String(value).substring(0, 20)}...`
                : String(value);
            return (
              <div key={key} data-tip={value}>
                &#8226; {truncatedValue}
              </div>
            );
          } else {
            return <div key={key} data-tip={value}></div>;
          }
        })
      ) : application.onRampAppData && application.onRampAppData.notes ? (
        String(application.onRampAppData.notes).length > 20 ? (
          `${String(application.onRampAppData.notes).substring(0, 20)}...`
        ) : (
          String(application.onRampAppData.notes)
        )
      ) : application &&
        application.application_status === 'ADD_QUESTIONS' &&
        application.add_questions &&
        application.add_questions.length > 0 ? (
        application.add_questions.map((que, index) => (
          <div key={index}>
            {index + 1}.
            {String(que.question).length > 20
              ? `${String(que.question).substring(0, 20)}
            ...`
              : String(que.question)}
          </div>
        ))
      ) : (
        <div>No notes available</div>
      )}
    </td>
    <td className='pl-4 text-left'>
      {application &&
      application.onRampAppData &&
      application.onRampAppData.field &&
      Object.keys(application.onRampAppData.field).length > 0 ? (
        <>
          {Object.keys(application.onRampAppData.field)
            .splice(0, 5)
            .map((value, index) => (
              <div
                className={
                  application.onRampAppData.completed_field &&
                  application.onRampAppData.completed_field.length > 0 &&
                  application.onRampAppData.completed_field.includes(value)
                    ? 'text-success'
                    : 'text-warning'
                }
                key={index}>
                {index + 1}. {value}
              </div>
            ))}
          {Object.keys(application.onRampAppData.field).length > 5 && '...more'}
        </>
      ) : application &&
        application.application_status === 'ADD_QUESTIONS' &&
        application.add_questions &&
        application.add_questions.length > 0 ? (
        application.add_questions.map((que, index) => (
          <div key={index}>
            {index + 1}. {que.type}
          </div>
        ))
      ) : (
        <div className='pl-4'>No fields available</div>
      )}
    </td>
    <td className='pl-4' width={200}>
      {moment(application.created_at).format('DD/MM/YYYY hh:mm A')}
    </td>
  </tr>
);

const getSeverity = (status) => {
  const statusToSeverity = {
    REJECT: 'danger',
    ACCEPT: 'success',
    ADD_QUESTIONS: 'info',
    negotiation: 'warning',
    COMPLETE: 'success',
    QUOTE: 'secondary',
    renewal: 'secondary'
  };

  return statusToSeverity[status] || 'secondary';
};

const statusSuggestions = [
  { id: 'REQUEST_ACTION', name: 'Action' },
  { id: 'PROCESSING', name: 'Processing' },
  { id: 'ACCEPT', name: 'Accept' },
  { id: 'REJECT', name: 'Reject' },
  { id: 'KYB', name: 'KYB' },
  { id: 'QUOTE', name: 'Quote' },
  { id: 'PAID', name: 'Paid' },
  { id: 'ADD_QUESTIONS', name: 'Add Question' }
];
