import React, { useState } from 'react';
import GetUserList from './GetUserList';
import { Container, Row } from 'reactstrap';
import BeneficiaryUserModal from '../../OnRampApplication/BeneficiaryUserModal';

function BeneficiaryUser({ userData, applicationId, getApplicationFunction }) {
  const [userModal, setUserModal] = useState(false);
  const [user, setUser] = useState();

  const addUser = () => {
    setUserModal(!userModal);
  };
  return (
    <React.Fragment>
      <div className=''>
        <Row className='d-flex justify-content-between pl-3 pr-2'>
          <div>
            <span className='text-black font-size-18 d-flex justify-content-center'>
              <u>Beneficial Ownership</u>
            </span>
          </div>
          <div>
            <button
              style={{ width: 180 }}
              className={`btn btn-status-change cursor-pointer`}
              onClick={addUser}>
              Add User
            </button>
          </div>
        </Row>
        <Container className='pt-4 pt-md-1 p-0'>
          {userData && (
            <div className='col-12 col-sm-auto font-size-16 font-weight-medium'>
              <div className='table-responsive'>
                <table className='table table-centered table-hover'>
                  <thead>
                    <tr className='font-size-12 text-muted center-text'>
                      <th className='pt-3 pb-4 border-0'>#</th>
                      <th className='pt-3 pb-4 border-0'>Full Name</th>
                      <th className='pt-3 pb-4 border-0'>Date Of Birth</th>
                      <th className='pt-3 pb-4 border-0'>Country</th>
                      <th className='pt-3 pb-4 border-0'>% Ownership</th>
                      <th className='pt-3 pb-4 border-0'>Role</th>
                      <th className='pt-3 pb-4 border-0'>Identity Documents</th>
                      <th className='pt-3 pb-4 border-0'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {userData && userData.length > 0 ? (
                      userData.map((user, key) => (
                        <tr
                          key={key}
                          className='font-size-12 font-weight-medium center-text'>
                          <GetUserList
                            user={user}
                            handleOpenModal={addUser}
                            setUser={setUser}
                            applicationId={applicationId}
                            index={key}
                            getApplicationFunction={getApplicationFunction}
                          />
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={8} className='center-text'>
                          No user found
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          )}

          <BeneficiaryUserModal
            applicationId={applicationId}
            modal={userModal}
            user={user}
            handleOpenModal={addUser}
            getApplicationFunction={getApplicationFunction}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default BeneficiaryUser;
