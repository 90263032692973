import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import documentUploadIcon from '../../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../../assets/images/documentuploaded.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { ProjectsWhitepaperAndPitchDeckInputName } from './helper';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';
import { get } from 'lodash';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { constants as CONST } from '../../../../constants/application';
import AuthInput from '../../../../Custom/AuthInput';

function ProjectsWhitepaperAndPitchDeckApp({
  allResponses = [],
  values = {},
  fileUrls = {},
  errors = {},
  touched = {},
  handleChange,
  setFieldValue = () => {},
  isSectionCollapsed,
  toggleSection,
  applicationId,
  setInitialValuesFunc
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);

  useEffect(() => {
    const res = Object.keys(errors).find((i) =>
      ProjectsWhitepaperAndPitchDeckInputName.includes(i)
    );
    setErrorShow(!!res);
  }, [errors]);
  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          ProjectsWhitepaperAndPitchDeckInputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);

  const handleSubmit = () => {
    if (
      !ProjectsWhitepaperAndPitchDeckInputName.includes(
        Object.keys(errors).find((i) =>
          ProjectsWhitepaperAndPitchDeckInputName.includes(i)
        )
      )
    ) {
      setIsLoading(true);
      const data = new FormData();
      data.append('whitepapers_doc', values.whitepapers_doc);
      data.append('pitch_decks_doc', values.pitch_decks_doc);
      data.append('whitepapers_doc_url', values.whitepapers_doc_url);
      data.append('pitch_decks_doc_url', values.pitch_decks_doc_url);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          notifySuccess(`Project's Whitepaper + Pitch deck data updated successfully`);
          setIsLoading(false);
          setInitialValuesFunc(application.exchange_application);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Project's Whitepaper + Pitch deck</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <Row>
                <Col lg={6} className=''>
                  <div className='font-size-12'>
                    <Label
                      htmlFor='description'
                      className='text-black font-weight-normal'>
                      Project's Documents
                    </Label>
                  </div>
                  <FormGroup>
                    <div
                      style={{
                        borderRadius: '8px',
                        border: '1px solid',
                        height: 47
                      }}
                      className='d-flex direction-row justify-content-between'>
                      <div className='file-upload-wrapper d-flex direction-row justify-content-between pr-2'>
                        <Label
                          htmlFor='whitepapers'
                          id='whitepapers_doc'
                          className='mb-0 pl-3'>
                          <span
                            className='p-1 rounded-circle'
                            style={{
                              color: '#134a87',
                              fontSize: 15,
                              cursor: 'pointer'
                            }}>
                            <img
                              src={
                                !values?.whitepapers_doc
                                  ? documentUploadIcon
                                  : documentUploadedIcon
                              }
                              alt='Whitepapers'></img>
                          </span>
                          <span className='font-size-12'>Whitepapers</span>
                        </Label>
                        {fileUrls.whitepapers_doc && (
                          <a
                            href={fileUrls.whitepapers_doc}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                            View File
                          </a>
                        )}
                        <Input
                          type='file'
                          id='whitepapers'
                          accept='.pdf'
                          name='whitepapers_doc'
                          onChange={(event) => {
                            setFieldValue(
                              'whitepapers_doc',
                              event.currentTarget.files[0]
                            );
                          }}
                          style={{ display: 'none' }}
                        />

                        {errors.whitepapers_doc && touched.whitepapers_doc && (
                          <div
                            style={{ fontSize: 14 }}
                            className='text-left mt-2 text-danger'>
                            {errors.whitepapers_doc.split('.')[0]}
                          </div>
                        )}
                        <ReactTooltip
                          anchorId='whitepapers_doc'
                          place='bottom'
                          content={
                            fileUrls['whitepapers_doc'] &&
                            fileUrls['whitepapers_doc'].name
                          }
                        />
                      </div>
                    </div>
                    <div className='mt-2'>
                      <AuthInput
                        type='text'
                        name={'whitepapers_doc_url'}
                        values={values}
                        className='font-size-12'
                        handleChange={handleChange}
                        placeholder={CONST.ENTER_URL_PLACEHOLDER}
                        errors={errors}></AuthInput>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <div className='d-flex direction-row justify-content-between mt-1'>
                    <div
                      style={{
                        borderRadius: '8px',
                        border: '1px solid',
                        height: 47
                      }}
                      className='file-upload-wrapper d-flex direction-row justify-content-between pr-2 mt-4'>
                      <Label
                        htmlFor='pitch_deck'
                        id='pitch_decks_doc'
                        className='mb-0 pl-3'>
                        <span
                          className='p-1  rounded-circle'
                          style={{
                            color: '#134a87',
                            fontSize: 14,
                            cursor: 'pointer'
                          }}>
                          <img
                            src={
                              !values?.pitch_decks_doc
                                ? documentUploadIcon
                                : documentUploadedIcon
                            }
                            alt='IntegrationFlows'></img>
                        </span>
                        <span className='font-size-12'>Pitch deck</span>
                      </Label>
                      {fileUrls.pitch_decks_doc && (
                        <a
                          href={fileUrls.pitch_decks_doc}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                          View File
                        </a>
                      )}
                      <Input
                        type='file'
                        id='pitch_deck'
                        accept='.pdf'
                        name='pitch_decks_doc'
                        onChange={(event) => {
                          setFieldValue('pitch_decks_doc', event.currentTarget.files[0]);
                        }}
                        style={{ display: 'none' }}
                      />
                      {errors.pitch_decks_doc && touched.pitch_decks_doc && (
                        <div
                          style={{ fontSize: 12 }}
                          className='text-left mt-2 text-danger'>
                          {errors.pitch_decks_doc.split('.')[0]}
                        </div>
                      )}
                      <ReactTooltip
                        anchorId='pitch_decks_doc'
                        place='bottom'
                        content={
                          fileUrls['pitch_decks_doc'] && fileUrls['pitch_decks_doc'].name
                        }
                      />
                    </div>
                  </div>
                  <div className='mt-2'>
                    <AuthInput
                      type='text'
                      name={'pitch_decks_doc_url'}
                      values={values}
                      className='font-size-12'
                      handleChange={handleChange}
                      placeholder={CONST.ENTER_URL_PLACEHOLDER}
                      errors={errors}></AuthInput>
                  </div>
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default ProjectsWhitepaperAndPitchDeckApp;
