import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../../constants/application';
import Select from 'react-select';
import { ErrorMessage } from 'formik';
import { GetAllNetwork } from '../../../../services/Business/Application';
import { notifyError } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';
import get from 'lodash/get';
import AuthInput from '../../../../Custom/AuthInput';
import documentUploadedIcon from '../../../../assets/images/documentuploaded.png';
import documentUploadIcon from '../../../../assets/images/documentupload.png';

function GeneralInfo({
  CustomFieldInput,
  values,
  errors,
  setFieldValue,
  touched,
  handleChange,
  setBuyCheck,
  buyCheck,
  setSellCheck,
  sellCheck,
  handleChangeOpt,
  selectedFields,
  otherNetwork,
  optionalFile,
  fileUrls,
  changeHandler
}) {
  const [allNetworks, setAllNetworks] = useState([]);

  useEffect(() => {
    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));

        setAllNetworks([...setFinalData, { label: 'Other', value: 'Other' }]);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, []);

  const businessCategory = [
    { value: 'Blockchain', label: 'Blockchain' },
    { value: 'NFT', label: 'NFT' },
    { value: 'CeFi', label: 'CeFi' },
    { value: 'DeFi', label: 'DeFi' },
    { value: 'Gaming', label: 'Gaming' },
    { value: 'Metaverse', label: 'Metaverse' },
    { value: 'DAO', label: 'DAO' },
    { value: 'Ecommerce', label: 'Ecommerce' },
    { value: 'Enterprise', label: 'Enterprise' },
    { value: 'Infrastructure', label: 'Infrastructure' },
    { value: 'Wallet', label: 'Wallet' },
    { value: 'Other', label: 'Other' }
  ];

  return (
    <div className='mt-4 ml-2'>
      <Row>
        <Col md={6} sm={6} lg={6}>
          <CustomFieldInput
            values={values}
            filedName={'legal_entity_name'}
            labelName={CONST.LEGAL_ENTITY}
            placeholder={CONST.ENTER_LEGAL_ENTITY}
            errors={errors}
            touched={touched}
            inputType={'text'}
            handleChange={handleChange}
          />
        </Col>
        <Col md={6} sm={6} lg={6}>
          <CustomFieldInput
            values={values}
            filedName={'project_name'}
            labelName={CONST.PROJECT_NAME}
            placeholder={CONST.ENTER_PROJECT_NAME}
            errors={errors}
            touched={touched}
            inputType={'text'}
            handleChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} lg={6}>
          <FormGroup className='mb-4'>
            <div className='font-size-12'>
              <Label
                htmlFor='business_category'
                className='text-black font-weight-normal'>
                {CONST.CATEGORY}
              </Label>
            </div>
            <div>
              <div
                className={
                  errors['business_category'] && touched['business_category']
                    ? 'border-danger'
                    : ''
                }>
                <Select
                  className='font-size-12'
                  isClearable={false}
                  isSearchable={true}
                  name='business_category'
                  options={businessCategory}
                  value={businessCategory.find(
                    (option) => option.value === values['business_category']
                  )}
                  onChange={(selectedOption) => {
                    setFieldValue(
                      'business_category',
                      selectedOption ? selectedOption.value : ''
                    );
                  }}
                  placeholder='Choose Category'
                />
              </div>
              <ErrorMessage name='business_category' className='font-size-12'>
                {(errorMsg) => <div style={{ color: 'red' }}>{errorMsg}</div>}
              </ErrorMessage>
            </div>
          </FormGroup>
        </Col>
        <Col md={6} sm={6} lg={6}>
          <CustomFieldInput
            values={values}
            filedName={'legal_entity_register_number'}
            labelName={CONST.REGISTRATION_NO}
            placeholder={CONST.ENTER_REGISTRATION_NO}
            errors={errors}
            touched={touched}
            inputType={'text'}
            handleChange={handleChange}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-4'>
            <div className='font-size-12'>
              <Label htmlFor='products' className='text-black font-weight-normal'>
                {CONST.PRODUCTS}
              </Label>
            </div>
            <div className='d-flex direction-row mt-2'>
              <div className='form-check custom-checkbox mr-3'>
                <Input
                  name='product_buy_enable'
                  type='checkbox'
                  onChange={() => setBuyCheck(!buyCheck)}
                  checked={buyCheck}
                  style={{ height: 14, width: 14 }}
                  value={buyCheck}
                  className='form-check-input'
                />
                <Label
                  className='form-check-label font-size-12'
                  onClick={() => {
                    setBuyCheck(!buyCheck);
                  }}>
                  {CONST.BUY}
                </Label>
              </div>
              <div className='form-check custom-checkbox'>
                <Input
                  name='product_sell_enable'
                  type='checkbox'
                  value={sellCheck}
                  style={{ height: 14, width: 14 }}
                  className='form-check-input'
                  onChange={() => setSellCheck(!sellCheck)}
                  checked={sellCheck}
                />
                <Label
                  className='form-check-label font-size-12'
                  onClick={() => {
                    setSellCheck(!sellCheck);
                  }}>
                  {CONST.SELL}
                </Label>
              </div>
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <CustomFieldInput
            labelName={CONST.TOKEN}
            filedName='token_address'
            placeholder={CONST.ENTER_TOKEN_ADDRESS_NAME}
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        <Col md={6} sm={6} lg={6}>
          <CustomFieldInput
            values={values}
            filedName={'country_of_incorporation'}
            labelName={CONST.COUNTRY}
            placeholder={CONST.ENTER_PROJECT_WEBSITE}
            errors={errors}
            touched={touched}
            inputType={'text'}
            handleChange={handleChange}
          />
        </Col>
        <Col md={6} sm={6} lg={6}>
          <CustomFieldInput
            values={values}
            filedName={'website'}
            labelName={CONST.WEBSITE}
            placeholder={CONST.ENTER_COUNTRY}
            errors={errors}
            touched={touched}
            inputType={'text'}
            handleChange={handleChange}
          />
        </Col>
      </Row>

      <Row>
        <Col md={6} sm={6} lg={6}>
          <FormGroup style={{ marginBottom: '1rem' }}>
            <div className='font-size-12'>
              <Label htmlFor='network' className='text-black font-weight-normal'>
                {CONST.NETWORK}
              </Label>
            </div>
            <div className={errors['network'] ? 'border-danger' : ''}>
              <Select
                className='font-size-12'
                isClearable={false}
                isSearchable={true}
                name='network'
                options={allNetworks}
                onChange={(e) => handleChangeOpt(e, setFieldValue)}
                value={selectedFields}
              />
            </div>
            {otherNetwork && (
              <>
                <div className='mt-1'>
                  <AuthInput
                    withTouchError={true}
                    type='text'
                    placeholder={'Enter network'}
                    name='other_network'
                    values={values}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                  />
                </div>
                <ErrorMessage name='listing_program_at_bybit' className='font-size-12'>
                  {(errorMsg) => <div style={{ color: 'red' }}>{errorMsg}</div>}
                </ErrorMessage>
              </>
            )}
          </FormGroup>

          <CustomFieldInput
            values={values}
            filedName={'ticker'}
            labelName={CONST.TICKER}
            placeholder={CONST.ENTER_TICKER}
            errors={errors}
            touched={touched}
            inputType={'text'}
            handleChange={handleChange}
          />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <CustomFieldInput
            labelName={CONST.DESCRIPTION}
            filedName='product_description'
            placeholder={CONST.ENTER_DESCRIPTION}
            values={values}
            handleChange={handleChange}
            errors={errors}
            inputType={'textarea'}
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <CustomFieldInput
            labelName={CONST.TICKER_NAME}
            filedName='ticker_name'
            placeholder={CONST.TICKER_NAME}
            values={values}
            handleChange={handleChange}
            errors={errors}
            inputType={'text'}
            touched={touched}
          />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-4'>
            <div className='font-size-12'>
              <Label
                htmlFor={'whitepapers'}
                id='ticker_logo'
                className='text-black font-weight-normal'>
                Ticker Logo
              </Label>
            </div>
            <div
              style={{
                borderRadius: '8px',
                border: '1px solid',
                height: 47
              }}
              className='d-flex direction-row justify-content-between align-items-center pl-1'>
              <Label htmlFor='whitepapers' className='mt-2'>
                <div className='cursor-pointer'>
                  <span
                    className='rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 14
                    }}>
                    <img
                      src={
                        fileUrls['ticker_logo'] || optionalFile['ticker_logo']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='Whitepapers'></img>
                  </span>
                  <span className='font-size-12 ml-2'> {CONST.ICON}</span>
                </div>
              </Label>
              <div>
                {fileUrls.ticker_logo && (
                  <a
                    href={fileUrls.ticker_logo}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
              </div>
              <Input
                type='file'
                id='whitepapers'
                name='ticker_logo'
                onChange={changeHandler}
                style={{ display: 'none' }}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default GeneralInfo;
