import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const businessDashboardOrderCountAPI = (status) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.BUSINESS_DASHBOARD_ORDER_COUNT + `?status=${status}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const BusinessDashboardTotalRevenueAPI = ({ days, status }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.BUSINESS_DASHBOARD_REVENUE +
          `?` +
          (days ? `&days=${days}` : '') +
          (status ? `&status=${status}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetWebhookDataAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.SET_WEBHOOK)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const SetWebhookDataAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.SET_WEBHOOK, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
