import React, { useEffect, useState } from 'react';
import { getAllUsersList } from '../../services/Admin/users';
import './adminMembers.css';
import { NotificationToastContainer } from '../../Custom/notification';
import { get } from 'lodash';
import { Paginator } from 'primereact/paginator';
import TableSortButton from '../../components/TableSortButton';
import GetUser from './GetUser';
import { Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';

function AdminMembers() {
  const [allUsers, setAllUsers] = useState([]);

  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [order, setOrder] = useState(null);
  const [page, setPage] = useState(1);
  const [userSelect, setUserSelect] = useState(null);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  /**
   * ? account_type
   * ? 0 - super_admin
   * ? 1 - on_ramp
   * ? 2 - business
   * ? 3 - user
   * ? 4 - Exchange
   * ? 5 - Listus Channel Reviewer
   */

  const toggle = () => setDropdownOpen((prevState) => !prevState);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
  };

  const getAllUsersAPIFunction = (paramsData) => {
    setIsLoading(true);
    getAllUsersList({ order, page, ...paramsData })
      .then((res) => {
        setIsLoading(false);
        if (res && res.data && res.data.data && res.data.data.data) {
          setAllUsers(res.data.data);
          setTotalCount(res.data.data.count);
        }
      })
      .catch((err) => {});
  };
  useEffect(() => {
    getAllUsersAPIFunction({ limit: 10, userSelect });
  }, [page, order, userSelect]);

  useEffect(() => {
    setPage(1);
  }, [userSelect]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='card'>
          <div className='p-4'>
            <div className='col-12 col-sm-auto font-size-24 font-weight-medium pb-2 pb-sm-0'>
              <div className='d-flex align-items-center justify-content-between'>
                <div className='d-flex justify-content-between'>
                  <span className='text-black'>All Users</span>
                </div>
                <Dropdown isOpen={dropdownOpen} toggle={toggle} direction={'down'}>
                  <DropdownToggle
                    caret
                    color='primary'
                    tag='span'
                    className='p-2 font-size-12 cursor-pointer'
                    style={{ border: '1px solid', borderRadius: '4px' }}>
                    Select User
                  </DropdownToggle>
                  <DropdownMenu className='cursor-pointer'>
                    <DropdownItem
                      key={null}
                      type='button'
                      className='font-size-12'
                      onClick={() => setUserSelect(null)}>
                      All
                    </DropdownItem>
                    <DropdownItem
                      key={2}
                      type='button'
                      className='font-size-12'
                      onClick={() => setUserSelect(2)}>
                      Business
                    </DropdownItem>
                    <DropdownItem
                      key={1}
                      type='button'
                      className='font-size-12'
                      onClick={() => setUserSelect(1)}>
                      On Ramp
                    </DropdownItem>
                    <DropdownItem
                      key={4}
                      type='button'
                      className='font-size-12'
                      onClick={() => setUserSelect(4)}>
                      Exchange
                    </DropdownItem>
                    <DropdownItem
                      key={3}
                      type='button'
                      className='font-size-12'
                      onClick={() => setUserSelect(3)}>
                      Users
                    </DropdownItem>
                  </DropdownMenu>
                </Dropdown>
              </div>
              <div className='table-responsive'>
                <table className='table table-centered table-hover'>
                  <thead className='center-text'>
                    <tr className='font-size-14 text-muted'>
                      <th className='pl-4 pt-3 pb-4 border-0'>#</th>
                      <th className='pt-3 pb-4 border-0'>Email</th>
                      <th className='pr-4 pt-3 pb-4 border-0'>Registered From</th>
                      <th className='pt-3 pb-4 border-0'>
                        <div className='d-flex align-items-center'>
                          <span>Account type</span>
                        </div>
                      </th>

                      <th className='pt-3 pb-4 border-0'>
                        {' '}
                        <div className='d-flex align-items-center'>
                          <span>Comapany Name</span>
                        </div>
                      </th>
                      <th className='pt-3 pb-4 border-0'>
                        <div className='d-flex align-items-center'>
                          <span>Provider</span>
                        </div>
                      </th>
                      <th className='pr-4 pt-3 pb-4 border-0'>Status</th>
                      <th className='pt-3 pb-4 border-0'>API Key</th>
                      <th className='pr-4 pt-3 pb-4 border-0'>Secret Key</th>
                      <th className='pr-4 pt-3 pb-4 border-0'>Revenue (%)</th>
                      <th className='pr-4 pt-3 pb-4 border-0'>
                        <div className='d-flex align-items-center'>
                          <span>Registered At</span>
                          <TableSortButton
                            className='ml-1'
                            value={order}
                            topValue='created_at_desc'
                            bottomValue='created_at_asc'
                            onChange={(v) => {
                              setPage(1);
                              setOrder(v);
                            }}
                          />
                        </div>
                      </th>
                      <th className='pr-4 pt-3 pb-4 border-0'>Is api key approved?</th>
                      <th className='pr-4 pt-3 pb-4 border-0'>
                        <div className='d-flex align-items-center'>
                          <span>Approve API keys</span>
                        </div>
                      </th>
                      <th className='pr-4 pt-3 pb-4 border-0'>
                        <div className='d-flex align-items-center'>
                          <span>Action</span>
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={14}>
                          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                            <i
                              className='fas fa-circle-notch fa-spin text-muted'
                              style={{ fontSize: 48 }}></i>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {get(allUsers, 'data', []).map((user, key) => (
                          <tr
                            key={key}
                            className='center-text font-size-12 font-weight-medium'
                            onClick={() => {}}>
                            <GetUser
                              index={key}
                              page={page}
                              user={user}
                              setIsLoading={setIsLoading}
                              getAllUsersAPIFunction={getAllUsersAPIFunction}
                            />
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Paginator
              rows={10}
              totalRecords={totalCount}
              first={first}
              onPageChange={(e) => onPageChange(e)}
            />
          </div>
        </div>
      </div>

      <NotificationToastContainer />
    </React.Fragment>
  );
}
export default AdminMembers;
