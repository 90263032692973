import React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, CardBody, Col, Row } from 'reactstrap';
import { constants as ROUTE_CONST } from '../../constants/routes';
import { GetAllProviders } from '../../services/Providers/AdminProviders';

function ProviderMatrix() {
  const [providers, setProviders] = useState([]);

  const providersFunction = () => {
    GetAllProviders()
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        if (data && data.length > 0) {
          setProviders(data);
        } else {
          setProviders([]);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    providersFunction();
  }, []);

  return (
    <div>
      <Row>
        {providers &&
          providers.map((provider, i) => (
            <CommonProviderCard key={i} provider={provider} />
          ))}
      </Row>
    </div>
  );
}

export default ProviderMatrix;

const CommonProviderCard = ({ provider }) => {
  return (
    <>
      <Col md={3}>
        <Link
          to={{
            pathname: ROUTE_CONST.PROVIDER,
            search: `?provider=${provider.provider_id}`
          }}>
          <Card
            style={{
              height: '100px',
              boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
              overflow: 'hidden'
            }}>
            <CardBody
              style={{
                textAlign: 'center',
                ...(provider.provider_name === 'Mercuryo'
                  ? { position: 'relative', bottom: '-18px' }
                  : provider.provider_name === 'Banxa'
                  ? { marginTop: '11px' }
                  : provider.provider_name === 'Unlimit Crypto'
                  ? { marginTop: '15px' }
                  : provider.provider_name === 'wyre'
                  ? { marginTop: '-23px' }
                  : {})
              }}>
              <img
                src={provider.logo}
                alt={provider.provider_name}
                width={200}
                style={{
                  ...(provider.provider_name === 'Xanpool'
                    ? { marginTop: '-31px' }
                    : provider.provider_name === 'Munzen' ||
                      provider.provider_name === 'Moonpay'
                    ? { marginTop: '10px' }
                    : {})
                }}
              />
              {/* <span>{provider.provider_name}</span> */}
            </CardBody>
          </Card>
        </Link>
      </Col>
    </>
  );
};
