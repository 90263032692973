import React, { useCallback, useEffect, useState } from 'react';
import { StreamChat } from 'stream-chat';
import {
  Chat,
  ChannelList,
  Channel,
  Window,
  MessageInput,
  ChannelHeader,
  VirtualizedMessageList,
  Thread
} from 'stream-chat-react';
import {
  GetStreamUserDataAPI,
  GetStreamTokenAPI,
  CreateStreamUserAPI,
  CreateStreamChannelAPI,
  GetChannelsDetailsAPI
} from '../../../services/Auth';
import { StringParam, useQueryParam } from 'use-query-params';
import 'stream-chat-react/dist/css/index.css';
import { Button, Row } from 'reactstrap';

function AppChat({ applicationAll, activeTab }) {
  // const client = new StreamChat(process.env.REACT_APP_STREAM_CHAT_API);
  const client = new StreamChat(process.env.REACT_APP_STREAM_CHAT_API);

  const [applicationId] = useQueryParam('application_id', StringParam);
  const [currentUser, setCurrentUser] = useState(null);
  const [userChannels, setUserChannels] = useState(false);
  const [userToken, setUserToken] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [showNotificationBanner, setShowNotificationBanner] = useState(false);
  const sort = { last_message_at: -1 };

  const fetchUserData = useCallback(async () => {
    try {
      setIsLoading(true);
      const userDataResponse = await GetStreamUserDataAPI(applicationId);
      if (userDataResponse?.data?.data?.data?.[0]) {
        const channelsResponse = await GetChannelsDetailsAPI(applicationId);
        const tokenResponse = await GetStreamTokenAPI(applicationId);
        const userData = userDataResponse.data.data.data[0];
        setCurrentUser(userData);
        if (channelsResponse?.data?.data?.data) {
          setUserChannels(channelsResponse.data.data.data);
        }
        if (tokenResponse?.data?.data?.data) {
          const token = tokenResponse.data.data.data;
          setUserToken(token);
        }
      }
      setIsLoading(false);
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, [applicationId]);

  const createStreamChannel = async (applicationId) => {
    CreateStreamChannelAPI(applicationId)
      .then((res) => {
        fetchUserData();
      })
      .catch((err) => {
        console.error('Error creating Stream channel:', err);
      });
  };

  const handleNewMessage = (event) => {
    if (Notification.permission === 'granted') {
      new Notification(event.user.name, {
        body: event.message.text
      });
    }
  };

  useEffect(() => {
    fetchUserData();
  }, [applicationId, applicationAll, activeTab, fetchUserData]);

  useEffect(() => {
    if (window.Notification && Notification.permission === 'default') {
      setShowNotificationBanner(true);
    }

    client.on('connection.changed', ({ online = false }) => {
      if (!online) console.log('connection lost');
    });

    client
      .connectUser({ id: applicationId, name: currentUser?.name }, userToken)
      .then(() => {
        console.log('Connected to Stream Chat');
        client.on('message.new', handleNewMessage);
      })
      .catch((error) => {
        console.error('Error connecting to Stream Chat:', error);
      });
  }, [applicationId, userToken, currentUser, client]);

  const createStreamUser = async () => {
    try {
      setIsLoading(true);
      await CreateStreamUserAPI(applicationId);
      await createStreamChannel(applicationId);
      setIsLoading(false);
    } catch (err) {
      console.error('Error creating Stream user or channel:', err);
    }
  };

  const grantPermission = () => {
    Notification.requestPermission().then((result) => {
      if (result === 'granted') {
        new Notification('Notifications enabled', {
          body: 'You will now receive notifications for new messages'
        });
      }
      setShowNotificationBanner(false);
    });
  };

  return (
    <div>
      {showNotificationBanner && (
        <div className='notification-banner'>
          <p>
            We needs your permission to enable desktop notifications
            <button onClick={grantPermission} className='btn'>
              Click here
            </button>
          </p>
        </div>
      )}

      {isLoading ? (
        <div className='h-100 d-flex align-items-center justify-content-center py-4'>
          <i
            className='fas fa-circle-notch fa-spin text-muted'
            style={{ fontSize: 48 }}></i>
        </div>
      ) : (
        <>
          {userChannels &&
          currentUser &&
          Object.keys(currentUser).length > 0 &&
          applicationId &&
          applicationAll &&
          applicationAll?.business_user_id ? (
            <Chat client={client}>
              <ChannelList
                filters={{
                  type: 'messaging',
                  members: {
                    $in: [currentUser?.id]
                  }
                }}
                sort={sort}
              />
              <Channel>
                <Window>
                  <ChannelHeader />
                  <VirtualizedMessageList
                    additionalVirtuosoProps={{
                      increaseViewportBy: { top: 400, bottom: 200 }
                    }}
                  />
                  <MessageInput autoFocus={true} />
                </Window>
                <Thread autoFocus={true} />
              </Channel>
            </Chat>
          ) : (
            <Row
              className='d-flex justify-content-center align-items-center flex-column'
              style={{ height: '77vh' }}>
              <span className='mb-2 font-size-22 text-center' style={{ width: '500px' }}>
                If you have any query with the business start conversation
              </span>
              <Button
                className='btn btn-status-change'
                style={{ color: 'white', width: '200px' }}
                onClick={createStreamUser}>
                Start
              </Button>
            </Row>
          )}
        </>
      )}
    </div>
  );
}

export default AppChat;
