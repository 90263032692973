/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';

function AlchemyPayWidget({ backToWidget, buyData }) {
  let api = `${process.env.REACT_APP_ALCHEMY_PAY}/?fiat=${buyData.fiatCurrency}&fiatAmount=${buyData.fiatAmount}&showTable=buy`;

  if (buyData['network']) api += `&network=${buyData['network']}`;

  if (buyData['crypto']) api += `&crypto=${buyData['crypto']}`;
  return (
    <>
      <button
        type="button"
        className="btn btn-light p-1 mb-2 ml-2 mt-2"
        style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
        onClick={backToWidget}>
        <ArrowLeftIcon width={18} height={18} />
      </button>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <iframe
          style={{
            width: '100%',
            minHeight: '100%',
            height: '88vh',
            borderStyle: 'none'
          }}
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          frameborder="0"
          src={api}
        />
      </div>
    </>
  );
}

export default AlchemyPayWidget;
