import userApi from '../../api/userApi';
import * as adminUrl from '../../api/userURL';

export const investorsListAPI = (data) => {
  return new Promise(async (resolve, reject) => {
    return userApi
      .getWithToken(adminUrl.INVESTORS_NAME, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};