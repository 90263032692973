import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepFifteen(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const step = 11;
  const [initialValue, setInitialValue] = useState({
    listing_price: '$ 1'
  });

  const ListingPriceSchema = Yup.object().shape({
    listing_price: Yup.string().required(ERROR_CONST.LISTING_PRICE_REQUIRED)
  });
  const [completeStep, setCompleteStep] = useState(0);
  const applicationData = props.applicationData.exchange_application;

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.listing_price) {
          setInitialValue({
            listing_price: application.exchange_application.listing_price
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 75));
    if (values.listing_price !== applicationData.listing_price) {
      const data = {
        listing_price: values.listing_price,
        percentage_complete: stepsRequired
      };
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          props.setApplicationData(application);
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    } else {
      nextPage();
    }
    setExchangeCompleteStep([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15]);
  };

  const calculateProgress = () => {
    return completeStep;
  };
  return (
    <React.Fragment>
      <div className='col-12 font-size-20'>
        <span className='text-black'>Potential listing price at Exchange</span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={ListingPriceSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, setFieldValue }) => (
              <Form>
                <div>
                  <Row>
                    <Col>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='listing_price'
                            className='text-black font-weight-normal'>
                            {CONST.LISTING_PRICE}
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            type='text'
                            className='font-size-12'
                            name='listing_price'
                            values={values}
                            handleChange={(e) => {
                              if (e.target.value.endsWith('.')) {
                                setFieldValue('listing_price', e.target.value);
                                return;
                              }

                              const rawValue = e.target.value.replace(/[^0-9.]/g, '');

                              const decimalValue =
                                rawValue.split('.').length > 2
                                  ? rawValue.slice(0, rawValue.lastIndexOf('.'))
                                  : rawValue;

                              const parts = decimalValue.split('.');
                              const formattedValue =
                                parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                                (parts[1] ? '.' + parts[1] : '');

                              if (formattedValue) {
                                setFieldValue('listing_price', '$' + formattedValue);
                              } else {
                                setFieldValue('listing_price', '$0');
                              }
                            }}
                            errors={errors}
                            touched={touched}></AuthInput>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepFifteen;
