import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';
import AuthInput from '../../../Custom/AuthInput';

function StepSix(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const CapMarketSchema = Yup.object().shape({
    number_active_users: Yup.string().required(ERROR_CONST.ROADMAP_REQUIRED)
  });
  const [initialValue, setInitialValue] = useState({
    number_active_users: ''
  });
  const step = 6;
  const [completeStep, setCompleteStep] = useState(0);
  const applicationData = props.applicationData.exchange_application;

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (
          application.exchange_application &&
          application.exchange_application.number_of_active_users
        ) {
          setInitialValue({
            number_active_users: Number(
              application.exchange_application.number_of_active_users +
                ''.replace(/[^0-9]/g, '')
            ).toLocaleString()
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);
  function convertToNumber(str) {
    const sanitizedString = str.replace(/[,]/g, ''); // Remove $ and commas
    return parseInt(sanitizedString, 10); // Convert to integer
  }
  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 43));
    if (values.number_active_users !== applicationData.number_of_active_users) {
      const data = {
        number_of_active_users: convertToNumber(values.number_active_users),
        percentage_complete: stepsRequired
      };
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          props.setApplicationData(application);
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
      setExchangeCompleteStep([0, 1, 2, 3, 4, 5, 6]);
    } else {
    }
    nextPage();
  };

  const calculateProgress = () => {
    return completeStep;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-22 pb-1'>
        <span className='text-black'>Number of active users in the product</span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={CapMarketSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, setErrors, setFieldValue }) => (
              <Form>
                <div>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='number_active_users'
                            className='text-black font-weight-normal'>
                            {CONST.NUMBEROFACTIVEUSERS}
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            type='text'
                            name='number_active_users'
                            className='font-size-12'
                            values={values}
                            placeholder={'Enter total amount of invesment stage'}
                            handleChange={(e) => {
                              setFieldValue(
                                'number_active_users',
                                Number(
                                  e.target.value.replace(/[^0-9]/g, '')
                                ).toLocaleString()
                              );
                            }}
                            errors={errors}
                            touched={touched}></AuthInput>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepSix;
