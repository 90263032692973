import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';
import get from 'lodash/get';

export const GetNews = ({ limit, page = 1, search = '' }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.CRYPTO_NEWS +
          `?page=${page}` +
          (limit ? `&limit=${limit}` : '') +
          (search ? `&crypto=${String(search || '').toLocaleUpperCase()}` : '')
      )
      .then((res) => {
        if (get(res, 'data.data.data.length') > 0)
          return Promise.resolve(get(res, 'data.data'));
        else
          return api
            .getWithToken(
              businessURL.NEWS +
                `?page=${page}` +
                (limit ? `&limit=${limit}` : '') +
                (search ? `&topic=${search}` : '')
            )
            .then((res) => get(res, 'data.data', {}));
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
