import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { GetProviderAllPaymentMethods } from '../../services/Providers/Provider';
import { constants as CONST } from '../../constants/constant';
import { useDebounce } from 'use-debounce';
import SimpleBar from 'simplebar-react';
import get from 'lodash/get';

function SupportedPaymentOptions({ provider }) {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 400);
  const [loading, setLoading] = useState(false);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState(
    get(paymentOptions, '[0].method', null)
  );

  useEffect(() => {
    setLoading(true);
    GetProviderAllPaymentMethods(provider, debouncedSearch)
      .then((res) => {
        setLoading(false);
        const data = res && res.data && res.data.data && res.data.data.data;
        if (data && data.length > 0) {
          setPaymentOptions(data);
        } else {
          setPaymentOptions([]);
        }
        setSelectedPaymentMethod(get(data, '[0].method', null));
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [debouncedSearch, provider]);

  const activePaymentOption = paymentOptions.find(
    ({ method }) => method === selectedPaymentMethod
  );

  return (
    <>
      <Card className='pt-4 px-4 pb-3 h-100'>
        <div className='row mb-4 align-items-center'>
          <div className='col-12 col-sm font-size-24 font-weight-medium text-black text-truncate'>
            {CONST.SUPPORTED_PAYMENT_OPTIONS}
          </div>
        </div>
        <div className='row mb-4 align-items-center'>
          <div className='col-12 col-md py-1'>
            <input
              type='text'
              className='form-control form-control-search'
              placeholder='Search'
              value={search}
              onInput={(e) => setSearch(e.target.value)}
              onChange={(e) => setSearch(e.target.value)}
              style={{ minWidth: '200px', maxWidth: '368px' }}
            />
          </div>
          <div className='col-12 col-md-auto d-flex flex-wrap font-size-16 text-black py-1'>
            {loading ? (
              <div className='text-muted font-size-16'>
                <i className='fas fa-circle-notch fa-spin mr-2'></i>
                <span>Loading...</span>
              </div>
            ) : (
              paymentOptions &&
              paymentOptions.length > 0 &&
              paymentOptions.map((option, key) => (
                <a
                  key={key}
                  href='!#'
                  className={
                    'badge badge-pill mb-1 mr-2 ' +
                    (selectedPaymentMethod === option.method
                      ? 'badge-dark'
                      : 'badge-secondary')
                  }
                  onClick={(evt) => {
                    evt.preventDefault();
                    setSelectedPaymentMethod(option.method);
                  }}>
                  {option.method_name}
                </a>
              ))
            )}
          </div>
        </div>
        {activePaymentOption && (
          <div className='row align-items-stretch'>
            {['country', 'currency'].map((key) => (
              <div key={key} className='col-12 col-md-6 pb-3'>
                <div className='border rounded p-3 h-100'>
                  <div className='d-flex align-items-center border-bottom pb-3 mb-2'>
                    {activePaymentOption.logo !== '' && (
                      <div className='pr-3'>
                        <img
                          src={activePaymentOption.logo}
                          alt={activePaymentOption.method}
                          height={32}
                        />
                      </div>
                    )}
                    <div className='flex-fill font-size-18 font-weight-medium text-capitalize'>
                      {key === 'currency' ? 'Currencies' : 'Countries'}
                    </div>
                  </div>
                  <SimpleBar style={{ maxHeight: '260px' }}>
                    {get(activePaymentOption, key, []).map((item, i) => (
                      <div key={i} className='d-flex align-items-center pb-2'>
                        {key === 'currency' ? (
                          <>
                            <div className='flex-fill font-size-16'>{item}</div>
                          </>
                        ) : (
                          <>
                            <div className='pr-3 flex-fill font-size-16'>
                              {item.country_name}
                            </div>
                            <div className=' py-1'>
                              <img
                                src={item.logo.replace('/3x2/', '/1x1/')}
                                alt=''
                                height={18}
                                className='rounded-circle'
                              />
                            </div>
                          </>
                        )}
                      </div>
                    ))}
                  </SimpleBar>
                </div>
              </div>
            ))}
          </div>
        )}
      </Card>
    </>
  );
}

export default SupportedPaymentOptions;
