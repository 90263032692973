import { GateFiDisplayModeEnum } from "./types";
import { OVERLAY_ID } from "./layouts";
import { domControlService } from "./services/DOMControlService";
import { isValidUrl } from "./utils";
var ConfigValidator = (function () {
    function ConfigValidator() {
    }
    ConfigValidator.prototype.validate = function (config) {
        if (!config.merchantId && typeof config.merchantId !== "string") {
            return "merchantId should be a string";
        }
        if ((config === null || config === void 0 ? void 0 : config.isSandbox) && typeof config.isSandbox !== "boolean") {
            return "isSandbox should be a boolean";
        }
        if (!Object.values(GateFiDisplayModeEnum).includes(config.displayMode)) {
            return "displayMode: ".concat(config.displayMode, " is not exist");
        }
        if (config.displayMode === GateFiDisplayModeEnum.Embedded && !config.nodeSelector) {
            return "nodeSelector is a required field for embedded mode";
        }
        if (config.displayMode === GateFiDisplayModeEnum.Overlay && domControlService.isNodeExist("#".concat(OVERLAY_ID))) {
            return "GateFiSDK with 'overlay' display mode already exist";
        }
        if (config.nodeSelector && !domControlService.isNodeExist(config.nodeSelector)) {
            return "node with id ".concat(config.nodeSelector, " does not exist");
        }
        if (config.successUrl && !isValidUrl(config.successUrl)) {
            return "successUrl should be a valid URL";
        }
        if (config.cancelUrl && !isValidUrl(config.cancelUrl)) {
            return "cancelUrl should be a valid URL";
        }
        if (config.declineUrl && !isValidUrl(config.declineUrl)) {
            return "declineUrl should be a valid URL";
        }
        if ((config === null || config === void 0 ? void 0 : config.externalId) && typeof config.externalId !== "string") {
            return "externalId should be a string";
        }
        if ((config === null || config === void 0 ? void 0 : config.externalId) && config.externalId.length > 64) {
            return "externalId length should not be more 64 chars";
        }
        if ((config === null || config === void 0 ? void 0 : config.hideAsset) && typeof config.hideAsset !== "boolean") {
            return "hideAsset should be a boolean";
        }
        return null;
    };
    return ConfigValidator;
}());
export { ConfigValidator };
export var configValidator = new ConfigValidator();
