/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';

let htmlText = `
          <head>
            <script src="https://cdn.widget.sandbox.xanpool.com/xanpool-sdk-v1.js"></script>
            <script>
              function onWidgetEvent(message) {
                console.log('Received message ' +message.type);
                console.log('Message payload: ' +message.payload);
              }

              function onLoad() {
                const options = { 
                  apiKey: 'XANPOOL_API_KEY',
                  transactionType:'XANPOOL_TRANSACTION_TYPE', 
                  chain: 'XANPOOL_NETWORK', 
                  cryptoCurrency:'XANPOOL_CRYPTO', 
                  currency: 'XANPOOL_FIAT_CURRENCY', 
                  fiat: XANPOOL_CURRENCY_VALUE,
                  method: XANPOOL_PAYMENT_METHOD
                };
                const widget = new XanPoolWidget(
                      options, document.getElementById("widgetContainer"), onWidgetEvent
                );
                widget.init();

              }
            </script>
            <style type="text/css">
              .widgetContainer {
                width: 100%;
                height: 100%;
              }
            </style>
          </head>
          <body onload="onLoad()">
          <div id="widgetContainer" class="widgetContainer"/>
          </body>
      `;

function XanpoolWidget({ backToWidget, buyData }) {
  htmlText = htmlText.replace(/XANPOOL_NETWORK/g, buyData.network);
  htmlText = htmlText.replace(/XANPOOL_CRYPTO/g, buyData.cryptoCurrency);
  htmlText = htmlText.replace(/XANPOOL_TRANSACTION_TYPE/g, 'buy');
  htmlText = htmlText.replace(/XANPOOL_API_KEY/g, 'c87bd2c44cd3209f9ad01be1ee823f36');
  htmlText = htmlText.replace(/XANPOOL_FIAT_CURRENCY/g, buyData.fiat_currency);
  htmlText = htmlText.replace(/XANPOOL_CURRENCY_VALUE/g, buyData.fiat_value);
  htmlText = htmlText.replace(/XANPOOL_PAYMENT_METHOD/g, buyData.payment_method);
  return (
    <div>
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light p-1 mb-2"
          style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
          onClick={backToWidget}>
          <ArrowLeftIcon width={18} height={18} />
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <iframe
          frameBorder="0"
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          style={{ height: '578px', width: '450px' }}
          srcDoc={htmlText}
        />
      </div>
    </div>
  );
}

export default XanpoolWidget;
