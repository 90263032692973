import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const BusinessSearchAPI = (search) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.ALL_SEARCH + `?search=${search}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const MercuryoWidgetApi = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(businessURL.MERCURYO_WIDGET, payload)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetETHApi = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(businessURL.GET_ETH)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetAllProvidersListAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.PROVIDERS)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UpdateProvidersAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(businessURL.PROVIDERS, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
