import React, { useState } from 'react';
import { Col, Row, Input } from 'reactstrap';
import { setAddQuestions } from '../../../../services/Onramp/Application';
import { notifyError, notifySuccess } from '../../../../Custom/notification';

const AddQuestions = (props) => {
  const [questionsData, setQuestionsData] = useState([
    { question: '', type: '', showError: false }
  ]);

  const handleAddQuestion = () => {
    const lastQuestion = questionsData[questionsData.length - 1];

    if (lastQuestion.question.trim() === '' || lastQuestion.type === '') {
      const updatedQuestionsData = [...questionsData];
      updatedQuestionsData[questionsData.length - 1].showError = true;
      setQuestionsData(updatedQuestionsData);
      return;
    }

    setQuestionsData([...questionsData, { question: '', type: '', showError: false }]);
  };

  const handleResetQuestions = () => {
    setQuestionsData([{ question: '', type: '', showError: false }]);
  };

  const handleTextboxChange = (index, value) => {
    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index].question = value;
    setQuestionsData(updatedQuestionsData);
  };

  const handleDropdownChange = (index, value) => {
    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData[index].type = value;
    setQuestionsData(updatedQuestionsData);
  };

  const handleRemoveQuestion = (index) => {
    const updatedQuestionsData = [...questionsData];
    updatedQuestionsData.splice(index, 1);
    setQuestionsData(updatedQuestionsData);
  };

  const isFormValid = () => {
    for (const question of questionsData) {
      if (question.question.trim() === '' || question.type === '') {
        return false;
      }
    }
    return true;
  };

  const handleSubmit = () => {
    if (isFormValid()) {
      let submitData = {
        add_questions: questionsData,
        status: 'ADD_QUESTIONS'
      };

      setAddQuestions(props.applicationId, submitData)
        .then((res) => {
          setQuestionsData([{ question: '', type: '' }]);
          props.setAddQuestions(res.data.data.data);
          notifySuccess(res.data.data.message);
          props.GetApplicationResponseFunc(props.applicationId);
        })
        .catch((error) => {
          notifyError(`${error?.data?.error?.message}`);
        });
    }
  };

  return (
    <React.Fragment>
      <div className='text-left'>
        <label>Add Questions</label>
      </div>
      <div className='p-2' style={{ border: '1px solid #80808047' }}>
        {questionsData.map((question, index) => (
          <React.Fragment key={index}>
            <Row className='mb-2' key={index}>
              <Col lg={1} md={1} sm={1}>
                <span>{index + 1}</span>
              </Col>
              <Col lg={9} md={9} sm={9}>
                <Input
                  type='textarea'
                  className='font-size-12'
                  value={question.question}
                  placeholder='Add your question'
                  name='question'
                  onChange={(e) => handleTextboxChange(index, e.target.value)}
                />
                {question.showError && question.question.trim() === '' && (
                  <div className='text-danger font-size-12'>Question is required.</div>
                )}
                <select
                  value={question.type}
                  name='option'
                  onChange={(e) => handleDropdownChange(index, e.target.value)}
                  className='form-control mt-2 font-size-12'>
                  <option value=''>Select field option</option>
                  <option value='text'>Text</option>
                  <option value='file'>File</option>
                </select>
                {question.showError && question.type === '' && (
                  <div className='text-danger font-size-12'>Option is required.</div>
                )}
              </Col>
              <div className='d-flex' style={{ flexDirection: 'column' }}>
                {index === 0 && questionsData && questionsData.length <= 1 && (
                  <>
                    <Col lg={1} md={1} sm={1} className='font-size-24'>
                      <i
                        className='mdi mdi-plus-circle-outline cursor-pointer'
                        onClick={handleAddQuestion}></i>{' '}
                    </Col>
                    <Col lg={1} md={1} sm={1} className='font-size-24'>
                      <i
                        className='mdi mdi-refresh cursor-pointer'
                        onClick={() => handleResetQuestions()}></i>
                    </Col>
                  </>
                )}
                {index !== 0 && (
                  <>
                    {index === questionsData.length - 1 && (
                      <Col lg={1} md={1} sm={1} className='font-size-24'>
                        <i
                          className='mdi mdi-plus-circle-outline cursor-pointer'
                          onClick={handleAddQuestion}></i>{' '}
                      </Col>
                    )}

                    <Col lg={1} md={1} sm={1} className='font-size-20 ml-1'>
                      <i
                        className='fas fa-trash-alt cursor-pointer'
                        style={{ color: 'red' }}
                        onClick={() => handleRemoveQuestion(index)}></i>
                    </Col>
                  </>
                )}
              </div>
            </Row>
            <hr
              style={{
                borderColor: 'grey',
                borderWidth: '1px',
                margin: '10px auto',
                width: '90%'
              }}
            />
          </React.Fragment>
        ))}
        <div className='d-flex align-items-center justify-content-center mt-3'>
          <button
            disabled={!isFormValid()}
            onClick={handleSubmit}
            className='btn btn-primary cursor-pointer'
            style={{ width: 100 }}>
            Submit
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default AddQuestions;
