import { Field } from 'formik';
import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../../constants/application';
import documentUploadedIcon from '../../../../assets/images/documentuploaded.png';
import documentUploadIcon from '../../../../assets/images/documentupload.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function PartnerEntity({
  values,
  errors,
  touched,
  optionalFile,
  fileUrls,
  changeHandler
}) {
  return (
    <div className='mt-2 ml-2'>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-2 font-size-12'>
            <div className='font-size-12'>
              <Label htmlFor='regulatory_auth' className='text-black font-weight-normal'>
                Is the partner entity subject to any regulatory authorization or licensing
                requirements?
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='d-flex align-items-center mr-4'>
                <Field
                  name='regulatory_auth'
                  type='radio'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.YES}</Label>
              </div>
              <div className='d-flex align-items-center  ml-4'>
                <Field
                  name='regulatory_auth'
                  type='radio'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.NO}</Label>
              </div>
            </div>
            <div className='d-flex'>
              {errors['regulatory_auth'] && touched['regulatory_auth'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['regulatory_auth']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-2 font-size-12'>
            <div className='font-size-12'>
              <Label
                htmlFor='AML_CTF_compliance'
                className='text-black font-weight-normal'>
                Does the partner entity maintain an AML/CTF compliance program?
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='d-flex align-items-center mr-4'>
                <Field
                  name='AML_CTF_compliance'
                  type='radio'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.YES}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='AML_CTF_compliance'
                  type='radio'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.NO}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='AML_CTF_compliance'
                  type='radio'
                  value='null'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.NA}</Label>
              </div>
            </div>
            <div className='d-flex'>
              {errors['AML_CTF_compliance'] && touched['AML_CTF_compliance'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['AML_CTF_compliance']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-2 font-size-12'>
            <div className='font-size-12'>
              <Label
                htmlFor='KYC_checks_on_users'
                className='text-black font-weight-normal'>
                Does the partner entity perform KYC checks on its users?
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='d-flex align-items-center mr-4'>
                <Field
                  name='KYC_checks_on_users'
                  type='radio'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.YES}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='KYC_checks_on_users'
                  type='radio'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.NO}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='KYC_checks_on_users'
                  type='radio'
                  value='null'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.NA}</Label>
              </div>
            </div>
            <div className='d-flex'>
              {errors['KYC_checks_on_users'] && touched['KYC_checks_on_users'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['KYC_checks_on_users']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-2 font-size-12'>
            <div className='font-size-12'>
              <Label htmlFor='users_screening' className='text-black font-weight-normal'>
                Does the partner entity perform sanctions screening of its users?
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='d-flex align-items-center mr-4'>
                <Field
                  name='users_screening'
                  type='radio'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.YES}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='users_screening'
                  type='radio'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.NO}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='users_screening'
                  type='radio'
                  value='null'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.NA}</Label>
              </div>
            </div>
            <div className='d-flex'>
              {errors['users_screening'] && touched['users_screening'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['users_screening']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-2 font-size-12'>
            <div className='font-size-12'>
              <Label
                htmlFor='services_to_minors'
                className='text-black font-weight-normal'>
                Does the partner entity perform sanctions screening of its users?
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='d-flex align-items-center mr-4'>
                <Field
                  name='services_to_minors'
                  type='radio'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.YES}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='services_to_minors'
                  type='radio'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.NO}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='services_to_minors'
                  type='radio'
                  value='null'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-1'>{CONST.NA}</Label>
              </div>
            </div>
            <div className='d-flex'>
              {errors['services_to_minors'] && touched['services_to_minors'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['services_to_minors']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-2 font-size-12'>
            <div className='font-size-12'>
              <Label htmlFor='description' className='text-black font-weight-normal'>
                Required
              </Label>
            </div>
            <div
              className='pt-2 d-flex justify-content-between font-size-12'
              style={{
                flexDirection: 'column',
                borderRadius: '8px',
                border: '1px solid'
              }}>
              <div className='font-size-12'>
                <Label htmlFor='corporate_registration_doc' id='registration_doc'>
                  <span
                    className='p-1 mb-1 rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 15,
                      cursor: 'pointer'
                    }}>
                    <img
                      src={
                        optionalFile['corporate_registration_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='Corporate Registration Documents'></img>
                  </span>
                  <span className='font-size-12'>Corporate Registration Documents</span>
                </Label>
                {fileUrls.corporate_registration_doc && (
                  <a
                    href={fileUrls.corporate_registration_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
                {!optionalFile['corporate_registration_doc'] && (
                  <div style={{ fontSize: 12 }} className='text-left text-danger pl-4'>
                    {errors.corporate_registration_doc}
                  </div>
                )}
                <Input
                  type='file'
                  id='corporate_registration_doc'
                  name='corporate_registration_doc'
                  onChange={changeHandler}
                  style={{ display: 'none' }}
                />
                <ReactTooltip
                  anchorId='registration_doc'
                  place='bottom'
                  content={
                    optionalFile['corporate_registration_doc'] &&
                    optionalFile['corporate_registration_doc'].name
                  }
                />
              </div>
              <div>
                <Label htmlFor='terms_of_service_doc' id='terms_of_service'>
                  <span
                    className='p-1 mb-2 rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 14,
                      cursor: 'pointer'
                    }}>
                    <img
                      src={
                        optionalFile['terms_of_service_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='Terms of Service'></img>
                  </span>
                  <span className='font-size-12'>Terms of Service</span>
                </Label>
                {fileUrls.terms_of_service_doc && (
                  <a
                    href={fileUrls.terms_of_service_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
                {!optionalFile['terms_of_service_doc'] && (
                  <div style={{ fontSize: 12 }} className='text-left text-danger pl-4'>
                    {errors.terms_of_service_doc}
                  </div>
                )}
                <Input
                  type='file'
                  id='terms_of_service_doc'
                  name='terms_of_service_doc'
                  onChange={changeHandler}
                  style={{ display: 'none' }}
                />
                <ReactTooltip
                  anchorId='terms_of_service'
                  place='bottom'
                  content={
                    optionalFile['terms_of_service_doc'] &&
                    optionalFile['terms_of_service_doc'].name
                  }
                />
              </div>
              <div>
                <Label htmlFor='privacy_policy_doc' id='privacy_policy'>
                  <span
                    className='p-1 mb-2 rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 14,
                      cursor: 'pointer'
                    }}>
                    <img
                      src={
                        optionalFile['privacy_policy_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='Privacy Policy'></img>
                  </span>
                  <span className='font-size-12'>Privacy Policy</span>
                </Label>
                {fileUrls.privacy_policy_doc && (
                  <a
                    href={fileUrls.privacy_policy_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
                {!optionalFile['privacy_policy_doc'] && (
                  <div style={{ fontSize: 12 }} className='text-left text-danger pl-4'>
                    {errors.privacy_policy_doc}
                  </div>
                )}
                <Input
                  type='file'
                  id='privacy_policy_doc'
                  name='privacy_policy_doc'
                  onChange={changeHandler}
                  style={{ display: 'none' }}
                />
                <ReactTooltip
                  anchorId='privacy_policy'
                  place='bottom'
                  content={
                    optionalFile['privacy_policy_doc'] &&
                    optionalFile['privacy_policy_doc'].name
                  }
                />
              </div>
              <div>
                <Label htmlFor='AML_CTF_policy_doc' id='aml_ctf_policy'>
                  <span
                    className='p-1 mb-2 rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 14,
                      cursor: 'pointer'
                    }}>
                    <img
                      src={
                        optionalFile['AML_CTF_policy_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='AML CTF Compliance'></img>
                  </span>
                  <span className='font-size-12'>AML / CTF Compliance</span>
                </Label>
                {fileUrls.AML_CTF_policy_doc && values.AML_CTF_compliance === 'true' && (
                  // {fileUrls.AML_CTF_policy_doc && (
                  <a
                    href={fileUrls.AML_CTF_policy_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
                {!optionalFile['AML_CTF_policy_doc'] && (
                  <div style={{ fontSize: 12 }} className='text-left text-danger'>
                    {errors.AML_CTF_policy_doc}
                  </div>
                )}
                <Input
                  type='file'
                  id='AML_CTF_policy_doc'
                  name='AML_CTF_policy_doc'
                  onChange={changeHandler}
                  style={{ display: 'none' }}
                />
                <ReactTooltip
                  anchorId='aml_ctf_policy'
                  place='bottom'
                  content={
                    optionalFile['AML_CTF_policy_doc'] &&
                    optionalFile['AML_CTF_policy_doc'].name
                  }
                />
              </div>
              <div>
                <Label htmlFor='licence_doc' id='licence'>
                  <span
                    className='p-1 mb-2 rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 15,
                      cursor: 'pointer'
                    }}>
                    <img
                      src={
                        optionalFile['licence_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='Copy of License'></img>
                  </span>
                  <span className='font-size-12'>Copy of License</span>
                </Label>
                {fileUrls.licence_doc && values.regulatory_auth === 'true' && (
                  <a
                    href={fileUrls.licence_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
                {!optionalFile['licence_doc'] && (
                  <div style={{ fontSize: 12 }} className='text-left text-danger'>
                    {errors.licence_doc}
                  </div>
                )}
                <Input
                  type='file'
                  id='licence_doc'
                  name='licence_doc'
                  onChange={changeHandler}
                  style={{ display: 'none' }}
                />
                <ReactTooltip
                  anchorId='licence'
                  place='bottom'
                  content={
                    optionalFile['licence_doc'] && optionalFile['licence_doc'].name
                  }
                />
              </div>
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-4'>
            <div className='font-size-12'>
              <Label htmlFor='description' className='text-black font-weight-normal'>
                Optional Documents
              </Label>
            </div>
            <div
              className='pt-2 d-flex justify-content-between font-size-12'
              style={{
                flexDirection: 'column',
                borderRadius: '8px',
                border: '1px solid'
              }}>
              <div>
                <Label htmlFor='legal_opinion_doc' id='legal_opinion'>
                  <span
                    className='p-1 mb-2 rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 14,
                      cursor: 'pointer'
                    }}>
                    <img
                      src={
                        optionalFile['legal_opinion_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='Legal Opinion'></img>
                  </span>
                  <span className='font-size-12'>Legal Opinion</span>
                </Label>
                {fileUrls.legal_opinion_doc && (
                  <a
                    href={fileUrls.legal_opinion_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
                <Input
                  type='file'
                  id='legal_opinion_doc'
                  name='legal_opinion_doc'
                  onChange={changeHandler}
                  style={{ display: 'none' }}
                />
                <ReactTooltip
                  anchorId='legal_opinion'
                  place='bottom'
                  content={
                    optionalFile['legal_opinion_doc'] &&
                    optionalFile['legal_opinion_doc'].name
                  }
                />
              </div>
              <div>
                <Label htmlFor='policy_procedures_doc' id='policy_procedures'>
                  <span
                    className='p-1 mb-2 rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 14,
                      cursor: 'pointer'
                    }}>
                    <img
                      src={
                        optionalFile['policy_procedures_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='PoliciesProcedures'></img>
                  </span>
                  Policies / Procedures
                </Label>
                {fileUrls.policy_procedures_doc && (
                  <a
                    href={fileUrls.policy_procedures_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
                <Input
                  type='file'
                  id='policy_procedures_doc'
                  name='policy_procedures_doc'
                  onChange={changeHandler}
                  style={{ display: 'none' }}
                />
                <ReactTooltip
                  anchorId='policy_procedures'
                  place='bottom'
                  content={
                    optionalFile['policy_procedures_doc'] &&
                    optionalFile['policy_procedures_doc'].name
                  }
                />
              </div>
              <div>
                <Label htmlFor='additional_doc' id='additional'>
                  <span
                    className='p-1 mb-2 rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 15,
                      cursor: 'pointer'
                    }}>
                    <img
                      src={
                        optionalFile['additional_docs'].length > 0
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='Additional Documents'></img>
                  </span>
                  Additional Documents
                </Label>
                {fileUrls.additional_docs &&
                  fileUrls.additional_docs.map((url, index) => {
                    return (
                      <div key={index}>
                        <a
                          href={url}
                          target='_blank'
                          rel='noopener noreferrer'
                          className='pl-4 font-size-14 font-weight-bold text-wrap text-primary'>
                          View File
                        </a>
                      </div>
                    );
                  })}
                <Input
                  type='file'
                  id='additional_doc'
                  name='additional_docs'
                  style={{ display: 'none' }}
                  onChange={changeHandler}
                  multiple
                />
                {optionalFile['additional_docs'].length > 0 &&
                  Array.from(optionalFile['additional_docs']).map(([file], index) => (
                    <div style={{ color: '#B9D794' }} key={index}>
                      <Label className='pl-4'>{file.name}</Label>
                    </div>
                  ))}
              </div>
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default PartnerEntity;
