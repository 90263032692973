import React from 'react';
import { Form, Formik } from 'formik';
import { Col, FormGroup, Input, Label } from 'reactstrap';
import * as Yup from 'yup';

function DomainSelectionStep({
  subDomainInitialValues,
  handleUpdateReferralData,
  getReferralsDetailFunction
}) {
  const validationSchema = Yup.object({
    subdomain: Yup.string()
      .required('Subdomain is Required')
      .matches(/^(?!.*\/\/)(?!.*:).*$/, 'Subdomain must not contain // or :')
      .matches(
        /^(?!.*\/\/)(?!.*:)(?!.*\.)[^.]*$/,
        'Subdomain must not contain //, :, or .'
      )
  });

  const handleSubdomains = (values) => {
    const payloadData = {
      subdomain: values.subdomain
    };

    handleUpdateReferralData(payloadData, 'Your brand domain set successfully', 1);
    getReferralsDetailFunction();
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={subDomainInitialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubdomains}>
        {({ values, handleChange, errors, touched }) => (
          <Form>
            <Col lg={6} className='ml-2 mr-3'>
              <FormGroup>
                <Label htmlFor='sub_domain' className='text-black font-size-12'>
                  Subdomain
                </Label>
                <Col className='d-flex flex-row justify-content-center align-items-center pl-0'>
                  <Input
                    type='text'
                    placeholder={'(e.g., mysite)'}
                    value={values.subdomain}
                    name='subdomain'
                    style={{ height: '35px' }}
                    className={
                      errors.subdomain && touched.subdomain
                        ? `font-size-12 border-danger`
                        : `font-size-12 border-dark`
                    }
                    onChange={handleChange}
                  />
                  <span>.listus.xyz</span>
                </Col>
                {errors.subdomain && touched.subdomain ? (
                  <div className='text-danger font-size-12'>{errors.subdomain}</div>
                ) : null}
              </FormGroup>
              <div className='d-flex mb-3'>
                <button
                  type='submit'
                  name='btn'
                  className={`btn btn-auth`}
                  onSubmit={handleSubdomains}
                  style={{ width: 120, marginLeft: 0 }}>
                  Set Domain
                </button>
              </div>
            </Col>
          </Form>
        )}
      </Formik>
      <span className='font-size-12 mx-3'>
        Note: Setting up the new domain will take approximately 15-20 minutes.
      </span>
    </React.Fragment>
  );
}

export default DomainSelectionStep;
