import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import { StyledEngineProvider } from '@mui/material';
import domain from '../../assets/images/domain.png';
import box from '../../assets/images/box.png';
import upload from '../../assets/images/upload.png';
import colorSelection from '../../assets/images/color-selection.png';
import share from '../../assets/images/share.png';
import checkMark from '../../assets/images/check-mark.png';
import DomainSelectionStep from './OnboardingSteps/DomainSelectionStep';
import {
  AddReferralDetailAPI,
  EditReferralDetailAPI,
  GetMyProfileAPI,
  GetReferralDetailAPI
} from '../../services/Auth';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../Custom/notification';
import SelectServices from './OnboardingSteps/SelectServices';
import { notificationMessageFunction } from '../../constants/notificationConst';
import BrandLogo from './OnboardingSteps/BrandLogo';
import CustomizeWidget from './OnboardingSteps/CustomizeWidget';
import ShareUrl from './OnboardingSteps/ShareUrl';
import Completion from './OnboardingSteps/Completion';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const steps = [
  {
    label: 'Subdomain Selection',
    description: 'Choose a unique subdomain to represent your service online.'
  },
  {
    label: 'Choose Services',
    description: 'Explore and select the necessary services tailored to your needs.'
  },
  {
    label: 'Upload Company Logo',
    description: 'Enhance your brand recognition by uploading your company logo.'
  },
  {
    label: 'Customize Design',
    description: 'Tailor the front-end design to align with your brand identity.'
  },
  {
    label: 'Share With Your Clients',
    description:
      'Facilitate client interactions and manage bulk applications to the top 15 exchanges.'
  },
  {
    label: 'Completion',
    description: 'Finalize the setup and launch your personalized service platform.'
  }
];

function Onboarding(props) {
  const [openedSection, setOpenedSection] = useState(0);
  const [referralDetail, setReferralDetail] = useState({});
  const [widgetTheme, setWidgetTheme] = useState('light');
  const [primaryColor, setPrimaryColor] = useState(
    widgetTheme === 'dark' ? '#222229' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
  );
  const [containerColor, setContainerColor] = useState(
    widgetTheme === 'dark' ? '#141416' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
  );
  const [primaryText, setPrimaryText] = useState(
    widgetTheme === 'dark' ? '#ffffff' : widgetTheme === 'light' ? '#141519' : '#141519'
  );
  const [secondaryText, setSecondaryText] = useState(
    widgetTheme === 'dark' ? '#bbbbbb' : widgetTheme === 'light' ? '#bbbbbb' : '#bbbbbb'
  );

  useEffect(() => {
    setPrimaryColor(
      widgetTheme === 'dark' ? '#222229' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
    );
    setContainerColor(
      widgetTheme === 'dark' ? '#141416' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
    );
    setPrimaryText(
      widgetTheme === 'dark' ? '#ffffff' : widgetTheme === 'light' ? '#141519' : '#141519'
    );
    setSecondaryText(
      widgetTheme === 'dark' ? '#bbbbbb' : widgetTheme === 'light' ? '#bbbbbb' : '#bbbbbb'
    );
  }, [widgetTheme]);

  const [isUpdate, setIsUpdate] = useState(false);
  const [subDomainInitialValues, setSubdomainInitialValues] = useState({
    subdomain: ''
  });
  const [brandLogoInitialValues, setBrandLogoInitialValues] = useState({
    brand_logo: null
  });
  const [applicationCheck, setApplicationCheck] = useState([]);
  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setInterval(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  function calculateTimeLeft() {
    const now = new Date();
    const minutes = now.getMinutes();
    const seconds = now.getSeconds();

    let nextQuarterHour = 0;
    if (minutes < 15 || (minutes === 15 && seconds === 0)) {
      nextQuarterHour = 15;
    } else if (minutes < 30 || (minutes === 30 && seconds === 0)) {
      nextQuarterHour = 30;
    } else if (minutes < 45 || (minutes === 45 && seconds === 0)) {
      nextQuarterHour = 45;
    } else {
      nextQuarterHour = 60;
    }

    let timeDiff;
    if (nextQuarterHour === 60) {
      timeDiff = (60 - minutes - 1) * 60 + (60 - seconds);
    } else {
      timeDiff = (nextQuarterHour - minutes) * 60 - seconds;
    }

    return timeDiff;
  }

  const formatTime = (timeDiff) => {
    let minutes = Math.floor(timeDiff / 60);
    let seconds = timeDiff % 60;
    return `${minutes.toString().padStart(2, '0')}:${seconds
      .toString()
      .padStart(2, '0')}`;
  };

  const toggleSection = (index) => {
    if (openedSection === index) {
      setOpenedSection(null);
    } else {
      setOpenedSection(index);
    }
  };

  const icons = [domain, box, upload, colorSelection, share, checkMark];

  const getReferralsDetailFunction = () => {
    GetReferralDetailAPI()
      .then((res) => {
        const referralData = res && res.data && res.data.data && res.data.data.data;
        setReferralDetail(referralData);
        if (referralData && Object.keys(referralData).length > 0) {
          setSubdomainInitialValues({ subdomain: referralData.subdomain });
          setBrandLogoInitialValues({ brand_logo: referralData.brand_logo });
          setApplicationCheck(referralData.applications);
          setIsUpdate(true);
          getProfileDataFunction(referralData);
        } else {
          setIsUpdate(false);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getReferralsDetailFunction();
  }, []);

  const handleUpdateReferralData = (payloadData, successMessage, selectionStep) => {
    if (isUpdate) {
      EditReferralDetailAPI(payloadData)
        .then((res) => {
          const referralData = res && res.data && res.data.data && res.data.data.data;
          setReferralDetail(referralData);
          setOpenedSection(selectionStep);
          notifySuccess(successMessage);
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          notifyError(notificationMessageFunction(errorMessage));
        });
    } else {
      AddReferralDetailAPI(payloadData)
        .then((res) => {
          const referralData = res && res.data && res.data.data && res.data.data.data;
          setReferralDetail(referralData);
          setOpenedSection(selectionStep);
          notifySuccess(successMessage);
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  const updateOnboardingCompleteStatus = () => {
    if (isUpdate) {
      EditReferralDetailAPI({})
        .then((res) => {
          const referralData = res && res.data && res.data.data && res.data.data.data;
          setReferralDetail(referralData);
        })
        .catch((err) => {});
    } else {
      AddReferralDetailAPI({})
        .then((res) => {
          const referralData = res && res.data && res.data.data && res.data.data.data;
          setReferralDetail(referralData);
        })
        .catch((err) => {});
    }
  };

  const getProfileDataFunction = (referralDetail) => {
    if (
      referralDetail &&
      referralDetail.subdomain &&
      referralDetail.applications &&
      referralDetail.applications.length > 0 &&
      referralDetail.brand_logo
    ) {
      GetMyProfileAPI()
        .then((res) => {
          const data = res && res.data && res.data.data && res.data.data.data;
          if (data.is_onboarding_completed === true) {
            props.history.push('/business-dashboard');
          }
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  const commonProps = {
    referralDetail,
    toggleSection,
    openedSection,
    subDomainInitialValues,
    handleUpdateReferralData,
    applicationCheck,
    setApplicationCheck,
    brandLogoInitialValues,
    widgetTheme,
    setWidgetTheme,
    primaryColor,
    setPrimaryColor,
    containerColor,
    setContainerColor,
    primaryText,
    setPrimaryText,
    secondaryText,
    setSecondaryText,
    getReferralsDetailFunction,
    timeLeft,
    formatTime
  };

  return (
    <React.Fragment>
      <div className='d-flex justify-content-center'>
        <div className='on-boarding-page'>
          <Row>
            <Col lg={8} md={8} sm={12}>
              <div className='font-size-24 font-weight-bold text-center my-4'>
                Complete Your Listus Onboarding
              </div>

              <StepSection
                key={0}
                step={steps[0]}
                index={0}
                icon={icons[0]}
                {...commonProps}
              />
              <StepSection
                key={1}
                step={steps[1]}
                index={1}
                icon={icons[1]}
                {...commonProps}
              />
              <StepSection
                key={2}
                step={steps[2]}
                index={2}
                icon={icons[2]}
                {...commonProps}
              />
              <StepSection
                key={3}
                step={steps[3]}
                index={3}
                icon={icons[3]}
                {...commonProps}
              />
              <StepSection
                key={4}
                step={steps[4]}
                index={4}
                icon={icons[4]}
                {...commonProps}
              />
              <StepSection
                key={5}
                step={steps[5]}
                index={5}
                icon={icons[5]}
                {...commonProps}
              />

              <div className='mt-5 d-flex align-items-center justify-content-center'>
                <Link to='/business-dashboard'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150 }}
                    onClick={updateOnboardingCompleteStatus}>
                    Skip
                  </button>
                </Link>
              </div>
            </Col>
            <Col lg={4} md={4} sm={12} className='on-boarding-step'>
              <Card>
                <CardBody>
                  <div>
                    <StyledEngineProvider injectFirst>
                      <Stepper activeStep={openedSection} orientation='vertical'>
                        {steps.map((step, index) => (
                          <Step key={step.label}>
                            <StepLabel>
                              <span style={{ fontWeight: 600 }}>{step.label}</span>
                            </StepLabel>
                            <StepContent>
                              <Typography>
                                <p className='font-weight-normal font-size-12 text-grey'>
                                  {step.description}
                                </p>
                              </Typography>
                            </StepContent>
                          </Step>
                        ))}
                      </Stepper>
                    </StyledEngineProvider>
                  </div>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default Onboarding;

const StepSection = ({
  referralDetail,
  step,
  index,
  toggleSection,
  openedSection,
  icon,
  subDomainInitialValues,
  handleUpdateReferralData,
  applicationCheck,
  setApplicationCheck,
  brandLogoInitialValues,
  widgetTheme,
  setWidgetTheme,
  primaryColor,
  setPrimaryColor,
  containerColor,
  setContainerColor,
  primaryText,
  setPrimaryText,
  secondaryText,
  setSecondaryText,
  getReferralsDetailFunction,
  timeLeft,
  formatTime
}) => (
  <div className='py-2 exchange-card-border ml-2 mr-2 mt-2' key={index}>
    <div className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'>
      <div className='d-flex' onClick={() => toggleSection(index)}>
        <img src={icon} alt={step.label} height={40} />
        <span className='text-black font-weight-bold font-size-14 ml-2'>
          <p>
            {step.label}{' '}
            {referralDetail &&
              !referralDetail.is_domain_live &&
              referralDetail.subdomain &&
              index === 0 && (
                <span style={{ color: 'orange' }}>
                  {' '}
                  Time remaining to activate domain: {formatTime(timeLeft)}
                </span>
              )}
          </p>

          <p className='font-weight-normal font-size-12 text-grey'>{step.description}</p>
        </span>
      </div>
      <i
        className={`font-size-22 mdi mdi-chevron-${
          openedSection === index ? 'up' : 'down'
        }`}
      />
    </div>
    <Collapse isOpen={openedSection === index}>
      <hr className='ml-3 mr-3' />
      {openedSection === 0 ? (
        <DomainSelectionStep
          subDomainInitialValues={subDomainInitialValues}
          handleUpdateReferralData={handleUpdateReferralData}
          getReferralsDetailFunction={getReferralsDetailFunction}
        />
      ) : openedSection === 1 ? (
        <SelectServices
          applicationCheck={applicationCheck}
          setApplicationCheck={setApplicationCheck}
          handleUpdateReferralData={handleUpdateReferralData}
          getReferralsDetailFunction={getReferralsDetailFunction}
        />
      ) : openedSection === 2 ? (
        <BrandLogo
          brandLogoInitialValues={brandLogoInitialValues}
          handleUpdateReferralData={handleUpdateReferralData}
          getReferralsDetailFunction={getReferralsDetailFunction}
        />
      ) : openedSection === 3 ? (
        <CustomizeWidget
          referralDetail={referralDetail}
          setWidgetTheme={setWidgetTheme}
          widgetTheme={widgetTheme}
          primaryColor={primaryColor}
          setPrimaryColor={setPrimaryColor}
          containerColor={containerColor}
          setContainerColor={setContainerColor}
          primaryText={primaryText}
          setPrimaryText={setPrimaryText}
          secondaryText={secondaryText}
          setSecondaryText={setSecondaryText}
        />
      ) : openedSection === 4 ? (
        <ShareUrl
          referralDetail={referralDetail}
          widgetTheme={widgetTheme}
          primaryColor={primaryColor}
          containerColor={containerColor}
          primaryText={primaryText}
          secondaryText={secondaryText}
        />
      ) : openedSection === 5 ? (
        <Completion />
      ) : (
        ''
      )}
    </Collapse>
  </div>
);
