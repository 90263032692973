import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'reactstrap';
import { constants as ROUTE_CONST } from '../../../constants/routes';

function Congratulations({ setCompletedSteps }) {
  useEffect(() => {
    setCompletedSteps([0, 1, 2, 3, 4, 5]);
  }, []);
  return (
    <React.Fragment>
      <div className='page-content'>
        <div
          className='card d-flex align-items-center justify-content-center'
          style={{ margin: '0px 15% 0px 15%' }}>
          <Container
            className='col-12 col-sm-auto pt-4 m-0 d-flex align-items-center justify-content-center'
            style={{ flexDirection: 'column', width: '490px', textAlign: 'center' }}>
            <span className='text-black font-size-32'>Congratulations 🎊</span>
            <span className='text-black font-size-18 m-4'>
              You've successfully submitted your application. Click here to view its
              status.
            </span>
            <Link to={{ pathname: ROUTE_CONST.GET_APPLICATION }}>
              <div
                className='d-flex btn btn-auth flex-column justify-content-center align-items-center'
                style={{
                  background: '#3AE488',
                  borderRadius: '8px',
                  width: '136px',
                  height: '60px'
                }}>
                GO
              </div>
            </Link>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Congratulations;
