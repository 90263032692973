import React from 'react';
import './VerticalStepper.css';

const Step = ({ isActive, number, title, onClick, completedSteps }) => (
  <div
    className={`step ${isActive ? 'active' : ''} ${
      completedSteps.includes(number - 1) ? 'cursor-pointer' : ''
    }`}
    onClick={onClick}>
    <div className='circle'>{number}</div>
    <div className='title pr-1'>{title}</div>
  </div>
);

const VerticalStepperSection = ({
  completedSteps,
  steps,
  currentStep,
  onSelectStep,
  sectionRefs,
  toggleSection
}) => {
  const scrollToSection = (index) => {
    const element = sectionRefs.current[index - 1];

    if (element && element.scrollIntoView) {
      element.scrollIntoView();
    } else {
      console.warn('Element is not defined or not a DOM element.');
    }
    onSelectStep(index);
  };

  return (
    <div className='stepper'>
      {steps.map((step, index) => (
        <div key={index} onClick={() => toggleSection(index)}>
          <Step
            index={index}
            number={index + 1}
            title={step}
            isActive={currentStep >= index}
            completedSteps={completedSteps}
            onClick={() => completedSteps.includes(index) && scrollToSection(index)}
          />
        </div>
      ))}
    </div>
  );
};

export default VerticalStepperSection;
