import React, { useEffect, useState } from 'react';
//import get from 'lodash/get';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { GetProvidersDetail } from '../../services/Providers/AdminProviders';
import { GetProviderAllPaymentMethods } from '../../services/Providers/Provider';
import News from '../News/News';

import SupportedAssets from './SupportedAssets';
import SupportedCountries from './SupportedCountries';
import SupportedNetwork from './SupportedNetwork';
import SupportedPaymentOptions from './SupportedPaymentOptions';

function ProviderDetails(props) {
  const [provider, setProvider] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState([]);

  useEffect(() => {
    const searchURL = props.location.search;
    let provider = searchURL.split(`?provider=`)[1];
    if (!provider) {
      setProvider(null);
      return;
    }
    GetProvidersDetail(provider)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setProvider(data);
        return data;
      })
      .then((provider) =>
        GetProviderAllPaymentMethods(provider.reference_name).then((res) => {
          const data = res && res.data && res.data.data && res.data.data.data;
          if (data && data.length > 0) {
            setPaymentOptions(data);
          } else {
            setPaymentOptions([]);
          }
        })
      )
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, [props.location.search]);

  return (
    <React.Fragment>
      <div className="page-content">
        {provider && (
          <div className="py-4 px-0 px-sm-4">
            <div className="d-flex mt-n5 justify-content-center">
              <div className="bg-white p-4 mb-0">
                <img src={provider.logo} alt="" height={38} />
              </div>
            </div>

            <div className="row">
              <div className="col-12 col-lg-7 col-xl-5">
                <News
                  search={provider.provider_name}
                  className="height-582px"
                />
                {/* <Card className="height-md-550px pl-4">
                  <BestAssets providerData={provider} paymentOptions={paymentOptions} />
                </Card> */}
              </div>
              <div className="col-12 col-lg-5 col-xl-7">
                <SupportedCountries paymentOptions={paymentOptions} />
              </div>
            </div>

            <div className="row align-items-stretch">
              <div className="col-12 col-lg pb-3">
                {paymentOptions.length > 0 && (
                  <SupportedPaymentOptions
                    provider={provider.reference_name}
                    paymentOptions={paymentOptions}
                  />
                )}
              </div>
              <div className="col-12 col-lg-auto pb-3">
                <SupportedNetwork provider={provider.reference_name} />
              </div>
            </div>

            <SupportedAssets provider={provider.reference_name} />
          </div>
        )}
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default ProviderDetails;
