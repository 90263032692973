import { Form, Formik } from 'formik';
import React, { useEffect, useState } from 'react';
import * as Yup from 'yup';
import { Col, FormGroup, Label } from 'reactstrap';
import AuthInput from '../../Custom/AuthInput';
import { constants as ERROR_CONST } from '../../constants/errorMessage';
import { Paginator } from 'primereact/paginator';
import { GetReviewersAPI, InviteReviewerAPI } from '../../services/Auth';
import { get } from 'lodash';
import moment from 'moment';
import { notifyError, notifySuccess } from '../../Custom/notification';

function Reviewer() {
  const [reviewInitialValues] = useState({ email: '' });
  const [page, setPage] = useState(1);
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [allReviewers, setAllReviewers] = useState([]);

  const validationReviewSchema = Yup.object({
    email: Yup.string()
      .email(ERROR_CONST.VALID_EMAIL)
      .required(ERROR_CONST.EMAIL_REQUIRED)
  });

  const handleSubmitInvite = (values) => {
    const data = { email: values.email };
    InviteReviewerAPI(data)
      .then((res) => {
        setLoading(false);
        getAllReviewersAPI();
        notifySuccess(`Invite sent successfully!`);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(errorMessage);
      });
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    const newPageData = allReviewers.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  const getAllReviewersAPI = () => {
    GetReviewersAPI()
      .then((res) => {
        const responses = get(res, 'data.data.data', []);
        const count = get(res, 'data.data.count', []);
        const slicedData = get(res, 'data.data.data', []).slice(first, first + 10);
        setCurrentPageData(slicedData);
        setTotalCount(count);
        setLoading(false);
        setAllReviewers(responses);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllReviewersAPI();
  }, []);

  return (
    <React.Fragment>
      <span className='font-size-18 font-weight-medium ml-2'>Reviewers</span>
      <Col lg={10}>
        <Formik
          initialValues={reviewInitialValues}
          validationSchema={validationReviewSchema}
          onSubmit={handleSubmitInvite}>
          {({ values, handleChange, errors, touched }) => (
            <Form>
              <div className='mb-3 mt-3'>
                <FormGroup>
                  <Label htmlFor='email' className='text-black font-size-12'>
                    Reviewer Email
                  </Label>
                  <AuthInput
                    type='text'
                    values={values}
                    placeholder={'enter reviewer email'}
                    handleChange={handleChange}
                    errors={errors}
                    touched={touched}
                    name='email'
                    className={'font-size-12'}
                  />
                </FormGroup>
              </div>

              <div>
                <button
                  type='submit'
                  name='btn'
                  className={`btn btn-auth`}
                  onSubmit={handleSubmitInvite}
                  style={{ width: 150, marginLeft: 0 }}>
                  Add
                </button>
              </div>
            </Form>
          )}
        </Formik>
      </Col>
      <Col lg={10}>
        <div className='mt-4'>
          <div className='table-responsive'>
            <table className='table table-centered table-hover'>
              <thead className='center-text'>
                <tr className='font-size-16 text-muted'>
                  <th className='pt-3 pb-4 border-0'>#</th>
                  <th className='pt-3 pb-4 border-0'>Full Name</th>
                  <th className='pt-3 pb-4 border-0'>Email</th>
                  <th className='pt-3 pb-4 border-0'>Status</th>
                  <th className='pt-3 pb-4 border-0'>Invited At</th>
                </tr>
              </thead>
              <tbody>
                {' '}
                {loading ? (
                  <tr>
                    <td colSpan={15}>
                      <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                        <i
                          className='fas fa-circle-notch fa-spin text-muted'
                          style={{ fontSize: 48 }}></i>
                      </div>
                    </td>
                  </tr>
                ) : (
                  <>
                    {currentPageData && currentPageData.length > 0 ? (
                      currentPageData.map((items, index) => (
                        <React.Fragment key={index}>
                          <tr className='center-text text-center font-size-14 font-weight-medium'>
                            <td className='text-center'>{index + 1 + (page - 1) * 10}</td>
                            <td className='text-center'>
                              {items.full_name ? items.full_name : '-'}
                            </td>
                            <td className='text-center'>{items.email}</td>
                            <td className='text-center'>
                              {items.status === 0 ? (
                                <span className='badge badge-pill badge-warning font-size-14'>
                                  Unverified
                                </span>
                              ) : items.status === 1 ? (
                                <span className='badge badge-pill badge-success font-size-14'>
                                  Active
                                </span>
                              ) : items.status === 2 ? (
                                <span className='badge badge-pill badge-danger font-size-14'>
                                  Inactive
                                </span>
                              ) : (
                                <span className='badge badge-pill badge-danger font-size-14'>
                                  Deleted
                                </span>
                              )}
                            </td>
                            <td className='text-center'>
                              {items.created_at
                                ? moment(items.created_at).format('DD MMM YY')
                                : '-'}
                            </td>
                          </tr>
                        </React.Fragment>
                      ))
                    ) : (
                      <tr>
                        <td colSpan={18}>
                          <span className='d-flex justify-content-center'>
                            No applications found
                          </span>
                        </td>
                      </tr>
                    )}
                  </>
                )}
              </tbody>
            </table>
          </div>
          <Paginator
            rows={10}
            totalRecords={totalCount}
            first={first}
            onPageChange={(e) => onPageChange(e)}
          />
        </div>
      </Col>
    </React.Fragment>
  );
}

export default Reviewer;
