import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';

import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import {
  GetAllNetwork,
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import Select from 'react-select';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepFive(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const CapMarketSchema = Yup.object().shape({
    blockchain_network: Yup.string().required(ERROR_CONST.NETWORK),
    other_network: Yup.string().when('blockchain_network', {
      is: (value) => value === 'Other',
      then: Yup.string().required(ERROR_CONST.NETWORK)
    }),
    token_address: Yup.string().required(ERROR_CONST.TOKEN_ADDRESS)
  });
  const [initialValue, setInitialValue] = useState({
    blockchain_network: '',
    other_network: '',
    token_address: ''
  });
  const step = 5;
  const [completeStep, setCompleteStep] = useState(0);
  const [allNetworks, setAllNetworks] = useState([]);
  const [selectNetworkList, setSelectnetworkList] = useState([]);
  const [displayotherNetwork, setDisplayotherNetwork] = useState(false);

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.blockchain_network) {
          setSelectnetworkList(application.exchange_application.blockchain_network);
          setInitialValue({
            blockchain_network: application.exchange_application.blockchain_network,
            other_network: application.exchange_application.other_network,
            token_address: application.exchange_application.token_address
          });
          if (application.exchange_application.blockchain_network === 'Other')
            setDisplayotherNetwork(true);
        }
        getAllNetworkApiFunction();
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const getAllNetworkApiFunction = () => {
    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));
        setFinalData.push({ label: 'Other', value: 'Other' });
        setAllNetworks(setFinalData);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const changeHandler = (selectValues, setFieldValue) => {
    if (selectValues.label === 'Other') {
      setDisplayotherNetwork(true);
    } else {
      setFieldValue('other_network', '');
      setDisplayotherNetwork(false);
    }
    setSelectnetworkList(selectValues.value);
    setFieldValue('blockchain_network', selectValues.label);
  };

  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    if (selectNetworkList !== '') {
      let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 39));
      const data = {
        blockchain_network: selectNetworkList,
        other_network: values.other_network,
        token_address: values.token_address,
        percentage_complete: stepsRequired
      };
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          props.setApplicationData(application);
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
      setExchangeCompleteStep([0, 1, 2, 3, 4, 5]);
    }
  };

  const calculateProgress = () => {
    return completeStep;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-22 pb-1'>
        <span className='text-black'>Chains Supported</span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={CapMarketSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <Form>
                <div>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-2'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='blockchain_network'
                            className='text-black font-weight-normal'>
                            {CONST.NETWORK}
                          </Label>
                        </div>
                        <div>
                          <Select
                            className='select-input border-dark font-size-12'
                            style={{
                              paddingRight: '30px',
                              borderRadius: '8px'
                            }}
                            options={allNetworks}
                            value={allNetworks.find((c) => c.value === selectNetworkList)}
                            onChange={(val) => changeHandler(val, setFieldValue)}
                            name='blockchain_network'
                          />
                          <ErrorMessage name='blockchain_network'>
                            {(errorMsg) => (
                              <div className='font-size-12' style={{ color: 'red' }}>
                                {errorMsg}
                              </div>
                            )}
                          </ErrorMessage>
                        </div>
                      </FormGroup>
                    </Col>
                    {displayotherNetwork && (
                      <Col lg={6} md={12} sm={12}>
                        <FormGroup className='mb-2'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='other_network'
                              className='text-black font-weight-normal'>
                              {CONST.OTHER_NETWORK}
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              type='text'
                              name='other_network'
                              className='font-size-12'
                              values={values}
                              placeholder={CONST.OTHER_NETWORK}
                              handleChange={(e) => {
                                setFieldValue('other_network', e.target.value);
                              }}
                              errors={errors}
                              touched={touched}
                              disabledField={!displayotherNetwork}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    )}
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='token_address'
                            className='text-black font-weight-normal'>
                            {CONST.TOKEN_ADDRESS}
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            type='text'
                            name='token_address'
                            className='font-size-12'
                            values={values}
                            handleChange={handleChange}
                            placeholder={CONST.ENTER_TOKEN_ADDRESS_NAME}
                            errors={errors}
                            touched={touched}></AuthInput>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row></Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepFive;
