// src/GiphySearch.js
import React, { useState } from 'react';
import axios from 'axios';

export const giphyLogo = (
  <svg
    xmlns='http://www.w3.org/2000/svg'
    viewBox='0 0 28 35'
    class='sc-1f039a7e-2 bMXkCi'>
    <g fill-rule='evenodd' clip-rule='evenodd'>
      <path fill='#00ff99' d='M0 3h4v29H0z'></path>
      <path fill='#9933ff' d='M24 11h4v21h-4z'></path>
      <path fill='#00ccff' d='M0 31h28v4H0z'></path>
      <path fill='#fff35c' d='M0 0h16v4H0z'></path>
      <path fill='#ff6666' d='M24 8V4h-4V0h-4v12h12V8'></path>
      <path class='shadow' d='M24 16v-4h4M16 0v4h-4'></path>
    </g>
  </svg>
);

const GiphySearch = () => {
  const [searchQuery, setSearchQuery] = useState('');
  const [gifs, setGifs] = useState([]);
  const [selectedGifs, setSelectedGifs] = useState([]);

  const API_KEY = 'tAEFUgagRjRNkU24orQdFB8EHMcNTUSe';
  const API_URL = `https://api.giphy.com/v1/gifs/search?api_key=${API_KEY}&limit=10&q=`;

  const searchGifs = async () => {
    try {
      const response = await axios.get(`${API_URL}${searchQuery}`);
      setGifs(response.data.data);
    } catch (error) {
      console.error('Error fetching GIFs:', error);
    }
  };

  const toggleSelectGif = (gifId) => {
    if (selectedGifs.includes(gifId)) {
      setSelectedGifs(selectedGifs.filter((id) => id !== gifId));
    } else {
      setSelectedGifs([...selectedGifs, gifId]);
    }
  };

  const downloadSelectedGifs = () => {
    selectedGifs.forEach((gifId) => {
      const selectedGif = gifs.find((gif) => gif.id === gifId);
      if (selectedGif) {
        window.open(selectedGif.images.original.url);
      }
    });
  };

  return (
    <div>
      <input
        type='text'
        value={searchQuery}
        onChange={(e) => setSearchQuery(e.target.value)}
        placeholder='Search for GIFs'
      />
      <button onClick={searchGifs}>Search</button>
      <button
        onClick={downloadSelectedGifs}
        disabled={selectedGifs.length === 0}
        style={{ color: 'white' }}>
        Download Selected GIFs
      </button>
      <div>
        {gifs.map((gif) => (
          <div key={gif.id}>
            <input
              type='checkbox'
              checked={selectedGifs.includes(gif.id)}
              onChange={() => toggleSelectGif(gif.id)}
            />
            <img src={`https://i.giphy.com/${gif.id}.gif`} alt={gif.title} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default GiphySearch;
