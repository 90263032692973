/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';
import { useEffect } from 'react';

function SwipeluxWidget({ backToWidget, buyData }) {
  useEffect(() => {
    const swipeluxContainer = document.getElementById('swipelux-container');
    const settings = {
      apiKey: process.env.REACT_APP_SWIPELUX_API_KEY,
      availablePayCurrency: buyData.fiatCurrency,
      availableReceiveCurrency: buyData.crypto,
      defaultValues: { fiatAmount: buyData.fiatAmount }
    };

    if (swipeluxContainer) {
      const widget = new window.SwipeluxWidget(swipeluxContainer, settings);
      widget.init();
    }
  }, []);

  return (
    <>
      <button
        type="button"
        className="btn btn-light p-1 mb-2 ml-2 mt-1"
        style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
        onClick={backToWidget}>
        <ArrowLeftIcon width={18} height={18} />
      </button>
      <div
        id="swipelux-container"
        style={{ left: '260px', position: 'absolute', top: '50px' }}></div>
    </>
  );
}

export default SwipeluxWidget;
