import React, { useEffect, useState } from 'react';
import {
  GetAllApplications,
  MassProcessApplication,
  ProcessApplication
} from '../../services/Onramp/Application';
import { get } from 'lodash';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import PriorityModal from './PriorityModal';
import GetApplication from './GetApplication';
import { stringify } from 'query-string';
import { Paginator } from 'primereact/paginator';

function AdminOnrampApplications({ history }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [allData, setAllData] = useState([]);
  const [applicationCheck, setApplicationCheck] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [massPriority, setMassPriority] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);

  const getApplications = (params, paramsData) => {
    setIsLoaded(false);
    GetAllApplications(params, { page, ...paramsData })
      .then((res) => {
        const allApplications = get(res, 'data.data.data.applications', []);
        setAllData(allApplications);
        setApplicationCheck(
          allApplications.reduce((accumulator, currentValue) => {
            accumulator.push({ id: currentValue._id, checked: false });
            return accumulator;
          }, [])
        );
        const slicedData = get(res, 'data.data.data.applications', []).slice(
          first,
          first + 10
        );
        setCurrentPageData(slicedData);
        setTotalCount(get(res, 'data.data.data.count', []));
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log({ err });
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    const params = {
      application_type: 'ONRAMP'
    };
    getApplications(params);
  }, []);

  const handlePriorityClick = () => {
    setIsModalOpen(true);
  };

  const changePriority = (payload) => {
    MassProcessApplication(payload)
      .then((res) => {
        notifySuccess('Application Priority Changed Successfully');
        setPage(page);

        setMassPriority(false);
      })
      .catch((err) => {});
  };

  const handleApplication = (application) => {
    setIsLoaded(false);
    if (!application.sub_application) {
      ProcessApplication(application._id).then((res) => {
        const id = get(res, 'data.data.data.sub_application_id', '');
        history.push(
          `/admin-application/view?${stringify({
            application_id: id
          })}`
        );
      });
    } else {
      history.push(
        `/admin-application/view?${stringify({
          application_id: application.sub_application._id
        })}`
      );
    }
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    const newPageData = allData.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='card'>
          <div className='p-4'>
            <div className='col-12 col-sm-auto font-size-24 font-weight-medium pb-2 pb-sm-0'>
              <div className='d-flex justify-content-between'>
                <span className='text-black'>Listus Onramp Applications</span>
              </div>
              <div className='table-responsive'>
                <table className='table table-centered table-hover'>
                  <thead className='center-text'>
                    <tr className='font-size-12 text-muted'>
                      {massPriority && (
                        <th className='pt-3 pb-4 border-0 cursor-pointer'>
                          <button
                            style={{ width: 130 }}
                            className='btn btn-status-change'
                            onClick={handlePriorityClick}>
                            Select Priority
                          </button>
                        </th>
                      )}
                      {isModalOpen && (
                        <PriorityModal
                          priorityApplicationChange={changePriority}
                          isModalOpen={isModalOpen}
                          setIsModalOpen={setIsModalOpen}
                          applicationCheck={applicationCheck}
                          isMass={true}
                        />
                      )}
                      <th className='pl-4 pt-3 pb-4 border-0'>#</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Project Name</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Ticker</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Ticker Name</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Legal Entity Name</th>
                      <th className='pl-4 pt-3 border-0'>Full Name</th>
                      <th className='pl-4 pt-3 border-0'>Applicant Email</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Business Category</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Date Sent</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {!isLoaded ? (
                      <tr>
                        <td colSpan={12}>
                          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                            <i
                              className='fas fa-circle-notch fa-spin text-muted'
                              style={{ fontSize: 48 }}></i>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {currentPageData.map((application, key) => (
                          <tr
                            key={key}
                            className='cursor-pointer center-text font-size-12 font-weight-medium'>
                            <GetApplication
                              getApplications={getApplications}
                              handleApplication={handleApplication}
                              application={application}
                              index={key}
                              page={page}
                              setPage={setPage}
                            />
                          </tr>
                        ))}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Paginator
            rows={10}
            totalRecords={totalCount}
            first={first}
            onPageChange={(e) => onPageChange(e)}
          />
        </div>
      </div>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default AdminOnrampApplications;
