var _a;
import { GateFiDisplayModeEnum, GateFiEventTypes } from "./types";
import { PMService } from "./services/PMService";
import { EmbeddedLayout, OverlayLayout } from "./layouts";
import { configValidator } from "./ConfigValidator";
import { errorService } from "./services/ErrorService";
import { proxyGtfSearchParam } from "./utils";
export * from "./types";
var Layouts = (_a = {},
    _a[GateFiDisplayModeEnum.Overlay] = OverlayLayout,
    _a[GateFiDisplayModeEnum.Embedded] = EmbeddedLayout,
    _a);
var GateFiSDK = (function () {
    function GateFiSDK(config) {
        var _this = this;
        var error = configValidator.validate(config);
        if (error) {
            errorService.send(error);
            return;
        }
        this.config = config;
        var baseUrl = (config === null || config === void 0 ? void 0 : config.isSandbox) ? process.env.BASE_URL_SANDBOX : process.env.BASE_URL;
        var urlWithParams = proxyGtfSearchParam(baseUrl);
        this.layout = new Layouts[this.config.displayMode];
        this.layout.create(urlWithParams, this.config.nodeSelector);
        var contentWindow = this.layout.getContentWindow();
        if (contentWindow) {
            this.pmIframe = new PMService(contentWindow);
            this.pmIframe.start();
            this.pmIframe.subscribe(GateFiEventTypes.onLoad, function () {
                _this.pmIframe.send(GateFiEventTypes.onInit, {
                    merchantId: _this.config.merchantId,
                    isSandbox: _this.config.isSandbox,
                    walletLock: _this.config.walletLock,
                    fiatAmountLock: _this.config.fiatAmountLock,
                    cryptoAmountLock: _this.config.cryptoAmountLock,
                    fiatCurrencyLock: _this.config.fiatCurrencyLock,
                    cryptoCurrencyLock: _this.config.cryptoCurrencyLock,
                    displayMode: _this.config.displayMode,
                    walletAddress: _this.config.walletAddress,
                    email: _this.config.email,
                    defaultFiat: _this.config.defaultFiat,
                    defaultCrypto: _this.config.defaultCrypto,
                    availableCrypto: _this.config.availableCrypto,
                    availableFiat: _this.config.availableFiat,
                    styles: _this.config.styles,
                    isPlaidMode: _this.config.isPlaidMode,
                    hideThemeSwitcher: _this.config.hideThemeSwitcher,
                    successUrl: _this.config.successUrl,
                    cancelUrl: _this.config.cancelUrl,
                    declineUrl: _this.config.declineUrl,
                    externalId: _this.config.externalId,
                    hideAsset: _this.config.hideAsset,
                });
                _this.pmIframe.subscribe(GateFiEventTypes.onClose, _this.hide.bind(_this));
                _this.pmIframe.subscribe(GateFiEventTypes._onRedirect, _this.redirect.bind(_this));
            });
        }
    }
    GateFiSDK.prototype.subscribe = function (type, callback) {
        var _a;
        (_a = this.pmIframe) === null || _a === void 0 ? void 0 : _a.subscribe(type, callback);
    };
    GateFiSDK.prototype.unsubscribe = function (type, callback) {
        var _a;
        (_a = this.pmIframe) === null || _a === void 0 ? void 0 : _a.unsubscribe(type, callback);
    };
    GateFiSDK.prototype.hide = function () {
        if (this.layout instanceof OverlayLayout) {
            this.layout.hide();
        }
    };
    GateFiSDK.prototype.setThemeType = function (type) {
        var _a;
        (_a = this.pmIframe) === null || _a === void 0 ? void 0 : _a.send(GateFiEventTypes.onSetThemeType, { type: type });
    };
    GateFiSDK.prototype.show = function () {
        if (this.layout instanceof OverlayLayout) {
            this.layout.show();
        }
    };
    GateFiSDK.prototype.destroy = function () {
        var _a, _b;
        (_a = this.pmIframe) === null || _a === void 0 ? void 0 : _a.stop();
        (_b = this.layout) === null || _b === void 0 ? void 0 : _b.destroy();
    };
    GateFiSDK.prototype.redirect = function (type, payload) {
        window.open(payload.redirectUrl, '_self');
    };
    return GateFiSDK;
}());
export { GateFiSDK };
