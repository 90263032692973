import React, { Component } from 'react';
import { Switch, BrowserRouter as Router } from 'react-router-dom';
import { connect } from 'react-redux';
import { QueryParamProvider } from 'use-query-params';
import { ReactRouter5Adapter } from 'use-query-params/adapters/react-router-5';
import { parse, stringify } from 'query-string';

// Import Routes
import {
  authProtectedRoutes,
  publicRoutes,
  businessUserRoutes,
  onRampUserRoutes,
  adminRoutes,
  exchangeUserRoutes,
  reviewerRoutes
} from './routes/';
import { constants as STORAGEKEY } from './constants/constant';
import AppRoute from './routes/route';

import lottie from 'lottie-web';
import { defineElement } from 'lord-icon-element';

// layouts
import HorizontalLayout from './components/VerticalLayout/';
import NonAuthLayout from './components/NonAuthLayout';

// Import scss
import './theme.scss';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {};
    this.getLayout = this.getLayout.bind(this);
    defineElement(lottie.loadAnimation);
  }

  /**
   * Returns the layout
   */
  getLayout = () => {
    return HorizontalLayout;
  };

  componentDidMount() {
    const urlParams = new URLSearchParams(window.location.search);
    const accessToken = urlParams.get('accessToken');

    if (accessToken) {
      localStorage.setItem(STORAGEKEY.ACCESSTOKEN, accessToken);
      urlParams.delete('accessToken');
      window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
    }
  }

  render() {
    const Layout = this.getLayout();
    const accountType = localStorage.getItem(STORAGEKEY.ACCOUNT_TYPE);

    return (
      <React.Fragment>
        <Router>
          <QueryParamProvider
            adapter={ReactRouter5Adapter}
            options={{
              searchStringToObject: parse,
              objectToSearchString: stringify
            }}>
            <Switch>
              {publicRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={NonAuthLayout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={false}
                />
              ))}

              {accountType === '2' &&
                businessUserRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                  />
                ))}

              {accountType === '0' &&
                adminRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                  />
                ))}

              {accountType === '1' &&
                onRampUserRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                  />
                ))}
              {accountType === '4' &&
                exchangeUserRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                  />
                ))}

              {accountType === '5' &&
                reviewerRoutes.map((route, idx) => (
                  <AppRoute
                    path={route.path}
                    layout={Layout}
                    component={route.component}
                    key={idx}
                    isAuthProtected={true}
                  />
                ))}

              {authProtectedRoutes.map((route, idx) => (
                <AppRoute
                  path={route.path}
                  layout={Layout}
                  component={route.component}
                  key={idx}
                  isAuthProtected={true}
                />
              ))}
            </Switch>
          </QueryParamProvider>
        </Router>
      </React.Fragment>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    layout: state.Layout
  };
};

export default connect(mapStateToProps, null)(App);
