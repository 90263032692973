import React, { useState } from 'react';
import { Col, Row } from 'reactstrap';
import { HexColorPicker } from 'react-colorful';
import * as copy from 'copy-to-clipboard';
import { notifySuccess } from '../../../Custom/notification';

function CustomizeWidget({
  referralDetail,
  widgetTheme,
  setWidgetTheme,
  primaryColor,
  setPrimaryColor,
  containerColor,
  setContainerColor,
  primaryText,
  setPrimaryText,
  secondaryText,
  setSecondaryText
}) {
  const [pickerVisible, setPickerVisible] = useState(false);
  const [pickerContainerVisible, setPickerContainerVisible] = useState(false);
  const [pickerPrimaryText, setPickerPrimaryText] = useState(false);
  const [pickerSecondaryText, setPickerSecondaryText] = useState(false);

  const togglePicker = () => {
    setPickerVisible(!pickerVisible);
    setPickerContainerVisible(false);
    setPickerPrimaryText(false);
    setPickerSecondaryText(false);
  };

  const toggleContainerPicker = () => {
    setPickerContainerVisible(!pickerContainerVisible);
    setPickerVisible(false);
    setPickerPrimaryText(false);
    setPickerSecondaryText(false);
  };

  const togglePrimaryText = () => {
    setPickerPrimaryText(!pickerPrimaryText);
    setPickerVisible(false);
    setPickerContainerVisible(false);
    setPickerSecondaryText(false);
  };
  const toggleSecondaryText = () => {
    setPickerSecondaryText(!pickerSecondaryText);
    setPickerVisible(false);
    setPickerContainerVisible(false);
    setPickerPrimaryText(false);
  };

  const copyToClipboard = () => {
    const iframeCode = `<iframe src="https://${
      referralDetail && referralDetail.subdomain ? referralDetail.subdomain : 'app'
    }.listus.xyz/landing-page?widgetTheme=${widgetTheme}&primaryColor=${primaryColor.replace(
      '#',
      ''
    )}&containerColor=${containerColor.replace(
      '#',
      ''
    )}&primaryText=${primaryText.replace('#', '')}&secondaryText=${secondaryText.replace(
      '#',
      ''
    )}"
  width='512px'
  height='717px'
  title='Listus'
  frameBorder='1'
/>`;
    copy(iframeCode);
    notifySuccess('iframe code copied successfully');
  };

  return (
    <React.Fragment>
      <Row className='ml-2 mr-2'>
        <Col lg={6} md={4}>
          <div>
            <span className='font-size-22 font-weight-bold'>Widget theming</span>
            <p className='font-size-12 font-weight-medium mb-2 mt-2'>Widget Theme</p>
            <div className='d-flex justify-content-around'>
              <div
                className='d-flex justify-content-center align-items-center cursor-pointer'
                style={{ flexDirection: 'column' }}
                onClick={() => setWidgetTheme('light')}>
                <div className='circle'></div>
                <p className='mt-1 font-size-12 font-wright-medium'>Light</p>
              </div>
              <div
                className='d-flex justify-content-center align-items-center cursor-pointer'
                style={{ flexDirection: 'column' }}
                onClick={() => setWidgetTheme('dark')}>
                <div className='dark'></div>
                <p className='mt-1 font-size-12 font-wright-medium'>Dark</p>
              </div>
            </div>

            <p className='font-size-12 font-weight-medium mb-2 mt-2'>Colors</p>
            <div className='d-flex justify-content-around'>
              <div
                className='d-flex align-items-center cursor-pointer'
                style={{ flexDirection: 'column' }}>
                <div
                  className='color-picker-class'
                  onClick={togglePicker}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: primaryColor,
                    zIndex: 100
                  }}>
                  {pickerVisible && (
                    <div style={{ position: 'absolute' }}>
                      <HexColorPicker color={primaryColor} onChange={setPrimaryColor} />
                    </div>
                  )}
                </div>
                <p className='mt-1 font-size-12 font-wright-medium'>{primaryColor}</p>
                <p className='mt-1 font-size-12 font-wright-medium'>Primary Color</p>
              </div>
              <div
                className='d-flex align-items-center cursor-pointer'
                style={{ flexDirection: 'column' }}>
                <div
                  className='color-picker-class'
                  onClick={toggleContainerPicker}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: containerColor,
                    zIndex: 99
                  }}>
                  {pickerContainerVisible && (
                    <div style={{ position: 'absolute' }}>
                      <HexColorPicker
                        color={containerColor}
                        onChange={setContainerColor}
                      />
                    </div>
                  )}
                </div>
                <p className='mt-1 font-size-12 font-wright-medium'>{containerColor}</p>
                <p className='mt-1 font-size-12 font-wright-medium'>Container Color</p>
              </div>
            </div>
            <div className='d-flex justify-content-around mt-3'>
              <div
                className='d-flex align-items-center cursor-pointer'
                style={{ flexDirection: 'column' }}>
                <div
                  className='color-picker-class'
                  onClick={togglePrimaryText}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: primaryText,
                    zIndex: 98
                  }}>
                  {pickerPrimaryText && (
                    <div style={{ position: 'absolute' }}>
                      <HexColorPicker color={primaryText} onChange={setPrimaryText} />
                    </div>
                  )}
                </div>
                <p className='mt-1 font-size-12 font-wright-medium'>{primaryText}</p>
                <p className='mt-1 font-size-12 font-wright-medium'>Primary Text</p>
              </div>
              <div
                className='d-flex align-items-center cursor-pointer'
                style={{ flexDirection: 'column' }}>
                <div
                  className='color-picker-class'
                  onClick={toggleSecondaryText}
                  style={{
                    width: '50px',
                    height: '50px',
                    borderRadius: '50%',
                    backgroundColor: secondaryText,
                    zIndex: 97
                  }}>
                  {pickerSecondaryText && (
                    <div style={{ position: 'absolute' }}>
                      <HexColorPicker color={secondaryText} onChange={setSecondaryText} />
                    </div>
                  )}
                </div>
                <p className='mt-1 font-size-12 font-wright-medium'>{secondaryText}</p>
                <p className='mt-1 font-size-12 font-wright-medium'>Secondary Text</p>
              </div>
            </div>
          </div>
        </Col>
        <Col
          lg={6}
          md={4}
          className='d-flex justify-content-center'
          style={{ minWidth: '400px' }}>
          <iframe
            src={`https://app.listus.xyz/landing-page?widgetTheme=${widgetTheme}&primaryColor=${primaryColor.replace(
              '#',
              ''
            )}&containerColor=${containerColor.replace(
              '#',
              ''
            )}&primaryText=${primaryText.replace(
              '#',
              ''
            )}&secondaryText=${secondaryText.replace('#', '')}`}
            style={{ borderRadius: '10px' }}
            width='420px'
            height='628px'
            title='Listus'
            frameBorder='1'
          />
        </Col>
      </Row>
      <Row className='mt-3 mx-3'>
        <span className='font-size-12 font-weight-medium'>Your Widget Code</span>
        <pre>
          <code>
            {`<iframe src="https://${
              referralDetail && referralDetail.subdomain
                ? referralDetail.subdomain
                : 'app'
            }.listus.xyz/landing-page?widgetTheme=${widgetTheme}&primaryColor=${primaryColor.replace(
              '#',
              ''
            )}&containerColor=${containerColor.replace(
              '#',
              ''
            )}&primaryText=${primaryText.replace(
              '#',
              ''
            )}&secondaryText=${secondaryText.replace('#', '')}"
  width='512px'
  height='717px'
  title='Listus'
  frameBorder='1'
/>`}
          </code>
        </pre>
        <button
          onClick={copyToClipboard}
          title='Copy to clipboard'
          className={`btn btn-auth`}
          style={{ width: 150 }}>
          Copy iframe
        </button>
      </Row>
    </React.Fragment>
  );
}

export default CustomizeWidget;
