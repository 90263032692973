import React, { useState, useEffect } from 'react';

function CryptoChart({ coinGeckoId, className = '' }) {
  const [isLoaded, setIsLoaded] = useState(false);
  useEffect(() => {
    if (isLoaded) return;
    setIsLoaded(true);
    // Your code here
    const el = document.createElement('script');
    el.src = 'https://widgets.coingecko.com/div/coingecko-coin-price-chart-widget-div.js';
    document.body.appendChild(el);
  }, [isLoaded]);

  return (
    <div className={'p-4 card ' + className}>
      <div
        data-background-color="#ffffff"
        data-currency="usd"
        data-coin-id={coinGeckoId}
        data-locale="en"
        data-height="532"
        className="coingecko-coin-price-chart-widget"></div>
    </div>
  );
}

export default CryptoChart;
