import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const getAllUsersList = ({ order, page, limit, userSelect }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.USERS_LIST +
          `?page=${page}` +
          (limit ? `&limit=${limit}` : '') +
          (order ? `&order=${order}` : '') +
          (userSelect ? `&account_type=${userSelect}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const approveAPIKey = (user_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(businessURL.APPROVE_API_KEY + `?user_id=${user_id}`, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteUserAccountAPI = (user_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.DELETE_USER_ACCOUNT + `?user_id=${user_id}`, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
