import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { constants as CONST } from '../../../constants/application';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import AuthInput from '../../../Custom/AuthInput';
import {
  GetApplication,
  UpdateApplication,
  UploadApplicationDocs
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import OnRampHandler from './OnRampHandler';

function IntegrationDetailsPage1({
  setCompletedSteps,
  nextPage,
  applicationId,
  prevPage
}) {
  const [initialValue, setInitialValue] = useState({
    product_custodial: '',
    user_access_to_wallet: '',
    pre_populate_wallet_address: '',
    crypto_to_other_party: '',
    describe_ramp_services: ''
  });

  const [fileUrls, setFileUrls] = useState({});

  useEffect(() => {
    if (applicationId !== '') {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        const {
          product_custodial,
          pre_populate_wallet_address,
          crypto_to_other_party,
          user_access_to_wallet,
          describe_ramp_services
        } = application;

        if (
          product_custodial !== undefined &&
          pre_populate_wallet_address !== undefined &&
          crypto_to_other_party !== undefined &&
          user_access_to_wallet !== undefined
        ) {
          setInitialValue({
            product_custodial: product_custodial.toString(),
            user_access_to_wallet:
              user_access_to_wallet !== null ? user_access_to_wallet.toString() : 'null',
            pre_populate_wallet_address:
              pre_populate_wallet_address !== null
                ? pre_populate_wallet_address.toString()
                : 'null',
            crypto_to_other_party:
              crypto_to_other_party !== null ? crypto_to_other_party.toString() : 'null',
            describe_ramp_services: describe_ramp_services
          });
        }
        setFileUrls({
          whitepapers_doc: application.whitepapers_doc,
          integration_flows_doc: application.integration_flows_doc,
          flow_of_funds_diagram_doc: application.flow_of_funds_diagram_doc
        });
      });
    }
  }, []);

  const [optionalFile, setOptionalFile] = useState({});
  const changeHandler = (event) => {
    if (event.target.files[0])
      setOptionalFile({ ...optionalFile, [event.target.name]: event.target.files[0] });
  };

  const handleSubmit = (values) => {
    const data = {
      product_custodial: values.product_custodial,
      user_access_to_wallet:
        values.user_access_to_wallet !== 'null' ? values.user_access_to_wallet : null,
      pre_populate_wallet_address:
        values.pre_populate_wallet_address !== 'null'
          ? values.pre_populate_wallet_address
          : null,
      crypto_to_other_party:
        values.crypto_to_other_party !== 'null' ? values.crypto_to_other_party : null,
      describe_ramp_services:
        values.describe_ramp_services !== 'null' ? values.describe_ramp_services : null
    };

    const payloadData = new FormData();
    Object.keys(optionalFile).forEach((key) => {
      payloadData.append(key, optionalFile[key]);
    });
    UploadApplicationDocs({ application_id: applicationId }, payloadData)
      .then((res) => {
        // const application = get(res, 'data.data.data', '');
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    UpdateApplication(applicationId, data)
      .then((res) => {})
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    setCompletedSteps([0, 1, 2]);
    nextPage();
  };

  const integrationDetailSchema = Yup.object().shape({
    product_custodial: Yup.string().required('Select one option'),
    user_access_to_wallet: Yup.string().required('Select one option'),
    pre_populate_wallet_address: Yup.string().required('Select one option'),
    crypto_to_other_party: Yup.string().required('Select one option'),
    describe_ramp_services: Yup.string().required('Field is required')
  });

  return (
    <React.Fragment>
      <div className='p-4 p-sm-1'>
        <div className='col-12 col-sm-auto font-size-22 pb-2'>
          <span className='text-black'>Wallet and Fund Flow Details</span>
        </div>
        <Container className='pt-4 pt-md-1'>
          <div>
            <Formik
              initialValues={initialValue}
              enableReinitialize={true}
              validationSchema={integrationDetailSchema}
              onSubmit={handleSubmit}>
              {({ errors, touched, values, handleChange, setFieldTouched }) => (
                <Form>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='product_custodial'
                            className='text-black font-weight-normal'>
                            {CONST.PRODUCT_CUSTODIAL_OR_NOT}
                          </Label>
                        </div>
                        <div
                          className='d-flex direction-row'
                          role='group'
                          aria-labelledby='my-radio-group'>
                          <div className='d-flex align-items-center mr-4'>
                            <Label className='mt-2 font-size-12 d-flex align-items-center'>
                              <Field
                                name='product_custodial'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.CUSTODIAL}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4'>
                            <Label className='mt-2 font-size-12 ml-2 d-flex align-items-center'>
                              <Field
                                name='product_custodial'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NON_CUSTODIAL}
                            </Label>
                          </div>
                        </div>
                        {errors['product_custodial'] && touched['product_custodial'] && (
                          <span
                            style={{ fontSize: 12 }}
                            className='text-left text-danger'>
                            {errors['product_custodial']}
                          </span>
                        )}
                      </FormGroup>
                    </Col>

                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='user_access_to_wallet'
                            className='text-black font-weight-normal'>
                            {CONST.ACCESS_CONTROL_FUNDS}
                          </Label>
                        </div>
                        <div
                          className='d-flex direction-row'
                          role='group'
                          aria-labelledby='my-radio-group'
                          s>
                          <div className='d-flex align-items-center mr-4'>
                            <Label className='mt-2 font-size-12 d-flex align-items-center'>
                              <Field
                                name='user_access_to_wallet'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2  font-size-12   mt-2 d-flex align-items-center'>
                              <Field
                                name='user_access_to_wallet'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center  ml-4 mr-4'>
                            <Label className='ml-2  font-size-12   mt-2 d-flex align-items-center'>
                              <Field
                                name='user_access_to_wallet'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['user_access_to_wallet'] &&
                          touched['user_access_to_wallet'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['user_access_to_wallet']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='pre_populate_wallet_address'
                            className='text-black font-weight-normal'>
                            Are you planning to auto-fill wallet addresses during
                            integration?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center  mr-4'>
                            <Label className='mt-2 font-size-12 d-flex align-items-center'>
                              <Field
                                name='pre_populate_wallet_address'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml-2  font-size-12    mt-2  d-flex align-items-center'>
                              <Field
                                name='pre_populate_wallet_address'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center  ml-4 mr-4'>
                            <Label className='ml-2  font-size-12 mt-2  d-flex align-items-center'>
                              <Field
                                name='pre_populate_wallet_address'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>
                        {errors['pre_populate_wallet_address'] &&
                          touched['pre_populate_wallet_address'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['pre_populate_wallet_address']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='crypto_to_other_party'
                            className='text-black font-weight-normal'>
                            Will the cryptocurrency be directed somewhere other than the
                            user's designated wallet?
                          </Label>
                        </div>
                        <div className='d-flex direction-row'>
                          <div className='d-flex align-items-center  mr-4'>
                            <Label className='mt-2 font-size-12 d-flex align-items-center'>
                              <Field
                                name='crypto_to_other_party'
                                type='radio'
                                value='true'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.YES}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center ml-4 mr-4'>
                            <Label className='ml mt-2 font-size-12 d-flex align-items-center'>
                              <Field
                                name='crypto_to_other_party'
                                type='radio'
                                value='false'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NO}
                            </Label>
                          </div>
                          <div className='d-flex align-items-center  ml-4 mr-4'>
                            <Label className='ml-2  font-size-12    mt-2  d-flex align-items-center'>
                              <Field
                                name='crypto_to_other_party'
                                type='radio'
                                value='null'
                                style={{
                                  height: '1.725rem',
                                  width: '1rem',
                                  marginRight: '5px'
                                }}
                              />
                              {CONST.NA}
                            </Label>
                          </div>
                        </div>

                        {errors['crypto_to_other_party'] &&
                          touched['crypto_to_other_party'] && (
                            <span
                              style={{ fontSize: 12 }}
                              className='text-left text-danger'>
                              {errors['crypto_to_other_party']}
                            </span>
                          )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='describe_ramp_services'
                            className='text-black font-weight-normal'>
                            Describe how you will be integrating an onramp
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            setFieldTouched={setFieldTouched}
                            withTouchError={true}
                            type='textarea'
                            name='describe_ramp_services'
                            rows='6'
                            className={'border-dark font-size-12'}
                            placeholder='Write a description here'
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='description'
                            className='text-black font-weight-normal'>
                            Optional
                          </Label>
                        </div>
                        <Row className='ml-1 d-flex justify-content-between'>
                          <div>
                            <Label htmlFor='whitepapers' id='whitepapers_doc'>
                              <span
                                className='pr-1 mb-2 rounded-circle'
                                style={{
                                  color: '#134a87',
                                  fontSize: 14,
                                  cursor: 'pointer'
                                }}>
                                <img
                                  src={
                                    optionalFile['whitepapers_doc']
                                      ? documentUploadedIcon
                                      : documentUploadIcon
                                  }
                                  alt='Whitepapers'></img>
                              </span>
                              <span className='font-size-12'>Whitepapers</span>
                            </Label>
                            <div>
                              {fileUrls.whitepapers_doc && (
                                <a
                                  href={fileUrls.whitepapers_doc}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                  View File
                                </a>
                              )}
                            </div>
                            <Input
                              type='file'
                              id='whitepapers'
                              name='whitepapers_doc'
                              onChange={changeHandler}
                              style={{ display: 'none' }}
                            />
                            <ReactTooltip
                              anchorId='whitepapers_doc'
                              place='bottom'
                              content={
                                optionalFile['whitepapers_doc'] &&
                                optionalFile['whitepapers_doc'].name
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor='integration_flow' id='integration_flows_doc'>
                              <span
                                className='pr-1 mb-2 rounded-circle'
                                style={{
                                  color: '#134a87',
                                  fontSize: 15,
                                  cursor: 'pointer'
                                }}>
                                <img
                                  src={
                                    optionalFile['integration_flows_doc']
                                      ? documentUploadedIcon
                                      : documentUploadIcon
                                  }
                                  alt='IntegrationFlows'></img>
                              </span>
                              <span className='font-size-12'>Integration Flows</span>
                            </Label>
                            <div>
                              {fileUrls.integration_flows_doc && (
                                <a
                                  href={fileUrls.integration_flows_doc}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                  View File
                                </a>
                              )}
                            </div>
                            <Input
                              type='file'
                              id='integration_flow'
                              name='integration_flows_doc'
                              onChange={changeHandler}
                              style={{ display: 'none' }}
                            />
                            <ReactTooltip
                              anchorId='integration_flows_doc'
                              place='bottom'
                              content={
                                optionalFile['integration_flows_doc'] &&
                                optionalFile['integration_flows_doc'].name
                              }
                            />
                          </div>
                          <div>
                            <Label htmlFor='flow_diagram' id='flow_of_funds_diagram_doc'>
                              <span
                                className='pr-1 mb-2 rounded-circle'
                                style={{
                                  color: '#134a87',
                                  fontSize: 14,
                                  cursor: 'pointer'
                                }}>
                                <img
                                  src={
                                    optionalFile['flow_of_funds_diagram_doc']
                                      ? documentUploadedIcon
                                      : documentUploadIcon
                                  }
                                  alt='FlowOfFundsDiagram'></img>
                              </span>
                              <span className='font-size-12'>Flow of Funds Diagram</span>
                            </Label>
                            <div>
                              {fileUrls.flow_of_funds_diagram_doc && (
                                <a
                                  href={fileUrls.flow_of_funds_diagram_doc}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                  View File
                                </a>
                              )}
                            </div>
                            <Input
                              type='file'
                              id='flow_diagram'
                              name='flow_of_funds_diagram_doc'
                              onChange={changeHandler}
                              style={{ display: 'none' }}
                            />
                            <ReactTooltip
                              anchorId='flow_of_funds_diagram_doc'
                              place='bottom'
                              content={
                                optionalFile['flow_of_funds_diagram_doc'] &&
                                optionalFile['flow_of_funds_diagram_doc'].name
                              }
                            />
                          </div>
                        </Row>
                      </FormGroup>
                    </Col>
                  </Row>

                  <OnRampHandler
                    ariaValueNow={
                      initialValue.describe_ramp_services !== '' ? '60' : '40'
                    }
                    condition={initialValue.describe_ramp_services !== '' ? '60%' : '40%'}
                    handleNext={(values) => handleSubmit(values)}
                    handlePre={prevPage}
                    type='submit'
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default IntegrationDetailsPage1;
