import moment from 'moment';
import React from 'react';
import deleteIcon from '../../../assets/images/trash.png';
import { DeleteUser } from '../../../services/Business/Application';
import { notifySuccess } from '../../../Custom/notification';

function GetUserList({
  user,
  applicationId,
  handleOpenModal,
  setUser,
  index,
  getApplicationFunction
}) {
  const handleDeleteUser = () => {
    DeleteUser(applicationId, user._id).then((res) => {
      notifySuccess('Beneficiary User Removed successfully');
      getApplicationFunction(applicationId);
    });
  };

  const handleEditUser = () => {
    setUser(user);
    handleOpenModal();
  };

  return (
    <React.Fragment>
      <td className='pl-3'>{index + 1}</td>
      <td className='pl-3'>{user.full_name}</td>
      <td className='pl-3'>{moment(user.date_of_birth).format('DD/MM/YYYY')}</td>
      <td className='pl-3'>{user.country}</td>
      <td className='pl-3'>{user.ownership_percent}%</td>
      <td className='pl-3'>{user.role}</td>
      <td className='pl-3'>
        <a href={user.id_doc} target='_blank' rel='noopener noreferrer'>
          View Doc
        </a>
      </td>
      <td className='d-flex '>
        <i
          className='cursor-pointer mdi mdi-pencil'
          onClick={handleEditUser}
          style={{ fontSize: 22 }}></i>
        <div className='cursor-pointer mt-1' onClick={handleDeleteUser}>
          <img src={deleteIcon} alt='delete'></img>
        </div>
      </td>
    </React.Fragment>
  );
}

export default GetUserList;
