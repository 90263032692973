import React from 'react';

const OnRampHandler = ({
  ariaValueNow = '0',
  condition = 1,
  handleNext,
  handlePre,
  nextBtnTitle = 'Next',
  type = 'next'
}) => {
  return (
    <>
      <div
        className='progress mb-5 mt-5'
        style={{
          height: 5
        }}>
        <div
          className='progress-bar bg-success'
          role='progressbar'
          aria-valuenow={ariaValueNow}
          aria-valuemin='0'
          aria-valuemax='100'
          style={{
            width: condition
          }}>
          {condition}
        </div>
      </div>
      <div className='d-flex justify-content-between'>
        {handlePre ? (
          <button
            className='btn btn-auth'
            style={{ width: 150, }}
            onClick={handlePre}>
            Previous
          </button>
        ) : (
          <div></div>
        )}
        {handleNext ? (
          type === 'submit' ? (
            <button
              className='btn btn-auth'
              type='submit'
              style={{ width: 150 }}
              onSubmit={handleNext}>
             {nextBtnTitle}
            </button>
          ) : (
            <button
              className='btn btn-auth'
              style={{ width: 150 }}
              onClick={handleNext}>
              {nextBtnTitle}
            </button>
          )
        ) : (
          <div></div>
        )}
      </div>
    </>
  );
};

export default OnRampHandler;
