import React, { useState } from 'react';
import Drawer from 'react-modern-drawer';
import get from 'lodash/get';
import SimpleBar from 'simplebar-react';
import { ReactComponent as CheckCircleIcon } from '../../assets/images/icons/check-circle.svg';
import { ReactComponent as PaymentOptionsAllIcon } from '../../assets/images/icons/payment-options-all.svg';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';

function PaymentOptionSelect({
  className = '',
  value = {},
  paymentOptions = [],
  onChange = () => {},
  disabled = false,
  limit = 3
}) {
  const [showMore, setShowMore] = useState(false);

  return (
    <>
      <button
        type="button"
        className={
          'btn btn-link d-flex align-items-center ' +
          (disabled ? ' disabled ' : '') +
          className
        }
        style={{ height: 44 }}
        onClick={() => setShowMore(true)}>
        {value.logo ? (
          <img
            src={value.logo}
            alt=""
            width={24}
            style={{ maxHeight: '24px' }}
            className="rounded-circle mr-2"
          />
        ) : (
          <PaymentOptionsAllIcon height={24} width={28} />
        )}
        <span className="font-size-16 font-weight-normal text-capitalize text-dark mx-2">
          {value.display_name || 'All'}
        </span>
        <i className="fas fa-chevron-down font-size-14 text-dark"></i>
      </button>
      <Drawer
        open={showMore}
        onClose={() => {
          setShowMore(false);
        }}
        size="80vh"
        overlayColor="#1D2E3F"
        overlayOpacity={0.2}
        direction="bottom">
        <div className={'py-3 '}>
          <div className="d-flex align-items-center  text-truncate mb-3 mx-3">
            <div className="font-size-20 font-weight-bold flex-fill text-center">
              Select Payment Method
            </div>
          </div>
          <button
            type="button"
            className="btn btn-link p-0 position-absolute"
            style={{ right: 16, top: 18, zIndex: 1 }}
            onClick={() => {
              setShowMore(false);
            }}>
            <CloseIcon height={24} className="" />
          </button>
          <SimpleBar style={{ maxHeight: 'calc(80vh - 80px)' }}>
            {[{ display_name: 'All' }]
              .concat(paymentOptions)
              .map((option, i) => (
                <div
                  key={i}
                  className="d-flex align-items-center text-truncate cursor-pointer rounded hover:bg-gray-900 hover:text-white py-2 px-3 mx-3"
                  onClick={() => {
                    setShowMore(false);
                    onChange(option);
                  }}>
                  {option.logo ? (
                    <img
                      src={option.logo}
                      alt=""
                      width={36}
                      style={{ maxHeight: '36px' }}
                      className="mr-3"
                    />
                  ) : (
                    <PaymentOptionsAllIcon
                      width={36}
                      style={{ maxHeight: '36px' }}
                      className="mr-3"
                    />
                  )}
                  <div className="font-size-18 text-capitalize">
                    {option.display_name}
                  </div>
                </div>
              ))}
          </SimpleBar>
        </div>
      </Drawer>
    </>
  );
}

export default PaymentOptionSelect;
