import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepThirteen(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const step = 13;
  const [completeStep, setCompleteStep] = useState(0);
  const [initialValue, setInitialValue] = useState({
    listing_program_at_bybit: [
      { id: 1, label: 'Direct Listing', value: 'direct_listing', isChecked: false },
      { id: 2, label: 'Launchpad / IEO', value: 'launchpad', isChecked: false },
      { id: 3, label: 'Launchpool', value: 'launchpool', isChecked: false },
      { id: 4, label: 'All Listing Programs', value: 'all_programs', isChecked: false }
    ]
  });

  const ListingProgramsSchema = Yup.object().shape({
    listing_program_at_bybit: Yup.array()
      .required(ERROR_CONST.SELECT_CHECKBOX)
      .min(1, ERROR_CONST.SELECT_CHECKBOX)
  });
  const applicationData = props.applicationData.exchange_application;

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.listing_program.length) {
          const updatedList = initialValue.listing_program_at_bybit.map((program) => {
            if (
              application.exchange_application.listing_program.includes(program.value)
            ) {
              return { ...program, isChecked: true };
            } else {
              return program;
            }
          });

          setInitialValue({
            listing_program_at_bybit: updatedList
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values, { setFieldError }) => {
    const { nextPage, applicationId } = props;
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 68));
    const listingValue = values.listing_program_at_bybit
      .filter((list) => list.isChecked === true && list.value !== 'all_programs')
      .map((list) => list.value);
    const data = {
      listing_program: JSON.stringify(listingValue),
      percentage_complete: stepsRequired
    };
    if (data.listing_program.length <= 0) {
      setFieldError('listing_program_at_bybit', 'select at least one program');
      return;
    }
    JSON.stringify(data);
    setIsLoading(true);

    UpdateExchangeApplication({ application_id: applicationId }, data)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        props.setApplicationData(application);
        nextPage();
        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    setExchangeCompleteStep([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13]);
  };
  const calculateProgress = () => {
    return completeStep;
  };
  return (
    <React.Fragment>
      <div className='col-12 font-size-20'>
        <span className='text-black'>Programs of Interest</span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={ListingProgramsSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <Form>
                <div>
                  <Row>
                    <Col>
                      <FormGroup>
                        <div>
                          {values.listing_program_at_bybit?.map((program, index) => (
                            <FormGroup check key={index}>
                              <Label check>
                                <Field
                                  type='checkbox'
                                  name={`listing_program_at_bybit[${index}].isChecked`}
                                  as={Input}
                                  onChange={(event) => {
                                    const { name, checked } = event.target;
                                    if (
                                      name === 'listing_program_at_bybit[3].isChecked'
                                    ) {
                                      const updatedPrograms =
                                        initialValue.listing_program_at_bybit.map(
                                          (program, index) => {
                                            return {
                                              ...program,
                                              isChecked: checked
                                            };
                                          }
                                        );

                                      setFieldValue(
                                        'listing_program_at_bybit',
                                        updatedPrograms
                                      );
                                    } else {
                                      handleChange(event);
                                    }
                                  }}
                                />{' '}
                                <span className='font-size-12'>{program.label}</span>
                              </Label>
                            </FormGroup>
                          ))}
                          <ErrorMessage name='listing_program_at_bybit'>
                            {(errorMsg) => <div style={{ color: 'red' }}>{errorMsg}</div>}
                          </ErrorMessage>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepThirteen;
