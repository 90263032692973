import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepTwentyTwo(props) {
  const step = 11;
  const { setExchangeCompleteStep, setIsLoading } = props;

  const [initialValue, setInitialValue] = useState({
    demo_link: '',
    video_link: ''
  });

  const ListingPriceSchema = Yup.object().shape({
    demo_link: Yup.string().url(CONST.ENTER_VALID_URL),
    video_link: Yup.string().url(CONST.ENTER_VALID_URL)
  });
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (
          application.exchange_application.demo_link ||
          application.exchange_application.video_link
        ) {
          setInitialValue({
            demo_link: application.exchange_application.demo_link,
            video_link: application.exchange_application.video_link
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 100));
    const data = {
      demo_link: values.demo_link,
      video_link: values.video_link,
      percentage_complete: stepsRequired
    };
    setIsLoading(true);
    UpdateExchangeApplication({ application_id: applicationId }, data)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        props.setApplicationData(application);
        nextPage();
        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    setExchangeCompleteStep([
      0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22
    ]);
  };

  const calculateProgress = () => {
    return completeStep;
  };
  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>
          If the product is not live yet, could you kindly provide a link to a demo?
        </span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={ListingPriceSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='demo_link'
                            className='text-black font-weight-normal'>
                            {CONST.DEMO_LINK}({CONST.OPTIONAL})
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            type='text'
                            name='demo_link'
                            placeholder={CONST.ENTER_URL_PLACEHOLDER}
                            values={values}
                            className='font-size-12'
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}></AuthInput>
                        </div>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='video_link'
                            className='text-black font-weight-normal'>
                            {CONST.VIDEO_LINK}({CONST.OPTIONAL})
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            type='text'
                            className='font-size-12'
                            name='video_link'
                            placeholder={CONST.ENTER_URL_PLACEHOLDER}
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}></AuthInput>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepTwentyTwo;
