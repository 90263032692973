import { Paginator } from 'primereact/paginator';
import React, { useEffect, useState } from 'react';
import { Card, Col, Row } from 'reactstrap';
import { notifyError, notifySuccess } from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import {
  ExistedExchangeListAPI,
  UpdateExcludedExchanges
} from '../../services/Business/Application';

function ExchangeSettings() {
  const [exchangeCheck, setExchangeCheck] = useState([]);
  const [allData, setAllData] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [first, setFirst] = useState(0);

  const exchangeListApiFunction = () => {
    ExistedExchangeListAPI()
      .then((res) => {
        const newData = res.data.data.data.map((item) => {
          return {
            label: item.exchange_name,
            value: item.exchange_name,
            logo: item.logo,
            _id: item._id,
            id: item._id,
            checked: item.checked
          };
        });
        setExchangeCheck(newData);
        setAllData(newData);
        setTotalCount(newData.length);
        const slicedData = newData.slice(first, first + 10);
        setCurrentPageData(slicedData);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    const newPageData = allData.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  useEffect(() => {
    exchangeListApiFunction();
  }, []);

  return (
    <Card body>
      <span className='pl-3 font-size-16'>Whiteliste Exchanges</span>
      <Row>
        <Col lg={4} className='p-3'>
          <div className='table-responsive'>
            <table className='table table-centered table-hover'>
              <thead className='center-text'>
                <th className='pl-4 pb-4 border-0'>#</th>
                <th className='pt-3 pb-4 border-0'>Logo</th>
                <th className='pt-3 pb-4 border-0'>Exchange</th>
              </thead>
              <tbody>
                {currentPageData &&
                  currentPageData.length > 0 &&
                  currentPageData.map((exchange, key) => (
                    <tr className='text-center' key={key}>
                      <GetExchange
                        exchange={exchange}
                        exchangeCheck={exchangeCheck}
                        setExchangeCheck={setExchangeCheck}
                      />
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          <Paginator
            rows={10}
            totalRecords={totalCount}
            first={first}
            onPageChange={(e) => onPageChange(e)}
          />
        </Col>
      </Row>
    </Card>
  );
}

export default ExchangeSettings;

export const GetExchange = ({ exchange, exchangeCheck, setExchangeCheck }) => {
  const handleCheckboxChange = (exchange) => {
    const updatedExchangeCheck = exchangeCheck.map((exCheck) => {
      if (exCheck._id === exchange._id) {
        return { ...exCheck, checked: !exCheck.checked };
      }
      return exCheck;
    });

    const getExchangeData = exchangeCheck.find((item) => item._id === exchange._id);

    const data = {
      exchanges_not_allowed: {
        exchange_name: exchange.label,
        _id: exchange._id
      },
      checked: !getExchangeData.checked
    };
    updateExchangesFunction(data);
    setExchangeCheck(updatedExchangeCheck);
  };

  const updateExchangesFunction = (exchangeData) => {
    UpdateExcludedExchanges(exchangeData)
      .then((res) => {
        notifySuccess('Exchange List updated successfully');
      })
      .catch((err) => {});
  };

  return (
    <>
      <td>
        <input
          type='checkbox'
          className='cursor-pointer'
          checked={
            exchangeCheck.find((exchangeItem) => exchangeItem._id === exchange._id)
              .checked
          }
          style={{ height: 16, width: 16 }}
          onChange={() => handleCheckboxChange(exchange)}
        />
      </td>
      <td>
        <img src={exchange.logo} alt='' height={28} />
      </td>
      <td>{exchange.label}</td>
    </>
  );
};
