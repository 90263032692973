import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../../constants/application';
import { ExistingExchangesListInputName } from './helper';
import Select from 'react-select';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { get } from 'lodash';
import { notificationMessageFunction } from '../../../../constants/notificationConst';

function ExistingExchangesListApp({
  allResponses = [],
  errors = {},
  exchangeList = {},
  filterExchangeList = [],
  isSectionCollapsed,
  toggleSection,
  applicationId,
  setInitialValuesFunc,
  setFilterExchangeList,
  changeHandlerExchange,
  selectedOptions
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);

  useEffect(() => {
    const res = Object.keys(errors).find((i) =>
      ExistingExchangesListInputName.includes(i)
    );
    setErrorShow(!!res);
  }, [errors]);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          ExistingExchangesListInputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);

  const handleSubmit = () => {
    if (
      !ExistingExchangesListInputName.includes(
        Object.keys(errors).find((i) => ExistingExchangesListInputName.includes(i))
      )
    ) {
      setIsLoading(true);
      if (filterExchangeList.length > 0) {
        const formData = filterExchangeList.map((item) => {
          const inputElement = document.getElementsByName(item.exchange_name)[0];
          const volume = inputElement.value;

          return {
            exchange_name: item.exchange_name,
            hour24_trading_volume: volume
          };
        });
        let data = {
          existing_exchanges_listed: JSON.stringify(formData)
        };
        UpdateExchangeApplication({ application_id: applicationId }, data)
          .then((res) => {
            const application = get(res, 'data.data.data', '');
            setIsLoading(false);
            notifySuccess(`Existing Exchanges List data updated successfully`);
            setInitialValuesFunc(application.exchange_application);
          })
          .catch((err) => {
            const errorMessage = get(err, 'data.error.message');
            notifyError(notificationMessageFunction(errorMessage));
          });
      } else if (filterExchangeList.every((i) => i.hour24_trading_volume)) {
        if (filterExchangeList.length > 0) {
          const formData = filterExchangeList.map((item) => {
            const inputElement = document.getElementsByName(item.exchange_name)[0];
            const volume = inputElement.value;

            return {
              exchange_name: item.exchange_name,
              hour24_trading_volume: volume
            };
          });
          let data = {
            existing_exchanges_listed: JSON.stringify(formData)
          };
          JSON.stringify(data);
          UpdateExchangeApplication({ application_id: applicationId }, data)
            .then((res) => {
              const application = get(res, 'data.data.data', '');
              notifySuccess(`Existing Exchanges List data updated successfully`);
              setInitialValuesFunc(application.exchange_application);
            })
            .catch((err) => {
              const errorMessage = get(err, 'data.error.message');
              notifyError(notificationMessageFunction(errorMessage));
            });
        }
      }
    }
  };

  const ValidateTable = ({ index, item }) => {
    const [localVolume, setLocalVolume] = useState(item.hour24_trading_volume || '');

    useEffect(() => {
      setLocalVolume(item.hour24_trading_volume);
    }, [item.hour24_trading_volume]);

    const handleBlur = () => {
      const updatedFilterExchangeList = filterExchangeList.map((exchange) => {
        if (exchange._id === item._id) {
          return { ...exchange, hour24_trading_volume: localVolume };
        }
        return exchange;
      });
      setFilterExchangeList(updatedFilterExchangeList);
    };
    return (
      <>
        <td>{index + 1}</td>
        <td className=''>{item.exchange_name}</td>
        <td>
          <div className='d-flex'>
            <label style={{ float: 'left' }} className='pt-3 pr-2'>
              $
            </label>

            <Input
              type='text'
              name={item.exchange_name}
              style={{ borderRadius: '8px', width: '100%' }}
              value={localVolume}
              placeholder='Enter 24 hours trading volume'
              className='font-size-12'
              onChange={(e) => {
                setLocalVolume(
                  Number(e.target.value.replace(/[^0-9]/g, '')).toLocaleString()
                );
              }}
              onBlur={handleBlur}
            />
          </div>
        </td>
      </>
    );
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Existing Exchanges List</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <Row>
                <Col lg={12}>
                  <FormGroup className='mb-4'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='existing_exchnage_listed'
                        className='text-black font-weight-normal'>
                        {CONST.EXISTING_EXCHANGES_LISTED}({CONST.OPTIONAL})
                      </Label>
                    </div>
                    <div>
                      <Select
                        className='select-input border-dark font-size-12'
                        isMulti
                        style={{
                          paddingRight: '30px',
                          borderRadius: '8px'
                        }}
                        options={exchangeList}
                        onChange={(val) => changeHandlerExchange(val)}
                        name='existing_exchnage_listed'
                        value={selectedOptions}
                      />
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  {filterExchangeList.length > 0 ? (
                    <div className='table-responsive'>
                      <table className='table table-centered table-hover'>
                        <thead className='font-size-12 center-text'>
                          <tr>
                            <th>#</th>
                            <th>Exchange Name</th>
                            <th>24 hour trading volume</th>
                          </tr>
                        </thead>
                        <tbody>
                          {filterExchangeList.map((item, index) => (
                            <tr
                              key={index}
                              className='center-text font-size-12 font-weight-medium cursor-pointer'>
                              <ValidateTable item={item} index={index} />
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  ) : (
                    ''
                  )}
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default ExistingExchangesListApp;
