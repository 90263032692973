import { stringify } from 'query-string';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal, ModalHeader } from 'reactstrap';
import { PayQuotePrice } from '../../../services/Business/Application';

function PayConfirm(props) {
  const history = useHistory();
  const [isLoaded, setIsLoaded] = useState(true);
  const createPaymentIntent = () => {
    setIsLoaded(false);
    PayQuotePrice(props.subAppId)
      .then((res) => {
        const paymentIntent = res.data.data.paymentIntent;
        if (paymentIntent && paymentIntent.id) {
          setIsLoaded(true);
          props.handleModal();
          props.GetApplicationResponseFunc(props.applicationId);
          history.push(
            `/sub-application/pay-quote?${stringify({
              sub_application_id: props.subAppId,
              payment_intent_id: paymentIntent.id
            })}`
          );
        }
      })
      .catch((error) => {
        console.log('error PayConfirm', error);
      });
  };
  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.handleModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        style={{ borderRadius: '8px' }}
        className='w-100'>
        <ModalHeader toggle={props.handleModal}>
          <span className='font-size-16'>Pay Confirm</span>
        </ModalHeader>
        <div>
          {!isLoaded ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <>
              <div className='col-12 col-sm-auto font-size-22 pt-3 pb-3 text-center'>
                <span className='text-black'>Pay Confirm</span>
              </div>
              <div className='font-size-22'></div>
              <div className='d-flex justify-content-center mt-4 mb-4'>
                <button
                  className='btn btn-auth'
                  type='button'
                  onClick={() => {
                    createPaymentIntent();
                  }}
                  style={{ width: 150, height: 40 }}>
                  Pay Now
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
export default PayConfirm;
