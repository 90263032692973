import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';

import AuthInput from '../../../Custom/AuthInput';
import {
  CreateApplication,
  GetAllNetwork,
  GetApplication,
  UpdateApplication
} from '../../../services/Business/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../../Custom/notification';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import get from 'lodash/get';
import Select from 'react-select';
import OnRampHandler from './OnRampHandler';

function GeneralInformationPage({
  setCompletedSteps,
  applicationId,
  setApplicationId,
  nextPage
}) {
  const TickerSchema = Yup.object().shape({
    ticker_name: Yup.string().min(2, ERROR_CONST.NAME).max(5, ERROR_CONST.NAME)
  });
  const GeneralInfoSchema = Yup.object()
    .shape({
      legal_entity_name: Yup.string().required(ERROR_CONST.COMPANY_NAME),
      project_name: Yup.string().required(ERROR_CONST.PROJECT_NAME),
      legal_entity_register_number: Yup.string()
        .required(ERROR_CONST.REGISTER_NUMBER)
        .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
      website: Yup.string().required(ERROR_CONST.WEBSITE).url(ERROR_CONST.INVALID_URL),
      business_category: Yup.string().required(ERROR_CONST.BUSINESS_CATEGORY),
      country_of_incorporation: Yup.string().required(ERROR_CONST.COUNTRY),
      token_address: Yup.string().required(ERROR_CONST.TOKEN_ADDRESS),
      product_description: Yup.string().required(ERROR_CONST.DESCRIPTION),
      network: Yup.string(),
      other_network: Yup.string().when('network', {
        is: (value) => value === 'Other',
        then: Yup.string().required(ERROR_CONST.NETWORK)
      }),
      ticker: Yup.string(),
      ticker_logo: Yup.string()
    })
    .concat(TickerSchema); // ensure TickerSchema is correctly defined

  const [buyCheck, setBuyCheck] = useState(false);
  const [sellCheck, setSellCheck] = useState(false);
  const [otherNetwork, setOtherNetwork] = useState(false);
  const [logoFile, setLogoFile] = useState({});
  const [fileUrls, setFileUrls] = useState({});
  const [allNetworks, setAllNetworks] = useState([]);
  const businesscategory = [
    'Blockchain',
    'NFT',
    'CeFi',
    'DeFi',
    'Gaming',
    'Metaverse',
    'DAO',
    'Ecommerce',
    'Enterprise',
    'Infrastructure',
    'Wallet',
    'Other'
  ];
  const [businessCategory, setBusinessCategory] = useState(
    businesscategory.map((i) => {
      return { label: i, value: i };
    })
  );
  const [selectedFields, setSelectedFields] = useState([]);
  const [initialValue, setInitialValue] = useState({
    legal_entity_name: '',
    project_name: '',
    legal_entity_register_number: '',
    website: '',
    business_category: '',
    country_of_incorporation: '',
    token_address: '',
    product_description: '',
    ticker: '',
    ticker_name: '',
    ticker_logo: '',
    network: '',
    other_network: ''
  });

  useEffect(() => {
    if (applicationId !== '') {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setInitialValue({
          legal_entity_name: application.legal_entity_name,
          project_name: application.project_name,
          legal_entity_register_number: application.legal_entity_register_number,
          website: application.website,
          business_category: application.business_category,
          country_of_incorporation: application.country_of_incorporation,
          token_address: application.token_address,
          product_description: application.product_description,
          ticker: application.ticker,
          ticker_name: application.ticker_name,
          logo: application.logo,
          network: application.network,
          other_network: application.other_network
        });
        setBuyCheck(application.product_enable_buy);
        setSellCheck(application.product_enable_sell);
        if (application.network) {
          setSelectedFields({ label: application.network, value: application.network });
          if (application.network === 'Other') setOtherNetwork(true);
        }
      });
    }
    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));

        setAllNetworks([...setFinalData, { label: 'Other', value: 'Other' }]);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, []);

  const changeHandler = (event) => {
    if (event.target.files[0])
      setLogoFile({ ...logoFile, [event.target.name]: event.target.files[0] });
  };

  const handleChangeOpt = (selected, setFieldValue) => {
    if (selected.value === 'Other') {
      setOtherNetwork(true);
      setSelectedFields(selected);
      setFieldValue('network', selected.value);
      return;
    }
    setOtherNetwork(false);
    setFieldValue('network', selected.value);
    setSelectedFields(selected);
  };

  const handleSubmit = (values) => {
    const payloadData = new FormData();
    payloadData.append('application_type', 'ONRAMP');
    payloadData.append('application_status', 'INCOMPLETE');
    payloadData.append('legal_entity_name', values.legal_entity_name.toLowerCase());
    payloadData.append('project_name', values.project_name);
    payloadData.append(
      'legal_entity_register_number',
      values.legal_entity_register_number
    );
    payloadData.append('website', values.website);
    payloadData.append('business_category', values.business_category);
    payloadData.append('country_of_incorporation', values.country_of_incorporation);
    payloadData.append('token_address', values.token_address);
    payloadData.append('product_enable_buy', buyCheck);
    payloadData.append('product_enable_sell', sellCheck);
    payloadData.append('product_description', values.product_description);
    payloadData.append('ticker', values.ticker ? values.ticker : 'Name');
    payloadData.append('ticker_name', values.ticker_name);
    payloadData.append(
      'ticker_logo',
      logoFile['ticker_logo'] ? logoFile['ticker_logo'] : ''
    );
    payloadData.append('network', selectedFields.label ? selectedFields.label : '');
    if (selectedFields.label && selectedFields.label === 'Other') {
      payloadData.append('other_network', values.other_network);
    } else {
      payloadData.append('other_network', '');
    }

    if (applicationId !== '') {
      UpdateApplication(applicationId, payloadData)
        .then((res) => {
          nextPage();
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    } else {
      CreateApplication(payloadData)
        .then((res) => {
          setApplicationId(get(res, 'data.data.data', ''));
          nextPage();
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
    setCompletedSteps([0]);
  };

  return (
    <React.Fragment>
      <div className='p-4 p-sm-1'>
        <div className='col-12 col-sm-auto font-size-22 pb-2'>
          <span className='text-black'>General Application</span>
        </div>
        <Container className='pt-4 pt-md-1'>
          <div>
            <Formik
              enableReinitialize={true}
              initialValues={initialValue}
              validationSchema={GeneralInfoSchema}
              onSubmit={handleSubmit}>
              {({
                errors,
                touched,
                values,
                handleChange,
                setFieldValue,
                setFieldTouched
              }) => (
                <Form>
                  <div>
                    <Row>
                      <Col lg={6} md={12} sm={12}>
                        <CustomInput
                          label={CONST.LEGAL_ENTITY}
                          htmlFor='legal_entity'
                          setFieldTouched={setFieldTouched}
                          name='legal_entity_name'
                          placeholder={CONST.ENTER_LEGAL_ENTITY}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col lg={6} md={12} sm={12}>
                        <CustomInput
                          label={CONST.PROJECT_NAME}
                          htmlFor='project_name'
                          setFieldTouched={setFieldTouched}
                          name='project_name'
                          placeholder={CONST.ENTER_PROJECT_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={12} sm={12}>
                        <FormGroup className='mb-4'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='business_category'
                              className='text-black font-weight-normal'>
                              {CONST.CATEGORY}
                            </Label>
                          </div>
                          <div>
                            <div
                              className={
                                errors['business_category'] &&
                                touched['business_category']
                                  ? 'border-danger'
                                  : ''
                              }>
                              <Select
                                isClearable={false}
                                isSearchable={true}
                                className='font-size-12'
                                name='business_category'
                                options={businessCategory}
                                onChange={(e) =>
                                  setFieldValue('business_category', e.value)
                                }
                                placeholder='Choose Category'
                              />
                            </div>
                            <ErrorMessage name='business_category'>
                              {(errorMsg) => (
                                <div style={{ color: 'red' }}>{errorMsg}</div>
                              )}
                            </ErrorMessage>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={6} md={12} sm={12}>
                        <CustomInput
                          label={CONST.REGISTRATION_NO}
                          htmlFor='legal_entity_register_number'
                          setFieldTouched={setFieldTouched}
                          name='legal_entity_register_number'
                          placeholder={CONST.ENTER_REGISTRATION_NO}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={12} sm={12}>
                        <FormGroup className='mb-4'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='products'
                              className='text-black font-weight-normal'>
                              {CONST.PRODUCTS}
                            </Label>
                          </div>
                          <div className='d-flex direction-row mt-2'>
                            <div className='form-check custom-checkbox mr-3'>
                              <Input
                                name='product_buy_enable'
                                type='checkbox'
                                onChange={() => setBuyCheck(!buyCheck)}
                                checked={buyCheck}
                                style={{ height: 14, width: 14 }}
                                value={buyCheck}
                                className='form-check-input mt-1'
                              />
                              <Label
                                className='form-check-label font-size-12'
                                onClick={() => {
                                  setBuyCheck(!buyCheck);
                                }}>
                                {CONST.BUY}
                              </Label>
                            </div>
                            <div className='form-check custom-checkbox'>
                              <Input
                                name='product_sell_enable'
                                type='checkbox'
                                value={sellCheck}
                                style={{ height: 14, width: 14 }}
                                className='form-check-input mt-1'
                                onChange={() => setSellCheck(!sellCheck)}
                                checked={sellCheck}
                              />
                              <Label
                                className='form-check-label font-size-12'
                                onClick={() => {
                                  setSellCheck(!sellCheck);
                                }}>
                                {CONST.SELL}
                              </Label>
                            </div>
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={6} md={12} sm={12}>
                        <CustomInput
                          label={CONST.TOKEN}
                          htmlFor='token_address'
                          setFieldTouched={setFieldTouched}
                          name='token_address'
                          placeholder={CONST.ENTER_TOKEN_ADDRESS_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={12} sm={12}>
                        <CustomInput
                          label={CONST.COUNTRY}
                          htmlFor='country_of_incorporation'
                          setFieldTouched={setFieldTouched}
                          name='country_of_incorporation'
                          placeholder={CONST.ENTER_COUNTRY}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col lg={6} md={12} sm={12}>
                        <CustomInput
                          label={CONST.WEBSITE}
                          htmlFor='website'
                          setFieldTouched={setFieldTouched}
                          name='website'
                          placeholder={CONST.ENTER_PROJECT_WEBSITE}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={12} sm={12}>
                        <FormGroup style={{ marginBottom: '1.80rem' }}>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='network'
                              className='text-black font-weight-normal'>
                              {CONST.NETWORK}
                            </Label>
                          </div>
                          <div className={errors['network'] ? 'border-danger' : ''}>
                            <Select
                              isClearable={false}
                              isSearchable={true}
                              name='network'
                              className='font-size-12'
                              options={allNetworks}
                              onChange={(e) => handleChangeOpt(e, setFieldValue)}
                              value={selectedFields}
                            />
                          </div>
                          {otherNetwork && (
                            <>
                              <div className='mt-2'>
                                <AuthInput
                                  setFieldTouched={setFieldTouched}
                                  withTouchError={true}
                                  type='text'
                                  placeholder={'Enter network'}
                                  name='other_network'
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}
                                />
                              </div>
                              <ErrorMessage name='listing_program_at_bybit'>
                                {(errorMsg) => (
                                  <div style={{ color: 'red' }}>{errorMsg}</div>
                                )}
                              </ErrorMessage>
                            </>
                          )}
                        </FormGroup>

                        <CustomInput
                          label={CONST.TICKER}
                          htmlFor='ticker'
                          setFieldTouched={setFieldTouched}
                          name='ticker'
                          placeholder={CONST.ENTER_TICKER}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col lg={6} md={12} sm={12}>
                        <CustomInput
                          label={CONST.DESCRIPTION}
                          htmlFor='product_description'
                          setFieldTouched={setFieldTouched}
                          name='product_description'
                          placeholder={CONST.ENTER_DESCRIPTION}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type='textarea'
                        />
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6} md={12} sm={12}>
                        <CustomInput
                          label={CONST.TICKER_NAME}
                          htmlFor='ticker_name'
                          setFieldTouched={setFieldTouched}
                          name='ticker_name'
                          placeholder={CONST.TICKER_NAME}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                        />
                      </Col>
                      <Col lg={6} md={12} sm={12}>
                        <FormGroup className='mb-4'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor={'whitepapers'}
                              id='ticker_logo'
                              className='text-black font-weight-normal'>
                              Ticker Logo
                            </Label>
                          </div>
                          <div
                            style={{
                              borderRadius: '8px',
                              border: '1px solid',
                              height: 47
                            }}
                            className='d-flex direction-row justify-content-between align-items-center pl-1'>
                            <Label htmlFor='whitepapers' className='mt-2'>
                              <div className='cursor-pointer'>
                                <span
                                  className='rounded-circle'
                                  style={{
                                    color: '#134a87',
                                    fontSize: 14
                                  }}>
                                  <img
                                    src={
                                      logoFile['ticker_logo']
                                        ? documentUploadedIcon
                                        : documentUploadIcon
                                    }
                                    alt='Whitepapers'></img>
                                </span>
                                <span className='font-size-12 ml-2'> {CONST.ICON}</span>
                              </div>
                            </Label>
                            <div>
                              {fileUrls.ticker_logo && (
                                <a
                                  href={fileUrls.ticker_logo}
                                  target='_blank'
                                  rel='noopener noreferrer'
                                  className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                  View File
                                </a>
                              )}
                            </div>
                            <Input
                              type='file'
                              id='whitepapers'
                              name='ticker_logo'
                              onChange={changeHandler}
                              style={{ display: 'none' }}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                  <OnRampHandler
                    condition={applicationId === '' ? '0%' : '20%'}
                    handleNext={(values) => handleSubmit(values)}
                    type='submit'
                  />
                </Form>
              )}
            </Formik>
          </div>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default GeneralInformationPage;

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text'
}) => (
  <FormGroup className='mb-4'>
    <div className='font-size-12'>
      <Label htmlFor={htmlFor} className='text-black font-weight-normal'>
        {label}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12'}
        placeholder={placeholder}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
