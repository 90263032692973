import { constants as CONST } from '../../../../constants/application';

export const ApplicantBackgroundInputNames = [
  {
    label: `${CONST.APPLICANT_FIRST_NAME}`,
    name: 'applicant_first_name',
    type: 'text',
    placeholder: `${CONST.ENTER_APPLICANT_FIRST_NAME}`
  },
  {
    label: `${CONST.APPLICANT_LAST_NAME}`,
    name: 'applicant_last_name',
    type: 'text',
    placeholder: `${CONST.ENTER_APPLICANT_LAST_NAME}`
  },
  {
    label: `${CONST.APPLICANT_EMAIL}`,
    name: 'applicant_email',
    type: 'text',
    placeholder: `${CONST.ENTER_APPLICANT_EMAIL}`
  },
  {
    label: `${CONST.APPLICANT_TELEGRAM}(${CONST.OPTIONAL})`,
    name: 'applicant_telegram',
    type: 'text',
    placeholder: `${CONST.ENTER_APPLICANT_TELEGRAM_NAME}`
  }
];

export const ProjectNameInputName = [
  'project_name',
  'token_name',
  "project_description",
  'ticker_logo',
  'project_website'
];

export const LinkOfSocialMediasFollowersInputNames = [
  {
    label: `${CONST.TWITTER}`,
    name: 'twitter',
    type: 'text',
    placeholder: `${CONST.ENTER_TWITTER_NAME}`
  },
  {
    label: `${CONST.DISCORD}`,
    name: 'discord',
    type: 'text',
    placeholder: `${CONST.ENTER_DISCORD_NAME}`
  },
  {
    label: `${CONST.LINKEDIN}(${CONST.OPTIONAL})`,
    name: 'linkedin',
    type: 'text',
    placeholder: `${CONST.ENTER_LINKEDIN_NAME}`
  },
  {
    label: `${CONST.FACEBOOK}(${CONST.OPTIONAL})`,
    name: 'facebook',
    type: 'text',
    placeholder: `${CONST.ENTER_FACEBOOK_NAME}`
  },
  {
    label: `${CONST.INSTAGRAM}(${CONST.OPTIONAL})`,
    name: 'instagram',
    type: 'text',
    placeholder: `${CONST.ENTER_INSTAGRAM_NAME}`
  },
  {
    label: `${CONST.TIKTOK}(${CONST.OPTIONAL})`,
    name: 'tiktok',
    type: 'text',
    placeholder: `${CONST.ENTER_TIKTOK_NAME}`
  }
];

export const LinkToCoinMarketCapCoinGeckoInputName = [
  {
    label: `${CONST.COINMARKETCAPURL}(${CONST.OPTIONAL})`,
    name: 'coin_market_cap_url',
    type: 'text',
    placeholder: `https://example.com`,
    col: 4
  },
  {
    label: `${CONST.COINGECKOURL}(${CONST.OPTIONAL})`,
    name: 'coin_gecko_url',
    type: 'text',
    placeholder: `https://example.com`,
    col: 4
  },
  {
    label: `${CONST.LOOKING_TO_GET_LISTED}(${CONST.OPTIONAL})`,
    name: 'looking_to_get_listed',
    type: 'text',
    placeholder: `https://example.com`,
    col: 4
  }
];

export const ChainsSupportedInputName = [
  'blockchain_network',
  'token_address',
  'other_network',
];

export const NumberOfActiveProductInputName = ['number_of_active_users'];

export const ExistingExchangesListInputName = ['existing_exchnage_listed'];

export const MarketMakerInputName = ['market_makers'];

export const ProjectInfluencersInputName = ['marketing_partners'];

export const UniqueSellingPointInputName = ['unique_selling_point'];

export const ExchangeLaunchMarketingCampaignInputName = [
  'exchange_launch_marketing_campaign'
];

export const MarketingBudgetInputName = ['marketing_budget'];

export const ListingDateInputName = ['listing_date'];

export const PotentialListingInputName = ['listing_price'];

export const AuditingReportInputName = ['interested_in_audit', 'audit_doc'];

export const TokenomicsInputName = ['tokenomics_doc', 'token_distribution_doc', 'token_use_case'];

export const ListingProgramInputName = ['listing_program_at_bybit'];

export const ProjectsWhitepaperAndPitchDeckInputName = [
  'whitepapers_doc',
  'pitch_decks_doc'
];

export const InvestmentHistoryInputName = ['selectRound', 'totalAmountStage', 'tokenPriceStage', 'dynamicFields'];

export const RoadMapInputName = ['road_map'];

export const TargetGeoInputName = ['target_geo'];

export const DemoVideoLinkInputName = [
  {
    label: `${CONST.DEMO_LINK}(${CONST.OPTIONAL})`,
    name: 'demo_link',
    type: 'text'
  },
  {
    label: `${CONST.VIDEO_LINK}(${CONST.OPTIONAL})`,
    name: 'video_link',
    type: 'text'
  }
];

export const ExtraInformationInputName = ['extra_information'];
