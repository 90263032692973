import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { Formik, Form, Field } from 'formik';
import { constants as CONST } from '../../../constants/application';
import { notifyError, notifySuccess } from '../../../Custom/notification';
import AuthInput from '../../../Custom/AuthInput';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { GetApplication } from '../../../services/Onramp/Application';
import { useQueryParam, StringParam } from 'use-query-params';
import get from 'lodash/get';
import { useHistory } from 'react-router-dom';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import './application.css';

function ViewOnRampApplication({
  props,
  setTextareaValues,
  setClickedLabels,
  setAddQuestions,
  setApplicationId,
  setBeneficialusersValues
}) {
  const [applicationId] = useQueryParam('application_id', StringParam);
  useEffect(() => {
    setApplicationId(applicationId);
  }, []);

  const [optionalFile] = useState({});
  const [fileUrls, setFileUrls] = useState({});
  const [selectNetworkList, setSelectnetworkList] = useState([]);
  const [filterExchangeList, setFilterExchangeList] = useState([]);
  const [selected, setSelected] = useState([]);
  const [displayinvestorListData, setDisplayinvestorListData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const history = useHistory();
  const CheckboxValues = [
    { id: 1, label: 'Direct Listing', value: 'direct_listing', isChecked: false },
    { id: 2, label: 'Launchpad / IEO', value: 'launchpad', isChecked: false },
    { id: 3, label: 'Launchpool', value: 'launchpool', isChecked: false },
    { id: 4, label: 'All Listing Programs', value: 'all_programs', isChecked: false }
  ];
  const investorRound = [
    { value: 'n/a', label: 'N/A' },
    { value: 'seed', label: 'Seed' },
    { value: 'series_a', label: 'Series A' },
    { value: 'series_b', label: 'Series B' },
    { value: 'series_c', label: 'Series C' },
    { value: 'series_d', label: 'Series D' },
    { value: 'series_e', label: 'Series E' },
    { value: 'late_stage_round', label: 'Late Stage Round' },
    { value: 'ipo', label: 'IPO' }
  ];
  const [checkBoxesData] = useState(CheckboxValues);
  const [initialValue, setInitialValue] = useState({
    applicant_first_name: '',
    applicant_last_name: '',
    applicant_email: '',
    applicant_telegram: '',
    project_name: '',
    token_name: '',
    token_ticker: '',
    project_website: '',
    project_description: '',
    twitter: '',
    discord: '',
    linkedin: '',
    facebook: '',
    instagram: '',
    tiktok: '',
    coin_market_cap_url: '',
    coin_gecko_url: '',
    looking_to_get_listed: '',
    blockchain_network: '',
    token_address: '',
    other_network: '',
    number_active_users: '',
    existing_exchnage_listed: '',
    market_makers: '',
    marketing_partners: '',
    exchange_launch_marketing_campaign: '',
    marketing_budget_allocated_for_listing: '',
    listing_program_at_bybit: [],
    listing_date: '',
    listing_price: '1',
    interested_in_audit: false,
    token_use_case: '',
    road_map: '',
    unique_selling_point: '',
    target_geo: '',
    demo_link: '',
    video_link: '',
    extra_information: ''
  });

  const addClickedLabel = (labelName, fieldName, id) => {
    let modifiedString = labelName;
    if (modifiedString.includes('Beneficial')) {
      modifiedString = modifiedString.replace('Beneficial', '');
      setBeneficialusersValues((prevClickedLabels) => {
        const labelExists = prevClickedLabels.some(
          (label) => label.fieldName === fieldName
        );

        if (labelExists) {
          return prevClickedLabels;
        }

        return [...prevClickedLabels, { labelName, fieldName, id }];
      });
    }
    notifySuccess(`${modifiedString} Addedd in List.`);
    setClickedLabels((prevClickedLabels) => {
      const labelExists = prevClickedLabels.some(
        (label) => label.fieldName === fieldName
      );

      if (labelExists) {
        return prevClickedLabels;
      }

      return [...prevClickedLabels, { labelName, fieldName }];
    });
    setTextareaValues((prevValues) => ({ ...prevValues, [fieldName]: '' }));
  };

  if (!applicationId) {
    history.push(`/exchange-applications`);
  }
  function formatDateForInput(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  useEffect(() => {
    let applicationData;
    setIsLoaded(false);
    if (applicationId !== '') {
      GetApplication(applicationId)
        .then((res) => {
          const application = get(res, 'data.data.data[0]', '');

          applicationData = application.exchange_application;
          let updatedList = checkBoxesData.map((program) => {
            if (applicationData.listing_program.includes(program.value)) {
              return { ...program, isChecked: true };
            } else {
              return program;
            }
          });

          setInitialValue({
            application_status: application.application_status,
            applicant_first_name: applicationData?.applicant_first_name || '',
            applicant_last_name: applicationData?.applicant_last_name || '',
            applicant_email: applicationData.applicant_email,
            applicant_telegram: applicationData.applicant_telegram,
            project_name: applicationData?.project_name || '',
            token_name: applicationData?.token_name || '',
            project_website: applicationData?.project_website || '',
            project_description: applicationData?.project_description || '',
            coin_market_cap_url: applicationData?.coin_market_cap_url || '',
            coin_gecko_url: applicationData?.coin_gecko_url || '',
            looking_to_get_listed: applicationData?.looking_to_get_listed || '',
            blockchain_network: applicationData?.blockchain_network || '',
            other_network: applicationData.other_network,
            number_active_users: Number(
              applicationData.number_of_active_users + ''.replace(/[^0-9]/g, '')
            ).toLocaleString(),
            marketing_partners: applicationData.marketing_partners,
            exchange_launch_marketing_campaign:
              applicationData.exchange_launch_marketing_campaign,
            marketing_budget_allocated_for_listing:
              applicationData.marketing_budget_allocated_for_listing,
            listing_date: !!applicationData.listing_date
              ? formatDateForInput(applicationData.listing_date)
              : '',
            listing_price: applicationData?.listing_price || '',
            interested_in_audit: applicationData?.interested_in_audit || false,
            selectRound: applicationData?.selectRound || '',
            totalAmountStage: applicationData?.totalAmountStage || '',
            tokenPriceStage: applicationData?.tokenPriceStage || '',
            token_address: applicationData?.token_address || '',
            dynamicFields: applicationData?.investment_stages || [
              { name: '', amount: '', lead: false, tier: '' }
            ],
            twitter: applicationData?.social_media[0]?.twitter || '',
            discord: applicationData?.social_media[0]?.discord || '',
            linkedin: applicationData?.social_media[0]?.linkedin || '',
            facebook: applicationData?.social_media[0]?.facebook || '',
            instagram: applicationData?.social_media[0]?.instagram || '',
            tiktok: applicationData?.social_media[0]?.tiktok || '',
            token_use_case: applicationData.token_use_case,
            road_map: applicationData.road_map,
            unique_selling_point: applicationData.unique_selling_point,
            target_geo: applicationData.target_geo,
            demo_link: applicationData?.demo_link || '',
            video_link: applicationData?.video_link || '',
            extra_information: applicationData?.extra_information || '',
            listing_program_at_bybit: updatedList,
            ticker_logo: applicationData.ticker_logo,
            whitepapers_doc: applicationData.whitepapers_doc,
            pitch_decks_doc: applicationData.pitch_decks_doc,
            whitepapers_doc_url: applicationData.whitepapers_doc_url || '',
            pitch_decks_doc_url: applicationData.pitch_decks_doc_url || '',
            audit_doc: applicationData.audit_doc,
            tokenomics_doc: applicationData.tokenomics_doc,
            token_distribution_doc: applicationData.token_distribution_doc,
            market_makers: applicationData.market_makers || [],
            team_members: applicationData.team_members || []
          });
          setFilterExchangeList(applicationData.existing_exchanges_listed);
          setSelectnetworkList(applicationData.blockchain_network);
          applicationData.social_media.forEach((social_media) => {
            setInitialValue((prevValues) => ({
              ...prevValues,
              [social_media.name]: social_media.account_id
            }));
          });
          setFileUrls({
            ticker_logo: applicationData.ticker_logo,
            whitepapers_doc: applicationData.whitepapers_doc,
            pitch_decks_doc: applicationData.pitch_decks_doc,
            audit_doc: applicationData.audit_doc,
            tokenomics_doc: applicationData.tokenomics_doc,
            token_distribution_doc: applicationData.token_distribution_doc
          });
          setSelected(applicationData.market_makers);
          setDisplayinvestorListData(applicationData.investment_stages);
          setAddQuestions(application.add_questions);
          setIsLoaded(true);
        })
        .catch((error) => {
          setIsLoaded(true);
          const errorMessage = get(error, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  }, []);

  return (
    <React.Fragment>
      <div className='p-4'>
        {!isLoaded ? (
          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
            <i
              className='fas fa-circle-notch fa-spin text-muted'
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <Container className='col-12 col-sm-auto pt-4'>
            <div>
              <Formik enableReinitialize={true} initialValues={initialValue}>
                {({ errors, touched, values }) => (
                  <Form>
                    <CustomHeaders headerName='Applicant Background' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'applicant_first_name'}
                            labelName={CONST.APPLICANT_FIRST_NAME}
                            placeholder={CONST.ENTER_APPLICANT_FIRST_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={
                              values &&
                              values.application_status &&
                              [
                                'KYB',
                                'ACCEPT',
                                'COMPLETE',
                                'REQUEST_ACTION',
                                'ADD_QUESTIONS',
                                'REJECT',
                                'QUOTE',
                                'PAID',
                                'PROCESSING'
                              ].includes(values.application_status)
                                ? 'text'
                                : 'password'
                            }
                          />
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'applicant_last_name'}
                            labelName={CONST.APPLICANT_LAST_NAME}
                            placeholder={CONST.ENTER_APPLICANT_LAST_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={
                              values &&
                              values.application_status &&
                              [
                                'KYB',
                                'ACCEPT',
                                'COMPLETE',
                                'REQUEST_ACTION',
                                'ADD_QUESTIONS',
                                'REJECT',
                                'QUOTE',
                                'PAID',
                                'PROCESSING'
                              ].includes(values.application_status)
                                ? 'text'
                                : 'password'
                            }
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'applicant_email'}
                            labelName={CONST.APPLICANT_EMAIL}
                            placeholder={CONST.ENTER_APPLICANT_EMAIL}
                            errors={errors}
                            touched={touched}
                            inputType={
                              values &&
                              values.application_status &&
                              [
                                'KYB',
                                'ACCEPT',
                                'COMPLETE',
                                'REQUEST_ACTION',
                                'ADD_QUESTIONS',
                                'REJECT',
                                'QUOTE',
                                'PAID',
                                'PROCESSING'
                              ].includes(values.application_status)
                                ? 'text'
                                : 'password'
                            }
                          />
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'applicant_telegram'}
                            labelName={CONST.APPLICANT_TELEGRAM}
                            placeholder={CONST.ENTER_APPLICANT_TELEGRAM_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                            extraLabel={` (${CONST.OPTIONAL})`}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Project Background' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'project_name'}
                            labelName={CONST.PROJECT_NAME}
                            placeholder={CONST.ENTER_PROJECT_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'token_name'}
                            labelName={CONST.TOKEN_NAME}
                            placeholder={CONST.ENTER_TOKEN_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'project_website'}
                            labelName={CONST.PROJECT_WEBSITE}
                            placeholder={CONST.ENTER_PROJECT_WEBSITE}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFileInput
                            labelName='Token Ticker'
                            addClickedLabel={addClickedLabel}
                            fieldName='ticker_logo'
                            optionalFile={optionalFile}
                            fileUrls={fileUrls}
                            errors={errors}
                            values={values}
                            headerName={CONST.ICON}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'project_description'}
                            labelName={CONST.PROJECT_DESCRIPTION}
                            placeholder={CONST.ENTER_PROJECT_WEBSITE}
                            errors={errors}
                            touched={touched}
                            inputType={'textarea'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Team Members' />
                    <Row>
                      <Col md={12} sm={12} lg={12}>
                        <div className='table-responsive'>
                          <table className='table table-centered table-hover'>
                            <thead className='center-text'>
                              <tr className='font-size-12 text-muted center-text'>
                                <th className='pl-4 pt-3 border-0'>#</th>
                                <th className='pl-4 pt-3 border-0'>First Name</th>
                                <th className='pl-4 pt-3 border-0'>Last Name</th>
                                <th className='pl-4 pt-3 border-0'>email</th>
                                <th className='pl-4 pt-3 border-0'>LinkedIn</th>
                              </tr>
                            </thead>
                            <tbody>
                              {values &&
                              values.team_members &&
                              values.team_members.length > 0 ? (
                                <>
                                  {values.team_members.map((member, index) => (
                                    <tr className='center-text font-size-12 font-weight-medium'>
                                      <td className='pl-4'>{index + 1}</td>
                                      <td className='pl-4'>
                                        {member && member.first_name}
                                      </td>
                                      <td className='pl-4'>
                                        {member && member.last_name}
                                      </td>
                                      <td className='pl-4'>{member && member.email}</td>
                                      <td className='pl-4'>
                                        {member && member.linkedin}
                                      </td>
                                    </tr>
                                  ))}
                                </>
                              ) : (
                                <tr>
                                  <td colSpan={5}>
                                    <span className='font-size-18 d-flex justify-content-center'>
                                      No team members found
                                    </span>
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <CustomHeaders headerName='Link of Social Medias + No. of Followers' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={4} sm={4} lg={4}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'twitter'}
                            labelName={CONST.TWITTER}
                            placeholder={CONST.ENTER_TWITTER_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'discord'}
                            labelName={CONST.DISCORD}
                            placeholder={CONST.ENTER_DISCORD_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'linkedin'}
                            labelName={CONST.LINKEDIN}
                            placeholder={CONST.ENTER_LINKEDIN_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                            extraLabel={` (${CONST.OPTIONAL})`}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={4} sm={4} lg={4}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'facebook'}
                            labelName={CONST.FACEBOOK}
                            placeholder={CONST.ENTER_FACEBOOK_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                            extraLabel={` (${CONST.OPTIONAL})`}
                          />
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'instagram'}
                            labelName={CONST.INSTAGRAM}
                            placeholder={CONST.ENTER_INSTAGRAM_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                            extraLabel={` (${CONST.OPTIONAL})`}
                          />
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'tiktok'}
                            labelName={CONST.TIKTOK}
                            placeholder={CONST.ENTER_TIKTOK_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                            extraLabel={` (${CONST.OPTIONAL})`}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Link to CoinMarketCap/CoinGecko' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={4} sm={4} lg={4}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'coin_market_cap_url'}
                            labelName={CONST.COINMARKETCAPURL}
                            placeholder={CONST.ENTER_URL_PLACEHOLDER}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                            extraLabel={` (${CONST.OPTIONAL})`}
                          />
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'coin_gecko_url'}
                            labelName={CONST.COINGECKOURL}
                            placeholder={CONST.ENTER_URL_PLACEHOLDER}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                            extraLabel={` (${CONST.OPTIONAL})`}
                          />
                        </Col>
                        <Col md={4} sm={4} lg={4}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'looking_to_get_listed'}
                            labelName={CONST.LOOKING_TO_GET_LISTED}
                            placeholder={CONST.ENTER_LOOKING_GET_LISTED}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                            extraLabel={` (${CONST.OPTIONAL})`}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Chains Supported' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'blockchain_network'}
                            labelName={CONST.NETWORK}
                            placeholder={CONST.ENTER_BLOCKCHAIN}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                        {values.blockchain_network === 'Other' && (
                          <Col md={6} sm={6} lg={6}>
                            <CustomFieldInput
                              addClickedLabel={addClickedLabel}
                              values={values}
                              filedName={'other_network'}
                              labelName={CONST.OTHER_NETWORK}
                              placeholder={CONST.ENTER_OTHER_NETWORK}
                              errors={errors}
                              touched={touched}
                              inputType={'text'}
                            />
                          </Col>
                        )}
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            values={values}
                            addClickedLabel={addClickedLabel}
                            filedName={'token_address'}
                            labelName={CONST.TOKEN_ADDRESS}
                            placeholder={CONST.ENTER_TOKEN_ADDRESS_NAME}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Number of Active Users in the Product' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'number_active_users'}
                            labelName={CONST.NUMBEROFACTIVEUSERS}
                            placeholder={CONST.ENTER_NUMBER_OF_ACTIVE_USERS}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Existing Exchanges List' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <table className='table center-text'>
                            <thead>
                              <tr>
                                <th>#</th>
                                <th>Exchange Name</th>
                                <th>24 hour trading volume</th>
                              </tr>
                            </thead>
                            <tbody className='font-size-12'>
                              {!filterExchangeList || filterExchangeList.length <= 0 ? (
                                <tr>
                                  <td colSpan={3}>No data found</td>
                                </tr>
                              ) : (
                                <>
                                  {filterExchangeList.map((item, index) => {
                                    return (
                                      <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td
                                          onClick={() =>
                                            addClickedLabel(
                                              `Exchange Name ( ${item.exchange_name} )`,
                                              `exchange_name_${item.exchange_name}`
                                            )
                                          }>
                                          {item.exchange_name}
                                        </td>
                                        <td>
                                          <b>${item.hour24_trading_volume}</b>
                                        </td>
                                      </tr>
                                    );
                                  })}
                                </>
                              )}
                            </tbody>
                          </table>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Name of Your Market Maker' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col lg={12}>
                          <FormGroup className='mb-6'>
                            <div>
                              {selected && selected.length > 0 ? (
                                <>
                                  <div className='font-size-14'>
                                    <Label
                                      htmlFor='number_active_users'
                                      className='text-black font-weight-normal clickable-label                                      '
                                      style={{ cursor: 'pointer' }}
                                      data-replace={`${CONST.MARKETMAKERS}`}
                                      onClick={() =>
                                        addClickedLabel(
                                          CONST.MARKETMAKERS,
                                          'market_makers',
                                          ''
                                        )
                                      }>
                                      <span>{CONST.MARKETMAKERS}</span>
                                    </Label>
                                  </div>
                                  {selected.map((maker, index) => (
                                    <span
                                      className='badge badge-pill badge-secondary font-size-12 mr-2'
                                      key={index}>
                                      {maker}
                                    </span>
                                  ))}
                                </>
                              ) : (
                                'No Market Makers Found'
                              )}
                            </div>
                            {errors && (
                              <div className='error'>{errors.market_makers}</div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Major Project Influencers and Marketing Partners for Project Promotion' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'marketing_partners'}
                            labelName={CONST.MARKETINGPARTNERS}
                            placeholder={CONST.ENTER_DESCRIPTION}
                            errors={errors}
                            touched={touched}
                            inputType={'textarea'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Strategies for Driving User Adoption After Listing a Token on an Exchange' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'exchange_launch_marketing_campaign'}
                            labelName={CONST.EXCHANGELAUNCHMARKETINGCAMPAIGN}
                            placeholder={CONST.ENTER_DESCRIPTION}
                            errors={errors}
                            touched={touched}
                            inputType={'textarea'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Optimizing Listing Success: Allocating an Effective Exchange Marketing Budget' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'marketing_budget_allocated_for_listing'}
                            labelName={CONST.MARKETINGBUDGETALLOCATEDFORLISTING}
                            placeholder={'Enter marketing budget'}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName="Project's Whitepaper + Pitch deck" />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFileInput
                            labelName="Project's Documents"
                            addClickedLabel={addClickedLabel}
                            fieldName='whitepapers_doc'
                            optionalFile={optionalFile}
                            fileUrls={fileUrls}
                            errors={errors}
                            values={values}
                            headerName={'Whitepapers'}
                          />
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFileInput
                            labelName=''
                            addClickedLabel={addClickedLabel}
                            fieldName='pitch_decks_doc'
                            optionalFile={optionalFile}
                            fileUrls={fileUrls}
                            errors={errors}
                            values={values}
                            headerName={'Pitch deck'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Programs of Interest' />
                    <div className='mt-2 ml-2'>
                      <Row>
                        <Col lg={6}>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='description'
                              className='text-black font-weight-normal clickable-label'
                              style={{ cursor: 'pointer' }}
                              data-replace={'Programs'}
                              onClick={() =>
                                addClickedLabel('Checkboxes', 'Checkboxes', '')
                              }>
                              <span>Programs</span>
                            </Label>
                          </div>
                          <FormGroup>
                            <div>
                              {values.listing_program_at_bybit?.map((program, index) => (
                                <FormGroup check key={index}>
                                  <Label check>
                                    <Field
                                      type='checkbox'
                                      name={`listing_program_at_bybit[${index}].isChecked`}
                                      as={Input}
                                    />
                                    <span
                                      // style={{ marginTop: 4 }}
                                      className='font-size-12 mt-1'>
                                      {program.label}
                                    </span>
                                  </Label>
                                </FormGroup>
                              ))}
                            </div>
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <Row>
                      <Col md={6} sm={6} lg={6}>
                        <CustomHeaders headerName='Estimated Listing Date at Exchange' />
                        <div className='mt-4 ml-2'>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'listing_date'}
                            labelName={CONST.LISTING_DATE}
                            placeholder={CONST.LISTING_DATE}
                            errors={errors}
                            touched={touched}
                            inputType={'datetime-local'}
                          />
                        </div>
                      </Col>
                      <Col md={6} sm={6} lg={6}>
                        <CustomHeaders headerName='Potential Listing Price at Exchange' />
                        <div className='mt-4 ml-2'>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'listing_price'}
                            labelName={CONST.LISTING_PRICE}
                            placeholder={CONST.LISTING_PRICE}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </div>
                      </Col>
                    </Row>
                    <hr />
                    <CustomHeaders headerName='Auditing Report' />
                    <div className='ml-2'>
                      <Row>
                        <Col md={6} sm={6} lg={6} className='mt-3'>
                          <FormGroup check className='mt-4'>
                            <Label check className='cursor-pointer'>
                              <Input
                                style={{ paddingRight: '30px', borderRadius: '8px' }}
                                value={values}
                                name='interested_in_audit '
                                type='checkbox'
                                data-replace='Are you interested in receiving a Smart Contract audit.'
                                readOnly
                                checked={values.interested_in_audit}
                              />
                              <span
                                className='d-flex justify-content-center font-size-12 clickable-label'
                                style={{ marginTop: 3 }}>
                                Are you interested in receiving a Smart Contract audit.
                              </span>
                            </Label>
                          </FormGroup>
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFileInput
                            labelName=''
                            addClickedLabel={addClickedLabel}
                            fieldName='audit_doc'
                            optionalFile={optionalFile}
                            fileUrls={fileUrls}
                            errors={errors}
                            headerName={'Upload your Audit'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Tokenomics' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFileInput
                            labelName='Tokenomics'
                            addClickedLabel={addClickedLabel}
                            fieldName='tokenomics_doc'
                            optionalFile={optionalFile}
                            fileUrls={fileUrls}
                            errors={errors}
                            headerName={'Tokenomics'}
                          />
                        </Col>
                        <Col md={6} sm={6} lg={6} className=''>
                          <CustomFileInput
                            labelName=''
                            addClickedLabel={addClickedLabel}
                            fieldName='token_distribution_doc'
                            optionalFile={optionalFile}
                            fileUrls={fileUrls}
                            errors={errors}
                            headerName={'Token Distribution'}
                          />
                        </Col>
                      </Row>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'token_use_case'}
                            labelName={CONST.USE_CASES_OF_TOKENS}
                            placeholder={CONST.ENTER_DESCRIPTION}
                            errors={errors}
                            touched={touched}
                            inputType={'textarea'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Investment History' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        {displayinvestorListData.length <= 0 && (
                          <span className='d-flex align-items-center ml-2'>
                            No Investor Found
                          </span>
                        )}
                        {displayinvestorListData.length > 0 &&
                          displayinvestorListData.map((round, index) => (
                            <Col lg={12} sm={12} md={12} key={index} className=''>
                              <div className='round-info'>
                                <span className='font-size-16'>
                                  <u>
                                    {
                                      investorRound.find(
                                        (investor) => investor.value === round.round
                                      ).label
                                    }{' '}
                                    Round
                                  </u>
                                </span>
                                <br />
                                <span className='font-size-12'>
                                  {round.token_size} total
                                </span>
                                <br />
                                <span className='font-size-12'>
                                  {round.token_price} per Token
                                </span>
                                <div className='investor-list'>
                                  <Table responsive className='center-text'>
                                    <thead>
                                      <tr>
                                        <th>#</th>
                                        <th>Investor</th>
                                        <th>Amount</th>
                                        <th>Lead Investor?</th>
                                      </tr>
                                    </thead>
                                    <tbody>
                                      {round.all_investors.map((input, idx) => (
                                        <tr key={idx} className='font-size-12'>
                                          <td>{idx + 1}</td>
                                          <td>
                                            <Label
                                              onClick={() =>
                                                addClickedLabel(
                                                  `${input.name} (${round.round} Round ) `,
                                                  `${round.round}_investors_${input.name}`,
                                                  ''
                                                )
                                              }
                                              className='clickable-label'
                                              data-replace={input.name}
                                              style={{ cursor: 'pointer' }}>
                                              <span>{input.name}</span>
                                            </Label>
                                          </td>
                                          <td>{input.amount}</td>
                                          <td>{input.lead ? 'True' : 'False'}</td>
                                        </tr>
                                      ))}
                                    </tbody>
                                  </Table>
                                </div>
                              </div>
                            </Col>
                          ))}
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Road Map and Upcoming Events/Updates' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'road_map'}
                            labelName={CONST.ROADMAP}
                            placeholder={CONST.ENTER_DESCRIPTION}
                            errors={errors}
                            touched={touched}
                            inputType={'textarea'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='What sets your project apart from competitors?' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'unique_selling_point'}
                            labelName={CONST.UNIQUESELLINGPOINT}
                            placeholder={CONST.ENTER_DESCRIPTION}
                            errors={errors}
                            touched={touched}
                            inputType={'textarea'}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Primary focal areas or principal user base regions' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <FormGroup className='mb-6'>
                            <div>
                              {values &&
                              values.target_geo &&
                              values.target_geo.length > 0 ? (
                                <>
                                  <div className='font-size-14'>
                                    <Label
                                      htmlFor='target_geo'
                                      className='text-black font-weight-normal clickable-label                                      '
                                      style={{ cursor: 'pointer' }}
                                      data-replace={`${CONST.TARGETGEO}`}
                                      onClick={() =>
                                        addClickedLabel(CONST.TARGETGEO, 'target_geo', '')
                                      }>
                                      <span>{CONST.TARGETGEO}</span>
                                    </Label>
                                  </div>
                                  {values.target_geo.map((geo, index) => (
                                    <span
                                      className='badge badge-pill badge-secondary font-size-12 mr-2'
                                      key={index}>
                                      {geo}
                                    </span>
                                  ))}
                                </>
                              ) : (
                                'No Market Makers Found'
                              )}
                            </div>
                            {errors && (
                              <div className='error'>{errors.market_makers}</div>
                            )}
                          </FormGroup>
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='If the product is not live yet, could you kindly provide a link to a demo?' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'demo_link'}
                            labelName={CONST.DEMO_LINK}
                            placeholder={CONST.ENTER_URL_PLACEHOLDER}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                          />
                        </Col>
                        <Col md={6} sm={6} lg={6}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'video_link'}
                            labelName={CONST.VIDEO_LINK}
                            placeholder={CONST.ENTER_URL_PLACEHOLDER}
                            errors={errors}
                            touched={touched}
                            inputType={'text'}
                            extraLabel={`(${CONST.OPTIONAL})`}
                          />
                        </Col>
                      </Row>
                    </div>
                    <hr />
                    <CustomHeaders headerName='Additional significant details that could assist us in assessing the project' />
                    <div className='mt-4 ml-2'>
                      <Row>
                        <Col md={12} sm={12} lg={12}>
                          <CustomFieldInput
                            addClickedLabel={addClickedLabel}
                            values={values}
                            filedName={'extra_information'}
                            labelName={CONST.EXTRA_INFORMATION}
                            placeholder={CONST.ENTER_DESCRIPTION}
                            errors={errors}
                            touched={touched}
                            inputType={'textarea'}
                          />
                        </Col>
                      </Row>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        )}
      </div>
    </React.Fragment>
  );
}

export default ViewOnRampApplication;

const CustomHeaders = ({ headerName }) => {
  return (
    <div className='col-12 col-sm-auto font-size-16 pb-2 pb-sm-0'>
      <span className='text-black'>
        <u>{headerName}</u>
      </span>
    </div>
  );
};

const CustomFieldInput = ({
  addClickedLabel,
  values,
  filedName,
  labelName,
  errors,
  touched,
  inputType,
  extraLabel
}) => {
  return (
    <FormGroup className='mb-4'>
      <div className='font-size-12'>
        {addClickedLabel ? (
          <Label
            htmlFor={`${filedName}`}
            className='text-black font-weight-normal clickable-label'
            data-replace={`${labelName} ${extraLabel}`}
            onClick={() => addClickedLabel(labelName, `${filedName}`, '')}
            style={{ cursor: 'pointer' }}>
            <span>
              {labelName}
              {extraLabel}
            </span>
          </Label>
        ) : (
          <Label htmlFor={`${filedName}`} className='text-black font-weight-normal'>
            {labelName}
            {extraLabel}
          </Label>
        )}
      </div>
      <div>
        <AuthInput
          type={`${inputType}`}
          className='levitate-input font-size-12'
          name={`${filedName}`}
          placeholder={`User didn't enter a ${labelName} in the exchange view `}
          values={values}
          disabledField={true}
          errors={errors}
          touched={touched}></AuthInput>
      </div>
    </FormGroup>
  );
};

const CustomFileInput = ({
  labelName,
  addClickedLabel,
  fieldName,
  optionalFile,
  fileUrls,
  errors,
  values,
  headerName
}) => {
  return (
    <FormGroup>
      <div className='font-size-12'>
        <Label
          htmlFor='description'
          className='text-black font-weight-normal clickable-label'
          onClick={() => addClickedLabel(headerName, fieldName, '')}
          data-replace={`${labelName}`}
          style={{ cursor: 'pointer' }}>
          <span>{labelName}</span>
        </Label>
      </div>
      <div
        style={{
          borderRadius: '8px',
          border: '1px solid',
          height: 47
        }}
        className='levitate-input d-flex direction-row justify-content-between align-items-center pl-1'>
        <div
          htmlFor={`${fieldName}`}
          id={`${fieldName}`}
          onClick={() => addClickedLabel(headerName, fieldName, '')}
          style={{ cursor: 'pointer' }}>
          <span
            className='rounded-circle'
            style={{
              color: '#134a87',
              fontSize: 12,
              cursor: 'pointer'
            }}>
            <img
              src={
                optionalFile[fieldName] || (fileUrls && fileUrls[fieldName])
                  ? documentUploadedIcon
                  : documentUploadIcon
              }
              alt={`${fieldName}`}
            />
          </span>
          <span className='font-size-12 ml-2'>{headerName}</span>
        </div>
        {(optionalFile[fieldName] || (fileUrls && fileUrls[fieldName])) && (
          <a
            href={optionalFile[fieldName] || (fileUrls && fileUrls[fieldName])}
            target='_blank'
            rel='noopener noreferrer'
            className='font-size-12 font-weight-bold text-wrap text-primary pr-2'>
            View File
          </a>
        )}
        {errors && errors[fieldName] && (
          <div style={{ fontSize: 12 }} className='text-left text-danger'>
            {errors[fieldName]}
          </div>
        )}
        <ReactTooltip
          anchorId={`${fieldName}`}
          place='bottom'
          content={optionalFile[fieldName] && optionalFile[fieldName].name}
        />
      </div>
      {(fieldName === 'whitepapers_doc' || fieldName === 'pitch_decks_doc') && (
        <div className='mt-2'>
          <AuthInput
            type='text'
            name={fieldName + '_url'}
            values={values}
            className='font-size-12'
            disabledField
            placeholder={CONST.ENTER_URL_PLACEHOLDER}
            errors={errors}></AuthInput>
        </div>
      )}
    </FormGroup>
  );
};
