import React, { useState } from 'react';
import { useEffect } from 'react';
import SimpleBar from 'simplebar-react';
import { Link, useHistory } from 'react-router-dom';
import { Card } from 'reactstrap';
import { GetProviderAllNetworks } from '../../services/Providers/Provider';
import { constants as CONST } from '../../constants/constant';
import get from 'lodash/get';
import { AddOnRampTrial } from '../../services/Business/Crypto';

function SupportedNetwork({ provider }) {
  let history = useHistory();

  const [networks, setNetworks] = useState([]);

  const providerNetworkFunction = (provider) => {
    GetProviderAllNetworks(provider)
      .then((res) => {
        const data = get(res, 'data.data.data', []);
        if (data && data.length > 0 && data[0].network && data[0].network.length > 0) {
          setNetworks(data[0].network);
        } else {
          setNetworks([]);
        }
      })
      .catch((err) => {});
  };

  useEffect(() => {
    providerNetworkFunction(provider);
  }, [provider]);

  return (
    <>
      <Card className="h-100 p-4">
        <div className="font-size-24 font-weight-medium text-black mb-4 text-truncate">
          {CONST.SUPPORTED_NETWORKS}
        </div>
        <SimpleBar style={{ maxHeight: '444px' }}>
          {networks &&
            networks.length > 0 &&
            networks.map((network, key) => (
              <Link
                to={{ pathname: '/network/' + network._id }}
                // to={'/network/' + network._id}
                key={key}
                className="text-reset d-flex align-items-center border-bottom py-2">
                <div className="pr-3 py-1">
                  <img src={network.logo} alt={network.network} height="40" />
                </div>
                <div className="flex-fill font-size-16 font-weight-medium text-capitalize">
                  {network.network}
                </div>
              </Link>
            ))}
        </SimpleBar>
      </Card>
    </>
  );
}

export default SupportedNetwork;
