import Cookies from 'js-cookie';
import React, { useEffect, useState } from 'react';
import { ReferralCodeDetailsAPI } from '../../services/Auth';
import ListusLogo from '../../assets/images/listus-logo-w.png';
import rampnalysis from '../../assets/images/rampnalysis-w.png';

function RightLogo() {
  const [referralData, setReferralData] = useState({});
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    let referral = urlParams.get('referral');

    const referralCode = Cookies.get('referral_code');

    if (referralCode || referral) {
      ReferralCodeDetailsAPI(referralCode || referral)
        .then((res) => {
          const referralRes = res?.data?.data?.data;
          setReferralData(referralRes);
        })
        .catch(() => {});
    }
  }, []);

  return (
    <div className='authentication-bg'>
      <div className='content-container d-flex justify-content-center flex-column align-items-center'>
        {referralData && referralData.brand_logo ? (
          <>
            <img src={ListusLogo} alt='Listus logo' height={300} width={300} />
            <span className='font-size-32 text-white'>
              <i className='mdi mdi-close' />
            </span>
            <img src={referralData.brand_logo} alt='Brand logo' />
          </>
        ) : (
          <img src={rampnalysis} alt='rampnalysis' />
        )}
      </div>
    </div>
  );
}

export default RightLogo;
