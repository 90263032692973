import React, { useRef } from 'react';
import { Form, Formik } from 'formik';
import { Col } from 'reactstrap';

const styles = {
  imagePreview: {
    width: '150px',
    height: '150px',
    objectFit: 'contain',
    border: '1px solid grey',
    borderRadius: '8px'
  }
};

const resizeImage = (file, maxWidth, maxHeight, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event) => {
    const img = new Image();
    img.src = event.target.result;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let width = img.width;
      let height = img.height;

      const scale = Math.min(maxWidth / width, maxHeight / height);
      if (scale < 1) {
        width = width * scale;
        height = height * scale;
      }

      canvas.width = width;
      canvas.height = height;

      if (file.type === 'image/png') {
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
      }

      ctx.drawImage(img, 0, 0, width, height);

      const imageFormat = file.type === 'image/jpeg' ? 'image/jpeg' : 'image/png';
      callback(canvas.toDataURL(imageFormat));
    };
  };
};

function BrandLogo({
  brandLogoInitialValues,
  handleUpdateReferralData,
  getReferralsDetailFunction
}) {
  const fileInputRef = useRef(null);

  const handleBrandLogo = (values, { setFieldError }) => {
    const payloadData = new FormData();

    if (values.brand_logo) {
      payloadData.append('brand_logo', values.brand_logo);
      handleUpdateReferralData(payloadData, 'Your brand logo set successfully', 3);
      getReferralsDetailFunction();
    } else {
      setFieldError('brand_logo', 'Brand logo is required');
    }
  };

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  return (
    <React.Fragment>
      <Formik
        initialValues={brandLogoInitialValues}
        enableReinitialize
        onSubmit={handleBrandLogo}>
        {({ setFieldValue, values, errors, touched }) => (
          <Form>
            <Col lg={6} className='ml-2 mr-3'>
              <div>
                {values.brand_logo !== null && typeof values.brand_logo === 'object' ? (
                  <img
                    src={URL.createObjectURL(values.brand_logo)}
                    alt='Profile'
                    style={styles.imagePreview}
                  />
                ) : values.brand_logo !== null &&
                  typeof values.brand_logo === 'string' ? (
                  <img
                    src={values.brand_logo}
                    alt='Profile'
                    style={styles.imagePreview}
                  />
                ) : (
                  <lord-icon
                    src='https://cdn.lordicon.com/hstjrpbz.json'
                    trigger='hover'
                    style={styles.imagePreview}></lord-icon>
                )}
              </div>
              <button
                type='button'
                className='btn btn-status-change mt-2 font-size-10'
                onClick={handleFileButtonClick}
                style={{ width: '150px' }}>
                Upload your Brand logo
              </button>
              <input
                type='file'
                name='brand_logo'
                style={{ display: 'none' }}
                ref={fileInputRef}
                onChange={(event) => {
                  const file = event.currentTarget.files[0];
                  resizeImage(file, 300, 300, (resizedImageBase64) => {
                    fetch(resizedImageBase64)
                      .then((res) => res.blob())
                      .then((resizedBlob) => {
                        const resizedFile = new File([resizedBlob], file.name, {
                          type: 'image/jpeg',
                          lastModified: Date.now()
                        });
                        setFieldValue('brand_logo', resizedFile);
                      });
                  });
                }}
              />
              {errors.brand_logo && (
                <p className='font-size-12 text-danger mt-2'>{errors.brand_logo}</p>
              )}
              <div className='d-flex mb-3 mt-3'>
                <button
                  type='submit'
                  name='btn'
                  className={`btn btn-auth`}
                  onSubmit={handleBrandLogo}
                  style={{ width: 100, marginLeft: 0 }}>
                  Set Logo
                </button>
              </div>
            </Col>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default BrandLogo;
