import React from 'react';
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';

function RevenueModal({
  userData,
  modalRevenueOpen,
  setModalRevenueOpen,
  setAccountRevenue,
  revenuePercentageFunction
}) {
  return (
    <Modal
      isOpen={modalRevenueOpen}
      toggle={() => {
        setModalRevenueOpen(!modalRevenueOpen);
        setAccountRevenue(0);
      }}
      centered>
      <ModalHeader
        toggle={() => {
          setModalRevenueOpen(!modalRevenueOpen);
          setAccountRevenue('');
        }}>
        Logout
      </ModalHeader>
      <ModalBody>
        <Formik
          initialValues={{
            revenue_percentage: userData.revenue_percentage || 0
          }}
          validationSchema={Yup.object().shape({
            revenue_percentage: Yup.number()
              .required('Revenue percentage is required')
              .min(1, 'Minimum revenue percentage is 1')
              .max(50, 'Maximum revenue percentage is 50')
              .typeError('Invalid revenue percentage')
          })}
          onSubmit={(values) => {
            revenuePercentageFunction({
              user_id: userData.user_id,
              accountRevenue: values.revenue_percentage
            });
          }}>
          <Form>
            <span>Are you sure you want to update this business revenue percentage?</span>
            <br />
            <br />
            <span>Enter revenue percentage (%): </span>
            <Field
              type='number'
              className=''
              placeholder='Revenue Percentage'
              name='revenue_percentage'
              min={1}
              max={50}
              step='.01'
              style={{
                borderRadius: '4px',
                paddingLeft: 5,
                fontSize: '16px',
                height: '40px',
                width: 185
              }}
            />
            <ErrorMessage
              name='revenue_percentage'
              component='div'
              style={{ color: 'red' }}
            />
            <ModalFooter>
              <Button color='primary' type='submit'>
                Revenue Update
              </Button>
              <Button
                color='danger'
                onClick={() => {
                  setModalRevenueOpen(!modalRevenueOpen);
                  setAccountRevenue(0);
                }}>
                Cancel
              </Button>
            </ModalFooter>
          </Form>
        </Formik>
      </ModalBody>
    </Modal>
  );
}

export default RevenueModal;
