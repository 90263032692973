import React, { useEffect, useState } from 'react';
import axios from 'axios';
import SimpleBar from 'simplebar-react';
import { extractFromAddress } from './BGImages';

function GifModal({
  onClose = () => {},
  defaultSearch = '',
  setSelectedGiphy,
  selectedGiphy
}) {
  const [searchQuery, setSearchQuery] = useState(defaultSearch);
  const [gifs, setGifs] = useState([]);
  const [offset, setOffset] = useState(0);
  const [allLoaded, setAllLoaded] = useState(false);

  const API_KEY = 'tAEFUgagRjRNkU24orQdFB8EHMcNTUSe';
  const LIMIT = 10;

  const searchGifs = async (offset) => {
    try {
      const response = await axios.get(
        `https://api.giphy.com/v1/gifs/search?api_key=${API_KEY}&limit=${LIMIT}&offset=${offset}&q=${searchQuery}`
      );
      if (response.data.data.length === 0) {
        setAllLoaded(true);
      } else {
        setGifs((prevGifs) => [...prevGifs, ...response.data.data]);
      }
    } catch (error) {
      console.error('Error fetching GIFs:', error);
    } finally {
    }
  };

  useEffect(() => {
    setAllLoaded(false);
    searchGifs(offset);
  }, [offset]);

  const toggleSelectGif = (gifId) => {
    if (selectedGiphy.includes(gifId)) {
      setSelectedGiphy(selectedGiphy.filter((id) => id !== gifId));
    } else {
      setSelectedGiphy([...selectedGiphy, gifId]);
    }
  };

  return (
    <div
      className='mt-2 px-3 font-size-12 p-2 position-absolute'
      style={{
        zIndex: 10,
        background: 'white',
        minWidth: '60%',
        border: `1px solid #e5e5ea`,
        borderRadius: '8px'
      }}>
      <div className='d-flex justify-content-between'>
        <span className='text-black font-weight-bold font-size-14'>Search Gifs</span>
        <span
          className='text-danger font-weight-bold font-size-14 cursor-pointer'
          onClick={onClose}>
          X
        </span>
      </div>
      <div className='mt-2 pb-3 d-flex'>
        <input
          type='text'
          className='form-control form-control-search'
          value={searchQuery}
          placeholder='Search'
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ minWidth: '200px', maxWidth: '80%' }}
        />
        <button
          className='btn btn-auth ml-2'
          onClick={() => {
            setGifs([]);
            setOffset(0);
            searchGifs(0);
          }}
          style={{ width: '80px' }}>
          Search
        </button>
      </div>
      <SimpleBar style={{ maxHeight: 'calc(60vh - 145px)' }}>
        <div className='d-flex flex-wrap'>
          {gifs.map((gif) => (
            <div
              key={gif.id}
              className='m-2'
              style={{
                border: selectedGiphy.includes(gif.id) ? '2px solid #007FFF' : 'none',
                cursor: 'pointer'
              }}
              onClick={() => toggleSelectGif(gif.id)}>
              <img src={gif.images.fixed_height_downsampled.url} alt={gif.title} />
            </div>
          ))}
          <div
            className='d-flex align-items-center justify-content-center'
            style={{ width: '100%' }}>
            {allLoaded ? (
              <span className='font-size-14 font-weight-medium cursor-pointer p-3'>
                All Viewed
              </span>
            ) : (
              <span
                className='font-size-14 font-weight-medium cursor-pointer p-3'
                onClick={() => setOffset(offset + LIMIT)}>
                Click to Load More
              </span>
            )}
          </div>
        </div>
      </SimpleBar>
    </div>
  );
}

export default GifModal;

export const ShowGiphy = ({ selectedGiphy, removeGif }) => {
  return (
    <>
      {selectedGiphy.map((file, index) => (
        <div key={index} className='d-flex align-items-center mt-2'>
          <div>
            <img
              src={
                typeof file === 'string'
                  ? `https://i.giphy.com/${file}.gif`
                  : URL.createObjectURL(file)
              }
              alt={`Preview https://i.giphy.com/${file}.gif`}
              style={{
                width: '15px',
                height: '15px',
                marginRight: '10px'
              }}
            />
          </div>
          <span className='font-size-14'>
            {extractFromAddress(
              typeof file === 'string' ? `https://i.giphy.com/${file}.gif` : file.name
            )}
          </span>
          <span
            className='ml-2 text-danger cursor-pointer'
            onClick={() => removeGif(index)}>
            x
          </span>
        </div>
      ))}
    </>
  );
};
