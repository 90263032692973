import React, { useEffect, useState } from 'react';
import 'primereact/resources/themes/lara-light-indigo/theme.css';
import 'primereact/resources/primereact.min.css';
import { useQueryParam, StringParam } from 'use-query-params';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { GetApplicationResponse } from '../../../services/Business/Application';
import get from 'lodash/get';
import TableSortButton from '../../../components/TableSortButton';
import GetResponse from './GetResponse';
import { Paginator } from 'primereact/paginator';

function ResponseList({ page, setPage, setApplicationId, statusSuggestions }) {
  const [applicationId] = useQueryParam('application_id', StringParam);
  useEffect(() => {
    setApplicationId(applicationId);
  }, []);

  const [order, setOrder] = useState(null);
  const [allResponses, setAllResponses] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };

  const [loading, setLoading] = useState(true);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    const newPageData = allResponses.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  const GetApplicationResponseFunc = (applicationId) => {
    const status = statusSuggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);
    GetApplicationResponse({ applicationId, order, status })
      .then((res) => {
        const responses = get(res, 'data.data.data', []);
        const count = get(res, 'data.data.count', []);
        const slicedData = get(res, 'data.data.data', []).slice(first, first + 10);
        setCurrentPageData(slicedData);
        setTotalCount(count);
        setLoading(false);
        setAllResponses(responses);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    GetApplicationResponseFunc(applicationId);
  }, [applicationId, order, statusSuggestions]);

  return (
    <React.Fragment>
      <div className='card'>
        <div className='table-responsive'>
          <table className='table table-centered table-hover'>
            <thead className='center-text'>
              <tr className='font-size-12 text-muted center-text'>
                <th className='pl-4 pt-3 border-0'>#</th>
                <th className='pl-4 pt-3 border-0 text-left'>Requested By</th>
                <th className='pt-3 border-0 text-left'>Field Changes Requested</th>
                <th className='pt-3 border-0 text-left'>Notes</th>
                <th className='pl-4 pt-3 border-0'>
                  <div className='d-flex align-items-center'>
                    <span>Date Submitted</span>
                    <TableSortButton
                      className='ml-1'
                      value={order}
                      topValue='created_at_desc'
                      bottomValue='created_at_asc'
                      onChange={(v) => {
                        setPage(1);
                        setOrder(v);
                      }}
                    />
                  </div>
                </th>
                <th className='pl-4 pt-3 border-0'>
                  <div className='d-flex align-items-center'>
                    <span>Status</span>
                    <TableSortButton
                      className='ml-1'
                      value={order}
                      topValue='status_desc'
                      bottomValue='status_asc'
                      onChange={(v) => {
                        setPage(1);
                        setOrder(v);
                      }}
                    />
                  </div>
                </th>
                <th className='pl-4 pt-3 border-0'>Needed Review</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={12}>
                    <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                      <i
                        className='fas fa-circle-notch fa-spin text-muted'
                        style={{ fontSize: 48 }}></i>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {currentPageData && currentPageData.length > 0 ? (
                    currentPageData.map((application, key) => (
                      <React.Fragment key={key}>
                        <tr
                          className='center-text font-size-12 font-weight-medium cursor-pointer'
                          onClick={() => toggleRowExpansion(key)}>
                          <GetResponse
                            application={application}
                            index={key}
                            page={page}
                            GetApplicationResponseFunc={GetApplicationResponseFunc}
                            applicationId={applicationId}
                          />
                        </tr>
                        {expandedRows.includes(key) && (
                          <tr>
                            <td colSpan={7} className='p-0'>
                              <div className='expanded-row'>
                                {application &&
                                application.applicationResponse.field &&
                                Object.keys(application.applicationResponse.field)
                                  .length > 0 ? (
                                  <ul className='notes-list ml-5 pl-4'>
                                    {Object.keys(
                                      application.applicationResponse.field
                                    ).map((fItem, index) => (
                                      <li key={index} className='font-size-12'>
                                        <span className='font-weight-medium'>
                                          <u>{fItem.toUpperCase()}</u>:
                                        </span>
                                        {'  '}
                                        {application.applicationResponse.field[fItem]}
                                      </li>
                                    ))}
                                  </ul>
                                ) : application.applicationResponse &&
                                  application.applicationResponse.notes ? (
                                  <span className='notes-list font-size-12 ml-5 pl-4'>
                                    {application.applicationResponse.notes}
                                  </span>
                                ) : (
                                  <div className='no-notes'>No notes available</div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <tr className='center-text'>
                      <td colSpan={7}>No response found</td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Paginator
        rows={10}
        totalRecords={totalCount}
        first={first}
        onPageChange={(e) => onPageChange(e)}
      />
    </React.Fragment>
  );
}

export default ResponseList;
