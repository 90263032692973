import React from 'react';

import { Row, Col, Container, FormGroup, Button, Label } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import AuthInput from '../../Custom/AuthInput';
import { constants as PLACE_CONST } from '../../constants/placeholder';
import { constants as CONST } from '../../constants/constant';
import { constants as ROUTE_CONST } from '../../constants/routes';
import { constants as BUTTON_CONST } from '../../constants/button';
import { constants as ERROR_CONST } from '../../constants/errorMessage';
import { constants as SUCCESS_CONST } from '../../constants/successMessage';
import { notificationMessageFunction } from '../../constants/notificationConst';

import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../Custom/notification';
import { VerifyInvite } from '../../services/Auth';
import RightLogo from './RightLogo';

function VerifyUserInvite(props) {
  const VerificationSchema = Yup.object().shape({
    password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
    confirm_password: Yup.string()
      .required(ERROR_CONST.CONFIRM_PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED)
  });

  let params = window.location.search;
  let accessToken = new URLSearchParams(params).get('token');

  const handleSubmit = (values) => {
    const data = {
      full_name: values.full_name,
      password: values.password,
      confirm_password: values.confirm_password,
      access_token: accessToken
    };
    VerifyInvite(data)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        localStorage.setItem(CONST.ACCESSTOKEN, data.accessToken);
        localStorage.setItem(CONST.ACCOUNT_TYPE, data.account_type);
        localStorage.setItem(CONST.EMAIL, data.email);
        localStorage.setItem(CONST.ROLE, data.role);
        notifySuccess(SUCCESS_CONST.VERIFICATION_SUCCESSFULLY);
        if (data.account_type === 0) {
          props.history.push(ROUTE_CONST.ADMIN_DASHBOARD);
        } else if (data.account_type === 2) {
          props.history.push(ROUTE_CONST.ONRAMPS);
        } else if (data.account_type === 1) {
          props.history.push(ROUTE_CONST.BUSINESS_DASHBOARD);
        } else if (data.account_type === 5) {
          props.history.push(ROUTE_CONST.BUSINESS_REFERRALS);
        }
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className='p-0'>
          <Row className='no-gutters'>
            <Col lg={4}>
              <div className='authentication-page-content p-4 d-flex align-items-top min-vh-100'>
                <div className='w-100'>
                  <Row className='justify-content-center'>
                    <Col lg={9}>
                      <div>
                        <div className='text-left'>
                          <h4 className='font-size-32 mt-4 text-black'>
                            {CONST.ACCEPT_INVITATION}
                          </h4>
                          <p className='text-muted'>
                            {/* {CONST.VERIFICATION_DESCRIPTION.replace(
                              'EMAIL_ADDRESS',
                              localStorage.getItem(CONST.EMAIL)
                            )} */}
                          </p>
                        </div>

                        <div className='p-2 mt-5'>
                          <Formik
                            initialValues={{
                              full_name: '',
                              confirm_password: '',
                              password: ''
                            }}
                            validationSchema={VerificationSchema}
                            onSubmit={handleSubmit}>
                            {({ errors, touched, values, handleChange }) => (
                              <Form>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='company_name' className='text-black'>
                                      Full Name
                                    </Label>
                                    <AuthInput
                                      type='text'
                                      values={values}
                                      placeholder={'Enter Full Name'}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      name='full_name'
                                    />
                                  </FormGroup>
                                </div>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='password' className='text-black'>
                                      {CONST.PASSWORD}
                                    </Label>
                                    <AuthInput
                                      type='password'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_PASSWORD}
                                      handleChange={handleChange}
                                      isPassword
                                      errors={errors}
                                      touched={touched}
                                      name='password'
                                    />
                                  </FormGroup>
                                </div>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label
                                      htmlFor='confirm_password'
                                      className='text-black'>
                                      {CONST.CONFIRM_PASSWORD}
                                    </Label>
                                    <AuthInput
                                      type='password'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_CONFIRM_PASSWORD}
                                      handleChange={handleChange}
                                      isPassword
                                      errors={errors}
                                      touched={touched}
                                      name='confirm_password'
                                    />
                                  </FormGroup>
                                </div>
                                <div className='mt-3 text-center w-100 waves-effect waves-light'>
                                  <Button
                                    type='submit'
                                    name='btn'
                                    className={`btn btn-auth`}
                                    onSubmit={(values) => handleSubmit(values)}>
                                    {BUTTON_CONST.VERIFY_JOIN_LINK}
                                  </Button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <RightLogo />
            </Col>
          </Row>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default VerifyUserInvite;
