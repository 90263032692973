import React, { useState } from 'react';
import { ReactComponent as CheckCircleIcon } from '../../../assets/images/icons/check.svg';
import moment from 'moment';
import { DeleteApplicationResponse } from '../../../services/Onramp/Application';
import { notifyError, notifySuccess } from '../../../Custom/notification';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { get } from 'lodash';
import { Button, Modal, ModalBody } from 'reactstrap';

function GetResponse({
  application,
  index,
  page,
  GetApplicationResponseFunc,
  applicationId
}) {
  const [modalOpen, setModalOpen] = useState(false);

  const getSeverity = (status) => {
    const statusToSeverity = {
      REJECT: 'danger',
      ACCEPT: 'success',
      ADD_QUESTIONS: 'info',
      negotiation: 'warning',
      COMPLETE: 'success',
      QUOTE: 'secondary',
      renewal: 'secondary'
    };

    return statusToSeverity[status] || 'secondary';
  };

  const statusSuggestions = [
    { id: 'REQUEST_ACTION', name: 'Action' },
    { id: 'ACCEPT', name: 'Accept' },
    { id: 'REJECT', name: 'Reject' },
    { id: 'KYB', name: 'KYB' },
    { id: 'QUOTE', name: 'Quote' },
    { id: 'PAID', name: 'Paid' },
    { id: 'ADD_QUESTIONS', name: 'Add Question' }
  ];

  const deleteApplicationResponse = (onramp_response_id) => {
    DeleteApplicationResponse({ onramp_response_id })
      .then((res) => {
        GetApplicationResponseFunc(applicationId);
        notifySuccess('Response Deleted Successfully');
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.field) {
      const fieldKeys = Object.keys(rowData.field);
      const completedFieldKeys = rowData.completed_field;
      if (fieldKeys.length === completedFieldKeys.length) {
        return (
          <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
            Completed
          </div>
        );
      }

      return fieldKeys.map((value, index) => {
        const isFieldCompleted = completedFieldKeys.includes(value);
        if (isFieldCompleted) {
          return (
            <div key={index} className='mb-1'>
              <CheckCircleIcon height={26} width={28} />
              {value}
            </div>
          );
        } else {
          return (
            <div key={index} className='mb-1'>
              -
            </div>
          );
        }
      });
    } else if (rowData.status === 'ACCEPT' || rowData.status === 'REJECT') {
      return (
        <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
          Completed
        </div>
      );
    } else if (rowData && rowData.response_complete) {
      return (
        <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
          Completed
        </div>
      );
    } else if (rowData && !rowData.response_complete) {
      return (
        <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
          Pending
        </div>
      );
    } else {
      return (
        <div>
          {rowData.status === 'QUOTE' &&
          rowData.quote_price &&
          rowData.quote_price.crypto_currency ? (
            <div
              onClick={() => {
                const externalURL = rowData.payments_txs[0]?.invoiceLinks?.pay;
                const newTab = window.open(externalURL, '_blank');
                newTab.focus();
              }}
              className='p-2 badge badge-secondary font-size-12 cursor-pointer'
              style={{ borderRadius: 8 }}>
              View Invoice
            </div>
          ) : rowData.status === 'QUOTE' &&
            rowData.quote_price &&
            rowData.quote_price.currency ? (
            'Payment Awaited'
          ) : (
            '-'
          )}
        </div>
      );
    }
  };

  return (
    <>
      <td className='pl-4'>{index + 1 + (page - 1) * 10}</td>
      <td className='pl-4 text-left'>
        {application.field && Object.keys(application.field).length > 0 ? (
          Object.keys(application.field).map((value, index) => (
            <div
              className={
                application.completed_field.includes(value)
                  ? 'text-success'
                  : 'text-warning'
              }
              key={index}>
              {index + 1}. {value}
            </div>
          ))
        ) : application &&
          application.status === 'ADD_QUESTIONS' &&
          application.onRampSubApp &&
          application.onRampSubApp.length > 0 &&
          application.onRampSubApp[0].add_questions &&
          application.onRampSubApp[0].add_questions.length > 0 ? (
          application.onRampSubApp[0].add_questions.map((que, index) => (
            <div key={index}>
              {index + 1}. {que.type}
            </div>
          ))
        ) : (
          <div>No fields available</div>
        )}
      </td>
      <td className='pl-4 text-left'>
        {application.field && Object.keys(application.field).length > 0 ? (
          Object.entries(application.field).map(([key, value]) => {
            if (value.length > 0) {
              const truncatedValue =
                String(value).length > 20
                  ? `${String(value).substring(0, 20)}...`
                  : String(value);
              return (
                <div key={key} data-tip={value}>
                  &#8226; {truncatedValue}
                </div>
              );
            } else {
              return <div key={key} data-tip={value}></div>;
            }
          })
        ) : application && application.notes ? (
          String(application.notes).length > 20 ? (
            `${String(application.notes).substring(0, 20)}...`
          ) : (
            String(application.notes)
          )
        ) : application &&
          application.status === 'ADD_QUESTIONS' &&
          application.onRampSubApp &&
          application.onRampSubApp.length > 0 &&
          application.onRampSubApp[0].add_questions &&
          application.onRampSubApp[0].add_questions.length > 0 ? (
          application.onRampSubApp[0].add_questions.map((que, index) => (
            <div key={index}>
              {index + 1}.
              {String(que.question).length > 20
                ? `${String(que.question).substring(0, 20)}
              ...`
                : String(que.question)}
            </div>
          ))
        ) : (
          <div>No notes available</div>
        )}
      </td>
      <td className='text-left' width={200}>
        {moment(application.created_at).format('DD/MM/YYYY hh:mm A')}
      </td>
      <td className='center-text'>
        <span
          className={`p-1 font-size-12 badge badge-pill badge-${getSeverity(
            application.status
          )}`}>
          {statusSuggestions.find((status) => status.id === application.status).name}
        </span>
      </td>
      <td className='pl-4'>{actionBodyTemplate(application)}</td>
      <td className='pl-4'>
        {application && !application.response_complete ? (
          <i
            className='font-size-20 fas fa-trash-alt cursor-pointer'
            style={{ color: 'red' }}
            onClick={() => setModalOpen(true)}></i>
        ) : (
          '-'
        )}
        {modalOpen && (
          <DeleteResponseModal
            modalOpen={modalOpen}
            setModalOpen={setModalOpen}
            application={application}
            deleteApplicationResponse={deleteApplicationResponse}
          />
        )}
      </td>
    </>
  );
}

export default GetResponse;

const DeleteResponseModal = ({
  modalOpen,
  setModalOpen,
  application,
  deleteApplicationResponse
}) => {
  return (
    <Modal
      style={{ width: 440 }}
      isOpen={modalOpen}
      toggle={() => setModalOpen(!modalOpen)}
      centered>
      <ModalBody>
        <span style={{ position: 'absolute', top: 0, right: 5 }} className='p-2'>
          <i
            className='mdi mdi-close font-size-16 cursor-pointer'
            onClick={() => setModalOpen(!modalOpen)}
          />
        </span>
        <div className='d-flex justify-content-center'>
          <lord-icon
            src='https://cdn.lordicon.com/exkbusmy.json'
            trigger='hover'
            colors='outline:#121331,primary:#646e78,secondary:#4bb3fd,tertiary:#ebe6ef'
            style={{ width: '150px', height: '150px' }}></lord-icon>
        </div>
        <span className='d-flex justify-content-center font-size-24 h2'>
          Are you sure?
        </span>
        <span className='d-flex justify-content-center font-size-18 h2'>
          You want to delete this response?
        </span>
        <br />
        <div
          style={{ paddingLeft: 120, paddingRight: 120 }}
          className='d-flex align-items-center justify-content-center flex-column w-100'>
          <Button
            className='w-100'
            color='primary'
            onClick={() => deleteApplicationResponse(application.onramp_response_id)}>
            Delete
          </Button>
          <Button
            className='w-100 mt-2'
            color='danger'
            onClick={() => {
              setModalOpen(!modalOpen);
            }}>
            Cancel
          </Button>
        </div>
        <div className='d-flex' style={{ justifyContent: 'space-evenly' }}></div>
      </ModalBody>
    </Modal>
  );
};
