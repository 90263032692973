import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const GetAllApplications = ({ page, limit, search, type, order }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.APPLICATIONS +
          `?page=${page}` +
          (limit ? `&limit=${limit}` : '') +
          (order ? `&order=${order}` : '') +
          (type ? `&type=${type}` : '') +
          (search ? `&search=${search}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const CreateApplication = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.CREATE_APPLICATION, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetApplication = (params) => {
  return new Promise(async (resolve, reject) => {
    const query =
      '?' +
      Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
    return api
      .getWithToken(businessURL.GET_APPLICATION + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UpdateApplication = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.EDIT_APPLICATION + '?id=' + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const DeleteApplicationAPI = ({ applicationId }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(
        businessURL.DELETE_APPLICATION + '?application_id=' + applicationId
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const DeleteUser = (id, user_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(
        businessURL.DELETE_USER + '?business_id=' + id + '&user_id=' + user_id
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UploadApplicationDocs = (params, data) => {
  return new Promise(async (resolve, reject) => {
    const query =
      '?' +
      Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
    return api
      .postWithToken(businessURL.UPLOAD_DOCS + query, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const EditUser = (params, data) => {
  return new Promise(async (resolve, reject) => {
    const query =
      '?' +
      Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join('&');
    return api
      .postWithToken(businessURL.EDIT_USER + query, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetApplicationResponse = ({ applicationId, status, order }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.APPLICATION_RESPONSE +
          '?application_id=' +
          applicationId +
          (order ? `&order=${order}` : '') +
          (status ? `&status=${status.map((item) => item).join(',')}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetApplicationNotifications = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.APPLICATION_NOTIFICATION + '?application_id=' + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const DeleteNotifications = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.DELETE_NOTIFICATION + '?response_id=' + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const EditOnRampApplication = (id, response_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(
        businessURL.EDIT_ONRAMP_APPLICATION +
          `?application_id=${id}&onramp_response_id=${response_id}`,
        data
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const PayQuotePrice = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.PAY_QUOTE + '?sub_application_id=' + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ConfirmPayment = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.CONFIRM_PAYMENT + '?sub_application_id=' + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetAllNetwork = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.NETWORK_API)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UpdateExchangeApplication = (params, data) => {
  const query =
    '?' +
    Object.keys(params)
      .map((key) => `${key}=${params[key]}`)
      .join('&');
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.EDIT_EXCHANGE_APPLICATION + query, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const completeReqAction = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.REQUEST_ACTION + '?response_id=' + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const completeAddQuestions = (responseId, subApplicationId, data) => {
  const query = `?response_id=${responseId}&sub_application_id=${subApplicationId}`;
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.ANS_QUESTIONS + query, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UpdateExcludedExchanges = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(businessURL.EXCLUDE_EXCHANGE, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ExistedExchangeListAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.EXISTED_EXCHANGE_LIST)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const SetWidgetImagesAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.WIDGET_IMAGES, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetWidgetImages = (accessToken) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(businessURL.GET_WIDGET_IMAGES + `?accessToken=${accessToken}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetWidgetApplication = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.WIDGET_APPLICATION)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const CreateWidgetApplication = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.WIDGET_APPLICATION, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
