import React, { useEffect, useState } from 'react';
import {
  GetAllApplications,
  MassProcessApplication,
  ProcessApplication
} from '../../services/Onramp/Application';
import get from 'lodash/get';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import GetApplication from './GetApplication';
import { stringify } from 'query-string';
import { Paginator } from 'primereact/paginator';
import TableSortButton from '../../components/TableSortButton';
import { Col, Row } from 'reactstrap';
import './application.css';
import PriorityModal from './PriorityModal';

function ExchangeApplicationList({ history }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [page, setPage] = useState(1);
  const [totalCount, setTotalCount] = useState(0);
  const [first, setFirst] = useState(0);
  const [order, setOrder] = useState(null);
  const [search, setSearch] = useState(null);
  const [suggestions, setSuggestions] = useState([
    { id: 0, name: 'Tier 0', checked: false },
    { id: 1, name: 'Tier 1', checked: false },
    { id: 2, name: 'Tier 2', checked: false },
    { id: 3, name: 'Tier 3', checked: false },
    { id: 4, name: 'Tier 4', checked: false }
  ]);

  const [prioritySuggestions, setPrioritySuggestions] = useState([
    { id: 0, name: 'None', checked: false },
    { id: 1, name: 'Low', checked: false },
    { id: 2, name: 'Medium', checked: false },
    { id: 3, name: 'High', checked: false }
  ]);
  const [allData, setAllData] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [statusSuggestions, setStatusSuggestions] = useState([
    {
      id: 'REQUEST_ACTION',
      name: 'Action',
      icon: 'fas fa-unlock',
      color: 'black',
      checked: false
    },
    {
      id: 'PROCESSING',
      name: 'Processing',
      icon: 'fas fa-minus-circle',
      color: 'orange',
      checked: false
    },
    {
      id: 'ACCEPT',
      name: 'Accept',
      icon: 'fas fa-check-circle',
      color: 'green',
      checked: false
    },
    { id: 'KYB', name: 'KYB', icon: 'fas fa-lock', color: 'black', checked: false },
    {
      id: 'QUOTE',
      name: 'Quote',
      icon: 'fas fa-quote-left',
      color: 'black',
      checked: false
    },
    {
      id: 'PAID',
      name: 'Paid',
      icon: 'fas fa-thumbs-up',
      color: 'green',
      checked: false
    },
    {
      id: 'ADD_QUESTIONS',
      name: 'Add Question',
      icon: 'fas fa-question',
      color: 'black',
      checked: false
    },
    {
      id: 'UNREAD',
      name: 'Unread',
      icon: 'fas fa-envelope',
      color: 'black',
      checked: false
    }
  ]);
  const [isTierSectionCollapsed, setIsTierSectionCollapsed] = useState(false);
  const [massPriority, setMassPriority] = useState(false);
  const [isStatusSectionCollapsed, setIsStatusSectionCollapsed] = useState(false);
  const [isPrioritySectionCollapsed, setIsPrioritySectionCollapsed] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [applicationCheck, setApplicationCheck] = useState([]);
  const toggleTierSection = () => {
    setIsTierSectionCollapsed(!isTierSectionCollapsed);
  };

  const toggleStatusSection = () => {
    setIsStatusSectionCollapsed(!isStatusSectionCollapsed);
  };

  const togglePrioritySection = () => {
    setIsPrioritySectionCollapsed(!isPrioritySectionCollapsed);
  };

  const handleCheckboxChange = (id) => {
    const updatedSuggestions = suggestions.map((suggestion) => {
      if (suggestion.id === id) {
        return { ...suggestion, checked: !suggestion.checked };
      }
      return suggestion;
    });

    setPage(1);
    setSuggestions(updatedSuggestions);
  };

  const handleStatusCheckboxChange = (id) => {
    const updatedSuggestions = statusSuggestions.map((suggestion) => {
      if (suggestion.id === id) {
        return { ...suggestion, checked: !suggestion.checked };
      }
      return suggestion;
    });

    setPage(1);
    setStatusSuggestions(updatedSuggestions);
  };

  const handlePriorityCheckboxChange = (id) => {
    const updatedSuggestions = prioritySuggestions.map((suggestion) => {
      if (suggestion.id === id) {
        return { ...suggestion, checked: !suggestion.checked };
      }
      return suggestion;
    });

    setPage(1);
    setPrioritySuggestions(updatedSuggestions);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    const newPageData = allData.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  const getApplications = (params, paramsData) => {
    const tags = suggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);
    const status = statusSuggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);
    const priority = prioritySuggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);
    setIsLoaded(false);
    GetAllApplications(params, {
      page,
      order,
      search,
      ...paramsData,
      tags,
      status,
      priority
    })
      .then((res) => {
        const allApplications = get(res, 'data.data.data.applications', []);
        setAllData(allApplications);
        setApplicationCheck(
          allApplications.reduce((accumulator, currentValue) => {
            accumulator.push({ id: currentValue._id, checked: false });
            return accumulator;
          }, [])
        );
        const slicedData = get(res, 'data.data.data.applications', []).slice(
          first,
          first + 5
        );
        setCurrentPageData(slicedData);
        setTotalCount(get(res, 'data.data.data.count', []));
        setIsLoaded(true);
      })
      .catch((err) => {
        console.log({ err });
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  useEffect(() => {
    const params = {
      application_type: 'EXCHANGE'
    };
    getApplications(params);
  }, [massPriority, order, search, statusSuggestions, prioritySuggestions, suggestions]);

  const handleApplication = (application) => {
    setIsLoaded(false);
    if (!application.sub_application) {
      ProcessApplication(application._id, { priority: 0 }).then((res) => {
        setIsLoaded(true);

        const id = get(res, 'data.data.data.sub_application_id', '');
        history.push(
          `/exchange-application/view-exchange?${stringify({
            application_id: id
          })}`
        );
      });
    } else {
      setIsLoaded(true);

      history.push(
        `/exchange-application/view-exchange?${stringify({
          application_id: application.sub_application._id
        })}`
      );
    }
  };

  const searchFunction = (search) => {
    setSearch(search);
  };

  const handlePriorityClick = () => {
    setIsModalOpen(true);
  };

  const changePriority = (payload) => {
    MassProcessApplication(payload)
      .then((res) => {
        notifySuccess('Application Priority Changed Successfully');
        setPage(page);

        setMassPriority(false);
      })
      .catch((err) => {});
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row>
          <Col lg={2} md={2} sm={2}>
            <div className='card'>
              <div className='px-3 pt-3'>
                <span className='font-size-16'>
                  <i className='mdi mdi-filter'></i> Filters
                </span>
                <br />
                <hr />
                <span className='font-size-14 cursor-pointer' onClick={toggleTierSection}>
                  <i
                    className={`mdi mdi-chevron-${
                      isTierSectionCollapsed === true ? 'right' : 'down'
                    }`}
                  />{' '}
                  Select Tier
                </span>
                <div
                  className={`filter-section ${
                    isTierSectionCollapsed ? 'collapsed' : ''
                  }`}>
                  {suggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className='d-flex align-items-center ml-3'
                      style={{ wordBreak: 'break-all' }}>
                      <input
                        type='checkbox'
                        checked={suggestion.checked}
                        style={{ height: 16, width: 16 }}
                        onChange={() => handleCheckboxChange(suggestion.id)}
                      />
                      <span className='font-size-12 mr-2 ml-1'>{suggestion.name}</span>
                    </div>
                  ))}
                </div>
                <hr />

                <span
                  className='font-size-14 cursor-pointer'
                  onClick={toggleStatusSection}>
                  <i
                    className={`mdi mdi-chevron-${
                      isStatusSectionCollapsed === true ? 'right' : 'down'
                    }`}
                  />
                  Select Status
                </span>
                <div
                  className={`filter-section ${
                    isStatusSectionCollapsed ? 'collapsed' : ''
                  }`}>
                  {statusSuggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className='d-flex align-items-center ml-3'
                      style={{ wordBreak: 'break-all' }}>
                      <input
                        type='checkbox'
                        checked={suggestion.checked}
                        style={{ height: 16, width: 16 }}
                        onChange={() => handleStatusCheckboxChange(suggestion.id)}
                      />
                      <i
                        className={`${suggestion.icon} font-size-14 ml-1`}
                        style={{
                          color: `${suggestion.color}`
                        }}></i>
                      <span className='font-size-12 mr-2 ml-1'>{suggestion.name}</span>
                    </div>
                  ))}
                </div>
                <hr />

                <span
                  className='font-size-14 cursor-pointer'
                  onClick={togglePrioritySection}>
                  <i
                    className={`mdi mdi-chevron-${
                      isPrioritySectionCollapsed === true ? 'right' : 'down'
                    }`}
                  />
                  Select Priority
                </span>
                <div
                  className={`mb-3 filter-section ${
                    isPrioritySectionCollapsed ? 'collapsed' : ''
                  }`}>
                  {prioritySuggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className='d-flex align-items-center ml-3'
                      style={{ wordBreak: 'break-all' }}>
                      <input
                        type='checkbox'
                        checked={suggestion.checked}
                        style={{ height: 16, width: 16 }}
                        onChange={() => handlePriorityCheckboxChange(suggestion.id)}
                      />
                      <i
                        className={`${suggestion.icon} font-size-14 ml-1`}
                        style={{
                          color: `${suggestion.color}`
                        }}></i>
                      <span className='font-size-12 mr-2 ml-1'>{suggestion.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={10} md={10} sm={10}>
            <div className='card'>
              <div className='p-4'>
                <div className='col-12 col-sm-auto font-size-22 font-weight-medium pb-2 pb-sm-0'>
                  <div className='d-flex justify-content-between'>
                    <span className='text-black ml-2'>Your Applications</span>
                  </div>

                  <Row>
                    <Col
                      lg={12}
                      md={12}
                      sm={12}
                      className='mt-2 d-flex justify-content-between'>
                      <input
                        type='text'
                        className='font-size-12 form-control form-control-search'
                        placeholder='Search by Project, Email, Network, Telegram'
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            searchFunction(event.target.value);
                          }
                        }}
                        autoFocus={true}
                        style={{ height: 40 }}
                      />
                      <div className='ml-2'>
                        <button
                          className='btn btn-auth'
                          onClick={() => setMassPriority(!massPriority)}
                          style={{ width: 150, height: 40 }}>
                          {massPriority ? 'Cancel' : 'Set Priority'}
                        </button>
                      </div>
                    </Col>
                  </Row>
                  <div className='table-responsive'>
                    <table className='table table-centered table-hover'>
                      <thead className='center-text'>
                        <tr className='font-size-12 text-muted'>
                          {massPriority && (
                            <th className='pt-3 pb-4 border-0 cursor-pointer'>
                              <button
                                style={{ width: 130 }}
                                className='btn btn-status-change'
                                onClick={handlePriorityClick}>
                                Select Priority
                              </button>
                            </th>
                          )}
                          {isModalOpen && (
                            <PriorityModal
                              priorityApplicationChange={changePriority}
                              isModalOpen={isModalOpen}
                              setIsModalOpen={setIsModalOpen}
                              applicationCheck={applicationCheck}
                              isMass={true}
                            />
                          )}
                          <th className='pl-4 pt-3 pb-4 border-0'>#</th>
                          <th className='pt-3 pb-4 border-0'>Project Name</th>
                          <th className='pt-3 pb-4 border-0'>Token Name</th>
                          <th className='pr-4 pt-3 pb-4 border-0'>Applicant Email</th>
                          <th className='pt-3 pb-4 border-0'>Applicant</th>
                          <th className='pr-4 pt-3 pb-4 border-0'>Telegram</th>
                          <th className='pr-4 pt-3 pb-4 border-0'>Network</th>
                          <th className='pr-4 pt-3 pb-4 border-0'>
                            Total Centralized Exchange Volume 24hr (est)
                          </th>
                          <th className='pr-4 pt-3 pb-4 border-0'>
                            Total DEX Volume 24hr
                          </th>
                          <th className='pr-4 pt-3 pb-4 border-0'>
                            Is listed on Exchange?
                          </th>
                          <th className='pr-4 pt-3 pb-4 border-0'>Investors Involved</th>
                          <th className='pt-3 pb-4 border-0'>Sub Application Status</th>
                          <th className='pt-3 pb-4 border-0'>Application Type</th>
                          <th className='pt-3 pb-4 border-0'>
                            <div className='d-flex align-items-center center-text justify-content-center'>
                              <span className='center-text'>Date Sent</span>
                              <TableSortButton
                                className='ml-1'
                                value={order}
                                topValue='created_at_desc'
                                bottomValue='created_at_asc'
                                onChange={(v) => {
                                  setPage(1);
                                  setOrder(v);
                                }}
                              />
                            </div>
                          </th>
                          <th className='pt-3 pb-4 border-0'>Priority</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoaded ? (
                          <tr>
                            <td colSpan={12}>
                              <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                                <i
                                  className='fas fa-circle-notch fa-spin text-muted'
                                  style={{ fontSize: 48 }}></i>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {currentPageData
                              // .from({ length: rows })
                              .map((application, key) => (
                                <tr
                                  key={key}
                                  className='cursor-pointer center-text font-size-12 font-weight-medium'>
                                  <GetApplication
                                    getApplications={getApplications}
                                    handleApplication={handleApplication}
                                    application={application}
                                    index={key}
                                    page={page}
                                    setPage={setPage}
                                    applicationCheck={applicationCheck}
                                    massPriority={massPriority}
                                    setApplicationCheck={setApplicationCheck}
                                  />
                                </tr>
                              ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Paginator
                  rows={5}
                  totalRecords={totalCount}
                  first={first}
                  onPageChange={(e) => onPageChange(e)}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default ExchangeApplicationList;
