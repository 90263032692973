import React from 'react';

function CoinButton({ logo, symbol, label, onClick, disabled, isCustom = false }) {
  return (
    <button
      type='button'
      className={
        'coin-button btn btn-secondary d-flex align-items-center ml-2 ' +
        (disabled ? 'disabled' : '')
      }
      style={{ height: 44, width: `${isCustom && '120px'}` }}
      disabled={disabled}
      onClick={onClick}>
      {logo && (
        <img
          src={logo}
          alt=''
          width={24}
          style={{ maxHeight: '24px' }}
          className='rounded-circle mr-2'
        />
      )}
      {symbol && (
        <span
          className='d-inline-block rounded-circle d-flex align-items-center justify-content-center font-size-16 text-white'
          style={{ height: 24, width: 24, backgroundColor: '#2775CA' }}>
          <span className='font-size-12'>{symbol}</span>
        </span>
      )}
      <span className='font-size-14 font-weight-normal text-uppercase text-dark ml-auto mx-2'>
        {label}
      </span>
      <i className='fas fa-chevron-down font-size-14 text-dark'></i>
    </button>
  );
}

export default CoinButton;
