import React from 'react';

function MoneyInput({
  value,
  className = '',
  label,
  placeholder = '',
  readonly = false,
  disabled = false,
  onChange,
  children
}) {
  return (
    <div className={'card py-3 px-3 money-input ' + className}>
      <label className="font-size-16 font-weight-normal mb-1">{label}</label>
      <div className="d-flex align-items-center text-truncate">
        {readonly ? (
          <div
            className="money-input__input text-truncate flex-fill"
            style={{
              height: '50px',
              lineHeight: '50px'
            }}>
            {value || placeholder}
          </div>
        ) : (
          <input
            type="text"
            onChange={(evt) => onChange(evt.target.value.replace(/[^\d.]/g, ''))}
            value={value}
            className={
              'money-input__input d-block px-0 bg-transparent border-0 ' +
              (disabled ? ' opacity-60 ' : '')
            }
            disabled={disabled}
            readOnly={readonly}
            placeholder={placeholder}
          />
        )}
        <div className="">{children}</div>
      </div>
    </div>
  );
}

export default MoneyInput;
