import React, { useState } from 'react';
import moment from 'moment';
import { notifyError, notifySuccess } from '../../Custom/notification';
import { approveAPIKey, deleteUserAccountAPI } from '../../services/Admin/users';
import { get } from 'lodash';
import { RevenuePercentageAPI } from '../../services/Auth';
import APIActivationModal from './APIActivationModal';
import RevenueModal from './RevenueModal';
import DeleteUserModal from './DeleteUserModal';

function GetUser({ index, page, user, setIsLoading, getAllUsersAPIFunction }) {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalDeleteOpen, setModalDeleteOpen] = useState(false);
  const [modalRevenueOpen, setModalRevenueOpen] = useState(false);
  const [accountPassword, setAccountPassword] = useState('');
  const [accountRevenue, setAccountRevenue] = useState(
    user.revenue_percentage ? user.revenue_percentage : 0
  );
  const [userData, setUserData] = useState({});

  const approveAPIKeyFunction = (user_id, status, accountPassword) => {
    setIsLoading(true);
    const data = { api_status: status, password: accountPassword };
    approveAPIKey(user_id, data)
      .then((res) => {
        setIsLoading(false);
        getAllUsersAPIFunction({ limit: 10 });
        setModalOpen(false);
        notifySuccess(
          `API Key permission ${
            user.api_status === 1 ? 'revoked' : 'activated'
          } successfully!`
        );
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(errorMessage);
      });
  };

  const deleteAccountFunction = (user_id, accountPassword) => {
    setIsLoading(true);
    const data = { password: accountPassword };
    deleteUserAccountAPI(user_id, data)
      .then((res) => {
        setIsLoading(false);
        getAllUsersAPIFunction({ limit: 10 });
        setModalOpen(false);
        notifySuccess(`Account deleted successfully!`);
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(errorMessage);
      });
  };

  const revenuePercentageFunction = ({ user_id, accountRevenue }) => {
    setIsLoading(true);
    const payload = { revenue_percentage: Number(accountRevenue) };
    RevenuePercentageAPI({ user_id, payload })
      .then((res) => {
        setIsLoading(false);
        getAllUsersAPIFunction({ limit: 10 });
        notifySuccess('Reveenue Percentage updated successfully!');
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(errorMessage);
      });
  };

  return (
    <>
      {' '}
      <td className='pl-4'>{index + 1 + (page - 1) * 10}</td>
      <td>
        <div key={index} className='d-flex align-items-center'>
          {user.email}
        </div>
      </td>
      <td className='pr-4'>
        {user?.widget_user_email && user.widget_user_id ? user?.widget_user_email : '-'}
      </td>
      <td>
        {' '}
        <span className='badge badge-pill badge-secondary'>
          {user.account_type === 1
            ? 'On Ramp'
            : user.account_type === 2
            ? 'Business'
            : user.account_type === 3
            ? 'User'
            : user.account_type === 4
            ? 'Exchange'
            : user.account_type === 5
            ? 'Reviewer'
            : ''}
        </span>
      </td>
      <td className='pr-4'>{user.company_name ? user.company_name : '-'}</td>
      <td className='pr-4'>{user.on_ramp_name ? user.on_ramp_name : '-'}</td>
      <td className='pr-4'>
        {user.status === 0 ? (
          <span className='badge badge-pill badge-warning'>Unverified</span>
        ) : user.status === 1 ? (
          <span className='badge badge-pill badge-success'>Active</span>
        ) : user.status === 2 ? (
          <span className='badge badge-pill badge-danger'>Inactive</span>
        ) : (
          <span className='badge badge-pill badge-danger'>Deleted</span>
        )}
      </td>
      <td className='pr-4'>
        {user.api_status === 1 && user.api_key ? user.api_key : '-'}
      </td>
      <td className='pr-0' style={{ wordBreak: 'break-word', minWidth: '200px' }}>
        {user.api_status === 1 && user.secret_key ? user.secret_key : '-'}
      </td>
      <td className='pr-4' style={{ minWidth: 120 }}>
        {user.account_type === 2 ? (
          <>
            {' '}
            ${user.revenue_percentage ? user.revenue_percentage : 0}%{' '}
            <i
              className='mdi mdi-pencil font-size-16 cursor-pointer'
              onClick={() => {
                setModalRevenueOpen(true);
                setUserData(user);
              }}></i>
          </>
        ) : (
          '-'
        )}
      </td>
      <td className='pr-4'>
        {user.created_at ? moment(user.created_at).format('DD MMM YY') : '-'}
      </td>
      <td className=''>
        {user.api_status === 0 ? (
          <span className='badge badge-pill badge-danger'>No</span>
        ) : user.api_status === 1 ? (
          <span className='badge badge-pill badge-success'>Yes</span>
        ) : user.api_status === 2 ? (
          <span className='badge badge-pill badge-secondary'>Requested</span>
        ) : (
          '-'
        )}
      </td>
      <td className='pr-4'>
        {user.account_type === 2 && (user.api_status === 2 || user.api_status === 0) ? (
          <button
            className='btn btn-continue font-weight-bold font-size-12'
            onClick={() => {
              setModalOpen(true);
              setUserData(user);
            }}>
            Approve It
          </button>
        ) : user.account_type === 2 &&
          user.api_status === 1 &&
          user.api_key &&
          user.secret_key ? (
          <button
            className='btn btn-continue font-weight-bold'
            onClick={() => {
              setModalOpen(true);
              setUserData(user);
            }}>
            Revoke
          </button>
        ) : (
          '-'
        )}
      </td>
      <td className='pl-4'>
        {' '}
        <i
          className='font-size-20 fas fa-trash-alt cursor-pointer'
          style={{ color: 'red' }}
          onClick={() => {
            setModalDeleteOpen(true);
            setUserData(user);
          }}></i>
      </td>
      {modalOpen && userData && Object.keys(userData).length > 0 && (
        <APIActivationModal
          approveAPIKeyFunction={approveAPIKeyFunction}
          modalOpen={modalOpen}
          setModalOpen={setModalOpen}
          setAccountPassword={setAccountPassword}
          userData={userData}
          accountPassword={accountPassword}
        />
      )}
      {modalDeleteOpen && userData && Object.keys(userData).length > 0 && (
        <DeleteUserModal
          deleteAccountFunction={deleteAccountFunction}
          modalDeleteOpen={modalDeleteOpen}
          setModalDeleteOpen={setModalDeleteOpen}
          setAccountPassword={setAccountPassword}
          userData={userData}
          accountPassword={accountPassword}
        />
      )}
      {modalRevenueOpen && userData && Object.keys(userData).length > 0 && (
        <RevenueModal
          userData={userData}
          modalRevenueOpen={modalRevenueOpen}
          setModalRevenueOpen={setModalRevenueOpen}
          setAccountRevenue={setAccountRevenue}
          accountRevenue={accountRevenue}
          revenuePercentageFunction={revenuePercentageFunction}
        />
      )}
    </>
  );
}

export default GetUser;
