import api from '../../api/userApi';
import * as userURL from '../../api/userURL';
import bApi from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const GenerateAPIKey = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(userURL.API_KEY)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const DeleteAPIKey = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(userURL.API_KEY)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GenerateRefreshedAccessToken = (data, headers) => {
  return new Promise(async (resolve, reject) => {
    return bApi
      .post(businessURL.REFRESHED_ACCESS_TOKEN, data, headers)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetExpiredDateAPI = (parameter) => {
  return new Promise(async (resolve, reject) => {
    return bApi
      .get(businessURL.REFRESHED_ACCESS_TOKEN + parameter)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetStreamUserDataAPI = (applicationId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(userURL.GET_BUSINESS_STREAM_USER + `?application_id=${applicationId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetStreamTokenAPI = (applicationId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        userURL.GET_BUSINESS_STREAM_TOKEN + `?application_id=${applicationId}`
      )
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
