import React, { useEffect, useState } from 'react';
import {
  adminOrderListAPI,
  businessOrderListAPI,
  orderSuggestionAPI
} from '../../services/Admin/orders';
import moment from 'moment';
import { GetMyProfileAPI } from '../../services/Auth';
import TableSortButton from '../../components/TableSortButton';
import get from 'lodash/get';
import includes from 'lodash/includes';
import { Paginator } from 'primereact/paginator';
import { notifyError } from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import ReactTags from 'react-tag-autocomplete';
import csvDownload from 'json-to-csv-export';

function Orders() {
  const [ordersList, setOrdersList] = useState(null);
  const [accountType, setAccountType] = useState(0);
  const [first, setFirst] = useState(0);
  const [order, setOrder] = useState(null);
  const [tags, setTags] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(1);
  const [allSuggestions, setAllSuggestions] = useState([]);
  const [csvDownloadData, setCsvDownloadData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
  };

  const adminOrderListFunction = (paramsData) => {
    setIsLoading(true);
    const tagsList = tags.map(({ id }) => id).join(',');
    adminOrderListAPI({ order, page, ...paramsData, tags: tagsList })
      .then((res) => {
        const data = get(res, 'data.data');
        const newData = {
          ...data,
          page,
          order
        };

        if (!paramsData.csv) {
          setIsLoading(false);
          setTotalCount(newData.count);
          setOrdersList(newData);
        } else {
          const csvData = newData.data.reduce(
            (accumulator, currentValue) => [
              ...accumulator,
              {
                Provider: currentValue.providerData.provider_name
                  ? currentValue.providerData.provider_name
                  : '-',
                'Busineess Email': currentValue.email ? currentValue.email : '-',
                'Comapny Name': currentValue.company_name
                  ? currentValue.company_name
                  : '-',
                'Fiat Amount': currentValue.fiatAmount ? currentValue.fiatAmount : '-',
                'Fiat Currency': currentValue.fiatCurrency
                  ? currentValue.fiatCurrency.toUpperCase()
                  : '-',
                'USD Amount': currentValue.usdAmount ? currentValue.usdAmount : '-',
                'Crypto Amount': currentValue.cryptoAmount
                  ? currentValue.cryptoAmount
                  : '-',
                'Crypto Currency': currentValue.cryptoCurrency
                  ? currentValue.cryptoCurrency.toUpperCase()
                  : '-',
                Status: currentValue.status ? currentValue.status.toUpperCase() : '-',
                'Order Id': currentValue.order_id ? currentValue.order_id : '-',
                'Wallet Address': currentValue.walletAddress
                  ? currentValue.walletAddress
                  : '-',
                'Ordered At': currentValue.created_at
                  ? moment(currentValue.created_at).format('DD MMMM YYYY')
                  : '-'
              }
            ],
            []
          );
          setCsvDownloadData(csvData);
        }
        return newData;
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const businessOrderListFunction = (paramsData) => {
    setIsLoading(true);
    const tagsList = tags.map(({ id }) => id).join(',');
    businessOrderListAPI({ order, page, ...paramsData, tags: tagsList })
      .then((res) => {
        setIsLoading(false);
        const data = get(res, 'data.data');
        const newData = {
          ...data,
          page,
          order
        };

        if (!paramsData.csv) {
          setIsLoading(false);
          setTotalCount(newData.count);
          setOrdersList(newData);
        } else {
          const csvData = newData.data.reduce(
            (accumulator, currentValue) => [
              ...accumulator,
              {
                Provider: currentValue.providerData.provider_name
                  ? currentValue.providerData.provider_name
                  : '-',
                'Fiat Amount': currentValue.fiatAmount ? currentValue.fiatAmount : '-',
                'Fiat Currency': currentValue.fiatCurrency
                  ? currentValue.fiatCurrency.toUpperCase()
                  : '-',
                'USD Amount': currentValue.usdAmount ? currentValue.usdAmount : '-',
                'Crypto Amount': currentValue.cryptoAmount
                  ? currentValue.cryptoAmount
                  : '-',
                'Crypto Currency': currentValue.cryptoCurrency
                  ? currentValue.cryptoCurrency.toUpperCase()
                  : '-',
                Status: currentValue.status ? currentValue.status.toUpperCase() : '-',
                'Order Id': currentValue.order_id ? currentValue.order_id : '-',
                'Wallet Address': currentValue.walletAddress
                  ? currentValue.walletAddress
                  : '-',
                'Ordered At': currentValue.created_at
                  ? moment(currentValue.created_at).format('DD MMMM YYYY')
                  : '-'
              }
            ],
            []
          );
          setCsvDownloadData(csvData);
        }
        return newData;
      })
      .catch((err) => {
        setIsLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    GetMyProfileAPI()
      .then((res) => {
        const accountTypeStatus = res.data.data.data.account_type;
        if (accountTypeStatus === 0) {
          adminOrderListFunction({ limit: 10, csv: false });
          setAccountType(0);
        } else if (accountTypeStatus === 2) {
          businessOrderListFunction({ limit: 10, csv: false });
          setAccountType(2);
        }
      })
      .catch(() => {});
  }, [page, order, tags]);

  useEffect(() => {
    orderSuggestionAPI()
      .then((res) => {
        const data = get(res, 'data.data.data', []);
        let suggestionsItems = data.reduce(
          (accumulator, currentValue) => [
            ...accumulator,
            currentValue.status && currentValue.status.toUpperCase(),
            currentValue.cryptoCurrency && currentValue.cryptoCurrency.toUpperCase(),
            currentValue.fiatCurrency && currentValue.fiatCurrency.toUpperCase()
          ],
          []
        );
        suggestionsItems = [...new Set(suggestionsItems)];
        setAllSuggestions(suggestionsItems);
      })
      .catch(() => {});
  }, []);

  useEffect(() => {
    GetMyProfileAPI()
      .then((res) => {
        const accountTypeStatus = res.data.data.data.account_type;
        if (accountTypeStatus === 0) {
          adminOrderListFunction({ csv: true });
          setAccountType(0);
        } else if (accountTypeStatus === 2) {
          businessOrderListFunction({ csv: true });
          setAccountType(2);
        }
      })
      .catch(() => {});
  }, [order, tags]);

  const suggestions = allSuggestions.map((item) => ({
    id: item,
    name: item
  }));

  const onDelete = (index) => {
    setTags(tags.filter((t, i) => i !== index));
  };

  const onAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const csvDownloadFunction = () => {
    csvDownload({ data: csvDownloadData, filename: `order_data.csv`, delimiter: ',' });
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='card'>
          <div className='p-4'>
            <div className='col-12 col-sm-auto font-size-24 font-weight-medium pb-2 pb-sm-0'>
              <div className='d-flex justify-content-between'>
                <span className='text-black'>All Orders</span>
                <button
                  onClick={() => csvDownloadFunction()}
                  className='btn btn-primary btn-font-text font-weight-bold'>
                  Download
                </button>
              </div>
              <div className='mb-3 mt-2'>
                <ReactTags
                  placeholderText='Select fiat, crypto, status'
                  tags={tags}
                  suggestions={suggestions}
                  onDelete={onDelete}
                  onAddition={onAddition}
                />
              </div>

              <div className='table-responsive'>
                <table className='table table-centered table-hover'>
                  <thead className='center-text'>
                    <tr className='font-size-14 text-muted'>
                      <th className='pl-4 pt-3 pb-4 border-0'>#</th>
                      <th className='pt-3 pb-4 border-0'>Logo</th>
                      <th className='pt-3 pb-4 border-0'>
                        <div className='d-flex align-items-center'>
                          <span>Provider</span>
                          <TableSortButton
                            className='ml-1'
                            value={order}
                            topValue='provider_name_desc'
                            bottomValue='provider_name_asc'
                            onChange={(v) => {
                              setPage(1);
                              setOrder(v);
                            }}
                          />
                        </div>
                      </th>
                      {accountType === 0 && (
                        <th className='pt-3 pb-4 border-0'>Business Email</th>
                      )}
                      <th className='pt-3 pb-4 border-0'>User Email</th>
                      {accountType === 0 && (
                        <th className='pt-3 pb-4 border-0'>
                          <div className='d-flex align-items-center'>Company Name</div>
                        </th>
                      )}
                      <th className='pt-3 pb-4 border-0'>
                        <span>Order Type</span>
                      </th>
                      <th className='pt-3 pb-4 border-0'>
                        {' '}
                        <div className='d-flex align-items-center'>
                          <span>Fiat Amount</span>
                          <TableSortButton
                            className='ml-1'
                            value={order}
                            topValue='fiat_amount_desc'
                            bottomValue='fiat_amount_asc'
                            onChange={(v) => {
                              setPage(1);
                              setOrder(v);
                            }}
                          />
                        </div>
                      </th>

                      <th className='pt-3 pb-4 border-0'>
                        <span>Fiat Currency</span>
                      </th>
                      <th className='pt-3 pb-4 border-0'>
                        <span>USD Amount</span>
                      </th>
                      <th className='pt-3 pb-4 border-0'>
                        <span>From Amount</span>
                      </th>
                      <th className='pt-3 pb-4 border-0'>
                        <span>From Crypto</span>
                      </th>
                      <th className='pt-3 pb-4 border-0'>
                        <div className='d-flex align-items-center'>
                          <span>Crypto Amount</span>
                          <TableSortButton
                            className='ml-1'
                            value={order}
                            topValue='crypto_amount_desc'
                            bottomValue='crypto_amount_asc'
                            onChange={(v) => {
                              setPage(1);
                              setOrder(v);
                            }}
                          />
                        </div>
                      </th>
                      <th className='pt-3 pb-4 border-0'>Crypto</th>
                      <th className='pr-4 pt-3 pb-4 border-0'>Status</th>
                      <th className='pr-4 pt-3 pb-4 border-0'>Order ID</th>
                      <th className='pr-4 pt-3 pb-4 border-0'>Wallet Address</th>
                      <th className='pr-4 pt-3 pb-4 border-0'>
                        <div className='d-flex align-items-center'>
                          <span>Ordered</span>
                          <TableSortButton
                            className='ml-1'
                            value={order}
                            topValue='created_at_desc'
                            bottomValue='created_at_asc'
                            onChange={(v) => {
                              setPage(1);
                              setOrder(v);
                            }}
                          />
                        </div>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {isLoading ? (
                      <tr>
                        <td colSpan={14}>
                          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                            <i
                              className='fas fa-circle-notch fa-spin text-muted'
                              style={{ fontSize: 48 }}></i>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {get(ordersList, 'data', []) &&
                        get(ordersList, 'data', []).length > 0 ? (
                          get(ordersList, 'data', []).map((orderItem, key) => (
                            <tr
                              key={key}
                              className='center-text font-size-12 font-weight-medium'
                              onClick={() => {}}>
                              {console.log(orderItem)}
                              <td className='pl-4 '>{key + 1 + (page - 1) * 10}</td>
                              <td>
                                <div key={key} className='d-flex align-items-center'>
                                  <div className='' style={{ minWidth: '43px' }}>
                                    <img
                                      src={orderItem.providerData.logo}
                                      width={100}
                                      alt={orderItem.providerData.logo}
                                    />
                                  </div>
                                </div>
                              </td>
                              <td className=''>{orderItem.providerData.provider_name}</td>
                              {accountType === 0 && (
                                <td className=''>
                                  {orderItem.email ? orderItem.email : '-'}
                                </td>
                              )}
                              <td className=''>
                                {orderItem.purchased_by_email
                                  ? orderItem.purchased_by_email
                                  : '-'}
                              </td>
                              {accountType === 0 && (
                                <td className=''>
                                  {orderItem.company_name ? orderItem.company_name : '-'}
                                </td>
                              )}
                              <td className=''>{orderItem.isBuyOrSell}</td>
                              <td className='pr-4 '>
                                {orderItem.fiatAmount ? <>{orderItem.fiatAmount}</> : '-'}
                              </td>

                              <td className='pr-4'>
                                {orderItem.fiatCurrency ? (
                                  <>{orderItem.fiatCurrency.toUpperCase()}</>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td className='pr-4'>
                                {orderItem.usdAmount ? (
                                  <>${orderItem.usdAmount.toFixed(2)}</>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td className='pr-4'>
                                {orderItem.fromCryptoAmount ? (
                                  <>{Number(orderItem.fromCryptoAmount).toFixed(6)}</>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td className='pr-4'>
                                {orderItem.fromCryptoCurrency ? (
                                  <>{orderItem.fromCryptoCurrency}</>
                                ) : (
                                  '-'
                                )}
                                {orderItem.provider === 'swing' &&
                                  `(${orderItem.fromNetwork.toUpperCase()})`}
                              </td>
                              <td className='pr-4'>
                                {orderItem.cryptoAmount ? (
                                  <>{Number(orderItem.cryptoAmount).toFixed(6)}</>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td className='pr-4'>
                                {orderItem.cryptoCurrency ? (
                                  <>{orderItem.cryptoCurrency.toUpperCase()}</>
                                ) : (
                                  '-'
                                )}
                                {orderItem.provider === 'swing' &&
                                  `(${orderItem.network.toUpperCase()})`}
                              </td>
                              <td className=''>
                                {orderItem.status ? (
                                  includes(orderItem.status.toUpperCase(), 'FAIL') ||
                                  includes(
                                    orderItem.status.toUpperCase(),
                                    'CANCELLED'
                                  ) ? (
                                    <span className='font-size-12 badge badge-pill badge-danger'>
                                      {orderItem.status.toUpperCase()}
                                    </span>
                                  ) : includes(
                                      orderItem.status.toUpperCase(),
                                      'COMPLETE'
                                    ) ||
                                    includes(orderItem.status.toUpperCase(), 'CONFIRM') ||
                                    includes(
                                      orderItem.status.toUpperCase(),
                                      'RELEASED'
                                    ) ? (
                                    <span className='font-size-12 badge badge-pill badge-success'>
                                      {orderItem.status.toUpperCase()}
                                    </span>
                                  ) : (
                                    <span className='font-size-12 badge badge-pill badge-secondary'>
                                      {orderItem.status.toUpperCase()}
                                    </span>
                                  )
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td className=''>
                                {orderItem.order_id ? orderItem.order_id : '-'}
                              </td>
                              <td className='pr-4'>
                                {orderItem.walletAddress ? (
                                  <>{orderItem.walletAddress}</>
                                ) : (
                                  '-'
                                )}
                              </td>
                              <td className='pr-4'>
                                {orderItem.created_at ? (
                                  <>
                                    {moment(orderItem.created_at).format('DD MMMM YYYY')}
                                  </>
                                ) : (
                                  '-'
                                )}
                              </td>
                            </tr>
                          ))
                        ) : (
                          <tr>
                            <td colSpan={15} className='center-text'>
                              No orders found
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <Paginator
              rows={10}
              totalRecords={totalCount}
              first={first}
              onPageChange={(e) => onPageChange(e)}
            />
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default Orders;
