import React, { useEffect, useState } from 'react';
import { Col, Nav, NavItem, Row, NavLink, Card, TabContent, TabPane } from 'reactstrap';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';
import { useHistory } from 'react-router-dom';
import ViewOnRampApplication from './ViewOnRampApplication';
import ChangeRequests from './ChangeRequests';
import { NotificationToastContainer, notifyError } from '../../../Custom/notification';
import ResponseList from './ResponsePage';
import ResponseFilters from './ResponseFilters';
import TokenAnalytics from './TokenAnalytics';
import {
  GetApplication,
  GetApplicationResponse,
  getCoinGeckoTerminalNetworks,
  getCoinGeckoTerminalPools,
  getCoinGeckoTerminalToken,
  GetGoPlusChainsList,
  getTeamAnalytics,
  getTokenAnalytics,
  getTokenMarketData,
  getTwitterScore
} from '../../../services/Onramp/Application';
import { get } from 'lodash';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import fuzzysort from 'fuzzysort';

import './PieChart.css';
import AppChat from './AppChat';

const mockedTeamAnalytics = [
  {
    id: 'tkTqrNccTWJonc8wpoOPcQ_00000',
    full_name: 'gavin wood',
    first_name: 'gavin',
    middle_initial: null,
    middle_name: null,
    last_initial: 'w',
    last_name: 'wood',
    gender: 'male',
    birth_year: null,
    birth_date: null,
    linkedin_url: 'linkedin.com/in/gavin-wood-88843316',
    linkedin_username: 'gavin-wood-88843316',
    linkedin_id: '54811688',
    facebook_url: null,
    facebook_username: null,
    facebook_id: null,
    twitter_url: null,
    twitter_username: null,
    github_url: null,
    github_username: null,
    work_email: 'gavin@parity.io',
    personal_emails: [],
    recommended_personal_email: null,
    mobile_phone: null,
    industry: 'entertainment',
    job_title: 'founder and lead developer',
    job_title_role: 'engineering',
    job_title_sub_role: null,
    job_title_levels: ['manager', 'owner'],
    job_company_id: 'paritytech',
    job_company_name: 'parity technologies',
    job_company_website: 'paritytech.io',
    job_company_size: '11-50',
    job_company_founded: 2015,
    job_company_industry: 'information technology and services',
    job_company_linkedin_url: 'linkedin.com/company/paritytech',
    job_company_linkedin_id: '10211733',
    job_company_facebook_url: 'facebook.com/paritytech',
    job_company_twitter_url: 'twitter.com/paritytech',
    job_company_location_name: 'london, london, united kingdom',
    job_company_location_locality: 'london',
    job_company_location_metro: null,
    job_company_location_region: 'london',
    job_company_location_geo: '51.51,-.09',
    job_company_location_street_address: null,
    job_company_location_address_line_2: null,
    job_company_location_postal_code: null,
    job_company_location_country: 'united kingdom',
    job_company_location_continent: 'europe',
    job_last_updated: '2022-12-13',
    job_start_date: '2015-10',
    location_name: 'london, greater london, united kingdom',
    location_locality: 'london',
    location_metro: null,
    location_region: 'greater london',
    location_country: 'united kingdom',
    location_continent: 'europe',
    location_street_address: null,
    location_address_line_2: null,
    location_postal_code: null,
    location_geo: '51.50,-0.12',
    location_last_updated: '2021-08-01',
    phone_numbers: [],
    emails: [
      {
        address: 'gavin@quidprocode.co.uk',
        type: 'professional'
      },
      {
        address: 'gavin@parity.io',
        type: 'current_professional'
      }
    ],
    interests: [
      'political science',
      'taekwon do',
      'snowboarding',
      'hci',
      'linguistics',
      'photography',
      'philosophy'
    ],
    skills: [
      'c++',
      'entrepreneurship',
      'c#',
      'public speaking',
      'start ups',
      'linux',
      'business strategy',
      'research',
      'javascript',
      'css',
      'teaching',
      'c',
      'project management',
      'sql',
      'php',
      'r',
      'git',
      'jquery',
      'photoshop',
      'boost',
      'qt',
      'windows',
      'mac os x',
      'opengl',
      'network programming',
      'audio programming',
      'creative solutions',
      'business administration',
      'perl',
      'html5',
      'css3',
      'realtime programming',
      'embedded systems',
      'ui design',
      'apache',
      'svn',
      'sdl',
      'ps3',
      'xbox 360',
      'api design',
      'inkscape'
    ],
    location_names: [
      'london, england, united kingdom',
      'london, greater london, united kingdom',
      'san francisco, california, united states',
      'london, london, united kingdom',
      'scottsdale, arizona, united states',
      'charlotte, north carolina, united states'
    ],
    regions: [
      'california, united states',
      'arizona, united states',
      'greater london, united kingdom',
      'london, united kingdom',
      'north carolina, united states',
      'england, united kingdom'
    ],
    countries: ['united states', 'switzerland', 'united kingdom'],
    street_addresses: [],
    experience: [
      {
        company: {
          name: 'ethereum foundation',
          size: '201-500',
          id: 'ethereum-foundation',
          founded: null,
          industry: 'computer software',
          location: null,
          linkedin_url: 'linkedin.com/company/ethereum-foundation',
          linkedin_id: '40769465',
          facebook_url: null,
          twitter_url: null,
          website: null
        },
        start_date: '2013-12',
        end_date: '2015-12',
        title: {
          name: 'co-founder and chief technology officer',
          role: 'engineering',
          sub_role: null,
          levels: ['owner', 'cxo']
        },
        location_names: [],
        is_primary: false
      },
      {
        company: {
          name: 'lancaster logic response',
          size: null,
          id: null,
          founded: null,
          industry: null,
          location: null,
          linkedin_url: null,
          linkedin_id: null,
          facebook_url: null,
          twitter_url: null,
          website: null
        },
        start_date: '2011-12',
        end_date: '2013-09',
        title: {
          name: 'technical director',
          role: null,
          sub_role: null,
          levels: ['director']
        },
        location_names: [],
        is_primary: false
      },
      {
        company: {
          name: 'microsoft research cambridge',
          size: '1-10',
          id: 'microsoft-research-cambridge',
          founded: null,
          industry: 'information technology and services',
          location: {
            name: 'cambridge, cambridgeshire, united kingdom',
            locality: 'cambridge',
            region: 'cambridgeshire',
            metro: null,
            country: 'united kingdom',
            continent: 'europe',
            street_address: '21 station road',
            address_line_2: null,
            postal_code: null,
            geo: '52.2,.11'
          },
          linkedin_url: 'linkedin.com/company/microsoft-research-cambridge',
          linkedin_id: '70597399',
          facebook_url: null,
          twitter_url: null,
          website: null
        },
        start_date: '2007-08',
        end_date: '2011-11',
        title: {
          name: 'research and softwareconsultant',
          role: 'engineering',
          sub_role: 'software',
          levels: []
        },
        location_names: [],
        is_primary: false
      },
      {
        company: {
          name: 'oxlegal',
          size: null,
          id: null,
          founded: null,
          industry: null,
          location: null,
          linkedin_url: null,
          linkedin_id: null,
          facebook_url: null,
          twitter_url: null,
          website: null
        },
        location_names: [],
        end_date: '2014-01',
        start_date: '2013-07',
        title: {
          name: 'directory of technology',
          role: null,
          sub_role: null,
          levels: []
        },
        is_primary: false
      },
      {
        company: {
          name: 'parity technologies',
          size: '11-50',
          id: 'paritytech',
          founded: 2015,
          industry: 'information technology and services',
          location: {
            name: 'london, london, united kingdom',
            locality: 'london',
            region: 'london',
            metro: null,
            country: 'united kingdom',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: '51.51,-.09'
          },
          linkedin_url: 'linkedin.com/company/paritytech',
          linkedin_id: '10211733',
          facebook_url: 'facebook.com/paritytech',
          twitter_url: 'twitter.com/paritytech',
          website: 'paritytech.io'
        },
        location_names: ['london, greater london, united kingdom'],
        end_date: null,
        start_date: '2015-10',
        title: {
          name: 'founder and lead developer',
          role: 'engineering',
          sub_role: null,
          levels: ['manager', 'owner']
        },
        is_primary: true
      },
      {
        company: {
          name: 'frontier developments',
          size: '201-500',
          id: 'frontier-developments',
          founded: 1994,
          industry: 'computer games',
          location: {
            name: 'cambridge, cambridgeshire, united kingdom',
            locality: 'cambridge',
            region: 'cambridgeshire',
            metro: null,
            country: 'united kingdom',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: '52.2,.11'
          },
          linkedin_url: 'linkedin.com/company/frontier-developments',
          linkedin_id: '501010',
          facebook_url: 'facebook.com/frontierdevelopments',
          twitter_url: 'twitter.com/frontierdev',
          website: 'frontier.co.uk'
        },
        location_names: ['cambridge, cambridgeshire, united kingdom'],
        end_date: '2007-02',
        start_date: '2005-12',
        title: {
          name: 'engine sublead programmer',
          role: 'engineering',
          sub_role: null,
          levels: []
        },
        is_primary: false
      },
      {
        company: {
          name: 'grid singularity',
          size: '11-50',
          id: 'grid-singularity',
          founded: 2016,
          industry: 'information technology and services',
          location: {
            name: 'berlin, germany',
            locality: null,
            region: 'berlin',
            metro: null,
            country: 'germany',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: null
          },
          linkedin_url: 'linkedin.com/company/grid-singularity',
          linkedin_id: '10456413',
          facebook_url: null,
          twitter_url: null,
          website: 'gridsingularity.com'
        },
        location_names: [],
        end_date: null,
        start_date: null,
        title: {
          name: 'co founder',
          role: null,
          sub_role: null,
          levels: ['owner']
        },
        is_primary: false
      },
      {
        company: {
          name: 'intechnology plc',
          size: '51-200',
          id: 'intechnology-plc',
          founded: 1983,
          industry: 'information technology and services',
          location: {
            name: 'harrogate, north yorkshire, united kingdom',
            locality: 'harrogate',
            region: 'north yorkshire',
            metro: null,
            country: 'united kingdom',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: '53.98,-1.53'
          },
          linkedin_url: 'linkedin.com/company/intechnology-plc',
          linkedin_id: '8679',
          facebook_url: null,
          twitter_url: 'twitter.com/intechnology',
          website: 'intechnologyplc.com'
        },
        location_names: [],
        end_date: '2001',
        start_date: '2000',
        title: {
          name: 'programmer',
          role: 'engineering',
          sub_role: null,
          levels: []
        },
        is_primary: false
      },
      {
        company: {
          name: 'web3 foundation',
          size: '51-200',
          id: 'web3foundation',
          founded: 2017,
          industry: 'information technology and services',
          location: {
            name: 'zug, zug, switzerland',
            locality: 'zug',
            region: 'zug',
            metro: null,
            country: 'switzerland',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: '47.17,8.51'
          },
          linkedin_url: 'linkedin.com/company/web3foundation',
          linkedin_id: '18162043',
          facebook_url: 'facebook.com/web3foundation',
          twitter_url: 'twitter.com/web3foundation',
          website: 'web3.foundation'
        },
        location_names: [],
        end_date: null,
        start_date: '2017-06',
        title: {
          name: 'president and founder',
          role: null,
          sub_role: null,
          levels: ['owner', 'cxo']
        },
        is_primary: false
      }
    ],
    education: [
      {
        school: {
          name: 'lancaster royal grammar school',
          type: 'secondary school',
          id: null,
          location: null,
          linkedin_url: null,
          facebook_url: null,
          twitter_url: null,
          linkedin_id: null,
          website: null,
          domain: null
        },
        degrees: [],
        start_date: '1991',
        end_date: '1998',
        majors: [],
        minors: [],
        gpa: null
      },
      {
        school: {
          name: 'university of york',
          type: 'post-secondary institution',
          id: null,
          location: null,
          linkedin_url: null,
          facebook_url: null,
          twitter_url: null,
          linkedin_id: null,
          website: null,
          domain: null
        },
        end_date: '2002',
        start_date: '1998',
        gpa: null,
        degrees: ['masters', 'master of engineering'],
        majors: ['engineering', 'software engineering', 'computer systems'],
        minors: []
      },
      {
        school: {
          name: 'university of york',
          type: 'post-secondary institution',
          id: '0B7N7BVsRPZ9Fllohqs46A_0',
          location: {
            name: 'united kingdom',
            locality: null,
            region: null,
            country: 'united kingdom',
            continent: 'europe'
          },
          linkedin_url: 'linkedin.com/school/uniofyork',
          facebook_url: 'facebook.com/universityofyork',
          twitter_url: 'twitter.com/uniofyork',
          linkedin_id: '12736',
          website: 'york.ac.uk',
          domain: 'york.ac.uk'
        },
        end_date: '2005',
        start_date: '2002',
        gpa: null,
        degrees: ['doctorates', 'doctor of philosophy'],
        majors: ['music', 'philosophy'],
        minors: []
      }
    ],
    profiles: [
      {
        network: 'linkedin',
        id: '54811688',
        url: 'linkedin.com/in/gavin-wood-88843316',
        username: 'gavin-wood-88843316'
      },
      {
        network: 'linkedin',
        id: null,
        url: 'ch.linkedin.com/in/gavin-wood-88843316',
        username: 'gavin-wood-88843316'
      }
    ],
    version_status: {
      status: '',
      contains: [],
      previous_version: '',
      current_version: ''
    }
  },
  {
    id: 'tkTqrNccTWJonc8wpoOPcQ_0000',
    full_name: 'gavin wood',
    first_name: 'gavin',
    middle_initial: null,
    middle_name: null,
    last_initial: 'w',
    last_name: 'wood',
    gender: 'male',
    birth_year: null,
    birth_date: null,
    linkedin_url: 'linkedin.com/in/gavin-wood-88843316',
    linkedin_username: 'gavin-wood-88843316',
    linkedin_id: '54811688',
    facebook_url: null,
    facebook_username: null,
    facebook_id: null,
    twitter_url: null,
    twitter_username: null,
    github_url: null,
    github_username: null,
    work_email: 'gavin@parity.io',
    personal_emails: [],
    recommended_personal_email: null,
    mobile_phone: null,
    industry: 'entertainment',
    job_title: 'founder and lead developer',
    job_title_role: 'engineering',
    job_title_sub_role: null,
    job_title_levels: ['manager', 'owner'],
    job_company_id: 'paritytech',
    job_company_name: 'parity technologies',
    job_company_website: 'paritytech.io',
    job_company_size: '11-50',
    job_company_founded: 2015,
    job_company_industry: 'information technology and services',
    job_company_linkedin_url: 'linkedin.com/company/paritytech',
    job_company_linkedin_id: '10211733',
    job_company_facebook_url: 'facebook.com/paritytech',
    job_company_twitter_url: 'twitter.com/paritytech',
    job_company_location_name: 'london, london, united kingdom',
    job_company_location_locality: 'london',
    job_company_location_metro: null,
    job_company_location_region: 'london',
    job_company_location_geo: '51.51,-.09',
    job_company_location_street_address: null,
    job_company_location_address_line_2: null,
    job_company_location_postal_code: null,
    job_company_location_country: 'united kingdom',
    job_company_location_continent: 'europe',
    job_last_updated: '2022-12-13',
    job_start_date: '2015-10',
    location_name: 'london, greater london, united kingdom',
    location_locality: 'london',
    location_metro: null,
    location_region: 'greater london',
    location_country: 'united kingdom',
    location_continent: 'europe',
    location_street_address: null,
    location_address_line_2: null,
    location_postal_code: null,
    location_geo: '51.50,-0.12',
    location_last_updated: '2021-08-01',
    phone_numbers: [],
    emails: [
      {
        address: 'gavin@quidprocode.co.uk',
        type: 'professional'
      },
      {
        address: 'gavin@parity.io',
        type: 'current_professional'
      }
    ],
    interests: [
      'political science',
      'taekwon do',
      'snowboarding',
      'hci',
      'linguistics',
      'photography',
      'philosophy'
    ],
    skills: [
      'c++',
      'entrepreneurship',
      'c#',
      'public speaking',
      'start ups',
      'linux',
      'business strategy',
      'research',
      'javascript',
      'css',
      'teaching',
      'c',
      'project management',
      'sql',
      'php',
      'r',
      'git',
      'jquery',
      'photoshop',
      'boost',
      'qt',
      'windows',
      'mac os x',
      'opengl',
      'network programming',
      'audio programming',
      'creative solutions',
      'business administration',
      'perl',
      'html5',
      'css3',
      'realtime programming',
      'embedded systems',
      'ui design',
      'apache',
      'svn',
      'sdl',
      'ps3',
      'xbox 360',
      'api design',
      'inkscape'
    ],
    location_names: [
      'london, england, united kingdom',
      'london, greater london, united kingdom',
      'san francisco, california, united states',
      'london, london, united kingdom',
      'scottsdale, arizona, united states',
      'charlotte, north carolina, united states'
    ],
    regions: [
      'california, united states',
      'arizona, united states',
      'greater london, united kingdom',
      'london, united kingdom',
      'north carolina, united states',
      'england, united kingdom'
    ],
    countries: ['united states', 'switzerland', 'united kingdom'],
    street_addresses: [],
    experience: [
      {
        company: {
          name: 'ethereum foundation',
          size: '201-500',
          id: 'ethereum-foundation',
          founded: null,
          industry: 'computer software',
          location: null,
          linkedin_url: 'linkedin.com/company/ethereum-foundation',
          linkedin_id: '40769465',
          facebook_url: null,
          twitter_url: null,
          website: null
        },
        start_date: '2013-12',
        end_date: '2015-12',
        title: {
          name: 'co-founder and chief technology officer',
          role: 'engineering',
          sub_role: null,
          levels: ['owner', 'cxo']
        },
        location_names: [],
        is_primary: false
      },
      {
        company: {
          name: 'lancaster logic response',
          size: null,
          id: null,
          founded: null,
          industry: null,
          location: null,
          linkedin_url: null,
          linkedin_id: null,
          facebook_url: null,
          twitter_url: null,
          website: null
        },
        start_date: '2011-12',
        end_date: '2013-09',
        title: {
          name: 'technical director',
          role: null,
          sub_role: null,
          levels: ['director']
        },
        location_names: [],
        is_primary: false
      },
      {
        company: {
          name: 'microsoft research cambridge',
          size: '1-10',
          id: 'microsoft-research-cambridge',
          founded: null,
          industry: 'information technology and services',
          location: {
            name: 'cambridge, cambridgeshire, united kingdom',
            locality: 'cambridge',
            region: 'cambridgeshire',
            metro: null,
            country: 'united kingdom',
            continent: 'europe',
            street_address: '21 station road',
            address_line_2: null,
            postal_code: null,
            geo: '52.2,.11'
          },
          linkedin_url: 'linkedin.com/company/microsoft-research-cambridge',
          linkedin_id: '70597399',
          facebook_url: null,
          twitter_url: null,
          website: null
        },
        start_date: '2007-08',
        end_date: '2011-11',
        title: {
          name: 'research and softwareconsultant',
          role: 'engineering',
          sub_role: 'software',
          levels: []
        },
        location_names: [],
        is_primary: false
      },
      {
        company: {
          name: 'oxlegal',
          size: null,
          id: null,
          founded: null,
          industry: null,
          location: null,
          linkedin_url: null,
          linkedin_id: null,
          facebook_url: null,
          twitter_url: null,
          website: null
        },
        location_names: [],
        end_date: '2014-01',
        start_date: '2013-07',
        title: {
          name: 'directory of technology',
          role: null,
          sub_role: null,
          levels: []
        },
        is_primary: false
      },
      {
        company: {
          name: 'parity technologies',
          size: '11-50',
          id: 'paritytech',
          founded: 2015,
          industry: 'information technology and services',
          location: {
            name: 'london, london, united kingdom',
            locality: 'london',
            region: 'london',
            metro: null,
            country: 'united kingdom',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: '51.51,-.09'
          },
          linkedin_url: 'linkedin.com/company/paritytech',
          linkedin_id: '10211733',
          facebook_url: 'facebook.com/paritytech',
          twitter_url: 'twitter.com/paritytech',
          website: 'paritytech.io'
        },
        location_names: ['london, greater london, united kingdom'],
        end_date: null,
        start_date: '2015-10',
        title: {
          name: 'founder and lead developer',
          role: 'engineering',
          sub_role: null,
          levels: ['manager', 'owner']
        },
        is_primary: true
      },
      {
        company: {
          name: 'frontier developments',
          size: '201-500',
          id: 'frontier-developments',
          founded: 1994,
          industry: 'computer games',
          location: {
            name: 'cambridge, cambridgeshire, united kingdom',
            locality: 'cambridge',
            region: 'cambridgeshire',
            metro: null,
            country: 'united kingdom',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: '52.2,.11'
          },
          linkedin_url: 'linkedin.com/company/frontier-developments',
          linkedin_id: '501010',
          facebook_url: 'facebook.com/frontierdevelopments',
          twitter_url: 'twitter.com/frontierdev',
          website: 'frontier.co.uk'
        },
        location_names: ['cambridge, cambridgeshire, united kingdom'],
        end_date: '2007-02',
        start_date: '2005-12',
        title: {
          name: 'engine sublead programmer',
          role: 'engineering',
          sub_role: null,
          levels: []
        },
        is_primary: false
      },
      {
        company: {
          name: 'grid singularity',
          size: '11-50',
          id: 'grid-singularity',
          founded: 2016,
          industry: 'information technology and services',
          location: {
            name: 'berlin, germany',
            locality: null,
            region: 'berlin',
            metro: null,
            country: 'germany',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: null
          },
          linkedin_url: 'linkedin.com/company/grid-singularity',
          linkedin_id: '10456413',
          facebook_url: null,
          twitter_url: null,
          website: 'gridsingularity.com'
        },
        location_names: [],
        end_date: null,
        start_date: null,
        title: {
          name: 'co founder',
          role: null,
          sub_role: null,
          levels: ['owner']
        },
        is_primary: false
      },
      {
        company: {
          name: 'intechnology plc',
          size: '51-200',
          id: 'intechnology-plc',
          founded: 1983,
          industry: 'information technology and services',
          location: {
            name: 'harrogate, north yorkshire, united kingdom',
            locality: 'harrogate',
            region: 'north yorkshire',
            metro: null,
            country: 'united kingdom',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: '53.98,-1.53'
          },
          linkedin_url: 'linkedin.com/company/intechnology-plc',
          linkedin_id: '8679',
          facebook_url: null,
          twitter_url: 'twitter.com/intechnology',
          website: 'intechnologyplc.com'
        },
        location_names: [],
        end_date: '2001',
        start_date: '2000',
        title: {
          name: 'programmer',
          role: 'engineering',
          sub_role: null,
          levels: []
        },
        is_primary: false
      },
      {
        company: {
          name: 'web3 foundation',
          size: '51-200',
          id: 'web3foundation',
          founded: 2017,
          industry: 'information technology and services',
          location: {
            name: 'zug, zug, switzerland',
            locality: 'zug',
            region: 'zug',
            metro: null,
            country: 'switzerland',
            continent: 'europe',
            street_address: null,
            address_line_2: null,
            postal_code: null,
            geo: '47.17,8.51'
          },
          linkedin_url: 'linkedin.com/company/web3foundation',
          linkedin_id: '18162043',
          facebook_url: 'facebook.com/web3foundation',
          twitter_url: 'twitter.com/web3foundation',
          website: 'web3.foundation'
        },
        location_names: [],
        end_date: null,
        start_date: '2017-06',
        title: {
          name: 'president and founder',
          role: null,
          sub_role: null,
          levels: ['owner', 'cxo']
        },
        is_primary: false
      }
    ],
    education: [
      {
        school: {
          name: 'lancaster royal grammar school',
          type: 'secondary school',
          id: null,
          location: null,
          linkedin_url: null,
          facebook_url: null,
          twitter_url: null,
          linkedin_id: null,
          website: null,
          domain: null
        },
        degrees: [],
        start_date: '1991',
        end_date: '1998',
        majors: [],
        minors: [],
        gpa: null
      },
      {
        school: {
          name: 'university of york',
          type: 'post-secondary institution',
          id: null,
          location: null,
          linkedin_url: null,
          facebook_url: null,
          twitter_url: null,
          linkedin_id: null,
          website: null,
          domain: null
        },
        end_date: '2002',
        start_date: '1998',
        gpa: null,
        degrees: ['masters', 'master of engineering'],
        majors: ['engineering', 'software engineering', 'computer systems'],
        minors: []
      },
      {
        school: {
          name: 'university of york',
          type: 'post-secondary institution',
          id: '0B7N7BVsRPZ9Fllohqs46A_0',
          location: {
            name: 'united kingdom',
            locality: null,
            region: null,
            country: 'united kingdom',
            continent: 'europe'
          },
          linkedin_url: 'linkedin.com/school/uniofyork',
          facebook_url: 'facebook.com/universityofyork',
          twitter_url: 'twitter.com/uniofyork',
          linkedin_id: '12736',
          website: 'york.ac.uk',
          domain: 'york.ac.uk'
        },
        end_date: '2005',
        start_date: '2002',
        gpa: null,
        degrees: ['doctorates', 'doctor of philosophy'],
        majors: ['music', 'philosophy'],
        minors: []
      }
    ],
    profiles: [
      {
        network: 'linkedin',
        id: '54811688',
        url: 'linkedin.com/in/gavin-wood-88843316',
        username: 'gavin-wood-88843316'
      },
      {
        network: 'linkedin',
        id: null,
        url: 'ch.linkedin.com/in/gavin-wood-88843316',
        username: 'gavin-wood-88843316'
      }
    ],
    version_status: {
      status: '',
      contains: [],
      previous_version: '',
      current_version: ''
    }
  }
];

function ExchangeViewApplication(props) {
  const [page, setPage] = useState('response');
  const [responsePage, setResponsePage] = useState(1);
  const history = useHistory();
  const [addQuestions, setAddQuestions] = useState([]);
  const [applicationId, setApplicationId] = useState(undefined);
  const [applicationAll, setApplicationAll] = useState(undefined);
  const [applicationDetails, setApplicationDetails] = useState(undefined);
  const [selectedStatus, setSelectedStatusValues] = useState('');
  const [statusChangeModal, setStatusChangeModal] = useState(false);
  const [modal, setModal] = useState(false);
  const [updatePage, setUpdatePage] = useState(false);
  const [textareaValues, setTextareaValues] = useState({});
  const [clickedLabels, setClickedLabels] = useState([]);
  const [beneficialusersValues, setBeneficialusersValues] = useState([]);

  const [twitterScore, setTwitterScore] = useState(undefined);
  const [twitterHandle, setTwitterHandle] = useState(undefined);
  const [tokenSecurity, setTokenSecurity] = useState(undefined);
  const [activeTab, setActiveTab] = useState(undefined);
  const [infoSecurity, setInfoSecurity] = useState(undefined);
  const [contractSecurity, setContractSecurity] = useState(undefined);
  const [tradingSecurity, setTradingSecurity] = useState(undefined);
  const [geckoPools, setGeckoPools] = useState(undefined);
  const [dexInfo, setDexInfo] = useState(undefined);
  const [geckoTokenInfo, setGeckoTokenInfo] = useState(undefined);
  const [teamAnalytics, setTeamAnalytics] = useState(undefined);
  const [loading, setLoading] = useState(true);
  const [first, setFirst] = useState(0);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [order, setOrder] = useState(null);
  const [totalCount, setTotalCount] = useState(0);
  const [allResponses, setAllResponses] = useState([]);
  const [selectedTeamMember, setSelectedTeamMember] = useState(undefined);
  const [tokenMarketData, setTokenMarketData] = useState(undefined);

  const [analyticsAvailable, setAnalyticsAvailable] = useState(false);

  const toggleTab = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const handleModal = () => {
    setModal(!modal);
  };

  const handleChangeStatus = (values) => {
    setSelectedStatusValues(values);
    setStatusChangeModal(!statusChangeModal);
    handleModal();
  };

  useEffect(() => {
    if (tokenSecurity !== undefined) {
      if (tokenSecurity.dex !== undefined) {
        setAnalyticsAvailable(true);
        if (activeTab === undefined) setActiveTab('dexInfo');
      } else if (
        tokenSecurity.holder_count !== undefined &&
        tokenSecurity.holders !== undefined
      ) {
        setAnalyticsAvailable(true);
        if (activeTab === undefined) setActiveTab('holdersInfo');
      } else if (
        tokenSecurity.lp_holders !== undefined &&
        tokenSecurity.lp_holder_count !== undefined &&
        tokenSecurity.lp_total_supply !== undefined
      ) {
        setAnalyticsAvailable(true);
        if (activeTab === undefined) setActiveTab('liquidityProvidersInfo');
      }
    }
  }, [tokenSecurity]);

  useEffect(() => {
    if (infoSecurity !== undefined) {
      setAnalyticsAvailable(true);
      if (activeTab === undefined) setActiveTab('infoSecurity');
    }
  }, [infoSecurity]);

  useEffect(() => {
    if (contractSecurity !== undefined) {
      setAnalyticsAvailable(true);
      if (activeTab === undefined) setActiveTab('contractSecurity');
    }
  }, [contractSecurity]);

  useEffect(() => {
    if (tradingSecurity !== undefined) {
      setAnalyticsAvailable(true);
      if (activeTab === undefined) setActiveTab('tradingSecurity');
    }
  }, [tradingSecurity]);

  useEffect(() => {
    if (geckoPools !== undefined) {
      setAnalyticsAvailable(true);
      if (activeTab === undefined) setActiveTab('poolsInfo');
    }
  }, [geckoPools]);

  useEffect(() => {
    if (dexInfo !== undefined) {
      setAnalyticsAvailable(true);
      if (activeTab === undefined) setActiveTab('dexInfo');
    }
  }, [dexInfo]);

  useEffect(() => {
    if (geckoTokenInfo !== undefined) {
      setAnalyticsAvailable(true);
      if (activeTab === undefined) setActiveTab('tokenInfo');
    }
  }, [geckoTokenInfo]);

  useEffect(() => {
    if (teamAnalytics !== undefined) {
      setAnalyticsAvailable(true);
      if (activeTab === undefined) {
        setActiveTab('teamAnalytics');
        setSelectedTeamMember(teamAnalytics[0]);
      }
    }
  }, [teamAnalytics]);

  useEffect(() => {
    if (tokenMarketData !== undefined) {
      setAnalyticsAvailable(true);
      if (activeTab === undefined) setActiveTab('priceChart');
    }
  }, [tokenMarketData]);

  const [statusSuggestions, setStatusSuggestions] = useState([
    {
      id: 'REQUEST_ACTION',
      name: 'Action',
      icon: 'fas fa-unlock',
      color: 'black',
      checked: false
    },
    {
      id: 'ACCEPT',
      name: 'Accept',
      icon: 'fas fa-check-circle',
      color: 'green',
      checked: false
    },
    { id: 'KYB', name: 'KYB', icon: 'fas fa-lock', color: 'black', checked: false },
    {
      id: 'QUOTE',
      name: 'Quote',
      icon: 'fas fa-quote-left',
      color: 'black',
      checked: false
    },
    {
      id: 'PAID',
      name: 'Paid',
      icon: 'fas fa-thumbs-up',
      color: 'green',
      checked: false
    },
    {
      id: 'ADD_QUESTIONS',
      name: 'Add Question',
      icon: 'fas fa-question',
      color: 'black',
      checked: false
    }
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const geckoTerminalNetworks = await getCoinGeckoTerminalNetworks(applicationId);
        const res = await GetGoPlusChainsList();
        const supportedChains = res.data.result.map((chain) => ({
          label: chain.name,
          value: chain.id
        }));

        const res2 = await GetApplication(applicationId);
        const application = get(res2, 'data.data.data[0]', '');
        setApplicationAll(application);
        const applicationData = application.exchange_application;
        setApplicationDetails(application.exchange_application);
        if (applicationData.team_members !== undefined) {
          // COMMENT BELOW TO TEST WITH REAL DATA
          // setTeamAnalytics(mockedTeamAnalytics);
          // COMMENT BELOW TO TEST WITH MOCK DATA
          try {
            const res = await getTeamAnalytics(
              applicationId,
              applicationData.team_members
            );
            if (
              res.data !== undefined &&
              res.data.data !== undefined &&
              res.data.data.length > 0
            ) {
              setTeamAnalytics(res.data.data);
            } else {
              setTeamAnalytics(undefined);
              setActiveTab('infoSecurity');
            }
          } catch (error) {
            setTeamAnalytics(undefined);
          }
        }

        const savedTokenSecurity = applicationData.token_security;
        if (savedTokenSecurity !== undefined)
          setTokenSecurity(savedTokenSecurity[applicationData.token_address]);

        const twitterHandle = applicationData.social_media.find(
          (socialMedia) => socialMedia.name === 'twitter'
        ).account_id;
        setTwitterHandle(twitterHandle);

        try {
          let cleanHandle = twitterHandle.toLowerCase();
          if (twitterHandle.startsWith('@')) {
            cleanHandle = twitterHandle.substring(1);
          }

          if (twitterHandle.startsWith('https://twitter.com/')) {
            cleanHandle = twitterHandle.substring(20);
          }

          if (twitterHandle.startsWith('twitter.com/')) {
            cleanHandle = twitterHandle.substring(12);
          }

          const twitterResult = await getTwitterScore(applicationId, {
            twitter_handle: cleanHandle
          });

          const twitterScoreResult = twitterResult.data.data.data;
          if (typeof twitterScoreResult !== 'object') {
            setTwitterScore(undefined);
          } else if (
            twitterScoreResult.followers_max === 0 &&
            twitterScoreResult.followers_min === 0 &&
            twitterScoreResult.scores_max === 0 &&
            twitterScoreResult.scores_min === 0
          ) {
            setTwitterScore(undefined);
          } else {
            setTwitterScore(twitterScoreResult);
          }
        } catch (error) {
          setTwitterScore(undefined);
        }

        const network = applicationData.blockchain_network;
        const mappedNetworks = mapNetworks(
          network,
          geckoTerminalNetworks.data.data,
          supportedChains
        );
        for (const geckoNetwork of mappedNetworks.gecko) {
          const poolsResponse = await getCoinGeckoTerminalPools(
            applicationId,
            geckoNetwork.target,
            applicationData.token_address
          );

          if (!poolsResponse.data.data.error) {
            setGeckoPools(poolsResponse.data.data.data);
            const tokenResponse = await getCoinGeckoTerminalToken(
              applicationId,
              geckoNetwork.target,
              applicationData.token_address
            );

            if (!tokenResponse.data.data.error) {
              const data = {
                coin_id: tokenResponse.data.data.data.attributes.coingecko_coin_id,
                target_currency: 'usd',
                days: 180,
                interval: 'daily',
                precision: 2
              };

              const res = await getTokenMarketData(applicationId, data);

              setTokenMarketData(res.data.data);
              setGeckoTokenInfo(tokenResponse.data.data.data);
              break;
            }
          }
        }

        const selectedChainData = supportedChains.find((chain) => {
          return (
            chain.label.toLowerCase() === network.toLowerCase() ||
            chain.label.toLowerCase().includes(network.toLowerCase())
          );
        });
        if (!applicationData.token_security) {
          const selectedChainId = selectedChainData ? selectedChainData.value : null;
          if (
            selectedChainId &&
            applicationData.token_address &&
            applicationData.token_address !== ''
          ) {
            const res = await getTokenAnalytics(applicationId, {
              chain_id: selectedChainId,
              contract_addresses: applicationData.token_address
            });

            if (!res.data.data.error) {
              const responseObject =
                res.data.data.data[applicationData.token_address?.toLowerCase()];
              setTokenSecurity(responseObject);
              populateSecurityArray(
                responseObject,
                setInfoSecurity,
                setContractSecurity,
                setTradingSecurity,
                setDexInfo
              );
            }
          }
        } else {
          populateSecurityArray(
            applicationData.token_security[applicationData.token_address?.toLowerCase()],
            setInfoSecurity,
            setContractSecurity,
            setTradingSecurity,
            setDexInfo
          );
        }
      } catch (error) {
        const errorMessage = get(error, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      }
    };

    if (applicationId !== undefined) {
      fetchData();
    }
  }, [applicationId]);

  const GetApplicationResponseFunc = (applicationId) => {
    setLoading(true);

    const status = statusSuggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);
    GetApplicationResponse({ applicationId, order, status })
      .then((res) => {
        const responses = get(res, 'data.data.data.applicationResponse', []);
        const count = get(res, 'data.data.data.count', []);
        const slicedData = get(res, 'data.data.data.applicationResponse', []).slice(
          first,
          first + 10
        );
        setCurrentPageData(slicedData);
        setTotalCount(count);
        setLoading(false);
        setAllResponses(responses);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const csvExportData = () => {
    let teamMembers = {};
    let existingExchangeList = {};
    let marketMakers = {};
    let programs = {};
    if (
      applicationDetails &&
      applicationDetails.team_members &&
      applicationDetails.team_members.length > 0
    ) {
      applicationDetails.team_members.map(
        (member, index) =>
          (teamMembers = {
            ...teamMembers,
            [`First Name ${index}`]: member.first_name,
            [`Last Name ${index}`]: member.last_name,
            [`Email ${index}`]: member.email,
            [`Linkedin ${index}`]: member.linkedin,
            [`Position ${index}`]: member.position,
            [`Start Date ${index}`]:
              member.previous_experience &&
              member.previous_experience.length > 0 &&
              member.previous_experience[0].start_date
                ? member.previous_experience[0].start_date
                : '',
            [`End Date ${index}`]:
              member.previous_experience &&
              member.previous_experience.length > 0 &&
              member.previous_experience[0].end_date
                ? member.previous_experience[0].end_date
                : ''
          })
      );
    }
    if (
      applicationDetails &&
      applicationDetails.existing_exchanges_listed &&
      applicationDetails.existing_exchanges_listed.length > 0
    ) {
      applicationDetails.existing_exchanges_listed.map(
        (member, index) =>
          (existingExchangeList = {
            ...existingExchangeList,
            [`Exchange Name ${index}`]: member.exchange_name,
            [`24h Trading Volume ${index}`]: member.hour24_trading_volume
          })
      );
    }
    if (
      applicationDetails &&
      applicationDetails.market_makers &&
      applicationDetails.market_makers.length > 0
    ) {
      applicationDetails.market_makers.map(
        (market, index) =>
          (marketMakers = { ...marketMakers, [`Market Maker ${index}`]: market })
      );
    }
    if (
      applicationDetails &&
      applicationDetails.listing_program &&
      applicationDetails.listing_program.length > 0
    ) {
      applicationDetails.listing_program.map(
        (prog, index) => (programs = { ...programs, [`Programs ${index}`]: prog })
      );
    }
    let targetGeoObj = {};
    if (
      applicationDetails &&
      applicationDetails.target_geo &&
      applicationDetails.target_geo.length > 0
    ) {
      applicationDetails.target_geo.map(
        (geo, index) => (targetGeoObj = { ...targetGeoObj, [`TargetGeo ${index}`]: geo })
      );
    }

    let roundsObject = {};

    if (
      applicationDetails &&
      applicationDetails.investment_stages &&
      applicationDetails.investment_stages.length > 0
    ) {
      applicationDetails.investment_stages.map((roundDetail, index) => {
        roundsObject = {
          ...roundsObject,
          [`Round ${index}`]: roundDetail.round,
          [`Token Price ${index}`]: roundDetail.token_price,
          [`Token Size ${index}`]: roundDetail.token_size
        };

        roundDetail.all_investors.map((investor, investorIndex) => {
          roundsObject = {
            ...roundsObject,
            [`Investor ${index}-${investorIndex} Name`]: investor.name,
            [`Investor ${index}-${investorIndex} Amount`]: investor.amount,
            [`Investor ${index}-${investorIndex} Lead`]: investor.lead,
            [`Investor ${index}-${investorIndex} Tier`]: investor.tier
          };
        });
      });
    }
    if (applicationDetails && Object.keys(applicationDetails).length > 0) {
      const data = {
        'Applicant Email': applicationDetails.applicant_email
          ? applicationDetails.applicant_email
          : '',
        'Applicant First Name': applicationDetails.applicant_first_name
          ? applicationDetails.applicant_first_name
          : '',
        'Applicant Last Name': applicationDetails.applicant_last_name
          ? applicationDetails.applicant_last_name
          : '',
        'Applicant Telegram': applicationDetails.applicant_telegram
          ? applicationDetails.applicant_telegram
          : '',
        'Project Name': applicationDetails.project_name
          ? applicationDetails.project_name
          : '',
        'Project Website': applicationDetails.project_website
          ? applicationDetails.project_website
          : '',
        'Project Description': applicationDetails.project_description
          ? applicationDetails.project_description
          : '',
        'Token Name': applicationDetails.token_name ? applicationDetails.token_name : '',
        'Token Ticker': applicationDetails.ticker_logo
          ? applicationDetails.ticker_logo
          : '',
        ...teamMembers,

        Twitter:
          applicationDetails.social_media &&
          applicationDetails.social_media.length > 0 &&
          applicationDetails.social_media.find((media) => media.name === 'twitter')
            .account_id,
        Discord:
          applicationDetails.social_media &&
          applicationDetails.social_media.length > 0 &&
          applicationDetails.social_media.find((media) => media.name === 'discord')
            .account_id,
        Linkedin:
          applicationDetails.social_media &&
          applicationDetails.social_media.length > 0 &&
          applicationDetails.social_media.find((media) => media.name === 'linkedin')
            .account_id,
        Facebook:
          applicationDetails.social_media &&
          applicationDetails.social_media.length > 0 &&
          applicationDetails.social_media.find((media) => media.name === 'facebook')
            .account_id,
        Instagram:
          applicationDetails.social_media &&
          applicationDetails.social_media.length > 0 &&
          applicationDetails.social_media.find((media) => media.name === 'instagram')
            .account_id,
        Tiktok:
          applicationDetails.social_media &&
          applicationDetails.social_media.length > 0 &&
          applicationDetails.social_media.find((media) => media.name === 'tiktok')
            .account_id,
        'CoinMarketCap URL': applicationDetails.coin_market_cap_url
          ? applicationDetails.coin_market_cap_url
          : '',
        'CoinGecko URL': applicationDetails.coin_gecko_url
          ? applicationDetails.coin_gecko_url
          : '',
        'Looking to get listed': applicationDetails.looking_to_get_listed
          ? applicationDetails.looking_to_get_listed
          : '',

        'Blockchain Network': applicationDetails.blockchain_network
          ? applicationDetails.blockchain_network
          : '',
        'Other Network': applicationDetails.other_network
          ? applicationDetails.other_network
          : '',
        'Token Address': applicationDetails.token_address
          ? applicationDetails.token_address
          : '',
        'Number of Active Users': applicationDetails.number_of_active_users
          ? applicationDetails.number_of_active_users
          : '',

        ...existingExchangeList,
        ...marketMakers,
        'Marketing Partners': applicationDetails.marketing_partners
          ? applicationDetails.marketing_partners
          : '',
        'Exchange Launch Marketing Campaign':
          applicationDetails.exchange_launch_marketing_campaign
            ? applicationDetails.exchange_launch_marketing_campaign
            : '',
        'Marketing Budget Allocated For Listing':
          applicationDetails.marketing_budget_allocated_for_listing
            ? applicationDetails.marketing_budget_allocated_for_listing
            : '',
        Whitepapers: applicationDetails.whitepapers_doc
          ? applicationDetails.whitepapers_doc
          : '',
        'Pitch deck': applicationDetails.pitch_decks_doc
          ? applicationDetails.pitch_decks_doc
          : '',
        ...programs,

        'Listing Date': applicationDetails.listing_date
          ? applicationDetails.listing_date
          : '',
        'Listing Price': applicationDetails.listing_price
          ? applicationDetails.listing_price
          : '',
        Tokenomics: applicationDetails.tokenomics_doc
          ? applicationDetails.tokenomics_doc
          : '',
        'Token Distribution': applicationDetails.token_distribution_doc
          ? applicationDetails.token_distribution_doc
          : '',
        'Use cases of tokens': applicationDetails.token_use_case
          ? applicationDetails.token_use_case
          : '',
        ...roundsObject,
        Roadmap: applicationDetails.road_map ? applicationDetails.road_map : '',
        'Unique Selling Point': applicationDetails.unique_selling_point
          ? applicationDetails.unique_selling_point
          : '',
        ...targetGeoObj,
        'Demo Link': applicationDetails.demo_link ? applicationDetails.demo_link : '',
        'Video Link': applicationDetails.video_link ? applicationDetails.video_link : '',
        'Extra Information': applicationDetails.extra_information
          ? applicationDetails.extra_information
          : ''
      };

      const newRow = { Fields: 'Value' };

      const csvContent = Object.keys(data)
        .map((key) => `"${key}","${data[key]}"`)
        .join('\n');

      const newCsvContent = Object.keys(newRow)
        .map((key) => `"${key}","${newRow[key]}"`)
        .join('\n');

      const finalCsvContent = `${newCsvContent}\n${csvContent}`;
      const encodedURI = encodeURI(`data:text/csv;charset=utf-8,${finalCsvContent}`);
      const link = document.createElement('a');
      link.setAttribute('href', encodedURI);
      link.setAttribute('download', `${applicationId}.csv`);
      document.body.appendChild(link);
      link.click();
    }
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row style={{ height: '82vh' }}>
          <Col
            lg={page === 'application' ? 8 : page === 'response' ? 9 : 10}
            md={page === 'application' ? 8 : page === 'response' ? 9 : 10}
            sm={12}>
            <Card>
              <div className='pt-3 pl-3'>
                <button
                  type='button'
                  className='btn btn-light p-1 mb-2'
                  style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
                  onClick={() => history.push('/exchange-applications')}>
                  <ArrowLeftIcon />
                </button>
              </div>
              <Nav tabs className='nav-tabs-custom nav-justified'>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'application' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('application');
                    }}>
                    <span className='font-size-14 mb-3'>Application Page</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'response' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('response');
                    }}>
                    <span className='font-size-14 mb-3'>Responses</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    disabled={!analyticsAvailable}
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'analytics' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('analytics');
                    }}>
                    <span className='font-size-14 mb-3'>Analytics</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'chat' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('chat');
                    }}>
                    <span className='font-size-14 mb-3'>Chat</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={page}>
                <TabPane tabId='application'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <ViewOnRampApplication
                        props={props}
                        setPage={setPage}
                        setAddQuestions={setAddQuestions}
                        addQuestions={addQuestions}
                        setApplicationId={setApplicationId}
                        applicationId={applicationId}
                        setTextareaValues={setTextareaValues}
                        setClickedLabels={setClickedLabels}
                        setBeneficialusersValues={setBeneficialusersValues}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='response'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <ResponseList
                        props={props}
                        allResponses={allResponses}
                        loading={loading}
                        totalCount={totalCount}
                        GetApplicationResponseFunc={GetApplicationResponseFunc}
                        statusSuggestions={statusSuggestions}
                        responsePage={responsePage}
                        updatePage={updatePage}
                        order={order}
                        setOrder={setOrder}
                        first={first}
                        setFirst={setFirst}
                        setResponsePage={setResponsePage}
                        setCurrentPageData={setCurrentPageData}
                        currentPageData={currentPageData}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='analytics'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <TokenAnalytics
                        activeTab={activeTab}
                        tokenSecurity={tokenSecurity}
                        infoSecurity={infoSecurity}
                        contractSecurity={contractSecurity}
                        toggleTab={toggleTab}
                        tradingSecurity={tradingSecurity}
                        twitterHandle={twitterHandle}
                        twitterScore={twitterScore}
                        poolsInfo={geckoPools}
                        teamAnalytics={teamAnalytics}
                        selectedTeamMember={selectedTeamMember}
                        tokenMarketData={tokenMarketData}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='chat'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <AppChat
                        activeTab={activeTab}
                        applicationId={applicationId}
                        applicationAll={applicationAll}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
          {page === 'response' && (
            <Col lg={3} md={3} sm={6}>
              <ResponseFilters
                statusSuggestions={statusSuggestions}
                setStatusSuggestions={setStatusSuggestions}
                setResponsePage={setResponsePage}
                setUpdatePage={setUpdatePage}
                updatePage={updatePage}
                applicationId={applicationId}
                GetApplicationResponseFunc={GetApplicationResponseFunc}
                applicationAll={applicationAll}
              />
            </Col>
          )}

          {page === 'application' && (
            <Col lg={4} md={4} sm={12}>
              <ChangeRequests
                setAddQuestions={setAddQuestions}
                applicationId={applicationId}
                selectedStatus={selectedStatus}
                modal={modal}
                handleModal={handleModal}
                setUpdatePage={setUpdatePage}
                updatePage={updatePage}
                handleChangeStatus={handleChangeStatus}
                setTextareaValues={setTextareaValues}
                clickedLabels={clickedLabels}
                textareaValues={textareaValues}
                setClickedLabels={setClickedLabels}
                beneficialusersValues={beneficialusersValues}
                GetApplicationResponseFunc={GetApplicationResponseFunc}
                setPage={setPage}
                csvExportData={csvExportData}
                applicationAll={applicationAll}
              />
            </Col>
          )}

          {page === 'analytics' && (
            <Col lg={2} md={2} sm={6}>
              <TableOfContents
                pickedTab={activeTab}
                setActiveContent={toggleTab}
                info={tokenSecurity}
                infoSecurity={infoSecurity}
                contractSecurity={contractSecurity}
                tradingSecurity={tradingSecurity}
                twitterInfo={{ twitterScore: twitterScore, twitterHandle: twitterHandle }}
                poolsInfo={geckoPools}
                teamAnalytics={teamAnalytics}
                geckoTokenInfo={geckoTokenInfo}
                setSelectedTeamMember={setSelectedTeamMember}
                selectedTeamMember={selectedTeamMember}
                tokenMarketData={tokenMarketData}
                setAnalyticsAvailable={setAnalyticsAvailable}
              />
            </Col>
          )}
        </Row>
      </div>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

function TableOfContents({
  pickedTab,
  setActiveContent,
  info,
  infoSecurity,
  contractSecurity,
  tradingSecurity,
  poolsInfo,
  teamAnalytics,
  setSelectedTeamMember,
  selectedTeamMember,
  tokenMarketData,
  twitterInfo
}) {
  const tabs = [];
  if (infoSecurity !== undefined)
    tabs.push({ name: 'Info Security', id: 'infoSecurity' });
  if (contractSecurity !== undefined)
    tabs.push({ name: 'Contract Security', id: 'contractSecurity' });
  if (tradingSecurity !== undefined)
    tabs.push({ name: 'Trading Security', id: 'tradingSecurity' });
  if (info !== undefined) {
    if (info.holders !== undefined)
      tabs.push({ name: 'Holders Info', id: 'holdersInfo' });
    if (info.lp_holders !== undefined)
      tabs.push({ name: 'Liquidity Providers Info', id: 'liquidityProvidersInfo' });
    if (info.dex !== undefined) tabs.push({ name: 'DEX Info', id: 'dexInfo' });
  }
  if (poolsInfo !== undefined) tabs.push({ name: 'Pools Info', id: 'poolsInfo' });
  if (teamAnalytics !== undefined && teamAnalytics.length > 0)
    tabs.push({ name: 'Team Analytics', id: 'teamAnalytics' });
  if (tokenMarketData !== undefined) tabs.push({ name: 'Price Chart', id: 'priceChart' });
  if (twitterInfo.twitterScore !== undefined)
    tabs.push({ name: 'Twitter Info', id: 'twitterInfo' });

  return (
    <Card className='table-of-contents p-3'>
      <h5 className='table-of-contents-title'>Table of Contents</h5>
      <div className='table-of-contents-content'>
        <ToCTabs
          pickedTab={pickedTab}
          setActiveContent={setActiveContent}
          tabs={tabs}
          teamAnalytics={teamAnalytics}
          setSelectedTeamMember={setSelectedTeamMember}
          selectedTeamMember={selectedTeamMember}
        />
      </div>
    </Card>
  );
}

function ToCTabs({
  pickedTab,
  setActiveContent,
  tabs,
  teamAnalytics,
  setSelectedTeamMember,
  selectedTeamMember
}) {
  return (
    <ul className='table-of-contents-list'>
      {tabs.map((tab, index) =>
        tab.id === 'teamAnalytics' ? (
          <div>
            <div key={index} className={`table-of-contents-items-group`}>
              <div className='table-of-contents-item-title'>{tab.name}</div>
              <div className='table-of-contents-item-title-subtitles'>
                {teamAnalytics?.map((member) => (
                  <li
                    className={`table-of-contents-item ${
                      selectedTeamMember?.id === member.id && pickedTab === tab.id
                        ? 'active-content'
                        : ''
                    }`}
                    key={member.id}
                    onClick={() => {
                      setActiveContent(tab.id);
                      setSelectedTeamMember(member);
                    }}>
                    {capitalizeText(member.full_name)}
                  </li>
                ))}
              </div>
            </div>
          </div>
        ) : (
          <li
            key={index}
            onClick={() => setActiveContent(tab.id)}
            className={`table-of-contents-item ${
              pickedTab === tab.id ? 'active-content' : ''
            }`}>
            <div className='table-of-contents-item-title'>{tab.name}</div>
          </li>
        )
      )}
    </ul>
  );
}

function mapNetworks(target, geckoArr, goplusArr) {
  const flattenedGeckoArr = geckoArr.map((network) => flattenObject(network));
  const flattenedGoplusArr = goplusArr.map((network) => flattenObject(network));

  let geckoFuzzyMatches = [];
  const geckoResults = fuzzysort.go(target, flattenedGeckoArr, {
    keys: ['id', 'attributes_coingecko_asset_platform_id', 'attributes_name']
  });

  geckoFuzzyMatches = geckoResults
    .filter((result) => result.score > -20)
    .map((result) => {
      if (result[0] !== undefined) {
        return result[0];
      }
    })
    .flat();

  let goplusFuzzyMatches = [];
  const goplusResults = fuzzysort.go(target, flattenedGoplusArr, {
    key: 'label'
  });

  goplusFuzzyMatches = goplusResults
    .filter((result) => result.score > -20)
    .map((result) => result.target);

  return {
    gecko: geckoFuzzyMatches,
    goplus: goplusFuzzyMatches
  };
}

function populateSecurityArray(
  responseObject,
  setInfoSecurity,
  setContractSecurity,
  setTradingSecurity,
  setDexInfo
) {
  setDexInfo(responseObject.dex);
  const infoSecurity = {
    token_name: { label: 'Token Name', value: responseObject.token_name },
    token_symbol: { label: 'Token Symbol', value: responseObject.token_symbol },
    total_supply: { label: 'Total Supply', value: responseObject.total_supply },
    creator_address: {
      label: 'Creator Address',
      value: responseObject.creator_address
    },
    creator_balance: {
      label: 'Creator Balance',
      value: responseObject.creator_balance
    },
    creator_percent: {
      label: 'Creator Percent',
      value: responseObject.creator_percent
    },
    owner_balance: {
      label: 'Owner Balance',
      value: responseObject.owner_balance
    },
    owner_percent: {
      label: 'Owner Percent',
      value: responseObject.owner_percent
    },
    trust_list: {
      label: 'Trust List',
      value: responseObject.trust_list
    },
    note: {
      label: 'Note',
      value: responseObject.note
    },
    other_potential_risks: {
      label: 'Other Potential Risks',
      value: responseObject.other_potential_risks
    },
    is_airdrop_scam: {
      label: 'Is Airdrop Scam',
      value: responseObject.is_airdrop_scam
    },
    is_true_token: {
      label: 'Is True Token',
      value: responseObject.is_true_token
    }
  };

  setInfoSecurity(infoSecurity);

  const contractSecurity = {
    is_mintable: { label: 'Is Mintable', value: responseObject.is_mintable },
    is_open_source: { label: 'Is Open Source', value: responseObject.is_open_source },
    is_proxy: { label: 'Is Proxy', value: responseObject.is_proxy },
    owner_address: { label: 'Owner Address', value: responseObject.owner_address },
    can_take_back_ownership: {
      label: 'Can Take Back Ownership',
      value: responseObject.can_take_back_ownership
    },
    owner_change_balance: {
      label: 'Owner Change Balance',
      value: responseObject.owner_change_balance
    },
    hidden_owner: { label: 'Hidden Owner', value: responseObject.hidden_owner },
    selfdestruct: { label: 'Selfdestruct', value: responseObject.selfdestruct },
    external_call: { label: 'External Call', value: responseObject.external_call },
    gas_abuse: { label: 'Gas Abuse', value: responseObject.gas_abuse }
  };

  setContractSecurity(contractSecurity);

  const tradingSecurity = {
    is_in_dex: { label: 'Is In Dex', value: responseObject.is_in_dex },
    buy_tax: { label: 'Buy Tax', value: responseObject.buy_tax },
    sell_tax: { label: 'Sell Tax', value: responseObject.sell_tax },
    cannot_buy: { label: 'Cannot Buy', value: responseObject.cannot_buy },
    cannot_sell_all: {
      label: 'Cannot Sell All',
      value: responseObject.cannot_sell_all
    },
    slippage_modifiable: {
      label: 'Slippage Modifiable',
      value: responseObject.slippage_modifiable
    },
    is_honeypot: { label: 'Is Honeypot', value: responseObject.is_honeypot },
    transfer_pausable: {
      label: 'Transfer Pausable',
      value: responseObject.transfer_pausable
    },
    is_blacklisted: {
      label: 'Is Blacklisted',
      value: responseObject.is_blacklisted
    },
    is_whitelisted: {
      label: 'Is Whitelisted',
      value: responseObject.is_whitelisted
    },
    is_anti_whale: { label: 'Is Anti Whale', value: responseObject.is_anti_whale },
    anti_whale_modifiable: {
      label: 'Anti Whale Modifiable',
      value: responseObject.anti_whale_modifiable
    },
    trading_cooldown: {
      label: 'Trading Cooldown',
      value: responseObject.trading_cooldown
    },
    personal_slippage_modifiable: {
      label: 'Personal Slippage Modifiable',
      value: responseObject.personal_slippage_modifiable
    }
  };

  setTradingSecurity(tradingSecurity);
}

function flattenObject(ob) {
  const toReturn = {};

  for (const i in ob) {
    if (!ob.hasOwnProperty(i)) continue;

    if (typeof ob[i] === 'object') {
      const flatObject = flattenObject(ob[i]);
      for (const x in flatObject) {
        if (!flatObject.hasOwnProperty(x)) continue;

        toReturn[i + '_' + x] = flatObject[x];
      }
    } else {
      toReturn[i] = ob[i];
    }
  }
  return toReturn;
}

export function capitalizeText(text) {
  const preposition = [
    'a',
    'an',
    'and',
    'but',
    'for',
    'at',
    'by',
    'from',
    'of',
    'in',
    'to',
    'on',
    'with'
  ];
  if (text) {
    return text
      .split(' ')
      .map((word, index) => {
        if (index === 0 || !preposition.includes(word)) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return word;
        }
      })
      .join(' ');
  }
}

export default ExchangeViewApplication;
