import React, { useEffect, useState } from 'react';
import ReactTags from 'react-tag-autocomplete';
import { Card, CardBody, Col, Collapse, FormGroup, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../../constants/application';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';

function TargetGeoApp({
  allResponses = [],
  touched = {},
  title = '',
  isSectionCollapsed,
  toggleSection,
  errors = {},
  inputName = [],
  values,
  setFieldValue,
  suggestionsRegion,
  applicationId,
  setInitialValuesFunc
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [tags, setTags] = useState([]);
  const [responseCount, setResponseCount] = useState(0);
  useEffect(() => {
    setTags(values.target_geo);
  }, []);

  useEffect(() => {
    const res = Object.keys(errors).find((i) => inputName.includes(i));
    setErrorShow(!!res);
  }, [errors]);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          ['target_geo'].includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);

  const onDelete = (index) => {
    setTags(tags.filter((t, i) => i !== index));
  };

  const onAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const handleSubmit = () => {
    if (!inputName.includes(Object.keys(errors).find((i) => inputName.includes(i)))) {
      setIsLoading(true);
      const data = {
        target_geo: JSON.stringify(values.target_geo)
      };
      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          notifySuccess(`Target Geo data updated successfully`);
          setIsLoading(false);
          setInitialValuesFunc(application.exchange_application);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody
        className='py-3 px-4'
        onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div
          className='d-flex align-items-center justify-content-between p-0'
          onClick={(e) => {
            e.stopPropagation();
            toggleSection();
          }}>
          {' '}
          <span className='font-size-14'>{title}</span>
          <span className='d-flex align-item-center'>
            {errorShow && (
              <div>
                <div className='d-flex justify-content-center'>
                  <lord-icon
                    src='https://cdn.lordicon.com/vyukcgvf.json'
                    trigger='hover'
                    target='#create-exchange-app'
                    colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                    style={{ width: '35px', height: '35px' }}></lord-icon>
                </div>
              </div>
            )}
            <div>
              <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3'>
                <i
                  className={`mdi mdi-chevron-${
                    isSectionCollapsed === true ? 'up' : 'down'
                  }`}
                />
              </label>
            </div>
            {responseCount > 0 && (
              <div style={{ position: 'absolute', right: 5, top: 0 }}>
                <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                  {responseCount}
                </label>
              </div>
            )}
          </span>
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <Row>
                <Col>
                  <FormGroup className='mb-2'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='target_geo'
                        className='text-black font-weight-normal'>
                        {CONST.TARGETGEO}
                      </Label>
                    </div>
                    <div>
                      <ReactTags
                        tags={tags}
                        classNames={{
                          root: `font-size-12 ${
                            errors.target_geo ? 'border-danger' : 'border-dark'
                          }  custom-react-tags`
                        }}
                        name='target_geo'
                        placeholderText='Select Region'
                        suggestions={suggestionsRegion}
                        onDelete={(index) => {
                          onDelete(index);
                          const newTags = tags.filter((t, i) => i !== index);
                          setFieldValue(
                            'target_geo',
                            newTags.map((tag) => tag.name)
                          );
                        }}
                        onAddition={(tag) => {
                          onAddition(tag);
                          const newTags = [...tags, tag];
                          setFieldValue(
                            'target_geo',
                            newTags.map((tag) => tag.name)
                          );
                        }}
                      />
                    </div>
                    {errors.target_geo && touched.target_geo ? (
                      <div className='text-danger font-size-12'>{errors.target_geo}</div>
                    ) : null}
                  </FormGroup>
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default TargetGeoApp;
