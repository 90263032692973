import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { GetNetworkDetail } from '../../services/Business/Crypto';
import Info from './Info';
import News from '../News/News';
import ProvidersTokens from './ProvidersTokens';
import TotalCoverage from './TotalCoverage';

function NetworkDetailsPage(props) {
  let { id: networkId } = useParams();
  const [network, setNetwork] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    GetNetworkDetail(networkId)
      .then((res) => {
        setIsLoaded(true);
        const data = get(res, 'data.data.data');
        setNetwork(data);
        return data;
      })
      .catch((err) => {
        setIsLoaded(true);
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, [networkId]);

  const newsTicker =
    get(network, 'networkData.networks', []).reduce(
      (res, n) => n && (!res || String(n).length < res.length ? n : res),
      ''
    ) || get(network, 'networkData.coin_gecko_id');

  return (
    <React.Fragment>
      <div className={'page-content ' + (!isLoaded && 'page-content-loading')}>
        {!isLoaded ? (
          <div className="h-100 d-flex align-items-center justify-content-center py-4">
            <i
              className="fas fa-circle-notch fa-spin text-muted"
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <>
            {network && (
              <>
                <div className="row mb-3">
                  <div className="col-12 col-lg-6">
                    <Info network={network.networkData} className="height-582px" />
                  </div>
                  <div className="col-12 col-lg-6">
                    <News search={newsTicker} className="height-582px" />
                  </div>
                  <div className="col-12">
                    <ProvidersTokens providers={network.providers_assets} />
                  </div>
                  <div className="col-12">
                    <TotalCoverage
                      providers={network.providers_assets}
                      network={network.networkData}
                    />
                  </div>
                </div>
              </>
            )}
            <NotificationToastContainer />
          </>
        )}
      </div>
    </React.Fragment>
  );
}

export default NetworkDetailsPage;
