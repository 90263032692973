import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';

function TeamMembers({
  allResponses = [],
  touched = {},
  errors = {},
  handleChange = () => {},
  values = {},
  setFieldTouched = () => {},
  setFieldValue = () => {},
  isSectionCollapsed,
  toggleSection,
  applicationId,
  setInitialValuesFunc
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [teamMembers, setTeamMembers] = useState(values.team_members);
  const [validationErrors, setValidationErrors] = useState({});
  const [responseCount, setResponseCount] = useState(0);

  const handleDeleteMember = (index) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers.splice(index, 1);
    setTeamMembers(newTeamMembers);
    setFieldValue('team_members', newTeamMembers);
  };

  const handleTeamChange = (name, value) => {
    setFieldValue(name, value);
  };
  const validateTeamMembers = () => {
    let errors = {};

    if (teamMembers && Array.isArray(teamMembers)) {
      teamMembers.forEach((member, i) => {
        const fieldsToCheck = [
          'first_name',
          'last_name',
          'email',
          'linkedin',
          'position',
          'start_date',
          'end_date'
        ];

        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;

        fieldsToCheck.forEach((field) => {
          if (!member[field]) {
            if (!errors.team_members) {
              errors.team_members = [];
            }
            if (!errors.team_members[i]) {
              errors.team_members[i] = {};
            }
            errors.team_members[i][field] = `${toReadableLabel(field)} is required`;
          } else if (field === 'email' && !emailRegex.test(member[field])) {
            if (!errors.team_members) {
              errors.team_members = [];
            }
            if (!errors.team_members[i]) {
              errors.team_members[i] = {};
            }
            errors.team_members[i][field] = 'Invalid email address';
          }
        });
      });
    }
    setValidationErrors(errors);
  };

  const handleAddMember = () => {
    const newTeamMembers = [
      ...teamMembers,
      {
        first_name: '',
        last_name: '',
        email: '',
        linkedin: '',
        position: '',
        start_date: '',
        end_date: ''
      }
    ];

    setTeamMembers(newTeamMembers);
  };
  useEffect(() => {
    if (values && values.team_members) {
      setTeamMembers(values.team_members);
    }
  }, [values]);
  const handleSubmit = () => {
    if (!Object.keys(validationErrors) || Object.keys(validationErrors).length <= 0) {
      const team_members = teamMembers.map((member, i) => ({
        first_name: member[`first_name`],
        last_name: member[`last_name`],
        email: member[`email`],
        linkedin: member[`linkedin`],
        position: member[`position`],
        previous_experience: [
          {
            start_date: member[`start_date`],
            end_date: member[`end_date`]
          }
        ]
      }));
      const data = {
        team_members: JSON.stringify(team_members)
      };
      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          notifySuccess(`Team Members data updated successfully`);
          setInitialValuesFunc(application.exchange_application);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Team Members</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              {teamMembers &&
                teamMembers.length > 0 &&
                teamMembers.map((member, index) => (
                  <Row key={index}>
                    {[
                      'first_name',
                      'last_name',
                      'email',
                      'linkedin',
                      'position',
                      'start_date',
                      'end_date'
                    ].map((field) => (
                      <Col className='mt-4' md={4} key={`${field}_${index}`}>
                        <CustomInput
                          label={field.replace('_', ' ')}
                          htmlFor={`${field}_${index}`}
                          setFieldTouched={setFieldTouched}
                          name={`team_members.${index}.${field}`}
                          placeholder={field.replace('_', ' ')}
                          values={member[field]}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          teamMembers={teamMembers}
                          type={field.includes('date') ? 'date' : 'text'}
                          validationErrors={validationErrors}
                          setFieldValue={setFieldValue}
                          index={index}
                          field={field}
                          handleTeamChange={handleTeamChange}
                        />
                      </Col>
                    ))}
                    {(teamMembers.length - 1 === index || teamMembers.length === 0) && (
                      <Col md={12}>
                        <button
                          className='btn btn-auth mt-2'
                          type='button'
                          style={{ width: 250, height: 40 }}
                          onClick={handleAddMember}>
                          Add New Member
                        </button>
                      </Col>
                    )}
                    <Col md={12}>
                      <button
                        className='btn btn-auth mt-3'
                        style={{ width: 150, height: 40 }}
                        type='button'
                        onClick={() => handleDeleteMember(index)}>
                        Delete
                      </button>
                    </Col>
                  </Row>
                ))}
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={() => {
                    if (!validateTeamMembers()) {
                      handleSubmit();
                    }
                  }}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default TeamMembers;

function toReadableLabel(str) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}
const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  extraLabel,
  index,
  handleTeamChange,
  validationErrors,
  field
}) => (
  <FormGroup className='mb-2'>
    <div className='font-size-12'>
      <Label htmlFor={htmlFor} className='text-black font-weight-normal'>
        {toReadableLabel(label)} {extraLabel}
      </Label>
    </div>
    <div>
      <Input
        type={name.includes('date') ? 'date' : 'text'}
        value={values}
        name={name}
        className='font-size-12 mr-2 border-dark'
        placeholder={'Enter ' + toReadableLabel(placeholder)}
        onChange={(e) => {
          handleTeamChange(name, e.target.value);
        }}
        onBlur={() => setFieldTouched(name, true, true)}
      />

      <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
        {validationErrors &&
          validationErrors.team_members &&
          validationErrors?.team_members[index][field]}
      </div>
    </div>
  </FormGroup>
);
