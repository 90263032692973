import React, { useEffect, useState } from 'react';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { constants as CONST } from '../../constants/application';
import { constants as APP_CONST } from '../../constants/constant';
import { constants as SUCCESS_CONST } from '../../constants/successMessage';
import { notificationMessageFunction } from '../../constants/notificationConst';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../Custom/notification';
import {
  Button,
  Card,
  CardBody,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  Table
} from 'reactstrap';
import AuthInput from '../../Custom/AuthInput';
import { updateSettings, getSettings } from '../../services/Application/settings';
import get from 'lodash/get';
import { inviteUser, inviteUserList } from '../../services/Auth';

const TABS = {
  GENERAL_INFO: 0,
  TEAM: 1,
  NOTIFICATIONS: 2
};

function SettingApplications() {
  const [activeTab, setActiveTab] = useState(TABS.GENERAL_INFO);
  const [fieldToUpdate, setFieldToUpdate] = useState('all');

  const ApplicationSettingsSchema = Yup.object().shape({
    business_name: Yup.string().required('Business Name is required'),
    business_logo: Yup.mixed().required('Business Logo is required'),
    business_ticker: Yup.string(),
    business_description: Yup.string(),
    web_notification: Yup.boolean(),
    email_notification: Yup.boolean(),
    on_statuses: Yup.array(),
    website: Yup.string().url('Enter valid url')
  });
  const accountType = localStorage.getItem(APP_CONST.ACCOUNT_TYPE);
  const accountTypeName =
    accountType === '1'
      ? 'OnRamp'
      : accountType === '2'
      ? 'Business'
      : accountType === '4'
      ? 'Exchange'
      : '';

  const [initialValues, setInitialValues] = useState({
    business_name: '',
    business_logo: null,
    business_ticker: '',
    business_description: '',
    on_statuses: [],
    web_notification: false,
    email_notification: false,
    website: ''
  });

  const [logoFile, setLogoFile] = useState({});
  const [fileUrls, setFileUrls] = useState('');

  const [teamEmail, setTeamEmail] = useState('');
  const [accessLevel, setAccessLevel] = useState('');

  const [webCheck, setWebCheck] = useState(false);
  const [emailCheck, setEmailCheck] = useState(false);

  const [inviteCount, setInviteCount] = useState({});
  const [inviteList, setInviteList] = useState([]);

  const changeHandler = (event, setFieldValue) => {
    if (event.target.files[0])
      setLogoFile({ ...logoFile, [event.target.name]: event.target.files[0] });
    setFieldValue('business_logo', event.target.files[0]);
    const fileUrl = URL.createObjectURL(event.target.files[0]);
    setFileUrls(fileUrl);
  };

  useEffect(() => {
    getApplicationSetting();
    getInviteUserList();
  }, []);

  const getApplicationSetting = () => {
    getSettings().then((res) => {
      const application = get(res, 'data.data.data[0]', '');
      if (application) {
        setInitialValues({
          business_name: application.name,
          business_logo: application.logo_url,
          business_ticker: application.ticker ? application.ticker : '',
          business_description: application.description ? application.description : '',
          on_statuses: application.on_statuses.length
            ? JSON.parse(application.on_statuses)
            : [],
          web_notification: setWebCheck(application.web_notification),
          email_notification: setEmailCheck(application.email_notification),
          website: application.website ? application.website : ''
        });
        if (application.logo_url) {
          setFileUrls(application.logo_url);
          setLogoFile({ ...logoFile, business_logo: application.logo_url });
        }
      }
    });
  };

  const getInviteUserList = () => {
    inviteUserList()
      .then((res) => {
        const inviteList = get(res, 'data.data.data', '');
        setInviteList(inviteList);
        const inviteCount = get(res, 'data.data.count', '');
        setInviteCount(inviteCount);
      })
      .catch((error) => {});
  };

  const handleSubmit = (values) => {
    const payloadData = new FormData();
    if (fieldToUpdate !== 'all') {
      if (fieldToUpdate === 'business_name') {
        payloadData.append('name', values[fieldToUpdate]);
      }

      if (fieldToUpdate === 'business_ticker') {
        payloadData.append('ticker', values[fieldToUpdate]);
      }
      if (fieldToUpdate === 'business_description') {
        payloadData.append('description', values[fieldToUpdate]);
      }
      if (fieldToUpdate === 'web_notification' && emailCheck !== undefined) {
        payloadData.append('email_notification', emailCheck);
      }
      if (fieldToUpdate === 'web_notification' && webCheck !== undefined) {
        payloadData.append('web_notification', webCheck);
      }
      if (fieldToUpdate === 'on_statuses') {
        payloadData.append('on_statuses', JSON.stringify(values[fieldToUpdate]));
      }
      if (fieldToUpdate === 'website') {
        payloadData.append('website', values[fieldToUpdate]);
      }
      if (fieldToUpdate === 'business_logo') {
        payloadData.append('logo_file', logoFile['business_logo']);
      }
    } else {
      if (values.business_name) {
        payloadData.append('name', values.business_name);
      }
      if (values.business_ticker) {
        payloadData.append('ticker', values.business_ticker);
      }
      if (values.business_description) {
        payloadData.append('description', values.business_description);
      }
      if (webCheck !== undefined) {
        payloadData.append('web_notification', webCheck);
      }
      if (emailCheck !== undefined) {
        payloadData.append('web_notification', emailCheck);
      }
      if (values.on_statuses) {
        payloadData.append('on_statuses', JSON.stringify(values.on_statuses));
      }
      if (values.website) {
        payloadData.append('website', values.website);
      }
      if (logoFile['business_logo']) {
        payloadData.append('logo_file', logoFile['business_logo']);
      }
    }

    updateSettings(payloadData)
      .then(() => {
        notifySuccess(SUCCESS_CONST.APPLICATION_SETTING);
        getApplicationSetting();
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const handleSubmitUsers = (e) => {
    e.preventDefault();
    const data = {
      email: teamEmail,
      role: accessLevel === '' ? 'Reviewer' : accessLevel
    };
    inviteUser(data)
      .then((res) => {
        notifySuccess('New member will join your team once they accept your invite');
        getInviteUserList();
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <React.Fragment>
      <div className='page-content ml-4'>
        <Container fluid>
          <Col>
            <Row>
              <div className='form-inline float-md-left'>
                <div className='search-box ml-2'>
                  <div className='position-relative'>
                    <span className='font-size-20 text-black'>{CONST.SETTINGS}</span>
                  </div>
                </div>
              </div>
            </Row>
          </Col>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <CardBody>
                <Row className='mb-4 mr-0 ml-0'>
                  <Nav
                    style={{
                      width: '100%'
                    }}
                    tabs
                    className='nav-tabs-custom nav-justified'>
                    <NavItem>
                      <NavLink
                        active={activeTab === TABS.GENERAL_INFO}
                        onClick={() => setActiveTab(TABS.GENERAL_INFO)}>
                        General Info
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        active={activeTab === TABS.NOTIFICATIONS}
                        onClick={() => setActiveTab(TABS.NOTIFICATIONS)}>
                        Notifications
                      </NavLink>
                    </NavItem>
                    {/* <NavItem>
                      <NavLink
                        active={activeTab === TABS.TEAM}
                        onClick={() => setActiveTab(TABS.TEAM)}>
                        Team Management
                      </NavLink>
                    </NavItem> */}
                  </Nav>
                </Row>
                <Formik
                  initialValues={initialValues}
                  validationSchema={ApplicationSettingsSchema}
                  onSubmit={handleSubmit}
                  enableReinitialize={true}>
                  {({ errors, touched, values, handleChange, setFieldValue }) => (
                    <Form>
                      {activeTab === TABS.GENERAL_INFO && (
                        <GeneralInfo
                          initialValues={initialValues}
                          handleSubmit={handleSubmit}
                          ApplicationSettingsSchema={ApplicationSettingsSchema}
                          accountTypeName={accountTypeName}
                          logoFile={logoFile}
                          fileUrls={fileUrls}
                          changeHandler={changeHandler}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          setFieldValue={setFieldValue}
                          setFieldToUpdate={setFieldToUpdate}
                        />
                      )}

                      {activeTab === TABS.NOTIFICATIONS && (
                        <Notifications
                          webCheck={webCheck}
                          setWebCheck={setWebCheck}
                          emailCheck={emailCheck}
                          setEmailCheck={setEmailCheck}
                          accountType={accountType}
                          setFieldToUpdate={setFieldToUpdate}
                        />
                      )}

                      {activeTab === TABS.TEAM && (
                        <TeamManagement
                          teamEmail={teamEmail}
                          setTeamEmail={setTeamEmail}
                          accessLevel={accessLevel}
                          setAccessLevel={setAccessLevel}
                          handleSubmitUsers={handleSubmitUsers}
                          inviteCount={inviteCount}
                          inviteList={inviteList}
                        />
                      )}
                    </Form>
                  )}
                </Formik>
              </CardBody>
            </Card>
          </Col>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

function GeneralInfo({
  accountTypeName,
  fileUrls,
  changeHandler,
  values,
  handleChange,
  errors,
  touched,
  setFieldValue,
  setFieldToUpdate
}) {
  return (
    <>
      <div>
        <div>
          <Row className='d-flex justify-content-between mt-4'>
            <Col lg={5} md={5} sm={12}>
              <FormGroup className='mb-3'>
                <Label className='mb-3' htmlFor='business_name'>
                  {CONST.BUSINESS_NAME.replace('Business', accountTypeName)}
                </Label>
                <AuthInput
                  name='business_name'
                  type='text'
                  className='form-control'
                  values={values}
                  handleChange={handleChange}
                  errors={errors}
                  touched={touched}
                  id='business-name'
                  customBorder={true}
                  styles={{
                    borderRadius: '100px',
                    backgroundColor: 'hsla(0,0%,100%,1)',
                    borderColor: '#d7d5dd'
                  }}
                />
                {}
              </FormGroup>

              <SaveButton
                setFieldToUpdate={setFieldToUpdate}
                elementName='business_name'
              />
            </Col>
            <Col lg={5} md={5} sm={12}>
              <FormGroup className='mb-3'>
                <Label className='mb-3' htmlFor='business_ticker'>
                  {CONST.BUSINESS_TICKER.replace('Business', accountTypeName)}
                </Label>
                <AuthInput
                  name='business_ticker'
                  type='text'
                  className='form-control'
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  id='business_ticker'
                  customBorder={true}
                  styles={{
                    borderRadius: '100px',
                    backgroundColor: 'hsla(0,0%,100%,1)',
                    borderColor: '#d7d5dd'
                  }}
                />
              </FormGroup>

              <SaveButton
                setFieldToUpdate={setFieldToUpdate}
                elementName='business_ticker'
              />
            </Col>
          </Row>

          <hr className='mb-4' />
          <Row className='d-flex justify-content-between mt-4'>
            <Col lg={5} md={5} sm={12}>
              <FormGroup className='mb-3'>
                <Label className='mb-3' htmlFor='business_description'>
                  {CONST.BUSINESS_DESCRIPTION.replace('Business', accountTypeName)}
                </Label>
                <AuthInput
                  name='business_description'
                  type='textarea'
                  className='form-control'
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  id='business_description'
                  customBorder={true}
                  styles={{
                    backgroundColor: 'hsla(0,0%,100%,1)',
                    borderColor: '#d7d5dd'
                  }}
                />
              </FormGroup>

              <SaveButton
                setFieldToUpdate={setFieldToUpdate}
                elementName='business_description'
              />
            </Col>

            <Col lg={5} md={5} sm={12}>
              <FormGroup className='mb-3'>
                <Label className='mb-3' htmlFor='business_website'>
                  {CONST.WEBSITE}
                </Label>
                <AuthInput
                  name='website'
                  type='text'
                  className='form-control'
                  values={values}
                  errors={errors}
                  touched={touched}
                  handleChange={handleChange}
                  id='business_website'
                  customBorder={true}
                  styles={{
                    borderRadius: '100px',
                    backgroundColor: 'hsla(0,0%,100%,1)',
                    borderColor: '#d7d5dd'
                  }}
                />
              </FormGroup>

              <SaveButton
                setFieldToUpdate={setFieldToUpdate}
                elementName='business_website'
              />
            </Col>
          </Row>
          <hr />
          <Row className='d-flex justify-content-between mt-4'>
            <Col lg={5} md={5} sm={12}>
              <div>
                <FormGroup className='mb-4'>
                  <Row
                    style={{
                      alignItems: 'center',
                      gap: '1rem'
                    }}
                    className='pt-2 pl-2'>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        maxHeight: '47px',
                        maxWidth: '47px',
                        minHeight: '47px',
                        minWidth: '47px',
                        borderRadius: '1000px',
                        border: '1px solid #80808029'
                      }}
                      className='ml-1'>
                      {fileUrls && (
                        <img
                          src={fileUrls}
                          alt='logo'
                          style={{
                            maxHeight: '47px',
                            maxWidth: '47px',
                            minHeight: '47px',
                            minWidth: '47px',
                            borderRadius: '1000px'
                          }}
                          className='p-1'></img>
                      )}
                    </div>
                    <Label
                      style={{ marginBottom: 0 }}
                      htmlFor='logo_file'
                      id='logo_label'>
                      {accountTypeName} Logo
                    </Label>
                    <Input
                      type='file'
                      id='logo_file'
                      name='business_logo'
                      onChange={(e) => changeHandler(e, setFieldValue)}
                      style={{ display: 'none', zIndex: 999 }}
                      accept='image/*'
                    />
                    {errors.business_logo && (
                      <div
                        style={{ fontSize: 14 }}
                        className='text-left mt-1 text-danger'>
                        {errors.business_logo}
                      </div>
                    )}
                  </Row>
                </FormGroup>

                <SaveButton
                  setFieldToUpdate={setFieldToUpdate}
                  elementName='business_logo'
                />
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
}

function Notifications({
  webCheck,
  setWebCheck,
  emailCheck,
  setEmailCheck,
  accountType,
  setFieldToUpdate
}) {
  return (
    <>
      <Col lg={12} className='mt-5'>
        <FormGroup className='mb-4'>
          <Label htmlFor='product-enabled'>Get {CONST.NOTIFICATION}s on</Label>
          <div className='d-flex direction-row'>
            <div className='custom-control form-check custom-checkbox mr-3'>
              <Input
                name='web_notification'
                type='checkbox'
                onChange={() => setWebCheck(!webCheck)}
                checked={webCheck}
                value={webCheck}
                className='form-check-input'
              />
              <Label
                className='form-check-label'
                onClick={() => {
                  setWebCheck(!webCheck);
                }}>
                Web
              </Label>
            </div>
            <div className='custom-control form-check custom-checkbox'>
              <Input
                name='email_notification'
                type='checkbox'
                onChange={() => setEmailCheck(!emailCheck)}
                checked={emailCheck}
                value={emailCheck}
                className='form-check-input'
              />
              <Label
                className='form-check-label'
                onClick={() => {
                  setEmailCheck(!emailCheck);
                }}>
                Email
              </Label>
            </div>
          </div>
        </FormGroup>

        <SaveButton setFieldToUpdate={setFieldToUpdate} elementName='web_notification' />
      </Col>

      <Divider />

      <Col lg={12}>
        <FormGroup className='mb-2'>
          <Label htmlFor='on_statuses'>{CONST.ON_STATUSES}</Label>
          <div role='group' aria-labelledby='checkbox-group'>
            {accountType === '2' && (
              <>
                <label className='mr-2'>
                  <Field
                    type='checkbox'
                    name='on_statuses'
                    value='PROCESSING'
                    className='mr-2'
                  />
                  <label>Processing</label>
                </label>

                <label className='mr-2'>
                  <Field
                    type='checkbox'
                    name='on_statuses'
                    value='KYB'
                    className='mr-2'
                  />
                  <label>Request KYB</label>
                </label>
                <label className='mr-2'>
                  <Field
                    type='checkbox'
                    name='on_statuses'
                    value='ACCEPT'
                    className='mr-2'
                  />
                  <label>Accept</label>
                </label>
                <label className='mr-2'>
                  <Field
                    type='checkbox'
                    name='on_statuses'
                    value='REJECT'
                    className='mr-2'
                  />
                  <label>Reject</label>
                </label>
                <label className='mr-2'>
                  <Field
                    type='checkbox'
                    name='on_statuses'
                    value='ADD_QUESTIONS'
                    className='mr-2'
                  />
                  <label>Additional Questions</label>
                </label>
                <label className='mr-2'>
                  <Field
                    type='checkbox'
                    name='on_statuses'
                    value='REQUEST_ACTION'
                    className='mr-2'
                  />
                  <label>Request Action</label>
                </label>
                <label className='mr-2'>
                  <Field
                    type='checkbox'
                    name='on_statuses'
                    value='QUOTE'
                    className='mr-2'
                  />
                  <label>QUOTE GIVEN</label>
                </label>
              </>
            )}
            {accountType !== '2' && (
              <>
                <label className='mr-2'>
                  <Field
                    type='checkbox'
                    name='on_statuses'
                    value='ACTION_NEEDED'
                    className='mr-2'
                  />
                  <label>Action Needed</label>
                </label>
                <label className='mr-2'>
                  <Field
                    type='checkbox'
                    name='on_statuses'
                    value='PAID'
                    className='mr-2'
                  />
                  <label>Paid</label>
                </label>
              </>
            )}
          </div>
        </FormGroup>

        <SaveButton setFieldToUpdate={setFieldToUpdate} elementName='on_statuses' />
      </Col>
    </>
  );
}

function TeamManagement({
  teamEmail,
  setTeamEmail,
  accessLevel,
  setAccessLevel,
  handleSubmitUsers,
  inviteCount,
  inviteList
}) {
  return (
    <>
      <div className='mt-5'>
        <form onSubmit={handleSubmitUsers}>
          <Label>Team Access:</Label>
          <Row>
            <Col lg={2}>
              <FormGroup className='mb-4'>
                <Label htmlFor='team-email'>{CONST.EMAIL}</Label>
                <Input
                  name='team_email'
                  type='text'
                  className='form-control'
                  id='team-email'
                  value={teamEmail}
                  onChange={(e) => setTeamEmail(e.target.value)}
                />
              </FormGroup>
            </Col>
            <Col lg={2}>
              <FormGroup className='mb-4'>
                <Label htmlFor='access-level'>{CONST.ACCESS_LEVEL}</Label>
                <select
                  value={accessLevel}
                  onChange={(e) => setAccessLevel(e.target.value)}
                  className='form-control'>
                  <option key='Reviewer' value='Reviewer'>
                    Reviewer
                  </option>
                  <option key='Admin' value='Admin'>
                    Admin
                  </option>
                </select>
              </FormGroup>
            </Col>
            <Col lg={2}>
              <div className='mt-4 pt-2 text-center w-25 waves-effect waves-light'>
                <button type='submit' className='btn btn-auth'>
                  Add
                </button>
              </div>
            </Col>
          </Row>
        </form>
      </div>
      <div className='mt-2'>
        <Label>Users ({inviteCount['Admin'] + inviteCount['Reviewer']})</Label>
        <div className='col-xl-4 col-lg-8 col-md-4 col-sm-6 col-12'>
          <Card>
            <CardBody
              style={{
                backgroundColor: '#EAEAFD',
                border: '1px solid #EAEAFD',
                borderRadius: 40
              }}>
              <div className='d-flex justify-content-between align-items-center pl-4 pr-4'>
                <div>
                  <h2 className=''>{inviteCount['Admin']}</h2>
                  <span className='w-400'>Admin</span>
                </div>
                <div>
                  <h2 className=''>{inviteCount['Reviewer']}</h2>
                  <span className='w-400'>Reviewer</span>
                </div>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
      <Col lg={8}>
        <Table responsive>
          {inviteList && inviteList.length > 0 && (
            <>
              <thead>
                <tr>
                  <th>#</th>
                  <th>Full name</th>
                  <th>Email</th>
                  <th>Access</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody>
                {inviteList.map((user, index) => (
                  <tr>
                    <td>{index + 1}</td>
                    <td>{user.invitees.full_name}</td>
                    <td>{user.invitees.email}</td>
                    <td>{user.invitees.role}</td>
                    <td>{user.invitees.status ? 'Joined' : 'Invited'}</td>
                  </tr>
                ))}
              </tbody>
            </>
          )}
        </Table>
      </Col>
    </>
  );
}

const SaveButton = ({ setFieldToUpdate, elementName }) => {
  return (
    <div className='waves-effect waves-light d-flex justify-content-center'>
      <Button
        style={{
          borderRadius: '100px',
          fontSize: '0.75rem',
          width: '150px'
        }}
        type='submit'
        name='btn'
        className={`btn btn-auth`}
        onClick={() => setFieldToUpdate(elementName)}>
        Save
      </Button>
    </div>
  );
};

const Divider = ({ color = '#f7f7f7', thickness = '2px', margin = '40px 0' }) => {
  return (
    <div
      style={{
        borderBottom: `${thickness} solid ${color}`,
        margin: margin
      }}
    />
  );
};

export default SettingApplications;
