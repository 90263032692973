/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect } from 'react';
import { GateFiSDK } from '@gatefi/js-sdk';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';

function GateFiWidget({ backToWidget, buyData }) {
  useEffect(() => {
    new GateFiSDK({
      merchantId: process.env.REACT_APP_GATEFI_MERCHANT_ID,
      displayMode: 'embedded',
      nodeSelector: '#container',
      fiatAmountLock: false,
      cryptoAmountLock: false,
      fiatCurrencyLock: false,
      cryptoCurrencyLock: false,
      defaultFiat: { currency: 'USD', amount: '1000' }
    });
  }, []);
  return (
    <>
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light p-1 mb-2"
          style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
          onClick={backToWidget}>
          <ArrowLeftIcon width={18} height={18} />
        </button>
      </div>
      <div
        style={{ display: 'flex', justifyContent: 'center' }}
        className="container"
        id="container"></div>
    </>
  );
}

export default GateFiWidget;
