import React, { useEffect, useState } from 'react';
import { GetApplication } from '../../../services/Business/Application';
import BeneficiaryUserModal from './BeneficiaryUserModal';
import get from 'lodash/get';
import GetUserList from './GetUserList';
import OnRampHandler from './OnRampHandler';
import { Container } from 'reactstrap';

function BeneficiaryUser({ setCompletedSteps, nextPage, prevPage, applicationId }) {
  const [userModal, setUserModal] = useState(false);
  const [userData, setUserData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [user, setUser] = useState();

  const addUser = () => {
    setUserModal(!userModal);
  };
  const getApplicationFunction = (applicationId) => {
    if (applicationId !== '') {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setUserData(application.beneficial_ownership);
        setIsLoaded(true);
      });
    }
  };
  useEffect(() => {
    setCompletedSteps([0, 1]);
    if (applicationId !== '') {
      getApplicationFunction(applicationId);
    }
  }, [userModal]);

  return (
    <React.Fragment>
      <div className='p-4 p-sm-1'>
        <div className='d-flex justify-content-between ml-3'>
          <div className='col-12 col-sm-auto font-size-22 d-flex align-items-center'>
            <span className='text-black'>Beneficial Ownership</span>
          </div>
          <button
            style={{ width: 180 }}
            className={`btn btn-status-change cursor-pointer`}
            onClick={addUser}>
            Add User
          </button>
        </div>
        <Container className='pt-4 pt-md-1'>
          <div className={!isLoaded && 'page-content-loading'}>
            {!isLoaded ? (
              <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                <i
                  className='fas fa-circle-notch fa-spin text-muted'
                  style={{ fontSize: 48 }}></i>
              </div>
            ) : (
              <>
                {userData && (
                  <div className='col-12 col-sm-auto font-size-16 font-weight-medium'>
                    <div className='table-responsive'>
                      <table className='table table-centered table-hover'>
                        <thead>
                          <tr className='font-size-14 text-muted center-text'>
                            <th className='pt-3 pb-4 border-0'>#</th>
                            <th className='pt-3 pb-4 border-0'>Full Name</th>
                            <th className='pt-3 pb-4 border-0'>Date Of Birth</th>
                            <th className='pt-3 pb-4 border-0'>Country</th>
                            <th className='pt-3 pb-4 border-0'>% Ownership</th>
                            <th className='pt-3 pb-4 border-0'>Role</th>
                            <th className='pt-3 pb-4 border-0'>Identity Documents</th>
                            <th className='pt-3 pb-4 border-0'>Action</th>
                          </tr>
                        </thead>
                        <tbody>
                          {userData && userData.length > 0 ? (
                            userData.map((user, key) => (
                              <tr
                                key={key}
                                className='font-size-12 font-weight-medium center-text'>
                                <GetUserList
                                  user={user}
                                  handleOpenModal={addUser}
                                  setUser={setUser}
                                  applicationId={applicationId}
                                  index={key}
                                  getApplicationFunction={getApplicationFunction}
                                />
                              </tr>
                            ))
                          ) : (
                            <tr>
                              <td colSpan={8} className='center-text'>
                                No user found
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                )}
              </>
            )}
          </div>

          <OnRampHandler
            ariaValueNow={userData && userData.length > 0 ? '40' : '20'}
            condition={userData && userData.length > 0 ? '40%' : '20%'}
            handleNext={nextPage}
            handlePre={prevPage}
          />
          <BeneficiaryUserModal
            applicationId={applicationId}
            modal={userModal}
            user={user}
            handleOpenModal={addUser}
          />
        </Container>
      </div>
    </React.Fragment>
  );
}

export default BeneficiaryUser;
