import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import { Progress } from 'reactstrap';
import {
  CreateApplication,
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import get from 'lodash/get';
import { StepsCalculator } from './StepsCalculator';
function StepOne(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const ApplicantInfoSchema = Yup.object().shape({
    applicant_first_name: Yup.string().required(ERROR_CONST.APPLICANT_FIRST_NAME),
    applicant_last_name: Yup.string().required(ERROR_CONST.APPLICANT_LAST_NAME),
    applicant_email: Yup.string()
      .required(ERROR_CONST.APPLICANT_EMAIL)
      .email(ERROR_CONST.INVALID_EMAIL),
    applicant_telegram: Yup.string()
  });
  const [initialValue, setInitialValue] = useState({
    applicant_first_name: '',
    applicant_last_name: '',
    applicant_email: '',
    applicant_telegram: ''
  });
  const step = 1;
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setInitialValue({
          applicant_first_name: application.exchange_application.applicant_first_name,
          applicant_last_name: application.exchange_application.applicant_last_name,
          applicant_email: application.exchange_application.applicant_email,
          applicant_telegram: application.exchange_application.applicant_telegram
        });

        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { nextPage } = props;
    setIsLoading(true);
    let stepsRequired = Math.floor(StepsCalculator(3, completeStep, 10));
    const data = {
      applicant_first_name: values.applicant_first_name.toLowerCase(),
      applicant_last_name: values.applicant_last_name,
      applicant_email: values.applicant_email,
      applicant_telegram: values.applicant_telegram,
      application_type: 'EXCHANGE',
      application_status: 'INCOMPLETE',
      percentage_complete: stepsRequired
    };

    if (props.applicationId !== '') {
      UpdateExchangeApplication({ application_id: props.applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          props.setApplicationData(application);
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    } else {
      CreateApplication(data)
        .then((res) => {
          props.setApplicationId(get(res, 'data.data.data', ''));
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
    setExchangeCompleteStep([0]);
  };

  const calculateProgress = () => {
    return completeStep;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>Applicant Background</span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={ApplicantInfoSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.APPLICANT_FIRST_NAME}
                        htmlFor='applicant_first_name'
                        name='applicant_first_name'
                        placeholder={CONST.ENTER_APPLICANT_FIRST_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.APPLICANT_LAST_NAME}
                        htmlFor='applicant_last_name'
                        name='applicant_last_name'
                        placeholder={CONST.ENTER_APPLICANT_LAST_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.APPLICANT_EMAIL}
                        htmlFor='applicant_email'
                        name='applicant_email'
                        placeholder={CONST.ENTER_APPLICANT_EMAIL}
                        values={values}
                        type='email'
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.APPLICANT_TELEGRAM}
                        htmlFor='applicant_telegram'
                        name='applicant_telegram'
                        placeholder={CONST.ENTER_APPLICANT_TELEGRAM_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        extraLabel={`(${CONST.OPTIONAL})`}
                      />
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-end'>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 200, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepOne;

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel
}) => (
  <FormGroup className='mb-4'>
    <div className='font-size-12'>
      <Label htmlFor={htmlFor} className='text-black font-weight-normal'>
        {label} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12'}
        placeholder={placeholder}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
