import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import AuthInput from '../../../Custom/AuthInput';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepSeventeen(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const step = 17;
  const [initialValue, setInitialValue] = useState({
    token_use_case: '',
    tokenomics_doc: null,
    token_distribution_doc: null
  });
  const [completeStep, setCompleteStep] = useState(0);
  const [notifyshowError, setNotifyError] = useState('');

  const TokenUseCaseSchema = Yup.object().shape({
    token_use_case: Yup.string().required('Token use case is required'),
    tokenomics_doc: Yup.mixed().required('Tokenomics document is required'),
    token_distribution_doc: Yup.mixed().required(
      'Token distribution document is required'
    )
  });

  const applicationData = props.applicationData.exchange_application;
  const [fileUrls, setFileUrls] = useState({});
  useEffect(() => {
    if (applicationData.token_use_case) {
      setInitialValue({
        token_use_case: applicationData.token_use_case
      });
    }
    if (applicationData.tokenomics_doc || applicationData.token_distribution_doc) {
      setFileUrls({
        ...fileUrls,
        tokenomics_doc: applicationData.tokenomics_doc,
        token_distribution_doc: applicationData.token_distribution_doc
      });
    }
    setCompleteStep(applicationData.percentage_complete);
  }, []);

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.token_use_case) {
          setInitialValue({
            token_use_case: application.exchange_application.token_use_case,
            tokenomics_doc: application.exchange_application.tokenomics_doc,
            token_distribution_doc:
              application.exchange_application.token_distribution_doc
          });
        }
        if (
          application.exchange_application.tokenomics_doc ||
          application.exchange_application.token_distribution_doc
        ) {
          setFileUrls({
            ...fileUrls,
            tokenomics_doc: application.exchange_application.tokenomics_doc,
            token_distribution_doc:
              application.exchange_application.token_distribution_doc
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values) => {
    if (fileUrls?.tokenomics_doc === undefined) {
      setNotifyError('Tokenomics is Required');
      return;
    }
    if (fileUrls?.token_distribution_doc === undefined) {
      setNotifyError('Token Distribution is Required');
      return;
    }
    setNotifyError('');
    const { nextPage, applicationId } = props;
    const payloadData = new FormData();
    payloadData.append('token_use_case', values.token_use_case);
    payloadData.append('tokenomics_doc', values.tokenomics_doc);
    payloadData.append('token_distribution_doc', values.token_distribution_doc);
    let stepsRequired = Math.floor(StepsCalculator(3, completeStep, 86));
    payloadData.append('percentage_complete', stepsRequired);
    setIsLoading(true);

    UpdateExchangeApplication({ application_id: applicationId }, payloadData)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        props.setApplicationData(application);
        nextPage();
        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    setExchangeCompleteStep([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17]);
  };
  const calculateProgress = () => {
    return completeStep;
  };

  const ErrorFormik = () => {
    if (!!notifyshowError) {
      return (
        <div className={`text-left mt-1 text-danger`} style={{ fontSize: '12px' }}>
          <span>{notifyshowError}</span>
        </div>
      );
    }
    return <></>;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>Tokenomics</span>
      </div>
      <Container className='col-12 col-sm-auto pt-2'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={TokenUseCaseSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <Form>
                <div>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-1'>
                        <div
                          style={{
                            borderRadius: '8px',
                            border: '1px solid',
                            height: 47
                          }}
                          className='d-flex direction-row justify-content-between cursor-pointer align-items-center pl-1'>
                          <Label htmlFor='tokenomics' id='tokenomics_doc'>
                            <span
                              className='p-1 mb-2 rounded-circle'
                              style={{
                                color: '#134a87',
                                fontSize: 15,
                                cursor: 'pointer'
                              }}>
                              <img
                                src={
                                  values.tokenomics_doc || fileUrls.tokenomics_doc
                                    ? documentUploadedIcon
                                    : documentUploadIcon
                                }
                                alt='tokenomics'
                              />
                            </span>
                            <span className='font-size-12'>Tokenomics</span>
                          </Label>
                          <div>
                            {fileUrls.tokenomics_doc && (
                              <a
                                href={fileUrls.tokenomics_doc}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                View File
                              </a>
                            )}
                          </div>
                          <input
                            type='file'
                            id='tokenomics'
                            name='tokenomics_doc'
                            accept='application/pdf'
                            onChange={(event) => {
                              setFieldValue('tokenomics_doc', event.target.files[0]);
                              setFileUrls({
                                ...fileUrls,
                                tokenomics_doc: event.target.files[0]
                              });
                            }}
                            style={{ display: 'none' }}
                          />
                          <ReactTooltip
                            anchorId='tokenomics_doc'
                            place='bottom'
                            content={values.tokenomics_doc && values.tokenomics_doc.name}
                          />
                        </div>
                        {errors.tokenomics_doc && (
                          <div
                            style={{ fontSize: 12 }}
                            className='text-left mt-1 text-danger'>
                            {errors.tokenomics_doc}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-1'>
                        <div
                          style={{
                            borderRadius: '8px',
                            border: '1px solid',
                            height: 47
                          }}
                          className='d-flex direction-row cursor-pointer justify-content-between align-items-center pl-1'>
                          <Label htmlFor='token_distribution' id='token_distribution_doc'>
                            <span
                              className='p-1 mb-2 rounded-circle'
                              style={{
                                color: '#134a87',
                                fontSize: 15,
                                cursor: 'pointer'
                              }}>
                              <img
                                src={
                                  values.token_distribution_doc ||
                                  fileUrls.token_distribution_doc
                                    ? documentUploadedIcon
                                    : documentUploadIcon
                                }
                                alt='Token Distribution'
                              />
                            </span>
                            <span className='font-size-12'>Token Distribution</span>
                          </Label>
                          <div>
                            {fileUrls.token_distribution_doc && (
                              <a
                                href={fileUrls.token_distribution_doc}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                View File
                              </a>
                            )}
                          </div>
                          <input
                            type='file'
                            id='token_distribution'
                            name='token_distribution_doc'
                            accept='.pdf,.xls,.xlsx'
                            onChange={(event) => {
                              setFieldValue(
                                'token_distribution_doc',
                                event.target.files[0]
                              );
                              setFileUrls({
                                ...fileUrls,
                                token_distribution_doc: event.target.files[0]
                              });
                            }}
                            style={{ display: 'none' }}
                          />
                          <ReactTooltip
                            anchorId='token_distribution_doc'
                            place='bottom'
                            content={
                              values.token_distribution_doc &&
                              values.token_distribution_doc.name
                            }
                          />
                        </div>
                        {errors.token_distribution_doc && (
                          <div
                            style={{ fontSize: 12 }}
                            className='text-left mt-1 text-danger'>
                            {errors.token_distribution_doc}
                          </div>
                        )}
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <FormGroup className='mb-4 mt-1'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='token_use_case'
                            className='text-black font-weight-normal'>
                            {CONST.USE_CASES_OF_TOKENS}
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            type='textarea'
                            name='token_use_case'
                            placeholder={CONST.ENTER_DESCRIPTION}
                            values={values}
                            className='font-size-12'
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}></AuthInput>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <ErrorFormik />
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepSeventeen;
