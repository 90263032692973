import React, { useState } from 'react';
import { Input } from 'reactstrap';
import './inputcss.scss';

function AuthInput({
  type = 'text',
  values,
  name,
  placeholder,
  errors = {},
  touched = {},
  handleChange,
  className,
  isPassword,
  isIcon = true,
  iconName,
  disabledField = false,
  min,
  max,
  withTouchError = false,
  setFieldTouched = false,
  styles = {},
  customBorder = false
}) {
  const isError = errors[name] && touched[name];
  const withTouchErrorShow = withTouchError ? isError : errors[name];
  const [showPassword, setShowPassword] = useState(false);
  const inputStyles = {
    paddingRight: '30px',
    borderRadius: '8px',
    ...styles
  };

  return (
    <div className='has-wrapper'>
      <Input
        style={inputStyles}
        className={
          withTouchErrorShow
            ? `${className} border-danger`
            : customBorder
            ? ``
            : `${className} border-dark`
        }
        value={values[name]}
        placeholder={placeholder}
        rows='6'
        name={name}
        step={type === 'number' ? 0.01 : 0}
        type={showPassword ? 'text' : type}
        onChange={(e) => {
          if (!!setFieldTouched) {
            setFieldTouched(name, !e.target.value);
          }
          handleChange(e);
        }}
        disabled={disabledField}
        min={min}
        max={max}
      />
      {isIcon && (
        <span className='has-icon'>
          <i className={iconName}></i>
        </span>
      )}

      {isPassword && showPassword && (
        <span className='has-icon'>
          <i className='ri-eye-fill' onClick={() => setShowPassword(false)}></i>
        </span>
      )}

      {isPassword && !showPassword && (
        <span className='has-icon'>
          <i className='ri-eye-off-fill' onClick={() => setShowPassword(true)}></i>
        </span>
      )}

      {withTouchErrorShow && (
        <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
          {errors[name]}
        </div>
      )}
    </div>
  );
}

export default AuthInput;
