import React, { useEffect, useState } from 'react';
import { stringify } from 'query-string';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { notifyError } from '../../Custom/notification';
import {
  GetAllApplications,
  ProcessApplication
} from '../../services/Onramp/Application';
import get from 'lodash/get';
import { Col, Row } from 'reactstrap';
import { Paginator } from 'primereact/paginator';
import TableSortButton from '../../components/TableSortButton';
import GetApplication from './GetApplication';

function ApplicationsList({ history }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isStatusSectionCollapsed, setIsStatusSectionCollapsed] = useState(false);
  const [first, setFirst] = useState(0);
  const [page, setPage] = useState(1);
  const [order, setOrder] = useState(null);
  const [search, setSearch] = useState(null);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [allData, setAllData] = useState([]);

  const [statusSuggestions, setStatusSuggestions] = useState([
    {
      id: 'REQUEST_ACTION',
      name: 'Action',
      icon: 'fas fa-unlock',
      color: 'black',
      checked: false
    },
    {
      id: 'PROCESSING',
      name: 'Processing',
      icon: 'fas fa-minus-circle',
      color: 'orange',
      checked: false
    },
    {
      id: 'ACCEPT',
      name: 'Accept',
      icon: 'fas fa-check-circle',
      color: 'green',
      checked: false
    },
    { id: 'KYB', name: 'KYB', icon: 'fas fa-lock', color: 'black', checked: false },
    {
      id: 'QUOTE',
      name: 'Quote',
      icon: 'fas fa-quote-left',
      color: 'black',
      checked: false
    },
    {
      id: 'PAID',
      name: 'Paid',
      icon: 'fas fa-thumbs-up',
      color: 'green',
      checked: false
    },
    {
      id: 'ADD_QUESTIONS',
      name: 'Add Question',
      icon: 'fas fa-question',
      color: 'black',
      checked: false
    },
    {
      id: 'UNREAD',
      name: 'Unread',
      icon: 'fas fa-envelope',
      color: 'black',
      checked: false
    }
  ]);

  const handleApplication = (application) => {
    if (!application.sub_application) {
      ProcessApplication(application._id).then((res) => {
        const id = get(res, 'data.data.data.sub_application_id', '');
        history.push(
          `/application/view?${stringify({
            application_id: id
          })}`
        );
      });
    } else {
      history.push(
        `/application/view?${stringify({
          application_id: application.sub_application._id
        })}`
      );
    }
  };

  const getApplications = (params, paramsData) => {
    const status = statusSuggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);

    setIsLoaded(false);
    GetAllApplications(params, {
      page,
      order,
      search,
      ...paramsData,
      status
      // priority
    })
      .then((res) => {
        setAllData(get(res, 'data.data.data.applications', []));
        const slicedData = get(res, 'data.data.data.applications', []).slice(
          first,
          first + 10
        );
        setCurrentPageData(slicedData);
        setTotalCount(get(res, 'data.data.data.count', []));
        setIsLoaded(true);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    const params = {
      status: 'COMPLETE',
      application_type: 'ONRAMP'
    };
    getApplications(params);
  }, [order, search, statusSuggestions]);

  const toggleStatusSection = () => {
    setIsStatusSectionCollapsed(!isStatusSectionCollapsed);
  };

  const handleStatusCheckboxChange = (id) => {
    const updatedSuggestions = statusSuggestions.map((suggestion) => {
      if (suggestion.id === id) {
        return { ...suggestion, checked: !suggestion.checked };
      }
      return suggestion;
    });

    setPage(1);
    setStatusSuggestions(updatedSuggestions);
  };

  const searchFunction = (search) => {
    setSearch(search);
  };

  const onPageChange = (event) => {
    setFirst(event.first);
    const newPageData = allData.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row>
          <Col lg={2} md={2} sm={2}>
            <div className='card'>
              <div className='px-3 pt-3'>
                <span className='font-size-16'>
                  <i className='mdi mdi-filter'></i> Filters
                </span>
                <br />
                <span
                  className='font-size-14 cursor-pointer'
                  onClick={toggleStatusSection}>
                  <i
                    className={`mdi mdi-chevron-${
                      isStatusSectionCollapsed === true ? 'right' : 'down'
                    }`}
                  />
                  Select Status
                </span>
                <div
                  className={`mb-3 filter-section ${
                    isStatusSectionCollapsed ? 'collapsed' : ''
                  }`}>
                  {statusSuggestions.map((suggestion) => (
                    <div
                      key={suggestion.id}
                      className='d-flex align-items-center ml-3'
                      style={{ wordBreak: 'break-all' }}>
                      <input
                        type='checkbox'
                        checked={suggestion.checked}
                        style={{ height: 16, width: 16 }}
                        onChange={() => handleStatusCheckboxChange(suggestion.id)}
                      />
                      <i
                        className={`${suggestion.icon} font-size-14 ml-1`}
                        style={{
                          color: `${suggestion.color}`
                        }}></i>
                      <span className='font-size-12 mr-2 ml-1'>{suggestion.name}</span>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </Col>
          <Col lg={10} md={10} sm={10}>
            <div className='card'>
              <div className='p-4'>
                <div className='col-12 col-sm-auto font-size-22 font-weight-medium pb-2 pb-sm-0'>
                  <div className='d-flex justify-content-between'>
                    <span className='text-black ml-2'>Your Applications</span>
                  </div>
                  {/* <Row>
                    <Col lg={12} md={12} sm={12} className='mt-2'>
                      <input
                        type='text'
                        className='form-control form-control-search'
                        placeholder='Search by Project, Email, Network, Telegram'
                        onKeyPress={(event) => {
                          if (event.key === 'Enter') {
                            searchFunction(event.target.value);
                          }
                        }}
                        autoFocus={true}
                      />
                    </Col>
                  </Row> */}
                  <div className='table-responsive'>
                    <table className='table table-centered table-hover'>
                      <thead className='center-text'>
                        <tr className='font-size-12 text-muted'>
                          <th className='pl-4 pt-3 pb-4 border-0'>#</th>
                          <th className='pt-3 pb-4 border-0'>Project Name</th>
                          <th className='pt-3 pb-4 border-0'>Legal Entity Name</th>
                          <th className='pt-3 pb-4 border-0'>Business Category</th>
                          <th className='pr-4 pt-3 pb-4 border-0'>Network</th>
                          <th className='pt-3 pb-4 border-0'>Sub Application Status</th>
                          <th className='pt-3 pb-4 border-0'>
                            <div className='d-flex align-items-center center-text justify-content-center'>
                              <span className='center-text'>Date Sent</span>
                              <TableSortButton
                                className='ml-1'
                                value={order}
                                topValue='created_at_desc'
                                bottomValue='created_at_asc'
                                onChange={(v) => {
                                  setPage(1);
                                  setOrder(v);
                                }}
                              />
                            </div>
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {!isLoaded ? (
                          <tr>
                            <td colSpan={12}>
                              <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                                <i
                                  className='fas fa-circle-notch fa-spin text-muted'
                                  style={{ fontSize: 48 }}></i>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          <>
                            {currentPageData.map((application, key) => (
                              <tr
                                key={key}
                                onClick={() => handleApplication(application)}
                                className='cursor-pointer center-text font-size-12 font-weight-medium'>
                                <GetApplication
                                  getApplications={getApplications}
                                  handleApplication={handleApplication}
                                  application={application}
                                  index={key}
                                  page={page}
                                  setPage={setPage}
                                />
                              </tr>
                            ))}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                <Paginator
                  rows={10}
                  totalRecords={totalCount}
                  first={first}
                  onPageChange={(e) => onPageChange(e)}
                />
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default ApplicationsList;
