import React, { useState } from 'react';
import { FixedSizeList } from 'react-window';

function DefaultFiats({ fiats = [], onClose = () => {}, onSelect = () => {} }) {
  const [search, setSearch] = useState('');
  const [showFiatsSelected, setShowFiatsSelected] = useState(false);
  const [selectedFiat, setSelectedFiat] = useState(null);

  const filteredFiats = fiats.filter((item) => {
    return (
      item.country_name?.toLowerCase().includes(search.toLowerCase()) ||
      item.currency?.toLowerCase().includes(search.toLowerCase()) ||
      item.fiats.some(
        (fiat) =>
          fiat.currency?.toLowerCase().includes(search.toLowerCase()) ||
          fiat.currencyName?.toLowerCase().includes(search.toLowerCase())
      )
    );
  });

  const backToCountryList = () => {
    setShowFiatsSelected(false);
  };
  const Row = React.memo(({ index, style }) => {
    return (
      <GetFiat
        key={index}
        style={style}
        setShowFiatsSelected={setShowFiatsSelected}
        onSelect={onSelect}
        fiat={filteredFiats[index]}
        setSelectedFiat={setSelectedFiat}
      />
    );
  });

  return (
    <div className={'py-3 '} style={{ background: `var(--light-container-color)` }}>
      {!showFiatsSelected ? (
        <>
          <div className='d-flex align-items-center text-truncate mb-3 mx-3'>
            <div
              className='font-size-18 font-weight-bold flex-fill text-center'
              style={{ color: `var(--light-primary-text-color)` }}>
              Select Country of Residence
            </div>
          </div>
          <i
            type='button'
            onClick={onClose}
            className='btn btn-link p-0 position-absolute font-size-24 mdi mdi-close'
            style={{
              right: 16,
              top: 14,
              zIndex: 1,
              color: `var(--light-primary-text-color)`
            }}></i>
          <div className='mx-3 px-3 pb-3'>
            <input
              type='text'
              className='form-control form-control-search mx-1'
              placeholder='Search'
              value={search}
              onInput={(e) => setSearch(e.target.value)}
              onChange={(e) => setSearch(e.target.value)}
              style={{
                minWidth: '200px',
                maxWidth: '100%',
                color: `var(--light-primary-text-color)`
              }}
            />
          </div>
          <FixedSizeList
            className='scroll-1'
            innerElementType='ul'
            itemCount={filteredFiats.length}
            itemSize={60}
            height={400}>
            {({ index, style }) => {
              return (
                <li style={style}>
                  <Row index={index} style={style} />
                </li>
              );
            }}
          </FixedSizeList>
        </>
      ) : (
        <CurrencyList
          onSelect={onSelect}
          selectedFiat={selectedFiat}
          backToCountryList={backToCountryList}
          onClose={onClose}
        />
      )}
    </div>
  );
}

export default DefaultFiats;

export const GetFiat = ({ onSelect, fiat, setShowFiatsSelected, setSelectedFiat }) => {
  const [isHovered, setIsHovered] = useState(false);

  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      style={{
        background: isHovered
          ? `var(--light-primary-text-color)`
          : `var(--light-container-color)`,
        color: isHovered
          ? `var(--light-container-color)`
          : `var(--light-primary-text-color)`
      }}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className='d-flex align-items-center cursor-pointer rounded py-2 px-3 mx-3'
      onClick={() => {
        if (fiat.fiats && fiat.fiats.length > 1) {
          setShowFiatsSelected(true);
          setSelectedFiat(fiat.fiats);
        } else {
          onSelect(fiat);
        }
      }}>
      <img
        src={fiat.country_logo}
        alt={fiat.country}
        height={36}
        width={36}
        className='circle-image'
      />
      <div className='ml-2'>
        <div
          className='font-size-14'
          style={{
            overflowWrap: 'break-word',
            width: '220px',
            color: isHovered
              ? `var(--light-container-color)`
              : `var(--light-primary-text-color)`
          }}>
          {fiat.country_name}
        </div>
        <div className='font-size-12 mt-1'>
          {fiat.fiats.map((item, index) => (
            <span key={index}>
              {item.currency}
              {index !== fiat.fiats.length - 1 ? ', ' : ''}
            </span>
          ))}
        </div>
      </div>

      <div className='ml-auto badge font-size-12'>
        <i className='mdi mdi-chevron-right font-size-22' />
      </div>
    </div>
  );
};

export const CurrencyList = ({
  selectedFiat,
  onSelect,
  backToCountryList,
  widgetTheme
}) => {
  const Row = React.memo(({ index, style }) => {
    return (
      <SelectFiat
        onSelect={onSelect}
        style={style}
        fiat={selectedFiat[index]}
        widgetTheme={widgetTheme}
      />
    );
  });

  return (
    <div className={'py-1 '} style={{ background: `var(--light-container-color)` }}>
      <div className='d-flex align-items-center mb-3 mx-3'>
        <i
          className='mdi mdi-chevron-left font-size-22 cursor-pointer'
          onClick={backToCountryList}
        />
        <div
          className='font-size-18 font-weight-bold text-center flex-fill'
          style={{ color: `var(--light-primary-text-color)` }}>
          Currency
        </div>
      </div>
      <FixedSizeList
        className='scroll-1'
        innerElementType='ul'
        itemCount={selectedFiat.length}
        itemSize={60}
        height={435}>
        {({ index, style }) => {
          return (
            <li style={style}>
              <Row index={index} style={style} />
            </li>
          );
        }}
      </FixedSizeList>
    </div>
  );
};

export const SelectFiat = ({ onSelect, fiat }) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      className='d-flex align-items-center text-truncate cursor-pointer rounded py-1 px-3 mx-2'
      style={{
        background: isHovered
          ? `var(--light-primary-text-color)`
          : `var(--light-container-color)`,
        color: isHovered
          ? `var(--light-container-color)`
          : `var(--light-primary-text-color)`
      }}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onClick={() => {
        onSelect(fiat);
      }}>
      <span
        className='d-inline-block rounded-circle d-flex align-items-center justify-content-center font-size-16 ml-1 my-1 mr-3'
        style={{
          height: 36,
          minWidth: 36,
          background: '#0052b4',
          color: 'white'
        }}>
        <span className='font-size-12'>{fiat.symbol}</span>
      </span>
      <div>
        <span
          className='font-size-16 text-uppercase mr-1 font-weight-medium'
          style={{
            color: isHovered
              ? `var(--light-container-color)`
              : `var(--light-primary-text-color)`
          }}>
          {fiat.currency}
        </span>
        <br />
        <span className='font-size-12 text-uppercase mr-1'>{fiat.currencyName}</span>
      </div>
    </div>
  );
};
