import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';

function FiatSelect({ className, fiats = [], onClose = () => {}, onSelect = () => {} }) {
  const [search, setSearch] = useState('');
  const filteredFiats = (Array.isArray(fiats) ? fiats : []).filter(
    ({ symbol, currencyName, currency }) =>
      [symbol, currencyName, currency]
        .join('')
        .toLowerCase()
        .includes((search || '').toLowerCase())
  );

  return (
    <div className={'py-3 ' + className}>
      <div className="d-flex align-items-center text-truncate mb-3 mx-3">
        <div className="font-size-20 font-weight-bold flex-fill text-center">
          Select Currency
        </div>
      </div>
      <button
        type="button"
        className="btn btn-link p-0 position-absolute"
        style={{ right: 16, top: 18, zIndex: 1 }}
        onClick={onClose}>
        <CloseIcon height={24} className="" />
      </button>
      <div className="mx-3 px-3 pb-3">
        <input
          type="text"
          className="form-control form-control-search mx-1"
          placeholder="Search"
          value={search}
          onInput={(e) => setSearch(e.target.value)}
          onChange={(e) => setSearch(e.target.value)}
          style={{ minWidth: '200px', maxWidth: '100%' }}
        />
      </div>
      <SimpleBar style={{ maxHeight: 'calc(75vh - 110px)' }}>
        {filteredFiats.map((fiat, i) => (
          <div
            key={i}
            className="d-flex align-items-center text-truncate cursor-pointer rounded hover:bg-gray-900 hover:text-white py-2 px-3 mx-3"
            onClick={() => onSelect(fiat)}>
            <span
              className="d-inline-block rounded-circle d-flex align-items-center justify-content-center font-size-16 text-white ml-1 my-1 mr-3"
              style={{ height: 36, minWidth: 36, backgroundColor: '#0052B4' }}>
              <span className="font-size-12">{fiat.symbol}</span>
            </span>
            <div className="font-size-18 mr-1">
              {fiat.currencyName} ({fiat.currency})
            </div>
          </div>
        ))}
      </SimpleBar>
    </div>
  );
}

export default FiatSelect;
