export const DevURL = `${process.env.REACT_APP_BACKEND_RNALYS_USER_HOST}/api/`;
// export const DevURL = 'http://localhost:3004/api/';

export const ADMIN_URL = 'admin/';
export const BUSINESS_URL = 'business/';
export const USER_URL = 'user/';

// ? Authentication URL
export const LOGIN_URL = USER_URL + 'login';
export const REGISTER_URL = USER_URL + 'signup';
export const CLONE_TO_PROD = USER_URL + 'clone_to_prod';
export const FORGOT_PASSWORD = USER_URL + 'forgot_password';
export const RESET_PASSWORD = USER_URL + 'change_password';
export const DELETE_USER_ACCOUNT = USER_URL + 'me';
export const VERIFICATION = USER_URL + 'verify';
export const RESEND_VERIFICATION = USER_URL + 'resend_verification_code';
export const VERIFY_OTP = USER_URL + 'verify_otp';
export const MY_PROFILE = USER_URL + 'me';
export const GET_STREAM_USER = USER_URL + 'get_user_data';
export const QUERY_CHANNEL = USER_URL + 'query_channel';
export const CREATE_STREAM_USER = USER_URL + 'sign_up_stream';
export const CREATE_STREAM_CHANNEL = USER_URL + 'exchange_create_channel';
export const GET_STREAM_TOKEN = USER_URL + 'get_token';
export const STREAM_SYSTEM_MESSAGE = USER_URL + 'stream_system_message';

export const INVITE_REVIEWER = USER_URL + 'invite_reviewer';

export const GET_BUSINESS_STREAM_TOKEN = BUSINESS_URL + 'stream/get_token';
export const GET_BUSINESS_STREAM_USER = BUSINESS_URL + 'stream/get_user_data';

export const INVITE_USER = USER_URL + 'invite';
export const VERIFY_INVITE = USER_URL + 'verify_invite';

// ? Admin
export const PROVIDER = ADMIN_URL + 'provider';
export const PROVIDER_DETAIL = PROVIDER + '/detail';
export const RAMP_LIST = ADMIN_URL + 'users/on_ramps';
export const EXCHANGE_LIST = ADMIN_URL + 'users/exchange_info';
export const INVESTORS_NAME = ADMIN_URL + 'users/investor_tier';
export const REVENUE_PERCENTAGE = ADMIN_URL + 'users/revenue_percentage';

// ? Referral
export const WIDGET_REFERRAL = USER_URL + 'widget_referral';
export const WIDGET_REFERRAL_USERS = USER_URL + 'widget_referral_users';
export const REFERRAL = USER_URL + 'referrals';
export const REFERRAL_LIST = USER_URL + 'referrals_list';
export const REFERRAL_CODE = USER_URL + 'referral_code';

// ? Business
export const BEST_ASSETS_BUY = BUSINESS_URL + 'best_assets/buy';
export const API_KEY = BUSINESS_URL + 'api_key';

export const URL = DevURL;
