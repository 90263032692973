import React, { useEffect, useState } from 'react';
import { Card, Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { useQueryParam, StringParam } from 'use-query-params';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import {
  EditOnRampApplication,
  GetApplication,
  UploadApplicationDocs
} from '../../../services/Business/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError, notifySuccess } from '../../../Custom/notification';
import get from 'lodash/get';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import moment from 'moment';
import BeneficiaryUserModal from '../OnRampApplication/BeneficiaryUserModal';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';
import { useHistory, useLocation } from 'react-router-dom';
import EditExchangeSubApplication from '../../ExchangeApplication/ViewExchangeApplication/EditSubApplication';

const INITIAL_STATE = {
  legal_entity_name: '',
  project_name: '',
  legal_entity_register_number: '',
  website: '',
  business_category: '',
  country_of_incorporation: '',
  token_address: '',
  product_description: '',
  product_custodial: '',
  user_access_to_wallet: '',
  pre_populate_wallet_address: '',
  crypto_to_other_party: '',
  describe_ramp_services: '',
  regulatory_auth: '',
  AML_CTF_compliance: '',
  KYC_checks_on_users: '',
  users_screening: '',
  services_to_minors: '',
  consent_info: '',
  consent_OFAC: '',
  consent_region: '',
  consent_region_name: '',
  consent_age: '',
  applicant_email: '',
  applicant_role: '',
  applicant_full_name: '',
  application_date: ''
};
const TickerSchema = Yup.object().shape({
  ticker_name: Yup.string().min(2, ERROR_CONST.NAME).max(5, ERROR_CONST.NAME)
});
const GeneralInfoSchema = Yup.object()
  .shape({
    legal_entity_name: Yup.string().required(ERROR_CONST.COMPANY_NAME),
    project_name: Yup.string()
      .required(ERROR_CONST.PROJECT_NAME)

      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
    legal_entity_register_number: Yup.string()
      .required(ERROR_CONST.REGISTER_NUMBER)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
    website: Yup.string().required(ERROR_CONST.WEBSITE),
    business_category: Yup.string().required(ERROR_CONST.BUSINESS_CATEGORY),
    country_of_incorporation: Yup.string().required(ERROR_CONST.COUNTRY),
    token_address: Yup.string(),
    product_description: Yup.string().required(ERROR_CONST.DESCRIPTION),
    ticker: Yup.string(),
    ticker_logo: Yup.string(),
    product_custodial: Yup.string().required('Field is required'),
    user_access_to_wallet: Yup.string().required('Field is required'),
    pre_populate_wallet_address: Yup.string().required('Field is required'),
    crypto_to_other_party: Yup.string().required('Field is required'),
    describe_ramp_services: Yup.string().required('Field is required'),
    regulatory_auth: Yup.string().required('Field is required'),
    AML_CTF_compliance: Yup.string().required('Field is required'),
    KYC_checks_on_users: Yup.string().required('Field is required'),
    users_screening: Yup.string().required('Field is required'),
    services_to_minors: Yup.string().required('Field is required'),
    consent_info: Yup.string().required('Field is required'),
    consent_OFAC: Yup.string().required('Field is required'),
    consent_region: Yup.string().required('Field is required'),
    consent_region_name: Yup.string('Field is required'),
    consent_age: Yup.string().required('Field is required'),
    applicant_email: Yup.string().required('Email is required'),
    applicant_role: Yup.string().required('Role is required'),
    applicant_full_name: Yup.string().required('Full Name is required'),
    application_date: Yup.string().required('Application Date is required')
  })
  .concat(TickerSchema);

function EditSubApplication(props) {
  const history = useHistory();
  const location = useLocation();
  const responses = props && props.location && props.location.state;
  const [applicationId] = useQueryParam('sub_application_id', StringParam);
  const [buyCheck, setBuyCheck] = useState(false);
  const [sellCheck, setSellCheck] = useState(false);
  const [initialValue, setInitialValue] = useState(INITIAL_STATE);
  const [optionalFile, setOptionalFile] = useState({ additional_docs: [] });
  const [fileUrls, setFileUrls] = useState({});
  const [userData, setUserData] = useState([]);
  const [userModal, setUserModal] = useState(false);
  const [user, setUser] = useState();
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [fields, setFields] = useState({});
  const [completedfields, setCompletedFields] = useState([]);
  const [beneficialUsersData, setBeneficialUsersData] = useState();
  const [completedBeneficialUsersData, setCompletedBeneficialUsersData] = useState([]);
  const [applicationTypeData, setApplicationTypeData] = useState([]);
  if (!applicationId) {
    history.push('/applications');
  }

  const generateFieldComparison = (values) => {
    return Object.keys(initialValue).reduce((acc, field) => {
      if (initialValue[field] !== values[field]) {
        acc[field] = values[field];
      }
      return acc;
    }, {});
  };
  const setInitialApplicationValues = (application) => {
    const data = {
      business_application_id: application.business_application_id,
      legal_entity_name: application.legal_entity_name,
      project_name: application.project_name,
      legal_entity_register_number: application.legal_entity_register_number,
      website: application.website,
      business_category: application.business_category,
      country_of_incorporation: application.country_of_incorporation,
      token_address: application.token_address,
      product_description: application.product_description,
      product_custodial: application.product_custodial.toString(),
      user_access_to_wallet:
        application.user_access_to_wallet !== null
          ? application.user_access_to_wallet.toString()
          : 'null',
      pre_populate_wallet_address:
        application.pre_populate_wallet_address !== null
          ? application.pre_populate_wallet_address.toString()
          : 'null',
      crypto_to_other_party:
        application.crypto_to_other_party !== null
          ? application.crypto_to_other_party.toString()
          : 'null',
      describe_ramp_services: application.describe_ramp_services,
      regulatory_auth: application.regulatory_authorisation_requirement.toString(),
      users_screening:
        application.sanctions_screening_of_users !== null
          ? application.sanctions_screening_of_users.toString()
          : 'null',
      AML_CTF_compliance:
        application.AML_CTF_compliance !== null
          ? application.AML_CTF_compliance.toString()
          : 'null',
      KYC_checks_on_users:
        application.KYC_checks_on_users !== null
          ? application.KYC_checks_on_users.toString()
          : null,
      services_to_minors:
        application.services_to_minors !== null
          ? application.services_to_minors.toString()
          : null,
      consent_info:
        application.consent_info !== null ? application.consent_info.toString() : null,
      consent_OFAC:
        application.consent_OFAC !== null ? application.consent_OFAC.toString() : null,
      consent_age:
        application.consent_age !== null ? application.consent_age.toString() : null,
      consent_region:
        application.consent_region !== null
          ? application.consent_region.toString()
          : null,
      consent_region_name: application.consent_region_name
        ? application.consent_region_name
        : null,
      applicant_email: application.applicant_email ? application.applicant_email : null,
      applicant_role: application.applicant_role ? application.applicant_role : null,
      applicant_full_name: application.applicant_full_name
        ? application.applicant_full_name
        : null,
      application_date: application.application_date
        ? moment(application.application_date).format('YYYY-MM-DD')
        : null
    };
    setInitialValue(data);
    return data;
  };

  const setInitialFileUrls = (application) => ({
    whitepapers_doc: application.whitepapers_doc ? application.whitepapers_doc : '',
    integration_flows_doc: application.integration_flows_doc
      ? application.integration_flows_doc
      : '',
    flow_of_funds_diagram_doc: application.flow_of_funds_diagram_doc
      ? application.flow_of_funds_diagram_doc
      : '',
    corporate_registration_doc: application.corporate_registration_doc
      ? application.corporate_registration_doc
      : '',
    terms_of_service_doc: application.terms_of_service_doc
      ? application.terms_of_service_doc
      : '',
    privacy_policy_doc: application.privacy_policy_doc
      ? application.privacy_policy_doc
      : '',
    AML_CTF_policy_doc: application.AML_CTF_policy_doc
      ? application.AML_CTF_policy_doc
      : '',
    licence_doc: application.licence_doc ? application.licence_doc : '',
    additional_docs: application.additional_docs ? application.additional_docs : '',
    legal_opinion_doc: application.legal_opinion_doc ? application.legal_opinion_doc : '',
    policy_procedures_doc: application.policy_procedures_doc
      ? application.policy_procedures_doc
      : ''
  });

  const buildPayloadData = (optionalFile) => {
    const payloadData = new FormData();
    Object.keys(optionalFile).forEach((key) => {
      if (key === 'additional_docs' && optionalFile.additional_docs[0]) {
        let docs = Array.from(optionalFile.additional_docs);
        docs.forEach((doc) => payloadData.append('additional_docs', doc[0]));
      } else {
        payloadData.append(key, optionalFile[key]);
      }
    });
    return payloadData;
  };

  const fetchApplicationData = async () => {
    const res = await GetApplication({ sub_application_id: applicationId });
    return get(res, 'data.data.data[0]', '');
  };

  const addUser = (users) => {
    setUser(users);
    setUserModal(!userModal);
  };

  const changeHandler = (event) => {
    if (event.target.files[0]) {
      const isAdditionalDocs = event.target.name === 'additional_docs';
      const optionalFileUpdates = isAdditionalDocs
        ? {
            ...optionalFile,
            [event.target.name]: [...optionalFile['additional_docs'], event.target.files]
          }
        : { ...optionalFile, [event.target.name]: event.target.files[0] };

      setOptionalFile(optionalFileUpdates);
    }
  };

  const handleSubmit = async (values) => {
    try {
      const responseId = location.state;
      const data = generateFieldComparison(values);
      const payloadData = buildPayloadData(optionalFile);

      if (applicationId && applicationId !== '') {
        await uploadAndUpdateApplication(data, payloadData, responseId);
        navigateToViewApplication();
        notifySuccess('Response updated successfully');
      }
    } catch (err) {
      const errorMessage = get(err, 'data.error.message');
      notifyError(notificationMessageFunction(errorMessage));
    }
  };

  const fetchApplication = async () => {
    try {
      setLoading(true);
      const data = location.state;
      await setFieldData(data);
      const application = await fetchApplicationData();
      setApplicationTypeData(application);
      setApplicationData(application);
      setLoading(false);
      setIsLoaded(true);
    } catch (error) {
      handleError(error);
    }
  };

  const setFieldData = async (data) => {
    setFields(data['field']);
    setBeneficialUsersData(data['field'].beneficialUsers);
    setCompletedBeneficialUsersData(data.completed_beneficiary);
    setCompletedFields(data['completed_field'] ? data['completed_field'] : []);
  };

  const setApplicationData = (application) => {
    setInitialApplicationValues(application);
    setBuyCheck(application.product_enable_buy);
    setSellCheck(application.product_enable_sell);
    setUserData(application.beneficial_ownership);
    setFileUrls(setInitialFileUrls(application));
  };

  const handleError = (error) => {
    setLoading(false);
    setIsLoaded(true);
    const errorMessage = get(error, 'data.error.message');
    if (errorMessage !== undefined) {
      notifyError(notificationMessageFunction(errorMessage));
    }
  };

  const uploadAndUpdateApplication = async (data, payloadData, responseId) => {
    try {
      await Promise.all([
        UploadApplicationDocs(
          {
            sub_application_id: applicationId,
            onramp_response_id: responseId.onramp_response_id
          },
          payloadData
        ),
        EditOnRampApplication(applicationId, responseId.onramp_response_id, data)
      ]);

      history.push(
        `/application/view?application_id=${
          initialValue && initialValue.business_application_id
        }`
      );
    } catch ([uploadError, editError]) {
      notifyError(notificationMessageFunction(get(uploadError, 'data.error.message')));
      notifyError(notificationMessageFunction(get(editError, 'data.error.message')));
    }
  };

  const navigateToViewApplication = () => {
    history.push(
      `/application/view?application_id=${
        initialValue && initialValue.business_application_id
      }`
    );
  };

  useEffect(() => {
    if (applicationId !== '') {
      fetchApplication();
    }
  }, [userModal]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row className='px-1'>
          <Col lg={9} md={12} sm={12}>
            <Card>
              <div className='pt-3 pl-3'>
                <button
                  type='button'
                  className='btn btn-light p-1'
                  style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
                  onClick={() => {
                    const applicationType = applicationTypeData.application_type;
                    if (applicationType === 'EXCHANGE') {
                      history.push(
                        `/application/viewExchange?application_id=${
                          initialValue.business_application_id ||
                          applicationTypeData.business_application_id
                        }`
                      );
                    } else {
                      history.push(
                        `/application/view?application_id=${
                          initialValue.business_application_id ||
                          applicationTypeData.business_application_id
                        }`
                      );
                    }
                  }}>
                  <ArrowLeftIcon width={18} height={18} />
                </button>
              </div>
              <div className='px-4 pb-4 pt-1'>
                {!isLoaded ? (
                  <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                    <i
                      className='fas fa-circle-notch fa-spin text-muted'
                      style={{ fontSize: 48 }}></i>
                  </div>
                ) : (
                  <Container className=''>
                    {applicationTypeData.application_type !== 'EXCHANGE' ? (
                      <Formik
                        enableReinitialize={true}
                        initialValues={initialValue}
                        validationSchema={GeneralInfoSchema}
                        onSubmit={handleSubmit}>
                        {({ errors, touched, values, handleChange }) => (
                          <Form>
                            {completedfields &&
                              ((!completedfields.includes('legal_entity_name') &&
                                fields.hasOwnProperty('legal_entity_name')) ||
                                (!completedfields.includes('project_name') &&
                                  fields.hasOwnProperty('project_name')) ||
                                (!completedfields.includes('business_category') &&
                                  fields.hasOwnProperty('business_category')) ||
                                (!completedfields.includes(
                                  'legal_entity_register_number'
                                ) &&
                                  fields.hasOwnProperty(
                                    'legal_entity_register_number'
                                  )) ||
                                (!completedfields.includes('website') &&
                                  fields.hasOwnProperty('website')) ||
                                (!completedfields.includes('country_of_incorporation') &&
                                  fields.hasOwnProperty('country_of_incorporation')) ||
                                (!completedfields.includes('products') &&
                                  fields.hasOwnProperty('products')) ||
                                (!completedfields.includes('token_address') &&
                                  fields.hasOwnProperty('token_address')) ||
                                (!completedfields.includes('product_description') &&
                                  fields.hasOwnProperty('product_description'))) && (
                                <CustomHeaders headerName='General Application' />
                              )}
                            <div className='mt-4 ml-2'>
                              <Row>
                                {completedfields &&
                                  !completedfields.includes('legal_entity_name') &&
                                  fields.hasOwnProperty('legal_entity_name') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='legal_entity'
                                            className='text-black font-weight-normal'>
                                            {CONST.LEGAL_ENTITY}
                                          </Label>
                                        </div>

                                        <div>
                                          <AuthInput
                                            type='text'
                                            className='font-size-12'
                                            name='legal_entity_name'
                                            values={values}
                                            handleChange={handleChange}
                                            placeholder={CONST.ENTER_LEGAL_ENTITY}
                                            errors={errors}
                                            touched={touched}></AuthInput>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                                {completedfields &&
                                  !completedfields.includes('project_name') &&
                                  fields.hasOwnProperty('project_name') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='project_name'
                                            className='text-black font-weight-normal'>
                                            {CONST.PROJECT_NAME}
                                          </Label>
                                        </div>
                                        <div>
                                          <AuthInput
                                            type='text'
                                            className='font-size-12'
                                            name='project_name'
                                            placeholder={CONST.ENTER_PROJECT_NAME}
                                            values={values}
                                            handleChange={handleChange}
                                            errors={errors}
                                            touched={touched}></AuthInput>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                                {completedfields &&
                                  !completedfields.includes('business_category') &&
                                  fields.hasOwnProperty('business_category') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='business_category'
                                            className='text-black font-weight-normal'>
                                            {CONST.CATEGORY}
                                          </Label>
                                        </div>
                                        <div>
                                          <Input
                                            type='select'
                                            name='business_category'
                                            value={values['business_category']}
                                            className={
                                              errors['business_category']
                                                ? 'border-danger font-size-12'
                                                : 'border-dark font-size-12'
                                            }
                                            onChange={handleChange}>
                                            <option hidden>Choose Category</option>
                                            <option value='Blockchain'>Blockchain</option>
                                            <option value='NFT'>NFT</option>
                                            <option value='CeFi'>CeFi</option>
                                            <option value='DeFi'>DeFi</option>
                                            <option value='Gaming'>Gaming</option>
                                            <option value='Metaverse'>Metaverse</option>
                                            <option value='DAO'>DAO</option>
                                            <option value='Ecommerce'>Ecommerce</option>
                                            <option value='Enterprise'>Enterprise</option>
                                            <option value='Infrastructure'>
                                              Infrastructure
                                            </option>
                                            <option value='Wallet'>Wallet</option>
                                            <option value='Other'>Other</option>
                                          </Input>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['business_category'] &&
                                            touched['business_category'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['business_category']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}

                                {completedfields &&
                                  !completedfields.includes(
                                    'legal_entity_register_number'
                                  ) &&
                                  fields.hasOwnProperty(
                                    'legal_entity_register_number'
                                  ) && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='legal_entity_register_number'
                                            className='text-black font-weight-normal'>
                                            {CONST.REGISTRATION_NO}
                                          </Label>
                                        </div>
                                        <div>
                                          <AuthInput
                                            type='text'
                                            name='legal_entity_register_number'
                                            values={values}
                                            className='font-size-12'
                                            placeholder={CONST.ENTER_REGISTRATION_NO}
                                            handleChange={handleChange}
                                            errors={errors}
                                            touched={touched}></AuthInput>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                              </Row>
                              <Row>
                                {completedfields &&
                                  !completedfields.includes('website') &&
                                  fields.hasOwnProperty('website') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='website'
                                            className='text-black font-weight-normal'>
                                            {CONST.WEBSITE}
                                          </Label>
                                        </div>
                                        <div>
                                          <AuthInput
                                            type='text'
                                            name='website'
                                            className='font-size-12'
                                            values={values}
                                            placeholder={CONST.ENTER_PROJECT_WEBSITE}
                                            handleChange={handleChange}
                                            errors={errors}
                                            touched={touched}></AuthInput>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}

                                {completedfields &&
                                  !completedfields.includes('country_of_incorporation') &&
                                  fields.hasOwnProperty('country_of_incorporation') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='country'
                                            className='text-black font-weight-normal'>
                                            {CONST.COUNTRY}
                                          </Label>
                                        </div>
                                        <div>
                                          <AuthInput
                                            type='text'
                                            placeholder={CONST.ENTER_COUNTRY}
                                            name='country_of_incorporation'
                                            values={values}
                                            className='font-size-12'
                                            handleChange={handleChange}
                                            errors={errors}
                                            touched={touched}></AuthInput>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                              </Row>
                              <Row>
                                {completedfields &&
                                  !completedfields.includes('products') &&
                                  fields.hasOwnProperty('products') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='products'
                                            className='text-black font-weight-normal'>
                                            {CONST.PRODUCTS}
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='custom-control form-check custom-checkbox mr-3'>
                                            <Input
                                              name='product_buy_enable'
                                              type='checkbox'
                                              onChange={() => setBuyCheck(!buyCheck)}
                                              checked={buyCheck}
                                              value={buyCheck}
                                              className='form-check-input font-size-12'
                                            />
                                            <Label
                                              className='form-check-label'
                                              onClick={() => {
                                                setBuyCheck(!buyCheck);
                                              }}>
                                              {CONST.BUY}
                                            </Label>
                                          </div>
                                          <div className='custom-control form-check custom-checkbox'>
                                            <Input
                                              name='product_sell_enable'
                                              type='checkbox'
                                              value={sellCheck}
                                              className='form-check-input'
                                              onChange={() => setSellCheck(!sellCheck)}
                                              checked={sellCheck}
                                            />
                                            <Label
                                              className='form-check-label'
                                              onClick={() => {
                                                setSellCheck(!sellCheck);
                                              }}>
                                              {CONST.SELL}
                                            </Label>
                                          </div>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}

                                {completedfields &&
                                  !completedfields.includes('token_address') &&
                                  fields.hasOwnProperty('token_address') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='tokens'
                                            className='text-black font-weight-normal'>
                                            {CONST.TOKEN}
                                          </Label>
                                        </div>
                                        <div>
                                          <AuthInput
                                            type='text'
                                            className='font-size-12'
                                            name='token_address'
                                            values={values}
                                            placeholder={CONST.TOKEN_ADDRESS}
                                            handleChange={handleChange}
                                            errors={errors}
                                            touched={touched}></AuthInput>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                              </Row>
                            </div>
                            <Row>
                              {completedfields &&
                                !completedfields.includes('product_description') &&
                                fields.hasOwnProperty('product_description') && (
                                  <Col lg={12} md={12} sm={12}>
                                    <FormGroup className='mb-3'>
                                      <div className='font-size-12'>
                                        <Label
                                          htmlFor='description'
                                          className='text-black font-weight-normal'>
                                          {CONST.DESCRIPTION}
                                        </Label>
                                      </div>
                                      <div className='w-50'>
                                        <AuthInput
                                          type='textarea'
                                          name='product_description'
                                          placeholder='Write a description here'
                                          className={'border-dark font-size-12'}
                                          values={values}
                                          handleChange={handleChange}
                                          errors={errors}
                                          touched={touched}></AuthInput>
                                      </div>
                                    </FormGroup>
                                  </Col>
                                )}
                            </Row>

                            {beneficialUsersData &&
                              completedBeneficialUsersData.length !==
                                Object.keys(beneficialUsersData.length) && (
                                <>
                                  <CustomHeaders headerName='Beneficial Ownership' />
                                  {userData && (
                                    <div className=' align-items-center'>
                                      <div className='col-12 col-sm-auto font-size-12 font-weight-medium '>
                                        <div className='table-responsive'>
                                          <table className='table table-centered table-hover'>
                                            <thead>
                                              <tr className='font-size-12 text-muted'>
                                                <td className='pt-3 pb-4 border-0'>
                                                  Full Name
                                                </td>
                                                <td className='pt-3 pb-4 border-0'>
                                                  Date Of Birth
                                                </td>
                                                <td className='pt-3 pb-4 border-0'>
                                                  Country
                                                </td>
                                                <td className='pt-3 pb-4 border-0'>
                                                  % Ownership
                                                </td>
                                                <td className='pt-3 pb-4 border-0'>
                                                  Role
                                                </td>
                                                <td className='pt-3 pb-4 border-0'>
                                                  Identity Documents
                                                </td>
                                                <th className='pt-3 pb-4 border-0'>
                                                  Edit
                                                </th>
                                              </tr>
                                            </thead>
                                            <tbody>
                                              {userData &&
                                                userData.length > 0 &&
                                                userData.map((user, key) => {
                                                  const matchingValues =
                                                    fields.beneficialUsers?.reduce(
                                                      (values, matchingUser) => {
                                                        const isMatch =
                                                          completedBeneficialUsersData.some(
                                                            ({ id, field }) => {
                                                              return (
                                                                id === matchingUser.id &&
                                                                field ===
                                                                  Object.keys(
                                                                    matchingUser
                                                                  )[0]
                                                              );
                                                            }
                                                          );

                                                        if (!isMatch) {
                                                          if (
                                                            matchingUser.id === user._id
                                                          ) {
                                                            Object.keys(
                                                              matchingUser
                                                            ).forEach((key) => {
                                                              if (
                                                                matchingUser.hasOwnProperty(
                                                                  key
                                                                )
                                                              ) {
                                                                values[key] =
                                                                  matchingUser[key];
                                                              }
                                                            });
                                                          }
                                                        }
                                                        return values;
                                                      },
                                                      {}
                                                    );

                                                  if (
                                                    matchingValues &&
                                                    Object.keys(matchingValues).length > 0
                                                  ) {
                                                    return (
                                                      <tr
                                                        key={key}
                                                        className='font-size-12 font-weight-medium'>
                                                        <td className='pl-3'>
                                                          {matchingValues.full_name
                                                            ? `${user.full_name} (${matchingValues.full_name})`
                                                            : ''}
                                                        </td>
                                                        <td className='pl-3'>
                                                          {matchingValues.date_of_birth
                                                            ? `${moment(
                                                                user.date_of_birth
                                                              ).format('DD/MM/YYYY')} (${
                                                                matchingValues.date_of_birth
                                                              })`
                                                            : ''}
                                                        </td>
                                                        <td className='pl-3'>
                                                          {matchingValues.country
                                                            ? `${user.country} (${matchingValues.country})`
                                                            : ''}
                                                        </td>
                                                        <td className='pl-3'>
                                                          {matchingValues.ownership_percent
                                                            ? `${user.ownership_percent} (${matchingValues.ownership_percent})`
                                                            : ''}
                                                        </td>
                                                        <td className='pl-3'>
                                                          {matchingValues.role
                                                            ? `${user.role} (${matchingValues.role})`
                                                            : ''}
                                                        </td>
                                                        <td className='pl-3'>
                                                          {matchingValues.profile
                                                            ? `${user.profile} (${matchingValues.profile})`
                                                            : ''}
                                                        </td>
                                                        <td
                                                          className='pl-3 cursor-pointer'
                                                          onClick={() => addUser(user)}>
                                                          <i
                                                            className='mdi mdi-pencil'
                                                            style={{
                                                              fontSize: 24
                                                            }}></i>
                                                        </td>
                                                      </tr>
                                                    );
                                                  }
                                                  return null;
                                                })}
                                            </tbody>
                                          </table>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </>
                              )}

                            {completedfields &&
                              ((!completedfields.includes('product_custodial') &&
                                fields.hasOwnProperty('product_custodial')) ||
                                (!completedfields.includes('user_access_to_wallet') &&
                                  fields.hasOwnProperty('user_access_to_wallet')) ||
                                (!completedfields.includes(
                                  'pre_populate_wallet_address'
                                ) &&
                                  fields.hasOwnProperty('pre_populate_wallet_address')) ||
                                (!completedfields.includes('crypto_to_other_party') &&
                                  fields.hasOwnProperty('crypto_to_other_party')) ||
                                (!completedfields.includes('describe_ramp_services') &&
                                  fields.hasOwnProperty('describe_ramp_services')) ||
                                (!completedfields.includes('whitepapers_doc') &&
                                  fields.hasOwnProperty('whitepapers_doc')) ||
                                (!completedfields.includes('integration_flows_doc') &&
                                  fields.hasOwnProperty('integration_flows_doc')) ||
                                (!completedfields.includes('flow_of_funds_diagram_doc') &&
                                  fields.hasOwnProperty(
                                    'flow_of_funds_diagram_doc'
                                  ))) && (
                                <CustomHeaders headerName='Wallet and Fund Flow Details' />
                              )}
                            <div className='mt-3 ml-2'>
                              <Row>
                                {completedfields &&
                                  !completedfields.includes('product_custodial') &&
                                  fields.hasOwnProperty('product_custodial') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='product_custodial'
                                            className='text-black font-weight-normal'>
                                            {CONST.PRODUCT_CUSTODIAL_OR_NOT}
                                          </Label>
                                        </div>
                                        <div
                                          className='d-flex direction-row'
                                          role='group'
                                          aria-labelledby='my-radio-group'>
                                          <div className='d-flex font-size-12 align-items-center mr-4'>
                                            <Field
                                              name='product_custodial'
                                              type='radio'
                                              value='true'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='mt-1 ml-2'>
                                              {CONST.CUSTODIAL}
                                            </Label>
                                          </div>
                                          <div className='d-flex font-size-12 align-items-center ml-4'>
                                            <Field
                                              name='product_custodial'
                                              type='radio'
                                              value='false'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='mt-1 ml-2'>
                                              {CONST.NON_CUSTODIAL}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['product_custodial'] &&
                                            touched['product_custodial'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['product_custodial']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                                {completedfields &&
                                  !completedfields.includes('user_access_to_wallet') &&
                                  fields.hasOwnProperty('user_access_to_wallet') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='user_access_to_wallet'
                                            className='text-black font-weight-normal'>
                                            {CONST.ACCESS_CONTROL_FUNDS}
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='d-flex align-items-center font-size-12 mr-4'>
                                            <Field
                                              name='user_access_to_wallet'
                                              type='radio'
                                              value='true'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-1'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='user_access_to_wallet'
                                              type='radio'
                                              value='false'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2  mt-1'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='user_access_to_wallet'
                                              type='radio'
                                              value='null'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2  mt-1'>
                                              {CONST.NA}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['user_access_to_wallet'] &&
                                            touched['user_access_to_wallet'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['user_access_to_wallet']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}

                                {completedfields &&
                                  !completedfields.includes(
                                    'pre_populate_wallet_address'
                                  ) &&
                                  fields.hasOwnProperty(
                                    'pre_populate_wallet_address'
                                  ) && (
                                    <Col>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='pre_populate_wallet_address'
                                            className='text-black font-weight-normal'>
                                            Are you planning to auto-fill wallet addresses
                                            during integration?
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='d-flex align-items-center font-size-12 mr-4'>
                                            <Field
                                              name='pre_populate_wallet_address'
                                              type='radio'
                                              value='true'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='pre_populate_wallet_address'
                                              type='radio'
                                              value='false'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2  mt-2'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='pre_populate_wallet_address'
                                              type='radio'
                                              value='null'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2  mt-2'>
                                              {CONST.NA}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['pre_populate_wallet_address'] &&
                                            touched['pre_populate_wallet_address'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['pre_populate_wallet_address']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}

                                {completedfields &&
                                  !completedfields.includes('crypto_to_other_party') &&
                                  fields.hasOwnProperty('crypto_to_other_party') && (
                                    <Col>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='crypto_to_other_party'
                                            className='text-black font-weight-normal'>
                                            Will the cryptocurrency be directed somewhere
                                            other than the user's designated wallet?
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='d-flex align-items-center font-size-12 mr-4'>
                                            <Field
                                              name='crypto_to_other_party'
                                              type='radio'
                                              value='true'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='crypto_to_other_party'
                                              type='radio'
                                              value='false'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2  mt-2'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='crypto_to_other_party'
                                              type='radio'
                                              value='null'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2  mt-2'>
                                              {CONST.NA}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['crypto_to_other_party'] &&
                                            touched['crypto_to_other_party'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['crypto_to_other_party']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                              </Row>
                              <Row>
                                {completedfields &&
                                  !completedfields.includes('describe_ramp_services') &&
                                  fields.hasOwnProperty('describe_ramp_services') && (
                                    <Col lg={12} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='describe_ramp_services'
                                            className='text-black font-weight-normal'>
                                            Describe how you will be integrating an onramp
                                          </Label>
                                        </div>
                                        <div>
                                          <AuthInput
                                            type='textarea'
                                            name='describe_ramp_services'
                                            rows='6'
                                            className={'border-dark font-size-12'}
                                            placeholder={CONST.ENTER_DESCRIPTION}
                                            values={values}
                                            handleChange={handleChange}
                                            errors={errors}
                                            touched={touched}></AuthInput>
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                              </Row>
                              <Row>
                                {completedfields &&
                                  (fields.hasOwnProperty('whitepapers_doc') ||
                                    fields.hasOwnProperty('integration_flows_doc') ||
                                    fields.hasOwnProperty(
                                      'flow_of_funds_diagram_doc'
                                    )) && (
                                    <Col lg={12} md={12} sm={12}>
                                      <FormGroup className='mb-2'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='description'
                                            className='text-black font-weight-normal'>
                                            Optional
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row justify-content-between'>
                                          {!completedfields.includes('whitepapers_doc') &&
                                            fields.hasOwnProperty('whitepapers_doc') && (
                                              <div>
                                                <Label
                                                  htmlFor='whitepapers'
                                                  id='whitepapers_doc'>
                                                  <span
                                                    className='p-1 mb-2 rounded-circle'
                                                    style={{
                                                      color: '#134a87',
                                                      fontSize: 14,
                                                      cursor: 'pointer'
                                                    }}>
                                                    <img
                                                      src={
                                                        optionalFile['whitepapers_doc']
                                                          ? documentUploadedIcon
                                                          : documentUploadIcon
                                                      }
                                                      alt='Whitepapers'></img>
                                                  </span>
                                                  <span className='font-size-12'>
                                                    Whitepapers
                                                  </span>
                                                </Label>
                                                <div>
                                                  {fileUrls.whitepapers_doc && (
                                                    <a
                                                      href={fileUrls.whitepapers_doc}
                                                      target='_blank'
                                                      rel='noopener noreferrer'
                                                      className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                                      View File
                                                    </a>
                                                  )}
                                                </div>
                                                <Input
                                                  type='file'
                                                  id='whitepapers'
                                                  name='whitepapers_doc'
                                                  onChange={changeHandler}
                                                  style={{ display: 'none' }}
                                                />
                                                <ReactTooltip
                                                  anchorId='whitepapers_doc'
                                                  place='bottom'
                                                  content={
                                                    optionalFile['whitepapers_doc'] &&
                                                    optionalFile['whitepapers_doc'].name
                                                  }
                                                />
                                              </div>
                                            )}

                                          {fields.hasOwnProperty(
                                            'integration_flows_doc'
                                          ) && (
                                            <div>
                                              <Label
                                                htmlFor='integration_flow'
                                                id='integration_flows_doc'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 14,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile[
                                                        'integration_flows_doc'
                                                      ]
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='IntegrationFlows'></img>
                                                </span>
                                                <span className='font-size-12'>
                                                  Integration Flows
                                                </span>
                                              </Label>
                                              <div>
                                                {fileUrls.integration_flows_doc && (
                                                  <a
                                                    href={fileUrls.integration_flows_doc}
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                                    View File
                                                  </a>
                                                )}
                                              </div>
                                              <Input
                                                type='file'
                                                id='integration_flow'
                                                name='integration_flows_doc'
                                                onChange={changeHandler}
                                                style={{ display: 'none' }}
                                              />
                                              <ReactTooltip
                                                anchorId='integration_flows_doc'
                                                place='bottom'
                                                content={
                                                  optionalFile['integration_flows_doc'] &&
                                                  optionalFile['integration_flows_doc']
                                                    .name
                                                }
                                              />
                                            </div>
                                          )}

                                          {fields.hasOwnProperty(
                                            'flow_of_funds_diagram_doc'
                                          ) && (
                                            <div>
                                              <Label
                                                htmlFor='flow_diagram'
                                                id='flow_of_funds_diagram_doc'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 14,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile[
                                                        'flow_of_funds_diagram_doc'
                                                      ]
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='FlowOfFundsDiagram'></img>
                                                </span>
                                                <span className='font-size-12'>
                                                  Flow of Funds Diagram
                                                </span>
                                              </Label>
                                              <div>
                                                {fileUrls.flow_of_funds_diagram_doc && (
                                                  <a
                                                    href={
                                                      fileUrls.flow_of_funds_diagram_doc
                                                    }
                                                    target='_blank'
                                                    rel='noopener noreferrer'
                                                    className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                                    View File
                                                  </a>
                                                )}
                                              </div>
                                              <Input
                                                type='file'
                                                id='flow_diagram'
                                                name='flow_of_funds_diagram_doc'
                                                onChange={changeHandler}
                                                style={{ display: 'none' }}
                                              />
                                              <ReactTooltip
                                                anchorId='flow_of_funds_diagram_doc'
                                                place='bottom'
                                                content={
                                                  optionalFile[
                                                    'flow_of_funds_diagram_doc'
                                                  ] &&
                                                  optionalFile[
                                                    'flow_of_funds_diagram_doc'
                                                  ].name
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                              </Row>
                            </div>

                            {completedfields &&
                              ((!completedfields.includes('regulatory_auth') &&
                                fields.hasOwnProperty('regulatory_auth')) ||
                                (!completedfields.includes('AML_CTF_compliance') &&
                                  fields.hasOwnProperty('AML_CTF_compliance')) ||
                                (!completedfields.includes('KYC_checks_on_users') &&
                                  fields.hasOwnProperty('KYC_checks_on_users')) ||
                                (!completedfields.includes('users_screening') &&
                                  fields.hasOwnProperty('users_screening')) ||
                                (!completedfields.includes('services_to_minors') &&
                                  fields.hasOwnProperty('services_to_minors')) ||
                                (!completedfields.includes('whitepapers_doc') &&
                                  fields.hasOwnProperty('whitepapers_doc')) ||
                                (!completedfields.includes('terms_of_service_doc') &&
                                  fields.hasOwnProperty('terms_of_service_doc')) ||
                                (!completedfields.includes('privacy_policy_doc') &&
                                  fields.hasOwnProperty('privacy_policy_doc')) ||
                                (!completedfields.includes('AML_CTF_policy_doc') &&
                                  fields.hasOwnProperty('AML_CTF_policy_doc')) ||
                                (!completedfields.includes('licence_doc') &&
                                  fields.hasOwnProperty('licence_doc')) ||
                                (!completedfields.includes('additional_docs') &&
                                  fields.hasOwnProperty('additional_docs')) ||
                                (!completedfields.includes('legal_opinion_doc') &&
                                  fields.hasOwnProperty('legal_opinion_doc')) ||
                                (!completedfields.includes('policy_procedures_doc') &&
                                  fields.hasOwnProperty('policy_procedures_doc'))) && (
                                <CustomHeaders headerName='Partner Entity Compliance and Documentation' />
                              )}

                            <div className='mt-2 ml-2'>
                              <Row>
                                {completedfields &&
                                  !completedfields.includes('regulatory_auth') &&
                                  fields.hasOwnProperty('regulatory_auth') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='regulatory_auth'
                                            className='text-black font-weight-normal'>
                                            Is the partner entity subject to any
                                            regulatory authorization or licensing
                                            requirements?
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='d-flex align-items-center font-size-12 mr-4'>
                                            <Field
                                              name='regulatory_auth'
                                              type='radio'
                                              value='true'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4'>
                                            <Field
                                              name='regulatory_auth'
                                              type='radio'
                                              value='false'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['regulatory_auth'] &&
                                            touched['regulatory_auth'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['regulatory_auth']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}

                                {completedfields &&
                                  !completedfields.includes('AML_CTF_compliance') &&
                                  fields.hasOwnProperty('AML_CTF_compliance') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='AML_CTF_compliance'
                                            className='text-black font-weight-normal'>
                                            Does the partner entity maintain an AML/CTF
                                            compliance program?
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='d-flex align-items-center font-size-12 mr-4'>
                                            <Field
                                              name='AML_CTF_compliance'
                                              type='radio'
                                              value='true'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='AML_CTF_compliance'
                                              type='radio'
                                              value='false'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='AML_CTF_compliance'
                                              type='radio'
                                              value='null'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.NA}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['AML_CTF_compliance'] &&
                                            touched['AML_CTF_compliance'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['AML_CTF_compliance']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}

                                {completedfields &&
                                  !completedfields.includes('KYC_checks_on_users') &&
                                  fields.hasOwnProperty('KYC_checks_on_users') && (
                                    <Col>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='KYC_checks_on_users'
                                            className='text-black font-weight-normal'>
                                            Does the partner entity perform KYC checks on
                                            its users?
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='d-flex align-items-center font-size-12 mr-4'>
                                            <Field
                                              name='KYC_checks_on_users'
                                              type='radio'
                                              value='true'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='KYC_checks_on_users'
                                              type='radio'
                                              value='false'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='KYC_checks_on_users'
                                              type='radio'
                                              value='null'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.NA}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['KYC_checks_on_users'] &&
                                            touched['KYC_checks_on_users'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['KYC_checks_on_users']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}

                                {completedfields &&
                                  !completedfields.includes('users_screening') &&
                                  fields.hasOwnProperty('users_screening') && (
                                    <Col lg={6} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='users_screening'
                                            className='text-black font-weight-normal'>
                                            Does the partner entity perform sanctions
                                            screening of its users?
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='d-flex align-items-center font-size-12 mr-4'>
                                            <Field
                                              name='users_screening'
                                              type='radio'
                                              value='true'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='users_screening'
                                              type='radio'
                                              value='false'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                          <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='users_screening'
                                              type='radio'
                                              value='null'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='ml-2 mt-2'>
                                              {CONST.NA}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['users_screening'] &&
                                            touched['users_screening'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['users_screening']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                                <Col lg={6} md={12} sm={12}>
                                  {completedfields &&
                                    !completedfields.includes('services_to_minors') &&
                                    fields.hasOwnProperty('services_to_minors') && (
                                      <Col>
                                        <FormGroup className='mb-3'>
                                          <div className='font-size-12'>
                                            <Label
                                              htmlFor='services_to_minors'
                                              className='text-black font-weight-normal'>
                                              Does the partner entity perform sanctions
                                              screening of its users?
                                            </Label>
                                          </div>
                                          <div className='d-flex direction-row'>
                                            <div className='d-flex align-items-center font-size-12 mr-4'>
                                              <Field
                                                name='services_to_minors'
                                                type='radio'
                                                value='true'
                                                style={{
                                                  height: '1.725rem',
                                                  width: '1rem'
                                                }}
                                              />
                                              <Label className='ml-2 mt-2'>
                                                {CONST.YES}
                                              </Label>
                                            </div>
                                            <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                              <Field
                                                name='services_to_minors'
                                                type='radio'
                                                value='false'
                                                style={{
                                                  height: '1.725rem',
                                                  width: '1rem'
                                                }}
                                              />
                                              <Label className='ml-2 mt-2'>
                                                {CONST.NO}
                                              </Label>
                                            </div>
                                            <div className='d-flex align-items-center font-size-12 ml-4 mr-4'>
                                              <Field
                                                name='services_to_minors'
                                                type='radio'
                                                value='null'
                                                style={{
                                                  height: '1.725rem',
                                                  width: '1rem'
                                                }}
                                              />
                                              <Label className='ml-2 mt-2'>
                                                {CONST.NA}
                                              </Label>
                                            </div>
                                          </div>
                                          <div className='d-flex align-items-center'>
                                            {errors['services_to_minors'] &&
                                              touched['services_to_minors'] && (
                                                <span
                                                  style={{ fontSize: 12 }}
                                                  className='text-left text-danger'>
                                                  {errors['services_to_minors']}
                                                </span>
                                              )}
                                          </div>
                                        </FormGroup>
                                      </Col>
                                    )}
                                  {(fields.hasOwnProperty('corporate_registration_doc') ||
                                    fields.hasOwnProperty('terms_of_service_doc') ||
                                    fields.hasOwnProperty('privacy_policy_doc') ||
                                    fields.hasOwnProperty('AML_CTF_policy_doc') ||
                                    fields.hasOwnProperty('licence_doc')) && (
                                    <Col
                                      style={{
                                        background: '#DDDADA',
                                        borderRadius: '20px'
                                      }}>
                                      <FormGroup className='mb-4'>
                                        <div className='font-size-16'>
                                          <Label
                                            htmlFor='description'
                                            className='text-black font-weight-normal'>
                                            Required
                                          </Label>
                                        </div>
                                        <div
                                          className='d-flex justify-content-between'
                                          style={{ flexDirection: 'column' }}>
                                          {fields.hasOwnProperty(
                                            'corporate_registration_doc'
                                          ) && (
                                            <div>
                                              <Label
                                                htmlFor='corporate_registration_doc'
                                                id='registration_doc'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 15,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile[
                                                        'corporate_registration_doc'
                                                      ]
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='Corporate Registration Documents'></img>
                                                </span>
                                                Corporate Registration Documents
                                              </Label>
                                              {fileUrls.corporate_registration_doc && (
                                                <a
                                                  href={
                                                    fileUrls.corporate_registration_doc
                                                  }
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                  className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                                  View File
                                                </a>
                                              )}
                                              <Input
                                                type='file'
                                                id='corporate_registration_doc'
                                                name='corporate_registration_doc'
                                                onChange={changeHandler}
                                                style={{ display: 'none' }}
                                              />
                                              <ReactTooltip
                                                anchorId='registration_doc'
                                                place='bottom'
                                                content={
                                                  optionalFile[
                                                    'corporate_registration_doc'
                                                  ] &&
                                                  optionalFile[
                                                    'corporate_registration_doc'
                                                  ].name
                                                }
                                              />
                                            </div>
                                          )}
                                          {fields.hasOwnProperty(
                                            'terms_of_service_doc'
                                          ) && (
                                            <div>
                                              <Label
                                                htmlFor='terms_of_service_doc'
                                                id='terms_of_service'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 15,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile['terms_of_service_doc']
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='Terms of Service'></img>
                                                </span>
                                                Terms of Service
                                              </Label>
                                              {fileUrls.terms_of_service_doc && (
                                                <a
                                                  href={fileUrls.terms_of_service_doc}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                  className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                                  View File
                                                </a>
                                              )}
                                              <Input
                                                type='file'
                                                id='terms_of_service_doc'
                                                name='terms_of_service_doc'
                                                onChange={changeHandler}
                                                style={{ display: 'none' }}
                                              />
                                              <ReactTooltip
                                                anchorId='terms_of_service'
                                                place='bottom'
                                                content={
                                                  optionalFile['terms_of_service_doc'] &&
                                                  optionalFile['terms_of_service_doc']
                                                    .name
                                                }
                                              />
                                            </div>
                                          )}
                                          {fields.hasOwnProperty(
                                            'privacy_policy_doc'
                                          ) && (
                                            <div>
                                              <Label
                                                htmlFor='privacy_policy_doc'
                                                id='privacy_policy'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 15,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile['privacy_policy_doc']
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='Privacy Policy'></img>
                                                </span>
                                                Privacy Policy
                                              </Label>
                                              {fileUrls.privacy_policy_doc && (
                                                <a
                                                  href={fileUrls.privacy_policy_doc}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                  className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                                  View File
                                                </a>
                                              )}
                                              <Input
                                                type='file'
                                                id='privacy_policy_doc'
                                                name='privacy_policy_doc'
                                                onChange={changeHandler}
                                                style={{ display: 'none' }}
                                              />
                                              <ReactTooltip
                                                anchorId='privacy_policy'
                                                place='bottom'
                                                content={
                                                  optionalFile['privacy_policy_doc'] &&
                                                  optionalFile['privacy_policy_doc'].name
                                                }
                                              />
                                            </div>
                                          )}
                                          {fields.hasOwnProperty(
                                            'AML_CTF_policy_doc'
                                          ) && (
                                            <div>
                                              <Label
                                                htmlFor='AML_CTF_policy_doc'
                                                id='aml_ctf_policy'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 15,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile['AML_CTF_policy_doc']
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='AML CTF Compliance'></img>
                                                </span>
                                                AML / CTF Compliance
                                              </Label>
                                              {fileUrls.AML_CTF_policy_doc && (
                                                <a
                                                  href={fileUrls.AML_CTF_policy_doc}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                  className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                                  View File
                                                </a>
                                              )}
                                              <Input
                                                type='file'
                                                id='AML_CTF_policy_doc'
                                                name='AML_CTF_policy_doc'
                                                onChange={changeHandler}
                                                style={{ display: 'none' }}
                                              />
                                              <ReactTooltip
                                                anchorId='aml_ctf_policy'
                                                place='bottom'
                                                content={
                                                  optionalFile['AML_CTF_policy_doc'] &&
                                                  optionalFile['AML_CTF_policy_doc'].name
                                                }
                                              />
                                            </div>
                                          )}
                                          {fields.hasOwnProperty('licence_doc') && (
                                            <div>
                                              <Label htmlFor='licence_doc' id='licence'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 15,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile['licence_doc']
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='Copy of License'></img>
                                                </span>
                                                Copy of License
                                              </Label>
                                              {fileUrls.licence_doc && (
                                                <a
                                                  href={fileUrls.licence_doc}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                  className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                                  View File
                                                </a>
                                              )}
                                              <Input
                                                type='file'
                                                id='licence_doc'
                                                name='licence_doc'
                                                onChange={changeHandler}
                                                style={{ display: 'none' }}
                                              />
                                              <ReactTooltip
                                                anchorId='licence'
                                                place='bottom'
                                                content={
                                                  optionalFile['licence_doc'] &&
                                                  optionalFile['licence_doc'].name
                                                }
                                              />
                                            </div>
                                          )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                                </Col>
                              </Row>
                              <Row>
                                {completedfields &&
                                  (fields.hasOwnProperty('legal_opinion_doc') ||
                                    fields.hasOwnProperty('policy_procedures_doc') ||
                                    fields.hasOwnProperty('additional_docs')) && (
                                    <Col lg={12} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='description'
                                            className='text-black font-weight-normal'>
                                            Optional Documents
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row justify-content-between'>
                                          {fields.hasOwnProperty('legal_opinion_doc') && (
                                            <div>
                                              <Label
                                                htmlFor='legal_opinion_doc'
                                                id='legal_opinion'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 14,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile['legal_opinion_doc']
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='Legal Opinion'></img>
                                                </span>
                                                <span className='font-size-12'>
                                                  Legal Opinion
                                                </span>
                                              </Label>
                                              {fileUrls.legal_opinion_doc && (
                                                <a
                                                  href={fileUrls.legal_opinion_doc}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                  className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                                  View File
                                                </a>
                                              )}
                                              <Input
                                                type='file'
                                                id='legal_opinion_doc'
                                                name='legal_opinion_doc'
                                                onChange={changeHandler}
                                                style={{ display: 'none' }}
                                              />
                                              <ReactTooltip
                                                anchorId='legal_opinion'
                                                place='bottom'
                                                content={
                                                  optionalFile['legal_opinion_doc'] &&
                                                  optionalFile['legal_opinion_doc'].name
                                                }
                                              />
                                            </div>
                                          )}

                                          {fields.hasOwnProperty(
                                            'policy_procedures_doc'
                                          ) && (
                                            <div>
                                              <Label
                                                htmlFor='policy_procedures_doc'
                                                id='policy_procedures'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 14,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile[
                                                        'policy_procedures_doc'
                                                      ]
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='PoliciesProcedures'></img>
                                                </span>
                                                <span className='font-size-12'>
                                                  Policies / Procedures
                                                </span>
                                              </Label>
                                              {fileUrls.policy_procedures_doc && (
                                                <a
                                                  href={fileUrls.policy_procedures_doc}
                                                  target='_blank'
                                                  rel='noopener noreferrer'
                                                  className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                                  View File
                                                </a>
                                              )}
                                              <Input
                                                type='file'
                                                id='policy_procedures_doc'
                                                name='policy_procedures_doc'
                                                onChange={changeHandler}
                                                style={{ display: 'none' }}
                                              />
                                              <ReactTooltip
                                                anchorId='policy_procedures'
                                                place='bottom'
                                                content={
                                                  optionalFile['policy_procedures_doc'] &&
                                                  optionalFile['policy_procedures_doc']
                                                    .name
                                                }
                                              />
                                            </div>
                                          )}
                                          {fields.hasOwnProperty('additional_docs') && (
                                            <div>
                                              <Label
                                                htmlFor='additional_doc'
                                                id='additional'>
                                                <span
                                                  className='p-1 mb-2 rounded-circle'
                                                  style={{
                                                    color: '#134a87',
                                                    fontSize: 14,
                                                    cursor: 'pointer'
                                                  }}>
                                                  <img
                                                    src={
                                                      optionalFile['additional_docs']
                                                        .length > 0
                                                        ? documentUploadedIcon
                                                        : documentUploadIcon
                                                    }
                                                    alt='Additional Documents'></img>
                                                </span>
                                                <span className='font-size-12'>
                                                  Additional Documents
                                                </span>
                                              </Label>
                                              {fileUrls.additional_docs &&
                                                fileUrls.additional_docs.map(
                                                  (url, index) => {
                                                    return (
                                                      <div key={index}>
                                                        <a
                                                          href={url}
                                                          target='_blank'
                                                          rel='noopener noreferrer'
                                                          className='pl-4 font-size-12 font-weight-bold text-wrap text-primary'>
                                                          View File
                                                        </a>
                                                      </div>
                                                    );
                                                  }
                                                )}
                                              <Input
                                                type='file'
                                                id='additional_doc'
                                                name='additional_docs'
                                                style={{ display: 'none' }}
                                                onChange={changeHandler}
                                                multiple
                                              />
                                              {optionalFile['additional_docs'].length >
                                                0 &&
                                                Array.from(
                                                  optionalFile['additional_docs']
                                                ).map((file, index) => (
                                                  <div
                                                    style={{ color: '#B9D794' }}
                                                    key={index}>
                                                    <Label className='pl-4'>
                                                      {file[0].name}
                                                    </Label>
                                                  </div>
                                                ))}
                                            </div>
                                          )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                              </Row>
                            </div>

                            {completedfields &&
                              ((!completedfields.includes('consent_info') &&
                                fields.hasOwnProperty('consent_info')) ||
                                (!completedfields.includes('consent_OFAC') &&
                                  fields.hasOwnProperty('consent_OFAC')) ||
                                (!completedfields.includes('applicant_full_name') &&
                                  fields.hasOwnProperty('applicant_full_name')) ||
                                (!completedfields.includes('applicant_email') &&
                                  fields.hasOwnProperty('applicant_email')) ||
                                (!completedfields.includes('applicant_role') &&
                                  fields.hasOwnProperty('applicant_role')) ||
                                (!completedfields.includes('application_date') &&
                                  fields.hasOwnProperty('application_date')) ||
                                (!completedfields.includes('consent_region_name') &&
                                  fields.hasOwnProperty('consent_region_name'))) && (
                                <CustomHeaders headerName='Certifications' />
                              )}

                            <div className='mt-2 ml-2'>
                              <Row>
                                {completedfields &&
                                  !completedfields.includes('consent_info') &&
                                  fields.hasOwnProperty('consent_info') && (
                                    <Col lg={12} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='consent_info'
                                            className='text-black font-weight-normal'>
                                            I hereby certify, to the best of my knowledge,
                                            that the information provided in this
                                            questionnaire is complete and accurate.
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='custom-control font-size-12 form-check mr-4'>
                                            <Field
                                              name='consent_info'
                                              type='radio'
                                              value='true'
                                              className='form-check-input'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='form-check-label ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='custom-control font-size-12 form-check ml-4'>
                                            <Field
                                              name='consent_info'
                                              type='radio'
                                              value='false'
                                              className='form-check-input'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='form-check-label ml-2 mt-2'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center mt-1'>
                                          {errors['consent_info'] &&
                                            touched['consent_info'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['consent_info']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}

                                {completedfields &&
                                  !completedfields.includes('consent_OFAC') &&
                                  fields.hasOwnProperty('consent_OFAC') && (
                                    <Col lg={12} md={12} sm={12}>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='consent_OFAC'
                                            className='text-black font-weight-normal'>
                                            I hereby certify that the partner entity does
                                            not interact with or provide services to
                                            persons or entities subject to sanctions
                                            administered by the US Office of Foreign
                                            Assets Control ("OFAC") or those imposed by
                                            the UK Office of Financial Sanctions
                                            Implementation ("OFSI").
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='custom-control form-check font-size-12 mr-4'>
                                            <Field
                                              name='consent_OFAC'
                                              type='radio'
                                              className='form-check-input'
                                              value='true'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='form-check-label ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='custom-control form-check font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='consent_OFAC'
                                              type='radio'
                                              className='form-check-input'
                                              value='false'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='form-check-label ml-2 mt-2'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                          <div className='custom-control form-check font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='consent_OFAC'
                                              type='radio'
                                              className='form-check-input'
                                              value='null'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='form-check-label ml-2 mt-2'>
                                              {CONST.NA}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center mt-1'>
                                          {errors['consent_OFAC'] &&
                                            touched['consent_OFAC'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['consent_OFAC']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                                {completedfields &&
                                  !completedfields.includes('consent_region') &&
                                  fields.hasOwnProperty('consent_region') && (
                                    <Col>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='consent_region'
                                            className='text-black font-weight-normal'>
                                            I hereby certify that the partner entity does
                                            not do business in the following
                                            jurisdictions: Cuba, Iran, North Korea, Syria,
                                            Crimea region of Ukraine, Donetsk region of
                                            Ukraine, Luhansk region of Ukraine, or the
                                            Russian Federation.
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row align-items-center'>
                                          <div className='custom-control form-check font-size-12 mr-4'>
                                            <Field
                                              name='consent_region'
                                              type='radio'
                                              value='true'
                                              className='form-check-input'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='form-check-label ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>

                                          {fields.hasOwnProperty(
                                            'consent_region_name'
                                          ) && (
                                            <>
                                              <div className='custom-control form-check  ml-4 mr-4'>
                                                <Label className='form-check-label  mt-2'>
                                                  I do business in these regions
                                                </Label>
                                              </div>
                                              <AuthInput
                                                name='consent_region_name'
                                                type='text'
                                                values={values}
                                                handleChange={handleChange}
                                                placeholder={CONST.ENTER_REGION}
                                                errors={errors}
                                                touched={touched}
                                              />
                                            </>
                                          )}
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['consent_region'] &&
                                            touched['consent_region'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['consent_region']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                                {completedfields &&
                                  !completedfields.includes('consent_age') &&
                                  fields.hasOwnProperty('consent_age') && (
                                    <Col>
                                      <FormGroup className='mb-3'>
                                        <div className='font-size-12'>
                                          <Label
                                            htmlFor='consent_age'
                                            className='text-black font-weight-normal'>
                                            I acknowledge that Rampnalysis and its
                                            Associated businesses may not provide services
                                            to sanctioned individuals, sanctioned
                                            jurisdictions, nor minors (individuals under
                                            the age of 18).
                                          </Label>
                                        </div>
                                        <div className='d-flex direction-row'>
                                          <div className='custom-control form-check font-size-12 mr-4'>
                                            <Field
                                              name='consent_age'
                                              type='radio'
                                              value='true'
                                              className='form-check-input'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='form-check-label ml-2 mt-2'>
                                              {CONST.YES}
                                            </Label>
                                          </div>
                                          <div className='custom-control form-check font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='consent_age'
                                              type='radio'
                                              value='false'
                                              className='form-check-input'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='form-check-label ml-2 mt-2'>
                                              {CONST.NO}
                                            </Label>
                                          </div>
                                          <div className='custom-control form-check font-size-12 ml-4 mr-4'>
                                            <Field
                                              name='consent_age'
                                              type='radio'
                                              value='null'
                                              className='form-check-input'
                                              style={{
                                                height: '1.725rem',
                                                width: '1rem'
                                              }}
                                            />
                                            <Label className='form-check-label ml-2 mt-2'>
                                              {CONST.NA}
                                            </Label>
                                          </div>
                                        </div>
                                        <div className='d-flex align-items-center'>
                                          {errors['consent_age'] &&
                                            touched['consent_age'] && (
                                              <span
                                                style={{ fontSize: 12 }}
                                                className='text-left text-danger'>
                                                {errors['consent_age']}
                                              </span>
                                            )}
                                        </div>
                                      </FormGroup>
                                    </Col>
                                  )}
                                <Col>
                                  <Row>
                                    {completedfields &&
                                      !completedfields.includes('applicant_full_name') &&
                                      fields.hasOwnProperty('applicant_full_name') && (
                                        <Col lg={6} md={12} sm={12}>
                                          <FormGroup className='mb-3'>
                                            <div className='font-size-12'>
                                              <Label
                                                htmlFor='applicant_full_name'
                                                className='text-black font-weight-normal'>
                                                {CONST.FULL_NAME}
                                              </Label>
                                            </div>

                                            <div>
                                              <AuthInput
                                                type='text'
                                                name='applicant_full_name'
                                                values={values}
                                                className='font-size-12'
                                                handleChange={handleChange}
                                                placeholder={CONST.ENTER_FULL_NAME}
                                                errors={errors}
                                                touched={touched}></AuthInput>
                                            </div>
                                          </FormGroup>
                                        </Col>
                                      )}
                                    {completedfields &&
                                      !completedfields.includes('applicant_email') &&
                                      fields.hasOwnProperty('applicant_email') && (
                                        <Col lg={6} md={12} sm={12}>
                                          <FormGroup className='mb-3'>
                                            <div className='font-size-12'>
                                              <Label
                                                htmlFor='applicant_email'
                                                className='text-black font-weight-normal'>
                                                {CONST.EMAIL}
                                              </Label>
                                            </div>
                                            <div>
                                              <AuthInput
                                                type='text'
                                                name='applicant_email'
                                                placeholder={CONST.ENTER_EMAIL}
                                                values={values}
                                                className='font-size-12'
                                                handleChange={handleChange}
                                                errors={errors}
                                                touched={touched}></AuthInput>
                                            </div>
                                          </FormGroup>
                                        </Col>
                                      )}
                                  </Row>
                                </Col>
                                <Col>
                                  <Row>
                                    {completedfields &&
                                      !completedfields.includes('applicant_role') &&
                                      fields.hasOwnProperty('applicant_role') && (
                                        <Col lg={6} md={12} sm={12}>
                                          <FormGroup className='mb-3'>
                                            <div className='font-size-12'>
                                              <Label
                                                htmlFor='applicant_role'
                                                className='text-black font-weight-normal'>
                                                {CONST.ROLE}
                                              </Label>
                                            </div>

                                            <div>
                                              <AuthInput
                                                type='text'
                                                name='applicant_role'
                                                values={values}
                                                className='font-size-12'
                                                handleChange={handleChange}
                                                placeholder={CONST.ENTER_ROLE}
                                                errors={errors}
                                                touched={touched}></AuthInput>
                                            </div>
                                          </FormGroup>
                                        </Col>
                                      )}
                                    {completedfields &&
                                      !completedfields.includes('application_date') &&
                                      fields.hasOwnProperty('application_date') && (
                                        <Col lg={6} md={12} sm={12}>
                                          <FormGroup className='mb-3'>
                                            <div className='font-size-12'>
                                              <Label
                                                htmlFor='application_date'
                                                className='text-black font-weight-normal'>
                                                {CONST.DATE}
                                              </Label>
                                            </div>
                                            <div>
                                              <AuthInput
                                                type='date'
                                                name='application_date'
                                                values={values}
                                                className='font-size-12'
                                                handleChange={handleChange}
                                                errors={errors}
                                                touched={touched}></AuthInput>
                                            </div>
                                          </FormGroup>
                                        </Col>
                                      )}
                                  </Row>
                                </Col>
                              </Row>
                            </div>
                            <div className='d-flex justify-content-center'>
                              <button
                                className='btn btn-auth'
                                type='submit'
                                style={{ width: 213, height: 40 }}
                                onClick={() => handleSubmit(values)}>
                                Update
                              </button>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    ) : (
                      <EditExchangeSubApplication
                        applicationTypeData={applicationTypeData}
                        completedfields={completedfields}
                        fields={fields}
                        applicationId={applicationId}
                        responseId={location.state}
                      />
                    )}
                  </Container>
                )}
                <BeneficiaryUserModal
                  applicationId={applicationId}
                  modal={userModal}
                  user={user}
                  handleOpenModal={addUser}
                  beneficialUsersData={beneficialUsersData}
                  responseId={location.state}
                  completedBeneficialUsersData={completedBeneficialUsersData}
                />
              </div>
            </Card>
          </Col>
          <Col lg={3} md={12} sm={12}>
            <Card>
              <div className='pt-3 pl-3'>
                <div className='col-12 col-sm-auto font-size-18 pb-2 pb-sm-0'>
                  <span className='text-black'>
                    <u>KYB Requests</u>
                    <span
                      style={{ position: 'absolute', right: '8px', top: '-10px' }}
                      className='font-size-16 badge badge-pill badge-warning'>
                      {responses && responses.field && responses.completed_field
                        ? Object.keys(responses.field).length -
                          responses.completed_field.length
                        : Object.keys(responses.field).length}
                    </span>
                  </span>
                </div>
              </div>
              <div className='mt-2 pl-4 pr-3'>
                {responses &&
                responses.field &&
                Object.keys(responses.field).length > 0 ? (
                  <span>
                    {Object.keys(responses.field).map((kyb, index) => (
                      <>
                        <span className='font-size-14 font-weight-medium'>
                          {index + 1}. {kyb.toUpperCase()}:
                        </span>{' '}
                        <span className='font-size-12'>{responses.field[kyb]}</span>
                        <hr />
                      </>
                    ))}
                  </span>
                ) : (
                  ''
                )}
              </div>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default EditSubApplication;

const CustomHeaders = ({ headerName }) => {
  return (
    <div className='col-12 col-sm-auto font-size-18 pb-2 pb-sm-0'>
      <span className='text-black'>
        <u>{headerName}</u>
      </span>
    </div>
  );
};
