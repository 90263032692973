import React from 'react';
import ConfettiDrop from '../../WidgetCustomization1Buy.js/ConfettiDrop';
import LoadingScreenMeme from '../../WidgetCustomization1Buy.js/LoadingScreenMeme';
import ConfirmScreenMeme from '../../WidgetCustomization1Buy.js/ConfirmScreenMeme';
import SwapLoadingComp from '../../WidgetCustomization1Buy.js/SwapLoadingComp';
import SwapFailedComp from '../../WidgetCustomization1Buy.js/SwapFailedComp';
import SwapCompletedComp from '../../WidgetCustomization1Buy.js/SwapCompletedComp';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Spinner } from 'reactstrap';

function FunMode({
  isUpdate,
  setIsUpdate,
  funMode,
  handleFunModeToggle,
  setFunModeImages,
  funModeLoadingGifs,
  setFunModeLoadingGifs,
  funModeConfirmScreenGifs,
  setFunModeConfirmScreenGifs,
  funModeSwapLoadingGifs,
  setFunModeSwapLoadingGifs,
  funModeSwapFailedGifs,
  setFunModeSwapFailedGifs,
  funModeSwapCompletedGifs,
  setFunModeSwapCompletedGifs,
  imageFiles,
  setImageFiles,
  loadingGifFiles,
  setLoadingGifFiles,
  confirmScreenGifFiles,
  setConfirmScreenGifFiles,
  swapLoadingGifFiles,
  setSwapLoadingGifFiles,
  swapFailedGifFiles,
  setSwapFailedGifFiles,
  swapCompletedGifFiles,
  setSwapCompletedGifFiles,
  isLoading = false
}) {
  return (
    <React.Fragment>
      <div className='ml-2'>
        <div className='d-flex align-items-center'>
          <label className='switch-fun-mode'>
            <input type='checkbox' checked={funMode} onChange={handleFunModeToggle} />
            <span className='slider-fun-mode'></span>
          </label>
          <span className='font-size-12 text-black font-weight-medium mb-2 ml-2'>
            Fun mode
          </span>
        </div>

        {funMode && (
          <div>
            <div className='d-flex flex-column justify-content-between'>
              <ConfettiDrop
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                imageFiles={imageFiles}
                setImageFiles={setImageFiles}
                setFunModeImages={setFunModeImages}
                ReactTooltip={ReactTooltip}
              />
              {isLoading && (
                <div className='overlay-spinner'>
                  <Spinner />
                </div>
              )}
              <LoadingScreenMeme
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                loadingGifFiles={loadingGifFiles}
                setLoadingGifFiles={setLoadingGifFiles}
                funModeLoadingGifs={funModeLoadingGifs}
                setFunModeLoadingGifs={setFunModeLoadingGifs}
                ReactTooltip={ReactTooltip}
              />
              <ConfirmScreenMeme
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                confirmScreenGifFiles={confirmScreenGifFiles}
                setConfirmScreenGifFiles={setConfirmScreenGifFiles}
                funModeConfirmScreenGifs={funModeConfirmScreenGifs}
                setFunModeConfirmScreenGifs={setFunModeConfirmScreenGifs}
                ReactTooltip={ReactTooltip}
              />
            </div>
            <hr />
            <p className='text-black'>Swap Memes</p>
            <div className='d-flex flex-column justify-content-between'>
              <SwapLoadingComp
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                swapLoadingGifFiles={swapLoadingGifFiles}
                setSwapLoadingGifFiles={setSwapLoadingGifFiles}
                funModeSwapLoadingGifs={funModeSwapLoadingGifs}
                setFunModeSwapLoadingGifs={setFunModeSwapLoadingGifs}
                ReactTooltip={ReactTooltip}
              />
              <SwapFailedComp
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                swapFailedGifFiles={swapFailedGifFiles}
                funModeSwapFailedGifs={funModeSwapFailedGifs}
                setSwapFailedGifFiles={setSwapFailedGifFiles}
                setFunModeSwapFailedGifs={setFunModeSwapFailedGifs}
                ReactTooltip={ReactTooltip}
              />
              <SwapCompletedComp
                isUpdate={isUpdate}
                setIsUpdate={setIsUpdate}
                swapCompletedGifFiles={swapCompletedGifFiles}
                funModeSwapCompletedGifs={funModeSwapCompletedGifs}
                setSwapCompletedGifFiles={setSwapCompletedGifFiles}
                setFunModeSwapCompletedGifs={setFunModeSwapCompletedGifs}
                ReactTooltip={ReactTooltip}
              />
            </div>
          </div>
        )}
      </div>
    </React.Fragment>
  );
}

export default FunMode;
