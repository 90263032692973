import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import { constants as CONST } from '../../../constants/application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';
import { Form, Formik } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';

function StepTwelve(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;
  const step = 12;
  const [fileUrls, setFileUrls] = useState({});
  const applicationData = props.applicationData.exchange_application;
  const [completeStep, setCompleteStep] = useState(0);
  const [initialValue, setInitialValue] = useState({
    whitepapers_doc: '',
    whitepapers_doc_url: '',
    pitch_decks_doc: '',
    pitch_decks_doc_url: ''
  });

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');

        if (application.exchange_application.whitepapers_doc) {
          setFileUrls({
            whitepapers_doc: application.exchange_application.whitepapers_doc,
            pitch_decks_doc: application.exchange_application.pitch_decks_doc
          });

          setInitialValue({
            whitepapers_doc: application.exchange_application.whitepapers_doc,
            whitepapers_doc_url: application.exchange_application.whitepapers_doc_url,
            pitch_decks_doc: application.exchange_application.pitch_decks_doc,
            pitch_decks_doc_url: application.exchange_application.pitch_decks_doc_url
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const [optionalFile, setOptionalFile] = useState({});

  const changeHandler = (event) => {
    if (event.target.files[0]) {
      setOptionalFile({ ...optionalFile, [event.target.name]: event.target.files[0] });
    }
  };
  const handleSubmit = (values, { setFieldError }) => {
    const { nextPage, applicationId } = props;
    if (
      !optionalFile.whitepapers_doc &&
      !fileUrls.whitepapers_doc &&
      !values.whitepapers_doc_url
    ) {
      setFieldError('whitepapers_doc', 'Please upload whitepapers');
      return;
    }
    if (
      !optionalFile.pitch_decks_doc &&
      !fileUrls.pitch_decks_doc &&
      !values.pitch_decks_doc_url
    ) {
      setFieldError('pitch_decks_doc', 'Please upload latest decks');
      return;
    }
    const data = new FormData();
    Object.keys(optionalFile).forEach((key) => {
      data.append(key, optionalFile[key]);
    });
    Object.keys(values).forEach((key) => {
      data.append(key, values[key]);
    });
    console.log('-------data------', data);

    let stepsRequired = Math.floor(StepsCalculator(2, completeStep, 64));
    data.append('percentage_complete', stepsRequired);
    setIsLoading(true);

    UpdateExchangeApplication({ application_id: applicationId }, data)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        props.setApplicationData(application);
        nextPage();
        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    setExchangeCompleteStep([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]);
  };

  const calculateProgress = () => {
    return completeStep;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>Project's Whitepaper + Pitch deck</span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange, setFieldError }) => (
              <Form>
                <div className=''>
                  <Row>
                    <Col md={6} sm={6} lg={6}>
                      <CustomFileInput
                        labelName="Project's Documents"
                        fieldName='whitepapers_doc'
                        optionalFile={optionalFile}
                        fileUrls={fileUrls}
                        errors={errors}
                        headerName={'Whitepapers'}
                        changeHandler={changeHandler}
                        values={values}
                        handleChange={handleChange}
                      />
                    </Col>
                    <Col md={6} sm={6} lg={6}>
                      <CustomFileInput
                        labelName=''
                        fieldName='pitch_decks_doc'
                        optionalFile={optionalFile}
                        fileUrls={fileUrls}
                        errors={errors}
                        headerName={'Pitch deck'}
                        changeHandler={changeHandler}
                        values={values}
                        handleChange={handleChange}
                      />
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onClick={() => handleSubmit(values, { setFieldError })}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepTwelve;

const CustomFileInput = ({
  labelName,
  fieldName,
  optionalFile,
  fileUrls,
  errors,
  headerName,
  changeHandler,
  values,
  handleChange
}) => {
  const inputFile = React.createRef();
  const handleClick = () => {
    inputFile.current.click();
  };
  return (
    <FormGroup>
      <div className='font-size-12'>
        <Label
          htmlFor='description'
          className={`text-black font-weight-normal ${!labelName ? 'mt-3' : 'mt-1'}`}
          data-replace={`${labelName}`}
          style={{ cursor: 'pointer' }}>
          <span>{labelName}</span>
        </Label>
      </div>
      <div
        style={{
          borderRadius: '8px',
          border: '1px solid',
          height: 47,
          cursor: 'pointer'
        }}
        onClick={handleClick}
        className='d-flex direction-row justify-content-between align-items-center pl-1'>
        <div htmlFor={`${fieldName}`} id={`${fieldName}`} style={{ cursor: 'pointer' }}>
          <span
            className='rounded-circle'
            style={{
              color: '#134a87',
              fontSize: 12,
              cursor: 'pointer'
            }}>
            <img
              src={
                optionalFile[fieldName] || (fileUrls && fileUrls[fieldName])
                  ? documentUploadedIcon
                  : documentUploadIcon
              }
              alt={`${fieldName}`}
            />
          </span>
          <span className='font-size-12 ml-2'>{headerName}</span>
        </div>
        {(optionalFile[fieldName] || (fileUrls && fileUrls[fieldName])) &&
          (fileUrls[fieldName] ? (
            <a
              href={fileUrls[fieldName]}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : values[fieldName] ? (
            <a
              href={values[fieldName]}
              target='_blank'
              rel='noopener noreferrer'
              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
              View File
            </a>
          ) : (
            ''
          ))}

        <input
          type='file'
          name={fieldName}
          ref={inputFile}
          style={{ display: 'none' }}
          accept='.pdf'
          onChange={changeHandler}
        />
        <ReactTooltip
          anchorId={`${fieldName}`}
          place='bottom'
          content={optionalFile[fieldName] && optionalFile[fieldName].name}
        />
      </div>
      <Row className='mt-2 d-flex justify-content-center'>
        <Col lg={2}> Or </Col>
      </Row>

      <div className='mt-2'>
        <AuthInput
          type='text'
          name={fieldName + '_url'}
          values={values}
          className='font-size-12'
          handleChange={handleChange}
          placeholder={CONST.ENTER_URL_PLACEHOLDER}
          errors={errors}></AuthInput>
      </div>
      {errors && errors[fieldName] && (
        <div style={{ fontSize: 12 }} className='text-left text-danger'>
          {errors[fieldName]}
        </div>
      )}
    </FormGroup>
  );
};
