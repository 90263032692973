import React, { useEffect, useState } from 'react';
import { adminListusApplicationList } from '../../services/Admin/applicaton';
import { get } from 'lodash';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { Paginator } from 'primereact/paginator';
import GetApplication from './GetApplication';

function AdminListusApplication() {
  const [first, setFirst] = useState(0);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [allApplications, setAllApplications] = useState([]);
  const [page, setPage] = useState(1);
  const [activeId, setActiveId] = useState(null);

  const onClick = (id) => {
    if (activeId === id) {
      return setActiveId(null);
    }

    setActiveId(id);
  };

  const listusApplicationListFunc = () => {
    adminListusApplicationList()
      .then((res) => {
        const responses = get(res, 'data.data.data', []);
        const count = get(res, 'data.data.count', []);
        const slicedData = get(res, 'data.data.data', []).slice(first, first + 10);
        setCurrentPageData(slicedData);
        setTotalCount(count);
        setLoading(false);
        setAllApplications(responses);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    listusApplicationListFunc();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    const newPageData = allApplications.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='card'>
          <div className='p-4'>
            <div className='col-12 col-sm-auto font-size-24 font-weight-medium pb-2 pb-sm-0'>
              <div className='d-flex justify-content-between'>
                <span className='text-black'>Listus applications</span>
              </div>
              <div className='table-responsive'>
                <table className='table table-centered table-hover'>
                  <thead className='center-text'>
                    <tr className='font-size-16 text-muted'>
                      <th className='pl-4 pt-3 pb-4 border-0'>#</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Brand Logo</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Referral Code</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Domain</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Email</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Company Name</th>
                      <th className='pl-4 pt-3 border-0'>Project Name</th>
                      <th className='pl-4 pt-3 border-0'>Applicant Email</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Applicantion Type</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Completion Status</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Percentage Complete</th>
                      <th className='pl-4 pt-3 border-0'>Lite Percentage Complete</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>App Created At</th>
                      <th className='pl-4 pt-3 pb-4 border-0'>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={15}>
                          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                            <i
                              className='fas fa-circle-notch fa-spin text-muted'
                              style={{ fontSize: 48 }}></i>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {currentPageData && currentPageData.length > 0 ? (
                          currentPageData.map((items, index) => (
                            <GetApplication
                              index={index}
                              page={page}
                              items={items}
                              activeId={activeId}
                              onClick={onClick}
                              setLoading={setLoading}
                              listusApplicationListFunc={listusApplicationListFunc}
                            />
                          ))
                        ) : (
                          <tr>
                            <td colSpan={18}>
                              <span className='d-flex justify-content-center'>
                                No applications found
                              </span>
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Paginator
            rows={10}
            totalRecords={totalCount}
            first={first}
            onPageChange={(e) => onPageChange(e)}
          />
        </div>
      </div>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default AdminListusApplication;
