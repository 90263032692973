import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const adminListusApplicationList = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.LISTUS_APPLICATIONS)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const adminDeleteApplicationAPI = (application_id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(
        businessURL.LISTUS_APPLICATIONS + `?application_id=${application_id}`,
        data
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
