import React from 'react';

import { Row, Col, Container, FormGroup, Button, Label } from 'reactstrap';
import { Link } from 'react-router-dom';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../Custom/AuthInput';
import { constants as PLACE_CONST } from '../../constants/placeholder';
import { constants as CONST } from '../../constants/constant';
import { constants as ROUTE_CONST } from '../../constants/routes';
import { constants as BUTTON_CONST } from '../../constants/button';
import { constants as ERROR_CONST } from '../../constants/errorMessage';
import { userAuthLogin } from '../../services/Auth';
import { notificationMessageFunction } from '../../constants/notificationConst';

import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import RightLogo from './RightLogo';

function Login(props) {
  const urlParams = new URLSearchParams(window.location.search);
  let paramsEmail = urlParams.get('email');
  let paramsPassword = urlParams.get('password');

  const LogInSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERROR_CONST.VALID_EMAIL)
      .required(ERROR_CONST.EMAIL_REQUIRED),
    password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
  });

  const handleSubmit = (values) => {
    const data = {
      email: values.email.toLowerCase(),
      password: values.password
    };
    userAuthLogin(data)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        localStorage.setItem(CONST.ACCESSTOKEN, data.accessToken);
        localStorage.setItem(CONST.ACCOUNT_TYPE, data.account_type);
        localStorage.setItem(CONST.EMAIL, data.email);
        localStorage.setItem(CONST.ROLE, data.role ? data.role : 'Admin');
        if (data.account_type === 0) {
          props.history.push(ROUTE_CONST.ADMIN_DASHBOARD);
        } else if (data.account_type === 1) {
          props.history.push(ROUTE_CONST.ONRAMPS);
        } else if (data.account_type === 2) {
          if (
            !data.is_onboarding_completed &&
            data.is_channel_partner &&
            process.env.REACT_APP_ENVIRONMENT === 'production'
          ) {
            props.history.push(ROUTE_CONST.ONBOARDING);
          } else if (
            !data.is_widget_onboarding_completed &&
            !data.is_channel_partner &&
            process.env.REACT_APP_ENVIRONMENT !== 'production'
          ) {
            props.history.push(ROUTE_CONST.WIDGET_ONBOARDING);
          } else {
            props.history.push(ROUTE_CONST.BUSINESS_DASHBOARD);
          }
        } else if (data.account_type === 4) {
          props.history.push(ROUTE_CONST.EXCHANGE_GET_APPLICATION);
        } else if (data.account_type === 5) {
          props.history.push(ROUTE_CONST.BUSINESS_REFERRALS);
        } else {
          props.history.push(ROUTE_CONST.ONRAMPS);
        }

        // stopLoading();
      })
      .catch((err) => {
        // stopLoading();

        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        if (errorMessage === 'err_20') {
          localStorage.setItem(CONST.EMAIL, values.email.toLowerCase());
          props.history.push(ROUTE_CONST.VERIFY_ACCOUNT);
        } else {
          notifyError(notificationMessageFunction(errorMessage));
        }
      });
  };

  return (
    <React.Fragment>
      <div className='home-btn d-none d-sm-block'>
        <Link to='/'>
          <i className='mdi mdi-home-variant h2 text-white'></i>
        </Link>
      </div>

      <div>
        <Container fluid className='p-0'>
          <Row className='no-gutters'>
            <Col lg={4}>
              <div className='authentication-page-content p-4 d-flex align-items-top min-vh-100'>
                <div className='w-100'>
                  <Row className='justify-content-center'>
                    <Col lg={9}>
                      <div>
                        <div className='text-left'>
                          <h4 className='font-size-32 mt-4 text-black'>
                            {CONST.WELCOME_RAMPNALYSIS}
                          </h4>
                          <p className='text-muted'>
                            <span className='text-black'>
                              {CONST.ENTER_YOUR_EMAIL_PASSWORD}
                            </span>
                          </p>
                        </div>

                        <div className='p-2 mt-5'>
                          <Formik
                            enableReinitialize
                            initialValues={{
                              email: paramsEmail ? paramsEmail : '',
                              password: paramsPassword ? paramsPassword : ''
                            }}
                            validationSchema={LogInSchema}
                            onSubmit={handleSubmit}>
                            {({ errors, touched, values, handleChange }) => (
                              <Form>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='email' className='text-black'>
                                      Email address
                                    </Label>
                                    <AuthInput
                                      type='text'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_EMAIL}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      name='email'
                                    />
                                  </FormGroup>
                                </div>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='email' className='text-black'>
                                      Password
                                    </Label>
                                    <AuthInput
                                      type='password'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_PASSWORD}
                                      handleChange={handleChange}
                                      isPassword
                                      errors={errors}
                                      touched={touched}
                                      name='password'
                                    />
                                  </FormGroup>
                                </div>
                                <div className='mt-3 text-center w-100 waves-effect waves-light'>
                                  <Button
                                    type='submit'
                                    name='btn'
                                    className={`btn ${
                                      !values.email ||
                                      !values.password ||
                                      errors.email ||
                                      errors.password
                                        ? 'btn-auth-disable'
                                        : 'btn-auth'
                                    }`}
                                    onSubmit={(values) => handleSubmit(values)}>
                                    {BUTTON_CONST.SIGN_IN}
                                  </Button>
                                </div>
                                <div className='mt-4 text-left'>
                                  <span className='text-black'>
                                    {CONST.CREATE_AN_ACCOUNT}{' '}
                                    <Link
                                      to={ROUTE_CONST.REGISTER}
                                      className='text-muted'>
                                      <span className='text-black'>
                                        <u>{CONST.CLICK_HERE}</u>
                                      </span>
                                    </Link>
                                  </span>
                                </div>
                                <div className='text-dark mt-2 text-left'>
                                  <span className='text-black'>
                                    {CONST.FORGOT_PASSWORD}{' '}
                                    <Link to='/forgot-password' className='text-muted'>
                                      <span className='text-black'>
                                        <u>{CONST.CLICK_HERE}</u>
                                      </span>
                                    </Link>
                                  </span>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <RightLogo />
            </Col>
          </Row>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default Login;
