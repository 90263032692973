import React from 'react';
import { Col, Row } from 'reactstrap';
import { Link } from 'react-router-dom';
import { constants as ROUTE_CONST } from '../../constants/routes';

import './CreateApplication.css';

function CreateApplication() {
  return (
    <React.Fragment>
      <Col lg={12} md={12} sm={12} className='d-flex justify-content-around'>
        <Row>
          <Col lg={6} md={6} sm={12}>
            <Link to={{ pathname: ROUTE_CONST.CREATE_ONRAMP_APPLICATION }}>
              <div
                id='create-onramp-app'
                className='application-frame create-new-application'>
                <div className='d-flex justify-content-center'>
                  <lord-icon
                    src='https://cdn.lordicon.com/zkfbupwq.json'
                    trigger='hover'
                    target='#create-onramp-app'
                    colors='outline:#121331,primary:#3a3347,secondary:#ee8f66,tertiary:#ffc738,quaternary:#ebe6ef'
                    style={{ width: '200px', height: '200px' }}></lord-icon>
                </div>
                <p style={{ marginTop: 20 }}>Create OnRamp Application</p>
                <p className='hidden-text'>Apply to multiple onramps in one go</p>
              </div>
            </Link>
          </Col>
          <Col lg={6} md={6} sm={12}>
            <Link to={{ pathname: ROUTE_CONST.CREATE_EXCHANGE_APPLICATION }}>
              <div
                id='create-exchange-app'
                className='application-frame create-new-application'>
                <div className='d-flex justify-content-center'>
                  <lord-icon
                    src='https://cdn.lordicon.com/tyaowhjv.json'
                    trigger='hover'
                    target='#create-exchange-app'
                    colors='primary:#646e78,secondary:#121331,tertiary:#ffc738'
                    style={{ width: '200px', height: '200px' }}></lord-icon>
                </div>

                <p style={{ marginTop: 20 }}>Create Exchange Application</p>
                <p className='hidden-text'>Apply to Tier 1-3 exchanges in one go</p>
              </div>
            </Link>
          </Col>
        </Row>
      </Col>
    </React.Fragment>
  );
}

export default CreateApplication;
