import api from '../api/userApi';
import * as url from '../api/userURL';
import CryptoJS from 'crypto-js';

// Import key and IV
const key = CryptoJS.enc.Hex.parse(process.env.REACT_APP_ENCRYPTION_KEY);
const iv = CryptoJS.enc.Hex.parse(process.env.REACT_APP_IV_KEY);

// Decrypt text function
export function decryptText(text) {
  if (text === null || typeof text === 'undefined') return text;
  const encryptedHexStr = CryptoJS.enc.Hex.parse(text);
  const encryptedBase64Str = CryptoJS.enc.Base64.stringify(encryptedHexStr);

  const decrypted = CryptoJS.AES.decrypt(encryptedBase64Str, key, {
    iv: iv,
    mode: CryptoJS.mode.CBC,
    padding: CryptoJS.pad.Pkcs7
  });
  return decrypted.toString(CryptoJS.enc.Utf8);
}

// User Login API
export const userAuthLogin = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.LOGIN_URL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userAuthRegister = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.REGISTER_URL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const cloneBusinessAccountToProd = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.CLONE_TO_PROD)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userForgotPassword = (query) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.FORGOT_PASSWORD + `?email=${query}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const userResetPassword = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.RESET_PASSWORD, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const deleteUserAccount = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithTokenData(url.DELETE_USER_ACCOUNT, paydata)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AuthVerifyEmail = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.VERIFICATION, paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const resendVerificationEmail = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.RESEND_VERIFICATION, paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const OTPCheckAPI = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .post(url.VERIFY_OTP, paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetMyProfileAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.MY_PROFILE)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const UpdateMyProfileAPI = (paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.MY_PROFILE, paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const RampListAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.RAMP_LIST)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ExchangeListAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.EXCHANGE_LIST)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const inviteUser = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.INVITE_USER, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const VerifyInvite = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.VERIFY_INVITE, data)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const inviteUserList = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.INVITE_USER)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const RevenuePercentageAPI = ({ user_id, payload }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.REVENUE_PERCENTAGE + `?user_id=${user_id}`, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetReferralDetailAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.REFERRAL)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddReferralDetailAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.REFERRAL, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const EditReferralDetailAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .putWithToken(url.REFERRAL, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ReferralListAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.REFERRAL_LIST)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ReferralCodeDetailsAPI = (referral_code) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(url.REFERRAL_CODE + `?referral_code=${referral_code}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetStreamUserDataAPI = (applicationId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_STREAM_USER + `?application_id=${applicationId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const CreateStreamUserAPI = (applicationId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.CREATE_STREAM_USER + `?application_id=${applicationId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const CreateStreamChannelAPI = (applicationId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.CREATE_STREAM_CHANNEL + `?application_id=${applicationId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetStreamTokenAPI = (applicationId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.GET_STREAM_TOKEN + `?application_id=${applicationId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const SendStreamMessageAPI = (channel_id, paydata) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.STREAM_SYSTEM_MESSAGE + `?channel_id=${channel_id}`, paydata)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetChannelsDetailsAPI = (userId) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.QUERY_CHANNEL + `?userId=${userId}`)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const InviteReviewerAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.INVITE_REVIEWER, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetReviewersAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.INVITE_REVIEWER)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetWidgetReferralDetailAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.WIDGET_REFERRAL)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddWidgetReferralDetailAPI = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(url.WIDGET_REFERRAL, payload)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetWidgetReferredUsersAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(url.WIDGET_REFERRAL_USERS)
      .then((response) => {
        resolve(response);
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
