import { Field } from 'formik';
import React from 'react';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../../constants/application';
import documentUploadedIcon from '../../../../assets/images/documentuploaded.png';
import documentUploadIcon from '../../../../assets/images/documentupload.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';

function WalletFunds({
  CustomFieldInput,
  values,
  errors,
  touched,
  handleChange,
  optionalFile,
  fileUrls,
  changeHandler
}) {
  return (
    <div className='mt-2 ml-2'>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-3'>
            <div className='font-size-12'>
              <Label
                htmlFor='product_custodial'
                className='text-black font-weight-normal'>
                {CONST.PRODUCT_CUSTODIAL_OR_NOT}
              </Label>
            </div>
            <div
              className='d-flex direction-row'
              role='group'
              aria-labelledby='my-radio-group'>
              <div className='d-flex align-items-center mr-4'>
                <Field
                  name='product_custodial'
                  type='radio'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='mt-2 ml-2 font-size-12'>{CONST.CUSTODIAL}</Label>
              </div>
              <div className='d-flex align-items-center ml-4'>
                <Field
                  name='product_custodial'
                  type='radio'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='mt-2 ml-2 font-size-12'>{CONST.NON_CUSTODIAL}</Label>
              </div>
            </div>
            <div className='d-flex'>
              {errors['product_custodial'] && touched['product_custodial'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['product_custodial']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-3 font-size-12'>
            <div className='font-size-12'>
              <Label
                htmlFor='user_access_to_wallet'
                className='text-black font-weight-normal'>
                {CONST.ACCESS_CONTROL_FUNDS}
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='d-flex align-items-center mr-4'>
                <Field
                  name='user_access_to_wallet'
                  type='radio'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-2'>{CONST.YES}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='user_access_to_wallet'
                  type='radio'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2  mt-2'>{CONST.NO}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='user_access_to_wallet'
                  type='radio'
                  value='null'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-2'>{CONST.NA}</Label>
              </div>
            </div>
            <div className='d-flex'>
              {errors['user_access_to_wallet'] && touched['user_access_to_wallet'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['user_access_to_wallet']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-3 font-size-12'>
            <div className='font-size-12'>
              <Label
                htmlFor='pre_populate_wallet_address'
                className='text-black font-weight-normal'>
                Are you planning to auto-fill wallet addresses during integration?
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='d-flex align-items-center  mr-4'>
                <Field
                  name='pre_populate_wallet_address'
                  type='radio'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-2'>{CONST.YES}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='pre_populate_wallet_address'
                  type='radio'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2  mt-2'>{CONST.NO}</Label>
              </div>
              <div className='d-flex align-items-center  ml-4 mr-4'>
                <Field
                  name='pre_populate_wallet_address'
                  type='radio'
                  value='null'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2  mt-2'>{CONST.NA}</Label>
              </div>
            </div>
            <div className='d-flex'>
              {errors['pre_populate_wallet_address'] &&
                touched['pre_populate_wallet_address'] && (
                  <span style={{ fontSize: 12 }} className='text-left text-danger'>
                    {errors['pre_populate_wallet_address']}
                  </span>
                )}
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='font-size-12 mb-3'>
            <div className='font-size-12'>
              <Label
                htmlFor='crypto_to_other_party'
                className='text-black font-weight-normal'>
                Will the cryptocurrency be directed somewhere other than the user's
                designated wallet?
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='d-flex align-items-center  mr-4'>
                <Field
                  name='crypto_to_other_party'
                  type='radio'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2 mt-2'>{CONST.YES}</Label>
              </div>
              <div className='d-flex align-items-center ml-4 mr-4'>
                <Field
                  name='crypto_to_other_party'
                  type='radio'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2  mt-2'>{CONST.NO}</Label>
              </div>
              <div className='d-flex align-items-center  ml-4 mr-4'>
                <Field
                  name='crypto_to_other_party'
                  type='radio'
                  value='null'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='ml-2  mt-2'>{CONST.NA}</Label>
              </div>
            </div>
            <div className='d-flex'>
              {errors['crypto_to_other_party'] && touched['crypto_to_other_party'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['crypto_to_other_party']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <CustomFieldInput
            labelName={'Describe how you will be integrating an onramp'}
            filedName='describe_ramp_services'
            placeholder={CONST.DESCRIPTION}
            values={values}
            handleChange={handleChange}
            errors={errors}
            inputType={'textarea'}
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-2'>
            <div className='font-size-12'>
              <Label
                htmlFor={'whitepapers'}
                id='whitepapers_doc'
                className='text-black font-weight-normal'>
                Optional
              </Label>
            </div>
            <div
              style={{
                borderRadius: '8px',
                border: '1px solid',
                height: 47
              }}
              className='d-flex direction-row justify-content-between align-items-center pl-1'>
              <Label htmlFor='whitepapers'>
                <div className='cursor-pointer'>
                  <span
                    className='rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 14
                    }}>
                    <img
                      src={
                        fileUrls['whitepapers_doc'] || optionalFile['whitepapers_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='Whitepapers'></img>
                  </span>
                  <span className='font-size-12 ml-2'>Whitepapers</span>
                </div>
              </Label>
              <div>
                {fileUrls.whitepapers_doc && (
                  <a
                    href={fileUrls.whitepapers_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
              </div>
              <Input
                type='file'
                id='whitepapers'
                name='whitepapers_doc'
                onChange={changeHandler}
                style={{ display: 'none' }}
              />
              <ReactTooltip
                anchorId='whitepapers_doc'
                place='bottom'
                content={
                  optionalFile['whitepapers_doc'] && optionalFile['whitepapers_doc'].name
                }
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-2 mt-2'>
            <div className='font-size-12'>
              <Label
                htmlFor={'integration_flow'}
                id='integration_flows_doc'
                className='text-black font-weight-normal'></Label>
            </div>
            <div
              style={{
                borderRadius: '8px',
                border: '1px solid',
                height: 47
              }}
              className='d-flex direction-row justify-content-between align-items-center pl-1'>
              <Label htmlFor='integration_flow'>
                <div className='cursor-pointer'>
                  <span
                    className='rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 14
                    }}>
                    <img
                      src={
                        fileUrls['integration_flows_doc'] ||
                        optionalFile['integration_flows_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='IntegrationFlows'></img>
                  </span>
                  <span className='font-size-12 ml-2'>Integration Flows</span>
                </div>
              </Label>
              <div>
                {fileUrls.integration_flows_doc && (
                  <a
                    href={fileUrls.integration_flows_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
              </div>
              <Input
                type='file'
                id='integration_flow'
                name='integration_flows_doc'
                onChange={changeHandler}
                style={{ display: 'none' }}
              />
              <ReactTooltip
                anchorId='integration_flows_doc'
                place='bottom'
                content={
                  optionalFile['integration_flows_doc'] &&
                  optionalFile['integration_flows_doc'].name
                }
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mb-3'>
            <div className='font-size-12'>
              <Label
                htmlFor={'flow_diagram'}
                id='flow_of_funds_diagram_doc'
                className='text-black font-weight-normal'></Label>
            </div>
            <div
              style={{
                borderRadius: '8px',
                border: '1px solid',
                height: 47
              }}
              className='d-flex direction-row justify-content-between align-items-center pl-1'>
              <Label htmlFor='integration_flow'>
                <div className='cursor-pointer'>
                  <span
                    className='rounded-circle'
                    style={{
                      color: '#134a87',
                      fontSize: 14
                    }}>
                    <img
                      src={
                        fileUrls['flow_of_funds_diagram_doc'] ||
                        optionalFile['flow_of_funds_diagram_doc']
                          ? documentUploadedIcon
                          : documentUploadIcon
                      }
                      alt='IntegrationFlows'></img>
                  </span>
                  <span className='font-size-12 ml-2'>Flow of Funds Diagram</span>
                </div>
              </Label>
              <div>
                {fileUrls.flow_of_funds_diagram_doc && (
                  <a
                    href={fileUrls.flow_of_funds_diagram_doc}
                    target='_blank'
                    rel='noopener noreferrer'
                    className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                    View File
                  </a>
                )}
              </div>
              <Input
                type='file'
                id='flow_diagram'
                name='flow_of_funds_diagram_doc'
                onChange={changeHandler}
                style={{ display: 'none' }}
              />
              <ReactTooltip
                anchorId='flow_of_funds_diagram_doc'
                place='bottom'
                content={
                  optionalFile['flow_of_funds_diagram_doc'] &&
                  optionalFile['flow_of_funds_diagram_doc'].name
                }
              />
            </div>
          </FormGroup>
        </Col>
      </Row>
    </div>
  );
}

export default WalletFunds;
