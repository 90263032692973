import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import {  notifyError } from '../../../Custom/notification';
import { DeleteNotifications, GetApplicationNotifications } from '../../../services/Business/Application';
import imageLogo from '../../../assets/images/users/avatar-5.jpg';
import TimeAgo from 'react-timeago'

function ApplicationNotifications(props) {
  const [notifications, setNotifications] = useState({});
  const [loading, setLoading] = useState(false);
  const [applicationId] = useQueryParam('application_id', StringParam);
  

  useEffect(() => {
    setLoading(true);
    getAllNotificationList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

const getAllNotificationList = () => {
  GetApplicationNotifications(applicationId)
      .then((res) => {
        setLoading(false);
        const data = res && res.data && res.data.data && res.data.data.data;
        setNotifications(data.response);
        return data;
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
}
  const handleClose = (id) => {
    
  setLoading(true);
    DeleteNotifications(id)
      .then((res) => {
        setLoading(false);
        getAllNotificationList();
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <React.Fragment>
      <section className="section-50">
        <div className="container">
          <h3 className="m-b-50 heading-line">Notifications <i className="fa fa-bell text-muted"></i></h3>
          <div className="notification-ui_dd-content">
            {notifications &&
            notifications.length > 0 &&
            notifications.map((notification, key) => (
              <div className="notification-list notification-list--unread">
                <div className="notification-list_content">
                  <div className="notification-list_img">
                  {notification.logo_url ? <img src={notification.logo_url} alt="user" /> : <img  src={imageLogo} alt="user" />}
                  </div>
                  <div className="notification-list_detail">
                    <p><b>{notification.title}</b></p>
                    {notification.field && notification.field.length > 0 && (
                      <p className="text-muted">
                        Edit required in{' '}
                        {Object.keys(notification.field).map((value, index) => (
                          <div key={index} className='text-black'>{value}</div>
                        ))}
                      </p>
                    )} 
                    {notification?.notes && notification.notes.length > 0 && (
                      <p className="text-muted">
                          Notes from Compliance:{' '}{notification.notes}
                      </p>
                    )}
                    <p className="text-muted">
                      <small>
                        <TimeAgo date={notification.created_at} formatter={(value, unit, suffix) => {
                          if (value < 1 && unit === "second") {
                            return "just now";
                          } else {
                            return value + " " + unit + (value > 1 ? "s" : "") + " " + suffix;
                          }
                        }} />
                      </small>
                    </p>
                  </div>
                </div>
                <div className="notification-list_feature-img">
                  <button className='close' aria-label="Close" onClick={() => handleClose(notification._id)}>
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
              </div>
            ))}
          </div>
        </div>
      </section>
    </React.Fragment>
  );
}

export default ApplicationNotifications;
