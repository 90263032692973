import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const updateSettings = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.APPLICATION_SETTINGS, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getSettings = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.APPLICATION_SETTINGS, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
