import React from 'react';
import { constants as CONST } from '../../constants/constant';
import SimpleBar from 'simplebar-react';
import { Link } from 'react-router-dom';
import { SizeMe } from 'react-sizeme';
import { get } from 'lodash';

function AvailableOnRamps({ cryptoData, className = '' }) {
  return (
    <div className={'p-4 card ' + className}>
      <div className="d-flex flex-wrap align-items-center mb-4">
        <div className="font-size-24 fomt-weight-medium text-dark flex-grow-1">
          {CONST.AVAILABLE_ON_RAMPS}
        </div>
      </div>

      <SizeMe monitorHeight>
        {({ size }) => (
          <div className="flex-grow-1 position-relative">
            <SimpleBar
              className="position-absolute position-cover"
              style={{
                maxHeight: size.height
              }}>
              {get(cryptoData, 'available_on_ramps', []).map((onRamp, key) => (
                <div
                  key={key}
                  className="d-flex align-items-center py-3"
                  style={{ borderBottom: '2px solid #F7F7F7' }}>
                  <div className="pr-4">
                    <img
                      src={onRamp.logo}
                      alt={onRamp.provider_name}
                      width={120}
                      style={{ maxHeight: '120px' }}
                      className="rounded-circle"
                    />
                  </div>
                  <div className="flex-fill text-truncate">
                    <Link
                      to={{
                        pathname: `/provider`,
                        search: `?provider=${onRamp.provider_id}`
                      }}
                      className="font-size-24 font-weight-bold mb-1">
                      {onRamp.provider_name}
                    </Link>
                    <div className="font-size-14 text-muted mb-3">
                      <div className="text-truncate">
                        Buy: {get(onRamp, 'currency', []).join(', ')}
                      </div>
                      <div className="text-truncate">
                        Sell: {get(onRamp, 'currency', []).join(', ')}
                      </div>
                    </div>
                    <div className="d-flex align-items-center">
                      {get(onRamp, 'payment_methods', [])
                        .slice(0, 5)
                        .map((option) => (
                          <img className="mr-3" src={option.logo} height={20} alt="" />
                        ))}
                      {onRamp.payment_methods && onRamp.payment_methods.length > 0 && (
                        <div
                          style={{
                            borderRight: '1px solid rgba(140, 146, 151, 0.6)',
                            height: 28
                          }}></div>
                      )}
                      {get(onRamp, 'assets', [])
                        .slice(0, 5)
                        .map((asset) => (
                          <img
                            src={asset.logo}
                            alt={asset.crypto_name}
                            height={32}
                            className="rounded-circle bg-light ml-3"
                          />
                        ))}
                    </div>
                  </div>
                </div>
              ))}
            </SimpleBar>
          </div>
        )}
      </SizeMe>
    </div>
  );
}

export default AvailableOnRamps;
