import React, { useEffect, useRef, useState } from 'react';
import { Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../Custom/AuthInput';
import {
  AddReferralDetailAPI,
  EditReferralDetailAPI,
  GetReferralDetailAPI
} from '../../services/Auth';
import * as copy from 'copy-to-clipboard';
import { notifyError, notifySuccess } from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import Reviewer from './Reviewer';

const styles = {
  imagePreview: {
    width: '200px',
    height: '200px',
    objectFit: 'contain',
    border: '1px solid grey',
    borderRadius: '8px'
  }
};

const resizeImage = (file, maxWidth, maxHeight, callback) => {
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = (event) => {
    const img = new Image();
    img.src = event.target.result;
    img.onload = () => {
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');

      let width = img.width;
      let height = img.height;

      const scale = Math.min(maxWidth / width, maxHeight / height);
      if (scale < 1) {
        width = width * scale;
        height = height * scale;
      }

      canvas.width = width;
      canvas.height = height;

      if (file.type === 'image/png') {
        ctx.fillStyle = 'rgba(0, 0, 0, 0)';
      }

      ctx.drawImage(img, 0, 0, width, height);

      const imageFormat = file.type === 'image/jpeg' ? 'image/jpeg' : 'image/png';
      callback(canvas.toDataURL(imageFormat));
    };
  };
};

function ReferralSettings() {
  const [initialValues, setInitialValues] = useState({
    referral_code: '',
    brand_logo: null,
    subdomain: ''
  });

  const [referralDetail, setReferralDetail] = useState({});
  const [applicationCheck, setApplicationCheck] = useState([]);

  const [isUpdate, setIsUpdate] = useState(false);

  const fileInputRef = useRef(null);

  const handleFileButtonClick = () => {
    fileInputRef.current.click();
  };

  const validationSchema = Yup.object({
    referral_code: Yup.string()
      .required('Referral Code is Required')
      .matches(/^[^\s]+$/, 'Referral Code must not contain white spaces'),
    subdomain:
      process.env.REACT_APP_ENVIRONMENT === 'production'
        ? Yup.string()
            .required('Subdomain is Required')
            .matches(/^(?!.*\/\/)(?!.*:).*$/, 'Subdomain must not contain // or :')
            .matches(
              /^(?!.*\/\/)(?!.*:)(?!.*\.)[^.]*$/,
              'Subdomain must not contain //, :, or .'
            )
        : Yup.string()
  });

  const handleReferrals = (values) => {
    const payloadData = new FormData();
    payloadData.append('referral_code', values.referral_code);
    payloadData.append('subdomain', values.subdomain);
    if (values.brand_logo) {
      payloadData.append('brand_logo', values.brand_logo);
    }

    payloadData.append('applications', JSON.stringify(applicationCheck));

    if (isUpdate) {
      EditReferralDetailAPI(payloadData)
        .then((res) => {
          const referralData = res && res.data && res.data.data && res.data.data.data;
          setReferralDetail(referralData);
          notifySuccess('Referral updated successfully');
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          notifyError(notificationMessageFunction(errorMessage));
        });
    } else {
      AddReferralDetailAPI(payloadData)
        .then((res) => {
          const referralData = res && res.data && res.data.data && res.data.data.data;
          setReferralDetail(referralData);
          notifySuccess('Referral added successfully');
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  const getReferralsDetailFunction = () => {
    GetReferralDetailAPI()
      .then((res) => {
        const referralData = res && res.data && res.data.data && res.data.data.data;
        setReferralDetail(referralData);
        if (referralData && Object.keys(referralData).length > 0) {
          setInitialValues({
            referral_code: referralData.referral_code,
            subdomain: referralData.subdomain,
            brand_logo: referralData.brand_logo
          });
          setApplicationCheck(referralData.applications);
          setIsUpdate(true);
        } else {
          setIsUpdate(false);
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    getReferralsDetailFunction();
  }, []);

  const handleAppCheckboxChange = (applicationName) => {
    if (applicationCheck.includes(applicationName)) {
      const updatedAppCheck = applicationCheck.filter((app) => app !== applicationName);
      setApplicationCheck(updatedAppCheck);
    } else {
      setApplicationCheck([...applicationCheck, applicationName]);
    }
  };

  return (
    <Card body>
      <Row>
        <Col lg={4}>
          <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            enableReinitialize
            onSubmit={handleReferrals}>
            {({ setFieldValue, values, handleChange, errors, touched }) => (
              <Form>
                <div>
                  <div>
                    {values.brand_logo !== null &&
                    typeof values.brand_logo === 'object' ? (
                      <img
                        src={URL.createObjectURL(values.brand_logo)}
                        alt='Profile'
                        style={styles.imagePreview}
                      />
                    ) : values.brand_logo !== null &&
                      typeof values.brand_logo === 'string' ? (
                      <img
                        src={values.brand_logo}
                        alt='Profile'
                        style={styles.imagePreview}
                      />
                    ) : (
                      <lord-icon
                        src='https://cdn.lordicon.com/hstjrpbz.json'
                        trigger='hover'
                        style={styles.imagePreview}></lord-icon>
                    )}
                  </div>
                  <button
                    type='button'
                    className='btn btn-status-change mt-3'
                    onClick={handleFileButtonClick}
                    style={{ width: '200px' }}>
                    Upload your Brand logo
                  </button>

                  <input
                    type='file'
                    name='brand_logo'
                    style={{ display: 'none' }}
                    ref={fileInputRef}
                    onChange={(event) => {
                      const file = event.currentTarget.files[0];
                      resizeImage(file, 300, 300, (resizedImageBase64) => {
                        fetch(resizedImageBase64)
                          .then((res) => res.blob())
                          .then((resizedBlob) => {
                            const resizedFile = new File([resizedBlob], file.name, {
                              type: 'image/jpeg',
                              lastModified: Date.now()
                            });
                            setFieldValue('brand_logo', resizedFile);
                          });
                      });
                    }}
                  />
                </div>
                <div className='mb-3 mt-3'>
                  <FormGroup>
                    <Label htmlFor='apiKey' className='text-black font-size-12'>
                      Referral Code
                    </Label>
                    <AuthInput
                      type='text'
                      values={values}
                      placeholder={'Enter Referral Code e.g: ethereum-exchange'}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      name='referral_code'
                      className={'font-size-12'}
                    />
                  </FormGroup>
                </div>
                {process.env.REACT_APP_ENVIRONMENT === 'production' && (
                  <div className='mb-3 mt-3'>
                    <FormGroup>
                      <Label htmlFor='sub_domain' className='text-black font-size-12'>
                        Subdomain
                      </Label>
                      <Col
                        lg={5}
                        md={5}
                        sm={5}
                        className='d-flex flex-row justify-content-center align-items-center pl-0'>
                        <Input
                          type='text'
                          placeholder={'(e.g., mysite)'}
                          values={values.subdomain}
                          name='subdomain'
                          className={
                            errors.subdomain && touched.subdomain
                              ? `font-size-12 border-danger`
                              : `font-size-12 border-dark`
                          }
                          onChange={handleChange}
                        />
                        <span>.listus.xyz</span>
                      </Col>
                      {errors.subdomain && touched.subdomain ? (
                        <div className='text-danger font-size-12'>{errors.subdomain}</div>
                      ) : null}
                    </FormGroup>
                  </div>
                )}

                <div className='mb-4 d-flex flex-column'>
                  <span className='font-size-16 font-weight-normal'>
                    Select Applications
                  </span>
                  <div className='d-flex align-items-center mt-2'>
                    <input
                      type='checkbox'
                      className='cursor-pointer'
                      style={{ height: 14, width: 14 }}
                      onChange={() => handleAppCheckboxChange('onramp_app')}
                      checked={applicationCheck.includes('onramp_app')}
                    />
                    <span className='font-size-12 ml-2'>Onramp Application</span>
                  </div>
                  <div className='d-flex align-items-center mt-2'>
                    <input
                      type='checkbox'
                      className='cursor-pointer'
                      style={{ height: 14, width: 14 }}
                      onChange={() => handleAppCheckboxChange('exchange_app')}
                      checked={applicationCheck.includes('exchange_app')}
                    />
                    <span className='font-size-12 ml-2'>Exchange Application</span>
                  </div>
                  <div className='d-flex align-items-center mt-2'>
                    <input
                      type='checkbox'
                      className='cursor-pointer'
                      style={{ height: 14, width: 14 }}
                      onChange={() => handleAppCheckboxChange('smart_contract_app')}
                      checked={applicationCheck.includes('smart_contract_app')}
                    />
                    <span className='font-size-12 ml-2'>Smart Contract Application</span>
                  </div>
                  <div className='d-flex align-items-center mt-2'>
                    <input
                      type='checkbox'
                      className='cursor-pointer'
                      style={{ height: 14, width: 14 }}
                      onChange={() => handleAppCheckboxChange('marketing_app')}
                      checked={applicationCheck.includes('marketing_app')}
                    />
                    <span className='font-size-12 ml-2'>Marketing Application</span>
                  </div>
                </div>
                <div>
                  <button
                    type='submit'
                    name='btn'
                    className={`btn btn-auth`}
                    onSubmit={handleReferrals}
                    style={{ width: 150, marginLeft: 0 }}>
                    Update Referral
                  </button>
                </div>
              </Form>
            )}
          </Formik>
          <div className='mt-2'>
            {process.env.REACT_APP_ENVIRONMENT === 'development' && (
              <>
                <span className='mt-2 font-size-12'>
                  For your own custom domain such as: <code>brand.listus.xyz</code> sign
                  up to the production account.
                </span>
              </>
            )}
            {process.env.REACT_APP_ENVIRONMENT === 'production' && (
              <>
                {Object.keys(referralDetail).length > 0 && (
                  <div className='mt-3'>
                    <span className='mt-2'>Your Widget URL</span>
                    <div
                      className='p-2 d-flex justify-content-between'
                      style={{
                        border: '1px solid #ccc',
                        borderRadius: '8px',
                        background: '#e5e5ea'
                      }}>
                      <span className='d-flex align-items-center justify-content-center'>
                        https://{referralDetail.subdomain}.listus.xyz
                      </span>
                      <i
                        className='mdi mdi-content-copy font-size-20 cursor-pointer'
                        onClick={() => {
                          copy(`https://${referralDetail.subdomain}.listus.xyz`);
                          notifySuccess('Referral link copied successfully');
                        }}
                      />
                    </div>
                  </div>
                )}
              </>
            )}

            {Object.keys(referralDetail).length > 0 && (
              <div className='mt-3'>
                <span className='mt-2'>Your Referral Link</span>
                <div
                  className='p-2 d-flex justify-content-between'
                  style={{
                    border: '1px solid #ccc',
                    borderRadius: '8px',
                    background: '#e5e5ea'
                  }}>
                  <span className='d-flex align-items-center justify-content-center'>
                    {process.env.REACT_APP_PROD_FRONTEND}/register?referral=
                    {referralDetail.referral_code}
                  </span>
                  <i
                    className='mdi mdi-content-copy font-size-20 cursor-pointer'
                    onClick={() => {
                      copy(
                        `${process.env.REACT_APP_PROD_FRONTEND}/register?referral=${referralDetail.referral_code}`
                      );
                      notifySuccess('Referral link copied successfully');
                    }}
                  />
                </div>
              </div>
            )}
          </div>
        </Col>
        <Col lg={1}></Col>
        <Col lg={6}>
          <Reviewer />
        </Col>
      </Row>
    </Card>
  );
}

export default ReferralSettings;
