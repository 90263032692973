import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepSixteen(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const step = 11;
  const [initialValue, setInitialValue] = useState({
    interested_in_audit: false,
    audit_doc: ''
  });
  const [completeStep, setCompleteStep] = useState(0);
  const AuditingReportSchema = Yup.object().shape({
    interested_in_audit: Yup.string()
  });

  const [optionalFile, setOptionalFile] = useState({});
  const [fileUrls, setFileUrls] = useState({});
  const [notifyshowError, setNotifyError] = useState('');

  const changeHandler = (event) => {
    if (event.target.files[0])
      setOptionalFile({ ...optionalFile, [event.target.name]: event.target.files[0] });
    setFileUrls({ ...fileUrls, [event.target.name]: event.target.files[0] });
  };

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        let initialData = { interested_in_audit: false, audit_doc: '' };
        if (
          application.exchange_application &&
          application.exchange_application.interested_in_audit
        ) {
          initialData({
            ...initialData,
            interested_in_audit: application.exchange_application.interested_in_audit
          });
          setInitialValue({
            interested_in_audit: application.exchange_application.interested_in_audit,
            audit_doc: application.exchange_application.audit_doc
          });
        }
        if (
          application.exchange_application &&
          application.exchange_application.audit_doc
        ) {
          initialData({
            ...initialData,
            audit_doc: application.exchange_application.audit_doc
          });
        }
        setInitialValue(initialData);
        if (application.exchange_application.audit_doc) {
          setFileUrls({
            audit_doc: application.exchange_application.audit_doc
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    const data = new FormData();
    setNotifyError('');
    data.append('interested_in_audit', values.interested_in_audit);
    Object.keys(optionalFile).forEach((key) => {
      data.append(key, optionalFile[key]);
    });
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 71));
    data.append('percentage_complete', stepsRequired);
    setIsLoading(true);

    UpdateExchangeApplication({ application_id: applicationId }, data)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        props.setApplicationData(application);
        nextPage();
        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    setExchangeCompleteStep([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16]);
  };
  const calculateProgress = () => {
    return completeStep;
  };

  const checkboxHandler = () => {
    setInitialValue({
      ...initialValue,
      interested_in_audit: !initialValue.interested_in_audit
    });
    !initialValue.interested_in_audit
      ? setFileUrls({ ...fileUrls, audit_doc: '' })
      : setFileUrls({});
  };

  const ErrorFormik = () => {
    if (!!notifyshowError) {
      return (
        <div className={` text-left mt-1 text-danger`} style={{ fontSize: '14px' }}>
          <span>{notifyshowError}</span>
        </div>
      );
    }
    return <></>;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>Auditing report </span>
      </div>

      <Container className='col-12 col-sm-auto pt-2'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={AuditingReportSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange, setFieldValue }) => (
              <Form>
                <div>
                  <Row>
                    <Col lg={6} md={12} sm={12} className='mt-3'>
                      <FormGroup check className='d-flex align-items-center'>
                        <Label check>
                          <Input
                            type='checkbox'
                            name='interested_in_audit'
                            values={values}
                            onChange={() => checkboxHandler()}
                            checked={values.interested_in_audit}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}
                          />
                          <span className='font-size-12'>
                            Are you interested in receiving a Smart Contract audit.{' '}
                          </span>
                        </Label>
                      </FormGroup>
                    </Col>
                    <Col lg={6} md={12} sm={12} className='mt-1'>
                      <FormGroup>
                        <div
                          style={{
                            borderRadius: '8px',
                            border: '1px solid',
                            height: 47
                          }}
                          className='d-flex direction-row justify-content-between align-items-center pl-1'>
                          <Label htmlFor='audit' id='audit_doc'>
                            <span
                              className='pr-1 mb-1 rounded-circle'
                              style={{
                                color: '#134a87',
                                fontSize: 14,
                                cursor: 'pointer'
                              }}>
                              <img
                                src={
                                  values.ticker_logo ||
                                  optionalFile['audit_doc'] ||
                                  fileUrls.audit_doc
                                    ? documentUploadedIcon
                                    : documentUploadIcon
                                }
                                alt='audit'></img>
                            </span>
                            <span className='font-size-12'>Upload your Audit</span>
                          </Label>
                          {fileUrls.audit_doc ? (
                            <a
                              href={fileUrls.audit_doc}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                              View File
                            </a>
                          ) : values.audit_doc ? (
                            <a
                              href={values.audit_doc}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                              View File
                            </a>
                          ) : (
                            ''
                          )}
                          <Input
                            type='file'
                            id='audit'
                            name='audit_doc'
                            onChange={changeHandler}
                            style={{ display: 'none' }}
                          />
                          <ReactTooltip
                            anchorId='audit_doc'
                            place='bottom'
                            content={
                              optionalFile['audit_doc'] && optionalFile['audit_doc'].name
                            }
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <ErrorFormik />
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepSixteen;
