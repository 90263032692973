export const providersEnum = {
  MOONPAY: 'moonpay',
  BANXA: 'banxa',
  BTC_DIRECT: 'btc_direct',
  MERCURYO: 'mercuryo',
  RAMP: 'ramp',
  TRANSAK: 'transak',
  WYRE: 'wyre',
  XANPOOL: 'xanpool',
  ALCHEMY: 'alchemy',
  UNLIMIT: 'unlimit',
  MUNZEN: 'munzen',
  SWIPELUX: 'swipelux',
  ALL: 'all'
};
