import React, { useEffect, useState } from 'react';
import {
  Card,
  Col,
  Container,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane
} from 'reactstrap';
import { GetAllProvidersListAPI } from '../../services/Business/Providers';
import { constants as CONST } from '../../constants/constant';
import ProviderSettings from './ProviderSettings';
import ReferralSettings from './ReferralSettings';
import { NotificationToastContainer } from '../../Custom/notification';

function BusinessProviders() {
  const [providersList, setProvidersList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState('2');

  const getAllProviders = () => {
    setIsLoading(true);
    GetAllProvidersListAPI()
      .then((res) => {
        setProvidersList(res.data.data.data);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getAllProviders();
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <Col md={8} lg={8}>
            <Card>
              <Col>
                <Row>
                  <div className='form-inline float-md-left'>
                    <div className='search-box ml-2'>
                      <div className='py-3 pl-3'>
                        <span className='font-size-20 text-black'>{CONST.SETTINGS}</span>
                      </div>
                    </div>
                  </div>
                </Row>
              </Col>
              <Col md={12} lg={12}>
                <Row>
                  <Col lg={6}>
                    <Nav
                      tabs
                      className='nav-tabs-custom nav-justified'
                      style={{
                        borderBottom: '1px solid #8C9297'
                      }}>
                      {/* <NavItem>
                        <NavLink
                          style={{
                            cursor: 'pointer',
                            borderBottom: `${
                              activeTab === '1' ? '2px solid #181B30' : ''
                            }`
                          }}
                          onClick={() => {
                            setActiveTab('1');
                          }}>
                          <span className='d-none d-sm-block'>Referral Settings</span>
                        </NavLink>
                      </NavItem> */}
                      <NavItem>
                        <NavLink
                          style={{
                            cursor: 'pointer',
                            borderBottom: `${
                              activeTab === '2' ? '2px solid #181B30' : ''
                            }`
                          }}
                          onClick={() => {
                            setActiveTab('2');
                          }}>
                          <span className='d-none d-sm-block'>Providers Settings</span>
                        </NavLink>
                      </NavItem>
                    </Nav>
                  </Col>
                </Row>
              </Col>
              <Col md={12} lg={12}>
                <TabContent activeTab={activeTab}>
                  <TabPane tabId='1'>
                    <ReferralSettings
                      isLoading={isLoading}
                      providersList={providersList}
                      setIsLoading={setIsLoading}
                      setProvidersList={setProvidersList}
                    />
                  </TabPane>
                  <TabPane tabId='2'>
                    <ProviderSettings
                      isLoading={isLoading}
                      providersList={providersList}
                      setIsLoading={setIsLoading}
                      setProvidersList={setProvidersList}
                    />
                  </TabPane>
                </TabContent>
              </Col>
            </Card>
          </Col>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default BusinessProviders;
