export const constants = {
  ENTER_EMAIL: 'enter email address',
  ENTER_PASSWORD: 'enter password',
  ENTER_CONFIRM_PASSWORD: 'enter confirm password',
  ENTER_ON_RAMP_NAME: 'enter on ramp name',
  ENTER_COMPANY_NAME: 'enter company name',
  ENTER_EXCHANGE_NAME: 'enter Exchange name',
  ENTER_BRAND_NAME: 'enter brand name',
  ENTER_WEBSITE: 'enter website URL',
  ENTER_BUY_FEE_PERCENTAGE: 'enter buy percentage fee',
  ENTER_SELL_FEE_PERCENTAGE: 'enter buy percentage fee',
  ENTER_LOGO_URL: 'enter logo URL',
  ENTER_OTP: 'enter OTP'
};
