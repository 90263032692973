import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import { useQueryParam, StringParam } from 'use-query-params';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { AvailableOnRampForCrypto } from '../../services/Business/Search';
import CryptoChart from './CryptoChart';
import News from '../News/News';
import AvailableOnRamps from './AvailableOnRamps';
import BuySell from '../BuySellWidget/BuySell';

function Crypto(props) {
  const [cryptoData, setCryptoData] = useState(null);
  const [isLoaded, setIsLoaded] = useState(false);
  const [coinId] = useQueryParam('crypto_id', StringParam);
  const [coinName] = useQueryParam('coin_name', StringParam);
  const [coinGeckoId] = useQueryParam('coin_gecko_id', StringParam);

  const getAvailableProviders = (coinId) => {
    AvailableOnRampForCrypto(coinId)
      .then((res) => {
        setIsLoaded(true);
        const data = get(res, 'data.data.data');
        setCryptoData(data);
      })
      .catch((err) => {
        setIsLoaded(true);
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  useEffect(() => {
    if (coinId) {
      getAvailableProviders(coinId);
    } else {
      setIsLoaded(true);
    }
  }, [coinId]);

  return (
    <React.Fragment>
      <div className={'page-content ' + (!isLoaded && 'page-content-loading')}>
        {!isLoaded ? (
          <div className="h-100 d-flex align-items-center justify-content-center py-4">
            <i
              className="fas fa-circle-notch fa-spin text-muted"
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <>
            <div className="row mb-3 justify-content-center">
              {coinGeckoId && (
                <div className="col-12 col-lg-6">
                  <CryptoChart coinGeckoId={coinGeckoId} className="height-582px" />
                </div>
              )}
              {(coinName || coinGeckoId) && (
                <div className="col-12 col-lg-6">
                  <News search={coinName || coinGeckoId} className="height-582px" />
                </div>
              )}
              {coinId && (
                <div className="col-12 col-lg-6">
                  <div className="card">
                    {/* <iframe
                      title="Buy or sell"
                      className="w-100 height-650px"
                      allow="accelerometer; autoplay; camera; encrypted-media; gyroscope; payment"
                      src={`https://widget.rampnalysis.com/widget/?crypto=${coinName}`}
                      frameBorder="0"></iframe> */}
                    <iframe
                      title="Buy or sell"
                      className="w-100 height-620px"
                      allow="accelerometer; autoplay; camera; encrypted-media; gyroscope; payment"
                      src={`/buy-sell?crypto=${coinId}`}
                      frameBorder="0"></iframe>
                  </div>
                </div>
              )}
              {cryptoData && (
                <div className="col-12 col-lg-6">
                  <AvailableOnRamps cryptoData={cryptoData} className="height-620px" />
                </div>
              )}
            </div>
          </>
        )}
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default Crypto;
