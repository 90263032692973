import React from 'react';
import { Col } from 'reactstrap';

function SelectServices({
  applicationCheck,
  setApplicationCheck,
  handleUpdateReferralData,
  getReferralsDetailFunction
}) {
  const handleAppCheckboxChange = (applicationName) => {
    if (applicationCheck.includes(applicationName)) {
      const updatedAppCheck = applicationCheck.filter((app) => app !== applicationName);
      setApplicationCheck(updatedAppCheck);
    } else {
      setApplicationCheck([...applicationCheck, applicationName]);
    }
  };

  const handleApplicationCheckBox = () => {
    const payloadData = new FormData();
    payloadData.append('applications', JSON.stringify(applicationCheck));

    handleUpdateReferralData(
      payloadData,
      'Your application services updated successfully',
      2
    );
    getReferralsDetailFunction();
  };

  return (
    <React.Fragment>
      <Col lg={6} className='ml-2 mr-3'>
        <div className='mb-4 d-flex flex-column'>
          <span className='font-size-16 font-weight-normal'>Select Applications</span>
          <div className='d-flex align-items-center mt-2'>
            <input
              type='checkbox'
              className='cursor-pointer'
              style={{ height: 14, width: 14 }}
              onChange={() => handleAppCheckboxChange('onramp_app')}
              checked={applicationCheck.includes('onramp_app')}
            />
            <span className='font-size-12 ml-2'>Onramp Application</span>
          </div>
          <div className='d-flex align-items-center mt-2'>
            <input
              type='checkbox'
              className='cursor-pointer'
              style={{ height: 14, width: 14 }}
              onChange={() => handleAppCheckboxChange('exchange_app')}
              checked={applicationCheck.includes('exchange_app')}
            />
            <span className='font-size-12 ml-2'>Exchange Application</span>
          </div>
          <div className='d-flex align-items-center mt-2'>
            <input
              type='checkbox'
              className='cursor-pointer'
              style={{ height: 14, width: 14 }}
              onChange={() => handleAppCheckboxChange('smart_contract_app')}
              checked={applicationCheck.includes('smart_contract_app')}
            />
            <span className='font-size-12 ml-2'>Smart Contract Application</span>
          </div>
          <div className='d-flex align-items-center mt-2'>
            <input
              type='checkbox'
              className='cursor-pointer'
              style={{ height: 14, width: 14 }}
              onChange={() => handleAppCheckboxChange('marketing_app')}
              checked={applicationCheck.includes('marketing_app')}
            />
            <span className='font-size-12 ml-2'>Marketing Application</span>
          </div>
        </div>
        <div className='d-flex mb-3'>
          <button
            type='submit'
            name='btn'
            className={`btn btn-auth`}
            onClick={handleApplicationCheckBox}
            style={{ width: 120, marginLeft: 0 }}>
            Set Services
          </button>
        </div>
      </Col>
    </React.Fragment>
  );
}

export default SelectServices;
