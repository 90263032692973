import React, { useState } from 'react';
import { useEffect } from 'react';
//import SimpleBar from 'simplebar-react';
import { Link, useHistory } from 'react-router-dom';
import get from 'lodash/get';
import { useQueryParam, StringParam } from 'use-query-params';
import { stringify } from 'query-string';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';

import { constants as CONST } from '../../constants/constant';
import { constants as ROUTE_CONST } from '../../constants/routes';
import { BusinessSearchAPI } from '../../services/Business/Search';
import { AddOnRampTrial } from '../../services/Business/Crypto';

function SearchResult({
  className = '',
  title,
  getLinkTo,
  items,
  logoKey,
  labelKey,
  search_id
}) {
  let history = useHistory();

  return (
    <div className={'card p-4 mb-4' + className}>
      <div className="font-size-24 font-weight-medium text-black mb-3 pb-1 text-truncate">
        {title}
      </div>
      <div>
        {items &&
          items.length > 0 &&
          items.map((item, i) => (
            <Link
              // to={getLinkTo(item)}
              onClick={() => {
                let apiData = {};
                if (labelKey === 'network_name') {
                  apiData = { network_id: search_id };
                }
                if (labelKey === 'crypto_symbol') {
                  apiData = { crypto_id: search_id };
                }
                // AddOnRampTrial(apiData)
                //   .then((res) => {
                history.push(getLinkTo(item));
                // })
                // .catch((err) => {});
              }}
              key={i}
              className={
                'text-reset d-flex align-items-center ' + (i > 0 ? 'mt-3 pt-4' : '')
              }>
              <div className="pr-3">
                <img
                  src={item[logoKey]}
                  alt={item[labelKey]}
                  width={40}
                  style={{ maxHeight: '40px' }}
                />
              </div>
              <div className="flex-fill font-size-22 font-weight-medium text-uppercase">
                {item[labelKey]}
              </div>
            </Link>
          ))}
      </div>
    </div>
  );
}

function BusinessSearch() {
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [cryptoData, setCryptoData] = useState([]);
  const [networkData, setNetworkData] = useState([]);
  const [providerData, setProviderData] = useState([]);
  const [loading, setLoading] = useState(false);

  const searchAPIFunction = (search) => {
    setLoading(true);
    BusinessSearchAPI(search)
      .then((res) => {
        setLoading(false);
        const data = get(res, 'data.data.data');
        if (data.cryptos && data.cryptos.length > 0) {
          setCryptoData(data.cryptos);
        } else {
          setCryptoData([]);
        }

        if (data.networks && data.networks.length > 0) {
          setNetworkData(data.networks);
        } else {
          setNetworkData([]);
        }

        if (data.providers && data.providers.length > 0) {
          setProviderData(data.providers);
        } else {
          setProviderData([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  const searchFunction = (search) => {
    setSearch(search);
  };

  useEffect(() => {
    searchAPIFunction(search);
  }, [search]);

  return (
    <React.Fragment>
      <div className="page-content">
        <div className="ml-md-4 pl-md-3">
          <div className="row">
            <div className="col-12 col-md-6 col-lg-4">
              <div
                className={search && 'd-lg-none'}
                style={{
                  marginTop: 40 - 18,
                  marginBottom: 40
                }}>
                <input
                  type="text"
                  className="form-control form-control-search"
                  placeholder="Search"
                  defaultValue={search}
                  onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                      searchFunction(event.target.value);
                    }
                  }}
                  style={{ maxWidth: '368px' }}
                  autoFocus={true}
                />
              </div>
              {loading ? (
                <div className="py-4 d-flex align-items-center justify-content-center">
                  <i
                    className="fas fa-circle-notch fa-spin text-muted"
                    style={{ fontSize: 48 }}></i>
                </div>
              ) : (
                <>
                  {!get(cryptoData, 'length', 0) &&
                    !get(networkData, 'length', 0) &&
                    !get(providerData, 'length', 0) &&
                    !!search && <div className="text-muted">Nothing was found</div>}
                  {get(cryptoData, 'length', 0) > 0 && (
                    <SearchResult
                      className=""
                      title={CONST.CRYPTO}
                      getLinkTo={(item) => ({
                        pathname: ROUTE_CONST.CRYPTO,
                        search: `?${stringify({
                          crypto_id: item._id || null,
                          coin_gecko_id: item.coin_gecko_id,
                          coin_name: item.news_ticker
                        })}`
                      })}
                      items={cryptoData}
                      logoKey="logo"
                      labelKey="crypto_symbol"
                    />
                  )}
                  {get(networkData, 'length', 0) > 0 && (
                    <SearchResult
                      className=""
                      title={CONST.NETWORK}
                      getLinkTo={(item) => ({
                        pathname: `/network/${item._id}`
                      })}
                      items={networkData}
                      logoKey="logo"
                      labelKey="network_name"
                      search_id={(item) => item._id}
                    />
                  )}
                  {get(providerData, 'length', 0) > 0 && (
                    <SearchResult
                      className=""
                      title={CONST.PROVIDER}
                      getLinkTo={(item) => ({
                        pathname: `/provider`,
                        search: `?provider=${item.provider_id}`
                      })}
                      items={providerData}
                      logoKey="logo"
                      labelKey="provider_name"
                    />
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default BusinessSearch;
