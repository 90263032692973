import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepEleven(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const step = 11;
  const [initialValue, setInitialValue] = useState({
    marketing_budget_allocated_for_listing: ''
  });

  const CapMarketSchema = Yup.object().shape({
    marketing_budget_allocated_for_listing: Yup.string().required(
      'Marketing budget allocated for listing is required'
    )
  });
  const applicationData = props.applicationData.exchange_application;
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');

        if (application.exchange_application.marketing_budget_allocated_for_listing) {
          setInitialValue({
            marketing_budget_allocated_for_listing:
              application.exchange_application.marketing_budget_allocated_for_listing
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 57));
    if (
      values.marketing_budget_allocated_for_listing !==
      applicationData.marketing_budget_allocated_for_listing
    ) {
      const data = {
        marketing_budget_allocated_for_listing:
          values.marketing_budget_allocated_for_listing,
        percentage_complete: stepsRequired
      };
      setIsLoading(true);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          props.setApplicationData(application);
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
      setExchangeCompleteStep([0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11]);
    } else {
      nextPage();
    }
  };

  const calculateProgress = () => {
    return completeStep;
  };
  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>
          Optimizing Listing Success: Allocating an Effective Exchange Marketing Budget
        </span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={CapMarketSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, setFieldValue, handleChange }) => (
              <Form>
                <div>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='marketing_budget_allocated_for_listing'
                            className='text-black font-weight-normal'>
                            {CONST.MARKETINGBUDGETALLOCATEDFORLISTING}
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            type='text'
                            className='font-size-12'
                            name='marketing_budget_allocated_for_listing'
                            placeholder={'Enter marketing budget'}
                            values={values}
                            handleChange={(e) => {
                              if (e.target.value.endsWith('.')) {
                                setFieldValue(
                                  'marketing_budget_allocated_for_listing',
                                  e.target.value
                                );
                                return;
                              }

                              const rawValue = e.target.value.replace(/[^0-9.]/g, '');

                              const decimalValue =
                                rawValue.split('.').length > 2
                                  ? rawValue.slice(0, rawValue.lastIndexOf('.'))
                                  : rawValue;

                              const parts = decimalValue.split('.');
                              const formattedValue =
                                parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                                (parts[1] ? '.' + parts[1] : '');

                              if (formattedValue) {
                                setFieldValue(
                                  'marketing_budget_allocated_for_listing',
                                  '$' + formattedValue
                                );
                              } else {
                                setFieldValue(
                                  'marketing_budget_allocated_for_listing',
                                  '$0'
                                );
                              }
                            }}
                            errors={errors}
                            touched={touched}></AuthInput>
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default StepEleven;
