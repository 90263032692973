import { Field } from 'formik';
import React from 'react';
import { Col, FormGroup, Label, Row } from 'reactstrap';
import AuthInput from '../../../../Custom/AuthInput';
import { constants as CONST } from '../../../../constants/application';

function Certifications({ CustomFieldInput, values, errors, touched, handleChange }) {
  return (
    <div className='mt-2 ml-2'>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <FormGroup className='mb-4'>
            <div className='font-size-12'>
              <Label htmlFor='consent_info' className='text-black font-weight-normal'>
                I hereby certify, to the best of my knowledge, that the information
                provided in this questionnaire is complete and accurate.
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='custom-control form-check mr-4'>
                <Field
                  name='consent_info'
                  type='radio'
                  value='true'
                  className='form-check-input mt-1'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='font-size-12 form-check-label ml-2 mt-2'>
                  {CONST.YES}
                </Label>
              </div>
              <div className='custom-control form-check ml-4'>
                <Field
                  name='consent_info'
                  type='radio'
                  value='false'
                  className='form-check-input mt-1'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='font-size-12 form-check-label ml-2 mt-2'>
                  {CONST.NO}
                </Label>
              </div>
            </div>
            <div className='font-size-12 d-flex align-items-center'>
              {errors['consent_info'] && touched['consent_info'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['consent_info']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <FormGroup className='mb-4'>
            <div className='font-size-12'>
              <Label htmlFor='consent_OFAC' className='text-black font-weight-normal'>
                I hereby certify that the partner entity does not interact with or provide
                services to persons or entities subject to sanctions administered by the
                US Office of Foreign Assets Control ("OFAC") or those imposed by the UK
                Office of Financial Sanctions Implementation ("OFSI").
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='custom-control form-check  mr-4'>
                <Field
                  name='consent_OFAC'
                  type='radio'
                  className='form-check-input mt-1'
                  value='true'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='form-check-label ml-2 mt-2 font-size-12 '>
                  {CONST.YES}
                </Label>
              </div>
              <div className='custom-control form-check ml-4 mr-4'>
                <Field
                  name='consent_OFAC'
                  type='radio'
                  className='form-check-input mt-1'
                  value='false'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='form-check-label ml-2 mt-2 font-size-12 '>
                  {CONST.NO}
                </Label>
              </div>
              <div className='custom-control form-check  ml-4 mr-4'>
                <Field
                  name='consent_OFAC'
                  type='radio'
                  className='form-check-input mt-1'
                  value='null'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='form-check-label ml-2 mt-2 font-size-12'>
                  {CONST.NA}
                </Label>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              {errors['consent_OFAC'] && touched['consent_OFAC'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['consent_OFAC']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <FormGroup className='mb-4'>
            <div className='font-size-12'>
              <Label htmlFor='consent_region' className='text-black font-weight-normal'>
                I hereby certify that the partner entity does not do business in the
                following jurisdictions: Cuba, Iran, North Korea, Syria, Crimea region of
                Ukraine, Donetsk region of Ukraine, Luhansk region of Ukraine, or the
                Russian Federation.
              </Label>
            </div>
            <div className='d-flex direction-row align-items-center'>
              <div className='custom-control form-check  mr-4'>
                <Field
                  name='consent_region'
                  type='radio'
                  value='true'
                  className='form-check-input mt-1'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='form-check-label ml-2 mt-2 font-size-12 '>
                  {CONST.YES}
                </Label>
              </div>

              <div className='custom-control form-check  ml-4 mr-4'>
                <Label className='form-check-label font-size-12  mt-2'>
                  I do business in these regions
                </Label>
              </div>
              <AuthInput
                name='consent_region_name'
                type='text'
                className={'font-size-12'}
                values={values}
                handleChange={handleChange}
                placeholder={CONST.ENTER_REGION}
                errors={errors}
                touched={touched}
              />
            </div>
            <div className='d-flex align-items-center'>
              {errors['consent_region'] && touched['consent_region'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['consent_region']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={12} md={12} sm={12}>
          <FormGroup className='mb-4'>
            <div className='font-size-12'>
              <Label
                htmlFor='consent_age'
                className='text-black font-weight-normal font-size-12 '>
                I acknowledge that Rampnalysis and its Associated businesses may not
                provide services to sanctioned individuals, sanctioned jurisdictions, nor
                minors (individuals under the age of 18).
              </Label>
            </div>
            <div className='d-flex direction-row'>
              <div className='custom-control form-check  mr-4'>
                <Field
                  name='consent_age'
                  type='radio'
                  value='true'
                  className='form-check-input mt-1'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='form-check-label ml-2 mt-2 font-size-12'>
                  {CONST.YES}
                </Label>
              </div>
              <div className='custom-control form-check ml-4 mr-4'>
                <Field
                  name='consent_age'
                  type='radio'
                  value='false'
                  className='form-check-input mt-1'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='form-check-label ml-2 mt-2 font-size-12'>
                  {CONST.NO}
                </Label>
              </div>
              <div className='custom-control form-check  ml-4 mr-4'>
                <Field
                  name='consent_age'
                  type='radio'
                  value='null'
                  className='form-check-input mt-1'
                  style={{ height: '1.725rem', width: '1rem' }}
                />
                <Label className='form-check-label ml-2 mt-2 font-size-12'>
                  {CONST.NA}
                </Label>
              </div>
            </div>
            <div className='d-flex align-items-center'>
              {errors['consent_age'] && touched['consent_age'] && (
                <span style={{ fontSize: 12 }} className='text-left text-danger'>
                  {errors['consent_age']}
                </span>
              )}
            </div>
          </FormGroup>
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <CustomFieldInput
            labelName={CONST.FULL_NAME}
            filedName='applicant_full_name'
            placeholder={CONST.ENTER_FULL_NAME}
            values={values}
            handleChange={handleChange}
            errors={errors}
            inputType={'text'}
            touched={touched}
          />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <CustomFieldInput
            labelName={CONST.EMAIL}
            filedName='applicant_email'
            placeholder={CONST.ENTER_EMAIL}
            values={values}
            handleChange={handleChange}
            errors={errors}
            inputType={'text'}
            touched={touched}
          />
        </Col>
      </Row>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <CustomFieldInput
            labelName={CONST.ROLE}
            filedName='applicant_role'
            placeholder={CONST.ENTER_ROLE}
            values={values}
            handleChange={handleChange}
            errors={errors}
            inputType={'text'}
            touched={touched}
          />
        </Col>
        <Col lg={6} md={12} sm={12}>
          <CustomFieldInput
            labelName={CONST.DATE}
            filedName='application_date'
            placeholder={''}
            values={values}
            handleChange={handleChange}
            errors={errors}
            inputType={'text'}
            touched={touched}
          />
        </Col>
      </Row>
    </div>
  );
}

export default Certifications;
