import React, { useState } from 'react';
import UpdateProvider from './UpdateProvider';
import { UpdateProvidersAPI } from '../../services/Business/Providers';

function GetProvider({ provider, index, setProvidersList, setIsLoading }) {
  const [modalOpen, setModalOpen] = useState(false);

  const updateProvider = (payload) => {
    setIsLoading(true);
    UpdateProvidersAPI(payload)
      .then((res) => {
        setProvidersList(res.data.data.data);
        setModalOpen(false);
        setIsLoading(false);
      })
      .catch((err) => {});
  };

  return (
    <tr className='center-text' key={index}>
      <td className='pl-4'>{index + 1}</td>
      <td className='p-4' style={{ maxWidth: '300px' }}>
        <img src={provider.logo} height={40} alt='user' />
      </td>
      <td className='font-size-14 font-weight-bold'>{provider.provider_name}</td>
      <td className='font-size-14 font-weight-bold'>
        {provider.is_active ? (
          <span className='badge badge-success py-1 px-2'>Is Active</span>
        ) : (
          <span className='badge badge-danger py-1 px-2'>Blocked</span>
        )}
      </td>
      <td>
        <button
          className='btn btn-status-change font-weight-bold'
          style={{ width: '150px' }}
          onClick={() => setModalOpen(true)}>
          Change Status
        </button>
      </td>
      {UpdateProvider({ modalOpen, setModalOpen, provider, updateProvider })}
    </tr>
  );
}

export default GetProvider;
