/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';

function TransakWidget({ backToWidget, buyData }) {
  return (
    <>
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light p-1 mb-2"
          style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
          onClick={backToWidget}>
          <ArrowLeftIcon width={18} height={18} />
        </button>
      </div>
      <div
        style={{
          position: 'relative',
          width: '500px',
          height: '80dvh',
          margin: 'auto',
          boxShadow: '0 0 5px',
          borderRadius: '15px',
          overflow: 'hidden'
        }}>
        <iframe
          src={`${process.env.REACT_APP_TRANSAK_API}?apiKey=${process.env.REACT_APP_TRANSAK_API_KEY}&cryptoCurrencyCode=${buyData.cryptoCurrency}&network=${buyData.network}&fiatCurrency=${buyData.fiat_currency}&fiatAmount=${buyData.fiat_value}&defaultPaymentMethod=${buyData.payment_method}`}
          allow="camera;microphone;fullscreen;payment"
          style={{ height: '100%', width: '100%', border: 'none' }}></iframe>
      </div>
    </>
  );
}

export default TransakWidget;
