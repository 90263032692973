import React, { useState } from 'react';
import { Button, Input, Modal, ModalBody, ModalHeader, FormFeedback } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';

const PriorityModal = ({
  isModalOpen,
  application,
  setIsModalOpen,
  priorityApplicationChange,
  isMass = false,
  applicationCheck
}) => {
  const [prioritySuggestions] = useState([
    { id: 0, name: 'None' },
    { id: 1, name: 'Low' },
    { id: 2, name: 'Medium' },
    { id: 3, name: 'High' }
  ]);
  const validationSchema = Yup.object().shape({
    priority: Yup.string().required('Priority is required')
  });

  const formik = useFormik({
    initialValues: {
      priority: isMass
        ? ''
        : application &&
          application.sub_application &&
          application.sub_application.priority
        ? application.sub_application.priority
        : ''
    },
    validationSchema,
    onSubmit: (values) => {
      priorityApplicationChange({
        priority: Number(values.priority),
        applicationIds: isMass
          ? applicationCheck.filter((app) => app.checked) &&
            applicationCheck.filter((app) => app.checked).map(({ id }) => id)
          : []
      });
      setIsModalOpen(false);
    }
  });

  return (
    <Modal isOpen={isModalOpen} toggle={() => setIsModalOpen(!isModalOpen)} centered>
      <ModalHeader toggle={() => setIsModalOpen(!isModalOpen)}>
        Change Application Priority
      </ModalHeader>
      <ModalBody>
        <span className='font-size-16 mb-4'>
          Are you sure you want to chagne the priority?
        </span>
        <form onSubmit={formik.handleSubmit}>
          <div className='font-size-14 mb-2 mt-3'>Select Priority:</div>
          <Input
            type='select'
            name='priority'
            id='priority'
            className='font-size-12'
            value={formik.values.priority}
            onChange={formik.handleChange}
            invalid={formik.touched.priority && !!formik.errors.priority}>
            <option value='' disabled>
              Select a priority
            </option>
            {prioritySuggestions.map((option) => (
              <option key={option} value={option.id}>
                {option.name}
              </option>
            ))}
          </Input>
          <FormFeedback>{formik.errors.priority}</FormFeedback>
          <div className='d-flex justify-content-center p-3'>
            <Button type='submit' color='primary'>
              Save
            </Button>
            <Button
              className='ml-2'
              color='danger'
              onClick={() => {
                setIsModalOpen(false);
              }}>
              Cancel
            </Button>
          </div>
        </form>
      </ModalBody>
    </Modal>
  );
};

export default PriorityModal;
