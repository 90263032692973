import React from 'react';
import countriesAPI from 'countries-api';
import SupportedCountriesMap from './SupportedCountriesMap';
import uniq from 'lodash/uniq';
import pick from 'lodash/pick';

function SupportedCountries({ paymentOptions }) {
  let countries = {};
  if (Array.isArray(paymentOptions)) {
    paymentOptions.forEach((option) => {
      option.country.forEach((country) => {
        if (!countries[country.country]) {
          countries[country.country] = {
            ...country,
            logo_square: `https://purecatamphetamine.github.io/country-flag-icons/1x1/${country.country}.svg`,
            currencies: [],
            methods: []
          };
        }
        const data = countries[country.country];
        data.currencies = uniq([...data.currencies, ...option.currency]);
        data.methods.push(pick(option, ['logo', 'method', 'method_name']));
      });
    });
  }
  Object.values(countries).forEach((country) => {
    const resp = countriesAPI.findByCountryCode(country.country);
    const countryData = (resp && resp.data && resp.data[0]) || null;
    if (countryData) {
      const [lat, lng] = countryData.latlng;
      country.position = { lat, lng };
      const nativeCurrency = countryData.currency && countryData.currency[0];
      country.nativeCurrency = nativeCurrency;
      country.isNativeCurrencySupported = country.currencies.includes(nativeCurrency);
    }
  });

  return <SupportedCountriesMap countries={countries} />;
}

export default SupportedCountries;
