import React, { useState } from 'react';
import get from 'lodash/get';
import { FixedSizeList } from 'react-window';

function SwingSwapCoins({
  className = '',
  currentCoin = {},
  coins = [],
  onClose = () => {},
  onSelect = () => {},
  isReceiveCoin = false,
  networkState = () => {},
  widgetTheme
}) {
  const [search, setSearch] = useState('');
  const [selectedCoin, setSelectedCoin] = useState(null);

  const currentSelectedCoin = coins.filter((coin) => coin.symbol === currentCoin.symbol);
  coins = [
    ...currentSelectedCoin,
    ...coins.filter((coin) => coin.symbol !== currentCoin.symbol)
  ];

  const filteredChains = search
    ? (Array.isArray(coins) ? coins : []).filter(
        ({ symbol, addresses }) =>
          !search ||
          [symbol, addresses]
            .join('')
            .toLowerCase()
            .includes((search || '').toLowerCase())
      )
    : coins;

  const filteredNetworks = search
    ? (Array.isArray(selectedCoin && selectedCoin.networks)
        ? selectedCoin.networks
        : []
      ).filter(
        ({ name, slug }) =>
          !search ||
          [name, slug]
            .join('')
            .toLowerCase()
            .includes((search || '').toLowerCase())
      )
    : selectedCoin && selectedCoin.networks;

  const ChainRow = React.memo(({ index, style }) => {
    return (
      <GetChains
        key={index}
        style={style}
        onSelect={onSelect}
        networkState={networkState}
        chain={filteredNetworks[index]}
        selectedCoin={selectedCoin}
        isReceiveCoin={isReceiveCoin}
        widgetTheme={widgetTheme}
      />
    );
  });

  const CoinRow = React.memo(({ index, style }) => {
    return (
      <GetCoin
        key={index}
        style={style}
        currentCoin={currentCoin}
        coin={filteredChains[index]}
        setSelectedCoin={setSelectedCoin}
        setSearch={setSearch}
        widgetTheme={widgetTheme}
      />
    );
  });

  return (
    <div
      className={'py-3 ' + className}
      style={{ background: `var(--${widgetTheme}-container-color)` }}>
      {selectedCoin !== null &&
        selectedCoin.networks &&
        selectedCoin.networks.length > 0 && (
          <>
            <div className='d-flex align-items-center text-truncate mb-3 mx-3'>
              <div
                className='font-size-18 font-weight-bold flex-fill text-center'
                style={{ color: `var(--${widgetTheme}-primary-text-color)` }}>
                Select Crypto Chain
              </div>
            </div>
            <i
              type='button'
              onClick={onClose}
              className='btn btn-link p-0 position-absolute font-size-24 mdi mdi-close'
              style={{
                right: 16,
                top: 12,
                zIndex: 1,
                color: `var(--${widgetTheme}-primary-text-color)`
              }}></i>
            <div className='mx-3 px-3 pb-3'>
              <input
                type='text'
                className='form-control form-control-search mx-1'
                placeholder='Search'
                value={search}
                onInput={(e) => setSearch(e.target.value)}
                onChange={(e) => setSearch(e.target.value)}
                style={{
                  minWidth: '200px',
                  maxWidth: '100%',
                  color: `var(--${widgetTheme}-primary-text-color)`
                }}
              />
            </div>
            <FixedSizeList
              className='scroll-1'
              innerElementType='ul'
              itemCount={filteredNetworks.length}
              itemSize={62}
              height={400}>
              {({ index, style }) => {
                return (
                  <li style={style}>
                    <ChainRow index={index} style={style} />
                  </li>
                );
              }}
            </FixedSizeList>
          </>
        )}
      {selectedCoin === null && (
        <>
          <div className='d-flex align-items-center text-truncate mb-3 mx-3'>
            <div
              className='font-size-18 font-weight-bold flex-fill text-center'
              style={{ color: `var(--${widgetTheme}-primary-text-color)` }}>
              Select Token
            </div>
          </div>
          <i
            type='button'
            onClick={onClose}
            className='btn btn-link p-0 position-absolute font-size-24 mdi mdi-close'
            style={{
              right: 16,
              top: 12,
              zIndex: 1,
              color: `var(--${widgetTheme}-primary-text-color)`
            }}></i>
          <div className='mx-3 px-3 pb-3'>
            <input
              type='text'
              className='form-control form-control-search mx-1'
              placeholder='Search'
              value={search}
              onInput={(e) => setSearch(e.target.value)}
              onChange={(e) => setSearch(e.target.value)}
              style={{
                minWidth: '200px',
                maxWidth: '100%',
                color: `var(--${widgetTheme}-primary-text-color)`
              }}
            />
          </div>
          <FixedSizeList
            className='scroll-1'
            innerElementType='ul'
            itemCount={filteredChains.length}
            itemSize={55}
            height={400}>
            {({ index, style }) => {
              return (
                <li style={style}>
                  <CoinRow index={index} style={style} />
                </li>
              );
            }}
          </FixedSizeList>
        </>
      )}
    </div>
  );
}

export default SwingSwapCoins;

export const GetChains = ({
  networkState,
  onSelect,
  chain,
  selectedCoin,
  isReceiveCoin,
  widgetTheme
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        background: isHovered
          ? `var(--${widgetTheme}-primary-text-color)`
          : `var(--${widgetTheme}-container-color)`
      }}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className='d-flex align-items-center text-truncate cursor-pointer rounded py-2 px-3 mx-3'
      onClick={async () => {
        networkState(chain.swing_slug);
        onSelect({ ...chain, ...selectedCoin });
      }}>
      <img
        src={chain.logo}
        alt=''
        width={36}
        style={{
          maxHeight: '36px',
          background: `var(--${widgetTheme}-primary-text-color)`,
          color: `var(--${widgetTheme}-container-color)`
        }}
        className='rounded-circle mr-3'
      />
      <div>
        <span
          className='font-size-16 text-uppercase mr-1 font-weight-medium'
          style={{
            color: isHovered
              ? `var(--${widgetTheme}-container-color)`
              : `var(--${widgetTheme}-primary-text-color)`
          }}>
          {chain.swing_slug}{' '}
        </span>
        <br />
        <span
          className='font-size-12 text-uppercase mr-1'
          style={{
            color: isHovered
              ? `var(--${widgetTheme}-container-color)`
              : `var(--${widgetTheme}-primary-text-color)`
          }}>
          {chain.id} ({chain.type})
        </span>
      </div>
      <div className='ml-auto badge font-size-12'>
        <span
          className='text-uppercase'
          style={{
            color: isHovered
              ? `var(--${widgetTheme}-container-color)`
              : `var(--${widgetTheme}-primary-text-color)`
          }}>
          {get(chain, 'name', '')}
        </span>
        {chain.popularity > 0 && (
          <span
            className='ml-1'
            style={{
              paddingLeft: 4,
              paddingRight: 4,
              borderRadius: 4,
              background: '#f0f3fe',
              color: '#7e50f5'
            }}>
            Popular
          </span>
        )}
      </div>
    </div>
  );
};

export const GetCoin = ({
  coin,
  currentCoin,
  setSelectedCoin,
  setSearch,
  widgetTheme
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };

  return (
    <div
      style={{
        background: isHovered
          ? `var(--${widgetTheme}-primary-text-color)`
          : `var(--${widgetTheme}-container-color)`
      }}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className='d-flex align-items-center text-truncate cursor-pointer rounded py-2 px-3 mx-3'
      onClick={async () => {
        setSelectedCoin(coin);
        setSearch('');
      }}>
      <img
        src={coin.logo}
        alt=''
        width={36}
        style={{
          maxHeight: '36px',
          background: `var(--${widgetTheme}-primary-text-color)`,
          color: `var(--${widgetTheme}-container-color)`
        }}
        className='rounded-circle mr-3'
      />
      <div>
        <span
          className='font-size-16 text-uppercase mr-1 font-weight-medium'
          style={{
            color: isHovered
              ? `var(--${widgetTheme}-container-color)`
              : `var(--${widgetTheme}-primary-text-color)`
          }}>
          {coin.symbol}{' '}
        </span>
      </div>
      <div className='ml-auto badge font-size-12 d-flex align-items-center'>
        <span
          className='text-uppercase'
          style={{
            color: isHovered
              ? `var(--${widgetTheme}-container-color)`
              : `var(--${widgetTheme}-primary-text-color)`
          }}>
          {get(coin, 'id', '')}
        </span>
        {coin.symbol === currentCoin.symbol && (
          <span
            className='font-size-10 font-weight-medium'
            style={{
              color: isHovered
                ? `var(--${widgetTheme}-container-color)`
                : `var(--${widgetTheme}-primary-text-color)`
            }}>
            ({currentCoin.slug.toUpperCase()})
          </span>
        )}
        <i
          className='mdi mdi-chevron-right font-size-16'
          style={{
            color: isHovered
              ? `var(--${widgetTheme}-container-color)`
              : `var(--${widgetTheme}-primary-text-color)`
          }}
        />
      </div>
    </div>
  );
};
