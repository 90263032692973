import React, { useState } from 'react';
import './application.css';
import {
  Button,
  Card,
  CardBody,
  CardSubtitle,
  CardTitle,
  Col,
  Collapse,
  Modal,
  ModalBody,
  Row
} from 'reactstrap';
import TimeAgo from 'react-timeago';
import { stringify } from 'query-string';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import moment from 'moment';
import { ReactComponent as CheckCircleIcon } from '../../assets/images/icons/check.svg';
import { DeleteApplicationAPI } from '../../services/Business/Application';
import { notifyError, notifySuccess } from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { get } from 'lodash';

const statusSuggestions = [
  { id: 'REQUEST_ACTION', name: 'Action' },
  { id: 'PROCESSING', name: 'Processing' },
  { id: 'ACCEPT', name: 'Accept' },
  { id: 'REJECT', name: 'Reject' },
  { id: 'KYB', name: 'KYB' },
  { id: 'QUOTE', name: 'Quote' },
  { id: 'PAID', name: 'Paid' },
  { id: 'ADD_QUESTIONS', name: 'Add Question' }
];

const getSeverity = (status) => {
  const statusToSeverity = {
    REJECT: 'danger',
    ACCEPT: 'success',
    ADD_QUESTIONS: 'info',
    negotiation: 'warning',
    COMPLETE: 'success',
    QUOTE: 'secondary',
    renewal: 'secondary'
  };

  return statusToSeverity[status] || 'secondary';
};
function GetApplication({
  application,
  index,
  activeId,
  onClick,
  history,
  setFirst,
  setApplicationDelete
}) {
  const handleContinue = () => handleContinueClick(application, history);
  const [modalOpen, setModalOpen] = useState(false);

  const deleteApplicationFunction = (applicationId) => {
    DeleteApplicationAPI({ applicationId })
      .then(() => {
        notifySuccess('Application deleted successfully');
        setApplicationDelete(true);
        setModalOpen(false);
        setFirst(1);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <Card
      style={{ border: '1px solid rgb(128 128 128 / 30%)', cursor: 'pointer' }}
      className='ml-4 mr-4'
      onClick={() => onClick(index)}>
      <CardBody>
        <div style={{ position: 'absolute', right: 10, top: 0 }}>
          {application.sub_application &&
            application.sub_application.length > 0 &&
            application.sub_application.length >= 1 &&
            application.sub_application.filter(
              (app) =>
                app && app.onRampAppData && Object.keys(app.onRampAppData).length > 0
            ).length > 0 && (
              <label
                data-event={index}
                key={index}
                style={{ fontSize: '25px', cursor: 'pointer' }}
                className='pl-3 mt-1'>
                <i className={`mdi mdi-chevron-${activeId === index ? 'up' : 'down'}`} />{' '}
              </label>
            )}
        </div>
        <Row>
          <Col lg={9} md={6} sm={12}>
            <div className='d-flex justify-content-between align-items-center'>
              <div className='d-flex'>
                <div>
                  <CardTitleSection
                    application={application}
                    ticker={
                      application.ticker
                        ? application.ticker
                        : application.exchange_application.token_name
                    }
                    tickerName={
                      application && application.ticker_name
                        ? application.ticker_name
                        : application.exchange_application &&
                          application.exchange_application.ticker_name
                        ? application.exchange_application.ticker_name
                        : ''
                    }
                  />
                  <CardSubtitleSection
                    type={application.application_type}
                    updatedAt={application.updated_at}
                  />
                  <ProgressBar
                    status={application.application_status}
                    percentage={
                      application.completed_percentage
                        ? application.completed_percentage
                        : application.exchange_application.percentage_complete
                    }
                  />
                  <ProgressBar
                    status={application.application_status}
                    percentage={
                      application.application_status === 'LITE_COMPLETE'
                        ? '100'
                        : application?.application_type === 'EXCHANGE'
                        ? application?.app_exchange_lite_per
                        : ''
                    }
                    isLite={true}
                  />
                </div>
              </div>
            </div>
          </Col>
          <Col lg={3} md={6} sm={12}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100%'
              }}>
              <ContinueButton onClick={handleContinue} />
              {application.application_status !== 'COMPLETE' && (
                <i
                  className='fas fa-trash-alt cursor-pointer ml-2'
                  onClick={() => setModalOpen(!modalOpen)}
                  style={{ color: '#de1738' }}
                />
              )}
              <DeleteApplication
                modalOpen={modalOpen}
                setModalOpen={setModalOpen}
                applicationId={application._id}
                deleteApplicationFunction={deleteApplicationFunction}
              />
            </div>
          </Col>
        </Row>
        {application.sub_application &&
          application.sub_application.length > 0 &&
          application.sub_application &&
          application.sub_application.filter(
            (app) => app && app.onRampAppData && Object.keys(app.onRampAppData).length > 0
          ).length > 0 && (
            <SubApplications
              applications={application.sub_application}
              activeId={activeId}
              index={index}
            />
          )}
      </CardBody>
    </Card>
  );
}

export default GetApplication;

const CardTitleSection = ({ application, ticker, tickerName }) => (
  <CardTitle className='mb-0'>
    <ProfileIcon
      iconUrl={
        application && application.ticker_logo
          ? application.ticker_logo
          : application.exchange_application &&
            application.exchange_application.ticker_logo
          ? application.exchange_application.ticker_logo
          : ''
      }
    />
    <div>
      <label className='name-label font-weight-bold'>{ticker}</label>{' '}
      <label className='text-muted ticker-label'>{tickerName}</label>
    </div>
  </CardTitle>
);

const CardSubtitleSection = ({ type, updatedAt }) => (
  <CardSubtitle className='mb-1'>
    <span className='font-size-12'>{type}</span>
    <span className='ticker-label text-muted'>
      {' '}
      - Last updated{' '}
      <TimeAgo
        date={updatedAt}
        formatter={(value, unit, suffix) => {
          if (value < 1 && unit === 'second') {
            return 'just now';
          } else {
            return value + ' ' + unit + (value > 1 ? 's' : '') + ' ' + suffix;
          }
        }}
      />
    </span>
  </CardSubtitle>
);

const handleContinueClick = (application, history) => {
  if (application.application_type === 'ONRAMP') {
    history.push(`/application/view?${stringify({ application_id: application._id })}`);
  } else {
    history.push(
      `/application/viewExchange?${stringify({ application_id: application._id })}`
    );
  }
};

const ContinueButton = ({ onClick }) => (
  <div className='d-flex align-items-center justify-content-center'>
    <button className='btn btn-continue font-weight-bold' onClick={onClick}>
      Continue
    </button>
  </div>
);

const ProfileIcon = ({ iconUrl }) => (
  <div style={{ padding: '5px' }}>
    {iconUrl && iconUrl.length > 0 ? (
      <img
        src={iconUrl}
        style={{ borderRadius: '50%' }}
        width={40}
        height={40}
        alt='user'
      />
    ) : (
      <i
        className='fa fa-user'
        style={{
          borderRadius: '50%',
          border: '1px solid #f0f0f0',
          width: '40px',
          height: '40px',
          background: '#f0f0f0',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center'
        }}></i>
    )}
  </div>
);

const ProgressBar = ({ status, percentage, isLite = false }) => (
  <div className='name-label font-weight-medium font-size-12 mb-2'>
    <span className='mr-2'>
      <CircularProgressbar
        value={status === 'COMPLETE' ? 100 : percentage}
        text={`${status === 'COMPLETE' ? '100' : percentage}%`}
      />
    </span>
    <span>
      {status === 'COMPLETE' ? '100' : percentage}%{' '}
      {isLite ? 'Lite Complete' : 'Complete'}
    </span>
  </div>
);
const SubApplications = ({ applications, activeId, index }) => (
  <Collapse className='mt-2' isOpen={activeId === index}>
    <div className='card'>
      <div className='table-responsive'>
        <table className='table table-centered table-hover font-size-12'>
          <thead className='center-text'>
            <tr>
              <th className='pl-4 pt-3 border-0'>#</th>
              <th className='pl-4 pt-3 border-0'>Requested By</th>
              <th className='pl-4 pt-3 border-0'>Status</th>
              <th className='pl-4 pt-3 border-0 text-left'>Notes</th>
              <th className='pl-4 pt-3 border-0 text-left'>Field Changes Requested</th>
              <th className='pl-4 pt-3 border-0'>Date Submitted</th>
              <th className='pl-4 pt-3 border-0'>Needed Review</th>
            </tr>
          </thead>
          <tbody>
            {applications && applications.length > 0 ? (
              <>
                {applications
                  .filter(
                    (app) =>
                      app &&
                      app.onRampAppData &&
                      Object.keys(app.onRampAppData).length > 0
                  )
                  .map((application, i) => (
                    <SubApplication key={i} application={application} index={i} />
                  ))}
              </>
            ) : (
              <tr>
                <td colSpan={7}>
                  <span className='d-flex justify-content-center'>
                    No sub applications found
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </Collapse>
);

const actionBodyTemplate = (application) => {
  if (application && application.onRampAppData) {
    const { onRampAppData } = application;
    if (onRampAppData.field) {
      const fieldKeys = Object.keys(onRampAppData.field);
      const completedFieldKeys = onRampAppData.completed_field;
      if (fieldKeys.length === completedFieldKeys.length) {
        return (
          <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
            Completed
          </div>
        );
      }
      let foundFirstPending = false;
      return fieldKeys
        .map((value, index) => {
          const isFieldCompleted = completedFieldKeys.includes(value);
          if (isFieldCompleted) {
            return (
              <div key={index} className='mb-1'>
                <CheckCircleIcon height={26} width={28} />
                {value}
              </div>
            );
          } else if (!foundFirstPending) {
            foundFirstPending = true;
            return (
              <div
                key={index}
                className='badge font-size-12 p-2'
                style={{ borderRadius: 8 }}>
                Pending
              </div>
            );
          }
          return null;
        })
        .filter(Boolean);
    } else if (onRampAppData.status === 'ACCEPT' || onRampAppData.status === 'REJECT') {
      return (
        <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
          Completed
        </div>
      );
    } else if (onRampAppData && onRampAppData.response_complete) {
      return (
        <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
          Completed
        </div>
      );
    } else if (onRampAppData && !onRampAppData.response_complete) {
      return (
        <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
          Pending
        </div>
      );
    } else {
      return (
        <div>
          {onRampAppData.status === 'QUOTE' &&
          onRampAppData.quote_price &&
          onRampAppData.quote_price.crypto_currency ? (
            <div
              onClick={() => {
                const externalURL = onRampAppData.payments_txs[0]?.invoiceLinks?.pay;
                const newTab = window.open(externalURL, '_blank');
                newTab.focus();
              }}
              className='p-2 badge badge-secondary font-size-12 cursor-pointer'
              style={{ borderRadius: 8 }}>
              View Invoice
            </div>
          ) : onRampAppData.status === 'QUOTE' &&
            onRampAppData.quote_price &&
            onRampAppData.quote_price.currency ? (
            'Payment Awaited'
          ) : (
            '-'
          )}
        </div>
      );
    }
  } else {
    return (
      <div className='badge font-size-12 p-2' style={{ borderRadius: 8 }}>
        N/A
      </div>
    );
  }
};
const SubApplication = ({ application, index }) => (
  <tr className='center-text'>
    <td className='pl-4'>{index + 1}</td>
    <td className='pl-4'>
      {' '}
      <div>
        <ProfileIcon
          iconUrl={
            application && application.onRampAppData && application.onRampAppData.logo_url
              ? application.onRampAppData.logo_url
              : ''
          }
        />
        <span>{application.onRampAppData && application.onRampAppData.on_ramp_name}</span>
      </div>
    </td>
    <td className='pl-4'>
      <span
        className={`p-1 font-size-12 badge badge-pill badge-${getSeverity(
          application.application_status
        )}`}>
        {application &&
          application.application_status &&
          statusSuggestions.find((status) => status.id === application.application_status)
            .name}
      </span>
    </td>
    <td className='pl-4 text-left'>
      {application &&
      application.onRampAppData &&
      application.onRampAppData.field &&
      Object.keys(application.onRampAppData.field).length > 0 ? (
        Object.entries(application.onRampAppData.field).map(([key, value]) => {
          if (value.length > 0) {
            const truncatedValue =
              String(value).length > 20
                ? `${String(value).substring(0, 20)}...`
                : String(value);
            return (
              <div key={key} data-tip={value}>
                &#8226; {truncatedValue}
              </div>
            );
          } else {
            return <div key={key} data-tip={value}></div>;
          }
        })
      ) : application.onRampAppData && application.onRampAppData.notes ? (
        String(application.onRampAppData.notes).length > 20 ? (
          `${String(application.onRampAppData.notes).substring(0, 20)}...`
        ) : (
          String(application.onRampAppData.notes)
        )
      ) : application &&
        application.application_status === 'ADD_QUESTIONS' &&
        application.add_questions &&
        application.add_questions.length > 0 ? (
        application.add_questions.map((que, index) => (
          <div key={index}>
            {index + 1}.
            {String(que.question).length > 20
              ? `${String(que.question).substring(0, 20)}
          ...`
              : String(que.question)}
          </div>
        ))
      ) : (
        <div>No notes available</div>
      )}
    </td>
    <td className='pl-4 text-left'>
      {application &&
      application.onRampAppData &&
      application.onRampAppData.field &&
      Object.keys(application.onRampAppData.field).length > 0 ? (
        <>
          {Object.keys(application.onRampAppData.field)
            .splice(0, 5)
            .map((value, index) => (
              <div
                className={
                  application.onRampAppData.completed_field &&
                  application.onRampAppData.completed_field.length > 0 &&
                  application.onRampAppData.completed_field.includes(value)
                    ? 'text-success'
                    : 'text-warning'
                }
                key={index}>
                {index + 1}. {value}
              </div>
            ))}
          {Object.keys(application.onRampAppData.field).length > 5 && '...more'}
        </>
      ) : application &&
        application.application_status === 'ADD_QUESTIONS' &&
        application.add_questions &&
        application.add_questions.length > 0 ? (
        application.add_questions.map((que, index) => (
          <div key={index}>
            {index + 1}. {que.type}
          </div>
        ))
      ) : (
        <div className='pl-4'>No fields available</div>
      )}
    </td>
    <td className='pl-4' width={200}>
      {moment(application.created_at).format('DD/MM/YYYY hh:mm A')}
    </td>

    <td className='pl-4'>{actionBodyTemplate(application)}</td>
  </tr>
);

const DeleteApplication = ({
  modalOpen,
  setModalOpen,
  applicationId,
  deleteApplicationFunction
}) => {
  return (
    <Modal
      style={{ width: 440 }}
      isOpen={modalOpen}
      toggle={() => setModalOpen(!modalOpen)}
      centered>
      <ModalBody>
        <span style={{ position: 'absolute', top: 0, right: 5 }} className='p-2'>
          <i
            className='mdi mdi-close font-size-16 cursor-pointer'
            onClick={() => setModalOpen(!modalOpen)}
          />
        </span>
        <div className='d-flex justify-content-center'>
          <lord-icon
            src='https://cdn.lordicon.com/exkbusmy.json'
            trigger='hover'
            colors='outline:#121331,primary:#646e78,secondary:#4bb3fd,tertiary:#ebe6ef'
            style={{ width: '150px', height: '150px' }}></lord-icon>
        </div>
        <span className='d-flex justify-content-center font-size-24 h2'>
          Are you sure?
        </span>
        <span className='d-flex justify-content-center font-size-18 h2'>
          You want to delete this application?
        </span>
        <br />
        <div
          style={{ paddingLeft: 120, paddingRight: 120 }}
          className='d-flex align-items-center justify-content-center my-2 flex-row '>
          <Button
            className='w-100'
            color='primary'
            style={{ width: 100 }}
            onClick={() => deleteApplicationFunction(applicationId)}>
            Delete
          </Button>
          <Button
            className='w-100 ml-2'
            color='danger'
            onClick={() => setModalOpen(!modalOpen)}>
            Cancel
          </Button>
        </div>
        <div className='d-flex' style={{ justifyContent: 'space-evenly' }}></div>
      </ModalBody>
    </Modal>
  );
};
