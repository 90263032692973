import api from '../../api/ramp2Api';
import api4 from '../../api/userApi';
import * as BusinessURL from '../../api/ramp2URL';
import * as BusinessURL4 from '../../api/userURL';

export const GetProviderAllNetworks = (provider) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(BusinessURL.PROVIDER_NETWORK + `?provider=${provider}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetProviderAllAssets = (provider) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(BusinessURL.ASSETS + `?provider=${provider}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetProviderAllCurrencies = (provider) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(BusinessURL.ASSETS + `?provider=${provider}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetProviderAllPaymentMethods = (provider, country = '') => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        BusinessURL.PROVIDER_PAYMENT_OPTIONS + `?provider=${provider}&country=${country}`
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetProviderBestAssetsBuy = (
  provider,
  paymentMethod = 'all',
  isGlobal = false
) => {
  return new Promise(async (resolve, reject) => {
    const url = isGlobal
      ? `${BusinessURL.BEST_ASSETS_GLOBAL}?currency=USD&provider=${provider}&payment_method=${paymentMethod}`
      : `${BusinessURL4.BEST_ASSETS_BUY}?provider=${provider}&payment_method=${paymentMethod}`;
    return (isGlobal ? api : api4)
      .getWithToken(url)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
