import React from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function DeleteUserModal({
  deleteAccountFunction,
  modalDeleteOpen,
  setModalDeleteOpen,
  setAccountPassword,
  userData,
  accountPassword
}) {
  return (
    <Modal
      isOpen={modalDeleteOpen}
      toggle={() => {
        setModalDeleteOpen(!modalDeleteOpen);
        setAccountPassword('');
      }}
      centered>
      <ModalHeader
        toggle={() => {
          setModalDeleteOpen(!modalDeleteOpen);
          setAccountPassword('');
        }}>
        Delete Account
      </ModalHeader>
      <ModalBody>
        <span>Do you really want to delete this user account?</span>
        <br />
        <br />
        <span>Enter login password: </span>
        <br />
        <Input
          type='password'
          className='border-dark'
          placeholder='Enter your password'
          rows='6'
          name='password'
          onChange={(e) => setAccountPassword(e.target.value)}
          style={{ paddingRight: '30px', borderRadius: '8px' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={() => deleteAccountFunction(userData.user_id, accountPassword)}>
          Delete
        </Button>
        <Button
          color='danger'
          onClick={() => {
            setModalDeleteOpen(!modalDeleteOpen);
            setAccountPassword('');
          }}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default DeleteUserModal;
