import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepThree(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const SocialMediaSchema = Yup.object().shape({
    twitter: Yup.string().required(ERROR_CONST.ROADMAP_REQUIRED),
    discord: Yup.string().required(ERROR_CONST.ROADMAP_REQUIRED),
    linkedin: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    tiktok: Yup.string()
  });
  const [initialValue, setInitialValue] = useState({
    twitter: '',
    discord: '',
    linkedin: '',
    facebook: '',
    instagram: '',
    tiktok: ''
  });
  const step = 3;
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.social_media.length) {
          application.exchange_application.social_media.forEach((social_media) => {
            setInitialValue((prevValues) => ({
              ...prevValues,
              [social_media.name]: social_media.account_id
            }));
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    const social_media = Object.keys(values).map((key) => ({
      name: key,
      account_id: values[key]
    }));
    let stepsRequired = Math.floor(StepsCalculator(2, completeStep, 36));
    let data = {
      social_media: JSON.stringify(social_media),
      percentage_complete: stepsRequired
    };
    JSON.stringify(data);
    setIsLoading(true);

    UpdateExchangeApplication({ application_id: applicationId }, data)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        props.setApplicationData(application);
        nextPage();
        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    setExchangeCompleteStep([0, 1, 2, 3]);
  };

  const calculateProgress = () => {
    return completeStep;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>Link of Social Medias + No. of Followers</span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={SocialMediaSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.TWITTER}
                        htmlFor='twitter'
                        name='twitter'
                        placeholder={CONST.ENTER_TWITTER_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.DISCORD}
                        htmlFor='discord'
                        name='discord'
                        placeholder={CONST.ENTER_DISCORD_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.LINKEDIN}
                        htmlFor='linkedin'
                        name='linkedin'
                        placeholder={CONST.ENTER_LINKEDIN_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        extraLabel={`(${CONST.OPTIONAL})`}
                      />
                    </Col>

                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.FACEBOOK}
                        htmlFor='facebook'
                        name='facebook'
                        placeholder={CONST.ENTER_FACEBOOK_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        extraLabel={`(${CONST.OPTIONAL})`}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.INSTAGRAM}
                        htmlFor='instagram'
                        name='instagram'
                        placeholder={CONST.ENTER_INSTAGRAM_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        extraLabel={`(${CONST.OPTIONAL})`}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.TIKTOK}
                        htmlFor='tiktok'
                        name='tiktok'
                        placeholder={CONST.ENTER_TIKTOK_NAME}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        extraLabel={`(${CONST.OPTIONAL})`}
                      />
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepThree;

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel
}) => (
  <FormGroup className='mb-2'>
    <div className='font-size-12'>
      <Label htmlFor={htmlFor} className='text-black font-weight-normal'>
        {label} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12'}
        placeholder={placeholder}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
