import React, { useState } from 'react';
import ResponseList from './ResponsePage';
import ViewOnRampApplication from './ViewOnRampApplication';
import ApplicationNotifications from './NotificationPage';
import { Col, Nav, NavItem, Row, NavLink, TabContent, TabPane, Card } from 'reactstrap';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';
import { useHistory } from 'react-router-dom';
import ResponseFilters from './ResponseFilters';

function ViewApplication(props) {
  const [page, setPage] = useState('response');
  const history = useHistory();
  const [applicationId, setApplicationId] = useState('application_id=');
  const [responsePage, setResponsePage] = useState(1);
  const [updatePage, setUpdatePage] = useState(false);
  const [statusSuggestions, setStatusSuggestions] = useState([
    {
      id: 'REQUEST_ACTION',
      name: 'Action',
      icon: 'fas fa-unlock',
      color: 'black',
      checked: false
    },
    {
      id: 'ACCEPT',
      name: 'Accept',
      icon: 'fas fa-check-circle',
      color: 'green',
      checked: false
    },
    { id: 'KYB', name: 'KYB', icon: 'fas fa-lock', color: 'black', checked: false },
    {
      id: 'QUOTE',
      name: 'Quote',
      icon: 'fas fa-quote-left',
      color: 'black',
      checked: false
    },
    {
      id: 'PAID',
      name: 'Paid',
      icon: 'fas fa-thumbs-up',
      color: 'green',
      checked: false
    },
    {
      id: 'ADD_QUESTIONS',
      name: 'Add Question',
      icon: 'fas fa-question',
      color: 'black',
      checked: false
    }
  ]);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row className='px-2'>
          <Col lg={9} md={9} sm={12}>
            <Card>
              <div className='pt-3 pl-3'>
                <button
                  type='button'
                  className='btn btn-light p-1 mb-2'
                  style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
                  onClick={() => history.push('/applications')}>
                  <ArrowLeftIcon width={18} height={18} />
                </button>
              </div>
              <Nav tabs className='nav-tabs-custom nav-justified'>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'application' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('application');
                    }}>
                    <span className='d-none d-sm-block'>Application Page</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'response' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('response');
                    }}>
                    <span className='d-none d-sm-block'>Responses</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${
                        page === 'notification' ? '2px solid #181B30' : ''
                      }`
                    }}
                    onClick={() => {
                      setPage('notification');
                    }}>
                    <span className='d-none d-sm-block'>Notifications</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={page}>
                <TabPane tabId='application'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <ViewOnRampApplication props={props} setPage={setPage} />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='response'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <ResponseList
                        props={props}
                        page={responsePage}
                        setPage={setResponsePage}
                        setApplicationId={setApplicationId}
                        statusSuggestions={statusSuggestions}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='notification'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <ApplicationNotifications props={props} page={page} />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
          {page === 'response' && (
            <Col lg={3} md={3} sm={6}>
              <ResponseFilters
                statusSuggestions={statusSuggestions}
                setStatusSuggestions={setStatusSuggestions}
                setResponsePage={setResponsePage}
                setUpdatePage={setUpdatePage}
                updatePage={updatePage}
                applicationId={applicationId}
              />
            </Col>
          )}
        </Row>
      </div>
    </React.Fragment>
  );
}

export default ViewApplication;
