import React from 'react';
import { ReactComponent as RefreshIcon } from '../../assets/images/icons/refresh.svg';

function MoneyInputGroup({ buyComponent, sellComponent, loading }) {
  return (
    <div>
      {buyComponent}
      <div className="refresh">
        <div
          className={
            'refresh__button rounded-circle d-flex align-items-center justify-content-center ' +
            (loading && 'fa-spin')
          }>
          <RefreshIcon height={24} />
        </div>
      </div>
      {sellComponent}
    </div>
  );
}

export default MoneyInputGroup;
