import React, { useEffect, useState } from 'react';
import { ChangeApplicationStatus } from '../../../services/Onramp/Application';
import { notifyError, notifySuccess } from '../../../Custom/notification';

function FieldChangesRequest({
  setTextareaValues,
  setClickedLabels,
  clickedLabels,
  textareaValues,
  beneficialusersValues,
  applicationId,
  setPage,
  GetApplicationResponseFunc
}) {
  const [fieldsReqError, setFieldsReqError] = useState('');

  const handleRemoveField = (index) => {
    const updatedQuestionsData = [...clickedLabels];

    updatedQuestionsData.splice(index, 1);

    const allowedFields = updatedQuestionsData.map((item) => item.fieldName);

    for (let key in textareaValues) {
      if (!allowedFields.includes(key)) {
        delete textareaValues[key];
      }
    }

    setTextareaValues(textareaValues);
    setClickedLabels(updatedQuestionsData);
  };

  useEffect(() => {
    const areAllFieldsFilled = Object.values(textareaValues).every(
      (value) => value.trim() !== ''
    );

    if (areAllFieldsFilled) {
      setFieldsReqError('');
    }
  }, [textareaValues]);

  const handleSubmitKYC = () => {
    const areAllFieldsFilled = Object.values(textareaValues).every(
      (value) => value.trim() !== ''
    );
    if (!areAllFieldsFilled) {
      setFieldsReqError('Fill all Notes for Fields Changes');
      return;
    }
    const updatedFields = Object.assign({}, textareaValues);
    for (const user of beneficialusersValues) {
      if (updatedFields.hasOwnProperty(user['fieldName'])) {
        delete updatedFields[user['fieldName']];
      }
    }
    const usersvalues = beneficialusersValues?.map((item) => {
      const fieldName = item.fieldName;
      return {
        [fieldName]: textareaValues[fieldName],
        id: item.id
      };
    });

    let output;
    if (usersvalues.length > 0) {
      output = {
        ...updatedFields,
        beneficialUsers: usersvalues
      };
    } else {
      output = {
        ...updatedFields
      };
    }

    const data = {
      status: 'KYB',
      fields: output
    };

    if (applicationId) {
      ChangeApplicationStatus(applicationId, data)
        .then((res) => {
          notifySuccess('Reuqested field changes apply.');
          setClickedLabels([]);
          setPage('response');
          setTextareaValues({});
          GetApplicationResponseFunc(applicationId);
        })
        .catch((error) => {
          notifyError(`${error?.data?.error?.message}`);
        });
    }
  };

  return (
    <React.Fragment>
      <div className='text-left'>
        <label>KYB Field Changes</label>
      </div>
      {clickedLabels.map(({ labelName, fieldName }, index) => (
        <div
          key={index}
          className='edit-app-content d-grid justify-content-center ml-2 mr-2 mt-2'>
          <span key={index} className='d-flex justify-content-between font-size-12'>
            {index + 1}. {labelName}
            <div className='d-flex'>
              <i
                className='mdi mdi-close font-size-16 cursor-pointer'
                onClick={() => handleRemoveField(index)}
              />
            </div>
          </span>
          <textarea
            name={fieldName}
            value={textareaValues[fieldName]}
            placeholder='Write a notes here'
            className='form-control font-size-12'
            onChange={(e) => {
              e.persist();
              setTextareaValues((prevTextareaValues) => ({
                ...prevTextareaValues,
                [fieldName]: e.target.value
              }));
            }}
            style={{ height: 120 }}
          />
        </div>
      ))}
      {fieldsReqError && (
        <div className='d-flex align-items-center'>
          <span style={{ fontSize: 12 }} className='text-left text-danger pl-2'>
            {fieldsReqError}
          </span>
        </div>
      )}
      {clickedLabels.length > 0 ? (
        <div className='d-flex align-items-center justify-content-center mt-3'>
          <button
            className='btn mt-2 btn-auth'
            type='button'
            style={{ width: 170 }}
            onClick={() => handleSubmitKYC()}>
            Request Changes
          </button>
        </div>
      ) : (
        ''
      )}
    </React.Fragment>
  );
}

export default FieldChangesRequest;
