import React, { useEffect, useState } from 'react';
import { Col, Collapse, Row } from 'reactstrap';
import { Drawer } from '@mui/material';

import DefaultToken from './DefaultToken';

import DefaultFiats from './DefaultFiats';
import SwingSwapCoins from './SwingSwapCoins';
import OnlyCrypto from './OnlyCrypto';
import copy from 'copy-to-clipboard';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../Custom/notification';
import './WidgetCustomization.css';
import { GetExpiredDateAPI } from '../../services/Business/APIKey';
import { GetMyProfileAPI } from '../../services/Auth';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { GetWidgetImages, SetWidgetImagesAPI } from '../../services/Business/Application';
import ColorSelection from '../BusinessDashboard/WidgetOnboardingSteps/ColorSelection';
import RadiusSelection from '../BusinessDashboard/WidgetOnboardingSteps/RadiusSelection';
import ReorderTabs from '../BusinessDashboard/WidgetOnboardingSteps/ReorderTabs';
import BGImages from '../BusinessDashboard/WidgetOnboardingSteps/BGImages';
import DefaultSelectedValues from '../BusinessDashboard/WidgetOnboardingSteps/DefaultSelectedValues';
import {
  addDataIntoCache,
  addFiatDataIntoCache,
  getAllCacheData,
  getAllCacheFiats,
  getCachedDate
} from './CacheFunctions';
import moment from 'moment';
import {
  GetAllCoins,
  GetAllCountries,
  GetAllSwingSwapCoins
} from '../../services/Business/Crypto';
import _, { get } from 'lodash';
import FunMode from '../BusinessDashboard/WidgetOnboardingSteps/FunMode';

function WidgetCustomization1Buy() {
  const [widgetToken, setWidgetToken] = useState('');
  const [funMode, setFunMode] = useState(false);
  const [bgImage, setBgImage] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const getProfileDataFunction = () => {
    GetMyProfileAPI()
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        generateWidgetCode(data);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  const generateWidgetCode = (userData) => {
    GetExpiredDateAPI(
      `?api_key=${userData.api_key || ''}&secret_key=${userData.secret_key}`
    )
      .then((res) => {
        const result = res?.data?.data?.accessToken;
        getWidgetImagesFunc(result);
        setWidgetToken(result);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getProfileDataFunction();
  }, []);

  const [widgetTheme, setWidgetTheme] = useState('light');
  const [primaryColor, setPrimaryColor] = useState(
    widgetTheme === 'dark' ? '#46de8c' : widgetTheme === 'light' ? '#241d1c' : '#241d1c'
  );
  const [secondaryColor, setSecondaryColor] = useState(
    widgetTheme === 'dark' ? '#3f3f43' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
  );
  const [containerColor, setContainerColor] = useState(
    widgetTheme === 'dark' ? '#141416' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
  );
  const [primaryText, setPrimaryText] = useState(
    widgetTheme === 'dark' ? '#ffffff' : widgetTheme === 'light' ? '#141519' : '#141519'
  );
  const [secondaryText, setSecondaryText] = useState('#babec1');
  const [cardColor, setCardColor] = useState(
    widgetTheme === 'dark' ? '#272727' : widgetTheme === 'light' ? '#f6f7f9' : '#f6f7f9'
  );

  useEffect(() => {
    setPrimaryColor(
      widgetTheme === 'dark' ? '#46de8c' : widgetTheme === 'light' ? '#241d1c' : '#241d1c'
    );
    setSecondaryColor(
      widgetTheme === 'dark' ? '#3f3f43' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
    );
    setContainerColor(
      widgetTheme === 'dark' ? '#141416' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
    );
    setPrimaryText(
      widgetTheme === 'dark' ? '#ffffff' : widgetTheme === 'light' ? '#141519' : '#141519'
    );
    setSecondaryText('#babec1');
    setCardColor(
      widgetTheme === 'dark' ? '#272727' : widgetTheme === 'light' ? '#f6f7f9' : '#f6f7f9'
    );
  }, [widgetTheme]);

  const [isUpdate, setIsUpdate] = useState(false);

  const [allCoins, setAllCoins] = useState([]);
  const [allFiats, setAllFiats] = useState([]);
  const [allSwap, setAllSwap] = useState([]);
  const [defaultCoinOpt, setDefaultCoinOpt] = useState(false);
  const [defaultCoinSelect, setDefaultCoinSelect] = useState(null);
  const [defaultFiatOpt, setDefaultFiatOpt] = useState(false);
  const [defaultFiatSelect, setDefaultFiatSelect] = useState('USD');
  const [sendCoin, setSendCoin] = useState(null);
  const [receiveCoin, setReceiveCoin] = useState(null);
  const [sendShowTokens, setSendShowTokens] = useState(false);
  const [receiveShowTokens, setReceiveShowTokens] = useState(false);
  const [onlyCryptoShowTokens, setOnlyCryptoShowTokens] = useState(false);
  const [checkedCoins, setCheckedCoins] = useState([]);
  const [onlyCryptoStr, setOnlyCryptoStr] = useState('');
  const [backgroundImage, setBackgroundImage] = useState(null);
  const [imageName, setImageName] = useState('');

  const [amountPay, setAmountPay] = useState(null);
  const [fromTokenAmount, setFromTokenAmount] = useState(0.1);
  const [opacity, setOpacity] = useState(1);
  const [borderRadius, setBorderRadius] = useState(0.5);
  const [widgetRadius, setWidgetRadius] = useState(0.5);
  const [activeTab, setActiveTab] = useState('');
  const [backgroundSize, setBackgroundSize] = useState('cover');
  const [backgroundRepeat, setBackgroundRepeat] = useState('repeat');
  const [funModeImages, setFunModeImages] = useState(null);
  const [funModeLoadingGifs, setFunModeLoadingGifs] = useState(null);
  const [funModeConfirmScreenGifs, setFunModeConfirmScreenGifs] = useState(null);
  const [funModeSwapLoadingGifs, setFunModeSwapLoadingGifs] = useState(null);
  const [funModeSwapFailedGifs, setFunModeSwapFailedGifs] = useState(null);
  const [funModeSwapCompletedGifs, setFunModeSwapCompletedGifs] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);

  const [imageFiles, setImageFiles] = useState([]);
  const [loadingGifFiles, setLoadingGifFiles] = useState([]);
  const [confirmScreenGifFiles, setConfirmScreenGifFiles] = useState([]);
  const [swapLoadingGifFiles, setSwapLoadingGifFiles] = useState([]);
  const [swapFailedGifFiles, setSwapFailedGifFiles] = useState([]);
  const [swapCompletedGifFiles, setSwapCompletedGifFiles] = useState([]);
  const [view, setView] = useState('');

  const [fromCoin, setFromCoin] = useState('');
  const [toCoin, setToCoin] = useState('');
  const [fromNetwork, setFromNetwork] = useState('');
  const [toNetwork, setToNetwork] = useState('');

  const [defaultCoin, setDefaultCoin] = useState('');
  const [defaultFiat, setDefaultFiat] = useState('');

  const items = [
    { id: 'buy', content: 'Buy', is_active: true },
    { id: 'sell', content: 'Sell', is_active: true },
    { id: 'swap', content: 'Swap', is_active: true },
    { id: 'staking', content: 'Staking', is_active: true }
  ];
  const prodItems = [
    { id: 'buy', content: 'Buy', is_active: true },
    { id: 'sell', content: 'Sell', is_active: true },
    { id: 'swap', content: 'Swap', is_active: true }
  ];

  const [list, setList] = useState(
    process.env.REACT_APP_ENVIRONMENT === 'development' ? items : prodItems
  );

  useEffect(() => {
    let tabList = '';
    if (list && list.length > 0) {
      for (const tab of list.filter((item) => item.is_active)) {
        tabList += tab.id + ',';
      }
    }
    setView(tabList);
  }, [list]);

  const handleFunModeToggle = () => {
    getWidgetImagesFunc(widgetToken);
    setFunMode(!funMode);
  };

  const setWidgetImageFunc = (payload) => {
    setIsLoading(true);
    SetWidgetImagesAPI(payload)
      .then((res) => {
        setIsLoading(false);

        getWidgetImagesFunc(widgetToken);
      })
      .catch((err) => {
        setIsLoading(false);
      });
  };

  const getWidgetImagesFunc = (widgetToken) => {
    if (widgetToken) {
      GetWidgetImages(widgetToken)
        .then((res) => {
          const result = res.data.data.data;
          if (result.backgroundImage) {
            setImageName(result.backgroundImage);
          }

          if (result.backgroundSize) {
            setBackgroundSize(result.backgroundSize);
          }

          if (result.opacity) {
            setOpacity(result.opacity);
          }

          if (result.backgroundRepeat) {
            setBackgroundRepeat(result.backgroundRepeat);
          }

          if (result.funModeImages) {
            setImageFiles(result.funModeImages);
          }

          if (result.funModeConfirmScreenGifs) {
            setConfirmScreenGifFiles(result.funModeConfirmScreenGifs);
          }

          if (result.funModeLoadingGifs) {
            setLoadingGifFiles(result.funModeLoadingGifs);
          }

          if (result.funModeSwapCompletedGifs) {
            setSwapCompletedGifFiles(result.funModeSwapCompletedGifs);
          }

          if (result.funModeSwapFailedGifs) {
            setSwapFailedGifFiles(result.funModeSwapFailedGifs);
          }

          if (result.funModeSwapLoadingGifs) {
            setSwapLoadingGifFiles(result.funModeSwapLoadingGifs);
          }

          if (result.defaultCrypto) {
            setDefaultCoin(result.defaultCrypto);
          }

          if (result.defaultFiat) {
            setDefaultFiat(result.defaultFiat);
          }

          if (result.fromToken) {
            setFromCoin(result.fromToken);
          }
          if (result.fromNetwork) {
            setFromNetwork(result.fromNetwork);
          }

          if (result.toToken) {
            setToCoin(result.toToken);
          }
          if (result.toNetwork) {
            setToNetwork(result.toNetwork);
          }

          if (result.fiatAmount) {
            setAmountPay(result.fiatAmount);
          }

          if (result.fromTokenAmount) {
            setFromTokenAmount(result.fromTokenAmount);
          }

          if (result.primaryColor) {
            setPrimaryColor('#' + result.primaryColor);
          }

          if (result.secondaryColor) {
            setSecondaryColor('#' + result.secondaryColor);
          }

          if (result.containerColor) {
            setContainerColor('#' + result.containerColor);
          }

          if (result.primaryText) {
            setPrimaryText('#' + result.primaryText);
          }

          if (result.secondaryText) {
            setSecondaryText('#' + result.secondaryText);
          }

          if (result.cardColor) {
            setCardColor('#' + result.cardColor);
          }

          if (result.showBG) {
            setBgImage(result.showBG);
          }

          if (result.borderRadius) {
            setBorderRadius(Number(result.borderRadius));
          }

          if (result.widgetRadius) {
            setWidgetRadius(Number(result.widgetRadius));
          }

          if (result.showMemes) {
            setFunMode(result.showMemes);
          }

          if (result.checkedCoins) {
            setCheckedCoins(result.checkedCoins);
            let coinsStr = '';
            for (const coin of checkedCoins) {
              coinsStr += coin + ',';
            }
            setOnlyCryptoStr(coinsStr);
          }
        })
        .catch((err) => {});
    }
  };

  useEffect(() => {
    getWidgetImagesFunc(widgetToken);
  }, []);

  const handleOpacityChange = (event, newValue) => {
    setOpacity(newValue);
  };

  useEffect(() => {
    let coinsStr = '';
    for (const coin of checkedCoins) {
      coinsStr += coin + ',';
    }
    setOnlyCryptoStr(coinsStr);
  }, [checkedCoins]);
  const widgetDetails = `<iframe src="${
    process.env.REACT_APP_WIDGET_FRONTEND
  }/buy-sell/?accessToken=${widgetToken}&widgetTheme=${widgetTheme}${
    borderRadius ? '&borderRadius=' + borderRadius : ''
  }${widgetRadius ? '&widgetRadius=' + widgetRadius : ''}${
    primaryColor ? '&primaryColor=' + primaryColor.replace('#', '') : ''
  }${secondaryColor ? '&secondaryColor=' + secondaryColor.replace('#', '') : ''}${
    containerColor ? '&containerColor=' + containerColor.replace('#', '') : ''
  }${primaryText ? '&primaryText=' + primaryText.replace('#', '') : ''}${
    secondaryText ? '&secondaryText=' + secondaryText.replace('#', '') : ''
  }${cardColor ? '&cardColor=' + cardColor.replace('#', '') : ''}${
    defaultCoinSelect && defaultCoinSelect.coin_symbol
      ? '&defaultCrypto=' + defaultCoinSelect?.coin_symbol
      : ''
  }${
    defaultFiatSelect && defaultFiatSelect.currency
      ? '&defaultFiat=' + defaultFiatSelect?.currency
      : ''
  }${sendCoin && sendCoin.symbol ? '&fromToken=' + sendCoin?.symbol : ''}${
    receiveCoin && receiveCoin.symbol ? '&toToken=' + receiveCoin?.symbol : ''
  }${fromNetwork && fromNetwork.length > 0 ? '&fromNetwork=' + fromNetwork : ''}${
    toNetwork && toNetwork.length > 0 ? '&toNetwork=' + toNetwork : ''
  }${amountPay ? '&fiatAmount=' + amountPay : ''}${
    fromTokenAmount ? '&fromTokenAmount=' + fromTokenAmount : ''
  }${onlyCryptoStr && onlyCryptoStr.length > 0 ? '&onlyCrypto=' + onlyCryptoStr : ''}${
    backgroundSize ? '&backgroundSize=' + backgroundSize : ''
  }${backgroundRepeat ? '&backgroundRepeat=' + backgroundRepeat : ''}${
    opacity ? '&cardOpacity=' + opacity : ''
  }${funMode === true ? '&funMode=true' : ''}${bgImage === true ? '&bgImage=true' : ''}${
    view && view.length > 0 ? '&view=' + view : ''
  }"
width='450px'
height='650px'
title='1buy.io'
frameBorder='1'
/>`;

  function extractFromAddress(address) {
    if (address) {
      const firstSix = address.substring(0, 6);
      const lastFour = address.substring(address.length - 4);

      return `${firstSix}...${lastFour}`;
    }
  }
  const [passView, setPassView] = useState('');

  const getTokenData = (data, symbol, defaultSymbol, defaultNetwork) => {
    const isTestnet = process.env.REACT_APP_ENVIRONMENT === 'development';

    let tokenData =
      data.find((coin) => coin.symbol === symbol) ||
      data.find((coin) => coin.symbol === defaultSymbol);

    const withGoerliNetworkFilter = isTestnet
      ? (net) => net.slug === 'goerli' && net.is_testnet === true
      : isTestnet
      ? (net) => net.is_testnet
      : (net) => net.is_testnet !== true;

    const networkFilter = isTestnet
      ? (net) => net.is_testnet
      : (net) => net.is_testnet !== true;

    let filteredNetwork = tokenData.networks.filter(withGoerliNetworkFilter);
    if (!filteredNetwork || filteredNetwork.length <= 0) {
      filteredNetwork = tokenData.networks.filter(networkFilter);
    }
    const tokenNetwork =
      filteredNetwork.length > 0 ? filteredNetwork[0].slug : defaultNetwork;

    const isTokenNetworkValid = tokenData.networks.some(
      (net) => net.slug === tokenNetwork
    );
    return {
      tokenData,
      tokenNetwork: isTokenNetworkValid ? tokenNetwork : tokenData.networks[0].slug
    };
  };

  const swingTokensFunction = () => {
    GetAllSwingSwapCoins({ accessToken: process.env.REACT_APP_WIDGET_ACCESS_TOKEN })
      .then((res) => {
        const data = res.data.data.data;
        setAllSwap(data);
        const { tokenData: fromTokenData, tokenNetwork: fromTokenNetwork } = getTokenData(
          data,
          '',
          'ETH',
          'ethereum'
        );

        const { tokenData: toTokenData, tokenNetwork: toTokenNetwork } = getTokenData(
          data,
          '',
          'USDC',
          'ethereum'
        );

        const toToken = toTokenData.networks.filter(
          (coin) => coin.slug === toTokenNetwork
        );

        const fromToken = fromTokenData.networks.filter(
          (coin) => coin.slug === fromTokenNetwork
        );
        const sendCoinData = { ...fromToken[0], ...fromTokenData };
        setSendCoin(sendCoinData);
        setReceiveCoin({ ...toToken[0], ...toTokenData });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    swingTokensFunction();
  }, []);

  const fetchCoins = async (query) => {
    const [cacheDate, tokensCache] = await Promise.all([
      getCachedDate(),
      getAllCacheData()
    ]);
    if (
      tokensCache &&
      tokensCache.length > 0 &&
      cacheDate !== undefined &&
      cacheDate !== null &&
      cacheDate === moment().format('L')
    ) {
      setAllCoins(tokensCache);
      setDefaultCoinSelect(
        tokensCache.find((coinItem) => coinItem.coin_symbol === 'ETH')
      );
    } else {
      GetAllCoins({ query })
        .then((res) => {
          const data = get(res, 'data.data.data', []);

          setAllCoins(data);
          setDefaultCoinSelect(data.find((coinItem) => coinItem.coin_symbol === 'ETH'));
          addDataIntoCache(
            'allTokensList',
            process.env.REACT_APP_PROD_FRONTEND,
            JSON.stringify(data)
          );
          addDataIntoCache(
            'cachedDate',
            process.env.REACT_APP_PROD_FRONTEND,
            moment().format('L')
          );
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
        });
    }
  };

  useEffect(() => {
    fetchCoins();
  }, []);

  const fetchFiats = async () => {
    const [cacheDate, cacheDataFiats] = await Promise.all([
      getCachedDate(),
      getAllCacheFiats()
    ]);
    if (
      cacheDataFiats &&
      cacheDataFiats.length > 0 &&
      cacheDate !== undefined &&
      cacheDate !== null &&
      cacheDate === moment().format('L')
    ) {
      setAllFiats(cacheDataFiats);
      setDefaultFiatSelect(cacheDataFiats.find((curr) => curr.currency === 'USD'));
    } else {
      const res = await GetAllCountries(process.env.REACT_APP_WIDGET_ACCESS_TOKEN);
      let data = get(res, 'data.data.data', []);
      data = _.sortBy(data, 'country');
      setAllFiats(data);
      setDefaultFiatSelect(data.find((curr) => curr.currency === 'USD'));
      addFiatDataIntoCache(
        'allFiatsList',
        process.env.REACT_APP_FRONT_END_HOST,
        JSON.stringify(data)
      );
    }
  };

  useEffect(() => {
    fetchFiats();
  }, []);

  const funModeProps = {
    funMode,
    isUpdate,
    setIsUpdate,
    handleFunModeToggle,
    setFunModeImages,
    funModeLoadingGifs,
    setFunModeLoadingGifs,
    funModeConfirmScreenGifs,
    setFunModeConfirmScreenGifs,
    funModeSwapLoadingGifs,
    setFunModeSwapLoadingGifs,
    funModeSwapFailedGifs,
    setFunModeSwapFailedGifs,
    funModeSwapCompletedGifs,
    setFunModeSwapCompletedGifs,
    extractFromAddress,
    imageFiles,
    setImageFiles,
    loadingGifFiles,
    setLoadingGifFiles,
    confirmScreenGifFiles,
    setConfirmScreenGifFiles,
    swapLoadingGifFiles,
    setSwapLoadingGifFiles,
    swapFailedGifFiles,
    setSwapFailedGifFiles,
    swapCompletedGifFiles,
    setSwapCompletedGifFiles,
    isLoading
  };

  useEffect(() => {
    setIsUpdate(!isUpdate);
    setWidgetImageFunc({
      showMemes: funMode,
      funModeImages,
      funModeLoadingGifs,
      funModeConfirmScreenGifs,
      funModeSwapLoadingGifs,
      funModeSwapFailedGifs,
      funModeSwapCompletedGifs
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    funMode,
    funModeImages,
    funModeLoadingGifs,
    funModeConfirmScreenGifs,
    funModeSwapLoadingGifs,
    funModeSwapFailedGifs,
    funModeSwapCompletedGifs
  ]);

  const [openedSection, setOpenedSection] = useState(null);
  const toggleSection = (index) => {
    if (openedSection === index) {
      setOpenedSection(null);
    } else {
      setOpenedSection(index);
    }
  };

  const applyBgImages = () => {
    setIsUpdate(!isUpdate);
    setWidgetImageFunc({
      showBG: bgImage,
      backgroundSize,
      backgroundRepeat,
      opacity,
      backgroundImage: backgroundImage
    });
  };

  const applyColorChanges = () => {
    setIsUpdate(!isUpdate);
    setWidgetImageFunc({
      primaryColor,
      secondaryColor,
      containerColor,
      primaryText,
      secondaryText,
      cardColor
    });
    notifySuccess('Color changes applied!');
  };
  const applyRadiusChanges = () => {
    setIsUpdate(!isUpdate);
    setWidgetImageFunc({ widgetRadius, borderRadius });
    notifySuccess('Radius changes applied!');
  };
  const applyDefaultValuesChanges = () => {
    setIsUpdate(!isUpdate);
    let coinsStr = '';
    for (const coin of checkedCoins) {
      coinsStr += coin + ',';
    }
    setWidgetImageFunc({
      fromNetwork,
      toNetwork,
      defaultCrypto: defaultCoin,
      defaultFiat: defaultFiat,
      passSendCoin: sendCoin?.symbol,
      passReceiveCoin: receiveCoin?.symbol,
      fromTokenAmount: fromTokenAmount,
      fiatAmount: amountPay,
      checkedCoins
    });

    setOnlyCryptoStr(coinsStr);
    notifySuccess('Default Value changes applied!');
  };

  const applyOrderChanges = (list) => {
    let tabList = '';
    if (list && list.length > 0) {
      for (const tab of list.filter((item) => item.is_active)) {
        tabList += tab.id + ',';
      }
    }
    setPassView(tabList);
    notifySuccess('Order changes applied!');
  };

  const handleBorderRadiusChange = (event, newValue) => {
    setBorderRadius(newValue);
  };

  const handleWidgetRadiusChange = (event, newValue) => {
    setWidgetRadius(newValue);
  };

  const colorSelectionProps = {
    primaryColor,
    secondaryColor,
    containerColor,
    primaryText,
    secondaryText,
    cardColor,
    setPrimaryColor,
    setSecondaryColor,
    setContainerColor,
    setPrimaryText,
    setSecondaryText,
    setCardColor,
    applyColorChanges
  };

  const radiusProps = {
    borderRadius,
    widgetRadius,
    handleBorderRadiusChange,
    handleWidgetRadiusChange,
    applyRadiusChanges
  };

  const defaultValuesProps = {
    widgetTheme,
    setActiveTab,
    defaultCoinSelect,
    setDefaultCoinSelect,
    defaultFiatSelect,
    setDefaultFiatSelect,
    allFiats,
    setAllFiats,
    allSwap,
    setAllSwap,
    allCoins,
    setAllCoins,
    sendCoin,
    setFromCoin,
    fromNetwork,
    setFromNetwork,
    toNetwork,
    setToCoin,
    setToNetwork,
    setSendCoin,
    receiveCoin,
    setReceiveCoin,
    amountPay,
    setAmountPay,
    fromTokenAmount,
    setFromTokenAmount,
    checkedCoins,
    setCheckedCoins,
    applyDefaultValuesChanges
  };
  const reorderProps = {
    list,
    setList,
    applyOrderChanges
  };

  const bgImageProps = {
    bgImage,
    isUpdate,
    setIsUpdate,
    setBgImage,
    imageName,
    setImageName,
    selectedFile,
    setSelectedFile,
    setBackgroundImage,
    setBackgroundSize,
    backgroundSize,
    backgroundRepeat,
    setBackgroundRepeat,
    opacity,
    handleOpacityChange,
    applyBgImages
  };

  const iframeData = (
    <iframe
      src={`${process.env.REACT_APP_WIDGET_FRONTEND}/buy-sell/?accessToken=${
        widgetToken ? widgetToken : process.env.REACT_APP_WIDGET_ACCESS_TOKEN
      }&widgetTheme=${widgetTheme}${funMode === true ? '&funMode=true' : ''}${
        bgImage === true ? '&bgImage=true' : ''
      }${passView && passView.length > 0 ? '&view=' + passView : ''}${
        activeTab ? '&activeTab=' + activeTab : ''
      }&savedTheme=true${isUpdate ? '&isUpdate=' + isUpdate : '&isUpdate=' + isUpdate}`}
      style={{ borderRadius: '10px' }}
      width='450px'
      height='650px'
      title='1buy.io'
      // frameBorder='1'
    />
  );

  return (
    <React.Fragment>
      <div className='page-content'>
        {widgetToken === '' ? (
          <span className='font-size-16 '>
            First request for api key approval and create widget access token!{' '}
            <Link to='/business-profile'>
              <span style={{ color: 'blue' }}>Click here</span>
            </Link>
          </span>
        ) : (
          <div className='py-2 exchange-card-border ml-2 mr-2 mt-2'>
            <span className='font-size-22 font-weight-bold ml-3'>Widget theming</span>

            <Row className='ml-2 mr-2 mt-3'>
              <Col lg={6} md={4}>
                <div className='customization-card-border p-2'>
                  <div
                    className='cursor-pointer col-12 font-size-14 d-flex justify-content-between align-items-center'
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSection(0);
                    }}>
                    <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
                      Widget Theme
                    </p>
                    <i
                      className={`font-size-22 mdi mdi-chevron-${
                        openedSection === 0 ? 'up' : 'down'
                      }`}
                    />
                  </div>
                  <Collapse isOpen={openedSection === 0}>
                    <div className='d-flex justify-content-around mt-1'>
                      <div
                        className='d-flex justify-content-center align-items-center cursor-pointer'
                        style={{ flexDirection: 'column' }}
                        onClick={() => setWidgetTheme('light')}>
                        <div className='circle'></div>
                        <p className='mt-1 text-black font-size-12 font-wright-medium'>
                          Light
                        </p>
                      </div>
                      <div
                        className='d-flex justify-content-center align-items-center cursor-pointer'
                        style={{ flexDirection: 'column' }}
                        onClick={() => setWidgetTheme('dark')}>
                        <div className='dark'></div>
                        <p className='mt-1 text-black font-size-12 font-wright-medium'>
                          Dark
                        </p>
                      </div>
                    </div>
                  </Collapse>
                </div>

                <div className='customization-card-border p-2'>
                  <div
                    className='cursor-pointer col-12 font-size-14 d-flex justify-content-between align-items-center'
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSection(1);
                    }}>
                    <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
                      Colors
                    </p>
                    <i
                      className={`font-size-22 mdi mdi-chevron-${
                        openedSection === 1 ? 'up' : 'down'
                      }`}
                    />
                  </div>
                  <Collapse isOpen={openedSection === 1}>
                    <ColorSelection {...colorSelectionProps} />
                  </Collapse>
                </div>

                <div className='customization-card-border p-2'>
                  <div
                    className='cursor-pointer col-12 font-size-14 d-flex justify-content-between align-items-center'
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSection(2);
                    }}>
                    <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
                      Radius
                    </p>
                    <i
                      className={`font-size-22 mdi mdi-chevron-${
                        openedSection === 2 ? 'up' : 'down'
                      }`}
                    />
                  </div>
                  <Collapse isOpen={openedSection === 2}>
                    <RadiusSelection {...radiusProps} />
                  </Collapse>
                </div>

                <div className='customization-card-border p-2'>
                  <div
                    className='cursor-pointer col-12 font-size-14 d-flex justify-content-between align-items-center'
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSection(3);
                    }}>
                    <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
                      Re-Order Tabs
                    </p>
                    <i
                      className={`font-size-22 mdi mdi-chevron-${
                        openedSection === 3 ? 'up' : 'down'
                      }`}
                    />
                  </div>
                  <Collapse isOpen={openedSection === 3}>
                    <ReorderTabs {...reorderProps} />
                  </Collapse>
                </div>

                <div className='customization-card-border p-2'>
                  <div
                    className='cursor-pointer col-12 font-size-14 d-flex justify-content-between align-items-center'
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSection(4);
                    }}>
                    <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
                      Background Image
                    </p>
                    <i
                      className={`font-size-22 mdi mdi-chevron-${
                        openedSection === 4 ? 'up' : 'down'
                      }`}
                    />
                  </div>
                  <Collapse isOpen={openedSection === 4}>
                    <BGImages {...bgImageProps} />
                  </Collapse>
                </div>

                <div className='customization-card-border p-2'>
                  <div
                    className='cursor-pointer col-12 font-size-14 d-flex justify-content-between align-items-center'
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSection(5);
                    }}>
                    <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
                      Default Values
                    </p>
                    <i
                      className={`font-size-22 mdi mdi-chevron-${
                        openedSection === 5 ? 'up' : 'down'
                      }`}
                    />
                  </div>
                  <Collapse isOpen={openedSection === 5}>
                    <DefaultSelectedValues {...defaultValuesProps} />
                  </Collapse>
                </div>

                <div className='customization-card-border p-2'>
                  <div
                    className='cursor-pointer col-12 font-size-14 d-flex justify-content-between align-items-center'
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleSection(6);
                    }}>
                    <p className='font-size-12 text-black font-weight-medium mb-2 mt-2'>
                      Fun Mode
                    </p>
                    <i
                      className={`font-size-22 mdi mdi-chevron-${
                        openedSection === 6 ? 'up' : 'down'
                      }`}
                    />
                  </div>
                  <Collapse isOpen={openedSection === 6}>
                    <FunMode {...funModeProps} />
                  </Collapse>
                </div>
              </Col>
              <Col
                lg={6}
                md={4}
                className='d-flex justify-content-center'
                style={{ minWidth: '400px' }}>
                {iframeData}
              </Col>
            </Row>

            <hr />
            <Row className='mt-3 mx-3'>
              <Col lg={6}>
                <span className='font-size-12 font-weight-medium'>Your Widget Code</span>
                <pre>
                  <code>{widgetDetails}</code>
                </pre>
                <button
                  onClick={() => {
                    copy(widgetDetails);
                    notifySuccess('iframe code copied successfully');
                  }}
                  title='Copy to clipboard'
                  className={`btn btn-auth`}
                  style={{ width: 150 }}>
                  Copy iframe
                </button>
              </Col>
            </Row>
          </div>
        )}
      </div>
      <Drawer
        anchor='bottom'
        open={
          defaultCoinOpt ||
          defaultFiatOpt ||
          sendShowTokens ||
          receiveShowTokens ||
          onlyCryptoShowTokens
        }
        onClose={() => {
          setDefaultCoinOpt(false);
          setDefaultFiatOpt(false);
          setSendShowTokens(false);
          setReceiveShowTokens(false);
          setOnlyCryptoShowTokens(false);
        }}>
        {defaultCoinOpt && (
          <DefaultToken
            coins={allCoins}
            onSelect={(v) => {
              setDefaultCoinSelect(v);
              setDefaultCoinOpt(false);
            }}
            onClose={() => setDefaultCoinOpt(false)}
          />
        )}
        {defaultFiatOpt && (
          <DefaultFiats
            fiats={allFiats}
            onSelect={(v) => {
              setDefaultFiatSelect(v);
              setDefaultFiatOpt(false);
            }}
            onClose={() => setDefaultCoinOpt(false)}
          />
        )}

        {sendShowTokens && (
          <SwingSwapCoins
            coins={allSwap}
            currentCoin={sendCoin}
            networkState={(v) => {
              setFromNetwork(v);
            }}
            onSelect={(v) => {
              setSendCoin(v);
              setSendShowTokens(false);
            }}
            onClose={() => setSendShowTokens(false)}
            widgetTheme={widgetTheme}
          />
        )}
        {receiveShowTokens && (
          <SwingSwapCoins
            coins={allSwap}
            networkState={(v) => setToNetwork(v)}
            currentCoin={receiveCoin}
            onSelect={(v) => {
              setReceiveCoin(v);
              setReceiveShowTokens(false);
            }}
            onClose={() => setReceiveShowTokens(false)}
            isReceiveCoin={true}
            widgetTheme={widgetTheme}
          />
        )}
        {onlyCryptoShowTokens && (
          <OnlyCrypto
            coins={allCoins}
            onClose={() => setOnlyCryptoShowTokens(false)}
            isReceiveCoin={true}
            widgetTheme={widgetTheme}
            checkedCoins={checkedCoins}
            setCheckedCoins={setCheckedCoins}
          />
        )}
      </Drawer>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default WidgetCustomization1Buy;
