import React from 'react';
import { Card } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import { ApplicationContext } from './applicationContext';
import AcceptApplication from '../../Exchange/ExchangeVIewApplication/AddStatusChange/AcceptApplication';
import RejectApplication from '../../Exchange/ExchangeVIewApplication/AddStatusChange/RejectApplication';

function ChangeRequests({
  selectedStatus,
  applicationId,
  handleChangeStatus,
  modal,
  handleModal,
  setUpdatePage,
  updatePage,
  GetApplicationResponseFunc
}) {
  return (
    <SimpleBar style={{ height: '150vh' }}>
      <ApplicationContext.Provider value={applicationId}>
        {selectedStatus === 'ACCEPT' ? (
          <AcceptApplication
            applicationId={applicationId}
            modal={modal}
            handleOpenModal={handleModal}
            setUpdatePage={setUpdatePage}
            updatePage={updatePage}
            GetApplicationResponseFunc={GetApplicationResponseFunc}
          />
        ) : (
          <RejectApplication
            applicationId={applicationId}
            modal={modal}
            handleOpenModal={handleModal}
            setUpdatePage={setUpdatePage}
            updatePage={updatePage}
            GetApplicationResponseFunc={GetApplicationResponseFunc}
          />
        )}
      </ApplicationContext.Provider>
      <div data-simplebar className=''>
        <Card className='p-1'>
          <div className='d-flex justify-content-center mt-2 mb-2'>
            <button
              className={`btn mr-3 btn-success cursor-pointer`}
              onClick={() => {
                handleChangeStatus('ACCEPT');
              }}>
              Accept Application
            </button>

            <button
              className={`btn btn-danger cursor-pointer`}
              onClick={() => {
                handleChangeStatus('REJECT');
              }}>
              Reject Application
            </button>
          </div>
        </Card>{' '}
      </div>
    </SimpleBar>
  );
}

export default ChangeRequests;
