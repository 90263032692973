export const constants = {
  LOGIN: '/login',
  REGISTER: '/register',
  VERIFY_ACCOUNT: '/verify-account',
  FORGET_PASSWORD: '/forgot-password',
  VERIFY_ACCOUNT_PASSWORD: '/verify-account-password',
  CHANGE_PASSWORD: '/change-password',
  DASHBOARD: '/dashboard',
  BUSINESS_DASHBOARD: '/business-dashboard',
  ONRAMPS: '/onramps',
  BUSINESS_SEARCH: '/business-search',
  PROVIDER: '/provider',
  COUNTRIES: '/countries',
  BUSINESS_PROFILE: '/business-profile',
  WIDGET_SETTINGS: '/widget-settings',
  BUSINESS_REFERRALS: '/business-referrals',
  BUSINESS_NFT: '/business-nft',
  ONBOARDING: '/onboarding',
  WIDGET_ONBOARDING: '/widget-onboarding',
  ALL_CRYPTO: '/all-crypto',
  CRYPTO: '/crypto',
  LISTUS_APPLICATIONS: '/admin-listus-applications',
  LISTUS_ONRAMP_APPLICATIONS: '/admin-onramp-applications',
  NOTIFICATIONS: '/notifications',
  NETWORK: '/network/:id',
  BEST_BUY_WIDGET: '/best-buy',
  BUY_SELL_WIDGET: '/buy-sell',
  NEWS: '/news',
  CREATE_APPLICATION: '/application/create',
  ORDERS: '/orders',
  SETTING_APPLICATION: '/application/setting',
  GET_APPLICATION: '/applications',
  EXCHANGE_GET_APPLICATION: '/exchange-applications',
  CREATE_ONRAMP_APPLICATION: '/application/onramp/create',
  CREATE_EXCHANGE_APPLICATION: '/application/exchange/create',
  VIEW_APPLICATION: '/application/view',
  ADMIN_VIEW_APPLICATION: '/admin-application/view',
  VIEW_EXCHANGE_APPLICATION: '/application/viewExchange',
  EXCHANGE_APPLICATION_DETAIL: '/exchange-application/view-exchange',
  EDIT_SUBAPPLICATION: '/sub-application/edit',
  PAY_QUOTE: '/sub-application/pay-quote',
  SETTINGS: '/settings',
  USER_INVITE: '/user/invite',
  MEMBERS: '/members',
  ADMIN_DASHBOARD: '/admin-dashboard',
  WIDGET_CUSTOMIZE_1_BUY: '/widget-customize-1buy'
};
