import { ErrorMessage, Field } from 'formik';
import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import { ListingProgramInputName } from './helper';
import { get } from 'lodash';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';

function ListingProgramApp({
  allResponses = [],
  errors = {},
  values = {},
  initialValue = {},
  setFieldValue = () => {},
  handleChange = () => {},
  isSectionCollapsed,
  toggleSection,
  applicationId,
  setInitialValuesFunc
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);

  useEffect(() => {
    const res = Object.keys(errors).find((i) => ListingProgramInputName.includes(i));
    setErrorShow(!!res);
  }, [errors]);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          ListingProgramInputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);

  const handleSubmit = () => {
    if (
      !ListingProgramInputName.includes(
        Object.keys(errors).find((i) => ListingProgramInputName.includes(i))
      )
    ) {
      setIsLoading(true);
      const listingValue = values.listing_program_at_bybit
        .filter((list) => list.isChecked === true && list.value !== 'all_programs')
        .map((list) => list.value);
      const data = {
        listing_program: JSON.stringify(listingValue)
      };

      JSON.stringify(data);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          notifySuccess(`Listing Program updated successfully`);
          setIsLoading(false);
          setInitialValuesFunc(application.exchange_application);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Programs of Interest</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <Row>
                <Col lg={6}>
                  <div className='font-size-12'>
                    <Label
                      htmlFor='description'
                      className='text-black font-weight-normal'>
                      Checkboxes
                    </Label>
                  </div>
                  <div>
                    {values.listing_program_at_bybit?.map((program, index) => (
                      <FormGroup check key={index}>
                        <Label check>
                          <Field
                            type='checkbox'
                            name={`listing_program_at_bybit[${index}].isChecked`}
                            as={Input}
                            onChange={(event) => {
                              const { name, checked } = event.target;
                              if (name === 'listing_program_at_bybit[3].isChecked') {
                                const updatedPrograms =
                                  initialValue.listing_program_at_bybit.map(
                                    (program, index) => {
                                      return {
                                        ...program,
                                        isChecked: checked
                                      };
                                    }
                                  );

                                setFieldValue(
                                  'listing_program_at_bybit',
                                  updatedPrograms
                                );
                              } else {
                                handleChange(event);
                              }
                            }}
                          />{' '}
                          <span className='font-size-12'>{program.label}</span>
                        </Label>
                      </FormGroup>
                    ))}
                    <ErrorMessage name='listing_program_at_bybit'>
                      {(errorMsg) => (
                        <div className='font-size-12' style={{ color: 'red' }}>
                          {errorMsg}
                        </div>
                      )}
                    </ErrorMessage>
                  </div>
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default ListingProgramApp;
