import get from 'lodash/get';
import uniq from 'lodash/uniq';
import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { ReactComponent as CloseIcon } from '../../assets/images/icons/close.svg';

function CoinSelect({
  className = '',
  coins = [],
  onClose = () => {},
  onSelect = () => {}
}) {
  const [search, setSearch] = useState('');
  // const [network, setNetwork] = useState('');

  const filteredCoins = search
    ? (Array.isArray(coins) ? coins : []).filter(
        ({ coin_name }) =>
          !search ||
          [coin_name]
            .join('')
            .toLowerCase()
            .includes((search || '').toLowerCase())
      )
    : coins;
  // const networks = uniq(
  //   (Array.isArray(coins) ? coins : [])
  //     .filter(({ network }) => get(network, 'length', 0) > 0)
  //     .map(({ network }) => network[0])
  // ).sort();

  return (
    <div className={'py-3 ' + className}>
      <div className='d-flex align-items-center text-truncate mb-3 mx-3'>
        <div className='font-size-20 font-weight-bold flex-fill text-center'>
          Select CryptoCurrency
        </div>
      </div>
      <button
        type='button'
        className='btn btn-link p-0 position-absolute'
        style={{ right: 16, top: 18, zIndex: 1 }}
        onClick={onClose}>
        <CloseIcon height={24} className='' />
      </button>
      <div className='mx-3 px-3 pb-3'>
        <input
          type='text'
          className='form-control form-control-search mx-1'
          placeholder='Search'
          value={search}
          onInput={(e) => setSearch(e.target.value)}
          onChange={(e) => setSearch(e.target.value)}
          style={{ minWidth: '200px', maxWidth: '100%' }}
        />
      </div>
      <SimpleBar style={{ maxHeight: 'calc(80vh - 145px)' }}>
        {filteredCoins.map((coin, i) => (
          <div
            key={i}
            className='d-flex align-items-center text-truncate cursor-pointer rounded hover:bg-gray-900 hover:text-white py-2 px-3 mx-3'
            onClick={() => onSelect(coin)}>
            <img
              src={coin.logo}
              alt=''
              width={36}
              style={{ maxHeight: '36px' }}
              className='rounded-circle mr-3'
            />
            <div className='font-size-18 text-uppercase mr-1'>{coin.coin_name}</div>
            <div className='ml-auto badge text-uppercase badge-secondary ml-1 font-size-16'>
              {get(coin, 'network_symbol', '')}
            </div>
          </div>
        ))}
      </SimpleBar>
    </div>
  );
}

export default CoinSelect;
