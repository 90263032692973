/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';

function MercuryoWidget({ backToWidget, buyData }) {
  return (
    <>
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light p-1 mb-2"
          style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
          onClick={backToWidget}>
          <ArrowLeftIcon width={18} height={18} />
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <iframe
          scrolling="no"
          style={{ height: '578px', width: '450px' }}
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          frameBorder="0"
          src={`${process.env.REACT_APP_MERCURYO}?currency=${buyData.cryptoCurrency}&fiat_amount=${buyData.fiat_value}&fiat_currency=${buyData.fiat_currency}&network=${buyData.network}&type=buy&widget_id=${process.env.REACT_APP_MERCURYO_WIDGET_ID}`}
        />
      </div>
    </>
  );
}

export default MercuryoWidget;
