import React, { useEffect, useState } from 'react';
import _, { get } from 'lodash';
import { StyledEngineProvider } from '@mui/material';
import { Card, CardBody, Col, Collapse, Row } from 'reactstrap';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import Typography from '@mui/material/Typography';
import telegram from '../../../assets/images/telegram.png';
import fingerprint from '../../../assets/images/fingerprint.png';
import CompanySocials from './CompanySocials';
import CreateAPIKey from './CreateAPIKey';
import colorSelection from '../../../assets/images/color-selection.png';
import startup from '../../../assets/images/startup.png';
import checkMark from '../../../assets/images/check-mark.png';
import clone from '../../../assets/images/clone.png';
import videoconference from '../../../assets/images/videoconference.png';
import programming from '../../../assets/images/programming-code-signs.png';
import Customizations from './Customizations';
import {
  addDataIntoCache,
  addFiatDataIntoCache,
  getAllCacheData,
  getAllCacheFiats,
  getCachedDate
} from '../../WidgetCustomization1Buy.js/CacheFunctions';
import moment from 'moment';
import {
  GetAllCoins,
  GetAllCountries,
  GetAllSwingSwapCoins
} from '../../../services/Business/Crypto';
import EmbeddedWidget from './EmbeddedWidget';
import { useDebounce } from 'use-debounce';
import ProductionLaunch from './ProductionLaunch';
import CloneToProduction from './CloneToProduction';
import GoProduction from './GoProduction';
import {
  CreateWidgetApplication,
  GetAllNetwork,
  GetWidgetApplication,
  GetWidgetImages
} from '../../../services/Business/Application';
import {
  GenerateAPIKey,
  GenerateRefreshedAccessToken,
  GetExpiredDateAPI
} from '../../../services/Business/APIKey';
import { GetMyProfileAPI } from '../../../services/Auth';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../../Custom/notification';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import BookDemo from './BookDemo';

const steps = [
  {
    label: 'Company Socials',
    description: "We'd love to stay connected with you!"
  },
  {
    label: 'Access to 1buy.io Widget',
    description:
      "To gain access to the 1buy.io widget, you'll need to create an API key and secret key."
  },
  {
    label: 'Customize Design',
    description: 'Tailor the front-end design to align with your brand identity.'
  },
  {
    label: 'Embed Widget',
    description:
      'Embed our Widget on your testing website by copying and pasting the provided iframe code.'
  },
  {
    label: 'Prepare Production',
    description: 'Prepare for production launch.'
  },
  {
    label: 'Production Credentials',
    description:
      'Duplicate your development widget to production effortlessly with a single click.'
  },
  {
    label: 'Book a Demo',
    description:
      'Schedule a demonstration if you have any inquiries or wish to learn more about our product.'
  },
  {
    label: 'Complete',
    description: 'All Done!'
  }
];

function WidgetOnboardingSteps() {
  const [openedSection, setOpenedSection] = useState(0);
  const [widgetApplicationDetail, setWidgetApplicationDetail] = useState({});

  const [defaultCoinSelect, setDefaultCoinSelect] = useState(null);
  const [defaultFiatSelect, setDefaultFiatSelect] = useState(null);

  const [allFiats, setAllFiats] = useState([]);
  const [allSwap, setAllSwap] = useState([]);
  const [allCoins, setAllCoins] = useState([]);
  const [sendCoin, setSendCoin] = useState(null);
  const [receiveCoin, setReceiveCoin] = useState(null);

  const [widgetToken, setWidgetToken] = useState('');
  const [widgetTheme, setWidgetTheme] = useState('light');
  const [funMode, setFunMode] = useState(false);
  const [bgImage, setBgImage] = useState(false);

  const [primaryColor, setPrimaryColor] = useState(
    widgetTheme === 'dark' ? '#46de8c' : widgetTheme === 'light' ? '#241d1c' : '#241d1c'
  );
  const [secondaryColor, setSecondaryColor] = useState(
    widgetTheme === 'dark' ? '#3f3f43' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
  );
  const [containerColor, setContainerColor] = useState(
    widgetTheme === 'dark' ? '#141416' : widgetTheme === 'light' ? '#ffffff' : '#ffffff'
  );
  const [primaryText, setPrimaryText] = useState(
    widgetTheme === 'dark' ? '#ffffff' : widgetTheme === 'light' ? '#141519' : '#141519'
  );
  const [secondaryText, setSecondaryText] = useState('#babec1');
  const [cardColor, setCardColor] = useState(
    widgetTheme === 'dark' ? '#272727' : widgetTheme === 'light' ? '#f6f7f9' : '#f6f7f9'
  );

  const [onlyCryptoStr, setOnlyCryptoStr] = useState('');
  const [checkedCoins, setCheckedCoins] = useState([]);

  const [amountPay, setAmountPay] = useState(null);
  const [debouncedAmountPay] = useDebounce(amountPay, 1000);
  const [fromTokenAmount, setFromTokenAmount] = useState(null);
  const [opacity, setOpacity] = useState(1);
  const [borderRadius, setBorderRadius] = useState(0.5);
  const [widgetRadius, setWidgetRadius] = useState(0.5);
  const [backgroundSize, setBackgroundSize] = useState('cover');
  const [backgroundRepeat, setBackgroundRepeat] = useState('repeat');

  const [passBorderRadius, setPassBorderRadius] = useState(null);
  const [passView, setPassView] = useState('');

  const [fromCoin, setFromCoin] = useState('');
  const [toCoin, setToCoin] = useState('');
  const [fromNetwork, setFromNetwork] = useState('');
  const [toNetwork, setToNetwork] = useState('');
  const [defaultCoin, setDefaultCoin] = useState('');
  const [defaultFiat, setDefaultFiat] = useState('');

  const [imageFiles, setImageFiles] = useState([]);
  const [loadingGifFiles, setLoadingGifFiles] = useState([]);
  const [confirmScreenGifFiles, setConfirmScreenGifFiles] = useState([]);
  const [swapLoadingGifFiles, setSwapLoadingGifFiles] = useState([]);
  const [swapFailedGifFiles, setSwapFailedGifFiles] = useState([]);
  const [swapCompletedGifFiles, setSwapCompletedGifFiles] = useState([]);
  const [imageName, setImageName] = useState('');
  const [clonedEmail, setClonedEmail] = useState('');
  const [clonedPassword, setClonedPassword] = useState('');

  const createWidgetApp = (data) => {
    CreateWidgetApplication(data)
      .then((res) => {
        getWidgetApplicationFunc();
      })
      .catch((err) => {});
  };

  const getWidgetApplicationFunc = () => {
    GetWidgetApplication()
      .then((res) => {
        setWidgetApplicationDetail(res.data.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getWidgetApplicationFunc();
  }, []);

  const getTokenData = (data, symbol, defaultSymbol, defaultNetwork) => {
    const isTestnet = process.env.REACT_APP_ENVIRONMENT === 'development';

    let tokenData =
      data.find((coin) => coin.symbol === symbol) ||
      data.find((coin) => coin.symbol === defaultSymbol);

    const withGoerliNetworkFilter = isTestnet
      ? (net) => net.slug === 'goerli' && net.is_testnet === true
      : isTestnet
      ? (net) => net.is_testnet
      : (net) => net.is_testnet !== true;

    const networkFilter = isTestnet
      ? (net) => net.is_testnet
      : (net) => net.is_testnet !== true;

    let filteredNetwork = tokenData.networks.filter(withGoerliNetworkFilter);
    if (!filteredNetwork || filteredNetwork.length <= 0) {
      filteredNetwork = tokenData.networks.filter(networkFilter);
    }
    const tokenNetwork =
      filteredNetwork.length > 0 ? filteredNetwork[0].slug : defaultNetwork;

    const isTokenNetworkValid = tokenData.networks.some(
      (net) => net.slug === tokenNetwork
    );
    return {
      tokenData,
      tokenNetwork: isTokenNetworkValid ? tokenNetwork : tokenData.networks[0].slug
    };
  };

  const swingTokensFunction = () => {
    GetAllSwingSwapCoins({ accessToken: process.env.REACT_APP_WIDGET_ACCESS_TOKEN })
      .then((res) => {
        const data = res.data.data.data;
        setAllSwap(data);
        const { tokenData: fromTokenData, tokenNetwork: fromTokenNetwork } = getTokenData(
          data,
          fromCoin,
          'ETH',
          'ethereum'
        );
        const { tokenData: toTokenData, tokenNetwork: toTokenNetwork } = getTokenData(
          data,
          toCoin,
          'USDC',
          'ethereum'
        );

        const toToken = toTokenData.networks.filter(
          (coin) => coin.slug === toTokenNetwork
        );

        const fromToken = fromTokenData.networks.filter(
          (coin) => coin.slug === fromTokenNetwork
        );
        const sendCoinData = { ...fromToken[0], ...fromTokenData };
        setSendCoin(sendCoinData);
        setReceiveCoin({ ...toToken[0], ...toTokenData });
      })
      .catch((err) => {});
  };

  useEffect(() => {
    swingTokensFunction();
  }, [fromCoin, toCoin]);

  const fetchCoins = async (query) => {
    const [cacheDate, tokensCache] = await Promise.all([
      getCachedDate(),
      getAllCacheData()
    ]);
    if (
      tokensCache &&
      tokensCache.length > 0 &&
      cacheDate !== undefined &&
      cacheDate !== null &&
      cacheDate === moment().format('L')
    ) {
      setAllCoins(tokensCache);
      if (defaultCoin && defaultCoin.length > 0) {
        const defaultData = tokensCache.find(
          ({ coin_symbol }) =>
            coin_symbol.toUpperCase() === defaultCoin ||
            coin_symbol.toUpperCase() === defaultCoin.toUpperCase()
        );
        setDefaultCoinSelect(defaultData);
      } else {
        setDefaultCoinSelect(
          tokensCache.find((coinItem) => coinItem.coin_symbol === 'ETH')
        );
      }
    } else {
      GetAllCoins({ query })
        .then((res) => {
          const data = get(res, 'data.data.data', []);

          setAllCoins(data);
          if (defaultCoin && defaultCoin.length > 0) {
            const defaultData = data.find(
              ({ coin_symbol }) =>
                coin_symbol.toUpperCase() === defaultCoin ||
                coin_symbol.toUpperCase() === defaultCoin.toUpperCase()
            );
            setDefaultCoinSelect(defaultData);
          } else {
            setDefaultCoinSelect(data.find((coinItem) => coinItem.coin_symbol === 'ETH'));
          }

          addDataIntoCache(
            'allTokensList',
            process.env.REACT_APP_PROD_FRONTEND,
            JSON.stringify(data)
          );
          addDataIntoCache(
            'cachedDate',
            process.env.REACT_APP_PROD_FRONTEND,
            moment().format('L')
          );
        })
        .catch((err) => {
          const errorMessage =
            err && err.data && err.data.error && err.data.error.message;
        });
    }
  };

  useEffect(() => {
    fetchCoins();
  }, [defaultCoin]);

  const fetchFiats = async () => {
    const [cacheDate, cacheDataFiats] = await Promise.all([
      getCachedDate(),
      getAllCacheFiats()
    ]);
    if (
      cacheDataFiats &&
      cacheDataFiats.length > 0 &&
      cacheDate !== undefined &&
      cacheDate !== null &&
      cacheDate === moment().format('L')
    ) {
      setAllFiats(cacheDataFiats);
      setDefaultFiatSelect(cacheDataFiats.find((curr) => curr.currency === 'USD'));
    } else {
      const res = await GetAllCountries(process.env.REACT_APP_WIDGET_ACCESS_TOKEN);
      let data = get(res, 'data.data.data', []);
      data = _.sortBy(data, 'country');
      setAllFiats(data);
      setDefaultFiatSelect(data.find((curr) => curr.currency === 'USD'));
      addFiatDataIntoCache(
        'allFiatsList',
        process.env.REACT_APP_FRONT_END_HOST,
        JSON.stringify(data)
      );
    }
  };

  useEffect(() => {
    fetchFiats();
  }, []);

  const toggleSection = (index) => {
    if (openedSection === index) {
      setOpenedSection(null);
    } else {
      setOpenedSection(index);
    }
  };

  const icons = [
    telegram,
    fingerprint,
    colorSelection,
    programming,
    clone,
    startup,
    videoconference,
    checkMark
  ];

  const widgetDetails = `<iframe src="${
    process.env.REACT_APP_WIDGET_FRONTEND
  }/buy-sell/?accessToken=${
    widgetToken ? widgetToken : '{{ACCESS_TOKEN}}'
  }&widgetTheme=${widgetTheme}${borderRadius ? '&borderRadius=' + borderRadius : ''}${
    widgetRadius ? '&widgetRadius=' + widgetRadius : ''
  }${primaryColor ? '&primaryColor=' + primaryColor.replace('#', '') : ''}${
    secondaryColor ? '&secondaryColor=' + secondaryColor.replace('#', '') : ''
  }${containerColor ? '&containerColor=' + containerColor.replace('#', '') : ''}${
    primaryText ? '&primaryText=' + primaryText.replace('#', '') : ''
  }${secondaryText ? '&secondaryText=' + secondaryText.replace('#', '') : ''}${
    cardColor ? '&cardColor=' + cardColor.replace('#', '') : ''
  }${
    defaultCoinSelect && defaultCoinSelect.coin_symbol
      ? '&defaultCrypto=' + defaultCoinSelect?.coin_symbol
      : ''
  }${
    defaultFiatSelect && defaultFiatSelect.currency
      ? '&defaultFiat=' + defaultFiatSelect?.currency
      : ''
  }${sendCoin && sendCoin.symbol ? '&fromToken=' + sendCoin?.symbol : ''}${
    receiveCoin && receiveCoin.symbol ? '&toToken=' + receiveCoin?.symbol : ''
  }${amountPay ? '&fiatAmount=' + amountPay : ''}${
    fromTokenAmount ? '&fromTokenAmount=' + fromTokenAmount : ''
  }${onlyCryptoStr && onlyCryptoStr.length > 0 ? '&onlyCrypto=' + onlyCryptoStr : ''}${
    backgroundSize ? '&backgroundSize=' + backgroundSize : ''
  }${backgroundRepeat ? '&backgroundRepeat=' + backgroundRepeat : ''}${
    opacity ? '&cardOpacity=' + opacity : ''
  }${funMode === true ? '&funMode=true' : ''}${bgImage === true ? '&bgImage=true' : ''}${
    passView && passView.length > 0 ? '&view=' + passView : ''
  }${fromNetwork && fromNetwork.length > 0 ? '&fromNetwork=' + fromNetwork : ''}${
    toNetwork && toNetwork.length > 0 ? '&toNetwork=' + toNetwork : ''
  }"
width='450px'
height='650px'
title='1buy.io'
frameBorder='1'
/>`;
  const [userData, setUserData] = useState(null);

  const getProfileDataFunction = () => {
    GetMyProfileAPI()
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setUserData(data);
        generateWidgetCode(data);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  useEffect(() => {
    getProfileDataFunction();
  }, []);

  const generateAPIKeyFunction = (values) => {
    GenerateAPIKey()
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.data) {
          const data = res.data.data.data;
          generateRefreshedAccessTokenFunc({
            apiKey: data.api_key,
            secretKey: data.secret_key,
            expirationDays: values.expirationDays
          });
        }
        getProfileDataFunction();
        notifySuccess('API Key is generated');
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const generateRefreshedAccessTokenFunc = (values) => {
    const headers = { 'api-key': values.apiKey, 'secret-key': values.secretKey };
    const data = { expirationDays: values.expirationDays };
    GenerateRefreshedAccessToken(data, headers)
      .then((res) => {
        const data = res && res.data && res.data.data;
        setWidgetToken(data.accessToken);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const generateWidgetCode = (userData) => {
    GetExpiredDateAPI(
      `?api_key=${userData.api_key || ''}&secret_key=${userData.secret_key}`
    )
      .then((res) => {
        const result = res?.data?.data?.accessToken;
        setWidgetToken(result);
        getWidgetImagesFunc(result);
      })
      .catch(() => {});
  };

  const getWidgetImagesFunc = (widgetToken) => {
    if (widgetToken) {
      GetWidgetImages(widgetToken)
        .then((res) => {
          const result = res.data.data.data;
          if (result.backgroundImage) {
            setImageName(result.backgroundImage);
          }

          if (result.backgroundSize) {
            setBackgroundSize(result.backgroundSize);
          }

          if (result.opacity) {
            setOpacity(result.opacity);
          }

          if (result.backgroundRepeat) {
            setBackgroundRepeat(result.backgroundRepeat);
          }

          if (result.funModeImages) {
            setImageFiles(result.funModeImages);
          }

          if (result.funModeConfirmScreenGifs) {
            setConfirmScreenGifFiles(result.funModeConfirmScreenGifs);
          }

          if (result.funModeLoadingGifs) {
            setLoadingGifFiles(result.funModeLoadingGifs);
          }

          if (result.funModeSwapCompletedGifs) {
            setSwapCompletedGifFiles(result.funModeSwapCompletedGifs);
          }

          if (result.funModeSwapFailedGifs) {
            setSwapFailedGifFiles(result.funModeSwapFailedGifs);
          }

          if (result.funModeSwapLoadingGifs) {
            setSwapLoadingGifFiles(result.funModeSwapLoadingGifs);
          }

          if (result.defaultCrypto) {
            setDefaultCoin(result.defaultCrypto);
          }

          if (result.defaultFiat) {
            setDefaultFiat(result.defaultFiat);
          }

          if (result.fromToken) {
            setFromCoin(result.fromToken);
          }
          if (result.fromNetwork) {
            setFromNetwork(result.fromNetwork);
          }

          if (result.toToken) {
            setToCoin(result.toToken);
          }

          if (result.toNetwork) {
            setToNetwork(result.toNetwork);
          }

          if (result.primaryColor) {
            setPrimaryColor('#' + result.primaryColor);
          }

          if (result.secondaryColor) {
            setSecondaryColor('#' + result.secondaryColor);
          }

          if (result.containerColor) {
            setContainerColor('#' + result.containerColor);
          }
          if (result.primaryText) {
            setPrimaryText('#' + result.primaryText);
          }
          if (result.secondaryText) {
            setSecondaryText('#' + result.secondaryText);
          }
          if (result.cardColor) {
            setCardColor('#' + result.cardColor);
          }

          if (result.showBG) {
            setBgImage(result.showBG);
          }
          if (result.borderRadius) {
            setBorderRadius(Number(result.borderRadius));
          }

          if (result.widgetRadius) {
            setWidgetRadius(Number(result.widgetRadius));
          }

          if (result.showMemes) {
            setFunMode(result.showMemes);
          }

          if (result.checkedCoins) {
            setCheckedCoins(result.checkedCoins);
            let coinsStr = '';
            for (const coin of checkedCoins) {
              coinsStr += coin + ',';
            }
            setOnlyCryptoStr(coinsStr);
          }
        })
        .catch((err) => {});
    }
  };
  const [allNetworks, setAllNetworks] = useState([]);

  const getAllNetworksFunc = () => {
    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));

        setAllNetworks(setFinalData);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    getAllNetworksFunc();
  }, []);

  const [activeTab, setActiveTab] = useState('');
  const [isUpdate, setIsUpdate] = useState(false);

  const iframeData = (
    <iframe
      src={`${process.env.REACT_APP_WIDGET_FRONTEND}/buy-sell/?accessToken=${
        widgetToken ? widgetToken : process.env.REACT_APP_WIDGET_ACCESS_TOKEN
      }&widgetTheme=${widgetTheme}${funMode === true ? '&funMode=true' : ''}${
        bgImage === true ? '&bgImage=true' : ''
      }${passView && passView.length > 0 ? '&view=' + passView : ''}${
        activeTab ? '&activeTab=' + activeTab : ''
      }&savedTheme=true${isUpdate ? '&isUpdate=' + isUpdate : '&isUpdate=' + isUpdate}`}
      style={{ borderRadius: '10px' }}
      width='450px'
      height='650px'
      title='1buy.io'
      // frameBorder='1'
    />
  );

  const commonProps = {
    isUpdate,
    setIsUpdate,
    activeTab,
    setActiveTab,

    userData,
    allNetworks,
    setUserData,
    generateAPIKeyFunction,
    createWidgetApp,
    generateRefreshedAccessTokenFunc,
    setOpenedSection,
    toggleSection,
    setWidgetApplicationDetail,
    openedSection,
    widgetApplicationDetail,
    defaultCoinSelect,
    setDefaultCoinSelect,
    defaultFiatSelect,
    setDefaultFiatSelect,
    allFiats,
    setAllFiats,
    allSwap,
    setAllSwap,
    allCoins,
    setAllCoins,
    sendCoin,
    setSendCoin,
    receiveCoin,
    setReceiveCoin,
    clonedEmail,
    setClonedEmail,
    clonedPassword,
    setClonedPassword,

    imageName,
    setImageName,
    imageFiles,
    setImageFiles,
    loadingGifFiles,
    setLoadingGifFiles,
    confirmScreenGifFiles,
    setConfirmScreenGifFiles,
    swapLoadingGifFiles,
    setSwapLoadingGifFiles,
    swapFailedGifFiles,
    setSwapFailedGifFiles,
    swapCompletedGifFiles,
    setSwapCompletedGifFiles,

    widgetToken,
    setWidgetToken,
    widgetTheme,
    setWidgetTheme,
    funMode,
    setFunMode,
    bgImage,
    setBgImage,
    primaryColor,
    setPrimaryColor,
    secondaryColor,
    setSecondaryColor,

    containerColor,
    setContainerColor,
    primaryText,
    setPrimaryText,
    secondaryText,
    setSecondaryText,
    cardColor,
    setCardColor,
    onlyCryptoStr,
    setOnlyCryptoStr,
    amountPay,
    setAmountPay,
    fromTokenAmount,
    setFromTokenAmount,
    opacity,
    setOpacity,
    borderRadius,
    setBorderRadius,
    widgetRadius,
    setWidgetRadius,
    backgroundSize,
    setBackgroundSize,
    backgroundRepeat,
    setBackgroundRepeat,
    passBorderRadius,
    setPassBorderRadius,
    checkedCoins,
    setCheckedCoins,
    setFromCoin,
    setToCoin,
    setFromNetwork,
    fromNetwork,
    setToNetwork,
    toNetwork,
    defaultCoin,
    setDefaultCoin,
    defaultFiat,
    setDefaultFiat,

    passView,
    setPassView,

    widgetDetails,
    iframeData
  };

  return (
    <React.Fragment>
      {process.env.REACT_APP_ENVIRONMENT === 'development' && (
        <div className='d-flex justify-content-center'>
          <div className='on-boarding-page'>
            <Row>
              <Col lg={9} md={8} sm={12}>
                <div className='font-size-24 font-weight-bold text-center my-4'>
                  Complete Your <u>1buy.io</u> Widget Onboarding
                </div>
                <div className='py-2 exchange-card-border ml-2 mr-2 mt-2'>
                  <div
                    className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                    onClick={() => toggleSection(0)}>
                    <div className='d-flex'>
                      <img src={icons[0]} alt={steps[0].label} height={40} />
                      <span className='text-black font-weight-bold font-size-14 ml-2'>
                        <p>{steps[0].label} </p>

                        <p className='font-weight-normal font-size-12 text-grey'>
                          {steps[0].description}
                        </p>
                      </span>
                    </div>
                    <i
                      onClick={() => toggleSection(0)}
                      className={`font-size-22 mdi mdi-chevron-${
                        openedSection === 0 ? 'up' : 'down'
                      }`}
                    />
                  </div>
                  <Collapse isOpen={openedSection === 0}>
                    <hr className='ml-3 mr-3' />

                    <CompanySocials
                      widgetApplicationDetail={widgetApplicationDetail}
                      createWidgetApp={createWidgetApp}
                      setWidgetApplicationDetail={setWidgetApplicationDetail}
                      setOpenedSection={setOpenedSection}
                    />
                  </Collapse>
                </div>

                <div className='py-2 exchange-card-border ml-2 mr-2 mt-2'>
                  <div
                    className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                    onClick={() => toggleSection(1)}>
                    <div className='d-flex'>
                      <img src={icons[1]} alt={steps[1].label} height={40} />
                      <span className='text-black font-weight-bold font-size-14 ml-2'>
                        <p>{steps[1].label} </p>

                        <p className='font-weight-normal font-size-12 text-grey'>
                          {steps[1].description}
                        </p>
                      </span>
                    </div>
                    <i
                      onClick={() => toggleSection(1)}
                      className={`font-size-22 mdi mdi-chevron-${
                        openedSection === 1 ? 'up' : 'down'
                      }`}
                    />
                  </div>
                  <Collapse isOpen={openedSection === 1}>
                    <hr className='ml-3 mr-3' />

                    <CreateAPIKey
                      userData={userData}
                      setUserData={setUserData}
                      generateAPIKeyFunction={generateAPIKeyFunction}
                      widgetToken={widgetToken}
                      generateRefreshedAccessTokenFunc={generateRefreshedAccessTokenFunc}
                    />
                  </Collapse>
                </div>

                <div
                  className='py-2 exchange-card-border ml-2 mr-2 mt-2'
                  style={{
                    background: widgetToken ? '' : '#D3D3D3'
                  }}>
                  <div
                    className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                    onClick={() => {
                      if (widgetToken) {
                        toggleSection(2);
                      }
                    }}>
                    <div className='d-flex'>
                      <img src={icons[2]} alt={steps[2].label} height={40} />
                      <span className='text-black font-weight-bold font-size-14 ml-2'>
                        <p>{steps[2].label} </p>

                        <p className='font-weight-normal font-size-12 text-grey'>
                          {steps[2].description}
                        </p>
                      </span>
                    </div>

                    {widgetToken ? (
                      <i
                        onClick={() => toggleSection(2)}
                        className={`font-size-22 mdi mdi-chevron-${
                          openedSection === 2 ? 'up' : 'down'
                        }`}
                      />
                    ) : (
                      <i className={`font-size-22 mdi mdi-lock `} />
                    )}
                  </div>
                  <Collapse isOpen={openedSection === 2}>
                    <hr className='ml-3 mr-3' />

                    <Customizations {...commonProps} />
                  </Collapse>
                </div>

                <div
                  className='py-2 exchange-card-border ml-2 mr-2 mt-2'
                  style={{
                    background: widgetToken ? '' : '#D3D3D3'
                  }}>
                  <div
                    className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                    onClick={() => {
                      if (widgetToken) {
                        toggleSection(3);
                      }
                    }}>
                    <div className='d-flex'>
                      <img src={icons[3]} alt={steps[3].label} height={40} />
                      <span className='text-black font-weight-bold font-size-14 ml-2'>
                        <p>{steps[3].label} </p>

                        <p className='font-weight-normal font-size-12 text-grey'>
                          {steps[3].description}
                        </p>
                      </span>
                    </div>

                    {widgetToken ? (
                      <i
                        onClick={() => toggleSection(3)}
                        className={`font-size-22 mdi mdi-chevron-${
                          openedSection === 3 ? 'up' : 'down'
                        }`}
                      />
                    ) : (
                      <i className={`font-size-22 mdi mdi-lock `} />
                    )}
                  </div>
                  <Collapse isOpen={openedSection === 3}>
                    <hr className='ml-3 mr-3' />

                    <EmbeddedWidget widgetDetails={widgetDetails} />
                  </Collapse>
                </div>

                <div
                  className='py-2 exchange-card-border ml-2 mr-2 mt-2'
                  style={{
                    background: widgetToken ? '' : '#D3D3D3'
                  }}>
                  <div
                    className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                    onClick={() => {
                      if (widgetToken) {
                        toggleSection(4);
                      }
                    }}>
                    <div className='d-flex'>
                      <img src={icons[4]} alt={steps[4].label} height={40} />
                      <span className='text-black font-weight-bold font-size-14 ml-2'>
                        <p>{steps[4].label} </p>

                        <p className='font-weight-normal font-size-12 text-grey'>
                          {steps[4].description}
                        </p>
                      </span>
                    </div>

                    {widgetToken ? (
                      <i
                        onClick={() => toggleSection(4)}
                        className={`font-size-22 mdi mdi-chevron-${
                          openedSection === 4 ? 'up' : 'down'
                        }`}
                      />
                    ) : (
                      <i className={`font-size-22 mdi mdi-lock `} />
                    )}
                  </div>
                  <Collapse isOpen={openedSection === 4}>
                    <hr className='ml-3 mr-3' />
                    <ProductionLaunch
                      allNetworks={allNetworks}
                      widgetApplicationDetail={widgetApplicationDetail}
                      createWidgetApp={createWidgetApp}
                      setOpenedSection={setOpenedSection}
                    />{' '}
                  </Collapse>
                </div>

                <div
                  className='py-2 exchange-card-border ml-2 mr-2 mt-2'
                  style={{
                    background:
                      widgetApplicationDetail &&
                      Object.keys(widgetApplicationDetail).length > 0 &&
                      widgetToken
                        ? ''
                        : '#D3D3D3'
                  }}>
                  <div
                    className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                    onClick={() => {
                      if (
                        widgetApplicationDetail &&
                        Object.keys(widgetApplicationDetail).length > 0 &&
                        widgetToken
                      ) {
                        toggleSection(5);
                      }
                    }}>
                    <div className='d-flex'>
                      <img src={icons[5]} alt={steps[5].label} height={40} />
                      <span className='text-black font-weight-bold font-size-14 ml-2'>
                        <p>{steps[5].label} </p>

                        <p className='font-weight-normal font-size-12 text-grey'>
                          {steps[5].description}
                        </p>
                      </span>
                    </div>
                    <div>
                      {widgetApplicationDetail &&
                      Object.keys(widgetApplicationDetail).length > 0 &&
                      widgetToken ? (
                        <i
                          onClick={() => toggleSection(5)}
                          className={`font-size-22 mdi mdi-chevron-${
                            openedSection === 5 ? 'up' : 'down'
                          }`}
                        />
                      ) : (
                        <i className={`font-size-22 mdi mdi-lock `} />
                      )}
                    </div>
                  </div>
                  <Collapse isOpen={openedSection === 5}>
                    <hr className='ml-3 mr-3' />
                    <CloneToProduction
                      clonedEmail={clonedEmail}
                      setClonedEmail={setClonedEmail}
                      clonedPassword={clonedPassword}
                      setClonedPassword={setClonedPassword}
                    />
                  </Collapse>
                </div>

                <div
                  className='py-2 exchange-card-border ml-2 mr-2 mt-2'
                  style={{
                    background:
                      widgetApplicationDetail &&
                      Object.keys(widgetApplicationDetail).length > 0 &&
                      widgetToken
                        ? ''
                        : '#D3D3D3'
                  }}>
                  <div
                    className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                    onClick={() => {
                      if (
                        widgetApplicationDetail &&
                        Object.keys(widgetApplicationDetail).length > 0 &&
                        widgetToken
                      ) {
                        toggleSection(6);
                      }
                    }}>
                    <div className='d-flex'>
                      <img src={icons[6]} alt={steps[6].label} height={40} />
                      <span className='text-black font-weight-bold font-size-14 ml-2'>
                        <p>{steps[6].label} </p>

                        <p className='font-weight-normal font-size-12 text-grey'>
                          {steps[6].description}
                        </p>
                      </span>
                    </div>
                    {widgetApplicationDetail &&
                    Object.keys(widgetApplicationDetail).length > 0 &&
                    widgetToken ? (
                      <i
                        onClick={() => toggleSection(6)}
                        className={`font-size-22 mdi mdi-chevron-${
                          openedSection === 6 ? 'up' : 'down'
                        }`}
                      />
                    ) : (
                      <i className={`font-size-22 mdi mdi-lock `} />
                    )}
                  </div>
                  <Collapse isOpen={openedSection === 6}>
                    <hr className='ml-3 mr-3' />
                    <BookDemo widgetApplicationDetail={widgetApplicationDetail} />
                  </Collapse>
                </div>

                <div
                  className='py-2 exchange-card-border ml-2 mr-2 mt-2'
                  style={{
                    background:
                      widgetApplicationDetail &&
                      Object.keys(widgetApplicationDetail).length > 0 &&
                      widgetToken
                        ? ''
                        : '#D3D3D3'
                  }}>
                  <div
                    className='cursor-pointer col-12 font-size-14 ml-2 d-flex justify-content-between align-items-center'
                    onClick={() => {
                      if (
                        widgetApplicationDetail &&
                        Object.keys(widgetApplicationDetail).length > 0 &&
                        widgetToken
                      ) {
                        toggleSection(7);
                      }
                    }}>
                    <div className='d-flex'>
                      <img src={icons[7]} alt={steps[7].label} height={40} />
                      <span className='text-black font-weight-bold font-size-14 ml-2'>
                        <p>{steps[7].label} </p>

                        <p className='font-weight-normal font-size-12 text-grey'>
                          {steps[7].description}
                        </p>
                      </span>
                    </div>
                    {widgetApplicationDetail &&
                    Object.keys(widgetApplicationDetail).length > 0 &&
                    widgetToken ? (
                      <i
                        onClick={() => toggleSection(7)}
                        className={`font-size-22 mdi mdi-chevron-${
                          openedSection === 7 ? 'up' : 'down'
                        }`}
                      />
                    ) : (
                      <i className={`font-size-22 mdi mdi-lock `} />
                    )}
                  </div>
                  <Collapse isOpen={openedSection === 7}>
                    <hr className='ml-3 mr-3' />
                    <GoProduction />
                  </Collapse>
                </div>
              </Col>
              <Col lg={3} md={4} sm={12} className='on-boarding-step'>
                <Card>
                  <CardBody>
                    <div>
                      <StyledEngineProvider injectFirst>
                        <Stepper activeStep={openedSection} orientation='vertical'>
                          {steps.map((step, index) => (
                            <Step key={step.label}>
                              <StepLabel>
                                <span style={{ fontWeight: 600 }}>{step.label}</span>
                              </StepLabel>
                              <StepContent>
                                <Typography>
                                  <p className='font-weight-normal font-size-12 text-grey'>
                                    {step.description}
                                  </p>
                                </Typography>
                              </StepContent>
                            </Step>
                          ))}
                        </Stepper>
                      </StyledEngineProvider>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>
        </div>
      )}

      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default WidgetOnboardingSteps;
