import React from 'react';
import SimpleBar from 'simplebar-react';
import { SizeMe } from 'react-sizeme';
import get from 'lodash/get';

function Info({ network = {}, className = '' }) {
  const description = get(network, 'coin_gecko_details.en');
  return (
    <div className={'p-4 card ' + className}>
      <div>
        <div className="font-size-24 font-weight-medium text-uppercase text-dark mb-4">
          {network.network_name}
        </div>
        {network.logo && (
          <div className="pb-4">
            <img
              src={network.logo}
              alt={network.network_name}
              height="120"
              className="rounded-circle"
            />
          </div>
        )}
      </div>
      <SizeMe monitorHeight>
        {({ size }) => (
          <div className="flex-grow-1 position-relative text-muted font-size-20 font-weight-medium pt-3">
            {description && (
              <SimpleBar
                className="position-absolute position-cover"
                style={{
                  maxHeight: size.height
                }}>
                {description}
              </SimpleBar>
            )}
          </div>
        )}
      </SizeMe>
    </div>
  );
}

export default Info;
