import { REGION, S3_BUCKET } from './AWSConstants';
import AWS from 'aws-sdk';

export const uploadFiles = ({ filesArr, setState, selectedGiphy = [] }) => {
  const myBucket = new AWS.S3({
    params: { Bucket: S3_BUCKET },
    region: REGION
  });

  let promises = [];
  let imagesArr = [];

  filesArr.forEach((file) => {
    if (typeof file === 'string') {
      imagesArr.push(file);
    } else {
      let randomNumber = Math.floor(Math.random() * 100000 + 1);
      let currentDate = Date.now();
      let randomString = `${randomNumber}-${currentDate}`;
      const fileName = `${randomString}.${
        file.name.split('.')[file.name.split('.').length - 1]
      }`;
      const params = {
        Body: file,
        Bucket: S3_BUCKET,
        Key: fileName
      };

      promises.push(
        new Promise((resolve, reject) => {
          myBucket
            .putObject(params)
            .on('httpUploadProgress', (evt) => {})
            .send((err, data) => {
              if (err) {
                console.error(err);
                reject(err);
              } else {
                const uploadedUrl = `https://${S3_BUCKET}.s3.amazonaws.com/${fileName}`;
                imagesArr.push(uploadedUrl);
                resolve();
              }
            });
        })
      );
    }
  });

  Promise.all(promises)
    .then(() => {
      let funModeStr = [];
      for (const img of imagesArr) {
        funModeStr.push(img);
      }
      setState([...funModeStr, ...selectedGiphy]);
    })
    .catch((error) => {
      console.error('Error uploading files:', error);
    });
};
