import { Form, Formik } from 'formik';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Button, Modal, Label, FormGroup, Input, ModalHeader } from 'reactstrap';
import * as Yup from 'yup';
import { constants as CONST } from '../../../constants/application';
import AuthInput from '../../../Custom/AuthInput';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import { EditUser, UpdateApplication } from '../../../services/Business/Application';
import get from 'lodash';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError, notifySuccess } from '../../../Custom/notification';
import { useQueryParam, StringParam } from 'use-query-params';

function BeneficiaryUserModal({
  user,
  beneficialUsersData,
  responseId,
  completedBeneficialUsersData,
  modal,
  handleOpenModal,
  applicationId: application_Id,
  getApplicationFunction
}) {
  const [selectedFile, setSelectedFile] = useState();
  const [initialValue, setInitialValue] = useState({
    full_name: user ? user.full_name : '',
    date_of_birth: user ? moment(user.date_of_birth).format('YYYY-MM-DD') : '',
    country: user ? user.country : '',
    ownership_percent: user ? user.ownership_percent : '',
    role: user ? user.role : '',
    profile: user ? user.id_doc : null
  });

  const [applicationId] = useQueryParam('sub_application_id', StringParam);
  useEffect(() => {
    if (user) {
      setInitialValue({
        full_name: user.full_name,
        date_of_birth: moment(user.date_of_birth).format('YYYY-MM-DD'),
        country: user.country,
        ownership_percent: user.ownership_percent,
        role: user.role,
        profile: user ? user.id_doc : null
      });
    }
  }, [user]);

  useEffect(() => {
    if (!modal) {
      setInitialValue({
        full_name: '',
        date_of_birth: '',
        country: '',
        ownership_percent: '',
        role: '',
        profile: ''
      });
      setSelectedFile();
    }
  }, [modal, user]);

  const beneficiaryUserSchema = Yup.object().shape({
    full_name: Yup.string()
      .min(3, 'Full Name must be at least 3 characters')
      .max(50, 'Full Name must be at most 50 characters')
      .matches(/^[^\s]+(\s+[^\s]+)*$/, 'Leading or trailing spaces are not allowed')
      .required('Full Name is required'),
    date_of_birth: Yup.date()
      .required('Date of Birth is required')
      .typeError('Invalid Date')
      .test('DOB', 'Age must be more than 18', (value) => {
        return moment().diff(moment(value), 'years') >= 18;
      }),
    country: Yup.string().required('Country is required'),
    ownership_percent: Yup.number()
      .max(50, 'Ownership percentage must be 50% or less')
      .required('Ownership percentage is required'),
    role: Yup.string().required('Role is required'),
    profile: Yup.mixed().required('Identification Docs is required')
  });

  beneficialUsersData?.forEach((user) => {
    const { full_name, date_of_birth, country, ownership_percent, role, profile } = user;
    const isFullnameExists = !!full_name;
    const isDateOfBirthExists = !!date_of_birth;
    const isCountryExists = !!country;
    const isOwnershipPercentExists = !!ownership_percent;
    const isRoleExists = !!role;
    const isProfileExists = !!profile;

    beneficiaryUserSchema
      .validate(user, {
        context: {
          isFullnameExists,
          isDateOfBirthExists,
          isCountryExists,
          isOwnershipPercentExists,
          isRoleExists,
          isProfileExists
        }
      })
      .then((validUser) => {
        console.log('Valid user:', validUser);
      })
      .catch((errors) => {
        console.log('Validation errors:', errors);
      });
  });

  const changeHandler = (event) => {
    if (event.target.files[0]) setSelectedFile(event.target.files[0]);
  };
  const handleSubmit = (values) => {
    const payloadData = new FormData();
    if (user) {
      payloadData.append(
        'full_name',
        values.full_name ? values.full_name : user.full_name
      );
      if (selectedFile !== undefined) {
        payloadData.append('user_id_doc', selectedFile);
      } else {
        payloadData.append('user_id_doc', user.id_doc);
      }
      payloadData.append(
        'date_of_birth',
        values.date_of_birth
          ? moment(values.date_of_birth).format('YYYY-MM-DD')
          : moment(user.date_of_birth).format('YYYY-MM-DD')
      );
      payloadData.append('country', values.country ? values.country : user.country);
      payloadData.append('role', values.role ? values.role : user.role);
      payloadData.append(
        'ownership_percent',
        values.ownership_percent ? values.ownership_percent : user.ownership_percent
      );
      const params = {
        sub_application_id: application_Id || applicationId,
        user_id: user?._id,
        onramp_response_id:
          responseId && responseId.onramp_response_id ? responseId.onramp_response_id : ''
      };

      EditUser(params, payloadData)
        .then((res) => {
          notifySuccess('User updated successfully');
          setInitialValue({
            full_name: '',
            date_of_birth: '',
            country: '',
            ownership_percent: '',
            role: '',
            profile: null
          });
          handleOpenModal();
          getApplicationFunction(application_Id);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    } else {
      if (selectedFile !== undefined) {
        payloadData.append('user_id_doc', selectedFile);
      }
      payloadData.append('full_name', values.full_name);
      payloadData.append('date_of_birth', values.date_of_birth);
      payloadData.append('country', values.country);
      payloadData.append('role', values.role);
      payloadData.append('ownership_percent', values.ownership_percent);
      console.log('applicationId', application_Id, applicationId);
      notifySuccess('User added successfully');
      setInitialValue({
        full_name: '',
        date_of_birth: '',
        country: '',
        ownership_percent: '',
        role: '',
        profile: null
      });
      UpdateApplication(application_Id || applicationId, payloadData)
        .then((res) => {
          handleOpenModal();
          getApplicationFunction(application_Id);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  const renderFormGroup = ({
    property,
    label,
    type,
    placeholder,
    values,
    handleChange,
    errors,
    touched
  }) => {
    if (
      completedBeneficialUsersData &&
      !beneficialUsersData.some(
        (obj) => obj.hasOwnProperty(property) && obj.id === user?._id
      )
    ) {
      return null;
    }
    return (
      <FormGroup className='mb-2 mt-2'>
        <Label className={'font-size-12'}>{label}</Label>
        <div>
          <AuthInput
            className={'font-size-12'}
            type={type}
            name={property}
            placeholder={placeholder}
            values={values}
            handleChange={handleChange}
            errors={errors}
            touched={touched}
          />
        </div>
      </FormGroup>
    );
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={() => {
          handleOpenModal();
          setInitialValue({
            full_name: '',
            date_of_birth: '',
            country: '',
            ownership_percent: '',
            role: '',
            profile: null
          });
        }}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        style={{ borderRadius: '8px', maxWidth: '581px' }}>
        <ModalHeader
          toggle={() => {
            handleOpenModal();
            setInitialValue({
              full_name: '',
              date_of_birth: '',
              country: '',
              ownership_percent: '',
              role: '',
              profile: null
            });
          }}>
          {user ? 'Edit' : 'Add'} Beneficiary User
        </ModalHeader>
        <div>
          <Formik
            enableReinitialize
            initialValues={initialValue}
            validationSchema={beneficiaryUserSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div className='pl-4 pr-4'>
                  {renderFormGroup({
                    property: 'full_name',
                    label: CONST.FULL_NAME,
                    type: 'text',
                    placeholder: CONST.ENTER_FULL_NAME,
                    values: values,
                    handleChange,
                    errors,
                    touched
                  })}
                  {renderFormGroup({
                    property: 'date_of_birth',
                    label: 'Date Of Birth',
                    type: 'date',
                    placeholder: CONST.ENTER_DOB,
                    values: values,
                    handleChange,
                    errors,
                    touched
                  })}
                  {renderFormGroup({
                    property: 'country',
                    label: 'Country',
                    type: 'text',
                    placeholder: CONST.ENTER_COUNTRY,
                    values: values,
                    handleChange,
                    errors,
                    touched
                  })}
                  {renderFormGroup({
                    property: 'ownership_percent',
                    label: '% Ownership',
                    type: 'number',
                    placeholder: CONST.ENTER_OWNERSHIP,
                    values: values,
                    handleChange,
                    errors,
                    touched
                  })}
                  {renderFormGroup({
                    property: 'role',
                    label: 'Role',
                    type: 'text',
                    placeholder: CONST.ENTER_ROLE,
                    values: values,
                    handleChange,
                    errors,
                    touched
                  })}
                  {beneficialUsersData ? (
                    beneficialUsersData.some(
                      (obj) => obj.hasOwnProperty('profile') && obj.id === user?._id
                    ) && (
                      <FormGroup className='mb-2 mt-2'>
                        <div>
                          <Label htmlFor='profile' id='id_docs'>
                            <span
                              className='p-1 mb-2 rounded-circle'
                              style={{
                                color: '#134a87',
                                fontSize: 14,
                                cursor: 'pointer'
                              }}>
                              <img
                                src={
                                  selectedFile || user.id_doc
                                    ? documentUploadedIcon
                                    : documentUploadIcon
                                }
                                alt='IntegrationFlows'></img>
                            </span>
                            Identification Docs
                          </Label>
                          <Input
                            type='file'
                            id='profile'
                            name='profile'
                            onChange={(event) => {
                              handleChange(event);
                              changeHandler(event);
                            }}
                            style={{ display: 'none' }}
                          />
                          {errors.profile && touched.profile && (
                            <div
                              style={{ fontSize: 12 }}
                              className='text-left mt-1 text-danger'>
                              {errors.profile}
                            </div>
                          )}
                          <div>
                            {user && user.id_doc && (
                              <a
                                href={user.id_doc}
                                target='_blank'
                                rel='noopener noreferrer'
                                className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                View File
                              </a>
                            )}
                          </div>
                          <ReactTooltip
                            anchorId='id_docs'
                            place='bottom'
                            content={selectedFile && selectedFile.name}
                          />
                        </div>
                      </FormGroup>
                    )
                  ) : (
                    <FormGroup className='mb-2 mt-3'>
                      <div>
                        <Label htmlFor='profile' id='id_docs'>
                          <span
                            className='pr-1 mb-2 rounded-circle'
                            style={{
                              color: '#134a87',
                              fontSize: 15,
                              cursor: 'pointer'
                            }}>
                            <img
                              src={
                                selectedFile || (user && user.id_doc)
                                  ? documentUploadedIcon
                                  : documentUploadIcon
                              }
                              alt='IntegrationFlows'></img>
                          </span>
                          <span className='font-size-12'>Identification Docs</span>
                        </Label>
                        <Input
                          type='file'
                          id='profile'
                          name='profile'
                          onChange={(event) => {
                            handleChange(event);
                            changeHandler(event);
                          }}
                          style={{ display: 'none' }}
                        />
                        {errors.profile && touched.profile && (
                          <div
                            style={{ fontSize: 14 }}
                            className='text-left mt-1 text-danger'>
                            {errors.profile}
                          </div>
                        )}
                        <div>
                          {user && user.id_doc && (
                            <a
                              href={user.id_doc}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                              View File
                            </a>
                          )}
                        </div>
                        <ReactTooltip
                          anchorId='id_docs'
                          place='bottom'
                          content={selectedFile && selectedFile.name}
                        />
                      </div>
                    </FormGroup>
                  )}
                  <div className='mt-3 mb-3 text-center d-flex justify-content-center'>
                    <Button
                      type='submit'
                      name='btn'
                      style={{ width: 150 }}
                      className={`btn btn-dark`}
                      onSubmit={(values) => handleSubmit(values)}>
                      {user ? 'Edit User' : 'Add User'}
                    </Button>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Modal>
    </div>
  );
}
export default BeneficiaryUserModal;
