import React, { useState } from 'react';
import get from 'lodash/get';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Slider from 'react-slick';
import { Tooltip } from 'reactstrap';

function SliderNextArrow(props) {
  const { style, onClick } = props;
  return (
    <div
      className={'px-1 position-absolute font-size-14 text-reset'}
      style={{ ...style, right: '-1.2rem', lineHeight: '1.5rem', top: 0 }}
      onClick={onClick}>
      <i className="ri-arrow-right-s-line" aria-hidden="true"></i>
    </div>
  );
}

function SliderPrevArrow(props) {
  const { style, onClick } = props;

  return (
    <div
      className={'px-1 position-absolute font-size-14 text-reset'}
      style={{ ...style, left: '-1.2rem', lineHeight: '1.5rem' }}
      onClick={onClick}>
      <i className="ri-arrow-left-s-line" aria-hidden="true"></i>
    </div>
  );
}

function SliderItem({ method, country, showTooltip = false }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const id = `slider-item-${country.iso}${method._id}`.toLowerCase();

  return (
    <div className="px-1 d-flex align-items-center" style={{ height: '24px' }} id={id}>
      <img
        src={method.logo}
        alt={method.paymentMethod}
        title={method.paymentMethod}
        width="24"
        style={{ maxHeight: '24px' }}
      />
      {showTooltip && (
        <Tooltip placement="top" target={id} isOpen={tooltipOpen} toggle={toggle}>
          <span className="font-weight-medium">{method.display_name}</span>
        </Tooltip>
      )}
    </div>
  );
}

function CountryGridCardRow({ provider, country, showTooltip, className = '' }) {
  const settings = {
    arrows: true,
    dots: false,
    slidesToShow: 4,
    slidesToScroll: 4,
    speed: 300,
    nextArrow: <SliderNextArrow />,
    prevArrow: <SliderPrevArrow />
  };

  return (
    <>
      <div className={`d-flex align-items-center ${className}`}>
        <div className="pr-2">
          <img
            src={provider.logo}
            alt={provider.provider_name}
            width={32}
            style={{ maxHeight: '32px' }}
          />
        </div>
        <div className="pr-3 flex-fill font-size-16 text-truncate">
          {provider.provider_name}
        </div>

        {get(provider, 'supportedPaymentMethods.length', 0) > 4 ? (
          <div
            className="flex-shrink-0 pr-3 mr-1"
            style={{ width: '140px', transform: 'translateY(0.25rem)' }}>
            <Slider {...settings} className="">
              {get(provider, 'supportedPaymentMethods', []).map((method, i) => (
                <div key={i}>
                  <SliderItem
                    method={method}
                    country={country}
                    showTooltip={showTooltip}
                  />
                </div>
              ))}
            </Slider>
          </div>
        ) : (
          <div className="d-flex align-items-center">
            {get(provider, 'supportedPaymentMethods', []).map((method, i) => (
              <div key={i}>
                <SliderItem method={method} country={country} showTooltip={showTooltip} />
              </div>
            ))}
          </div>
        )}
      </div>
    </>
  );
}

export default CountryGridCardRow;
