import React, { useState } from 'react';
import ResponseList from './ResponsePage';
import ApplicationNotifications from './NotificationPage';
import { Col, Nav, NavItem, Row, NavLink, Card, TabContent, TabPane } from 'reactstrap';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';
import { useHistory } from 'react-router-dom';
import ViewExchangeApplication from './ViewExchangeApplication';
import { get } from 'lodash';
import { GetApplicationResponse } from '../../../services/Business/Application';
import { notifyError } from '../../../Custom/notification';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { StringParam, useQueryParam } from 'use-query-params';
import StreamChatElem from './StreamChat';

function ViewExchnagesApplication(props) {
  const [page, setPage] = useState('response');
  const history = useHistory();
  const [first, setFirst] = useState(0);
  const [allResponses, setAllResponses] = useState([]);
  const [applicationDetails, setApplicationDetails] = useState({});
  const [applicationAll, setApplicationAll] = useState({});
  const [responsePage, setResponsePage] = useState(1);
  const [order, setOrder] = useState(null);
  const [applicationIdData] = useQueryParam('application_id', StringParam);

  const [applicationId, setApplicationId] = useState(applicationIdData || null);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [statusSuggestions, setStatusSuggestions] = useState([
    {
      id: 'REQUEST_ACTION',
      name: 'Action',
      icon: 'fas fa-unlock',
      color: 'black',
      checked: false
    },
    {
      id: 'ACCEPT',
      name: 'Accept',
      icon: 'fas fa-check-circle',
      color: 'green',
      checked: false
    },
    { id: 'KYB', name: 'KYB', icon: 'fas fa-lock', color: 'black', checked: false },
    {
      id: 'QUOTE',
      name: 'Quote',
      icon: 'fas fa-quote-left',
      color: 'black',
      checked: false
    },
    {
      id: 'PAID',
      name: 'Paid',
      icon: 'fas fa-thumbs-up',
      color: 'green',
      checked: false
    },
    {
      id: 'ADD_QUESTIONS',
      name: 'Add Question',
      icon: 'fas fa-question',
      color: 'black',
      checked: false
    }
  ]);

  const GetApplicationResponseFunc = (applicationId) => {
    const status = statusSuggestions
      .filter((suggestion) => suggestion.checked)
      .map((suggestion) => suggestion.id);
    setLoading(true);
    GetApplicationResponse({ applicationId, order, status })
      .then((res) => {
        setAllResponses(get(res, 'data.data.data', []));
        const slicedData = get(res, 'data.data.data', []).slice(first, first + 10);
        const count = get(res, 'data.data.count', 0);
        setLoading(false);
        setCurrentPageData(slicedData);
        setTotalCount(count);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  return (
    <React.Fragment>
      <div className='page-content'>
        <Row className='px-2'>
          <Col lg={12} md={12} sm={12}>
            <Card>
              <div className='pt-3 pl-3'>
                <button
                  type='button'
                  className='btn btn-light p-1 mb-2'
                  style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
                  onClick={() => history.push('/applications')}>
                  <ArrowLeftIcon width={18} height={18} />
                </button>
              </div>
              <Nav tabs className='nav-tabs-custom nav-justified'>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'application' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('application');
                    }}>
                    <span className='d-none d-sm-block'>Application Page</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'response' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('response');
                    }}>
                    <span className='d-none d-sm-block'>Responses</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${
                        page === 'notification' ? '2px solid #181B30' : ''
                      }`
                    }}
                    onClick={() => {
                      setPage('notification');
                    }}>
                    <span className='d-none d-sm-block'>Notifications</span>
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{
                      cursor: 'pointer',
                      borderBottom: `${page === 'chat' ? '2px solid #181B30' : ''}`
                    }}
                    onClick={() => {
                      setPage('chat');
                    }}>
                    <span className='d-none d-sm-block'>Chat</span>
                  </NavLink>
                </NavItem>
              </Nav>
              <TabContent activeTab={page}>
                <TabPane tabId='application'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <ViewExchangeApplication
                        props={props}
                        allResponses={allResponses}
                        setPage={setPage}
                        GetApplicationResponseFunc={GetApplicationResponseFunc}
                        setApplicationDetails={setApplicationDetails}
                        applicationDetails={applicationDetails}
                        setApplicationAll={setApplicationAll}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='response'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <ResponseList
                        page={responsePage}
                        allResponses={allResponses}
                        setOrder={setOrder}
                        totalCount={totalCount}
                        first={first}
                        order={order}
                        loading={loading}
                        setPage={setResponsePage}
                        setResponsePage={setResponsePage}
                        setFirst={setFirst}
                        currentPageData={currentPageData}
                        setApplicationId={setApplicationId}
                        setStatusSuggestions={setStatusSuggestions}
                        GetApplicationResponseFunc={GetApplicationResponseFunc}
                        setCurrentPageData={setCurrentPageData}
                        statusSuggestions={statusSuggestions}
                      />
                    </Col>
                  </Row>
                </TabPane>
                <TabPane tabId='notification'>
                  <Row>
                    <ApplicationNotifications props={props} page={page} />
                  </Row>
                </TabPane>
                <TabPane tabId='chat'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <StreamChatElem
                        props={props}
                        page={page}
                        applicationAll={applicationAll}
                      />
                    </Col>
                  </Row>
                </TabPane>
              </TabContent>
            </Card>
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default ViewExchnagesApplication;
