import React, { useEffect, useState } from 'react';
import { Form, Formik } from 'formik';
import { Col, FormGroup, Input, Label, Row } from 'reactstrap';
import * as Yup from 'yup';
import {
  AddWidgetReferralDetailAPI,
  GetWidgetReferralDetailAPI,
  GetWidgetReferredUsersAPI
} from '../../services/Auth';
import { notifyError, notifySuccess } from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { Paginator } from 'primereact/paginator';
import { get } from 'lodash';
import moment from 'moment';

function ReferralSettings() {
  const [referralDetails, setReferralDetails] = useState(null);
  const [referredUsers, setReferredUsers] = useState([]);
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [currentPageData, setCurrentPageData] = useState([]);

  const validationSchema = Yup.object().shape({
    referral_code: Yup.string()
      .required('Referral Code is required')
      .matches(/^[^-]*$/, 'Referral Code cannot contain "-"')
      .matches(/^[^?]*$/, 'Referral Code cannot contain "?"')
      .matches(/^[^&]*$/, 'Referral Code cannot contain "&"')
      .matches(/^[^\s]+$/, 'Referral Code must not contain white spaces')
  });

  const handleReferralCode = (values) => {
    AddWidgetReferralDetailAPI(values)
      .then((res) => {
        getWidgetReferrals();
        notifySuccess('Referral Code updated successfully');
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const getReferredUsersList = () => {
    GetWidgetReferredUsersAPI()
      .then((res) => {
        const slicedData = get(res, 'data.data.data', []).slice(first, first + 10);
        setCurrentPageData(slicedData);
        setReferredUsers(res.data.data.data);
        setTotalCount(res.data.data.count);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getReferredUsersList();
  }, []);

  const getWidgetReferrals = () => {
    GetWidgetReferralDetailAPI()
      .then((res) => {
        setReferralDetails(res.data.data.data);
      })
      .catch((err) => {});
  };

  useEffect(() => {
    getWidgetReferrals();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    const newPageData = referredUsers.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize
        initialValues={{
          referral_code:
            referralDetails && referralDetails.referral_code
              ? referralDetails?.referral_code
              : ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleReferralCode}>
        {({ values, handleChange, errors, touched }) => (
          <Form>
            <Row>
              <Col lg={6} className='ml-3 mt-2'>
                <FormGroup>
                  <Label htmlFor='sub_domain' className='text-black font-size-12'>
                    Referral Code
                  </Label>
                  <Col className='d-flex flex-row justify-content-center align-items-center pl-0'>
                    <Input
                      type='text'
                      placeholder={'e.g. hello_xyz'}
                      value={values.referral_code}
                      name='referral_code'
                      style={{ height: '36px' }}
                      className={
                        errors.referral_code && touched.referral_code
                          ? `font-size-12 border-danger`
                          : `font-size-12 border-dark`
                      }
                      onChange={handleChange}
                    />
                  </Col>
                  {errors.referral_code && touched.referral_code ? (
                    <div className='text-danger font-size-12'>{errors.referral_code}</div>
                  ) : null}
                </FormGroup>
              </Col>
            </Row>

            <div className='mb-3 ml-3'>
              <button
                type='submit'
                onSubmit={handleReferralCode}
                name='submit'
                className={`btn btn-auth`}
                style={{ width: 120, marginLeft: 0 }}>
                Save
              </button>
            </div>
          </Form>
        )}
      </Formik>
      {referralDetails && referralDetails.referral_code && (
        <Col lg={6}>
          <pre>
            <code>https://1buy.io/{referralDetails.referral_code}</code>
          </pre>
        </Col>
      )}
      <Col lg={6}>
        <div className='table-responsive'>
          <table className='table table-centered table-hover'>
            <thead className='center-text'>
              <tr className='font-size-14 text-black'>
                <th className='pl-4 pt-3 pb-4 border-0'>#</th>
                <th className='pt-3 pb-4 border-0'>Email</th>
                <th className='pt-3 pb-4 border-0'>Created At</th>
              </tr>
            </thead>
            <tbody>
              {currentPageData && currentPageData.length > 0 ? (
                currentPageData.map((user, index) => (
                  <tr key={index}>
                    <td className='pl-4'>{index + 1}</td>
                    <td className='pl-4 center-text'>{user.email}</td>
                    <td className='pl-4'>
                      {moment(user.created_at).format('DD MMM YYYY')}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>No referred users found</tr>
              )}
            </tbody>
          </table>
          <Paginator
            rows={10}
            totalRecords={totalCount}
            first={first}
            onPageChange={(e) => onPageChange(e)}
          />
        </div>
      </Col>
    </React.Fragment>
  );
}

export default ReferralSettings;
