import React, { useEffect, useState } from 'react';
import { useDebounce } from 'use-debounce';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import get from 'lodash/get';
import omit from 'lodash/omit';
import Drawer from 'react-modern-drawer';
import {
  GetAllFiats,
  GetAllCoins,
  GetPaymentOptions,
  GetPriceCompareBuy,
  GetPriceCompareSell
} from '../../services/Business/Crypto';
import Tabs from './Tabs';
import MoneyInput from './MoneyInput';
import MoneyInputGroup from './MoneyInputGroup';
import CoinButton from './CoinButton';
import FiatSelect from './FiatSelect';
import CoinSelect from './CoinSelect';
import PaymentOptionSelect from './PaymentOptionSelect';
import Rate from './Rate';
import RateSelect from './RateSelect';
import 'react-modern-drawer/dist/index.css';
import '../../assets/scss/custom/components/buy-sell.scss';
import { providersEnum as PROVIDER_ENUM } from '../../constants/providers';

function BuySell({
  isEmbeddable = false,
  defaultCoinId = 'bitcoin',
  getSelectedData,
  setShowMoonpayWidget,
  setShowAlchemyPayWidget,
  setShowXanpoolWidget,
  setShowTransakWidget,
  setShowGateFiWidget,
  setShowMunzenWidget,
  setShowSwipeluxWidget,
  setShowMercuryoWidget,
  setShowRampWidget,
  compareMode
}) {
  const [tab, setTab] = useState('buy');
  const [fiat, setFiat] = useState(null);
  const [coin, setCoin] = useState(null);
  const [amountPay, setAmountPay] = useState(1000);
  const [debouncedAmountPay] = useDebounce(amountPay, 1000);
  const [paymentOption, setPaymentOption] = useState({});
  const [selectedRate, setSelectedRate] = useState(null);
  const [allFiats, setAllFiats] = useState([]);
  const [allCoins, setAllCoins] = useState([]);
  const [allPaymentOptions, setAllPaymentOptions] = useState([]);
  const [allRates, setAllRates] = useState([]);
  const [showFiatSelect, setShowFiatSelect] = useState(false);
  const [showCoinSelect, setShowCoinSelect] = useState(false);
  const [showRateSelect, setShowRateSelect] = useState(false);
  const [error, setError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);

  const fetchCoins = (query) => {
    GetAllCoins({ query })
      .then((res) => {
        setLoading(false);
        const data = get(res, 'data.data.data', []);
        let defaultCoin = data.find(
          ({ coin_gecko_id, _id }) =>
            coin_gecko_id === defaultCoinId || _id === defaultCoinId
        );
        if (!defaultCoin) {
          defaultCoin = data[0];
        }
        setCoin(defaultCoin);
        setAllCoins(data);
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    setError(false);
    setLoading(true);
    setAllRates([]);
    setAllCoins([]);
    setAllPaymentOptions([]);
    setSelectedRate(null);
    (loaded
      ? Promise.resolve()
      : GetAllFiats().then((res) => {
          const data = get(res, 'data.data.data', []);
          setAllFiats(data);
          if (!fiat) setFiat(data.find(({ currency }) => currency === 'USD'));
          return data;
        })
    )
      .then(() => fetchCoins(tab))
      .then((res) => {
        setLoading(false);
        setLoaded(true);
        const data = get(res, 'data.data.data', []);
        if (!coin) setCoin(data.find(({ coin_name }) => coin_name === 'btc'));
        setAllCoins(data);
      })
      .catch((err) => {
        setLoading(false);
        setLoaded(true);
        setError("Can't load widget");
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, [tab]);

  useEffect(() => {
    if (!fiat) return;
    setLoading(true);
    setSelectedRate(null);
    GetPaymentOptions(fiat.currency)
      .then((res) => {
        setLoading(false);
        const data = get(res, 'data.data.data', []);

        setAllPaymentOptions(data);
        setPaymentOption({});
        return data;
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, [fiat && fiat.currency]);

  useEffect(() => {
    if (!paymentOption || !fiat || !coin || !debouncedAmountPay || !amountPay) return;
    setLoading(true);
    setSelectedRate(null);
    let paymentMethod = {};
    if (Object.keys(paymentOption).length <= 0) {
      // const filterMethods = allPaymentOptions.filter(
      //   (option) =>
      //     option.common_name !== 'credit_debit_card' &&
      //     option.common_name !== 'us_bank_transfer'
      // );
      paymentMethod = 'all';
      setPaymentOption(paymentMethod);
    }
    const options = {
      payment_method: get(
        Object.keys(paymentOption).length > 0 ? paymentOption : paymentMethod,
        'common_name',
        'all'
      ),
      currency: fiat.currency,
      coin_id: coin._id
    };
    const promise =
      tab === 'buy'
        ? GetPriceCompareBuy({
            ...options,
            currency_value: Number(debouncedAmountPay),
            compare_mode: compareMode
          })
        : GetPriceCompareSell({
            ...options,
            crypto_amount: Number(debouncedAmountPay)
          });
    promise
      .then((res) => {
        setLoading(false);
        let data = get(res, 'data.data.data', []);
        // filter out unrelevant results an sort rates
        data = (Array.isArray(data) ? data : [])
          .filter((provider) =>
            get(provider, 'priceOutput', []).some(
              (rate) => rate && typeof rate.value === 'number'
            )
          )
          .map((provider, i) => {
            const priceOutput = get(provider, 'priceOutput', [])
              .filter((rate) => rate && typeof rate.value === 'number')
              .sort((rate1, rate2) => (rate1.value < rate2.value ? 1 : -1));
            return {
              ...provider,
              bestValue: priceOutput.reduce((best, { value }) => {
                return Math.max(best, value);
              }, 0),
              priceOutput
            };
          })
          .sort((provider1, provider2) =>
            provider1.bestValue < provider2.bestValue ? 1 : -1
          );
        setAllRates(data);

        let bestRate = null;
        (Array.isArray(data) ? data : []).forEach((provider) => {
          get(provider, 'priceOutput', []).forEach((price) => {
            if (
              typeof price.value === 'number' &&
              (!bestRate || price.value > bestRate.value)
            ) {
              bestRate = {
                ...price,
                provider: omit(provider, 'priceOutput')
              };
            }
          });
        });
        setSelectedRate(bestRate);

        return data;
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, [paymentOption, fiat, coin, debouncedAmountPay, amountPay, tab]);

  let bestRateValue =
    tab === 'buy'
      ? debouncedAmountPay / get(selectedRate, 'value')
      : get(selectedRate, 'value') / debouncedAmountPay;
  let bestRateText =
    selectedRate &&
    fiat &&
    `1 ${get(coin, 'coin_name')} ≈
  ${new Intl.NumberFormat('en-US', {
    style: 'currency',
    //notation: 'compact',
    minimumSignificantDigits: bestRateValue < 1 ? 3 : undefined,
    maximumSignificantDigits: bestRateValue < 1 ? 6 : undefined,
    currency: get(fiat, 'currency')
  }).format(bestRateValue)}`;

  return (
    <>
      {error ? (
        <div className='text-center min-height-100vh d-flex align-items-center justify-content-center'>
          <div>
            <div className='text-danger h1 mb-1'>
              <i className='fas fa-exclamation-circle '></i>
            </div>
            <div className='h4 text-muted'>An error occured</div>
            <div className='text-danger'>{error}</div>
          </div>
        </div>
      ) : !loaded ? (
        <div className='text-center min-height-100vh d-flex align-items-center justify-content-center'>
          <i
            className='fas fa-circle-notch fa-spin text-muted'
            style={{ fontSize: 48 }}></i>
        </div>
      ) : (
        <div className='h-100 buy-sell overflow-hidden'>
          <Tabs
            className='mb-4'
            activeTab={tab}
            disabled={loading}
            onChange={(val) => {
              setAmountPay(0);
              setTab(val);
            }}
          />
          <MoneyInputGroup
            loading={loading}
            buyComponent={
              <MoneyInput
                value={amountPay}
                onChange={(v) => setAmountPay(v)}
                label={tab === 'buy' ? 'You Pay' : 'You Sell'}
                disabled={loading}
                placeholder='100'
                className='mb-0'>
                {tab === 'buy' ? (
                  <CoinButton
                    label={get(fiat, 'currency')}
                    symbol={get(fiat, 'symbol')}
                    disabled={loading}
                    onClick={() => setShowFiatSelect(true)}
                  />
                ) : (
                  <CoinButton
                    label={get(coin, 'coin_name')}
                    logo={get(coin, 'logo')}
                    disabled={loading}
                    onClick={() => setShowCoinSelect(true)}
                  />
                )}
              </MoneyInput>
            }
            sellComponent={
              <MoneyInput
                label='You Get'
                placeholder='...'
                value={get(selectedRate, 'value', '...')}
                className='mb-0'
                readonly={true}>
                {tab === 'buy' ? (
                  <CoinButton
                    label={get(coin, 'coin_name')}
                    logo={get(coin, 'logo')}
                    disabled={loading}
                    onClick={() => setShowCoinSelect(true)}
                  />
                ) : (
                  <CoinButton
                    label={get(fiat, 'currency')}
                    symbol={get(fiat, 'symbol')}
                    disabled={loading}
                    onClick={() => setShowFiatSelect(true)}
                  />
                )}
              </MoneyInput>
            }
          />
          <div className='mb-3'></div>
          {!loading && (
            <div className='font-size-14 text-center mb-2'>
              {get(selectedRate, 'value') ? (
                <span className='text-muted text-uppercase'>{bestRateText}</span>
              ) : (
                <span className='text-muted'>Not Compatible</span>
              )}
            </div>
          )}
          {get(allPaymentOptions, 'length', 0) > 0 && (
            <div className='d-flex flex-wrap align-items-center justify-content-center mb-3 border-width-2'>
              <div className='font-size-16 font-weight-bold mb-2 flex-grow-1'>
                Payment Method
              </div>
              <PaymentOptionSelect
                className='mb-2'
                value={paymentOption}
                paymentOptions={allPaymentOptions}
                disabled={loading}
                onChange={(v) => setPaymentOption(v)}
              />
            </div>
          )}

          <div className='mt-5'></div>

          {selectedRate && (
            <Rate
              className={loading ? 'opacity-60' : ''}
              value={selectedRate}
              text={bestRateText}
              onClick={() => !loading && setShowRateSelect(true)}
            />
          )}

          <div className='pt-3'>
            <button
              type='button'
              className={
                'btn btn-dark btn-block btn-lg text-capitalize ' +
                (loading ? 'disabled' : '')
              }
              onClick={() => {
                if (selectedRate.provider.provider === PROVIDER_ENUM.MOONPAY) {
                  setShowMoonpayWidget(true);
                }
                if (selectedRate.provider.provider === PROVIDER_ENUM.ALCHEMY) {
                  setShowAlchemyPayWidget(true);
                }
                if (selectedRate.provider.provider === PROVIDER_ENUM.XANPOOL) {
                  setShowXanpoolWidget(true);
                }
                if (selectedRate.provider.provider === PROVIDER_ENUM.TRANSAK) {
                  setShowTransakWidget(true);
                }
                if (selectedRate.provider.provider === PROVIDER_ENUM.UNLIMIT) {
                  setShowGateFiWidget(true);
                }
                if (selectedRate.provider.provider === PROVIDER_ENUM.MUNZEN) {
                  setShowMunzenWidget(true);
                }
                if (selectedRate.provider.provider === PROVIDER_ENUM.SWIPELUX) {
                  setShowSwipeluxWidget(true);
                }
                if (selectedRate.provider.provider === PROVIDER_ENUM.MERCURYO) {
                  setShowMercuryoWidget(true);
                }
                if (selectedRate.provider.provider === PROVIDER_ENUM.RAMP) {
                  setShowRampWidget(true);
                }
                getSelectedData({
                  fiat,
                  coin,
                  amountPay,
                  debouncedAmountPay,
                  selectedRate
                });
              }}
              disabled={loading || !selectedRate}>
              <div className='text-white font-size-18 my-2'>
                {loading ? (
                  <>
                    <i className='fas fa-circle-notch fa-spin '></i>
                  </>
                ) : (
                  <>
                    {tab === 'buy' ? 'Buy' : 'Sell'}{' '}
                    <span className='text-uppercase'>{get(coin, 'coin_name')}</span>
                  </>
                )}
              </div>
            </button>
          </div>

          <Drawer
            open={showFiatSelect || showCoinSelect || showRateSelect}
            onClose={() => {
              setShowCoinSelect(false);
              setShowFiatSelect(false);
              setShowRateSelect(false);
            }}
            size='80vh'
            overlayColor='#1D2E3F'
            overlayOpacity={0.2}
            direction='bottom'>
            {showFiatSelect && (
              <FiatSelect
                fiats={allFiats}
                onSelect={(v) => {
                  setFiat(v);
                  setShowFiatSelect(false);
                }}
                onClose={() => setShowFiatSelect(false)}
              />
            )}
            {showCoinSelect && (
              <CoinSelect
                coins={allCoins}
                onSelect={(v) => {
                  setCoin(v);
                  setShowCoinSelect(false);
                }}
                onClose={() => setShowCoinSelect(false)}
              />
            )}
            {showRateSelect && (
              <RateSelect
                tab={tab}
                fiat={fiat}
                coin={coin}
                value={selectedRate}
                allRates={allRates}
                onSelect={(v) => {
                  setSelectedRate(v);
                  setShowRateSelect(false);
                }}
                onClose={() => setShowRateSelect(false)}
              />
            )}
          </Drawer>
        </div>
      )}
      <NotificationToastContainer />
    </>
  );
}

export default BuySell;
