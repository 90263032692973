import React from 'react';

function Tabs({ activeTab = 'buy', className = '', disabled = false, onChange }) {
  const tabs = [
    { value: 'buy', label: 'Buy' },
    { value: 'sell', label: 'Sell' }
  ];
  return (
    <div
      className={
        'd-flex tabs ' + (disabled ? ' opacity-60 pointer-events-none ' : '') + className
      }>
      {tabs.map((tab) => (
        <div
          key={tab.value}
          className={
            'font-size-22 font-weight-medium py-2 px-4 ' +
            (tab.value === activeTab ? 'active' : '')
          }
          onClick={() => onChange(tab.value)}>
          {tab.label}
        </div>
      ))}
    </div>
  );
}

export default Tabs;
