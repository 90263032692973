import React, { useEffect, useState } from 'react';
import { Container, FormGroup, Label } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { useQueryParam, StringParam } from 'use-query-params';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import {
  GetAllNetwork,
  GetApplication,
  UpdateApplication,
  UploadApplicationDocs
} from '../../../services/Business/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../../Custom/notification';
import get from 'lodash/get';
import moment from 'moment';
import GeneralInfo from './ApplicationPages/GeneralInfo';
import BeneficiaryUser from './ApplicationPages/BeneficiaryUser';
import WalletFunds from './ApplicationPages/WalletFunds';
import PartnerEntity from './ApplicationPages/PartnerEntity';
import Certifications from './ApplicationPages/Certifications';

function ViewOnRampApplication(props) {
  const TickerSchema = Yup.object().shape({
    ticker_name: Yup.string().min(2, ERROR_CONST.NAME).max(5, ERROR_CONST.NAME)
  });

  const GeneralInfoSchema = Yup.object()
    .shape({
      legal_entity_name: Yup.string().required(ERROR_CONST.COMPANY_NAME),
      project_name: Yup.string()
        .required(ERROR_CONST.PROJECT_NAME)

        .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
      legal_entity_register_number: Yup.string()
        .required(ERROR_CONST.REGISTER_NUMBER)
        .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
      website: Yup.string().required(ERROR_CONST.WEBSITE),
      business_category: Yup.string().required(ERROR_CONST.BUSINESS_CATEGORY),
      country_of_incorporation: Yup.string().required(ERROR_CONST.COUNTRY),
      token_address: Yup.string(),
      product_description: Yup.string().required(ERROR_CONST.DESCRIPTION),
      ticker: Yup.string(),
      ticker_logo: Yup.string(),
      licence_doc: Yup.mixed().test(
        'licence_doc',
        'License document is required',
        function () {
          const { regulatory_auth } = this.parent;
          if (
            regulatory_auth === 'true' &&
            !optionalFile['licence_doc'] &&
            !fileUrls.licence_doc
          ) {
            return false; // Validation error
          }
          return true; // Validation success
        }
      ),
      AML_CTF_policy_doc: Yup.mixed().test(
        'AML_CTF_compliance',
        'AML / CTF Compliance is required',
        function () {
          const { AML_CTF_compliance } = this.parent;
          if (
            AML_CTF_compliance === 'true' &&
            !optionalFile['AML_CTF_policy_doc'] &&
            !fileUrls.AML_CTF_policy_doc
          ) {
            return false; // Validation error
          }
          return true; // Validation success
        }
      ),
      corporate_registration_doc: Yup.mixed().test(
        'Corporate Registration Documents is required',
        function () {
          if (
            !optionalFile['corporate_registration_doc'] &&
            !fileUrls.corporate_registration_doc
          ) {
            return false; // Validation error
          }
          return true; // Validation success
        }
      ),
      terms_of_service_doc: Yup.mixed().test(ERROR_CONST.TERMSOFSERVICEDOC, function () {
        if (!optionalFile['terms_of_service_doc'] && !fileUrls.terms_of_service_doc) {
          return false; // Validation error
        }
        return true; // Validation success
      }),
      privacy_policy_doc: Yup.mixed().test(ERROR_CONST.PRIVACYPOLICYDOC, function () {
        if (!optionalFile['privacy_policy_doc'] && !fileUrls.privacy_policy_doc) {
          return false;
        }
        return true;
      }),
      product_custodial: Yup.string().required('Field is required'),
      user_access_to_wallet: Yup.string().required('Field is required'),
      pre_populate_wallet_address: Yup.string().required('Field is required'),
      crypto_to_other_party: Yup.string().required('Field is required'),
      describe_ramp_services: Yup.string().required('Field is required'),
      regulatory_auth: Yup.string().required('Field is required'),
      AML_CTF_compliance: Yup.string().required('Field is required'),
      KYC_checks_on_users: Yup.string().required('Field is required'),
      users_screening: Yup.string().required('Field is required'),
      services_to_minors: Yup.string().required('Field is required'),
      consent_info: Yup.string().required('Field is required'),
      consent_OFAC: Yup.string().required('Field is required'),
      consent_region: Yup.string().required('Field is required'),
      consent_region_name: Yup.string('Field is required'),
      consent_age: Yup.string().required('Field is required'),
      applicant_email: Yup.string().required('Email is required'),
      applicant_role: Yup.string().required('Role is required'),
      applicant_full_name: Yup.string().required('Full Name is required'),
      application_date: Yup.string().required('Application Date is required')
    })
    .concat(TickerSchema);

  const [applicationId] = useQueryParam('application_id', StringParam);
  const [buyCheck, setBuyCheck] = useState(false);
  const [sellCheck, setSellCheck] = useState(false);
  const [initialValue, setInitialValue] = useState({
    legal_entity_name: '',
    project_name: '',
    legal_entity_register_number: '',
    website: '',
    business_category: '',
    country_of_incorporation: '',
    token_address: '',
    product_description: '',
    ticker: '',
    ticker_name: '',
    ticker_logo: '',
    network: '',
    other_network: '',
    product_custodial: '',
    user_access_to_wallet: '',
    pre_populate_wallet_address: '',
    crypto_to_other_party: '',
    describe_ramp_services: '',
    regulatory_auth: '',
    AML_CTF_compliance: '',
    KYC_checks_on_users: '',
    users_screening: '',
    services_to_minors: '',
    consent_info: '',
    consent_OFAC: '',
    consent_region: '',
    consent_region_name: '',
    consent_age: '',
    applicant_email: '',
    applicant_role: '',
    applicant_full_name: '',
    application_date: ''
  });
  const [optionalFile, setOptionalFile] = useState({ additional_docs: [] });
  const [fileUrls, setFileUrls] = useState({});
  const [userData, setUserData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [applicationStatus, setApplicationStatus] = useState('');
  const [allNetworks, setAllNetworks] = useState([]);
  const [otherNetwork, setOtherNetwork] = useState(false);
  const [selectedFields, setSelectedFields] = useState([]);

  const changeHandler = (event) => {
    if (event.target.files[0])
      if (event.target.name === 'additional_docs') {
        setOptionalFile({
          ...optionalFile,
          [event.target.name]: [...optionalFile['additional_docs'], event.target.files]
        });
      } else {
        setOptionalFile({ ...optionalFile, [event.target.name]: event.target.files[0] });
      }
  };

  if (!applicationId) {
    props.history.push(`/applications`);
  }

  const getApplicationFunction = () => {
    GetApplication({ application_id: applicationId })
      .then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setApplicationStatus(application.application_status);
        setInitialValue({
          legal_entity_name: application.legal_entity_name,
          ticker_logo: application.ticker_logo,
          project_name: application.project_name,
          legal_entity_register_number: application.legal_entity_register_number,
          website: application.website,
          business_category: application.business_category,
          country_of_incorporation: application.country_of_incorporation,
          token_address: application.token_address,
          network: application.network,
          other_network: application.other_network,
          product_description: application.product_description,
          ticker: application.ticker,
          ticker_name: application.ticker_name,
          product_custodial: application.product_custodial?.toString(),
          user_access_to_wallet:
            application.user_access_to_wallet !== null
              ? application.user_access_to_wallet?.toString()
              : 'null',
          pre_populate_wallet_address:
            application.pre_populate_wallet_address !== null
              ? application.pre_populate_wallet_address?.toString()
              : 'null',
          crypto_to_other_party:
            application.crypto_to_other_party !== null
              ? application.crypto_to_other_party?.toString()
              : 'null',
          describe_ramp_services: application.describe_ramp_services,
          regulatory_auth:
            application.sanctions_screening_of_users !== null
              ? application.regulatory_authorisation_requirement?.toString()
              : 'null',
          users_screening:
            application.sanctions_screening_of_users !== null
              ? application.sanctions_screening_of_users?.toString()
              : 'null',
          AML_CTF_compliance:
            application.AML_CTF_compliance !== null
              ? application.AML_CTF_compliance?.toString()
              : 'null',
          KYC_checks_on_users:
            application.KYC_checks_on_users !== null
              ? application.KYC_checks_on_users?.toString()
              : 'null',
          services_to_minors: application.services_to_minors?.toString(),
          consent_info: application.consent_info?.toString(),
          consent_OFAC:
            application.consent_OFAC !== null
              ? application.consent_OFAC?.toString()
              : 'null',
          consent_age:
            application.consent_age !== null
              ? application.consent_age?.toString()
              : 'null',
          consent_region: application.consent_region?.toString(),
          consent_region_name: application.consent_region_name,
          applicant_email: application.applicant_email,
          applicant_role: application.applicant_role,
          applicant_full_name: application.applicant_full_name,
          application_date: moment(application.application_date).format('YYYY-MM-DD')
        });
        if (application.network) {
          setSelectedFields({ label: application.network, value: application.network });
          if (application.network === 'Other') setOtherNetwork(true);
        }
        setBuyCheck(application.product_enable_buy);
        setSellCheck(application.product_enable_sell);
        setUserData(application.beneficial_ownership);
        setFileUrls({
          whitepapers_doc: application.whitepapers_doc,
          integration_flows_doc: application.integration_flows_doc,
          flow_of_funds_diagram_doc: application.flow_of_funds_diagram_doc,
          corporate_registration_doc: application.corporate_registration_doc,
          terms_of_service_doc: application.terms_of_service_doc,
          privacy_policy_doc: application.privacy_policy_doc,
          AML_CTF_policy_doc: application.AML_CTF_policy_doc,
          licence_doc: application.licence_doc,
          additional_docs: application.additional_docs,
          legal_opinion_doc: application.legal_opinion_doc,
          policy_procedures_doc: application.policy_procedures_doc,
          ticker_logo: application.ticker_logo
        });
        setIsLoaded(true);
      })
      .catch((error) => {
        setIsLoaded(true);
        const errorMessage = get(error, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    if (applicationId !== '') {
      getApplicationFunction();
    }

    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));

        setAllNetworks([...setFinalData, { label: 'Other', value: 'Other' }]);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, []);

  const handleChangeOpt = (selected) => {
    if (selected.value === 'Other') {
      setOtherNetwork(true);
      setSelectedFields(selected);
      return;
    }
    setOtherNetwork(false);
    setSelectedFields(selected);
  };

  const handleSubmit = (values) => {
    const data = {
      legal_entity_name: values.legal_entity_name.toLowerCase(),
      project_name: values.project_name,
      legal_entity_register_number: values.legal_entity_register_number,
      website: values.website,
      business_category: values.business_category,
      country_of_incorporation: values.country_of_incorporation,
      token_address: values.token_address,
      product_enable_buy: buyCheck,
      product_enable_sell: sellCheck,
      product_description: values.product_description,
      ticker: values.ticker ? values.ticker : 'Name',
      ticker_name: values.ticker_name ? values.ticker_name : 'TICKER',
      network: selectedFields ? selectedFields.label : '',
      other_network:
        selectedFields.label && selectedFields.label === 'Other'
          ? values.other_network
          : '',
      product_custodial: values.product_custodial,
      user_access_to_wallet:
        values.user_access_to_wallet !== 'null' ? values.user_access_to_wallet : null,
      pre_populate_wallet_address:
        values.pre_populate_wallet_address !== 'null'
          ? values.pre_populate_wallet_address
          : null,
      crypto_to_other_party:
        values.crypto_to_other_party !== 'null' ? values.crypto_to_other_party : null,
      describe_ramp_services:
        values.describe_ramp_services !== 'null' ? values.describe_ramp_services : null,
      regulatory_authorisation_requirement: values.regulatory_auth,
      AML_CTF_compliance:
        values.AML_CTF_compliance !== 'null' ? values.AML_CTF_compliance : null,
      KYC_checks_on_users:
        values.KYC_checks_on_users !== 'null' ? values.KYC_checks_on_users : null,
      sanctions_screening_of_users:
        values.users_screening !== 'null' ? values.users_screening : null,
      services_to_minors:
        values.services_to_minors !== 'null' ? values.services_to_minors : null,
      consent_info: values.consent_info,
      consent_OFAC: values.consent_OFAC !== 'null' ? values.consent_OFAC : null,
      consent_region: values.consent_region,
      consent_region_name: values.consent_region_name,
      consent_age: values.consent_age !== 'null' ? values.consent_age : null,
      applicant_email: values.applicant_email,
      applicant_role: values.applicant_role,
      applicant_full_name: values.applicant_full_name,
      application_date: values.application_date,
      application_status: 'COMPLETE'
    };
    const payloadData = new FormData();
    Object.keys(optionalFile).forEach((key) => {
      if (key === 'additional_docs' && optionalFile.additional_docs[0]) {
        let docs = Array.from(optionalFile.additional_docs);
        for (let i = 0; i < docs.length; i++) {
          payloadData.append('additional_docs', docs[i][0]);
        }
      } else {
        payloadData.append(key, optionalFile[key]);
      }
    });
    if (applicationId && applicationId !== '') {
      // if (payloadData.entries().next().done)
      UploadApplicationDocs({ application_id: applicationId }, payloadData)
        .then((res) => {
          // const application = get(res, 'data.data.data', '');
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
      UpdateApplication(applicationId, data)
        .then((res) => {
          //update success
          notifySuccess('Application updated Successfully');
          setInterval(() => {
            window.location.reload();
          }, 5000);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  return (
    <React.Fragment>
      {/* <div className="card" style={{ margin: '0px 15% 0px 15%' }}> */}
      <div className='p-4'>
        {!isLoaded ? (
          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
            <i
              className='fas fa-circle-notch fa-spin text-muted'
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <Container className=''>
            <div>
              <Formik
                enableReinitialize={true}
                initialValues={initialValue}
                validationSchema={GeneralInfoSchema}
                onSubmit={handleSubmit}>
                {({ errors, touched, values, setFieldValue, handleChange }) => (
                  <Form>
                    <CustomHeaders headerName='General Application' />
                    <GeneralInfo
                      CustomFieldInput={CustomFieldInput}
                      values={values}
                      errors={errors}
                      setFieldValue={setFieldValue}
                      touched={touched}
                      handleChange={handleChange}
                      setBuyCheck={setBuyCheck}
                      buyCheck={buyCheck}
                      setSellCheck={setSellCheck}
                      sellCheck={sellCheck}
                      handleChangeOpt={handleChangeOpt}
                      selectedFields={selectedFields}
                      otherNetwork={otherNetwork}
                      optionalFile={optionalFile}
                      fileUrls={fileUrls}
                      changeHandler={changeHandler}
                    />
                    <hr />
                    <BeneficiaryUser
                      userData={userData}
                      applicationId={applicationId}
                      getApplicationFunction={getApplicationFunction}
                    />
                    <hr />
                    <CustomHeaders headerName='Wallet and Fund Flow Details' />
                    <WalletFunds
                      CustomFieldInput={CustomFieldInput}
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                      optionalFile={optionalFile}
                      fileUrls={fileUrls}
                      changeHandler={changeHandler}
                    />
                    <hr />
                    <CustomHeaders headerName='Partner Entity Compliance and Documentation' />
                    <PartnerEntity
                      values={values}
                      errors={errors}
                      touched={touched}
                      optionalFile={optionalFile}
                      fileUrls={fileUrls}
                      changeHandler={changeHandler}
                    />
                    <hr />
                    <CustomHeaders headerName='Certifications' />
                    <Certifications
                      CustomFieldInput={CustomFieldInput}
                      values={values}
                      errors={errors}
                      touched={touched}
                      handleChange={handleChange}
                    />

                    {isLoaded && (
                      <div className='d-flex justify-content-center'>
                        <button
                          className='btn btn-auth'
                          type='submit'
                          style={{ width: 213, height: 40 }}
                          onSubmit={(values) => handleSubmit(values)}>
                          Update
                        </button>
                      </div>
                    )}
                  </Form>
                )}
              </Formik>
            </div>
          </Container>
        )}
      </div>

      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default ViewOnRampApplication;

const CustomFieldInput = ({
  values,
  filedName,
  labelName,
  placeholder,
  errors,
  touched,
  inputType,
  extraLabel,
  handleChange
}) => {
  return (
    <FormGroup className='mb-3'>
      <div className='font-size-12'>
        <Label htmlFor={`${filedName}`} className='text-black font-weight-normal'>
          {labelName}
          {extraLabel}
        </Label>
      </div>
      <div className='font-size-12'>
        <AuthInput
          className='font-size-12'
          type={`${inputType}`}
          name={`${filedName}`}
          placeholder={`${placeholder}`}
          values={values}
          errors={errors}
          handleChange={handleChange}
          touched={touched}></AuthInput>
      </div>
    </FormGroup>
  );
};

const CustomHeaders = ({ headerName }) => {
  return (
    <div className='col-12 col-sm-auto font-size-18 pb-2 pb-sm-0'>
      <span className='text-black'>
        <u>{headerName}</u>
      </span>
    </div>
  );
};
