import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const BusinessSearchAPI = (search) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.ALL_SEARCH + `?search=${search}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AvailableOnRampForCrypto = (crypto_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.AVAILABLE_ON_RAMP + `?crypto_id=${crypto_id}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetAllCountries = (country = '') => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.COUNTRY_PROVIDER_PAYMENT + (true ? `?country=${country}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
