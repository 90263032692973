import { useEffect, useRef, useState } from 'react';
import { Col, Row, Spinner, Table, UncontrolledTooltip } from 'reactstrap';
import { createChart } from 'lightweight-charts';
import * as d3 from 'd3';

import './PieChart.css';
import { capitalizeText } from '.';

function TokenAnalytics({
  activeTab,
  tokenSecurity,
  twitterScore,
  twitterHandle,
  infoSecurity,
  contractSecurity,
  tradingSecurity,
  poolsInfo,
  teamAnalytics,
  selectedTeamMember,
  tokenMarketData
}) {
  return (
    <div>
      <Row className='w-100 m-0 mt-4 pl-3 pr-3'>
        <div
          style={{
            flex: 5
          }}>
          <MainContent
            pickedTab={activeTab}
            info={tokenSecurity}
            infoSecurity={infoSecurity}
            contractSecurity={contractSecurity}
            tradingSecurity={tradingSecurity}
            twitterInfo={{ twitterScore: twitterScore, twitterHandle: twitterHandle }}
            poolsInfo={poolsInfo}
            teamAnalytics={teamAnalytics}
            selectedTeamMember={selectedTeamMember}
            tokenMarketData={tokenMarketData}
          />
        </div>
      </Row>
    </div>
  );
}

function MainContent({
  pickedTab,
  info,
  infoSecurity,
  contractSecurity,
  tradingSecurity,
  poolsInfo,
  teamAnalytics,
  twitterInfo,
  selectedTeamMember,
  tokenMarketData
}) {
  switch (pickedTab) {
    case 'infoSecurity':
      return (
        <div className='p-4'>
          <InfoSecurity infoSecurity={infoSecurity} />
        </div>
      );
    case 'contractSecurity':
      return (
        <div className='p-4'>
          <ContractSecurity contractSecurity={contractSecurity} />
        </div>
      );
    case 'tradingSecurity':
      return (
        <div className='p-4'>
          <TradingSecurity tradingSecurity={tradingSecurity} />
        </div>
      );
    case 'holdersInfo':
      return (
        <div className='p-4'>
          <HoldersInfo
            holderCount={info.holder_count}
            holders={info.holders}
            areLiquidityProviers={false}
          />
        </div>
      );
    case 'liquidityProvidersInfo':
      return (
        <div className='p-4'>
          <HoldersInfo
            totalSupply={info.lp_total_supply}
            holderCount={info.lp_holder_count}
            holders={info.lp_holders}
            areLiquidityProviers={true}
          />
        </div>
      );
    case 'dexInfo':
      return (
        <div className='p-4'>
          <DexInfo dexInfo={info.dex} />
        </div>
      );
    case 'twitterInfo':
      return (
        <div className='p-4'>
          <TwitterInfo twitterInfo={twitterInfo} />
        </div>
      );
    case 'poolsInfo':
      return (
        <div className='p-4'>
          <PoolsInfo poolsInfo={poolsInfo} />
        </div>
      );
    case 'teamAnalytics':
      return (
        <div className='p-4'>
          <TeamViewer teamData={teamAnalytics} selectedTeamMember={selectedTeamMember} />
        </div>
      );
    case 'priceChart':
      return (
        <div className='p-4'>
          <PriceChart data={tokenMarketData} />
        </div>
      );
    default:
      return (
        <div className='p-4'>
          <InfoSecurity infoSecurity={infoSecurity} />
        </div>
      );
  }
}

function PriceChart({ data }) {
  const chartContainerRef = useRef();

  function convertToFormat(array) {
    const formattedArray = array.map((item) => ({
      time: unixTimestampToString(item[0]),
      value: item[1]
    }));

    const uniqueArray = formattedArray.filter(
      (item, index) =>
        formattedArray.findIndex((item2) => item2.time === item.time) === index
    );

    return uniqueArray;
  }

  function unixTimestampToString(unixTimestamp) {
    const date = new Date(unixTimestamp);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');

    const res = `${year}-${month}-${day}`;
    return res;
  }

  useEffect(() => {
    const handleResize = () => {
      chart.applyOptions({ width: chartContainerRef.current.clientWidth });
    };

    const chartOptions = {
      layout: {
        textColor: 'black',
        background: { type: 'solid', color: 'white' }
      },
      rightPriceScale: {
        borderVisible: false
      },
      width: chartContainerRef.current.clientWidth,
      height: 300
    };

    const chart = createChart(chartContainerRef.current, chartOptions);
    chart.timeScale().fitContent();

    const areaSeries = chart.addAreaSeries({
      topColor: '#2962FF',
      bottomColor: 'rgba(41, 98, 255, 0.28)',
      lineColor: '#2962FF',
      lineWidth: 2
    });
    areaSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.1,
        bottom: 0.4
      }
    });
    areaSeries.setData(convertToFormat(data.prices));

    const volumeSeries = chart.addHistogramSeries({
      color: '#26a69a',
      priceFormat: {
        type: 'volume'
      },
      priceScaleId: '',
      scaleMargins: {
        top: 0.7,
        bottom: 0
      }
    });
    volumeSeries.priceScale().applyOptions({
      scaleMargins: {
        top: 0.7,
        bottom: 0
      }
    });
    volumeSeries.setData(convertToFormat(data.total_volumes));

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);

      chart.remove();
    };
  }, [data]);

  return (
    <div>
      <div id='firstContainer' ref={chartContainerRef} style={{ height: '500px' }}></div>
    </div>
  );
}

function MultiLineChart({ data }) {
  const ref = useRef(null);

  const drawChart = () => {
    const svg = d3.select(ref.current);
    svg.selectAll('*').remove();

    const parentNode = svg.node().parentNode.getBoundingClientRect();
    const width = parentNode.width;
    const height = 500;
    const marginTop = 20;
    const marginRight = 54;
    const marginBottom = 30;
    const marginLeft = 40;

    const combinedData = data.scores.map((score, index) => ({
      date: new Date(`${score.year}-${score.month}-01`),
      score: score.value,
      followers: data.followers[index].value
    }));

    const x = d3.scaleUtc(
      d3.extent(combinedData, (d) => d.date),
      [marginLeft, width - marginRight]
    );
    const y1 = d3.scaleLinear(
      [data.scores_min, data.scores_max],
      [height - marginBottom, marginTop]
    );
    const y2 = d3.scaleLinear(
      [data.followers_min, data.followers_max],
      [height - marginBottom, marginTop]
    );

    const line1 = d3
      .line()
      .x((d) => x(d.date))
      .y((d) => y1(d.score));

    const line2 = d3
      .line()
      .x((d) => x(d.date))
      .y((d) => y2(d.followers));

    svg
      .append('g')
      .attr('transform', `translate(0,${height - marginBottom})`)
      .call(
        d3
          .axisBottom(x)
          .ticks(width / 80)
          .tickSizeOuter(0)
      );

    svg
      .append('g')
      .attr('transform', `translate(${marginLeft},0)`)
      .call(d3.axisLeft(y1).ticks(height / 40))
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .append('text')
          .attr('x', -marginLeft)
          .attr('y', 10)
          .attr('fill', 'currentColor')
          .attr('text-anchor', 'start')
          .text('Scores')
      );

    svg
      .append('g')
      .attr('transform', `translate(${width - marginRight},0)`)
      .call(d3.axisRight(y2).ticks(height / 40))
      .call((g) => g.select('.domain').remove())
      .call((g) =>
        g
          .append('text')
          .attr('x', marginRight)
          .attr('y', 10)
          .attr('fill', 'currentColor')
          .attr('text-anchor', 'end')
          .text('Followers')
      );

    svg
      .append('path')
      .datum(combinedData)
      .attr('fill', 'none')
      .attr('stroke', '#3765ebff')
      .attr('stroke-width', 1.5)
      .attr('d', line1);

    svg
      .append('path')
      .datum(combinedData)
      .attr('fill', 'none')
      .attr('stroke', '#1a3170ff')
      .attr('stroke-width', 1.5)
      .attr('d', line2);

    svg
      .selectAll('circle.scoreDot')
      .data(combinedData)
      .enter()
      .append('circle')
      .attr('class', 'scoreDot')
      .attr('cx', (d) => x(d.date))
      .attr('cy', (d) => y1(d.score))
      .attr('r', 3)
      .style('fill', '#3765ebff')
      .style('opacity');

    svg
      .selectAll('circle.followerDot')
      .data(combinedData)
      .enter()
      .append('circle')
      .attr('class', 'followerDot')
      .attr('cx', (d) => x(d.date))
      .attr('cy', (d) => y2(d.followers))
      .attr('r', 3)
      .style('fill', '#1a3170ff')
      .style('opacity', 1);

    svg.attr('width', width).attr('height', height);
  };

  useEffect(() => {
    drawChart();
  }, [data]);

  useEffect(() => {
    const handleResize = () => {
      drawChart();
    };

    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <svg
      ref={ref}
      width='928'
      height='500'
      style={{ maxWidth: '100%', fontFamily: 'sans-serif' }}></svg>
  );
}

function ArcChart({ value }) {
  const ref = useRef();

  const [hasRenderedOnce, setHasRenderedOnce] = useState(false);

  useEffect(() => {
    if (!hasRenderedOnce) {
      setHasRenderedOnce(true);
    }
  }, [hasRenderedOnce]);

  const drawArc = () => {
    const svgNode = d3.select(ref.current);
    svgNode.selectAll('*').remove();

    const parentNode = svgNode.node().parentNode.getBoundingClientRect();
    const width = parentNode.width < 500 ? parentNode.width : 500;
    const height = width;
    const radius = Math.min(width, height) * 0.4;

    svgNode.attr('width', width).attr('height', height);

    const svg = svgNode
      .append('g')
      .attr(
        'transform',
        `translate(${parentNode.width / 2},${parentNode.height / 1.25})`
      );

    const defs = svg.append('defs');

    const gradient = defs
      .append('linearGradient')
      .attr('id', 'arcGradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%');

    gradient
      .append('stop')
      .attr('offset', '0%')
      .attr('stop-color', '#3765ebff')
      .attr('stop-opacity', 1);

    gradient
      .append('stop')
      .attr('offset', '100%')
      .attr('stop-color', '#1a3170ff')
      .attr('stop-opacity', 1);

    const scaleValue = d3
      .scaleLinear()
      .domain([0, 1000])
      .range([-Math.PI / 2, Math.PI / 2]);
    const endAngle = scaleValue(value);

    const arc = d3
      .arc()
      .innerRadius(radius)
      .outerRadius(radius)
      .startAngle(-Math.PI / 2)
      .endAngle(endAngle)
      .cornerRadius(20);

    const startX = -radius;
    const startY = 0;

    const endX = radius * Math.cos(endAngle - Math.PI / 2);
    const endY = radius * Math.sin(endAngle - Math.PI / 2);

    svg
      .append('circle')
      .attr('cx', startX)
      .attr('cy', startY)
      .attr('r', 7.3)
      .attr('fill', '#3765ebff');

    svg
      .append('circle')
      .attr('cx', endX)
      .attr('cy', endY)
      .attr('r', 7.3)
      .attr('fill', '#1a3170ff');

    svg
      .append('path')
      .attr('d', arc)
      .attr('fill', 'none')
      .attr('stroke', 'url(#arcGradient)')
      .attr('stroke-width', 15)
      .attr('stroke-linecap', 'round');

    svg
      .append('text')
      .attr('x', -radius)
      .attr('y', 25)
      .attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('font-size', '20px')
      .text('0');

    svg
      .append('text')
      .attr('x', radius)
      .attr('y', 25)
      .attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('font-size', '20px')
      .text('1000');

    svg
      .append('text')
      .attr('x', 0)
      .attr('y', -height / 8)
      .attr('fill', '#000')
      .attr('text-anchor', 'middle')
      .attr('alignment-baseline', 'middle')
      .attr('font-size', '1.2rem')
      .attr('font-weight', 'bold')
      .text(`Twitter Score: ${value}`);
  };

  useEffect(() => {
    drawArc();

    const handleResize = () => {
      drawArc();
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [value, hasRenderedOnce]);

  return (
    <svg ref={ref} style={{ width: '100%', height: 'auto', maxHeight: '300' }}></svg>
  );
}

function TwitterInfo({ twitterInfo }) {
  const scores = twitterInfo.twitterScore.scores;
  const latestScore = scores[scores.length - 1].value;
  return (
    <div>
      <h3>Twitter Info</h3>
      <div>
        {twitterInfo.twitterScore === undefined ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Spinner />
          </div>
        ) : (
          <div>
            <div className='twitter-content'>
              <div className='twitter-content-item'>
                <div className='twitter-content-chart'>
                  <ArcChart value={latestScore} />
                </div>
              </div>
              <div className='twitter-content-item'>
                <div className='twitter-content-chart'>
                  <div className='twitter-content-title'>Followers Statistics</div>
                  <MultiLineChart data={twitterInfo.twitterScore} />
                </div>
                <div className='twitter-content-text-group'>
                  <p className='twitter-content-text'>
                    <span className='twitter-content-text-bold'>
                      Minimal Number of Followers for the Past Year:{' '}
                    </span>
                    {twitterInfo.twitterScore.followers_min}
                  </p>
                  <p className='twitter-content-text'>
                    <span className='twitter-content-text-bold'>
                      Maximal Number of Followers for the Past Year:{' '}
                    </span>
                    {twitterInfo.twitterScore.followers_max}
                  </p>
                  <p className='twitter-content-text'>
                    <span className='twitter-content-text-bold'>
                      Minimal Twitter Score for the Past Year:{' '}
                    </span>
                    {twitterInfo.twitterScore.scores_min}
                  </p>
                  <p className='twitter-content-text'>
                    <span className='twitter-content-text-bold'>
                      Maximal Twitter Score for the Past Year:{' '}
                    </span>
                    {twitterInfo.twitterScore.scores_max}
                  </p>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

function TeamViewer({ selectedTeamMember }) {
  if (selectedTeamMember && selectedTeamMember.experience) {
    selectedTeamMember.experience = sortByDate(selectedTeamMember.experience);
  }
  if (selectedTeamMember && selectedTeamMember.education) {
    selectedTeamMember.education = sortByDate(selectedTeamMember.education);
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: '1.1rem'
      }}>
      <div className='member-data-wrapper'>
        <h5 className='member-data-section-title'>Name and Profiles</h5>
        <div
          style={{
            flexDirection: 'column'
          }}
          className='member-data-experience-wrapper'>
          {selectedTeamMember && selectedTeamMember.full_name && (
            <ExperiencePiece
              title='Name'
              text={capitalizeText(selectedTeamMember && selectedTeamMember.full_name)}
            />
          )}

          {selectedTeamMember
            ? selectedTeamMember.profiles.map((profile, index) => (
                <p key={index}>
                  {profile.network}:{' '}
                  <a
                    href={
                      profile.url.startsWith('https://')
                        ? profile.url
                        : `https://${profile.url}`
                    }
                    target='_blank'
                    rel='noopener noreferrer'>
                    {profile.username}
                  </a>
                </p>
              ))
            : 'No Data Found'}
        </div>
      </div>

      <div className='member-data-wrapper'>
        <h5 className='member-data-section-title'>Professional Experience</h5>
        <div className='member-data-experience-wrapper'>
          {selectedTeamMember
            ? selectedTeamMember.experience.map((exp, index) => (
                <div className='member-data-experience' key={index}>
                  {exp.company.name && (
                    <ExperiencePiece title='Company' text={exp.company.name} />
                  )}
                  {exp.title.name && (
                    <ExperiencePiece title='Title' text={exp.title.name} />
                  )}
                  {exp.title.role && (
                    <ExperiencePiece title={'Role'} text={exp.title.role} />
                  )}
                  {exp.title.sub_role && (
                    <ExperiencePiece title={'Sub-Role'} text={exp.title.sub_role} />
                  )}

                  {exp.title.levels && exp.title.levels.length ? (
                    <ExperiencePiece
                      title={'Levels'}
                      text={exp.title.levels.join(', ')}
                    />
                  ) : null}
                  {exp.start_date && (
                    <ExperiencePiece title='Start Date' text={exp.start_date} />
                  )}
                  {exp.end_date ? (
                    <ExperiencePiece title='End Date' text={exp.end_date} />
                  ) : (
                    <ExperiencePiece title='End Date' text='Present' />
                  )}
                </div>
              ))
            : 'No Data Found'}
        </div>
      </div>

      <div className='member-data-wrapper'>
        <h5 className='member-data-section-title'>Education</h5>
        <div className='member-data-experience-wrapper'>
          {selectedTeamMember
            ? selectedTeamMember.education.map((edu, index) => (
                <div className='member-data-experience' key={index}>
                  <ExperiencePiece
                    title='School'
                    text={capitalizeText(edu.school.name)}
                  />
                  {edu.degrees.length > 0 && (
                    <ExperiencePiece
                      title='Degree(s)'
                      text={capitalizeText(edu.degrees.join(', '))}
                    />
                  )}
                  {edu.majors.length > 0 && (
                    <ExperiencePiece
                      title='Major(s)'
                      text={capitalizeText(edu.majors.join(', '))}
                    />
                  )}
                  {edu.start_date && (
                    <ExperiencePiece title='Start Date' text={edu.start_date} />
                  )}
                  {edu.end_date ? (
                    <ExperiencePiece title='End Date' text={edu.end_date} />
                  ) : (
                    <ExperiencePiece title='End Date' text='Present' />
                  )}
                </div>
              ))
            : 'No Data found'}
        </div>
      </div>

      <div className='member-data-skills-wrapper'>
        <h5 className='member-data-section-title'>Skills</h5>
        <div className='member-data-skills'>
          {selectedTeamMember
            ? selectedTeamMember.skills.map((skill, index) => (
                <span
                  className='badge badge-pill badge-secondary font-size-12'
                  key={index}>
                  {capitalizeText(skill)}
                </span>
              ))
            : 'No Data found'}
        </div>
      </div>

      {/* <div>
        <h5>Email and Contact Details</h5>
        <p>Email: {selectedTeamMember.recommended_personal_email}</p>
        {selectedTeamMember.phone_numbers && (
          <p>Phone: {selectedTeamMember.phone_numbers.join(', ')}</p>
        )}
      </div> */}
    </div>
  );
}

function DexInfo({ dexInfo }) {
  return (
    <div>
      <h3>Dex Info</h3>
      <div>
        <Table className='tablesorter' responsive>
          <thead className='text-primary'>
            <tr>
              <th>Name</th>
              <th>Liquidity</th>
              <th>Pair</th>
            </tr>
          </thead>
          <tbody>
            {dexInfo.map((dex) => (
              <tr key={dex.name + dex.liquidity}>
                <td>{dex.name}</td>
                <td>{shortenTheFloatingPoint(dex.liquidity, 2)}</td>
                <td
                  style={{ cursor: 'pointer' }}
                  onClick={() => copyToClipboard(dex.pair)}>
                  {shortenTheString(dex.pair, 6)}
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
    </div>
  );
}

function ExperiencePiece({ title, text }) {
  return (
    <div className='member-data-piece-wrapper'>
      <p className='member-data-title'>{title}:</p>
      <p className='member-data-text'>{capitalizeText(text)}</p>
    </div>
  );
}

function PoolsInfo({ poolsInfo }) {
  const [isChartView, setIsChartView] = useState(false);
  let ComponentToRender;
  switch (isChartView) {
    case true:
      ComponentToRender = PieChart;
      break;
    case false:
      ComponentToRender = PoolsTable;
      break;
    default:
      ComponentToRender = PoolsTable;
  }

  return (
    <>
      <Row className='m-0' style={{}}>
        <Col
          style={{
            flex: 5,
            width: '100%'
          }}>
          <h3>Top Pools Info</h3>
          <h4>By Percentage out of Total Reserves</h4>
        </Col>
        <SwitchToggle
          labels={['Table', 'Chart']}
          isChecked={isChartView}
          onToggle={() => setIsChartView(!isChartView)}
        />
      </Row>
      <ComponentToRender pools={poolsInfo} />
    </>
  );
}

function PoolsTable({ pools }) {
  pools.sort((a, b) => b.attributes.reserve_in_usd - a.attributes.reserve_in_usd);
  return (
    <div>
      <Table className='tablesorter' responsive>
        <thead className='text-primary'>
          <tr>
            <th>Name</th>
            <th>Reserves</th>
            <th>Address</th>
          </tr>
        </thead>
        <tbody>
          {pools.map((pool) => (
            <tr key={pool.attributes.name + pool.attributes.reserve_in_usd}>
              <td>{pool.attributes.name}</td>
              <td>{shortenTheFloatingPoint(pool.attributes.reserve_in_usd, 2)}</td>
              <td
                style={{ cursor: 'pointer' }}
                onClick={() => copyToClipboard(pool.attributes.address)}>
                {shortenTheString(pool.attributes.address, 6)}
              </td>
            </tr>
          ))}
        </tbody>
      </Table>
    </div>
  );
}

function PieChart({ pools }) {
  const ref = useRef();

  const renderChart = () => {
    const totalSum = pools.reduce(
      (accumulator, pool) => accumulator + parseFloat(pool.attributes.reserve_in_usd),
      0
    );
    const threshold = totalSum * 0.02;

    let otherValue = 0;
    let otherPools = [];

    const filteredData = pools
      .filter((pool) => {
        const value = shortenTheFloatingPoint(
          parseFloat(pool.attributes.reserve_in_usd),
          2
        );
        if (value < threshold) {
          otherValue += Number(value);
          otherPools.push({
            name: pool.attributes.name,
            value: pool.attributes.reserve_in_usd
          });
          return false;
        }
        return true;
      })
      .map((pool) => ({
        name: pool.attributes.name,
        value: shortenTheFloatingPoint(parseFloat(pool.attributes.reserve_in_usd), 2)
      }));

    if (otherValue > 0) {
      otherPools.sort((a, b) => b.value - a.value);
      filteredData.push({
        name: 'Others',
        value: otherValue,
        details: otherPools
          .map(
            (pool) =>
              `${pool.name}: ${shortenTheFloatingPoint(
                pool.value.toLocaleString('en-US'),
                2
              )}`
          )
          .join('\n ')
      });
    }

    const svg = d3.select(ref.current);
    svg.selectAll('*').remove();

    const width = 928;
    const height = Math.min(width, 500);

    const color = d3
      .scaleOrdinal()
      .domain(filteredData.map((d) => d.name))
      .range(
        d3
          .quantize((t) => d3.interpolateSpectral(t * 0.8 + 0.1), filteredData.length)
          .reverse()
      );

    const pie = d3
      .pie()
      .sort(null)
      .value((d) => d.value);

    const arc = d3
      .arc()
      .innerRadius(0)
      .outerRadius(Math.min(width, height) / 2 - 1);

    const labelRadius = arc.outerRadius()() * 0.8;

    const arcLabel = d3.arc().innerRadius(labelRadius).outerRadius(labelRadius);

    const arcs = pie(filteredData);

    svg
      .attr('width', width)
      .attr('height', height)
      .attr('viewBox', [-width / 2, -height / 2, width, height])
      .attr('style', 'max-width: 100%; height: auto; font: 10px sans-serif;');

    svg
      .append('g')
      .attr('stroke', 'white')
      .selectAll()
      .data(arcs)
      .join('path')
      .attr('fill', (d) => color(d.data.name))
      .attr('d', arc)
      .append('title')
      .text((d) =>
        d.data.name === 'Others'
          ? `${d.data.name}: ${d.data.value.toLocaleString('en-US')} (${d.data.details})`
          : `${d.data.name}: ${d.data.value.toLocaleString('en-US')}`
      );

    svg
      .append('g')
      .attr('text-anchor', 'middle')
      .selectAll()
      .data(arcs)
      .join('text')
      .attr('transform', (d) => `translate(${arcLabel.centroid(d)})`)
      .call((text) =>
        text
          .append('tspan')
          .attr('y', '-0.4em')
          .attr('font-weight', 'bold')
          .text((d) => d.data.name)
      )
      .call((text) =>
        text
          .filter((d) => d.endAngle - d.startAngle > 0.25)
          .append('tspan')
          .attr('x', 0)
          .attr('y', '0.7em')
          .attr('fill-opacity', 0.7)
          .text((d) => d.data.value.toLocaleString('en-US'))
      );
  };

  useEffect(() => {
    renderChart();
  }, [pools]);

  useEffect(() => {
    window.addEventListener('resize', renderChart);
    return () => window.removeEventListener('resize', renderChart);
  }, []);

  return (
    <svg ref={ref} style={{ width: '100%', height: 'auto', maxHeight: '500px' }}></svg>
  );
}

function InfoSecurity({ infoSecurity }) {
  return (
    <div>
      <h3>Info Security</h3>
      <div>
        {infoSecurity === undefined ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center'
            }}>
            <Spinner />
          </div>
        ) : (
          <>
            <div className='security-content'>
              <div className='security-content-item'>
                {infoSecurity.token_name.value === undefined ? (
                  <div id='token_name' className='security-content-item-label'>
                    Token Name is Unknown
                  </div>
                ) : (
                  <>
                    <div id='token_name' className='security-content-item-label'>
                      {infoSecurity.token_name.label}
                    </div>

                    <div className='security-content-item-value'>
                      {infoSecurity.token_name.value}
                    </div>
                  </>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.token_symbol.value === undefined ? (
                  <div id='token_symbol' className='security-content-item-label'>
                    Token Symbol is Unknown
                  </div>
                ) : (
                  <>
                    <div id='token_symbol' className='security-content-item-label'>
                      {infoSecurity.token_symbol.label}
                    </div>
                    <div className='security-content-item-value'>
                      {infoSecurity.token_symbol.value}
                    </div>
                  </>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.creator_balance.value === undefined ? (
                  <div id='total_supply' className='security-content-item-label positive'>
                    Total Supply is Unknown
                  </div>
                ) : (
                  <>
                    <div id='total_supply' className='security-content-item-label'>
                      {infoSecurity.total_supply.label}
                    </div>
                    <div className='security-content-item-value'>
                      {infoSecurity.total_supply.value}
                    </div>
                  </>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.creator_address.value === undefined ? (
                  <div
                    id='creator_address'
                    className='security-content-item-label positive'>
                    Creator Address is Unknown
                  </div>
                ) : (
                  <>
                    <div id='creator_address' className='security-content-item-label'>
                      {infoSecurity.creator_address.label}
                    </div>
                    <div className='security-content-item-value'>
                      {infoSecurity.creator_address.value}
                    </div>
                  </>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.creator_balance.value === undefined ? (
                  <div
                    id='creator_balance'
                    className='security-content-item-label positive'>
                    Creator Balance is Unknown
                  </div>
                ) : (
                  <>
                    <div id='creator_balance' className='security-content-item-label'>
                      {infoSecurity.creator_balance.label}
                    </div>
                    <div className='security-content-item-value'>
                      {infoSecurity.creator_balance.value}
                    </div>
                  </>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.creator_percent.value === undefined ? (
                  <div
                    id='creator_percent'
                    className='security-content-item-label positive'>
                    Creator Percent is Unknown
                  </div>
                ) : (
                  <>
                    <div id='creator_percent' className='security-content-item-label'>
                      {infoSecurity.creator_percent.label}
                    </div>
                    <div className='security-content-item-value'>
                      {infoSecurity.creator_percent.value}
                    </div>
                  </>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.owner_balance.value === undefined ? (
                  <div
                    id='owner_balance'
                    className='security-content-item-label positive'>
                    Owner Balance is Unknown
                  </div>
                ) : (
                  <>
                    <div id='owner_balance' className='security-content-item-label'>
                      {infoSecurity.owner_balance.label}
                    </div>
                    <div className='security-content-item-value'>
                      {infoSecurity.owner_balance.value}
                    </div>
                  </>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.owner_percent.value === undefined ? (
                  <div
                    id='owner_percent'
                    className='security-content-item-label positive'>
                    Owner Percent is Unknown
                  </div>
                ) : (
                  <>
                    <div id='owner_percent' className='security-content-item-label'>
                      {infoSecurity.owner_percent.label}
                    </div>
                    <div className='security-content-item-value'>
                      {infoSecurity.owner_percent.value}
                    </div>
                  </>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.trust_list.value === undefined ? null : (
                  <div id='trust_list' className='security-content-item-label positive'>
                    Token should be famous and trustworthy
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.is_airdrop_scam.value === undefined ? null : infoSecurity
                    .is_airdrop_scam.value === '0' ? (
                  <div
                    id='is_airdrop_scam'
                    className='security-content-item-label positive'>
                    Token is Not an Airdrop Scam
                  </div>
                ) : (
                  <div
                    id='is_airdrop_scam'
                    className='security-content-item-label negative'>
                    Token is an Airdrop Scam
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.is_true_token.value === undefined ? null : infoSecurity
                    .is_true_token.value === '0' ? (
                  <div
                    id='is_true_token'
                    className='security-content-item-label negative'>
                    Token is a Fake Token
                  </div>
                ) : (
                  <div
                    id='is_true_token'
                    className='security-content-item-label positive'>
                    Token is a True Token
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.other_potential_risks.value && (
                  <>
                    <div
                      id='other_potential_risks'
                      className='security-content-item-label'>
                      {infoSecurity.other_potential_risks.label}
                    </div>
                    <div className='security-content-item-value'>
                      {infoSecurity.other_potential_risks.value}
                    </div>
                  </>
                )}
              </div>

              <div className='security-content-item'>
                {infoSecurity.note.value && (
                  <>
                    <div id='note' className='security-content-item-label'>
                      {infoSecurity.note.label}
                    </div>
                    <div className='security-content-item-value'>
                      {infoSecurity.note.value}
                    </div>
                  </>
                )}
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
}

function ContractSecurity({ contractSecurity }) {
  return (
    <div>
      <h3>Contract Security</h3>
      <div>
        {contractSecurity === undefined ? (
          <Spinner />
        ) : (
          <>
            <div className='security-content'>
              <div className='security-content-item'>
                {contractSecurity.is_mintable.value === '1' ? (
                  <div id='is_mintable' className='security-content-item-label negative'>
                    Contract Has the Ability to Mint Tokens
                  </div>
                ) : contractSecurity.is_mintable.value === '0' ? (
                  <div id='is_mintable' className='security-content-item-label positive'>
                    Contract Does Not Have the Ability to Mint Tokens
                  </div>
                ) : (
                  <div id='is_mintable' className='security-content-item-label'>
                    Minting Ability of the Contract is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {contractSecurity.is_open_source.value === '1' ? (
                  <div
                    id='is_open_source'
                    className='security-content-item-label positive'>
                    Contract is Open-Sourced
                  </div>
                ) : (
                  <div
                    id='is_open_source'
                    className='security-content-item-label negative'>
                    Contract is Not Open-Sourced
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {contractSecurity.is_proxy.value === '1' ? (
                  <div id='is_proxy' className='security-content-item-label negative'>
                    Contract is a Proxy Contract
                  </div>
                ) : contractSecurity.is_proxy.value === '0' ? (
                  <div id='is_proxy' className='security-content-item-label positive'>
                    Contract is Not a Proxy Contract
                  </div>
                ) : (
                  <div id='is_proxy' className='security-content-item-label'>
                    Proxy Status of the Contract is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {contractSecurity.can_take_back_ownership.value === '1' ? (
                  <div
                    id='can_take_back_ownership'
                    className='security-content-item-label negative'>
                    Ownership of the Contract Can Be Reclaimed
                  </div>
                ) : contractSecurity.can_take_back_ownership.value === '0' ? (
                  <div
                    id='can_take_back_ownership'
                    className='security-content-item-label positive'>
                    Ownership of the Contract Can Not Be Reclaimed
                  </div>
                ) : (
                  <div
                    id='can_take_back_ownership'
                    className='security-content-item-label'>
                    Ownership Reclaimability of the Contract is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {contractSecurity.owner_change_balance.value === '1' ? (
                  <div
                    id='owner_change_balance'
                    className='security-content-item-label negative'>
                    Contract Owner Can Change Token Holders Balances
                  </div>
                ) : contractSecurity.owner_change_balance.value === '0' ? (
                  <div
                    id='owner_change_balance'
                    className='security-content-item-label positive'>
                    Contract Owner Can Not Change Token Holders Balances
                  </div>
                ) : (
                  <div id='owner_change_balance' className='security-content-item-label'>
                    Contract Owner's Ability to Change Token Holders Balances is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {contractSecurity.owner_address.value === '' ? (
                  <div
                    id='owner_address'
                    className='security-content-item-label positive'>
                    Contract Has No Owner
                  </div>
                ) : contractSecurity.owner_address.value !== '' &&
                  contractSecurity.owner_address.value !== undefined ? (
                  <div id='owner_address' className='security-content-item-label'>
                    Contract Owner Address is {contractSecurity.owner_address.value}
                  </div>
                ) : (
                  <div id='owner_address' className='security-content-item-label'>
                    Contract Owner Address is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {contractSecurity.hidden_owner.value === '1' ? (
                  <div id='hidden_owner' className='security-content-item-label negative'>
                    Contract has Hidden Owners
                  </div>
                ) : contractSecurity.hidden_owner.value === '0' ? (
                  <div id='hidden_owner' className='security-content-item-label positive'>
                    Contract Does Not Have Hidden Owners
                  </div>
                ) : (
                  <div id='hidden_owner' className='security-content-item-label'>
                    Contract's Hidden Ownership Status is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {contractSecurity.selfdestruct.value === '1' ? (
                  <div id='selfdestruct' className='security-content-item-label negative'>
                    Contract Can Self-Destruct
                  </div>
                ) : contractSecurity.selfdestruct.value === '0' ? (
                  <div id='selfdestruct' className='security-content-item-label positive'>
                    Contract Can Not Self-Destruct
                  </div>
                ) : (
                  <div id='selfdestruct' className='security-content-item-label'>
                    Contract's Self-Destruct Status is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {contractSecurity.external_call.value === '1' ? (
                  <div
                    id='external_call'
                    className='security-content-item-label negative'>
                    Can Call Functions in Other Contracts During the Execution of Primary
                    Methods
                  </div>
                ) : contractSecurity.external_call.value === '0' ? (
                  <div
                    id='external_call'
                    className='security-content-item-label positive'>
                    Does Not Call Functions in Other Contracts During the Execution of
                    Primary Methods
                  </div>
                ) : (
                  <div id='external_call' className='security-content-item-label'>
                    Contract's Ability to Call Functions in Other Contracts During the
                    Execution of Primary Methods is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {contractSecurity.gas_abuse.value === '1' ? (
                  <div id='gas_abuse' className='security-content-item-label negative'>
                    Contract Abuses Gas Fees
                  </div>
                ) : (
                  <div id='gas_abuse' className='security-content-item-label positive'>
                    No Evidence of Gas Abuse Found
                  </div>
                )}
              </div>
            </div>
            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='is_open_source'>
              Closed-sourced contracts may hide various unknown mechanisms and are
              extremely risky.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='is_mintable'>
              Mint functions can trigger a massive sell-off, causing the coin price to
              plummet. It is an extremely risky function for a contract to have. This
              function generally relies on ownership.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='is_proxy'>
              Most proxy contracts are accompanied by implementation contracts which are
              modifiable, potentially containing significant risk.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='can_take_back_ownership'>
              Ownership is usually used to adjust the parameters and status of the
              contract, such as minting, modification of slippage, suspension of trading,
              setting blacklist, etc. When the contract's owner cannot be retrieved, is a
              black hole address, or does not have an owner, ownership-related
              functionality will most likely be disabled. These risky functions may be
              able to be reactivated if ownership is reclaimed.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='owner_change_balance'>
              Tokens with this feature allow the owner to modify anyone's balance,
              resulting in a holder's asset to be changed (i.e. to 0) or a massive minting
              and sell-off.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='owner_address'>
              Ownership is usually used to adjust the parameters and status of the
              contract, such as minting, modification of slippage, suspension of trading,
              setting blacklist, etc. When the contract's owner cannot be retrieved, is a
              black hole address, or does not have an owner, ownership-related
              functionality will most likely be disabled.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='hidden_owner'>
              Hidden ownership is used by developers to maintain ownership ability even
              after abandoning ownership, and is often an indicator of malicious intent.
              When a hidden owner exists, it is safe to assume that ownership has not been
              abandoned.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='selfdestruct'>
              When the self-destruct function is triggered, the contract will be
              destroyed, all of its functions will be unavailable, and all related assets
              will be erased.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='external_call'>
              External calls causes the implementation of this contract to be dependent on
              other external contracts which may or may not be risky.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='gas_abuse'>
              Contracts may be using user's gas fee to mint other assets. Any interaction
              with such addresses may result in loss of property.
            </UncontrolledTooltip>
          </>
        )}
      </div>
    </div>
  );
}

function TradingSecurity({ tradingSecurity }) {
  return (
    <div>
      <h3>Contract Security</h3>
      <div>
        {tradingSecurity === undefined ? (
          <Spinner />
        ) : (
          <>
            <div className='security-content'>
              <div className='security-content-item'>
                {tradingSecurity.is_in_dex.value === '1' ? (
                  <div id='is_in_dex' className='security-content-item-label positive'>
                    Token is Listed on Dexes
                  </div>
                ) : (
                  <div id='is_in_dex' className='security-content-item-label negative'>
                    Token is Not Listed on Dexes
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.buy_tax.value === '' ? (
                  <div id='buy_tax' className='security-content-item-label'>
                    Buy Tax is Unknown
                  </div>
                ) : (
                  <div id='buy_tax' className='security-content-item-label'>
                    Buy Tax is {String(Number(tradingSecurity.buy_tax.value) * 100) + '%'}
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.sell_tax.value === '' ? (
                  <div id='sell_tax' className='security-content-item-label'>
                    Sell Tax is Unknown
                  </div>
                ) : (
                  <div id='sell_tax' className='security-content-item-label'>
                    Sell Tax is{' '}
                    {String(Number(tradingSecurity.sell_tax.value) * 100) + '%'}
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.cannot_buy.value === '1' ? (
                  <div id='cannot_buy' className='security-content-item-label negative'>
                    Token Cannot Be Bought
                  </div>
                ) : tradingSecurity.cannot_buy.value === '0' ? (
                  <div id='cannot_buy' className='security-content-item-label positive'>
                    Token Can Be Bought
                  </div>
                ) : (
                  <div id='cannot_buy' className='security-content-item-label'>
                    Token's Buy Status is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.cannot_sell_all.value === '1' ? (
                  <div
                    id='cannot_sell_all'
                    className='security-content-item-label negative'>
                    Token Cannot Be Sold
                  </div>
                ) : tradingSecurity.cannot_sell_all.value === '0' ? (
                  <div
                    id='cannot_sell_all'
                    className='security-content-item-label positive'>
                    Token Can Be Sold
                  </div>
                ) : (
                  <div id='cannot_sell_all' className='security-content-item-label'>
                    Token's Sell Status is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.slippage_modifiable.value === '1' ? (
                  <div
                    id='slippage_modifiable'
                    className='security-content-item-label negative'>
                    Slippage Can Be Modified
                  </div>
                ) : tradingSecurity.slippage_modifiable.value === '0' ? (
                  <div
                    id='slippage_modifiable'
                    className='security-content-item-label positive'>
                    Slippage Can Not Be Modified
                  </div>
                ) : (
                  <div id='slippage_modifiable' className='security-content-item-label'>
                    Slippage Modifiability is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.is_honeypot.value === '1' ? (
                  <div id='is_honeypot' className='security-content-item-label negative'>
                    Token is a Honeypot
                  </div>
                ) : tradingSecurity.is_honeypot.value === '0' ? (
                  <div id='is_honeypot' className='security-content-item-label positive'>
                    Token is Not a Honeypot
                  </div>
                ) : (
                  <div id='is_honeypot' className='security-content-item-label'>
                    Token's Honeypot Status is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.transfer_pausable.value === '1' ? (
                  <div
                    id='transfer_pausable'
                    className='security-content-item-label negative'>
                    Token Transfer Can Be Paused
                  </div>
                ) : tradingSecurity.transfer_pausable.value === '0' ? (
                  <div
                    id='transfer_pausable'
                    className='security-content-item-label positive'>
                    Token Transfer Can Not Be Paused
                  </div>
                ) : (
                  <div id='transfer_pausable' className='security-content-item-label'>
                    Token Transfer Pausing is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.is_blacklisted.value === '1' ? (
                  <div
                    id='is_blacklisted'
                    className='security-content-item-label negative'>
                    Token is Blacklisted
                  </div>
                ) : tradingSecurity.is_blacklisted.value === '0' ? (
                  <div
                    id='is_blacklisted'
                    className='security-content-item-label positive'>
                    Token is Not Blacklisted
                  </div>
                ) : (
                  <div id='is_blacklisted' className='security-content-item-label'>
                    Token's Blacklist Status is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.is_whitelisted.value === '1' ? (
                  <div
                    id='is_whitelisted'
                    className='security-content-item-label negative'>
                    Token is Whitelisted
                  </div>
                ) : tradingSecurity.is_whitelisted.value === '0' ? (
                  <div
                    id='is_whitelisted'
                    className='security-content-item-label positive'>
                    Token is Not Whitelisted
                  </div>
                ) : (
                  <div id='is_whitelisted' className='security-content-item-label'>
                    Token's Whitelist Status is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.is_anti_whale.value === '1' ? (
                  <div
                    id='is_anti_whale'
                    className='security-content-item-label negative'>
                    Token is Anti Whale
                  </div>
                ) : tradingSecurity.is_anti_whale.value === '0' ? (
                  <div
                    id='is_anti_whale'
                    className='security-content-item-label positive'>
                    Token is Not Anti Whale
                  </div>
                ) : (
                  <div id='is_anti_whale' className='security-content-item-label'>
                    Token's Anti Whale Status is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.anti_whale_modifiable.value === '1' ? (
                  <div
                    id='anti_whale_modifiable'
                    className='security-content-item-label negative'>
                    Anti Whale Can Be Modified
                  </div>
                ) : tradingSecurity.anti_whale_modifiable.value === '0' ? (
                  <div
                    id='anti_whale_modifiable'
                    className='security-content-item-label positive'>
                    Anti Whale Can Not Be Modified
                  </div>
                ) : (
                  <div id='anti_whale_modifiable' className='security-content-item-label'>
                    Anti Whale Modifiability is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.trading_cooldown.value === '1' ? (
                  <div
                    id='trading_cooldown'
                    className='security-content-item-label negative'>
                    Trading Cooldown is Enabled
                  </div>
                ) : tradingSecurity.trading_cooldown.value === '0' ? (
                  <div
                    id='trading_cooldown'
                    className='security-content-item-label positive'>
                    Trading Cooldown is Disabled
                  </div>
                ) : (
                  <div id='trading_cooldown' className='security-content-item-label'>
                    Trading Cooldown is Unknown
                  </div>
                )}
              </div>

              <div className='security-content-item'>
                {tradingSecurity.personal_slippage_modifiable.value === '1' ? (
                  <div
                    id='personal_slippage_modifiable'
                    className='security-content-item-label negative'>
                    Personal Slippage Can Be Modified
                  </div>
                ) : tradingSecurity.personal_slippage_modifiable.value === '0' ? (
                  <div
                    id='personal_slippage_modifiable'
                    className='security-content-item-label positive'>
                    Personal Slippage Can Not Be Modified
                  </div>
                ) : (
                  <div
                    id='personal_slippage_modifiable'
                    className='security-content-item-label'>
                    Personal Slippage Modifiability is Unknown
                  </div>
                )}
              </div>
            </div>
            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='is_in_dex'>
              Token has a marketing pair with mainstream coins/tokens.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='buy_tax'>
              When buying a token, a buy tax will cause the actual token value received to
              be less than the amount paid. An excessive buy tax may lead to heavy losses.
              A Buy Tax of 100% buy tax, will result in all purchase funds to go towards
              the tax. This results in a token that is effectively not able to be
              purchased.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='sell_tax'>
              Sell tax will cause the actual value received when selling a token to be
              less than expected, and too much buy tax may lead to large losses. When Sell
              Tax is 100% this token cannot be sold.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='cannot_buy'>
              Generally, tokens that cannot be bought are Reward Tokens. Such Tokens are
              issued as rewards for some on-chain applications and cannot be bought
              directly by users.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='cannot_sell_all'>
              This feature means that you will not be able to sell all your tokens in a
              single sale. Sometimes you need to leave a certain percentage of the token,
              e.g. 10%, sometimes you need to leave a fixed number of tokens, such as 10
              tokens.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='slippage_modifiable'>
              Token with modifiable tax means that the contract owner can modify the buy
              tax or sell tax of the token. This may cause some losses, especially since
              some contracts have unlimited modifiable tax rates, which would make the
              token untradeable.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='is_honeypot'>
              "HoneyPot" means that the token maybe cannot be sold because of the token
              contract's function, or the token contains malicious code. High risk,
              definitely scam.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='is_blacklisted'>
              The contract owner may add any address to the blacklist, and the token
              holder in the blacklist will not be able to trade. Abuse of the blacklist
              function will lead to great risks. For contracts without an owner (or the
              owner is a black hole address), the blacklist will not be able to get
              updated. However, the existing blacklist is still in effect.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='is_whitelisted'>
              Whitelisting is mostly used to allow specific addresses to make early
              transactions, tax-free, and not affected by transaction suspension. For
              contracts without an owner (or the owner is a black hole address), the
              whitelist will not be able to get updated. However, the existing whitelist
              is still in effect.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='is_anti_whale'>
              It describes whether the contract has the function to limit the maximum
              amount of transactions or the maximum token position for a single address.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='anti_whale_modifiable'>
              It describes whether the contract has the function to modify the maximum
              amount of transactions or the maximum token position.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='trading_cooldown'>
              It describes whether the contract has a trading-cool-down mechanism that can
              limit the minimum time between two transactions.
            </UncontrolledTooltip>

            <UncontrolledTooltip
              style={{
                border: ''
              }}
              placement='top'
              target='personal_slippage_modifiable'>
              The contract owner may set a very outrageous tax rate for an assigned
              address to block it from trading. Abuse of this function will lead to great
              risks.
            </UncontrolledTooltip>
          </>
        )}
      </div>
    </div>
  );
}

function HoldersInfo({ holders, holderCount, totalSupply, areLiquidityProviers }) {
  const [isChartView, setIsChartView] = useState(false);
  let ComponentToRender;
  switch (isChartView) {
    case true:
      ComponentToRender = BarChart;
      break;
    case false:
      ComponentToRender = HoldersTable;
      break;
    default:
      ComponentToRender = HoldersTable;
  }

  return (
    <>
      <Row className='m-0' style={{}}>
        <h3
          style={{
            flex: 5,
            width: '100%'
          }}>
          {areLiquidityProviers ? 'Liquidity Providers Info' : 'Holders Info'}
        </h3>
        <SwitchToggle
          labels={['Table', 'Chart']}
          isChecked={isChartView}
          onToggle={() => setIsChartView(!isChartView)}
        />
      </Row>
      <ComponentToRender
        holderCount={holderCount}
        dataArray={holders}
        totalSupply={areLiquidityProviers ? totalSupply : undefined}
      />
    </>
  );
}

function HoldersTable({ dataArray, holderCount, totalSupply }) {
  return (
    <>
      <h5>Total Number: {holderCount}</h5>
      {totalSupply && (
        <h5>Total Liquidity Supply: {shortenTheFloatingPoint(totalSupply, 2)}</h5>
      )}
      <Table
        style={{
          maxWidth: '1100px'
        }}>
        <thead className='text-primary'>
          <tr>
            <th>Address</th>
            <th>Percentage</th>
            <th>Balance</th>
          </tr>
        </thead>
        <tbody>
          {dataArray.map((item, index) => (
            <tr key={index} className='holders-table-row'>
              <td>{item.address}</td>
              <td>{shortenTheFloatingPoint(item.percent, 5)}</td>
              <td>{shortenTheFloatingPoint(item.balance, 2)}</td>
            </tr>
          ))}
        </tbody>
      </Table>
    </>
  );
}

function BarChart({ dataArray }) {
  const chartRef = useRef(null);

  const renderChart = () => {
    const biggestPercent = d3.max(dataArray, (d) => d.percent);
    const tickValues = Array.from(
      { length: 8 },
      (_, i) => Math.round((biggestPercent / 8) * (i + 1) * 100) / 100
    );
    const svg = d3.select(chartRef.current);
    const svgWidth = svg.node().getBoundingClientRect().width;
    svg.selectAll('*').remove();

    const margin = { top: 20, right: 20, bottom: 40, left: 150 };
    const width = svgWidth - margin.left - margin.right;
    const height = dataArray.length * 50 - margin.top - margin.bottom;

    const x = d3
      .scaleLinear()
      .domain([0, d3.max(dataArray, (d) => d.percent)])
      .range([margin.left, width - margin.right]);

    const y = d3
      .scaleBand()
      .domain(d3.map(dataArray, (d) => shortenTheString(d.address, 6)))
      .rangeRound([margin.top, height - margin.bottom])
      .padding(0.1);

    svg
      .attr('width', width + margin.left + margin.right)
      .attr('height', height + margin.top + margin.bottom)
      .append('g')
      .attr('transform', 'translate(' + margin.left + ',' + margin.top + ')');

    const defs = svg.append('defs');

    const gradient = defs
      .append('linearGradient')
      .attr('id', 'barGradient')
      .attr('x1', '0%')
      .attr('y1', '0%')
      .attr('x2', '100%')
      .attr('y2', '0%');

    gradient.append('stop').attr('offset', '0%').attr('stop-color', '#3765ebff');

    gradient.append('stop').attr('offset', '100%').attr('stop-color', '#1a3170ff');

    svg
      .append('g')
      .attr('fill', 'url(#barGradient)')
      .selectAll()
      .data(dataArray)
      .join('rect')
      .attr('x', x(0))
      .attr('y', (d) => y(shortenTheString(d.address, 6)))
      .attr('width', (d) => x(d.percent) - x(0))
      .attr('height', y.bandwidth())
      .on('mouseover', function (event, d) {
        d3.select(this)
          .transition()
          .duration(300)
          .attr('fill', '#3765ebff')
          .attr('cursor', 'pointer');
      })
      .on('mouseout', function (event, d) {
        d3.select(this).transition().duration(300).attr('fill', 'url(#barGradient)');
      })
      .on('click', () => {
        window.open('https://www.google.com', '_blank');
      });

    svg
      .append('g')
      .attr('fill', 'white')
      .attr('text-anchor', 'end')
      .attr('font-size', '0.7rem')
      .selectAll()
      .data(dataArray)
      .join('text')
      .attr('x', (d) => x(d.percent))
      .attr('y', (d) => y(shortenTheString(d.address, 6)) + y.bandwidth() / 2)
      .attr('dy', '0.35em')
      .attr('dx', -4)
      .text((d) => shortenTheFloatingPoint(d.balance, 2))
      .call((text) =>
        text
          .filter((d) => x(d.percent) - x(0) < 100)
          .attr('dx', +4)
          .attr('fill', 'black')
          .attr('text-anchor', 'start')
      );

    svg
      .append('g')
      .attr('transform', `translate(0,${margin.top})`)
      .call(
        d3
          .axisTop(x)
          .tickValues(tickValues)
          .tickFormat((x) => x + '%')
      )
      .call((g) => g.select('.domain').remove());

    svg
      .append('g')
      .attr('transform', `translate(${margin.left},0)`)
      .call(d3.axisLeft(y).tickSizeOuter(0));
  };

  useEffect(() => {
    renderChart();
  }, [dataArray]);

  useEffect(() => {
    window.addEventListener('resize', renderChart);
    return () => window.removeEventListener('resize', renderChart);
  }, []);

  return (
    <svg
      ref={chartRef}
      style={{ width: '100%', height: 'auto', maxHeight: '500px' }}></svg>
  );
}

function SwitchToggle({ labels = ['Off', 'On'], isChecked, onToggle }) {
  return (
    <div className='switch-toggle'>
      <span className='label-left'>{labels[0]}</span>
      <div className='toggle-container'>
        <input type='checkbox' id='toggle' checked={isChecked} onChange={onToggle} />
        <label htmlFor='toggle' className='toggle-label'>
          <span></span>
        </label>
      </div>
      <span className='label-right'>{labels[1]}</span>
    </div>
  );
}

function copyToClipboard(text) {
  const textarea = document.createElement('textarea');
  textarea.value = text;
  document.body.appendChild(textarea);
  textarea.select();
  document.execCommand('copy');
  document.body.removeChild(textarea);
}

function shortenTheString(str, length) {
  if (str.length > length) {
    return str.slice(0, length) + '...' + str.slice(str.length - length, str.length);
  } else {
    return str;
  }
}

function shortenTheFloatingPoint(num, length) {
  if (num.toString().includes('.')) {
    const [integer, decimal] = num.toString().split('.');
    return integer + '.' + decimal.slice(0, length);
  } else {
    return num;
  }
}

function normalizeDate(startDateStr, endDateStr) {
  if (!startDateStr) {
    if (!endDateStr) return new Date('9999-12-31').getTime();
    return 0;
  }
  const parts = startDateStr.split('-');

  let day = '01';
  let month = '01';
  let year = '1970';

  switch (parts.length) {
    case 1:
      year = parts[0];
      break;
    case 2:
      if (parts[1].length === 4) {
        month = parts[0];
        year = parts[1];
      } else {
        month = parts[1];
        year = parts[0];
      }
      break;
    case 3:
      day = parts[0];
      month = parts[1];
      year = parts[2];
      break;
    default:
      throw new Error('Invalid date format');
  }

  return new Date(`${year}-${month}-${day}`).getTime();
}

function sortByDate(arr) {
  console.log('-------arr--------', arr);
  return arr.sort((a, b) => {
    const normalizedA = normalizeDate(a.start_date, a.end_date);
    const normalizedB = normalizeDate(b.start_date, b.end_date);
    return normalizedA - normalizedB;
  });
}

export default TokenAnalytics;
