import moment from 'moment';
import { stringify } from 'query-string';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PayConfirm from './PayQuoteModal';
import RequestAction from '../../BusinessApplication/ViewOnRampApplication/RequestAction';
import AddQuestionAnswer from '../../BusinessApplication/ViewOnRampApplication/add_questions_answer';

function GetResponse({
  application,
  index,
  page,
  GetApplicationResponseFunc,
  applicationId
}) {
  const [subAppId, setSubApplication] = useState('');
  const [responseId, setResponseId] = useState('');
  const [modal, setModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false);
  const [answerModal, setAnswerModal] = useState(false);
  const toggle = () => {
    setModal(!modal);
  };
  const actionToggle = () => {
    setRequestModal(!requestModal);
  };

  const answerToggle = () => {
    setAnswerModal(!answerModal);
  };
  const ProfileIcon = ({ iconUrl }) => (
    <div style={{ padding: '5px' }}>
      {iconUrl && iconUrl.length > 0 ? (
        <img
          src={iconUrl}
          style={{ borderRadius: '50%' }}
          width={40}
          height={40}
          alt='user'
        />
      ) : (
        <i
          className='fa fa-user'
          style={{
            borderRadius: '50%',
            border: '1px solid #f0f0f0',
            width: '40px',
            height: '40px',
            background: '#f0f0f0',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center'
          }}></i>
      )}
    </div>
  );

  const statusSuggestions = [
    { id: 'REQUEST_ACTION', name: 'Action' },
    { id: 'ACCEPT', name: 'Accept' },
    { id: 'REJECT', name: 'Reject' },
    { id: 'KYB', name: 'KYB' },
    { id: 'QUOTE', name: 'Quote' },
    { id: 'PAID', name: 'Listd🎉' },
    { id: 'ADD_QUESTIONS', name: 'Add Question' },
    { id: 'PROCESSING', name: 'Processing' }
  ];

  const getSeverity = (status) => {
    const statusToSeverity = {
      REJECT: 'danger',
      ACCEPT: 'success',
      ADD_QUESTIONS: 'info',
      negotiation: 'warning',
      COMPLETE: 'success',
      QUOTE: 'secondary',
      renewal: 'secondary'
    };

    return statusToSeverity[status] || 'secondary';
  };

  const actionBodyTemplate = (rowData) => {
    if (rowData.applicationResponse.field) {
      return Object.keys(rowData.applicationResponse.field).length ===
        rowData.applicationResponse.completed_field.length ? (
        <div>Complete Action</div>
      ) : (
        <Link
          to={{
            pathname: '/sub-application/edit',
            search: `?${stringify({
              sub_application_id: rowData.applicationResponse.onramp_sub_application_id
            })}`,
            state: rowData.applicationResponse
          }}>
          <button
            style={{ width: 150 }}
            className='btn btn-status-change font-weight-bold'>
            Edit Application
          </button>
        </Link>
      );
    } else if (rowData.applicationResponse.status === 'ADD_QUESTIONS') {
      return rowData.applicationResponse.response_complete ? (
        <div>Complete Action</div>
      ) : (
        <div
          onClick={() => {
            answerToggle();
            setSubApplication(rowData._id);
            setResponseId(rowData.applicationResponse._id);
          }}
          style={{ width: 150 }}
          className='btn btn-status-change font-weight-bold'>
          Add Answer
        </div>
      );
    } else {
      return rowData.applicationResponse.status === 'QUOTE' &&
        rowData.applicationResponse.quote_price &&
        rowData.applicationResponse.quote_price.currency ? (
        <>
          <div
            onClick={() => {
              toggle();
              setSubApplication(rowData._id);
            }}
            style={{ width: 150 }}
            className='btn btn-status-change font-weight-bold'>
            Pay ${rowData.applicationResponse.quote_price.amount}
          </div>
        </>
      ) : rowData.applicationResponse.status === 'QUOTE' &&
        rowData.applicationResponse.quote_price &&
        rowData.applicationResponse.quote_price.crypto_currency ? (
        <>
          {rowData.application_status === 'PAID' ? (
            'Action Completed'
          ) : (
            <div
              onClick={() => {
                setSubApplication(rowData._id);
                const externalURL =
                  rowData.applicationResponse.payments_txs[0]?.invoiceLinks?.pay;
                const newTab = window.open(externalURL, '_blank');
                newTab.focus();
              }}
              style={{ width: 180 }}
              className='btn btn-status-change font-weight-bold'>
              Pay {rowData.applicationResponse.quote_price.amount}{' '}
              {rowData.applicationResponse.quote_price.crypto_currency.toUpperCase()}
            </div>
          )}
        </>
      ) : rowData.applicationResponse.status === 'REQUEST_ACTION' &&
        !rowData.applicationResponse.response_complete ? (
        <>
          {' '}
          <div
            onClick={() => {
              actionToggle();
              setResponseId(rowData.applicationResponse._id);
            }}
            className='btn btn-status-change font-weight-bold'>
            Complete Request Action
          </div>
        </>
      ) : (
        'Action Completed'
      );
    }
  };

  return (
    <>
      <td className='pl-4'>{index + 1 + (page - 1) * 10}</td>
      <td className='text-center'>
        <div>
          <ProfileIcon
            iconUrl={
              application &&
              application.applicationResponse &&
              application.applicationResponse.logo_url
                ? application.applicationResponse.logo_url
                : ''
            }
          />
          <span>
            {application.applicationResponse &&
            application.applicationResponse.on_ramp_name
              ? application.applicationResponse.on_ramp_name
              : ''}
          </span>
        </div>
      </td>
      <td className='pl-4 text-left'>
        {application &&
        application.applicationResponse &&
        application.applicationResponse.field &&
        Object.keys(application.applicationResponse.field).length > 0 ? (
          Object.keys(application.applicationResponse.field).map((value, index) => (
            <div
              className={
                application.applicationResponse.completed_field.includes(value)
                  ? 'text-success'
                  : 'text-warning'
              }
              key={index}>
              {index + 1}. {value}
            </div>
          ))
        ) : application &&
          application.application_status === 'ADD_QUESTIONS' &&
          application.add_questions &&
          application.add_questions.length > 0 ? (
          application.add_questions.map((que, index) => (
            <div key={index}>
              {index + 1}. {que.type}
            </div>
          ))
        ) : (
          <div>No fields available</div>
        )}
      </td>
      <td className='pl-4 text-left'>
        {application.applicationResponse.field &&
        Object.keys(application.applicationResponse.field).length > 0 ? (
          Object.entries(application.applicationResponse.field).map(([key, value]) => {
            if (value.length > 0) {
              const truncatedValue =
                String(value).length > 20
                  ? `${String(value).substring(0, 20)}...`
                  : String(value);
              return (
                <div key={key} data-tip={value}>
                  &#8226; {truncatedValue}
                </div>
              );
            } else {
              return <div key={key} data-tip={value}></div>;
            }
          })
        ) : application && application.applicationResponse.notes ? (
          String(application.applicationResponse.notes).length > 20 ? (
            `${String(application.applicationResponse.notes).substring(0, 20)}...`
          ) : (
            String(application.applicationResponse.notes)
          )
        ) : application &&
          application.application_status === 'ADD_QUESTIONS' &&
          application.add_questions &&
          application.add_questions.length > 0 ? (
          application.add_questions.map((que, index) => (
            <div key={index}>
              {index + 1}.
              {String(que.question).length > 20
                ? `${String(que.question).substring(0, 20)}
              ...`
                : String(que.question)}
            </div>
          ))
        ) : (
          <div>No notes available</div>
        )}
      </td>
      <td className='text-left' width={200}>
        {moment(application.applicationResponse.created_at).format('DD/MM/YYYY hh:mm A')}
      </td>
      <td className='pl-4 text-center'>
        <span
          className={`p-1 font-size-12 badge badge-pill badge-${getSeverity(
            application.status
          )}`}>
          {
            statusSuggestions.find(
              (status) => status.id === application.application_status
            ).name
          }
        </span>
      </td>
      <td className='pl-4'>{actionBodyTemplate(application)}</td>
      <PayConfirm
        modal={modal}
        handleModal={toggle}
        subAppId={subAppId}
        GetApplicationResponseFunc={GetApplicationResponseFunc}
        applicationId={applicationId}
      />
      <RequestAction
        modal={requestModal}
        handleModal={actionToggle}
        responseId={responseId}
        GetApplicationResponseFunc={GetApplicationResponseFunc}
        applicationId={applicationId}
      />
      <AddQuestionAnswer
        modal={answerModal}
        handleModal={answerToggle}
        subAppId={subAppId}
        responseId={responseId}
        GetApplicationResponseFunc={GetApplicationResponseFunc}
        applicationId={applicationId}
      />
    </>
  );
}

export default GetResponse;
