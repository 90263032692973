import { ErrorMessage } from 'formik';
import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

import {
  Card,
  CardBody,
  Col,
  Collapse,
  FormGroup,
  Input,
  Label,
  Modal,
  ModalHeader,
  Row
} from 'reactstrap';
import { constants as CONST } from '../../../../constants/application';
import { InvestmentHistoryInputName } from './helper';
import AuthInput from '../../../../Custom/AuthInput';
import { investorsListAPI } from '../../../../services/Admin/investors';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../../services/Business/Application';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';

function InvestorHistoryApp({
  allResponses = [],
  touched = {},
  errors = {},
  handleTextAmountChange = () => {},
  values = {},
  handleChangeInvestor = () => {},
  handleSubmitRound = () => {},
  handleLeadChange = () => {},
  displayinvestorData = false,
  setDisplayinvestorData = () => {},
  setFieldValue = () => {},
  investorsList = {},
  showRound,
  isSectionCollapsed,
  toggleSection,
  setErrors,
  displayinvestorListData = [],
  setDisplayinvestorListData,
  applicationId,
  setInvestorsList,
  setselectedRound,
  setShowRound,
  setInitialValuesFunc
}) {
  const [validationErrors, setValidationErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          InvestmentHistoryInputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);
  const validateFields = () => {
    let errors = {};
    if (!values.totalAmountStage) {
      errors.totalAmountStage = 'Total amount is required';
    }

    function convertToNumber(str) {
      const sanitizedString = str && str.replace(/[$,]/g, '');
      return parseInt(sanitizedString, 10);
    }
    const sumAmount = values.dynamicFields.reduce(
      (sum, field) =>
        sum + (isNaN(convertToNumber(field.amount)) ? 0 : convertToNumber(field.amount)),
      0
    );
    if (convertToNumber(values.totalAmountStage) <= sumAmount) {
      errors.totalAmountStage =
        'Total amount stage should be greater than the sum of all investor amounts';
    }

    values.dynamicFields.forEach((field, index) => {
      if (!field.amount) {
        errors[`dynamicFields.${index}.amount`] = 'Amount is Required';
      }
      if (!field.name) {
        errors[`dynamicFields.${index}.name`] = 'Name is Required';
      }
    });

    if (!values.tokenPriceStage) {
      errors.tokenPriceStage = 'Token price is required';
    }

    values.dynamicFields.forEach((field, index) => {
      if (!field.name) {
        errors[`dynamicFields.${index}.name`] = 'Name is required';
      }
      if (!field.amount) {
        errors[`dynamicFields.${index}.amount`] = 'Amount is required';
      }
    });

    setValidationErrors(errors);
    return Object.keys(errors).length === 0;
  };
  const [initialValue, setInitialValue] = useState({
    selectRound: '',
    totalAmountStage: '',
    tokenPriceStage: '',
    dynamicFields: [{ name: '', amount: '', lead: false, tier: '' }]
  });
  const handleChangeOpt = (selectedOption, setFieldValue) => {
    setFieldValue('selectRound', selectedOption);
    setselectedRound(selectedOption.value);
    if (selectedOption.id === 0) {
      setDisplayinvestorData(false);
    } else {
      setDisplayinvestorData(true);
    }
  };
  const allOptions = [
    { id: 0, value: 'n/a', label: 'N/A' },
    { id: 1, value: 'seed', label: 'Seed' },
    { id: 2, value: 'series_a', label: 'Series A' },
    { id: 3, value: 'series_b', label: 'Series B' },
    { id: 4, value: 'series_c', label: 'Series C' },
    { id: 5, value: 'series_d', label: 'Series D' },
    { id: 6, value: 'series_e', label: 'Series E' },
    { id: 7, value: 'late_stage_round', label: 'Late Stage Round' },
    { id: 8, value: 'ipo', label: 'IPO' }
  ];
  const [options, setOptions] = useState([
    { id: 0, value: 'n/a', label: 'N/A' },
    { id: 1, value: 'seed', label: 'Seed' },
    { id: 2, value: 'series_a', label: 'Series A' },
    { id: 3, value: 'series_b', label: 'Series B' },
    { id: 4, value: 'series_c', label: 'Series C' },
    { id: 5, value: 'series_d', label: 'Series D' },
    { id: 6, value: 'series_e', label: 'Series E' },
    { id: 7, value: 'late_stage_round', label: 'Late Stage Round' },
    { id: 8, value: 'ipo', label: 'IPO' }
  ]);
  const [errorShow, setErrorShow] = useState(false);
  const [updateRound, setUpdateRound] = useState(false);

  useEffect(() => {
    investorsListAPI()
      .then((res) => {
        let list = [];
        res.data.data.data.map((e, i) => {
          let obj = {
            label: e.name,
            value: e.name,
            tier: e.tier
          };
          list.push(obj);
        });
        setInvestorsList(list);
      })
      .catch((err) => {});
    if (applicationId !== '') {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.investment_stages.length > 0) {
          setShowRound(true);
          setDisplayinvestorListData(application.exchange_application.investment_stages);
        }
      });
    }
  }, []);

  useEffect(() => {
    if (displayinvestorListData.length > 0) {
      let displayInvestorsList = displayinvestorListData.map((i) => i.round);
      let updatedRound = options.filter(
        (i) => !displayInvestorsList.includes(i.value) && i.id !== 0
      );
      setOptions([...updatedRound]);
    }
  }, [displayinvestorListData]);
  useEffect(() => {
    const res = Object.keys(errors).find((i) => InvestmentHistoryInputName.includes(i));
    setErrorShow(!!res);
  }, [touched, errors]);

  function convertToNumber(str) {
    const sanitizedString = str.replace(/[,]/g, '');
    return parseInt(sanitizedString, 10);
  }

  const addField = (setFieldValue, values) => {
    if (
      values.dynamicFields[values.dynamicFields.length - 1].amount !== '' &&
      values.dynamicFields[values.dynamicFields.length - 1].name !== ''
    ) {
      let arr = { ...values };
      arr.dynamicFields.push({ name: '', amount: '', lead: false, tier: '' });
      setFieldValue(arr);
    } else {
      let arr = { ...values };
      arr.dynamicFields.push({ name: '', amount: '', lead: false, tier: '' });
      setFieldValue(arr);
    }
  };

  const removeField = (index, setFieldValue, values) => {
    let arr = { ...values };
    arr.dynamicFields.splice(index, 1);
    setFieldValue(arr);
  };

  const handleSubmit = () => {
    if (values.selectRound.id !== 0 && values.selectRound) {
      setIsLoading(true);
      if (displayinvestorListData[0]['round'] === '') {
        return;
      }
      let val = values.dynamicFields[values.dynamicFields.length - 1];
      if (
        (displayinvestorData && val.name !== '' && val.amount !== '') ||
        !displayinvestorData
      ) {
        const data = {
          investment_stages: JSON.stringify(displayinvestorListData)
        };
        JSON.stringify(data);

        UpdateExchangeApplication({ application_id: applicationId }, data)
          .then((res) => {
            const application = get(res, 'data.data.data', '');
            setIsLoading(false);
            setInitialValuesFunc(application.exchange_application);
            notifySuccess(`Investment history data updated successfully`);
          })
          .catch((err) => {
            const errorMessage = get(err, 'data.error.message');
            notifyError(notificationMessageFunction(errorMessage));
          });
      }
    } else {
      notifySuccess(`Investment history data updated successfully`);
    }
  };

  const updateInvestorData = (value) => {
    const newInvestorList = displayinvestorListData.map((item) =>
      item.round === value.selectRound
        ? {
            round: value.selectRound,
            token_price: value.tokenPriceStage,
            token_size: value.totalAmountStage,
            all_investors: value.dynamicFields
          }
        : item
    );
    setDisplayinvestorListData(newInvestorList);
    setUpdateRound(false);
  };

  const handleRemoveField = (index) => {
    const updatedQuestionsData = [...displayinvestorListData];
    const removeField = updatedQuestionsData[index];
    const findValue = allOptions.find((opt) => opt.value === removeField.round);
    updatedQuestionsData.splice(index, 1);
    setDisplayinvestorListData(updatedQuestionsData);
    let NAOption = [];
    if (displayinvestorListData && displayinvestorListData.length === 1) {
      NAOption = [{ id: 0, value: 'n/a', label: 'N/A' }];
    }
    const sortedData = [findValue, ...options, ...NAOption].sort((a, b) => {
      const indexA = allOptions.findIndex((item) => item.id === a.id);
      const indexB = allOptions.findIndex((item) => item.id === b.id);

      if (indexA < indexB) {
        return -1;
      }
      if (indexA > indexB) {
        return 1;
      }

      return 0;
    });

    setOptions(sortedData);
  };

  return (
    <Card
      style={{
        border: `1px solid rgb(128 128 128 / 30%)`,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Investment History</span>
          {/* {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )} */}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <div>
                <Row>
                  <Col lg={12} md={12} sm={12}>
                    <FormGroupSelectRound
                      errors={errors}
                      name='selectRound'
                      handleChangeOpt={(selectedOption) =>
                        handleChangeOpt(selectedOption, setFieldValue)
                      }
                      options={options}
                      displayinvestorListData={displayinvestorListData}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12}>
                    {displayinvestorData && (
                      <InvestorData
                        updateRound={updateRound}
                        setUpdateRound={setUpdateRound}
                        values={values}
                        setErrors={setErrors}
                        errors={errors}
                        touched={touched}
                        investorsList={investorsList}
                        setFieldValue={setFieldValue}
                        handleSubmitRound={handleSubmitRound}
                        displayinvestorListData={displayinvestorListData}
                        handleChangeInvestor={handleChangeInvestor}
                        handleLeadChange={handleLeadChange}
                        handleTextAmountChange={handleTextAmountChange}
                        addField={addField}
                        setDisplayinvestorData={setDisplayinvestorData}
                        removeField={removeField}
                        updateInvestorData={updateInvestorData}
                        validateFields={validateFields}
                        validationErrors={validationErrors}
                      />
                    )}
                  </Col>
                </Row>
              </div>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  {showRound && (
                    <div className='pt-3 pb-1 mt-4'>
                      {displayinvestorListData.map((round, index) => (
                        <div className='round-info' key={index}>
                          <span
                            key={index}
                            className='d-flex justify-content-between font-size-12'>
                            <u>
                              <span className='font-size-14'>
                                {allOptions.find((opt) => opt.value === round.round)
                                  ?.label || 'Unknown'}{' '}
                                Round
                              </span>
                            </u>

                            <div className='d-flex'>
                              <i
                                className='mdi mdi-pencil font-size-20 mr-1 cursor-pointer'
                                onClick={() => {
                                  setInitialValue({
                                    selectRound: round.round,
                                    totalAmountStage: round.token_size,
                                    tokenPriceStage: round.token_price,
                                    dynamicFields: round.all_investors
                                  });
                                  setUpdateRound(!updateRound);
                                }}></i>
                              <i
                                className='mdi mdi-close font-size-22 cursor-pointer'
                                onClick={() => handleRemoveField(index)}
                              />
                            </div>

                            {updateRound && (
                              <UpdateRoundModal
                                updateRound={updateRound}
                                setUpdateRound={setUpdateRound}
                                values={{
                                  selectRound: round.round,
                                  totalAmountStage: round.token_size,
                                  tokenPriceStage: round.token_price,
                                  dynamicFields: round.all_investors
                                }}
                                setErrors={setErrors}
                                errors={errors}
                                touched={touched}
                                investorsList={investorsList}
                                setFieldValue={setFieldValue}
                                handleSubmitRound={handleSubmitRound}
                                displayinvestorListData={displayinvestorListData}
                                handleChangeInvestor={handleChangeInvestor}
                                handleLeadChange={handleLeadChange}
                                handleTextAmountChange={handleTextAmountChange}
                                addField={addField}
                                setDisplayinvestorData={setDisplayinvestorData}
                                removeField={removeField}
                                updateInvestorData={updateInvestorData}
                                validateFields={validateFields}
                                validationErrors={validationErrors}
                              />
                            )}
                          </span>

                          <span className='total-price mt-0 font-size-12'>
                            {round.token_size} total
                          </span>
                          <br />
                          <span className='token-price mt-1 font-size-12'>
                            {round.token_price} per token
                          </span>
                          <div className='investor-list mt-2'>
                            <span className='font-size-12'>Investors</span>
                            <div className='table-responsive'>
                              <table className='table table-centered table-hover'>
                                <thead className='font-size-12'>
                                  <tr>
                                    <th>Investor Name</th>
                                    <th>Investment Amount</th>
                                    <th>Lead Investor?</th>
                                  </tr>
                                </thead>
                                <tbody className='font-size-12'>
                                  {round.all_investors.map((input, idx) => (
                                    <tr key={idx}>
                                      <td>{input.name}</td>
                                      <td>{input.amount}</td>
                                      <td>{input.lead ? 'True' : 'False'}</td>
                                    </tr>
                                  ))}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  )}
                </Col>
              </Row>

              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default InvestorHistoryApp;

const InvestorData = ({
  values,
  errors,
  touched,
  investorsList,
  setFieldValue,
  handleSubmitRound,
  displayinvestorListData,
  handleChangeInvestor,
  handleLeadChange,
  handleTextAmountChange,
  addField,
  setDisplayinvestorData,
  removeField,
  isEdit = false,
  updateInvestorData,
  validateFields,
  validationErrors
}) => {
  return (
    <div className={`pt-3 pl-4 pb-5 ${!isEdit && 'investor_box'}`}>
      <Row>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mr-4 pr-4'>
            <div className='font-size-12'>
              <Label htmlFor='totalAmountStage' className='text-black font-weight-normal'>
                {CONST.TOKEN_SIZE}
              </Label>
            </div>
            <div>
              <AuthInput
                type='text'
                name='totalAmountStage'
                className='font-size-12'
                values={values}
                placeholder={'Enter total amount of invesment stage'}
                handleChange={(e) => {
                  if (e.target.value.endsWith('.')) {
                    setFieldValue('totalAmountStage', e.target.value);
                    return;
                  }

                  const rawValue = e.target.value.replace(/[^0-9.]/g, '');

                  const decimalValue =
                    rawValue.split('.').length > 2
                      ? rawValue.slice(0, rawValue.lastIndexOf('.'))
                      : rawValue;

                  const parts = decimalValue.split('.');
                  const formattedValue =
                    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                    (parts[1] ? '.' + parts[1] : '');

                  if (formattedValue) {
                    setFieldValue('totalAmountStage', '$' + formattedValue);
                  } else {
                    setFieldValue('totalAmountStage', '$0');
                  }
                }}
                errors={validationErrors}
                touched={touched}
              />
            </div>
          </FormGroup>
        </Col>
        <Col lg={6} md={12} sm={12}>
          <FormGroup className='mr-4 pr-4'>
            <div className='font-size-12'>
              <Label htmlFor='tokenPriceStage' className='text-black font-weight-normal'>
                {CONST.TOKEN_PRICE}
              </Label>
            </div>
            <div>
              <AuthInput
                type='text'
                name='tokenPriceStage'
                className='font-size-12'
                values={values}
                placeholder={'Enter token price'}
                handleChange={(e) => {
                  if (e.target.value.endsWith('.')) {
                    setFieldValue('tokenPriceStage', e.target.value);
                    return;
                  }

                  const rawValue = e.target.value.replace(/[^0-9.]/g, '');

                  const decimalValue =
                    rawValue.split('.').length > 2
                      ? rawValue.slice(0, rawValue.lastIndexOf('.'))
                      : rawValue;

                  const parts = decimalValue.split('.');
                  const formattedValue =
                    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                    (parts[1] ? '.' + parts[1] : '');

                  if (formattedValue) {
                    setFieldValue('tokenPriceStage', '$' + formattedValue);
                  } else {
                    setFieldValue('tokenPriceStage', '$0');
                  }
                }}
                errors={validationErrors}
                touched={touched}
              />
            </div>
          </FormGroup>
        </Col>
      </Row>

      <Row>
        <Col lg={12} md={12} sm={12}>
          <div className='font-size-14'>
            <Label className='text-black font-weight-normal'>
              <u>{CONST.ADD_INVESTORS}</u>
            </Label>
          </div>
        </Col>
      </Row>

      {values.dynamicFields.map((input, index) => (
        <React.Fragment key={index}>
          <Row className='mb-2 pr-5'>
            <Col lg={4} md={12} sm={12}>
              <FormGroup className='font-size-12'>
                <Label htmlFor='investors_name' className='text-black font-weight-normal'>
                  Name
                </Label>
                <CreatableSelect
                  options={investorsList}
                  className='font-size-12'
                  value={investorsList.find((e, i) => e.value === input.name)}
                  placeholder='Type or select an investor'
                  onChange={(selectedOption) =>
                    handleChangeInvestor(selectedOption, index, values, setFieldValue)
                  }
                  isClearable
                />
                <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
                  {validationErrors[`dynamicFields.${index}.name`] || ''}
                </div>
              </FormGroup>
            </Col>

            <Col lg={4} md={12} sm={12}>
              <FormGroup className='font-size-12'>
                <Label
                  htmlFor='investors_amount'
                  className='text-black font-weight-normal'>
                  Amount
                </Label>
                <Input
                  type='text'
                  placeholder='Enter investment amount'
                  name={`dynamicFields.${index}.amount`}
                  value={input.amount}
                  className='font-size-12'
                  onChange={(e) =>
                    handleTextAmountChange(index, e.target.value, values, setFieldValue)
                  }
                />

                <div style={{ fontSize: 12 }} className='text-left mt-1 text-danger'>
                  {validationErrors[`dynamicFields.${index}.amount`]}{' '}
                </div>
              </FormGroup>
            </Col>

            <Col
              lg={2}
              md={2}
              sm={2}
              className='d-flex align-items-center mt-4 justify-content-center'>
              <FormGroup className='font-size-12'>
                <Input
                  type='checkbox'
                  value={input.lead}
                  name={`${index}.lead`}
                  className='font-size-12 mr-3'
                  onChange={(e) =>
                    handleLeadChange(index, e.target.checked, values, setFieldValue)
                  }
                />
                <Label
                  htmlFor='investors_amount'
                  className='text-black font-weight-normal'>
                  Lead
                </Label>
                <ErrorMessage
                  name={`${index}.lead`}
                  component='div'
                  className='error font-size-12'
                />
              </FormGroup>
            </Col>

            <div className='d-flex align-items-center font-size-20 mt-1'>
              {index !== 0 && (
                <Col lg={1} md={1} sm={1}>
                  <i
                    className='fas fa-trash-alt cursor-pointer mr-1'
                    style={{ color: 'red' }}
                    onClick={() => removeField(index, setFieldValue, values)}></i>
                </Col>
              )}
              {index === 0 &&
                values.dynamicFields &&
                values.dynamicFields.length <= 0 && (
                  <Col lg={1} md={1} sm={1} className='font-size-22 ml-1'>
                    <i
                      className='mdi mdi-plus-circle-outline cursor-pointer'
                      onClick={(event) => {
                        addField(setFieldValue, values);
                      }}></i>
                  </Col>
                )}
              {index === values.dynamicFields.length - 1 && (
                <Col lg={1} md={1} sm={1} className='font-size-22 ml-1'>
                  <i
                    className='mdi mdi-plus-circle-outline cursor-pointer'
                    onClick={(event) => {
                      addField(setFieldValue, values);
                    }}></i>
                </Col>
              )}
            </div>
            <hr
              style={{
                borderColor: 'grey',
                borderWidth: '1px',
                width: '100%'
              }}
            />
          </Row>
        </React.Fragment>
      ))}
      <div className='d-flex align-items-center justify-content-center'>
        {isEdit ? (
          <button
            type='submit'
            onClick={() => {
              updateInvestorData(values);
            }}
            className='btn btn-auth'
            style={{ width: 150, height: 40 }}>
            Edit Round
          </button>
        ) : (
          <>
            <button
              type='submit'
              onClick={() => {
                if (validateFields()) {
                  handleSubmitRound(values, setFieldValue);
                }
              }}
              className='btn btn-auth'
              style={{ width: 150, height: 40 }}>
              Add Round
            </button>
            {displayinvestorListData.length > 0 && (
              <button
                type='submit'
                onClick={() => {
                  setDisplayinvestorData(false);
                }}
                className='btn btn-auth ml-2'
                style={{ width: 150, height: 40 }}>
                Clear
              </button>
            )}
          </>
        )}
      </div>
    </div>
  );
};

const FormGroupSelectRound = ({
  errors,
  name,
  handleChangeOpt,
  options,
  displayinvestorListData
}) => (
  <FormGroup>
    <Label htmlFor={name} className='text-black font-weight-normal font-size-12'>
      {CONST.ROUND}
    </Label>
    {displayinvestorListData && displayinvestorListData.length === 0 ? (
      <Select
        options={options}
        name={name}
        defaultValue={
          displayinvestorListData.length === 0
            ? options.find((item) => item.id === 0)
            : ''
        }
        onChange={handleChangeOpt}
        className='font-size-12'
      />
    ) : (
      <Select
        options={options}
        name={name}
        onChange={handleChangeOpt}
        className='font-size-12'
      />
    )}
    <ErrorMessage
      name={name}
      component='div'
      className='text-left mt-1 text-danger font-size-12'
    />
  </FormGroup>
);

const UpdateRoundModal = ({
  updateRound,
  setUpdateRound,
  values,
  setErrors,
  errors,
  touched,
  investorsList,
  setFieldValue,
  handleSubmitRound,
  displayinvestorListData,
  handleChangeInvestor,
  handleLeadChange,
  handleTextAmountChange,
  addField,
  setDisplayinvestorData,
  removeField,
  updateInvestorData,
  setInitialValue,
  validateFields,
  validationErrors
}) => {
  return (
    <Modal
      isOpen={updateRound}
      toggle={() => {
        setUpdateRound(!updateRound);
        setInitialValue({
          selectRound: '',
          totalAmountStage: '',
          tokenPriceStage: '',
          dynamicFields: [{ name: '', amount: '', lead: false, tier: '' }]
        });
      }}
      aria-labelledby='contained-modal-title-vcenter'
      centered
      style={{ borderRadius: '8px', maxWidth: '800px' }}>
      <ModalHeader toggle={() => setUpdateRound(!updateRound)}>Update Round</ModalHeader>
      <InvestorData
        values={values}
        setErrors={setErrors}
        errors={errors}
        touched={touched}
        investorsList={investorsList}
        setFieldValue={setFieldValue}
        handleSubmitRound={handleSubmitRound}
        displayinvestorListData={displayinvestorListData}
        handleChangeInvestor={handleChangeInvestor}
        handleLeadChange={handleLeadChange}
        handleTextAmountChange={handleTextAmountChange}
        addField={addField}
        setDisplayinvestorData={setDisplayinvestorData}
        removeField={removeField}
        isEdit={true}
        updateInvestorData={updateInvestorData}
        validateFields={validateFields}
        validationErrors={validationErrors}
      />
    </Modal>
  );
};
