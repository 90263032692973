import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import documentUploadIcon from '../../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../../assets/images/documentuploaded.png';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { TokenomicsInputName } from './helper';
import AuthInput from '../../../../Custom/AuthInput';
import { constants as CONST } from '../../../../constants/application';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';

function TokenomicsApp({
  allResponses = [],
  values = {},
  fileUrls = {},
  errors = {},
  touched = {},
  setFieldValue = () => {},
  handleChange = () => {},
  setFieldTouched = () => {},
  isSectionCollapsed,
  toggleSection,
  applicationId,
  setInitialValuesFunc
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);

  useEffect(() => {
    const res = Object.keys(errors).find((i) => TokenomicsInputName.includes(i));
    setErrorShow(!!res);
  }, [errors]);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          TokenomicsInputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);

  const handleSubmit = () => {
    if (
      !TokenomicsInputName.includes(
        Object.keys(errors).find((i) => TokenomicsInputName.includes(i))
      )
    ) {
      const payloadData = new FormData();
      setIsLoading(true);
      payloadData.append('token_use_case', values.token_use_case);
      payloadData.append('tokenomics_doc', values.tokenomics_doc);
      payloadData.append('token_distribution_doc', values.token_distribution_doc);

      UpdateExchangeApplication({ application_id: applicationId }, payloadData)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          notifySuccess(`Tokenomics updated successfully`);

          setIsLoading(false);
          setInitialValuesFunc(application.exchange_application);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Tokenomics</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <Row>
                <Col lg={6}>
                  <div className='font-size-12'>
                    <Label
                      htmlFor='description'
                      className='text-black font-weight-normal'>
                      Tokenomics
                    </Label>
                  </div>
                </Col>
                <Col lg={12}>
                  <Row>
                    <Col lg={6}>
                      <div
                        style={{
                          borderRadius: '8px',
                          border: '1px solid',
                          height: 47
                        }}
                        className='d-flex direction-row justify-content-between mb-2'>
                        <div className='d-flex direction-row justify-content-between file-upload-wrapper pr-2'>
                          <Label
                            htmlFor='tokenomics'
                            id='tokenomics_doc'
                            className='mb-0 pl-3'>
                            <span
                              className='p-1 mb-2 rounded-circle'
                              style={{
                                color: '#134a87',
                                fontSize: 14,
                                cursor: 'pointer'
                              }}>
                              <img
                                src={
                                  !values?.tokenomics_doc
                                    ? documentUploadIcon
                                    : documentUploadedIcon
                                }
                                alt='tokenomics'></img>
                            </span>
                            <span className='font-size-12'>Tokenomics</span>
                          </Label>
                          {fileUrls.tokenomics_doc && (
                            <a
                              href={fileUrls.tokenomics_doc}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                              View File
                            </a>
                          )}
                          <Input
                            type='file'
                            accept='.pdf'
                            id='tokenomics'
                            name='tokenomics_doc'
                            onChange={(event) => {
                              setFieldValue(
                                'tokenomics_doc',
                                event.currentTarget.files[0]
                              );
                            }}
                            style={{ display: 'none' }}
                          />
                          <ReactTooltip
                            anchorId='tokenomics_doc'
                            place='bottom'
                            content={
                              fileUrls['tokenomics_doc'] &&
                              fileUrls['tokenomics_doc'].name
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6} className=''>
                      <div
                        style={{
                          borderRadius: '8px',
                          border: '1px solid',
                          height: 47
                        }}
                        className='d-flex direction-row justify-content-between'>
                        <div className='d-flex direction-row justify-content-between file-upload-wrapper pr-2'>
                          <Label
                            htmlFor='token_distribution'
                            id='token_distribution_doc'
                            className='mb-0 pl-3'>
                            <span
                              className='p-1 mb-2 rounded-circle'
                              style={{
                                color: '#134a87',
                                fontSize: 15,
                                cursor: 'pointer'
                              }}>
                              <img
                                src={
                                  !values?.token_distribution_doc
                                    ? documentUploadIcon
                                    : documentUploadedIcon
                                }
                                alt='IntegrationFlows'></img>
                            </span>
                            <span className='font-size-12'>Token Distribution</span>
                          </Label>
                          {fileUrls.token_distribution_doc && (
                            <a
                              href={fileUrls.token_distribution_doc}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                              View File
                            </a>
                          )}
                          <Input
                            type='file'
                            accept='.pdf,.xls,.xlsx'
                            id='token_distribution'
                            name='token_distribution_doc'
                            className='font-size-12'
                            onChange={(event) => {
                              setFieldValue(
                                'token_distribution_doc',
                                event.currentTarget.files[0]
                              );
                            }}
                            style={{ display: 'none' }}
                          />
                          <ReactTooltip
                            anchorId='token_distribution_doc'
                            place='bottom'
                            content={
                              fileUrls['token_distribution_doc'] &&
                              fileUrls['token_distribution_doc'].name
                            }
                          />
                        </div>
                      </div>
                    </Col>
                    <Col lg={6}>
                      {errors.tokenomics_doc && touched.tokenomics_doc && (
                        <div
                          className='text-left mt-1 text-danger'
                          style={{
                            cursor: 'pointer',
                            fontSize: '12px'
                          }}>
                          {errors.tokenomics_doc}
                        </div>
                      )}
                    </Col>{' '}
                  </Row>
                  <Row>
                    <Col lg={6}>
                      {errors.token_distribution_doc &&
                        touched.token_distribution_doc && (
                          <div
                            className='text-left mt-1 text-danger'
                            style={{
                              cursor: 'pointer',
                              fontSize: ' 14px'
                            }}>
                            {errors.token_distribution_doc}
                          </div>
                        )}
                    </Col>
                  </Row>
                </Col>
                <Col lg={12}>
                  <FormGroup className='mb-2 mt-2'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='token_use_case'
                        className='text-black font-weight-normal'>
                        {CONST.USE_CASES_OF_TOKENS}
                      </Label>
                    </div>
                    <div>
                      <AuthInput
                        setFieldTouched={setFieldTouched}
                        withTouchError={true}
                        type='textarea'
                        className='font-size-12'
                        name='token_use_case'
                        values={values}
                        handleChange={handleChange}
                        placeholder={CONST.ENTER_DESCRIPTION}
                        errors={errors}
                        touched={touched}></AuthInput>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default TokenomicsApp;
