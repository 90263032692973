import React, { useEffect, useState } from 'react';
import { GetReferralDetailAPI, ReferralListAPI } from '../../services/Auth';
import get from 'lodash/get';
import { notifyError } from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { Paginator } from 'primereact/paginator';
import moment from 'moment';
import _ from 'lodash';
import { Collapse } from 'reactstrap';

function BusinessReferrals() {
  const [allReferrals, setAllReferrals] = useState([]);
  const [currentPageData, setCurrentPageData] = useState([]);
  const [first, setFirst] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [loading, setLoading] = useState(true);
  const [page, setPage] = useState(1);
  const [activeId, setActiveId] = useState(null);
  const [referralDetail, setReferralDetail] = useState({});

  const getReferralsDetailFunction = () => {
    GetReferralDetailAPI()
      .then((res) => {
        const referralData = res && res.data && res.data.data && res.data.data.data;
        setReferralDetail(referralData);
      })
      .catch(() => {});
  };

  useEffect(() => {
    getReferralsDetailFunction();
  }, []);

  const onPageChange = (event) => {
    setFirst(event.first);
    setPage(event.page + 1);
    const newPageData = allReferrals.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  const onClick = (id) => {
    if (activeId === id) {
      return setActiveId(null);
    }

    setActiveId(id);
  };

  const referralListFunction = () => {
    ReferralListAPI()
      .then((res) => {
        const responses = get(res, 'data.data.data', []);
        const count = get(res, 'data.data.count', []);
        const slicedData = get(res, 'data.data.data', []).slice(first, first + 10);
        setCurrentPageData(slicedData);
        setTotalCount(count);
        setLoading(false);
        setAllReferrals(responses);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    referralListFunction();
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <div className='card'>
          <div className='p-4'>
            <div className='col-12 col-sm-auto font-size-24 font-weight-medium pb-2 pb-sm-0'>
              <div className='d-flex justify-content-between'>
                <span className='text-black'>Referred Users</span>
              </div>
              <div className='table-responsive'>
                <table className='table table-centered table-hover'>
                  <thead className='center-text'>
                    <tr className='font-size-14 text-muted'>
                      <th className='pl-4 pt-3 pb-4 border-0'>#</th>
                      <th className='pt-3 pb-4 border-0'>Company Name</th>
                      <th className='pt-3 pb-4 border-0'>Email</th>
                      <th className='pt-3 pb-4 border-0'>Referral Code</th>
                      <th className='pt-3 pb-4 border-0'>Onramp App.</th>
                      <th className='pt-3 pb-4 border-0'>Exchange App.</th>
                      <th className='pt-3 pb-4 border-0'>Complete</th>
                      <th className='pt-3 pb-4 border-0'>Incomplete</th>
                      <th className='pt-3 pb-4 border-0'>Processing</th>
                      <th className='pt-3 pb-4 border-0'>Accept</th>
                      <th className='pt-3 pb-4 border-0'>Reject</th>
                      <th className='pt-3 pb-4 border-0'>KYB</th>
                      <th className='pt-3 pb-4 border-0'>Quote</th>
                      <th className='pt-3 pb-4 border-0'>Paid</th>
                      <th className='pt-3 pb-4 border-0'>Referred at</th>
                      <th className='pt-3 pb-4 border-0'></th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan={18}>
                          <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                            <i
                              className='fas fa-circle-notch fa-spin text-muted'
                              style={{ fontSize: 48 }}></i>
                          </div>
                        </td>
                      </tr>
                    ) : (
                      <>
                        {currentPageData && currentPageData.length > 0 ? (
                          currentPageData.map((items, index) => (
                            <React.Fragment key={index}>
                              <tr
                                onClick={() => onClick(index)}
                                className='center-text font-size-14 font-weight-medium cursor-pointer'>
                                <td>{index + 1 + (page - 1) * 10}</td>
                                <td>{items.company_name}</td>
                                <td>{items.email}</td>
                                <td>{items.referred_by_code}</td>
                                <td>
                                  <CommonParentApplicationStatus
                                    items={items.applications}
                                    status={'ONRAMP'}
                                  />
                                </td>
                                <td>
                                  <CommonParentApplicationStatus
                                    items={items.applications}
                                    status={'EXCHANGE'}
                                  />
                                </td>
                                <td>
                                  <CommonParentApplicationStatus
                                    items={items.applications}
                                    status={'COMPLETE'}
                                  />
                                </td>
                                <td>
                                  <CommonParentApplicationStatus
                                    items={items.applications}
                                    status={'INCOMPLETE'}
                                  />
                                </td>
                                <td>
                                  <CommonApplicationStatus
                                    items={items.applications}
                                    status={'PROCESSING'}
                                  />
                                </td>
                                <td>
                                  <CommonApplicationStatus
                                    items={items.applications}
                                    status={'ACCEPT'}
                                  />
                                </td>
                                <td>
                                  <CommonApplicationStatus
                                    items={items.applications}
                                    status={'REJECT'}
                                  />
                                </td>
                                <td>
                                  <CommonApplicationStatus
                                    items={items.applications}
                                    status={'KYB'}
                                  />
                                </td>
                                <td>
                                  <CommonApplicationStatus
                                    items={items.applications}
                                    status={'QUOTE'}
                                  />
                                </td>
                                <td>
                                  <CommonApplicationStatus
                                    items={items.applications}
                                    status={'PAID'}
                                  />
                                </td>
                                <td>{moment(items.created_at).format('DD MMM YYYY')}</td>
                                <td>
                                  {items.applications &&
                                  items.applications.length === 1 &&
                                  Object.keys(items.applications[0].exchange_application)
                                    .length === 0 &&
                                  items.applications[0].sub_application.length === 0 ? (
                                    ''
                                  ) : (
                                    <label
                                      data-event={index}
                                      key={index}
                                      style={{ fontSize: '25px', cursor: 'pointer' }}
                                      className='pl-3 mt-1'>
                                      <i
                                        className={`mdi mdi-chevron-${
                                          activeId === index ? 'up' : 'down'
                                        }`}
                                      />{' '}
                                    </label>
                                  )}
                                </td>
                              </tr>
                              <tr>
                                {items.applications &&
                                items.applications.length === 1 &&
                                Object.keys(items.applications[0].exchange_application)
                                  .length === 0 &&
                                items.applications[0].sub_application.length === 0 ? (
                                  ''
                                ) : (
                                  <td colSpan={18}>
                                    <SubApplications
                                      applications={items.applications}
                                      activeId={activeId}
                                      index={index}
                                    />
                                  </td>
                                )}
                              </tr>
                            </React.Fragment>
                          ))
                        ) : (
                          <tr className='center-text'>
                            <td colSpan={18} className='font-size-14'>
                              {referralDetail && referralDetail.subdomain ? (
                                <>
                                  Share link to your clients{' '}
                                  <a
                                    href={`https://${referralDetail.subdomain}.listus.xyz`}
                                    style={{ color: 'blue' }}
                                    rel='noreferrer'
                                    target='_blank'>
                                    https://
                                    {referralDetail && referralDetail.subdomain
                                      ? referralDetail.domain
                                      : ''}
                                  </a>
                                </>
                              ) : (
                                'No User found'
                              )}
                            </td>
                          </tr>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <Paginator
            rows={10}
            totalRecords={totalCount}
            first={first}
            onPageChange={(e) => onPageChange(e)}
          />
        </div>
      </div>
    </React.Fragment>
  );
}

export default BusinessReferrals;

export const CommonApplicationStatus = ({ items, status }) => {
  const applications = _.chain(items)
    .flatMap('sub_application')
    .filter((app) =>
      status === 'KYB'
        ? app.application_status === status ||
          app.application_status === 'ADD_QUESTIONS' ||
          app.application_status === 'REQUEST_ACTION'
        : app.application_status === status
    )
    .uniqBy('onramp_user_id')
    .value();

  const additionalApplicationsCount = applications.length - 3;
  const showAdditionalCount = additionalApplicationsCount > 0;
  return (
    <div className='d-flex justify-content-center'>
      {applications && applications.length > 0 ? (
        <>
          {applications.slice(0, 3).map((application, index) => (
            <span key={application._id} className='mr-2'>
              {application.exchange_logo_url ? (
                <img
                  className='rounded-circle'
                  width={40}
                  src={application.exchange_logo_url}
                  alt=''
                />
              ) : (
                <div
                  className='rounded-circle d-flex justify-content-center align-items-center'
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: '#0052B4'
                  }}>
                  <span className='text-white'>N.A</span>
                </div>
              )}
            </span>
          ))}
          {showAdditionalCount && (
            <span className='ml-2 font-size-16 d-flex justify-content-center align-items-center'>
              +{additionalApplicationsCount}
            </span>
          )}
        </>
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

export const CommonParentApplicationStatus = ({ items, status }) => {
  const applications = _.chain(items)
    .filter((app) =>
      status === 'COMPLETE' || status === 'INCOMPLETE'
        ? app.application_status === status
        : app.application_type === status
    )
    .value();

  const additionalApplicationsCount = applications.length - 3;
  const showAdditionalCount = additionalApplicationsCount > 0;
  return (
    <div className='d-flex justify-content-center'>
      {applications && applications.length > 0 ? (
        <>
          {applications.slice(0, 3).map((application, index) => (
            <span key={application._id} className='mr-2'>
              {(application.exchange_application &&
                application.exchange_application.ticker_logo) ||
              application.ticker_logo ? (
                <img
                  className='rounded-circle'
                  height={40}
                  width={40}
                  src={
                    application.exchange_application &&
                    application.exchange_application.ticker_logo
                      ? application.exchange_application.ticker_logo
                      : application.ticker_logo
                  }
                  alt={
                    application.exchange_application &&
                    application.exchange_application.project_name
                      ? application.exchange_application.project_name
                      : application.project_name
                  }
                />
              ) : (
                <div
                  className='rounded-circle d-flex justify-content-center align-items-center'
                  style={{
                    width: 40,
                    height: 40,
                    backgroundColor: '#0052B4'
                  }}>
                  <span className='text-white'>N.A</span>
                </div>
              )}
            </span>
          ))}
          {showAdditionalCount && (
            <span className='ml-2 font-size-16 d-flex justify-content-center align-items-center'>
              +{additionalApplicationsCount}
            </span>
          )}
        </>
      ) : (
        <span>-</span>
      )}
    </div>
  );
};

const SubApplications = ({ applications, activeId, index }) => (
  <Collapse className='mt-2' isOpen={activeId === index}>
    <div className='card'>
      <div className='table-responsive'>
        <table className='table table-centered table-hover font-size-12'>
          <thead className='center-text'>
            <tr>
              <th className='pl-4 pt-3 border-0'>#</th>
              <th className='pl-4 pt-3 border-0'>Project Name</th>
              <th className='pl-4 pt-3 border-0'>Applicant Email</th>
              <th className='pl-4 pt-3 border-0'>Applicantion Type</th>
              <th className='pl-4 pt-3 border-0'>Completion Status</th>
              <th className='pl-4 pt-3 border-0'>Percentage Complete</th>
              <th className='pl-4 pt-3 border-0'>Lite Percentage Complete</th>
              <th className='pt-3 pb-4 border-0'>Processing</th>
              <th className='pt-3 pb-4 border-0'>Accept</th>
              <th className='pt-3 pb-4 border-0'>Reject</th>
              <th className='pt-3 pb-4 border-0'>KYB</th>
              <th className='pt-3 pb-4 border-0'>Quote</th>
              <th className='pt-3 pb-4 border-0'>Paid</th>
              <th className='pt-3 pb-4 border-0'>App Created At</th>
            </tr>
          </thead>
          <tbody>
            {applications && applications.length > 0 ? (
              applications.map((items, index) => (
                <React.Fragment key={index}>
                  <tr className='center-text font-size-14 font-weight-medium'>
                    <td>{index + 1}</td>
                    <td>
                      {items?.exchange_application?.project_name
                        ? items?.exchange_application?.project_name
                        : items?.project_name
                        ? items?.project_name
                        : 'N/A'}
                    </td>
                    <td>
                      {items?.exchange_application?.applicant_email
                        ? items?.exchange_application?.applicant_email
                        : items.applicant_email
                        ? items?.applicant_email
                        : 'N/A'}
                    </td>
                    <td>{items?.application_type || '-'}</td>
                    <td>
                      {items?.application_status === 'COMPLETE' ? (
                        <span className='p-2 font-size-12 badge badge-success'>
                          Complete
                        </span>
                      ) : items?.application_status === 'LITE_COMPLETE' ? (
                        <span className='p-2 font-size-12 badge badge-warning'>
                          Lite Complete
                        </span>
                      ) : (
                        <span className='p-2 font-size-12 badge badge-danger'>
                          {items?.application_status}
                        </span>
                      )}
                    </td>
                    <td>
                      {items?.application_status === 'COMPLETE'
                        ? '100'
                        : items?.application_type === 'ONRAMP'
                        ? items?.app_onramp_per
                        : items?.application_type === 'EXCHANGE'
                        ? items?.app_exchange_per
                        : ''}{' '}
                      %
                    </td>
                    <td>
                      {items?.application_status === 'LITE_COMPLETE' &&
                      items?.application_type === 'EXCHANGE'
                        ? '100'
                        : items?.application_type === 'EXCHANGE'
                        ? items?.app_exchange_lite_per
                        : '-'}{' '}
                      %
                    </td>
                    <td>
                      <CommonSubApp items={items} status={'PROCESSING'} />
                    </td>
                    <td>
                      <CommonSubApp items={items} status={'ACCEPT'} />
                    </td>
                    <td>
                      <CommonSubApp items={items} status={'REJECT'} />
                    </td>
                    <td>
                      <CommonSubApp items={items} status={'KYB'} />
                    </td>
                    <td>
                      <CommonSubApp items={items} status={'QUOTE'} />
                    </td>
                    <td>
                      <CommonSubApp items={items} status={'PAID'} />
                    </td>
                    <td>{moment(items.app_created_at).format('DD MMM YYYY')}</td>
                  </tr>
                </React.Fragment>
              ))
            ) : (
              <tr>
                <td colSpan={18}>
                  <span className='d-flex justify-content-center'>
                    No sub applications found
                  </span>
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </Collapse>
);

export const CommonSubApp = ({ items, status }) => {
  const applications = items.sub_application.filter((app) =>
    status === 'KYB'
      ? app?.application_status === status ||
        app?.application_status === 'ADD_QUESTIONS' ||
        app?.application_status === 'REQUEST_ACTION'
      : app?.application_status === status
  );

  const additionalApplicationsCount = applications.length - 3;
  const showAdditionalCount = additionalApplicationsCount > 0;

  return (
    <div className='d-flex justify-content-center'>
      {items.sub_application.length > 0 ? (
        applications.length > 0 ? (
          applications.splice(0, 3).map((item) => (
            <>
              <span key={item._id} className='mr-2'>
                {item.exchange_logo_url ? (
                  <img
                    className='rounded-circle'
                    width={40}
                    src={item.exchange_logo_url}
                    alt=''
                  />
                ) : (
                  <div
                    className='rounded-circle d-flex justify-content-center align-items-center'
                    style={{
                      width: 40,
                      height: 40,
                      backgroundColor: '#0052B4'
                    }}>
                    <span className='text-white'>N.A</span>
                  </div>
                )}
              </span>
              {showAdditionalCount && (
                <span className='ml-2 font-size-16 d-flex justify-content-center align-items-center'>
                  +{additionalApplicationsCount}
                </span>
              )}
            </>
          ))
        ) : (
          <span>-</span>
        )
      ) : (
        <span>-</span>
      )}
    </div>
  );
};
