import React, { useEffect, useState } from 'react';
import get from 'lodash/get';
import countBy from 'lodash/countBy';
import { Tooltip } from 'reactstrap';
import { v4 as uuidv4 } from 'uuid';
import { useDebounce } from 'use-debounce';
import { useQueryParam, StringParam } from 'use-query-params';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { GetNews } from '../../services/Business/News';

function BadgesTruncate({ items, limit = 2, className }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [id, setId] = useState(null);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    setId('sentiment-' + uuidv4());
  }, [items, limit]);
  if (!get(items, 'length', 0) > 0) return null;

  return (
    get(items, 'length', 0) > 0 && (
      <div className={'text-right ' + className}>
        {items.slice(0, limit).map((ticker, i) => (
          <span key={i} className="badge badge-pill badge-dark">
            {ticker}
          </span>
        ))}
        {items.length > limit && (
          <>
            <span
              className="badge badge-pill badge-dark cursor-pointer hover:opacity-80"
              id={id}>
              +{items.length - limit}
            </span>
            {!!id && (
              <Tooltip placement="top" target={id} isOpen={tooltipOpen} toggle={toggle}>
                {items.slice(limit).map((ticker, i) => (
                  <span key={i} className="badge badge-pill badge-dark">
                    {ticker}
                  </span>
                ))}
              </Tooltip>
            )}
          </>
        )}
      </div>
    )
  );
}

function Sentiment({ sentiment, className }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [id, setId] = useState(null);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  useEffect(() => {
    setId('sentiment-' + uuidv4());
  }, [sentiment]);
  if (!sentiment) return null;
  return (
    <div className={className}>
      {sentiment === 'Positive' && (
        <i
          className="cursor-pointer fas fa-smile"
          style={{ color: '#21BF73' }}
          id={id}></i>
      )}
      {sentiment === 'Neutral' && (
        <i className="cursor-pointer fas fa-meh text-muted" id={id}></i>
      )}
      {sentiment === 'Negative' && (
        <i
          className="cursor-pointer fas fa-frown"
          style={{ color: '#EB001B' }}
          id={id}></i>
      )}
      {!!id && (
        <Tooltip placement="top" target={id} isOpen={tooltipOpen} toggle={toggle}>
          <span className="font-weight-medium">{sentiment}</span>
        </Tooltip>
      )}
    </div>
  );
}

function AllNewsPage({ location }) {
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [news, setNews] = useState([]);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [debouncedSearch] = useDebounce(search, 400);

  const perPage = 10;

  useEffect(() => {
    setIsLoading(true);
    //let search = decodeURIComponent(get(location.search.split(`?search=`), [1], ''));
    GetNews({ page, limit: perPage, search: debouncedSearch })
      .then((data) => {
        const newData = {
          ...data,
          page,
          limit: perPage
        };
        // check pagination
        if (get(news, 'data.length', 0) > 0 && page > 1) {
          newData.data = [...get(news, 'data', []), ...data.data];
        }
        setNews(newData);
        setIsLoaded(true);
        setIsLoading(false);
      })
      .catch((err) => {
        setIsLoaded(true);
        setIsLoading(false);
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, [debouncedSearch, page]);

  const newsArray = get(news, 'data', []);
  const stats = {
    sentiments:
      newsArray.some(({ sentiment }) => !!sentiment) &&
      countBy(newsArray, ({ sentiment }) => sentiment),
    sources:
      newsArray.some(({ source_name }) => !!source_name) &&
      countBy(newsArray, ({ source_name }) => source_name),
    tickers: newsArray.reduce(
      (tickers, item) => [
        ...tickers,
        ...get(item, 'tickers', []).filter((t) => !tickers.includes(t))
      ],
      []
    ),
    topics: newsArray.reduce(
      (topics, item) => [
        ...topics,
        ...get(item, 'topics', []).filter((t) => !topics.includes(t))
      ],
      []
    )
  };

  return (
    <React.Fragment>
      <div className={'page-content ' + (!isLoaded && 'page-content-loading')}>
        {!isLoaded ? (
          <div className="h-100 d-flex align-items-center justify-content-center py-4">
            <i
              className="fas fa-circle-notch fa-spin text-muted"
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <div className="row mb-3 align-items-stretch">
            <div className="col-12 mb-2 d-flex flex-column flex-sm-row justify-content-between align-items-center">
              <input
                type="text"
                className="form-control form-control-search mb-2 mr-3"
                placeholder="Search"
                defaultValue={search}
                onChange={(e) => setSearch(e.target.value)}
                style={{ maxWidth: '576px' }}
                autoFocus={true}
              />
              {isLoading && (
                <div className="text-muted font-size-16 ml-auto flex-shrink-0">
                  <i className="fas fa-circle-notch fa-spin mr-2"></i>
                  <span>Loading...</span>
                </div>
              )}
            </div>
            <div className="col-12 col-lg-8 order-1 order-lg-0">
              <div className="card p-4">
                <div className="d-flex flex-wrap align-items-center">
                  <div className="font-size-24 font-weight-medium text-dark flex-grow-1">
                    Updates/News
                  </div>
                </div>
                {get(news, 'data.length', 0) > 0 ? (
                  <>
                    {get(news, 'data', []).map((item, i) => (
                      <div key={i} className="row no-gutters py-3 mb-1">
                        <div className="col-12 col-sm-auto">
                          <img
                            src={item.urlToImage || item.image_url}
                            alt=""
                            width="122"
                            style={{ maxHeight: '122px', borderRadius: '1rem' }}
                            className="mr-4"
                          />
                        </div>
                        <div className="col-12 col-sm text-truncate">
                          <div className="font-size-14 text-muted mb-1">
                            {new Intl.DateTimeFormat('en-GB', {
                              dateStyle: 'long'
                            }).format(
                              new Date(item.publishedAt || item.date || Date.now)
                            )}
                          </div>
                          <a
                            href={item.url || item.news_url}
                            className="font-size-18 font-weight-bold text-wrap mb-1">
                            {item.title}
                          </a>
                          <div className="font-size-14 text-muted text-truncate">
                            {item.description || item.text}
                          </div>
                        </div>
                        <div className="col-12 col-md-auto">
                          <div className="d-flex flex-md-column">
                            <Sentiment
                              sentiment={item.sentiment}
                              className="font-size-16 opacity-50 mx-1 mb-md-1 text-right"
                            />
                            <BadgesTruncate
                              items={item.tickers}
                              className="mx-1 mb-md-1"
                            />
                            <BadgesTruncate items={item.topics} className="mx-1" />
                          </div>
                        </div>
                      </div>
                    ))}
                    {get(news, 'data.length', 0) < news.count && (
                      <div className="d-flex justify-content-center px-3 pb-3">
                        {isLoading ? (
                          <i
                            className="fas fa-circle-notch fa-spin text-muted"
                            style={{ fontSize: 48 }}></i>
                        ) : (
                          <button
                            className="btn font-size-18 font-weight-medium text-reset"
                            style={{
                              textDecoration: 'underline',
                              textUnderlineOffset: '0.2rem'
                            }}
                            onClick={(evt) => {
                              evt.preventDefault();
                              setPage(page + 1);
                            }}>
                            View More
                          </button>
                        )}
                      </div>
                    )}
                  </>
                ) : (
                  <div className="h-100 d-flex align-items-center justify-content-center">
                    <span className="text-muted font-size-16 ">No news available</span>
                  </div>
                )}
              </div>
            </div>
            <div className="col-12 col-lg-4 order-0 order-lg-1">
              <div className="card pt-4 px-4 pb-3">
                <div className="font-size-24 font-weight-medium mb-3">
                  News Analytics Feed
                </div>
                <div className="row">
                  <div className="col-12 col-sm-6 col-lg-12">
                    <div className="mb-3">
                      <div className="font-size-16 font-weight-medium">Search</div>
                      <div className="font-size-14 text-muted">
                        {get(location.search.split(`?search=`), [1], '')}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-sm-6 col-lg-12">
                    <div className="mb-3">
                      <div className="font-size-16 font-weight-medium">Articles</div>
                      <div className="font-size-14 text-muted">
                        {get(news, 'count', '')}
                      </div>
                    </div>
                  </div>
                </div>
                {stats && (
                  <>
                    {stats.sentiments && (
                      <div className="mb-3">
                        <div className="font-size-16 font-weight-medium">Sentiment</div>
                        <div className="font-size-14 text-muted text-capitalize">
                          {Object.keys(stats.sentiments)
                            .map(
                              (sentiment) => stats.sentiments[sentiment] + ' ' + sentiment
                            )
                            .join(', ')}
                        </div>
                      </div>
                    )}

                    {get(stats, 'tickers.length', 0) > 0 && (
                      <div className="mb-3">
                        <div className="font-size-16 font-weight-medium">Tickers</div>
                        <div className="font-size-14 text-muted">
                          {stats.tickers.map((ticker, i) => (
                            <span key={i} className="badge badge-pill badge-dark">
                              {ticker}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    {get(stats, 'topics.length', 0) > 0 && (
                      <div className="mb-3">
                        <div className="font-size-16 font-weight-medium">Topics</div>
                        <div className="font-size-14 text-muted">
                          {stats.topics.map((topic, i) => (
                            <span key={i} className="badge badge-pill badge-dark">
                              {topic}
                            </span>
                          ))}
                        </div>
                      </div>
                    )}
                    {stats.sources && (
                      <div className="mb-3">
                        <div className="font-size-16 font-weight-medium">Sources</div>
                        <div className="font-size-14 text-muted text-capitalize">
                          {Object.keys(stats.sources)
                            .map((source) => stats.sources[source] + ' ' + source)
                            .join(', ')}
                        </div>
                      </div>
                    )}
                  </>
                )}
              </div>
            </div>
          </div>
        )}
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default AllNewsPage;
