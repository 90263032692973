import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import { constants as CONST } from '../../constants/constant';
import {
  GoogleMap,
  Marker,
  InfoWindow,
  MarkerClusterer,
  LoadScript
} from '@react-google-maps/api';
import { GetMyProfileAPI } from '../../services/Auth';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { notifyError } from '../../Custom/notification';

const containerStyle = {
  width: '100%',
  height: '100%'
};
const center = {
  lat: 0,
  lng: 0
};

function SupportedCountriesMap({ countries }) {
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [userData, setUserData] = useState(null);
  const getProfileDataFunction = () => {
    GetMyProfileAPI()
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setUserData(data);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  useEffect(() => {
    getProfileDataFunction();
  }, []);
  return (
    <Card
      className="height-582px position-relative"
      style={{
        filter: userData && userData.account_type === 1 && 'blur(3px)',
        cursor: 'not-allowed'
      }}>
      <LoadScript googleMapsApiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}>
        <GoogleMap mapContainerStyle={containerStyle} center={center} zoom={2}>
          <MarkerClusterer minimumClusterSize={4} maxZoom={6}>
            {(clusterer) =>
              Object.values(countries).map((country) => (
                <Marker
                  key={country.country}
                  clusterer={clusterer}
                  onClick={() => setSelectedCountry(country)}
                  name={country.country}
                  icon={{
                    url: country.logo,
                    scaledSize: { width: 24, height: 24 },
                    shape: { type: 'circle' }
                  }}
                  position={country.position}
                />
              ))
            }
          </MarkerClusterer>
          {userData && userData.account_type !== 1 && selectedCountry && (
            <InfoWindow
              position={selectedCountry.position}
              onCloseClick={() => setSelectedCountry(null)}>
              <div>
                <div className="d-flex align-items-stretch mb-3">
                  <div className="pr-2">
                    <img
                      src={selectedCountry.logo_square}
                      alt={selectedCountry.country}
                      width="60"
                      className="rounded-circle"
                    />
                  </div>
                  <div className="border-bottom d-flex align-items-center">
                    <div className="font-size-16 font-weight-medium ">
                      {selectedCountry.country_name}
                    </div>
                  </div>
                </div>
                <div className="mb-3">
                  <div className="font-size-14 font-weight-medium py-1">
                    {CONST.SUPPORTED_PAYMENT_OPTIONS}
                  </div>
                  {selectedCountry.methods.map((option, i) => (
                    <div key={i} className="d-flex align-items-center my-1">
                      {option.logo && (
                        <div className="pr-1">
                          <img
                            src={option.logo}
                            alt={option.method}
                            width={24}
                            style={{ maxHeight: '14px' }}
                          />
                        </div>
                      )}
                      <div className="flex-fill font-size-14 text-muted text-capitalize">
                        {option.method_name}
                      </div>
                    </div>
                  ))}
                </div>
                <div>
                  <div className="font-size-14 font-weight-medium py-1">
                    Supported currencies
                  </div>
                  <div>
                    {(selectedCountry.isNativeCurrencySupported
                      ? [selectedCountry.nativeCurrency]
                      : selectedCountry.currencies
                    ).map((currency) => (
                      <div
                        key={currency}
                        className=" d-inline-block border rounded-pill my-1 mr-1 py-1 px-2 font-size-11 text-muted">
                        {currency}
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </InfoWindow>
          )}
        </GoogleMap>
      </LoadScript>
    </Card>
  );
}

export default SupportedCountriesMap;
