import React, { useEffect, useState } from 'react';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { GetProvidersDetail } from '../../services/Providers/AdminProviders';
import { GetProviderAllPaymentMethods } from '../../services/Providers/Provider';

import BestAssets from './BestAssets';

function BestBuyWidget(props) {
  const [provider, setProvider] = useState(null);
  const [paymentOptions, setPaymentOptions] = useState([]);
  const [error, setError] = useState(false);

  useEffect(() => {
    setError(false);
    const searchURL = props.location.search;
    let provider = searchURL.split(`?provider=`)[1];
    if (!provider) {
      setProvider(null);
      return;
    }
    GetProvidersDetail(provider)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setProvider(data);
        return data;
      })
      .then((provider) =>
        GetProviderAllPaymentMethods(provider.reference_name).then((res) => {
          const data = res && res.data && res.data.data && res.data.data.data;
          if (data && data.length > 0) {
            setPaymentOptions(data);
          } else {
            setPaymentOptions([]);
          }
        })
      )
      .catch((err) => {
        setError("Can't load widget");
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });

    document.body.classList.add('bg-transparent');
  }, [props.location.search]);

  return (
    <React.Fragment>
      {error ? (
        <div className="text-center min-height-100vh d-flex align-items-center justify-content-center">
          <div>
            <div className="text-danger h1 mb-1">
              <i className="fas fa-exclamation-circle "></i>
            </div>
            <div className="h4 text-muted">An error occured</div>
            <div className="text-danger">{error}</div>
          </div>
        </div>
      ) : (
        provider && (
          <BestAssets
            providerData={provider}
            paymentOptions={paymentOptions}
            isEmbeddable={true}
          />
        )
      )}
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default BestBuyWidget;
