import React, { useEffect, useState, useRef } from 'react';
import {
  ButtonDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  Tooltip
} from 'reactstrap';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { notifyError } from '../../Custom/notification';
import SimpleBar from 'simplebar-react';
import { constants as CONST } from '../../constants/constant';
//import poweredby from "../../assets/images/poweredby.png";
import { GetProviderBestAssetsBuy } from '../../services/Providers/Provider';
import '../../assets/scss/custom/pages/provider.scss';

function BestAssetsPaymentOption({ payment }) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const toggle = () => setTooltipOpen(!tooltipOpen);
  const id = `best-assets-payment-option-${payment.coin_id}-${payment.payment_common_name}`;

  return (
    <div className="pr-1">
      <img
        src={payment.logo}
        alt={payment.paymentMethod}
        title={payment.paymentMethod}
        height="24"
        id={id}
      />
      <Tooltip placement="top" target={`#${id}`} isOpen={tooltipOpen} toggle={toggle}>
        <span className="font-weight-medium">{payment.paymentMethod}</span>
      </Tooltip>
    </div>
  );
}

function Controls({
  toggleIsGlobal,
  isGlobal,
  dropdownOpen,
  toggle,
  allPaymentMethods,
  selectedPaymentMethod,
  onSelectedPaymentMethodChange
}) {
  return (
    <>
      <div className="btn btn-link" onClick={toggleIsGlobal} title="Toggle Global">
        <i className={'fas fa-globe ' + (isGlobal ? '' : 'text-muted')}></i>
      </div>
      <ButtonDropdown isOpen={dropdownOpen} toggle={toggle} title="Select payment option">
        <DropdownToggle caret color="white" size="sm">
          {allPaymentMethods[selectedPaymentMethod]}
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-right">
          {Object.keys(allPaymentMethods).map((key) => (
            <DropdownItem
              key={key}
              type="button"
              onClick={() => onSelectedPaymentMethodChange(key)}
              disabled={key === selectedPaymentMethod}>
              {allPaymentMethods[key]}
            </DropdownItem>
          ))}
        </DropdownMenu>
      </ButtonDropdown>
    </>
  );
}

function BestAssets({ providerData, paymentOptions = [], isEmbeddable = false }) {
  const [bestAssetsData, setBestAssetsData] = useState([]);
  const [selectedPaymentMethod, setSelectedPaymentMethod] = useState('all');
  const [isGlobal, setIsGlobal] = useState(false);
  const [dropdownOpen, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const toggle = () => setOpen(!dropdownOpen);
  const toggleIsGlobal = () => setIsGlobal(!isGlobal);
  const handleSelectedPaymentMethodChange = (key) => setSelectedPaymentMethod(key);

  const scrollContentRef = React.useRef();
  const [contentHeight, setContentHeight] = useState(null);
  const updateContentHeight = () => {
    if (scrollContentRef.current) {
      setContentHeight(scrollContentRef.current.offsetHeight);
    }
  };
  useEffect(() => {
    updateContentHeight();
  }, []);
  useEffect(() => {
    window.addEventListener('resize', updateContentHeight);
    return () => {
      window.removeEventListener('resize', updateContentHeight);
    };
  }, []);

  const bestAssetsBuyFunction = (provider, selectedPaymentMethod, isGlobal) => {
    if (!provider) return null;
    setLoading(true);
    GetProviderBestAssetsBuy(provider, selectedPaymentMethod, isGlobal)
      .then((res) => {
        setLoading(false);
        const data = get(res, isGlobal ? 'data.data.data' : 'data.data');
        if (data) {
          if (isGlobal) {
            setBestAssetsData({
              ...data,
              price_result: get(data, 'price_result', []).filter(
                ({ provider: p }) => provider === p
              )
            });
          } else {
            setBestAssetsData(data);
          }
        } else {
          setBestAssetsData([]);
        }
      })
      .catch((err) => {
        setLoading(false);
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    bestAssetsBuyFunction(providerData.reference_name, selectedPaymentMethod, isGlobal);
  }, [providerData.reference_name, selectedPaymentMethod, isGlobal]);

  const allPaymentMethods = paymentOptions.reduce(
    (all, { method, method_name }) => ({
      ...all,
      [method]: method_name
    }),
    { all: 'All' }
  );
  let updatedAt, bestAssets;
  if (isGlobal) {
    updatedAt = bestAssetsData.updated_at;
    bestAssets = get(bestAssetsData, 'price_result', []);
  } else {
    bestAssets = get(bestAssetsData, 'data', []);
    if (bestAssetsData && bestAssetsData.length) {
      bestAssetsData.forEach(({ orderedByAssetsResult }) => {
        if (orderedByAssetsResult && orderedByAssetsResult.length)
          orderedByAssetsResult.forEach(({ timestamp }) => {
            if (!updatedAt || updatedAt < timestamp) updatedAt = timestamp;
          });
      });
    }
  }

  return (
    <>
      {loading ? (
        <div className="min-height-100vh d-flex align-items-center justify-content-center">
          <i
            className="fas fa-circle-notch fa-spin text-muted"
            style={{ fontSize: 48 }}></i>
        </div>
      ) : (
        <div className="min-height-100vh d-flex flex-column">
          <div className="">
            {isEmbeddable ? (
              <>
                <div className="row no-gutters align-items-stretch">
                  <div className="col-6"></div>
                  <div
                    className="col-6"
                    style={{
                      backgroundColor: `${providerData.color}`
                    }}>
                    <div className="d-flex justify-content-center my-3">
                      <img src={providerData.logo} alt="" height={38} />
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      <Controls
                        toggleIsGlobal={toggleIsGlobal}
                        isGlobal={isGlobal}
                        dropdownOpen={dropdownOpen}
                        toggle={toggle}
                        allPaymentMethods={allPaymentMethods}
                        selectedPaymentMethod={selectedPaymentMethod}
                        onSelectedPaymentMethodChange={handleSelectedPaymentMethodChange}
                      />
                    </div>
                  </div>
                </div>
                <div className="row no-gutters align-items-stretch">
                  <div className="col-6 pb-4">
                    <div className="font-size-20 font-weight-light text-black">
                      {CONST.BEST_CRYPTO_ASSETS}
                    </div>
                  </div>
                  <div
                    className="col-6 text-center pt-3 pb-4"
                    style={{
                      backgroundColor: `${providerData.color}`
                    }}></div>
                </div>
              </>
            ) : (
              <div className="row no-gutters align-items-center py-4">
                <div className="col-6 pb-1 pb-sm-0 font-size-20 font-weight-medium text-black">
                  {CONST.BEST_CRYPTO_ASSETS}
                </div>
                <div className="col-6 d-flex justify-content-center">
                  <Controls
                    toggleIsGlobal={toggleIsGlobal}
                    isGlobal={isGlobal}
                    dropdownOpen={dropdownOpen}
                    toggle={toggle}
                    allPaymentMethods={allPaymentMethods}
                    selectedPaymentMethod={selectedPaymentMethod}
                    onSelectedPaymentMethodChange={handleSelectedPaymentMethodChange}
                  />
                </div>
              </div>
            )}
          </div>

          <div className="flex-grow-1 position-relative" ref={scrollContentRef}>
            {contentHeight && (
              <SimpleBar
                className="position-absolute position-cover"
                style={{ maxHeight: contentHeight }}>
                <div className="">
                  {bestAssets.map((asset, i) => {
                    const assetResult = isGlobal ? asset : asset.orderedByAssetsResult[0];
                    const currency = isGlobal
                      ? bestAssetsData.currency
                      : assetResult.currency;
                    const price = isGlobal
                      ? bestAssetsData.currency_value / assetResult.value
                      : assetResult.currency_value /
                        assetResult.selected_provider_asset_value;
                    const paymentItems = isGlobal
                      ? [
                          {
                            ...assetResult,
                            paymentMethod: assetResult.payment_method
                          }
                        ]
                      : get(asset, 'orderedByAssetsResult', []).slice(0, 1);
                    return (
                      <div key={i} className="row no-gutters align-items-stretch">
                        <div
                          className={'col-6 d-flex align-items-center py-3 '}
                          style={{
                            borderTop: i > 0 ? '2px solid #F7F7F7' : 'none'
                          }}>
                          <div className="">
                            <img
                              src={assetResult.coin_logo}
                              alt={asset.crypto_name}
                              height={46}
                              className="rounded-circle"
                            />
                          </div>
                          <div className="flex-fill px-3">
                            <div className="font-weight-medium font-size-20 mb-1">
                              {asset.crypto_name}
                            </div>
                            <div className="d-flex align-items-center">
                              {paymentItems.map((payment, i) => (
                                <BestAssetsPaymentOption key={i} payment={payment} />
                              ))}
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-6 text-right no-gutters"
                          style={{
                            backgroundColor: `${providerData.color}`
                          }}>
                          <div className="py-3 px-4">
                            <div className="text-truncate font-weight-medium font-size-20 mb-1">
                              {new Intl.NumberFormat('en-US', {
                                style: 'currency',
                                //notation: 'compact',
                                //minimumSignificantDigits: 1,
                                //maximumFractionDigits: 3,
                                currency: currency
                              }).format(price)}
                            </div>
                            {isGlobal ? (
                              <div className="text-truncate font-weight-medium font-size-10 text-white text-capitalize">
                                {assetResult.provider}
                              </div>
                            ) : (
                              <>
                                <div className="text-truncate font-weight-medium font-size-10 text-white">
                                  {assetResult.second_provider !== null
                                    ? `${assetResult.percentage}% + Cheaper`
                                    : `is only provider`}
                                </div>
                                {assetResult.second_provider !== null && (
                                  <div className="text-truncate font-weight-lighter font-size-10 text-white">
                                    Compared with{' '}
                                    {get(asset, 'orderedByAssetsResult.length', 0)}{' '}
                                    providers
                                  </div>
                                )}
                              </>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
              </SimpleBar>
            )}
          </div>

          <div className="position-relative py-3 py-sm-4">
            <div className="text-center text-muted font-size-18 font-weight-medium py-1">
              Powered by Rampnalysis
            </div>
            {updatedAt && (
              <div className="text-right text-muted font-size-10 font-weight-medium mr-1">
                Last checked {updatedAt}
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
}

export default BestAssets;
