import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import { UpdateExchangeApplication } from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { ExchangeListAPI } from '../../../services/Auth';
import Select from 'react-select';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';
import './steps.css';

function StepSeven(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const step = 7;
  const [completeStep, setCompleteStep] = useState(0);
  const [exchangeList, setExchangeList] = useState([]);
  const [allExchangeList, setAllExchangeList] = useState([]);
  const [filterExchangeList, setFilterExchangeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const applicationData = props.applicationData.exchange_application;

  const ValidateTable = ({ index, item }) => {
    const [localVolume, setLocalVolume] = useState(item.hour24_trading_volume || '');

    useEffect(() => {
      setLocalVolume(item.hour24_trading_volume);
    }, [item.hour24_trading_volume]);

    const handleBlur = () => {
      const updatedFilterExchangeList = filterExchangeList.map((exchange) => {
        if (exchange._id === item._id) {
          return { ...exchange, hour24_trading_volume: localVolume };
        }
        return exchange;
      });
      setFilterExchangeList(updatedFilterExchangeList);
    };
    return (
      <>
        <td>{index + 1}</td>
        <td className=''>{item.exchange_name}</td>
        <td>
          <div className='d-flex'>
            <label style={{ float: 'left' }} className='pt-3 pr-2'>
              $
            </label>

            <Input
              type='text'
              name={item.exchange_name}
              style={{ borderRadius: '8px', width: '100%' }}
              value={localVolume}
              placeholder='Enter 24 hours trading volume'
              className='font-size-12'
              onChange={(e) => {
                setLocalVolume(
                  Number(e.target.value.replace(/[^0-9]/g, '')).toLocaleString()
                );
              }}
              onBlur={handleBlur}
            />
          </div>
        </td>
      </>
    );
  };

  const CapMarketSchema = Yup.object().shape({
    existing_exchnage_listed: Yup.string()
  });

  useEffect(() => {
    exchangeListApiFunction();
    setCompleteStep(applicationData.percentage_complete);
  }, []);

  const exchangeListApiFunction = () => {
    ExchangeListAPI()
      .then((res) => {
        const GetExchangeData = get(res, 'data.data.data', []);
        setAllExchangeList(GetExchangeData);
        const newData = res.data.data.data.map((item) => {
          return { label: item.exchange_name, value: item.exchange_name };
        });
        setExchangeList(newData);
        if (applicationData.existing_exchanges_listed) {
          const selectedList = applicationData.existing_exchanges_listed.map(
            (exchange) => ({
              label: exchange.exchange_name,
              value: exchange.exchange_name
            })
          );
          setSelectedOptions(selectedList);
          const filteredData = GetExchangeData.filter((item) =>
            selectedList.some((dataItem) => dataItem.label === item.exchange_name)
          );
          const updatedArrayList2 = filteredData.map((items) => {
            const matchedItem = applicationData.existing_exchanges_listed.find(
              (item) =>
                item.exchange_name.toLowerCase() === items.exchange_name.toLowerCase()
            );

            if (matchedItem) {
              return {
                ...items,
                hour24_trading_volume: matchedItem.hour24_trading_volume
              };
            }
            return items;
          });
          setFilterExchangeList(updatedArrayList2);
        }
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const changeHandlerExchange = (selectValues) => {
    const filteredData = allExchangeList.filter((item) =>
      selectValues.some((dataItem) => dataItem.label === item.exchange_name)
    );
    const updatedArrayList2 = filteredData.map((items) => {
      const matchedItem = applicationData.existing_exchanges_listed.find(
        (item) => item.exchange_name.toLowerCase() === items.exchange_name.toLowerCase()
      );

      if (matchedItem) {
        return {
          ...items,
          hour24_trading_volume: matchedItem.hour24_trading_volume
        };
      }
      return items;
    });
    setSelectedOptions(selectValues);
    setFilterExchangeList([...updatedArrayList2]);
  };

  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    if (filterExchangeList.length > 0) {
      const formData = filterExchangeList.map((item) => {
        const inputElement = document.getElementsByName(item.exchange_name)[0];
        const volume = inputElement.value;

        return {
          exchange_name: item.exchange_name,
          hour24_trading_volume: volume
        };
      });
      let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 40));
      let data = {
        existing_exchanges_listed: JSON.stringify(formData),
        percentage_complete: stepsRequired
      };
      setIsLoading(true);
      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          props.setApplicationData(application);
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
      setExchangeCompleteStep([0, 1, 2, 3, 4, 5, 6, 7]);
    }

    if (filterExchangeList.every((i) => i.hour24_trading_volume)) {
      const { nextPage, applicationId } = props;
      if (filterExchangeList.length > 0) {
        const formData = filterExchangeList.map((item) => {
          const inputElement = document.getElementsByName(item.exchange_name)[0];
          const volume = inputElement.value;

          return {
            exchange_name: item.exchange_name,
            hour24_trading_volume: volume
          };
        });
        let stepsRequired = Math.floor(StepsCalculator(1, completeStep, 40));
        let data = {
          existing_exchanges_listed: JSON.stringify(formData),
          percentage_complete: stepsRequired
        };
        JSON.stringify(data);
        UpdateExchangeApplication({ application_id: applicationId }, data)
          .then((res) => {
            const application = get(res, 'data.data.data', '');
            props.setApplicationData(application);
            nextPage();
          })
          .catch((err) => {
            const errorMessage = get(err, 'data.error.message');
            notifyError(notificationMessageFunction(errorMessage));
          });
      } else {
        nextPage();
      }
    }
  };

  const calculateProgress = () => {
    return completeStep;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>
          Currently where is it listed and 24-h trading volume
        </span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={{
              existing_exchnage_listed: ''
            }}
            validationSchema={CapMarketSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div>
                  <Row>
                    <Col>
                      <FormGroup className='mb-4'>
                        <div className='font-size-12'>
                          <Label
                            htmlFor='existing_exchnage_listed'
                            className='text-black font-weight-normal'>
                            {CONST.EXISTING_EXCHANGES_LISTED}({CONST.OPTIONAL})
                          </Label>
                        </div>
                        <div>
                          <Select
                            className='select-input border-dark font-size-12'
                            isMulti
                            style={{
                              paddingRight: '30px',
                              borderRadius: '8px'
                            }}
                            options={exchangeList}
                            onChange={(val) => changeHandlerExchange(val)}
                            name='existing_exchnage_listed'
                            value={selectedOptions}
                          />
                        </div>
                      </FormGroup>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      {filterExchangeList.length > 0 ? (
                        <div className='table-responsive'>
                          <table className='table table-centered table-hover'>
                            <thead className='font-size-12 center-text'>
                              <th>#</th>
                              <th>Exchange Name</th>
                              <th>24 hour trading volume</th>
                            </thead>
                            <tbody>
                              {filterExchangeList.map((item, index) => (
                                <tr
                                  key={index}
                                  className='center-text font-size-12 font-weight-medium cursor-pointer'>
                                  <ValidateTable item={item} index={index} />
                                </tr>
                              ))}
                            </tbody>
                          </table>
                        </div>
                      ) : (
                        ''
                      )}
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepSeven;
