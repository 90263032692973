import React from 'react';
import { Link } from 'react-router-dom';

function Completion() {
  return (
    <React.Fragment>
      <span className='font-size-12 text-center d-flex justify-content-center'>
        Checkout your Referred Users{' '}
        <Link to='/business-referrals'>
          {' '}
          <span style={{ color: 'blue' }} className='ml-1'>
            Here
          </span>
        </Link>
      </span>
    </React.Fragment>
  );
}

export default Completion;
