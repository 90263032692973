import React, { Component } from 'react';

// MetisMenu
import MetisMenu from 'metismenujs';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

//i18n
import { withNamespaces } from 'react-i18next';

import { connect } from 'react-redux';
import {
  changeLayout,
  changeLayoutWidth,
  changeSidebarTheme,
  changeSidebarType,
  changePreloader
} from '../../store/actions';
import { constants as STORAGEKEY } from '../../constants/constant';
import { Button, Modal, ModalBody, ModalHeader } from 'reactstrap';
import { GetMyProfileAPI } from '../../services/Auth';
// import nftImage from '../../assets/images/nft.png';

class SidebarContent extends Component {
  constructor(props) {
    super(props);
    this.state = { modal: false, userData: {} };
  }

  componentDidMount() {
    this.initMenu();
  }

  componentDidUpdate(prevProps) {
    if (prevProps !== this.props) {
      if (this.props.type !== prevProps.type) {
        this.initMenu();
      }
    }
  }

  initMenu() {
    new MetisMenu('#side-menu');
    this.GetMyProfileAPIFunction();
    var matchingMenuItem = null;
    var ul = document.getElementById('side-menu');
    var items = ul.getElementsByTagName('a');
    for (var i = 0; i < items.length; ++i) {
      if (this.props.location.pathname === items[i].pathname) {
        matchingMenuItem = items[i];
        break;
      }
    }
    if (matchingMenuItem) {
      this.activateParentDropdown(matchingMenuItem);
    }
  }

  GetMyProfileAPIFunction = () => {
    GetMyProfileAPI()
      .then((res) => {
        if (res && res.data && res.data.data && res.data.data.data) {
          this.setState(() => ({
            userData: res.data.data.data
          }));
        }
      })
      .catch((err) => {});
  };

  activateParentDropdown = (item) => {
    item.classList.add('active');
    const parent = item.parentElement;

    if (parent) {
      parent.classList.add('mm-active');
      const parent2 = parent.parentElement;

      if (parent2) {
        parent2.classList.add('mm-show');

        const parent3 = parent2.parentElement;

        if (parent3) {
          parent3.classList.add('mm-active'); // li
          parent3.childNodes[0].classList.add('mm-active'); //a
          const parent4 = parent3.parentElement;
          if (parent4) {
            parent4.classList.add('mm-active');
          }
        }
      }
      return false;
    }
    return false;
  };
  toggle = () => {
    this.setState(() => ({
      modal: !this.state.modal
    }));
  };

  logoutFunction = () => {
    const { history } = this.props;
    localStorage.clear();
    history.push('/login');
  };

  render() {
    const accountType = localStorage.getItem(STORAGEKEY.ACCOUNT_TYPE);
    const email = localStorage.getItem(STORAGEKEY.EMAIL);
    const role = localStorage.getItem(STORAGEKEY.ROLE);
    return (
      <React.Fragment>
        <div id='sidebar-menu'>
          <ul className='metismenu list-unstyled' id='side-menu'>
            <li className='menu-title'>{this.props.t('Menu')}</li>
            {accountType === '0' && (
              <li>
                <Link to='/admin-dashboard' className='waves-effect'>
                  <i className='ri-dashboard-line'></i>
                  <span className='ml-1'>{this.props.t('Dashboard')}</span>
                </Link>
              </li>
            )}
            {accountType === '2' && (
              <li>
                <Link to='/business-dashboard' className='waves-effect'>
                  <i className='ri-dashboard-line'></i>
                  <span className='ml-1'>{this.props.t('Dashboard')}</span>
                </Link>
              </li>
            )}
            {(accountType === '0' || accountType === '1') && (
              <li>
                <Link to='/onramps' className='waves-effect'>
                  <i className='mdi mdi-monitor-multiple'></i>
                  <span className='ml-1'>{this.props.t('Onramps')}</span>
                </Link>
              </li>
            )}

            {accountType === '0' && (
              <li>
                <Link to='/admin-listus-applications' className='waves-effect'>
                  <i className='ri-list-ordered'></i>
                  <span className='ml-1'>{this.props.t('Listus Applications')}</span>
                </Link>
              </li>
            )}

            {accountType === '0' && (
              <li>
                <Link to='/admin-onramp-applications' className='waves-effect'>
                  <i className='ri-list-ordered'></i>
                  <span className='ml-1'>{this.props.t('Onramp Applications')}</span>
                </Link>
              </li>
            )}

            {accountType !== '2' && accountType !== '4' && accountType !== '5' && (
              <li>
                <Link to='/countries' className='waves-effect'>
                  <i className='ri-earth-line'></i>
                  <span className='ml-1'>{this.props.t('All countries')}</span>
                </Link>
              </li>
            )}

            {accountType !== '2' && accountType !== '4' && accountType !== '5' && (
              <li>
                <Link to='/all-crypto' className='waves-effect'>
                  <i className='fab fa-bitcoin'></i>
                  <span className='ml-1'>{this.props.t('All Cryptocurrencies')}</span>
                </Link>
              </li>
            )}

            {/* <li>
              <Link to="/notifications" className="waves-effect">
                <i className="fas fa-envelope"></i>
                <span className="ml-1">{this.props.t('Notifications')}</span>
              </Link>
            </li> */}
            {accountType === '2' && this.state.userData.business_list_token === true && (
              <li>
                <Link to='/application' className='waves-effect'>
                  <i className='fab fa-wpforms'></i>
                  <span className='ml-1'>{this.props.t('Application')}</span>
                </Link>
                <ul className='sub-menu'>
                  <li>
                    <Link to='/application/create'>
                      {this.props.t('Create Application')}
                    </Link>
                  </li>
                  <li>
                    <Link to='/applications'>{this.props.t('View Applications')}</Link>
                  </li>
                  {(role === null || role === 'Admin') && (
                    <li>
                      <Link to='/application/setting'>
                        {this.props.t('Setting Applications')}
                      </Link>
                    </li>
                  )}
                </ul>
              </li>
            )}

            {(accountType === '0' || accountType === '2') && (
              <li>
                <Link to='/orders' className='waves-effect'>
                  <i className='fas fa-money-check-alt'></i>
                  <span className='ml-1'>{this.props.t('Orders')}</span>
                </Link>
              </li>
            )}
            {(accountType === '2' || accountType === '5') && (
              <li>
                <Link to='/business-referrals' className='waves-effect'>
                  <i className='mdi mdi-account-convert'></i>
                  <span className='ml-1'>{this.props.t('Referral Users')}</span>
                </Link>
              </li>
            )}

            {/* {accountType === '2' && (
              <li>
                <Link to='/business-nft' className='waves-effect'>
                  <img src={nftImage} alt='nft' height={20} width={20} />
                  <span className='ml-1'>{this.props.t('NFT')}</span>
                </Link>
              </li>
            )} */}
            {accountType === '2' && (
              <li>
                <Link to='/widget-settings' className='waves-effect'>
                  <i className='fa fa-cog'></i>
                  <span className='ml-1'>{this.props.t('Widget Settings')}</span>
                </Link>
              </li>
            )}
            {accountType === '2' && (
              <li>
                <Link to='/widget-customize-1buy' className='waves-effect'>
                  <i className='mdi mdi-xml'></i>
                  <span className='ml-1'>{this.props.t('Widget Customization')}</span>
                </Link>
              </li>
            )}
            {accountType === '2' && (
              <li>
                <Link to='/business-profile' className='waves-effect'>
                  <i className='fa fa-cog'></i>
                  <span className='ml-1'>{this.props.t('Settings')}</span>
                </Link>
              </li>
            )}
            {accountType === '0' && (
              <li>
                <Link to='/members' className='waves-effect'>
                  <i className='fas fa-users'></i>
                  <span className='ml-1'>{this.props.t('Users')}</span>
                </Link>
              </li>
            )}
            {accountType === '1' && (
              <>
                <li>
                  <Link to='/application' className='waves-effect'>
                    <i className='fa fa-wpforms'></i>
                    <span className='ml-1'>{this.props.t('Application')}</span>
                  </Link>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/applications'>{this.props.t('View Applications')}</Link>
                    </li>
                    {(role === 'undefined' || role === 'Admin') && (
                      <li>
                        <Link to='/application/setting'>
                          {this.props.t('Setting Applications')}
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </>
            )}

            {accountType === '4' && (
              <>
                <li>
                  <Link to='/exchange-applications' className='waves-effect'>
                    <i className='fab fa-wpforms'></i>
                    <span className='ml-1'>{this.props.t('Applications')}</span>
                  </Link>
                </li>
                <li>
                  <Link to='/application/setting'>
                    <i className='fa fa-cog'></i>
                    <span className='ml-1'>{this.props.t('Setting Applications')}</span>
                  </Link>
                </li>
                {/* <li>
                  <Link to='/application' className='waves-effect'>
                    <i className='fab fa-wpforms'></i>
                    <span className='ml-1'>{this.props.t('Application')}</span>
                  </Link>
                  <ul className='sub-menu'>
                    <li>
                      <Link to='/applications'>{this.props.t('View Applications')}</Link>
                    </li>
                    {(role === 'undefined' || role === 'Admin') && (
                      <li>
                        <Link to='/application/setting'>
                          {this.props.t('Setting Applications')}
                        </Link>
                      </li>
                    )}
                  </ul>
                </li> */}
              </>
            )}

            <li>
              <a className='waves-effect' onClick={this.toggle}>
                <i className='mdi mdi-logout'></i>
                <span className='ml-1'>{this.props.t('Logout')}</span>
              </a>
            </li>
            <li
              style={{ position: 'absolute', bottom: '0px', color: 'black' }}
              className='p-3'>
              <i className='fa fa-user'></i>
              <span className='ml-1'>
                {accountType === '0' && 'Super Admin'}
                {accountType === '1' && 'Ramp User'}
                {accountType === '2' && 'Business User'}
                {accountType === '3' && 'User'}
                {accountType === '4' && 'Exchange User'}
                {accountType === '5' && 'Reviewer User'}
              </span>
              <br />
              <span style={{ wordBreak: 'break-all' }} className='pr-4'>
                {email}
              </span>
            </li>
          </ul>
        </div>
        {this.state.modal && (
          <Modal isOpen={this.state.modal} toggle={this.toggle} centered>
            <ModalHeader toggle={this.toggle} style={{ paddingLeft: '45%' }}>
              <span className='d-flex justify-content-center'>Logout</span>
            </ModalHeader>
            <ModalBody>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/twopqjaj.json'
                  trigger='hover'
                  target='#create-onramp-app'
                  colors='outline:#121331,primary:#3a3347,secondary:#ee8f66,tertiary:#ffc738,quaternary:#ebe6ef'
                  style={{ width: '200px', height: '200px' }}></lord-icon>
              </div>
              <div className='text-center font-size-16'>
                Are you absolutely certain you want to proceed with logging out? This
                action will terminate your current session and disconnect you from the
                system.
              </div>
              <div className='d-flex justify-content-center mt-4 mb-3'>
                <Button
                  color='primary'
                  onClick={this.logoutFunction}
                  style={{ width: 100 }}>
                  Logout
                </Button>{' '}
                <Button
                  color='danger'
                  onClick={this.toggle}
                  className='ml-2'
                  style={{ width: 100 }}>
                  Cancel
                </Button>
              </div>
            </ModalBody>
          </Modal>
        )}
      </React.Fragment>
    );
  }
}

const mapStatetoProps = (state) => {
  return { ...state.Layout };
};

export default withRouter(
  connect(mapStatetoProps, {
    changeLayout,
    changeSidebarTheme,
    changeSidebarType,
    changeLayoutWidth,
    changePreloader
  })(withNamespaces()(SidebarContent))
);
