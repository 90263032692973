import React from 'react';
import { Box, Slider } from '@mui/material';

function RadiusSelection({
  borderRadius,
  widgetRadius,
  handleBorderRadiusChange,
  handleWidgetRadiusChange,
  applyRadiusChanges
}) {
  return (
    <React.Fragment>
      <div className='d-flex justify-content-around mt-3'>
        <div
          className='d-flex align-items-center cursor-pointer'
          style={{ flexDirection: 'column' }}>
          <Box sx={{ width: 100 }}>
            <Slider
              value={borderRadius}
              onChange={handleBorderRadiusChange}
              aria-label='Default'
              valueLabelDisplay='auto'
              min={0}
              max={2}
              step={0.25}
            />
          </Box>
          <p className='mt-1 font-size-12 text-black font-wright-medium'>
            {borderRadius}rem
          </p>
          <p className='mt-1 font-size-12 text-black font-wright-medium'>Border Radius</p>
        </div>
        <div
          className='d-flex align-items-center cursor-pointer'
          style={{ flexDirection: 'column' }}>
          <Box sx={{ width: 100 }}>
            <Slider
              value={widgetRadius}
              onChange={handleWidgetRadiusChange}
              aria-label='Default'
              valueLabelDisplay='auto'
              min={0}
              max={2}
              step={0.25}
            />
          </Box>
          <p className='mt-1 font-size-12 text-black font-wright-medium'>
            {widgetRadius}rem
          </p>
          <p className='mt-1 font-size-12 text-black font-wright-medium'>Widget Radius</p>
        </div>
      </div>
      <div className='mb-3 ml-3 mt-3'>
        <button
          type='button'
          name='btn'
          className={`btn btn-auth`}
          onClick={() => applyRadiusChanges()}
          style={{ width: 120, marginLeft: 0 }}>
          Apply
        </button>
      </div>
    </React.Fragment>
  );
}

export default RadiusSelection;
