import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Card, Col, Row } from 'reactstrap';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';
import ViewOnrampApplication from './ViewOnrampApplication';
import ChangeRequests from './ChangeRequests';

function AdminViewApplication(props) {
  const [applicationId, setApplicationId] = useState('application_id=');
  const [selectedStatus, setSelectedStatusValues] = useState('');
  const [updatePage, setUpdatePage] = useState(false);
  const [modal, setModal] = useState(false);
  const [statusChangeModal, setStatusChangeModal] = useState(false);
  const [textareaValues, setTextareaValues] = useState({});
  const [clickedLabels, setClickedLabels] = useState([]);
  const history = useHistory();

  const handleModal = () => {
    setModal(!modal);
  };

  const handleChangeStatus = (values) => {
    setSelectedStatusValues(values);
    setStatusChangeModal(!statusChangeModal);
    handleModal();
  };

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row className='px-2'>
          <Col lg={8} md={8} sm={12}>
            <Card>
              <div className='pt-3 pl-3'>
                <button
                  type='button'
                  className='btn btn-light p-1 mb-2'
                  style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
                  onClick={() => history.push('/admin-onramp-applications')}>
                  <ArrowLeftIcon width={18} height={18} />
                </button>
              </div>

              <ViewOnrampApplication props={props} setApplicationId={setApplicationId} />
            </Card>
          </Col>
          <Col lg={4} md={4} sm={12}>
            <ChangeRequests
              applicationId={applicationId}
              selectedStatus={selectedStatus}
              modal={modal}
              handleModal={handleModal}
              setUpdatePage={setUpdatePage}
              updatePage={updatePage}
              handleChangeStatus={handleChangeStatus}
              setTextareaValues={setTextareaValues}
              clickedLabels={clickedLabels}
              textareaValues={textareaValues}
              setClickedLabels={setClickedLabels}
            />
          </Col>
        </Row>
      </div>
    </React.Fragment>
  );
}

export default AdminViewApplication;
