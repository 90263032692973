import React, { useState } from 'react';
import { useEffect } from 'react';
import { Card } from 'reactstrap';
import get from 'lodash/get';
import uniq from 'lodash/uniq';
import { useDebounce } from 'use-debounce';
import SimpleBar from 'simplebar-react';
import { GetAllCountries } from '../../services/Business/Search';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { ReactComponent as PeopleIcon } from '../../assets/images/icons/people.svg';
import { ReactComponent as ActivityIcon } from '../../assets/images/icons/activity.svg';
import CountryGridCardRow from './CountryGridCardRow';

function AllCountriesGrid({ allCountries = [] }) {
  const [search, setSearch] = useState('');
  const [debouncedSearch] = useDebounce(search, 400);
  const [loading, setLoading] = useState(false);
  const [selectedLetter, setSelectedLetter] = useState('A');
  const [countries, setCountries] = useState([]);

  const getLetters = (arr = []) =>
    uniq(arr.map(({ country }) => country.slice(0, 1).toUpperCase()));

  useEffect(() => {
    let promise;
    if (!debouncedSearch) {
      promise = Promise.resolve(allCountries);
    } else {
      promise = GetAllCountries(debouncedSearch).then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        return data;
      });
    }
    setLoading(true);
    promise
      .then((data) => {
        setLoading(false);
        data.forEach((country) => {
          country.logo = `https://purecatamphetamine.github.io/country-flag-icons/3x2/${country.iso}.svg`;
          country.logo_square = `https://purecatamphetamine.github.io/country-flag-icons/1x1/${country.iso}.svg`;
        });
        const letters = getLetters(data);
        if (letters.length > 0 && !letters.includes(selectedLetter)) {
          setSelectedLetter(letters[0]);
        }
        setCountries(data);
        return data;
      })
      .catch((err) => {
        setLoading(false);
      });
  }, [debouncedSearch]);
  const letters = getLetters(countries);
  const filteredCountries = countries.filter(({ country }) =>
    country.startsWith(selectedLetter)
  );

  return (
    <>
      <Card className="p-4">
        <div className="row mb-4 align-items-center">
          <div className="col-12 col-md py-1">
            <input
              type="text"
              className="form-control form-control-search"
              placeholder="Search"
              defaultValue={search}
              onChange={(e) => setSearch(e.target.value)}
              style={{ maxWidth: '368px' }}
            />
          </div>
          <div className="col-12 col-md-auto d-flex flex-wrap font-size-20 py-1">
            {loading ? (
              <div className="text-muted font-size-16">
                <i className="fas fa-circle-notch fa-spin mr-2"></i>
                <span>Loading...</span>
              </div>
            ) : (
              letters &&
              letters.length > 0 &&
              letters.map((l, key) => (
                <a
                  key={key}
                  href="!#"
                  className={
                    ' mr-2 ' + (selectedLetter === l ? 'text-black' : 'text-muted')
                  }
                  onClick={(evt) => {
                    evt.preventDefault();
                    setSelectedLetter(l);
                  }}>
                  {l}
                </a>
              ))
            )}
          </div>
        </div>
        <div className="row align-items-stretch">
          {filteredCountries.map((country) => (
            <div key={country.iso} className="col-12 col-md-6 col-xl-4 col-xxl-3 pb-3">
              <div className="border rounded p-3 h-100">
                <div className="d-flex align-items-center border-bottom pb-3 mb-2">
                  {!!country.logo_square && (
                    <div className="pr-3">
                      <img
                        src={country.logo_square}
                        alt={country.country}
                        width={42}
                        style={{ maxHeight: '42px' }}
                        className="rounded-circle"
                      />
                    </div>
                  )}
                  <div className="flex-fill font-size-16 font-weight-medium mr-1 text-truncate">
                    {country.country}
                  </div>
                  <div
                    className="font-size-10 font-weight-medium"
                    style={{
                      transform: 'translateY(-0.5rem)'
                    }}>
                    <div className="d-flex align-items-center text-nowrap mb-1 ">
                      <PeopleIcon alt="" className="mr-1" width={16} />
                      <div>25.69 Million</div>
                    </div>
                    <div className="d-flex align-items-center text-nowrap">
                      <ActivityIcon alt="" className="mr-1" width={16} />
                      <div>18.53 Million</div>
                    </div>
                  </div>
                </div>
                <div className="font-size-14 text-muted mb-2">Onramps</div>
                <SimpleBar style={{ maxHeight: '290px' }}>
                  {get(country, 'supportedProvider', []).map((provider, i) => (
                    <CountryGridCardRow
                      key={i}
                      provider={provider}
                      country={country}
                      showTooltip={true}
                      className="pb-2"
                    />
                  ))}
                </SimpleBar>
              </div>
            </div>
          ))}
        </div>
      </Card>
    </>
  );
}

export default AllCountriesGrid;
