import React from 'react';
import moment from 'moment';

function GetApplication({ handleApplication, application }) {
  const ProfileIcon = ({ iconUrl }) => (
    <div>
      {iconUrl && iconUrl.length > 0 ? (
        <img
          src={iconUrl}
          style={{ borderRadius: '50%' }}
          width={40}
          height={40}
          alt='user'
        />
      ) : (
        <i
          className='mt-2 pt-2 text-center fa fa-user'
          style={{
            borderRadius: '50%',
            border: '1px solid #f0f0f0',
            width: '40px',
            height: '40px',
            background: '#f0f0f0'
          }}></i>
      )}
    </div>
  );

  return (
    <>
      {' '}
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application.ticker_logo ? (
          <img
            src={application.ticker_logo}
            height={45}
            width={45}
            style={{ borderRadius: '50%' }}
            alt='ticker-logo'
          />
        ) : (
          <ProfileIcon iconUrl={application.ticker_logo} />
        )}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application && application.project_name ? application.project_name : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application && application.ticker ? application.ticker : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application && application.ticker_name ? application.ticker_name : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application && application.legal_entity_name
          ? application.legal_entity_name
          : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application && application.applicant_full_name
          ? application.applicant_full_name
          : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application && application.applicant_email ? application.applicant_email : '-'}
      </td>
      <td className='pl-2' onClick={() => handleApplication(application)}>
        {application && application.business_category
          ? application.business_category
          : '-'}
      </td>
      <td className='pl-4'>
        {application.sub_application ? (
          application.sub_application.application_status === 'PROCESSING' &&
          application.sub_application.response_complete ? (
            <span className='badge badge-pill badge-warning'>Action Needed</span>
          ) : application.sub_application.application_status === 'PROCESSING' ? (
            <span className='badge badge-pill badge-success'>Processing</span>
          ) : application.sub_application.application_status === 'ACCEPT' ? (
            <span className='badge badge-pill badge-success'>Accepted</span>
          ) : application.sub_application.application_status === 'REJECT' ? (
            <span className='badge badge-pill badge-danger'>Rejected</span>
          ) : application.sub_application.application_status === 'KYB' ? (
            <span className='badge badge-pill badge-primary'>KYB</span>
          ) : application.sub_application.application_status === 'ADD_QUESTIONS' ? (
            <span className='badge badge-pill badge-primary'>Add Questions</span>
          ) : application.sub_application.application_status === 'REQUEST_ACTION' ? (
            <span className='badge badge-pill badge-primary'>Request Action</span>
          ) : application.sub_application.application_status === 'QUOTE' ? (
            <span className='badge badge-pill badge-dark'>Quote</span>
          ) : application.sub_application.application_status === 'PAID' ? (
            <span className='badge badge-pill badge-info'>Paid</span>
          ) : (
            '-'
          )
        ) : (
          <span className='badge badge-pill badge-secondary'>Unread</span>
        )}
      </td>
      <td
        className='pl-2'
        style={{ minWidth: 150 }}
        onClick={() => handleApplication(application)}>
        {application && application.created_at
          ? moment(application.created_at).format('DD MMM, YYYY')
          : '-'}
      </td>
    </>
  );
}

export default GetApplication;
