import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import GetResponse from './GetResponse';
import { Paginator } from 'primereact/paginator';
import TableSortButton from '../../../components/TableSortButton';
import '../application.css';
function ResponseList({
  allResponses,
  loading,
  totalCount,
  GetApplicationResponseFunc,
  statusSuggestions,
  responsePage,
  updatePage,
  order,
  setOrder,
  first,
  setFirst,
  setResponsePage,
  setCurrentPageData,
  currentPageData
}) {
  const [applicationId] = useQueryParam('application_id', StringParam);

  const [expandedRows, setExpandedRows] = useState([]);

  const toggleRowExpansion = (index) => {
    if (expandedRows.includes(index)) {
      setExpandedRows(expandedRows.filter((rowIndex) => rowIndex !== index));
    } else {
      setExpandedRows([...expandedRows, index]);
    }
  };
  const onPageChange = (event) => {
    setFirst(event.first);
    setResponsePage(event.page + 1);
    const newPageData = allResponses.slice(event.first, event.first + event.rows);
    setCurrentPageData(newPageData);
  };

  useEffect(() => {
    GetApplicationResponseFunc(applicationId);
  }, [applicationId, order, statusSuggestions, responsePage, updatePage]);

  return (
    <React.Fragment>
      <div className='card'>
        <div className='table-responsive'>
          <table className='table table-centered table-hover'>
            <thead className='center-text'>
              <tr className='font-size-12 text-muted center-text'>
                <th className='pl-4 pt-3 border-0'>#</th>
                <th className='pl-4 pt-3 border-0 text-left'>Field Changes Requested</th>
                <th className='pl-4 pt-3 border-0 text-left'>Notes</th>
                <th className='pl-4 pt-3 border-0'>
                  <div className='d-flex align-items-center'>
                    <span>Date Submitted</span>
                    <TableSortButton
                      className='ml-1'
                      value={order}
                      topValue='created_at_desc'
                      bottomValue='created_at_asc'
                      onChange={(v) => {
                        setResponsePage(1);
                        setOrder(v);
                      }}
                    />
                  </div>
                </th>
                <th className='pl-4 pt-3 border-0'>
                  {' '}
                  <div className='d-flex align-items-center'>
                    <span>Status</span>
                    <TableSortButton
                      className='ml-1'
                      value={order}
                      topValue='status_desc'
                      bottomValue='status_asc'
                      onChange={(v) => {
                        setResponsePage(1);
                        setOrder(v);
                      }}
                    />
                  </div>
                </th>
                <th className='pl-4 pt-3 border-0'>Needed Review</th>
                <th className='pl-4 pt-3 border-0'>Action</th>
              </tr>
            </thead>
            <tbody>
              {loading ? (
                <tr>
                  <td colSpan={12}>
                    <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                      <i
                        className='fas fa-circle-notch fa-spin text-muted'
                        style={{ fontSize: 48 }}></i>
                    </div>
                  </td>
                </tr>
              ) : (
                <>
                  {currentPageData && currentPageData.length > 0 ? (
                    currentPageData.map((application, key) => (
                      <React.Fragment key={key}>
                        <tr
                          className='center-text font-size-12 font-weight-medium cursor-pointer'
                          onClick={() => toggleRowExpansion(key)}>
                          <GetResponse
                            application={application}
                            index={key}
                            page={responsePage}
                            GetApplicationResponseFunc={GetApplicationResponseFunc}
                            applicationId={applicationId}
                          />
                        </tr>
                        {expandedRows.includes(key) && (
                          <tr>
                            <td colSpan={7} className='p-0'>
                              <div className='expanded-row'>
                                {application.field &&
                                Object.keys(application.field).length > 0 ? (
                                  <ul className='notes-list ml-5 pl-4'>
                                    {Object.keys(application.field).map(
                                      (fItem, index) => (
                                        <li key={index} className='font-size-12'>
                                          <span className='font-weight-medium'>
                                            <u>{fItem.toUpperCase()}</u>:
                                          </span>
                                          {'  '}
                                          {application.field[fItem]}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : application && application.notes ? (
                                  <span className='notes-list font-size-12 ml-5 pl-4'>
                                    {application.notes}
                                  </span>
                                ) : application &&
                                  application.status === 'ADD_QUESTIONS' &&
                                  application.onRampSubApp &&
                                  application.onRampSubApp.length > 0 &&
                                  application.onRampSubApp[0].add_questions &&
                                  application.onRampSubApp[0].add_questions.length > 0 ? (
                                  <ul className='notes-list ml-5 pl-4'>
                                    {application.onRampSubApp[0].add_questions.map(
                                      (que, index) => (
                                        <li key={index} className='font-size-12'>
                                          <span className='font-weight-medium'>
                                            <u>{que.type.toUpperCase()}</u>:
                                          </span>
                                          {'  '}
                                          {que.question}
                                        </li>
                                      )
                                    )}
                                  </ul>
                                ) : (
                                  <div className='no-notes'>No notes available</div>
                                )}
                              </div>
                            </td>
                          </tr>
                        )}
                      </React.Fragment>
                    ))
                  ) : (
                    <tr className='center-text'>
                      <td colSpan={7}>No response found</td>
                    </tr>
                  )}
                </>
              )}
            </tbody>
          </table>
        </div>
      </div>
      <Paginator
        rows={10}
        totalRecords={totalCount}
        first={first}
        onPageChange={(e) => onPageChange(e)}
      />
    </React.Fragment>
  );
}

export default ResponseList;
