import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Collapse, FormGroup, Input, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../../constants/application';
import AuthInput from '../../../../Custom/AuthInput';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import documentUploadIcon from '../../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../../assets/images/documentuploaded.png';
import { ProjectNameInputName } from './helper';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';
import { get } from 'lodash';

function ProjectBackgroundApplication({
  allResponses = [],
  touched = {},
  errors = {},
  handleChange = () => {},
  values = {},
  setFieldTouched = () => {},
  setFieldValue = () => {},
  fileUrls = {},
  isSectionCollapsed,
  toggleSection,
  applicationId,
  setInitialValuesFunc
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);

  useEffect(() => {
    const res = Object.keys(errors).find((i) => ProjectNameInputName.includes(i));
    setErrorShow(!!res);
  }, [errors]);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          ProjectNameInputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);

  const handleSubmit = () => {
    if (
      !ProjectNameInputName.includes(
        Object.keys(errors).find((i) => ProjectNameInputName.includes(i))
      )
    ) {
      setIsLoading(true);
      const payloadData = new FormData();
      payloadData.append('project_name', values.project_name);
      payloadData.append('token_name', values.token_name);
      payloadData.append('project_website', values.project_website);
      payloadData.append('project_description', values.project_description);
      payloadData.append(
        'ticker_logo',
        values['ticker_logo'] ? values['ticker_logo'] : ''
      );

      if (applicationId !== '') {
        UpdateExchangeApplication({ application_id: applicationId }, payloadData)
          .then((res) => {
            const application = get(res, 'data.data.data', '');
            notifySuccess(`Project Background data updated successfully`);
            setIsLoading(false);
            setInitialValuesFunc(application.exchange_application);
          })
          .catch((err) => {
            const errorMessage = get(err, 'data.error.message');
            notifyError(notificationMessageFunction(errorMessage));
          });
      }
    }
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Project Background</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <Row>
                <Col lg={6}>
                  <FormGroup className='mb-4'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='project_name'
                        className='text-black font-weight-normal'>
                        {CONST.PROJECT_NAME}
                      </Label>
                    </div>
                    <div>
                      <AuthInput
                        setFieldTouched={setFieldTouched}
                        withTouchError={true}
                        type='text'
                        className='font-size-12'
                        name='project_name'
                        values={values}
                        handleChange={handleChange}
                        placeholder={CONST.ENTER_PROJECT_NAME}
                        errors={errors}
                        touched={touched}></AuthInput>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className='mb-4'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='token_name'
                        className='text-black font-weight-normal'>
                        {CONST.TOKEN_NAME}
                      </Label>
                    </div>
                    <div>
                      <AuthInput
                        setFieldTouched={setFieldTouched}
                        withTouchError={true}
                        type='text'
                        name='token_name'
                        placeholder={CONST.ENTER_TOKEN_NAME}
                        values={values}
                        className='font-size-12'
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}></AuthInput>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={6}>
                  <FormGroup className='mb-4'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='project_website'
                        className='text-black font-weight-normal'>
                        {CONST.PROJECT_WEBSITE}
                      </Label>
                    </div>
                    <div>
                      <AuthInput
                        setFieldTouched={setFieldTouched}
                        withTouchError={true}
                        type='text'
                        className='font-size-12'
                        name='project_website'
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}></AuthInput>
                    </div>
                  </FormGroup>
                </Col>
                <Col lg={6}>
                  <FormGroup className='mb-4'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='description'
                        className='text-black font-weight-normal'>
                        Token Ticker
                      </Label>
                    </div>
                    <div
                      style={{
                        borderRadius: '8px',
                        border: '1px solid',
                        height: 47
                      }}
                      className='d-flex direction-row justify-content-between'>
                      <div className='d-flex direction-row justify-content-between file-upload-wrapper pr-2'>
                        <Label
                          htmlFor='token_ticker'
                          id='ticker_logo'
                          className='mb-0 ml-3'>
                          <span
                            className='p-1 mb-2 rounded-circle'
                            style={{
                              color: '#134a87',
                              fontSize: 14,
                              cursor: 'pointer'
                            }}>
                            <img
                              src={
                                !values?.ticker_logo
                                  ? documentUploadIcon
                                  : documentUploadedIcon
                              }
                              alt='token_ticker'></img>
                          </span>
                          <span className='font-size-12'>{CONST.ICON}</span>
                        </Label>
                        {fileUrls.ticker_logo && (
                          <a
                            href={fileUrls.ticker_logo}
                            target='_blank'
                            rel='noopener noreferrer'
                            className='font-size-12 font-weight-bold text-wrap text-primary pl-4'>
                            View File
                          </a>
                        )}
                        <Input
                          type='file'
                          accept='image/*'
                          id='token_ticker'
                          name='ticker_logo'
                          onChange={(event) => {
                            setFieldValue('ticker_logo', event.currentTarget.files[0]);
                          }}
                          style={{ display: 'none' }}
                        />
                        {errors.ticker_logo && touched.ticker_logo && (
                          <div
                            style={{ fontSize: 14 }}
                            className='text-left mt-2 text-danger'>
                            {errors.ticker_logo.split('.')[0]}
                          </div>
                        )}
                        <ReactTooltip
                          anchorId='ticker_logo'
                          place='bottom'
                          content={
                            fileUrls['ticker_logo'] && fileUrls['ticker_logo'].name
                          }
                        />
                      </div>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <Row>
                <Col lg={12}>
                  <FormGroup className='mb-4'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='project_description'
                        className='text-black font-weight-normal'>
                        {CONST.PROJECT_DESCRIPTION}
                      </Label>
                    </div>
                    <div>
                      <AuthInput
                        setFieldTouched={setFieldTouched}
                        withTouchError={true}
                        type='textarea'
                        name='project_description'
                        rows='6'
                        className={'border-dark font-size-12'}
                        placeholder={CONST.ENTER_DESCRIPTION}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}></AuthInput>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default ProjectBackgroundApplication;
