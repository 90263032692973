import React, { useState } from 'react';
import { Modal, ModalHeader } from 'reactstrap';
import { completeReqAction } from '../../../services/Business/Application';

function RequestAction(props) {
  const [isLoaded, setIsLoaded] = useState(true);
  const confirmActionComplete = () => {
    setIsLoaded(false);
    completeReqAction(props.responseId)
      .then((res) => {
        setIsLoaded(true);
        props.handleModal();
        props.GetApplicationResponseFunc(props.applicationId);
      })
      .catch((error) => {
        console.log('error completeReqAction', error);
      });
  };
  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.handleModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        style={{ borderRadius: '8px' }}
        className='w-100'>
        <ModalHeader toggle={props.handleModal}>
          <span className='font-size-16'>Request Action</span>
        </ModalHeader>
        <div>
          {!isLoaded ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <>
              <div className='col-12 col-sm-auto font-size-18 pt-3 pb-3 text-center'>
                <span className='text-black'>Have you completed Action?</span>
              </div>
              <div className='font-size-22'></div>
              <div className='d-flex justify-content-center mt-3 mb-4'>
                <button
                  className='btn btn-auth'
                  type='button'
                  onClick={() => {
                    confirmActionComplete();
                  }}
                  style={{ width: 150, height: 40 }}>
                  Confirm
                </button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </div>
  );
}
export default RequestAction;
