import React from 'react';
import { Card } from 'reactstrap';
import GetProvider from './GetProvider';

function ProviderSettings({ isLoading, providersList, setIsLoading, setProvidersList }) {
  return (
    <Card>
      <div className='col-12 col-sm-auto font-size-24 font-weight-medium pb-2 pb-sm-0'>
        <div className='p-4 d-flex justify-content-between'>
          <span className='text-black'>Providers</span>
        </div>
      </div>
      <div className='table-responsive'>
        <table className='table table-centered table-hover font-size-12'>
          <thead className='center-text'>
            <tr>
              <th className='pl-4 pt-3 border-0'>#</th>
              <th className='pl-4 pt-3 border-0'>Logo</th>
              <th className='pl-4 pt-3 border-0'>Provider</th>
              <th className='pl-4 pt-3 border-0'>Status</th>
              <th className='pl-4 pt-3 border-0'>Action</th>
            </tr>
          </thead>
          <tbody>
            {isLoading ? (
              <tr>
                <td colSpan={14}>
                  <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                    <i
                      className='fas fa-circle-notch fa-spin text-muted'
                      style={{ fontSize: 48 }}></i>
                  </div>
                </td>
              </tr>
            ) : (
              <>
                {providersList &&
                  providersList.map((provider, key) => (
                    <GetProvider
                      provider={provider}
                      index={key}
                      setProvidersList={setProvidersList}
                      setIsLoading={setIsLoading}
                    />
                  ))}
              </>
            )}
          </tbody>
        </table>
      </div>
    </Card>
  );
}

export default ProviderSettings;
