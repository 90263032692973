import React, { useEffect, useState } from 'react';
import { Card } from 'reactstrap';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { GetSummaryNotifications } from '../../services/Business/Notification';
import SummaryNotificationGroup from './SummaryNotificationGroup';

function SummaryNotifications(props) {
  const [notifications, setNotifications] = useState({});
  const [loading, setLoading] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  useEffect(() => {
    setLoading(true);
    GetSummaryNotifications()
      .then((res) => {
        setLoading(false);
        setIsLoaded(true);
        const data = res && res.data && res.data.data && res.data.data.data;

        setNotifications(data);
        return data;
      })
      .catch((err) => {
        setLoading(false);
        setIsLoaded(true);
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  }, []);

  const now = new Date();
  const groups = {
    countrySum: {
      title: 'Countries'
    },
    cryptoSum: {
      title: 'Cryptocurrencies'
    },
    minMaxSum: {
      title: 'Min/max'
    },
    networkSum: {
      title: 'Networks'
    },
    paymentMethodSum: {
      title: 'PaymentMethods'
    }
  };

  return (
    <React.Fragment>
      <div className={'page-content ' + (!isLoaded && 'page-content-loading')}>
        {!isLoaded ? (
          <div className="h-100 d-flex align-items-center justify-content-center py-4">
            <i
              className="fas fa-circle-notch fa-spin text-muted"
              style={{ fontSize: 48 }}></i>
          </div>
        ) : (
          <Card className="p-4">
            <div className="d-flex flex-wrap align-items-center mb-3">
              <div className="font-size-24 flex-fill font-weight-medium mr-4 pb-2">
                Notifications
              </div>
              <div className="font-size-20 text-muted pb-2">
                {loading ? (
                  <div className="text-muted font-size-16">
                    <i className="fas fa-circle-notch fa-spin mr-2"></i>
                    <span>Loading...</span>
                  </div>
                ) : (
                  new Intl.DateTimeFormat('en-GB', {
                    dateStyle: 'full'
                  }).format(now)
                )}
              </div>
            </div>
            <div>
              {Object.keys(notifications).map((group) => (
                <SummaryNotificationGroup
                  key={group}
                  notifications={get(notifications, group, [])}
                  title={get(groups, `${group}.title`, group)}
                />
              ))}
              <div className="text-center pt-3" style={{ cursor: 'pointer' }}>
                {[
                  { width: 278, margin: 27 },
                  { width: 197, margin: 21 },
                  { width: 100, margin: 10 }
                ].map((item, i) => (
                  <div
                    key={item.width}
                    className="mx-auto border-bottom border-dark"
                    style={{
                      maxWidth: `${item.width}px`,
                      marginBottom: `${item.margin}px`
                    }}></div>
                ))}
                <span
                  className="font-size-16 font-weight-medium hover:text-dark"
                  style={{ color: '#aaaaaa' }}>
                  view more
                </span>
              </div>
            </div>
          </Card>
        )}
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default SummaryNotifications;
