import React from 'react';
import { Card } from 'reactstrap';
import SimpleBar from 'simplebar-react';
import AddQuestions from '../../Exchange/ExchangeVIewApplication/AddStatusChange/addQuestions';
import { ApplicationContext } from './applicationContext';
import AcceptApplication from '../../Exchange/ExchangeVIewApplication/AddStatusChange/AcceptApplication';
import RejectApplication from '../../Exchange/ExchangeVIewApplication/AddStatusChange/RejectApplication';
import FieldChangesRequest from './FieldChangesRequest';

function ChangeRequests({
  selectedStatus,
  setAddQuestions,
  applicationId,
  handleChangeStatus,
  modal,
  handleModal,
  setUpdatePage,
  updatePage,
  setTextareaValues,
  clickedLabels,
  textareaValues,
  setClickedLabels,
  beneficialusersValues,
  setPage,
  GetApplicationResponseFunc
}) {
  return (
    <SimpleBar style={{ height: '150vh' }}>
      <ApplicationContext.Provider value={applicationId}>
        {selectedStatus === 'ACCEPT' ? (
          <AcceptApplication
            modal={modal}
            handleOpenModal={handleModal}
            setUpdatePage={setUpdatePage}
            updatePage={updatePage}
            GetApplicationResponseFunc={GetApplicationResponseFunc}
          />
        ) : (
          <RejectApplication
            modal={modal}
            handleOpenModal={handleModal}
            setUpdatePage={setUpdatePage}
            updatePage={updatePage}
            GetApplicationResponseFunc={GetApplicationResponseFunc}
          />
        )}
      </ApplicationContext.Provider>
      <div data-simplebar className=''>
        <Card className='p-1'>
          <div className='d-flex justify-content-center mt-2 mb-2'>
            <button
              className={`btn mr-3 btn-success cursor-pointer`}
              onClick={() => {
                handleChangeStatus('ACCEPT');
              }}>
              Accept Application
            </button>

            <button
              className={`btn btn-danger cursor-pointer`}
              onClick={() => {
                handleChangeStatus('REJECT');
              }}>
              Reject Application
            </button>
          </div>
        </Card>{' '}
        <Card className='p-1'>
          <div className='m-3'>
            <AddQuestions
              applicationId={applicationId}
              setAddQuestions={setAddQuestions}
              GetApplicationResponseFunc={GetApplicationResponseFunc}
            />
          </div>
        </Card>
        <Card className='p-1'>
          <div className='m-3'>
            {clickedLabels && clickedLabels.length > 0 ? (
              <FieldChangesRequest
                setTextareaValues={setTextareaValues}
                clickedLabels={clickedLabels}
                textareaValues={textareaValues}
                setClickedLabels={setClickedLabels}
                beneficialusersValues={beneficialusersValues}
                applicationId={applicationId}
                setPage={setPage}
                GetApplicationResponseFunc={GetApplicationResponseFunc}
              />
            ) : (
              'No KYB Field Changes'
            )}
          </div>
        </Card>
      </div>
    </SimpleBar>
  );
}

export default ChangeRequests;
