export const getAllCacheData = async () => {
  try {
    const cacheName = 'allTokensList';
    const cache = await caches.open(cacheName);
    const cachedResponses = await cache.keys();

    let cacheDataArray = [];
    if (cachedResponses && cachedResponses.length > 0) {
      const response = await cache.match(cachedResponses[0]);
      const data = await response.json();
      if (data && data.length > 0) {
        cacheDataArray = JSON.parse(data);
      }
    }

    return cacheDataArray;
  } catch (error) {
    console.error('Error fetching cache data:', error);
  }
};

export const getCachedDate = async () => {
  try {
    const cacheName = 'cachedDate';
    const cache = await caches.open(cacheName);
    const cachedResponses = await cache.keys();
    let cacheDate = '';
    if (cachedResponses && cachedResponses.length > 0) {
      const response = await cache.match(cachedResponses[0]);
      const data = await response.json();
      if (data && data.length > 0) {
        cacheDate = data;
      }
    }
    return cacheDate;
  } catch (error) {
    console.error('Error fetching cache data:', error);
  }
};

export const addDataIntoCache = (cacheName, url, response) => {
  const data = new Response(JSON.stringify(response));
  if ('caches' in window) {
    caches.open(cacheName).then((cache) => {
      cache.put(url, data);
    });
  }
};

export const addFiatDataIntoCache = (cacheName, url, response) => {
  const data = new Response(JSON.stringify(response));
  if ('caches' in window) {
    caches.open(cacheName).then((cache) => {
      cache.put(url, data);
    });
  }
};

export const getAllCacheFiats = async () => {
  try {
    const cacheName = 'allFiatsList';
    const cache = await caches.open(cacheName);
    const cachedResponses = await cache.keys();
    let cacheDataArray = [];
    if (cachedResponses && cachedResponses.length > 0) {
      const response = await cache.match(cachedResponses[0]);
      const data = await response.json();
      if (data && data.length > 0) {
        cacheDataArray = JSON.parse(data);
      }
    }
    return cacheDataArray;
  } catch (error) {
    console.error('Error fetching cache data:', error);
  }
};
