import api from '../../api/userApi';
import * as AdminURL from '../../api/userURL';

export const GetAllProviders = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(AdminURL.PROVIDER)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetProvidersDetail = (provider_id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(AdminURL.PROVIDER_DETAIL + `?provider_id=${provider_id}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
