import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const GetNetworkDetail = (networkId, provider) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.NETWORK_DETAIL +
          `?network_id=${networkId}` +
          (provider ? `&provider=${provider}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetTotalCoverage = ({ limit, page, crypto, order, providers }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.TOTAL_COVERAGE +
          `?page=${page}` +
          (limit ? `&limit=${limit}` : '') +
          (order ? `&order=${order}` : '') +
          (providers ? `&providers=${providers}` : '') +
          (crypto ? `&crypto=${crypto}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetAllFiats = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.ALL_FIATS)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetAllCoins = ({ query = 'buy' }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(`${businessURL.ALL_COINS}?query=${query}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetAllCountries = (accessToken) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(`${businessURL.ALL_COUNTRIES}?accessToken=${accessToken}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetAllSwingSwapCoins = ({ accessToken }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(`${businessURL.GET_SWING_TOKENS}?accessToken=${accessToken}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetPaymentOptions = (currency = 'USD') => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(`${businessURL.PAYMENT_OPTIONS}?currency=${currency}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetPriceCompareBuy = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.PRICE_COMPARE_BUY, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetPriceCompareSell = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.PRICE_COMPARE_SELL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const AddOnRampTrial = (data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(businessURL.TRIAL, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
