import React, { useState } from 'react';
import { get } from 'lodash';
import CoinButton from '../../BuySellWidget/CoinButton';

import { Col, Input, Row } from 'reactstrap';
import DefaultToken from '../../WidgetCustomization1Buy.js/DefaultToken';
import DefaultFiats from '../../WidgetCustomization1Buy.js/DefaultFiats';
import SwingSwapCoins from '../../WidgetCustomization1Buy.js/SwingSwapCoins';
import OnlyCrypto from '../../WidgetCustomization1Buy.js/OnlyCrypto';
import { Drawer } from '@mui/material';

function DefaultSelectedValues({
  widgetTheme,
  setActiveTab = () => {},
  defaultCoinSelect,
  setDefaultCoinSelect,
  defaultFiatSelect,
  setDefaultFiatSelect,
  allFiats,
  allSwap,
  allCoins,
  sendCoin,
  setSendCoin,
  receiveCoin,
  setReceiveCoin,
  amountPay,
  setAmountPay,
  fromTokenAmount,
  setFromTokenAmount,
  checkedCoins,
  setCheckedCoins,
  applyDefaultValuesChanges,
  setFromCoin,
  fromNetwork,
  setFromNetwork,
  toNetwork,
  setToCoin,
  setToNetwork,
  setDefaultCoin,
  setDefaultFiat
}) {
  const [defaultCoinOpt, setDefaultCoinOpt] = useState(false);
  const [defaultFiatOpt, setDefaultFiatOpt] = useState(false);
  const [onlyCryptoShowTokens, setOnlyCryptoShowTokens] = useState(false);
  const [sendShowTokens, setSendShowTokens] = useState(false);
  const [receiveShowTokens, setReceiveShowTokens] = useState(false);
  return (
    <div className='mt-1'>
      <Row>
        <Col lg={4}>
          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <span className='font-size-12 text-black'>Default Coin</span>

            <CoinButton
              label={get(defaultCoinSelect, 'coin_name')}
              logo={get(defaultCoinSelect, 'logo')}
              onClick={() => {
                setActiveTab('buy');
                setDefaultCoinOpt(!defaultCoinOpt);
              }}
              isCustom={true}
              widgetTheme={widgetTheme}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <span className='font-size-12 text-black'>Default Fiat</span>
            <CoinButton
              label={get(defaultFiatSelect, 'currency')}
              symbol={get(defaultFiatSelect, 'symbol')}
              onClick={() => {
                setActiveTab('buy');
                setDefaultFiatOpt(true);
              }}
              isCustom={true}
              widgetTheme={widgetTheme}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <span className='font-size-12 text-black'>Swap Send Coin</span>
            <CoinButton
              label={get(sendCoin, 'symbol')}
              logo={get(sendCoin, 'logo')}
              chain={get(sendCoin, 'slug')}
              onClick={() => {
                setActiveTab('swap');
                setSendShowTokens(true);
              }}
              isCustom={true}
              widgetTheme={widgetTheme}
            />
          </div>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col lg={4}>
          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <span className='font-size-12 text-black'>Swap Receive Coin</span>
            <CoinButton
              label={get(receiveCoin, 'symbol')}
              logo={get(receiveCoin, 'logo')}
              chain={get(receiveCoin, 'slug')}
              onClick={() => {
                setActiveTab('swap');
                setReceiveShowTokens(true);
              }}
              widgetTheme={widgetTheme}
              isCustom={true}
            />
          </div>
        </Col>
        <Col lg={4}>
          <div
            className='d-flex align-items-center cursor-pointer'
            style={{ flexDirection: 'column' }}>
            <span className='font-size-12 text-black'>Only Tokens</span>
            <CoinButton
              label={
                checkedCoins && checkedCoins.length > 0
                  ? checkedCoins.length + ' coins selected'
                  : '0 coin selected'
              }
              logo={''}
              onClick={() => {
                setActiveTab('buy');
                setOnlyCryptoShowTokens(true);
              }}
              isCustom={true}
              widgetTheme={widgetTheme}
            />
          </div>
        </Col>
      </Row>
      <Row className='mt-3'>
        <Col lg={6}>
          <div
            className='d-flex align-items-center cursor-pointer mr-2'
            style={{ flexDirection: 'column' }}>
            <span className='font-size-12 text-black'>You Pay Amount(Buy)</span>
            <Input
              type='text'
              placeholder='e.g. 100'
              onChange={(evt) => setAmountPay(evt.target.value.replace(/[^\d.]/g, ''))}
              value={amountPay}
            />
          </div>
        </Col>
        <Col lg={6}>
          <div
            className='d-flex align-items-center cursor-pointer ml-2'
            style={{ flexDirection: 'column' }}>
            <span className='font-size-12 text-black'>You Send Amount(Swap)</span>
            <Input
              type='text'
              placeholder='e.g. 0.1'
              onChange={(evt) =>
                setFromTokenAmount(evt.target.value.replace(/[^\d.]/g, ''))
              }
              value={fromTokenAmount}
            />
          </div>
        </Col>
      </Row>
      <div className='mb-3 ml-3 mt-3'>
        <button
          type='button'
          name='btn'
          className={`btn btn-auth`}
          onClick={() => applyDefaultValuesChanges()}
          style={{ width: 120, marginLeft: 0 }}>
          Apply
        </button>
      </div>
      <Drawer
        anchor='bottom'
        open={
          defaultCoinOpt ||
          defaultFiatOpt ||
          sendShowTokens ||
          receiveShowTokens ||
          onlyCryptoShowTokens
        }
        onClose={() => {
          setDefaultCoinOpt(false);
          setDefaultFiatOpt(false);
          setSendShowTokens(false);
          setReceiveShowTokens(false);
          setOnlyCryptoShowTokens(false);
        }}>
        {defaultCoinOpt && (
          <DefaultToken
            coins={allCoins}
            onSelect={(v) => {
              setDefaultCoinSelect(v);
              setDefaultCoin(v.coin_symbol);
              setDefaultCoinOpt(false);
            }}
            onClose={() => setDefaultCoinOpt(false)}
          />
        )}
        {defaultFiatOpt && (
          <DefaultFiats
            fiats={allFiats}
            onSelect={(v) => {
              setDefaultFiatSelect(v);
              setDefaultFiat(v?.currency);
              setDefaultFiatOpt(false);
            }}
            onClose={() => setDefaultCoinOpt(false)}
          />
        )}

        {sendShowTokens && (
          <SwingSwapCoins
            coins={allSwap}
            currentCoin={sendCoin}
            networkState={(v) => {
              setFromNetwork(v);
            }}
            onSelect={(v) => {
              setSendCoin(v);
              setFromCoin(v.symbol);
              setSendShowTokens(false);
            }}
            onClose={() => setSendShowTokens(false)}
            widgetTheme={widgetTheme}
          />
        )}
        {receiveShowTokens && (
          <SwingSwapCoins
            coins={allSwap}
            currentCoin={receiveCoin}
            networkState={(v) => setToNetwork(v)}
            onSelect={(v) => {
              setReceiveCoin(v);
              setToCoin(v.symbol);
              setReceiveShowTokens(false);
            }}
            onClose={() => setReceiveShowTokens(false)}
            isReceiveCoin={true}
            widgetTheme={widgetTheme}
          />
        )}
        {onlyCryptoShowTokens && (
          <OnlyCrypto
            coins={allCoins}
            onClose={() => setOnlyCryptoShowTokens(false)}
            isReceiveCoin={true}
            widgetTheme={widgetTheme}
            checkedCoins={checkedCoins}
            setCheckedCoins={setCheckedCoins}
          />
        )}
      </Drawer>
    </div>
  );
}

export default DefaultSelectedValues;
