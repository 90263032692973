import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Input, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import {
  CreateApplication,
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import get from 'lodash/get';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepTwo(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const ProjectBackgroundSchema = Yup.object().shape({
    project_name: Yup.string().required(ERROR_CONST.PROJECT_NAME),
    token_name: Yup.string().required(ERROR_CONST.TOKEN_NAME),

    project_website: Yup.string()
      .url(ERROR_CONST.INVALID_URL) // Make sure it's a valid URL.
      .required(ERROR_CONST.PROJECT_WEBSITE),

    project_description: Yup.string().required(ERROR_CONST.PROJECT_DESCRIPTION),

    ticker_logo: Yup.mixed()
  });

  const [initialValue, setInitialValue] = useState({
    project_name: '',
    token_name: '',
    ticker_logo: '',
    project_website: '',
    project_description: ''
  });
  const step = 1;

  const [logoFile, setLogoFile] = useState({});
  const [fileUrls, setFileUrls] = useState('');
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        setInitialValue({
          project_name: application.exchange_application.project_name,
          token_name: application.exchange_application.token_name,
          project_website: application.exchange_application.project_website,
          project_description: application.exchange_application.project_description,
          ticker_logo: application.exchange_application.ticker_logo
        });

        setCompleteStep(application.exchange_application.percentage_complete);
        if (application.exchange_application.ticker_logo)
          setFileUrls(application.exchange_application.ticker_logo);
      });
    }
  }, []);

  const changeHandler = (event) => {
    if (event.target.files[0])
      setLogoFile({ ...logoFile, [event.target.name]: event.target.files[0] });
  };

  const handleSubmit = (values) => {
    const { nextPage } = props;
    const payloadData = new FormData();
    payloadData.append('project_name', values.project_name);
    payloadData.append('token_name', values.token_name);
    payloadData.append('project_website', values.project_website);
    payloadData.append('project_description', values.project_description);
    payloadData.append(
      'ticker_logo',
      logoFile['ticker_logo'] ? logoFile['ticker_logo'] : ''
    );

    if (Object.keys(logoFile).length === 0 && fileUrls === '') {
      notifyError('Ticker logo is required');
      return;
    }
    let stepsRequired = Math.floor(StepsCalculator(5, completeStep, 28));
    payloadData.append('percentage_complete', stepsRequired);
    JSON.stringify(payloadData);
    setIsLoading(true);

    if (props.applicationId !== '') {
      UpdateExchangeApplication({ application_id: props.applicationId }, payloadData)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          props.setApplicationData(application);
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    } else {
      CreateApplication(payloadData)
        .then((res) => {
          props.setApplicationId(get(res, 'data.data.data', ''));
          nextPage();
          setIsLoading(false);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
    setExchangeCompleteStep([0, 1, 2]);
  };
  const calculateProgress = () => {
    return completeStep;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>Project Background</span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            enableReinitialize={true}
            initialValues={initialValue}
            validationSchema={ProjectBackgroundSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <Row>
                  <Col lg={6} md={12} sm={12}>
                    <CustomInput
                      label={CONST.PROJECT_NAME}
                      htmlFor='project_name'
                      name='project_name'
                      placeholder={CONST.ENTER_PROJECT_NAME}
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                  <Col lg={6} md={12} sm={12}>
                    <CustomInput
                      label={CONST.TOKEN_NAME}
                      htmlFor='token_name'
                      name='token_name'
                      placeholder={CONST.ENTER_TOKEN_NAME}
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12}>
                    <CustomInput
                      label={CONST.PROJECT_WEBSITE}
                      htmlFor='project_website'
                      name='project_website'
                      placeholder={CONST.ENTER_URL_PLACEHOLDER}
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={12} md={12} sm={12}>
                    <CustomInput
                      label={CONST.PROJECT_DESCRIPTION}
                      htmlFor='project_description'
                      name='project_description'
                      placeholder={CONST.ENTER_DESCRIPTION}
                      values={values}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      type='textarea'
                    />
                  </Col>
                </Row>
                <Row>
                  <Col lg={6} md={12} sm={12}>
                    <FormGroup className='mb-4'>
                      <div className='font-size-12'>
                        <Label
                          htmlFor='token_ticker_doc'
                          className='text-black font-weight-normal'>
                          Ticker Logo
                        </Label>
                      </div>
                      <div
                        style={{
                          borderRadius: '8px',
                          border: '1px solid',
                          height: 47
                        }}
                        className='d-flex direction-row justify-content-between align-items-center pl-1'>
                        <Label htmlFor='ticker_logo' className='mt-2'>
                          <div className='cursor-pointer'>
                            <span
                              className='rounded-circle'
                              style={{ color: '#134a87', fontSize: 12 }}>
                              <img
                                src={
                                  values.ticker_logo || logoFile['ticker_logo']
                                    ? documentUploadedIcon
                                    : documentUploadIcon
                                }
                                alt='ticker_logo'
                              />
                            </span>
                            <span className='font-size-12 ml-2'>{CONST.ICON}</span>
                          </div>
                        </Label>
                        <div>
                          {fileUrls.ticker_logo ? (
                            <a
                              href={fileUrls.ticker_logo}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                              View File
                            </a>
                          ) : values.ticker_logo ? (
                            <a
                              href={values.ticker_logo}
                              target='_blank'
                              rel='noopener noreferrer'
                              className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'>
                              View File
                            </a>
                          ) : (
                            ''
                          )}
                        </div>
                        <Input
                          type='file'
                          id='ticker_logo'
                          name='ticker_logo'
                          accept='image/*'
                          onChange={(event) => {
                            handleChange(event);
                            changeHandler(event);
                          }}
                          style={{ display: 'none' }}
                        />
                        <ReactTooltip
                          anchorId='ticker_logo'
                          place='bottom'
                          content={
                            logoFile['ticker_logo'] && logoFile['ticker_logo'].name
                          }
                        />
                      </div>
                      {errors.ticker_logo && touched.ticker_logo ? (
                        <div
                          style={{ fontSize: 12 }}
                          className='text-left mt-1 text-danger'>
                          {errors.ticker_logo}
                        </div>
                      ) : Object.keys(logoFile).length === 0 && fileUrls === '' ? (
                        <div
                          style={{ fontSize: 12 }}
                          className='text-left mt-1 text-danger'>
                          Ticker logo is required
                        </div>
                      ) : (
                        ''
                      )}
                    </FormGroup>
                  </Col>
                </Row>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth ml-2'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepTwo;

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel
}) => (
  <FormGroup className='mb-2'>
    <div className='font-size-12'>
      <Label htmlFor={htmlFor} className='text-black font-weight-normal'>
        {label} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12'}
        placeholder={placeholder}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
