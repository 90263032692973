import React from 'react';
import { Button, Input, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';

function APIActivationModal({
  approveAPIKeyFunction,
  modalOpen,
  setModalOpen,
  setAccountPassword,
  userData,
  accountPassword
}) {
  return (
    <Modal
      isOpen={modalOpen}
      toggle={() => {
        setModalOpen(!modalOpen);
        setAccountPassword('');
      }}
      centered>
      <ModalHeader
        toggle={() => {
          setModalOpen(!modalOpen);
          setAccountPassword('');
        }}>
        Approve Request
      </ModalHeader>
      <ModalBody>
        <span>
          Do you really want to{' '}
          {userData.api_status === 0
            ? 'revoke'
            : userData.api_status === 1
            ? 'approve'
            : ''}{' '}
          the permission of this API key?
        </span>
        <br />
        <br />
        <span>Enter login password: </span>
        <br />
        <Input
          type='password'
          className='border-dark'
          placeholder='Enter your password'
          rows='6'
          name='password'
          onChange={(e) => setAccountPassword(e.target.value)}
          style={{ paddingRight: '30px', borderRadius: '8px' }}
        />
      </ModalBody>
      <ModalFooter>
        <Button
          color='primary'
          onClick={() =>
            approveAPIKeyFunction(
              userData.user_id,
              userData.api_status === 1
                ? 0
                : userData.api_status === 2 || userData.api_status === 0
                ? 1
                : 0,
              accountPassword
            )
          }>
          {userData.api_status === 1
            ? 'Revoke'
            : userData.api_status === 2 || userData.api_status === 0
            ? 'Approve'
            : ''}
        </Button>
        <Button
          color='danger'
          onClick={() => {
            setModalOpen(!modalOpen);
            setAccountPassword('');
          }}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
}

export default APIActivationModal;
