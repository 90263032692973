import React, { useEffect, useState } from 'react';
import { ChainsSupportedInputName } from './helper';
import { Card, CardBody, Col, Collapse, FormGroup, Label, Row } from 'reactstrap';
import { ErrorMessage } from 'formik';
import AuthInput from '../../../../Custom/AuthInput';
import { constants as CONST } from '../../../../constants/application';
import Select from 'react-select';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { get } from 'lodash';
import { notificationMessageFunction } from '../../../../constants/notificationConst';

function ChainSupport({
  allResponses = [],
  touched = {},
  errors = {},
  handleChange = () => {},
  values = {},
  setFieldTouched = () => {},
  allNetworks = {},
  setSelectnetworkList = () => {},
  setFieldValue = () => {},
  selectNetworkList = {},
  isSectionCollapsed,
  toggleSection,
  applicationId,
  setInitialValuesFunc
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          ChainsSupportedInputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);

  useEffect(() => {
    const res = Object.keys(errors).find((i) => ChainsSupportedInputName.includes(i));
    setErrorShow(!!res);
  }, [errors]);

  const handleSubmit = () => {
    if (
      !ChainsSupportedInputName.includes(
        Object.keys(errors).find((i) => ChainsSupportedInputName.includes(i))
      )
    ) {
      setIsLoading(true);
      const data = {
        blockchain_network: selectNetworkList,
        other_network: values.other_network,
        token_address: values.token_address
      };

      if (applicationId !== '') {
        UpdateExchangeApplication({ application_id: applicationId }, data)
          .then((res) => {
            const application = get(res, 'data.data.data', '');
            setIsLoading(false);
            notifySuccess(`Chains Supported data updated successfully`);
            setInitialValuesFunc(application.exchange_application);
          })
          .catch((err) => {
            const errorMessage = get(err, 'data.error.message');
            notifyError(notificationMessageFunction(errorMessage));
          });
      }
    }
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Chains Supported</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <Row>
                <Col lg={6}>
                  <FormGroup className='mb-4'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='blockchain_network'
                        className='text-black font-weight-normal'>
                        {CONST.NETWORK}
                      </Label>
                    </div>
                    <div>
                      <Select
                        className='select-input border-dark font-size-12'
                        style={{
                          paddingRight: '30px',
                          borderRadius: '8px'
                        }}
                        options={allNetworks}
                        value={allNetworks.find((c) => c.value === selectNetworkList)}
                        onChange={(val) => {
                          setFieldValue('blockchain_network', val.value);
                          setSelectnetworkList(val.value);
                        }}
                        name='blockchain_network'
                      />
                      <ErrorMessage name='blockchain_network'>
                        {(errorMsg) => (
                          <div className='font-size-12' style={{ color: 'red' }}>
                            {errorMsg}
                          </div>
                        )}
                      </ErrorMessage>
                    </div>
                  </FormGroup>
                </Col>
                {values.blockchain_network === 'Other' && (
                  <Col lg={6}>
                    <FormGroup className='mb-4'>
                      <div className='font-size-12'>
                        <Label
                          htmlFor='other_network'
                          className='text-black font-weight-normal'>
                          {CONST.OTHER_NETWORK}
                        </Label>
                      </div>
                      <div>
                        <AuthInput
                          setFieldTouched={setFieldTouched}
                          withTouchError={true}
                          type='text'
                          name='other_network'
                          className='font-size-12'
                          placeholder={CONST.ENTER_OTHER_NETWORK}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}></AuthInput>
                      </div>
                    </FormGroup>
                  </Col>
                )}
                <Col lg={6}>
                  <FormGroup className='mb-4'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='token_address'
                        className='text-black font-weight-normal'>
                        {CONST.TOKEN_ADDRESS}
                      </Label>
                    </div>
                    <div>
                      <AuthInput
                        type='text'
                        name='token_address'
                        values={values}
                        className='font-size-12'
                        handleChange={handleChange}
                        placeholder={CONST.ENTER_TOKEN_ADDRESS_NAME}
                        errors={errors}
                        touched={touched}></AuthInput>
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default ChainSupport;
