import React, { useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalHeader, Row } from 'reactstrap';
import {
  GetApplication,
  completeAddQuestions
} from '../../../services/Business/Application';
import get from 'lodash/get';
import AuthInput from '../../../Custom/AuthInput';

const FileQuestion = ({ question, handleInputChange, handleModal }) => (
  <Col lg={12} className='mb-3'>
    <Label className='pt-2'>{question.question}</Label>
    <FormGroup
      className='d-flex direction-row justify-content-between align-items-center pl-1'
      style={{
        borderRadius: '8px',
        border: '1px solid',
        height: 45
      }}>
      <Label htmlFor={`${question._id}_file`} style={{ cursor: 'pointer' }}>
        <span className='d-flex align-items-center justify-content-center mt-2 ml-2'>
          Upload File
        </span>
        <Input
          type='file'
          id={`${question._id}_file`}
          onChange={(e) => handleInputChange(e, question)}
          style={{ display: 'none' }}
        />
      </Label>
      {question.answer && (
        <a
          className='pr-2 font-size-12 font-weight-bold text-wrap text-primary'
          href={question.answer}
          target='_blank'
          rel='noopener noreferrer'>
          View File
        </a>
      )}
    </FormGroup>
  </Col>
);

const TextQuestion = ({ question, handleInputChange }) => (
  <Col lg={12} className='mb-3'>
    <Label className='pt-2 font-size-12'>{question.question}</Label>
    <FormGroup>
      <Input
        type='textarea'
        id={`${question._id}_answer`}
        rows='6'
        value={question.answer}
        className={'font-size-12 border-dark'}
        onChange={(e) => handleInputChange(e, question)}
      />
    </FormGroup>
  </Col>
);

const AddQuestionAnswer = ({
  modal,
  responseId,
  subAppId,
  handleModal,
  GetApplicationResponseFunc,
  applicationId
}) => {
  const [formData, setFormData] = useState([]);
  const [isLoaded, setIsLoaded] = useState(true);
  const [questionList, setQuestionList] = useState([]);

  useEffect(() => {
    if (modal) {
      GetApplication({ sub_application_id: subAppId }).then((res) => {
        const application = get(res, 'data.data.data[0]', {});
        setQuestionList(application?.add_questions || []);
      });
    }
  }, [modal]);

  const handleInputChange = (e, question) => {
    const { value, type } = e.target;
    if (type === 'file') {
      const updatedAnswer = { _id: question._id, answer: e.target.files[0] };

      setFormData((prevFormData) => [...prevFormData, updatedAnswer]);
    } else {
      setFormData((prevFormData) => {
        const answerExists = prevFormData.some((answer) => answer._id === question._id);
        if (answerExists) {
          return prevFormData.map((answer) =>
            answer._id === question._id ? { ...answer, answer: value } : answer
          );
        } else {
          return [...prevFormData, { _id: question._id, answer: value }];
        }
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoaded(false);
    const payload = new FormData();
    formData.forEach((question) => {
      if (question.type === 'file') {
        payload.append(question._id, question.answer, question.answer.name);
      } else {
        payload.append(question._id, question.answer);
      }
    });
    completeAddQuestions(responseId, subAppId, payload)
      .then((res) => {
        GetApplicationResponseFunc(applicationId);

        setIsLoaded(true);
        handleModal();
      })
      .catch((err) => {});
  };

  return (
    <Modal isOpen={modal} toggle={handleModal} centered>
      <ModalHeader toggle={handleModal}>Answer Question</ModalHeader>
      <div className='modal-body'>
        {!isLoaded ? (
          <div>Loading...</div>
        ) : (
          <form onSubmit={handleSubmit}>
            <Row>
              {questionList.map((question) =>
                question.type === 'file' ? (
                  <FileQuestion
                    key={question._id}
                    question={question}
                    handleInputChange={handleInputChange}
                  />
                ) : (
                  <TextQuestion
                    key={question._id}
                    question={question}
                    handleInputChange={handleInputChange}
                  />
                )
              )}
            </Row>
            <div className='text-center'>
              <button
                type='submit'
                className='btn mt-2 btn-auth'
                style={{ width: 150, height: 40 }}>
                Submit
              </button>
            </div>{' '}
          </form>
        )}
      </div>
    </Modal>
  );
};

export default AddQuestionAnswer;
