import React, { useMemo, useState } from 'react';
import { get } from 'lodash';
import { FixedSizeList } from 'react-window';
import { Input } from 'reactstrap';

function OnlyCrypto({
  coins,
  onClose = () => {},
  onSelect = () => {},
  checkedCoins,
  setCheckedCoins
}) {
  const [search, setSearch] = useState('');

  const filteredCoins = useMemo(
    () =>
      search
        ? coins.filter(
            ({ coin_name, crypto_name }) =>
              !search ||
              [coin_name, crypto_name]
                .join('')
                .toLowerCase()
                .includes(search.toLowerCase())
          )
        : coins,
    [coins, search]
  );
  const filteredAndSortedCoins = useMemo(() => {
    const uncheckedCoins = filteredCoins.filter(
      (coin) => !checkedCoins.includes(coin.coin_symbol)
    );
    const checkedCoinsArr = filteredCoins.filter((coin) =>
      checkedCoins.includes(coin.coin_symbol)
    );
    const sortedAndUncheckedCoins = [...checkedCoinsArr, ...uncheckedCoins];

    return sortedAndUncheckedCoins.filter(
      ({ coin_name, crypto_name }) =>
        !search ||
        [coin_name, crypto_name].join('').toLowerCase().includes(search.toLowerCase())
    );
  }, [checkedCoins, filteredCoins, search]);

  const handleCheckboxChange = (coin, isChecked) => {
    setCheckedCoins((prevCheckedCoins) => {
      if (isChecked) {
        return [...prevCheckedCoins, coin.coin_symbol];
      } else {
        return prevCheckedCoins.filter((c) => c !== coin.coin_symbol);
      }
    });
  };
  const CoinRow = React.memo(({ index, style }) => {
    return (
      <GetCoin
        key={index}
        style={style}
        onSelect={onSelect}
        coin={filteredAndSortedCoins[index]}
        onCheckboxChange={handleCheckboxChange}
        isChecked={checkedCoins.includes(filteredAndSortedCoins[index].coin_symbol)}
      />
    );
  });
  return (
    <div className={'py-3 '} style={{ background: `var(--light-container-color)` }}>
      <div className='d-flex align-items-center text-truncate mb-3 mx-2'>
        <div
          className='font-size-18 font-weight-bold flex-fill text-center'
          style={{ color: `var(--light-primary-text-color)` }}>
          Select CryptoCurrency
        </div>
      </div>
      <i
        type='button'
        onClick={onClose}
        className='btn btn-link p-0 position-absolute font-size-24 mdi mdi-close'
        style={{
          right: 16,
          top: 12,
          zIndex: 1,
          color: `var(--light-primary-text-color)`
        }}></i>
      <div className='mx-3 px-3 pb-3'>
        <input
          type='text'
          className='form-control form-control-search mx-1'
          placeholder='Search'
          value={search}
          onInput={(e) => setSearch(e.target.value)}
          onChange={(e) => setSearch(e.target.value)}
          style={{
            minWidth: '200px',
            maxWidth: '100%',
            color: `var(--light-primary-text-color)`
          }}
        />
      </div>
      <FixedSizeList
        className='scroll-1'
        innerElementType='ul'
        itemCount={filteredCoins.length}
        itemSize={62}
        height={360}>
        {({ index, style }) => {
          return (
            <li style={style}>
              <CoinRow index={index} style={style} />
            </li>
          );
        }}
      </FixedSizeList>
    </div>
  );
}

export default OnlyCrypto;

export const GetCoin = ({
  onSelect,
  coin,
  onCheckboxChange,
  isChecked,
  isStakingChecked
}) => {
  const [isHovered, setIsHovered] = useState(false);
  const handleMouseEnter = () => {
    setIsHovered(true);
  };

  const handleMouseLeave = () => {
    setIsHovered(false);
  };
  return (
    <div
      style={{
        background: isHovered
          ? `var(--light-primary-text-color)`
          : `var(--light-container-color)`
      }}
      onMouseOver={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className='d-flex align-items-center text-truncate cursor-pointer rounded py-2 px-3 mx-3'
      onClick={() => {
        onSelect(coin);
      }}>
      <Input
        name='coin'
        type='checkbox'
        onChange={() => onCheckboxChange(coin, !isChecked)}
        checked={isChecked}
        value={isChecked}
        className='form-check-input font-size-12'
      />

      <img
        src={coin.logo}
        alt=''
        width={36}
        style={{
          maxHeight: '36px',
          background: `var(--light-primary-text-color)`,
          color: `var(--light-container-color)`
        }}
        className='rounded-circle mr-3'
      />
      <div>
        <span
          className='font-size-16 text-uppercase mr-1 font-weight-medium'
          style={{
            color: isHovered
              ? `var(--light-container-color)`
              : `var(--light-primary-text-color)`
          }}>
          {coin.coin_name}{' '}
          {coin.only_swing && (
            <span
              style={{
                color: isHovered
                  ? `var(--light-container-color)`
                  : `var(--light-primary-text-color)`
              }}
              className='font-size-10'>
              2️⃣ Step Process
            </span>
          )}
        </span>
        <br />
        <span
          className='font-size-12 text-uppercase mr-1'
          style={{
            color: isHovered
              ? `var(--light-container-color)`
              : `var(--light-primary-text-color)`
          }}>
          {coin.crypto_name}
          {isStakingChecked === 'staking' && (
            <span
              style={{
                color: isHovered
                  ? `var(--light-container-color)`
                  : `var(--light-primary-text-color)`
              }}
              className='font-size-10'>
              {coin.integration}
            </span>
          )}
        </span>
      </div>

      <div className='ml-auto badge font-size-12'>
        <span
          className='text-uppercase'
          style={{
            color: isHovered
              ? `var(--light-container-color)`
              : `var(--light-primary-text-color)`
          }}>
          {get(coin, 'network_symbol', '')}
        </span>
        {coin.popularity > 0 && (
          <span
            className='ml-1'
            style={{
              paddingLeft: 4,
              paddingRight: 4,
              borderRadius: 4,
              background: '#f0f3fe',
              color: '#7e50f5'
            }}>
            Popular
          </span>
        )}
      </div>
    </div>
  );
};
