import React, { useState } from 'react';
import BeneficiaryUser from './BeneficiaryUser';
import Certifications from './Certifications';
import Congratulations from './Congratulations';
import GeneralInformationPage from './GeneralInformationPage';
import IntegrationDetailsPage1 from './IntegrationDetailsPage1';
import IntegrationDetailsPage2 from './IntegrationDetailsPage2';
import VerticalStepper from '../../../Custom/VerticalStepper';
import { Col, Row } from 'reactstrap';
import { NotificationToastContainer } from '../../../Custom/notification';

function CreateOnRampApplication() {
  const [step, setStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState([0]);
  const [applicationId, setApplicationId] = useState('');

  const prevPage = () => {
    if (step) setStep(step - 1);
  };
  const nextPage = () => {
    if (step < 5) setStep(step + 1);
  };
  const currentComponent = () => {
    switch (step) {
      case 0:
        return (
          <GeneralInformationPage
            setCompletedSteps={setCompletedSteps}
            applicationId={applicationId}
            setApplicationId={setApplicationId}
            nextPage={nextPage}
          />
        );
      case 1:
        return (
          <BeneficiaryUser
            setCompletedSteps={setCompletedSteps}
            applicationId={applicationId}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        );
      case 2:
        return (
          <IntegrationDetailsPage1
            setCompletedSteps={setCompletedSteps}
            applicationId={applicationId}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        );
      case 3:
        return (
          <IntegrationDetailsPage2
            setCompletedSteps={setCompletedSteps}
            applicationId={applicationId}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        );
      case 4:
        return (
          <Certifications
            setCompletedSteps={setCompletedSteps}
            applicationId={applicationId}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        );
      case 5:
        return <Congratulations setCompletedSteps={setCompletedSteps} />;

      default:
      // do nothing
    }
  };

  const stepsContent = [
    'General Information',
    'Beneficial Ownership',
    'Wallet and Fund Flow Details',
    'Partner Entity Compliance and Documentation',
    'Certifications',
    'Finish'
  ];

  return (
    <React.Fragment>
      <div className='page-content'>
        <Row>
          <Col lg={3} md={3} sm={3}>
            <div className='card p-3'>
              <VerticalStepper
                steps={stepsContent}
                currentStep={step}
                completedSteps={completedSteps}
                onSelectStep={setStep}
              />
            </div>
          </Col>
          <Col lg={9} md={9} sm={9}>
            <div className='card'>
              <div className='p-4'>{currentComponent()}</div>
            </div>
          </Col>
        </Row>
      </div>
      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default CreateOnRampApplication;
