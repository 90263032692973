import React, { useEffect, useState } from 'react';
import { Col, Container, Row, FormGroup, Label, Progress } from 'reactstrap';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';

import { StepsCalculator } from './StepsCalculator';
import './TeamMembers.css';
import AuthInput from '../../../Custom/AuthInput';
import { notifyError } from '../../../Custom/notification';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import moment from 'moment';

const getMemberSchema = (length) => {
  let schema = {};
  for (let index = 0; index < length; index++) {
    schema = {
      ...schema,
      [`first_name_${index}`]: Yup.string(),
      [`last_name_${index}`]: Yup.string(),
      [`email_${index}`]: Yup.string().email('Invalid Email'),
      [`linkedin_${index}`]: Yup.string(),
      [`position_${index}`]: Yup.string(),
      [`start_date_${index}`]: Yup.date(),
      [`end_date_${index}`]: Yup.date()
    };
  }
  return Yup.object().shape(schema);
};

function TeamMembers(props) {
  const { setExchangeCompleteStep, setIsLoading, applicationId } = props;

  const [teamMembers, setTeamMembers] = useState([{}]);
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (applicationId) {
      GetApplication({ application_id: applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.team_members.length > 0) {
          let teamMembersValues = [];
          for (const member of application.exchange_application.team_members) {
            teamMembersValues.push({
              first_name: member.first_name,
              last_name: member.last_name,
              email: member.email,
              linkedin: member.linkedin,
              position: member.position,
              start_date:
                member && member.previous_experience
                  ? moment(member.previous_experience[0].start_date).format('YYYY-MM-DD')
                  : '',
              end_date:
                member && member.previous_experience
                  ? moment(member.previous_experience[0].end_date).format('YYYY-MM-DD')
                  : ''
            });
          }
          setTeamMembers(application.exchange_application.team_members);
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, [applicationId]);

  const generateInitialValues = () => {
    const initialValues = {};
    teamMembers.forEach((member, index) => {
      initialValues[`first_name_${index}`] = member.first_name || '';
      initialValues[`last_name_${index}`] = member.last_name || '';
      initialValues[`email_${index}`] = member.email || '';
      initialValues[`linkedin_${index}`] = member.linkedin || '';
      initialValues[`position_${index}`] = member.position || '';
      initialValues[`start_date_${index}`] = moment(member.start_date).format(
        'YYYY-MM-DD'
      );
      initialValues[`end_date_${index}`] = moment(member.end_date).format('YYYY-MM-DD');
    });
    return initialValues;
  };
  const handleSubmit = (values) => {
    const { nextPage, setApplicationData } = props;

    const team_members = teamMembers.map((_, i) => ({
      first_name: values[`first_name_${i}`],
      last_name: values[`last_name_${i}`],
      email: values[`email_${i}`],
      linkedin: values[`linkedin_${i}`],
      position: values[`position_${i}`],
      previous_experience: [
        { start_date: values[`start_date_${i}`], end_date: values[`end_date_${i}`] }
      ]
    }));
    const stepsRequired = Math.floor(StepsCalculator(2, completeStep, 36));
    const data = {
      team_members: JSON.stringify(team_members),
      percentage_complete: stepsRequired
    };
    UpdateExchangeApplication({ application_id: applicationId }, data)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        setApplicationData(application);
        nextPage();
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const separateValuesIntoMembers = (values) => {
    const members = [];

    Object.keys(values).forEach((key) => {
      const match = key.match(/_(\d+)$/);
      if (!match) return;

      const index = parseInt(match[1], 10);
      const newKey = key.replace(/_\d+$/, '');

      if (!members[index]) members[index] = {};

      members[index][newKey] = values[key];
    });

    return members;
  };

  const addNewTeamMember = (values) => {
    console.log('Before adding new member:', teamMembers);

    const newMembers = separateValuesIntoMembers(values);
    const updatedMembers = [...newMembers, {}]; // Adds a new empty member object

    setTeamMembers(updatedMembers);
  };

  const handleDeleteMember = (index) => {
    const newTeamMembers = [...teamMembers];
    newTeamMembers.splice(index, 1);
    setTeamMembers(newTeamMembers);
  };

  useEffect(() => {
    setTeamMembers(teamMembers);
  }, [teamMembers]);

  const calculateProgress = () => {
    return completeStep;
  };
  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>Team Members</span>
      </div>
      <Container className='col-12 col-sm-auto'>
        <div>
          <Formik
            enableReinitialize
            initialValues={generateInitialValues()}
            onSubmit={handleSubmit}
            validationSchema={getMemberSchema(teamMembers.length)}>
            {({ touched, errors, values, handleChange, setValues, setFieldTouched }) => (
              <Form>
                {teamMembers.map((_, index) => (
                  <Row key={index}>
                    {[
                      'first_name',
                      'last_name',
                      'email',
                      'linkedin',
                      'position',
                      'start_date',
                      'end_date'
                    ].map((field) => (
                      <Col className='mt-4' md={4} key={`${field}_${index}`}>
                        <CustomInput
                          label={field.replace('_', ' ')}
                          htmlFor={`${field}_${index}`}
                          setFieldTouched={setFieldTouched}
                          name={`${field}_${index}`}
                          placeholder={field.replace('_', ' ')}
                          values={values}
                          handleChange={handleChange}
                          errors={errors}
                          touched={touched}
                          type={field.includes('date') ? 'date' : 'text'}
                        />
                      </Col>
                    ))}
                    <Col md={12}>
                      <button
                        className='btn btn-auth mt-3'
                        style={{ width: 150, height: 40 }}
                        type='button'
                        onClick={() => handleDeleteMember(index)}>
                        Delete
                      </button>
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col md={12}>
                    <button
                      className='btn btn-auth mt-2'
                      style={{ width: 200, height: 40 }}
                      type='button'
                      onClick={() => addNewTeamMember(values)}>
                      {' '}
                      Add New Team Member
                    </button>
                  </Col>
                </Row>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth ml-2'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default TeamMembers;

function toReadableLabel(str) {
  return str
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ');
}

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel
}) => (
  <FormGroup className='mb-2'>
    <div className='font-size-12'>
      <Label htmlFor={htmlFor} className='text-black font-weight-normal'>
        {toReadableLabel(label)} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12'}
        placeholder={'Enter ' + toReadableLabel(placeholder)}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
