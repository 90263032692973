import React, { useEffect, useState } from 'react';
import {
  Button,
  Card,
  CardBody,
  CardTitle,
  Col,
  Container,
  FormGroup,
  Input,
  Label,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane
} from 'reactstrap';
import { Formik, Form } from 'formik';
import { constants as PLACE_CONST } from '../../constants/placeholder';
import { constants as CONST } from '../../constants/constant';
import { constants as BUTTON_CONST } from '../../constants/button';
import { constants as ERROR_CONST } from '../../constants/errorMessage';
import { constants as SUCCESS_CONST } from '../../constants/successMessage';
import AuthInput from '../../Custom/AuthInput';
import { GetMyProfileAPI, UpdateMyProfileAPI } from '../../services/Auth';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import * as Yup from 'yup';
import APICredential from './APICredential';
import ReferralSettings from './ReferralSettings';
import ExchangeSettings from './ExchangeSettings';

function BusinessProfile() {
  const UpdateProfileSchema = Yup.object().shape({
    email: Yup.string()
      .email(ERROR_CONST.VALID_EMAIL)
      .required(ERROR_CONST.EMAIL_REQUIRED),
    company_name: Yup.string().required(ERROR_CONST.COMPANY_NAME),
    brand_name: Yup.string().required(ERROR_CONST.BRAND_NAME),
    website: Yup.string().required(ERROR_CONST.WEBSITE)
    // logo_url: Yup.string().required(ERROR_CONST.LOGO_URL)
  });

  const [userData, setUserData] = useState({});
  // const [buyCheck, setBuyCheck] = useState(
  //   userData.product_buy_enable ? userData.product_buy_enable : false
  // );
  // const [sellCheck, setSellCheck] = useState(
  //   userData.product_sell_enable ? userData.product_sell_enable : false
  // );
  const [disableEmail, setDisableEmail] = useState(
    userData.subscription_email ? userData.subscription_email : false
  );

  const [activeTab, setActiveTab] = useState('1');

  const getProfileDataFunction = () => {
    GetMyProfileAPI()
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setUserData(data);
        // setBuyCheck(data.product_buy_enable);
        // setSellCheck(data.product_sell_enable);
        setDisableEmail(data.product_sell_enable);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  const handleSubmit = (values) => {
    const data = {
      email: values.email,
      company_name: values.company_name,
      brand_name: values.brand_name,
      website: values.website
      // buy_fee_percentage: values.buy_fee_percentage,
      // sell_fee_percentage: values.sell_fee_percentage,
      // product_buy_enable: buyCheck,
      // product_sell_enable: sellCheck,
      // logo_url: values.logo_url
    };
    UpdateMyProfileAPI(data)
      .then(() => {
        notifySuccess(SUCCESS_CONST.PROFILE_UPDATED_SUCCESSFULLY);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  useEffect(() => {
    getProfileDataFunction();
  }, []);

  return (
    <React.Fragment>
      <div className='page-content ml-4'>
        <Container fluid>
          <Card>
            <Col>
              <Row>
                <div className='form-inline float-md-left'>
                  <div className='search-box ml-2'>
                    <div className='py-3 pl-3'>
                      <span className='font-size-20 text-black'>{CONST.SETTINGS}</span>
                    </div>
                  </div>
                </div>
              </Row>
            </Col>
            <Col md={12} lg={12}>
              <Row>
                <Col lg={6}>
                  <Nav
                    tabs
                    className='nav-tabs-custom nav-justified'
                    style={{
                      borderBottom: '1px solid #8C9297'
                    }}>
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: 'pointer',
                          borderBottom: `${activeTab === '1' ? '2px solid #181B30' : ''}`
                        }}
                        onClick={() => {
                          setActiveTab('1');
                        }}>
                        <span className='d-none d-sm-block'>Account Details</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: 'pointer',
                          borderBottom: `${activeTab === '2' ? '2px solid #181B30' : ''}`
                        }}
                        onClick={() => {
                          setActiveTab('2');
                        }}>
                        <span className='d-none d-sm-block'>Account Credentials</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: 'pointer',
                          borderBottom: `${activeTab === '3' ? '2px solid #181B30' : ''}`
                        }}
                        onClick={() => {
                          setActiveTab('3');
                        }}>
                        <span className='d-none d-sm-block'>Referral Settings</span>
                      </NavLink>
                    </NavItem>
                    <NavItem>
                      <NavLink
                        style={{
                          cursor: 'pointer',
                          borderBottom: `${activeTab === '4' ? '2px solid #181B30' : ''}`
                        }}
                        onClick={() => {
                          setActiveTab('4');
                        }}>
                        <span className='d-none d-sm-block'>Whitelisted Exchanges</span>
                      </NavLink>
                    </NavItem>
                  </Nav>
                </Col>
              </Row>
            </Col>

            <TabContent activeTab={activeTab}>
              <TabPane tabId='1'>
                <Col lg={12} md={12} sm={12}>
                  <Card>
                    <CardBody>
                      <Formik
                        initialValues={{
                          email: userData.email ? userData.email : '',
                          company_name: userData.company_name
                            ? userData.company_name
                            : '',
                          brand_name: userData.brand_name ? userData.brand_name : '',
                          website: userData.website ? userData.website : ''
                          // buy_fee_percentage: userData.buy_fee_percentage
                          //   ? userData.buy_fee_percentage
                          //   : 0,
                          // sell_fee_percentage: userData.sell_fee_percentage
                          //   ? userData.sell_fee_percentage
                          //   : 0,
                          // logo_url: userData.logo_url ? userData.logo_url : ''
                        }}
                        validationSchema={UpdateProfileSchema}
                        onSubmit={handleSubmit}
                        enableReinitialize>
                        {({ errors, touched, values, handleChange }) => (
                          <Form>
                            <div>
                              <div>
                                <Row>
                                  <Col lg={4}>
                                    <FormGroup className='mb-4'>
                                      <Label htmlFor='email-address'>
                                        {CONST.EMAIL_ADDRESS}
                                      </Label>
                                      <AuthInput
                                        name='email'
                                        type='email'
                                        className='form-control'
                                        values={values}
                                        handleChange={handleChange}
                                        errors={errors}
                                        touched={touched}
                                        id='email-address'
                                        placeholder={PLACE_CONST.ENTER_EMAIL}
                                        disabledField={true}
                                      />
                                      {}
                                    </FormGroup>
                                  </Col>
                                  <Col lg={4}>
                                    <FormGroup className='mb-4'>
                                      <Label htmlFor='company-name'>
                                        {CONST.COMPANY_NAME}
                                      </Label>
                                      <AuthInput
                                        name='company_name'
                                        type='text'
                                        className='form-control'
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleChange={handleChange}
                                        id='company-name'
                                        placeholder={PLACE_CONST.ENTER_COMPANY_NAME}
                                      />
                                    </FormGroup>
                                  </Col>

                                  <Col lg={4}>
                                    <FormGroup className='mb-4'>
                                      <Label htmlFor='brand-name'>
                                        {CONST.BRAND_NAME}
                                      </Label>
                                      <AuthInput
                                        name='brand_name'
                                        type='text'
                                        className='form-control'
                                        values={values}
                                        errors={errors}
                                        touched={touched}
                                        handleChange={handleChange}
                                        id='brand-name'
                                        placeholder={PLACE_CONST.ENTER_BRAND_NAME}
                                      />
                                    </FormGroup>
                                  </Col>
                                </Row>

                                <FormGroup className='mb-4'>
                                  <Label htmlFor='website'>{CONST.WEBSITE}</Label>
                                  <AuthInput
                                    name='website'
                                    type='text'
                                    className='form-control'
                                    values={values}
                                    errors={errors}
                                    touched={touched}
                                    handleChange={handleChange}
                                    id='website'
                                    placeholder={PLACE_CONST.ENTER_WEBSITE}
                                  />
                                </FormGroup>
                                {/* <Row>
                                <Col lg={4}>
                                  <FormGroup className="mb-4">
                                    <Label htmlFor="product-enabled">
                                      {CONST.PRODUCT_ENABLED}
                                    </Label>
                                    <div className="d-flex direction-row">
                                      <div className="custom-control form-check custom-checkbox mr-3">
                                        <Input
                                          name="product_buy_enable"
                                          type="checkbox"
                                          onChange={() => setBuyCheck(!buyCheck)}
                                          checked={buyCheck}
                                          value={buyCheck}
                                          className="form-check-input"
                                        />
                                        <Label
                                          className="form-check-label"
                                          onClick={() => {
                                            setBuyCheck(!buyCheck);
                                          }}>
                                          {CONST.BUY}
                                        </Label>
                                      </div>
                                      <div className="custom-control form-check custom-checkbox">
                                        <Input
                                          name="product_sell_enable"
                                          type="checkbox"
                                          value={sellCheck}
                                          className="form-check-input"
                                          onChange={() => setSellCheck(!sellCheck)}
                                          checked={sellCheck}
                                        />
                                        <Label
                                          className="form-check-label"
                                          onClick={() => {
                                            setSellCheck(!sellCheck);
                                          }}>
                                          {CONST.SELL}
                                        </Label>
                                      </div>
                                    </div>
                                  </FormGroup>
                                </Col>
                              </Row> */}

                                {/* <Row>
                                <Col lg={6}>
                                  <FormGroup className='mb-4'>
                                    <Label htmlFor='buy-fee-percentage'>
                                      {CONST.BUY_FEE_PERCENTAGE}
                                    </Label>
                                    <AuthInput
                                      name='buy_fee_percentage'
                                      type='number'
                                      className='form-control'
                                      handleChange={handleChange}
                                      values={values}
                                      errors={errors}
                                      touched={touched}
                                      id='buy-fee-percentage'
                                      placeholder={PLACE_CONST.ENTER_BUY_FEE_PERCENTAGE}
                                    />
                                  </FormGroup>
                                </Col>
                                <Col lg={6}>
                                  <FormGroup className='mb-4'>
                                    <Label htmlFor='sell-fee-percentage'>
                                      {CONST.SELL_FEE_PERCENTAGE}
                                    </Label>
                                    <AuthInput
                                      name='sell_fee_percentage'
                                      type='number'
                                      className='form-control'
                                      id='sell-fee-percentage'
                                      handleChange={handleChange}
                                      values={values}
                                      errors={errors}
                                      touched={touched}
                                      placeholder={PLACE_CONST.ENTER_SELL_FEE_PERCENTAGE}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row> */}

                                {/* <Row>
                                <Col lg={12}>
                                  <FormGroup className='mb-4'>
                                    <Label htmlFor='logo-url'>{CONST.LOGO_URL}</Label>
                                    <AuthInput
                                      errors={errors}
                                      touched={touched}
                                      name='logo_url'
                                      type='text'
                                      className='form-control'
                                      values={values}
                                      handleChange={handleChange}
                                      id='logo-url'
                                      placeholder={PLACE_CONST.ENTER_LOGO_URL}
                                    />
                                  </FormGroup>
                                </Col>
                              </Row> */}
                                <span className='text-black'>
                                  {CONST.SETTINGS_PROFILE_DESCRIPTION}
                                </span>

                                <div className='custom-control custom-checkbox mb-3 mt-2'>
                                  <Input
                                    name='disable_email'
                                    type='checkbox'
                                    onChange={() => false}
                                    checked={disableEmail}
                                    className='custom-control-input'
                                  />
                                  <Label
                                    className='custom-control-label'
                                    onClick={() => {
                                      setDisableEmail(!disableEmail);
                                    }}>
                                    {CONST.DISABLE_EMAIL}
                                  </Label>
                                </div>
                                <Row>
                                  <Col lg={2}>
                                    <div className='mt-3 text-center w-100 waves-effect waves-light'>
                                      <Button
                                        type='submit'
                                        name='btn'
                                        className={`btn btn-auth`}
                                        onSubmit={(values) => handleSubmit(values)}>
                                        {BUTTON_CONST.UPDATE}
                                      </Button>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </div>
                          </Form>
                        )}
                      </Formik>
                    </CardBody>
                  </Card>
                </Col>
              </TabPane>
              <TabPane tabId='2'>
                <APICredential
                  userData={userData}
                  getProfileDataFunction={getProfileDataFunction}
                />
              </TabPane>
              <TabPane tabId='3'>
                <ReferralSettings
                  userData={userData}
                  getProfileDataFunction={getProfileDataFunction}
                />
              </TabPane>
              <TabPane tabId='4'>
                <ExchangeSettings
                  userData={userData}
                  getProfileDataFunction={getProfileDataFunction}
                />
              </TabPane>
            </TabContent>
          </Card>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default BusinessProfile;
