import React, { useEffect, useState } from 'react';
import AcceptApplication from './AddStatusChange/AcceptApplication';
import RequestAction from './AddStatusChange/RequestAction';
import RejectApplication from './AddStatusChange/RejectApplication';
import QuotePrice from './AddStatusChange/QuotePrice';
import { Col, Row } from 'reactstrap';
import { ApplicationContext } from './applicationContext';
import { SendStreamMessageAPI } from '../../../services/Auth';

function ResponseFilters({
  applicationId,
  setUpdatePage,
  updatePage,
  setResponsePage,
  statusSuggestions,
  setStatusSuggestions,
  GetApplicationResponseFunc,
  applicationAll
}) {
  const [isStatusSectionCollapsed, setIsStatusSectionCollapsed] = useState(false);

  const [selectedStatus, setSelectedStatus] = useState('');
  const [modal, setModal] = useState(false);
  const handleModal = () => {
    setModal(!modal);
  };

  useEffect(() => {
    if (!modal) {
      setSelectedStatus('');
    }
  }, [modal]);

  const StreamSystemMessageFunc = (channel_id, message) => {
    const data = { message };
    SendStreamMessageAPI(channel_id, data)
      .then((res) => {})
      .catch((err) => {});
  };

  const getModal = () => {
    switch (selectedStatus) {
      case 'ACCEPT':
        return (
          <AcceptApplication
            modal={modal}
            handleOpenModal={handleModal}
            setUpdatePage={setUpdatePage}
            updatePage={updatePage}
            applicationId={applicationId}
            GetApplicationResponseFunc={GetApplicationResponseFunc}
            applicationAll={applicationAll}
            StreamSystemMessageFunc={StreamSystemMessageFunc}
          />
        );
      case 'REQUEST_ACTION':
        return (
          <RequestAction
            modal={modal}
            handleOpenModal={handleModal}
            setUpdatePage={setUpdatePage}
            updatePage={updatePage}
            applicationId={applicationId}
            GetApplicationResponseFunc={GetApplicationResponseFunc}
            applicationAll={applicationAll}
            StreamSystemMessageFunc={StreamSystemMessageFunc}
          />
        );
      case 'REJECT':
        return (
          <RejectApplication
            modal={modal}
            handleOpenModal={handleModal}
            setUpdatePage={setUpdatePage}
            updatePage={updatePage}
            applicationId={applicationId}
            applicationAll={applicationAll}
            GetApplicationResponseFunc={GetApplicationResponseFunc}
            StreamSystemMessageFunc={StreamSystemMessageFunc}
          />
        );
      case 'QUOTE':
        return (
          <QuotePrice
            modal={modal}
            handleOpenModal={handleModal}
            setUpdatePage={setUpdatePage}
            updatePage={updatePage}
            applicationId={applicationId}
            applicationAll={applicationAll}
            GetApplicationResponseFunc={GetApplicationResponseFunc}
            StreamSystemMessageFunc={StreamSystemMessageFunc}
          />
        );
      default:
    }
  };
  const toggleStatusSection = () => {
    setIsStatusSectionCollapsed(!isStatusSectionCollapsed);
  };

  const handleStatusCheckboxChange = (id) => {
    const updatedSuggestions = statusSuggestions.map((suggestion) => {
      if (suggestion.id === id) {
        return { ...suggestion, checked: !suggestion.checked };
      }
      return suggestion;
    });

    setResponsePage(1);
    setStatusSuggestions(updatedSuggestions);
  };

  return (
    <div className='card'>
      <div className='p-3'>
        <span className='font-size-20'>
          <i className='mdi mdi-filter'></i> Filters
        </span>
        <br />
        <hr />
        <span className='font-size-18 cursor-pointer' onClick={toggleStatusSection}>
          <i
            className={`mdi mdi-chevron-${
              isStatusSectionCollapsed === true ? 'right' : 'down'
            }`}
          />
          Select Status
        </span>
        <div className={`filter-section ${isStatusSectionCollapsed ? 'collapsed' : ''}`}>
          {statusSuggestions.map((suggestion) => (
            <div
              key={suggestion.id}
              className='d-flex align-items-center ml-3'
              style={{ wordBreak: 'break-all' }}>
              <input
                type='checkbox'
                checked={suggestion.checked}
                style={{ height: 16, width: 16 }}
                onChange={() => handleStatusCheckboxChange(suggestion.id)}
              />
              <i
                className={`${suggestion.icon} font-size-14 ml-1`}
                style={{
                  color: `${suggestion.color}`
                }}></i>
              <span className='font-size-14 mr-2 ml-1'>{suggestion.name}</span>
            </div>
          ))}
        </div>
        <hr />
        <span className='font-size-20'>
          <i className='mdi mdi-plus-circle-outline'></i> Actions
        </span>
        <br />
        <Row>
          <Col lg={12} sm={12} md={12} className='mt-2'>
            <button
              className={`btn btn-status-change cursor-pointer`}
              onClick={() => {
                setSelectedStatus('REQUEST_ACTION');
                handleModal();
              }}>
              Request Action
            </button>
          </Col>

          <Col lg={12} sm={12} md={12} className='mt-2'>
            <button
              className={`btn btn-status-change cursor-pointer`}
              onClick={() => {
                setSelectedStatus('QUOTE');
                handleModal();
              }}>
              Quote Price
            </button>
          </Col>
          <Col lg={12} sm={12} md={12} className='mt-2'>
            <button
              className={`btn btn-status-change cursor-pointer`}
              onClick={() => {
                setSelectedStatus('ACCEPT');
                handleModal();
              }}>
              Accept Application
            </button>
          </Col>
          <Col lg={12} sm={12} md={12} className='mt-2'>
            <button
              className={`btn btn-status-change cursor-pointer`}
              onClick={() => {
                setSelectedStatus('REJECT');
                handleModal();
              }}>
              Reject Application
            </button>
          </Col>
        </Row>
        <ApplicationContext.Provider value={applicationId}>
          {getModal()}
        </ApplicationContext.Provider>
      </div>
    </div>
  );
}

export default ResponseFilters;
