/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import { ReactComponent as ArrowLeftIcon } from '../../../assets/images/icons/arrow-left.svg';
import { MercuryoWidgetApi } from '../../../services/Business/Providers';

function MunzenWidget({ backToWidget, buyData }) {
  const [widgetURL, setWidgetURL] = useState('');
  useEffect(() => {
    MercuryoWidgetApi({
      crypto: buyData.cryptoCurrency,
      fiat_currency: buyData.fiat_currency,
      fiat_value: buyData.fiat_value
    })
      .then((res) => {
        setWidgetURL(res.data);
      })
      .catch((err) => {});
  }, []);

  return (
    <>
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light p-1 mb-2"
          style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
          onClick={backToWidget}>
          <ArrowLeftIcon width={18} height={18} />
        </button>
      </div>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <iframe
          scrolling="no"
          style={{ height: '578px', width: '450px' }}
          allow="accelerometer; autoplay; camera; gyroscope; payment"
          frameBorder="0"
          src={widgetURL}
        />
      </div>
    </>
  );
}

export default MunzenWidget;
