import React from 'react';
import './VerticalStepper.css';

const Step = ({ isActive, number, title, onClick, completedSteps }) => (
  <div
    className={`step ${isActive ? 'active' : ''} ${
      completedSteps.includes(number - 1) ? 'cursor-pointer' : ''
    }`}
    onClick={onClick}>
    <div className='circle'>{number}</div>
    <div className='title pr-1'>{title}</div>
  </div>
);

const VerticalStepper = ({ completedSteps, steps, currentStep, onSelectStep }) => {
  return (
    <div className='stepper'>
      {steps.map((step, index) => (
        <Step
          key={index}
          number={index + 1}
          title={step}
          isActive={currentStep >= index}
          completedSteps={completedSteps}
          onClick={() => completedSteps.includes(index) && onSelectStep(index)}
        />
      ))}
    </div>
  );
};

export default VerticalStepper;
