import React, { useEffect, useState } from 'react';
import { Card, CardBody, Col, Collapse, FormGroup, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../../constants/application';
import AuthInput from '../../../../Custom/AuthInput';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';

function TextAreaExchangeApplication({
  touched = {},
  errors = {},
  allResponses = [],
  handleChange = () => {},
  values = {},
  setFieldTouched = () => {},
  inputName = [],
  title = '',
  name = '',
  label = '',
  type = 'textarea',
  placeholder = CONST.ENTER_DESCRIPTION,
  filed = [],
  isFullSize = false,
  isSectionCollapsed,
  toggleSection,
  applicationId,
  setInitialValuesFunc,
  setFieldValue
}) {
  const [responseCount, setResponseCount] = useState(0);
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    const res = Object.keys(errors).find((i) => inputName.includes(i));
    setErrorShow(!!res);
  }, [errors]);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );

    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          inputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          const completedFieldData = current.applicationResponse.completed_field.filter(
            (res) => inputName.includes(res)
          );
          completedFileds = completedFieldData.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);
    setResponseCount(count);
  }, []);

  const handleSubmit = () => {
    if (!inputName.includes(Object.keys(errors).find((i) => inputName.includes(i)))) {
      setIsLoading(true);
      let data = {};
      for (const field of inputName) {
        if (inputName.includes('number_of_active_users')) {
          const stringWithoutCommas = values.number_of_active_users.split(',').join('');
          const numberOfActiveUsers = parseInt(stringWithoutCommas, 10);

          data = { ...data, number_of_active_users: numberOfActiveUsers };
        } else if (inputName.includes('marketing_budget')) {
          data = {
            ...data,
            marketing_budget_allocated_for_listing:
              values['marketing_budget_allocated_for_listing']
          };
        } else {
          data = { ...data, [field]: values[field] };
        }
      }

      if (applicationId !== '') {
        UpdateExchangeApplication({ application_id: applicationId }, data)
          .then((res) => {
            const application = get(res, 'data.data.data', '');
            notifySuccess(`${title} data updated successfully`);
            setIsLoading(false);
            setInitialValuesFunc(application.exchange_application);
          })
          .catch((err) => {
            const errorMessage = get(err, 'data.error.message');
            notifyError(notificationMessageFunction(errorMessage));
          });
      }
    }
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>{title}</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />

              <Row>
                {filed.length > 0 ? (
                  filed.map((textFiled, index) => (
                    <Col key={index} lg={textFiled.col || isFullSize ? 12 : 6}>
                      <FormGroup className='mb-2' key={textFiled.name}>
                        <div className='font-size-12'>
                          <Label
                            htmlFor={textFiled.name}
                            className='text-black font-weight-normal'>
                            {textFiled.label}
                          </Label>
                        </div>
                        <div>
                          <AuthInput
                            setFieldTouched={setFieldTouched}
                            withTouchError={true}
                            type={textFiled.type}
                            name={textFiled.name}
                            className='font-size-12'
                            placeholder={textFiled.placeholder || placeholder}
                            values={values}
                            handleChange={handleChange}
                            errors={errors}
                            touched={touched}></AuthInput>
                        </div>
                      </FormGroup>
                    </Col>
                  ))
                ) : (
                  <Col lg={isFullSize ? 12 : 6}>
                    <FormGroup className='mb-2'>
                      <div className='font-size-12'>
                        <Label htmlFor={name} className='text-black font-weight-normal'>
                          {label}
                        </Label>
                      </div>
                      <div>
                        <AuthInput
                          setFieldTouched={setFieldTouched}
                          withTouchError={true}
                          className='font-size-12'
                          type={type}
                          name={name}
                          placeholder={placeholder}
                          values={values}
                          handleChange={
                            inputName.includes('number_of_active_users')
                              ? (e) => {
                                  setFieldValue(
                                    'number_of_active_users',
                                    Number(
                                      e.target.value.replace(/[^0-9]/g, '')
                                    ).toLocaleString()
                                  );
                                }
                              : inputName.includes('marketing_budget')
                              ? (e) => {
                                  if (e.target.value.endsWith('.')) {
                                    setFieldValue(
                                      'marketing_budget_allocated_for_listing',
                                      e.target.value
                                    );
                                    return;
                                  }

                                  const rawValue = e.target.value.replace(/[^0-9.]/g, '');

                                  const decimalValue =
                                    rawValue.split('.').length > 2
                                      ? rawValue.slice(0, rawValue.lastIndexOf('.'))
                                      : rawValue;

                                  const parts = decimalValue.split('.');
                                  const formattedValue =
                                    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                                    (parts[1] ? '.' + parts[1] : '');

                                  if (formattedValue) {
                                    setFieldValue(
                                      'marketing_budget_allocated_for_listing',
                                      '$' + formattedValue
                                    );
                                  } else {
                                    setFieldValue(
                                      'marketing_budget_allocated_for_listing',
                                      '$0'
                                    );
                                  }
                                }
                              : inputName.includes('listing_price')
                              ? (e) => {
                                  if (e.target.value.endsWith('.')) {
                                    setFieldValue('listing_price', e.target.value);
                                    return;
                                  }

                                  const rawValue = e.target.value.replace(/[^0-9.]/g, '');

                                  const decimalValue =
                                    rawValue.split('.').length > 2
                                      ? rawValue.slice(0, rawValue.lastIndexOf('.'))
                                      : rawValue;

                                  const parts = decimalValue.split('.');
                                  const formattedValue =
                                    parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ',') +
                                    (parts[1] ? '.' + parts[1] : '');

                                  if (formattedValue) {
                                    setFieldValue('listing_price', '$' + formattedValue);
                                  } else {
                                    setFieldValue('listing_price', '$0');
                                  }
                                }
                              : handleChange
                          }
                          errors={errors}
                          touched={touched}></AuthInput>
                      </div>
                    </FormGroup>
                  </Col>
                )}
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={(event) => {
                    event.stopPropagation();
                    handleSubmit();
                  }}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default TextAreaExchangeApplication;
