import React from 'react';
import { Row } from 'reactstrap';
import * as copy from 'copy-to-clipboard';
import { notifySuccess } from '../../../Custom/notification';

function ShareUrl({
  referralDetail,
  widgetTheme,
  primaryColor,
  containerColor,
  primaryText,
  secondaryText
}) {
  const copyToClipboard = () => {
    const iframeCode = `https://${
      referralDetail && referralDetail.subdomain ? referralDetail.subdomain : 'app'
    }.listus.xyz/landing-page?widgetTheme=${widgetTheme}&primaryColor=${primaryColor.replace(
      '#',
      ''
    )}&containerColor=${containerColor.replace(
      '#',
      ''
    )}&primaryText=${primaryText.replace('#', '')}&secondaryText=${secondaryText.replace(
      '#',
      ''
    )}`;
    copy(iframeCode);
    notifySuccess('URL copied successfully');
  };

  return (
    <React.Fragment>
      {' '}
      <Row className='mt-3 mx-3'>
        <span className='font-size-12 font-weight-medium'>Your Widget URL</span>
        <pre>
          <code>
            "https://
            {referralDetail && referralDetail.subdomain
              ? referralDetail.subdomain
              : 'app'}
            .listus.xyz/landing-page?widgetTheme={widgetTheme}&primaryColor=
            {primaryColor.replace('#', '')}&containerColor=
            {containerColor.replace('#', '')}&primaryText={primaryText.replace('#', '')}
            &secondaryText={secondaryText.replace('#', '')}"
          </code>
        </pre>
        <button
          onClick={copyToClipboard}
          title='Copy to clipboard'
          className={`btn btn-auth`}
          style={{ width: 150 }}>
          Copy URL
        </button>
      </Row>
    </React.Fragment>
  );
}

export default ShareUrl;
