import React from 'react';
import { Redirect } from 'react-router-dom';
import { constants as ROUTE_CONST } from '../constants/routes';
// Authentication related pages
import Login from '../pages/AuthenticationFlow/Login';
import Register from '../pages/AuthenticationFlow/Register';
import VerifyAccount from '../pages/AuthenticationFlow/VerifyAccount';
import ForgetPassword from '../pages/AuthenticationFlow/ForgetPassword';
import VerifyAccountPassword from '../pages/AuthenticationFlow/VerifyAccountPassword';
import ChangePassword from '../pages/AuthenticationFlow/ChangePassword';

// Dashboard
import Dashboard from '../pages/UserDashboard';
import ProviderDetails from '../pages/BusinessDashboard/ProviderDetails';
import AllCountries from '../pages/AllCountries/AllCountries';
import BusinessSearch from '../pages/BusinessSearch';
import BusinessProfile from '../pages/BusinessProfile';
import AllCryptosPage from '../pages/AllCryptos';
import Crypto from '../pages/Crypto';
import SummaryNotifications from '../pages/Notifications/SummaryNotifications';
import NetworkDetailsPage from '../pages/Network/NetworkDetailsPage';
import BestBuyWidget from '../pages/BusinessDashboard/BestBuyWidget';
import BuySellWidget from '../pages/BuySellWidget';
import News from '../pages/News';

import CreateApplication from '../pages/BusinessApplication/CreateApplication';
import BusinessApplications from '../pages/BusinessApplication/ApplicationsList';
import CreateOnRampApplication from '../pages/BusinessApplication/OnRampApplication/CreateOnRampApplication';
import ViewApplication from '../pages/BusinessApplication/ViewOnRampApplication/index';

import OnRampApplications from '../pages/OnrampApplication/ApplicationsList';

import OnRampViewApplication from '../pages/OnrampApplication/ViewApplication/index';
import EditSubApplication from '../pages/BusinessApplication/ViewOnRampApplication/EditSubApplication';
// import OnRampApplicationSettings from '../pages/OnrampApplication/SettingApplications';
import Stripe from '../pages/BusinessApplication/ViewOnRampApplication/StripeWrapper';
import CreateExchangeApplication from '../pages/ExchangeApplication/ExchangeApplication/CreateExchangeApplication';
import SettingApplications from '../pages/BusinessApplication/SettingApplications';
import VerifyUserInvite from '../pages/AuthenticationFlow/UserInvite';
import Orders from '../pages/Orders';
import ViewExchnagesApplication from '../pages/ExchangeApplication/ViewExchangeApplication';
import AdminMembers from '../pages/AdminMembers';
import AdminDashboard from '../pages/AdminDashboard';
import OnRamps from '../pages/BusinessDashboard';
import BusinessDashboard from '../pages/BusinessDashboard/BusinessDashboard';
import ExchangeApplicationList from '../pages/Exchange/ExchangeApplicationList';
import ExchangeViewApplication from '../pages/Exchange/ExchangeVIewApplication';
import BusinessReferrals from '../pages/BusinessReferrals';
import BusinessNFT from '../pages/BusinessNFT';
import AdminListusApplication from '../pages/AdminListusApplication';
import Onboarding from '../pages/BusinessDashboard/Onboarding';
import AdminOnrampApplications from '../pages/AdminOnrampApplications';
import AdminViewApplication from '../pages/AdminOnrampApplications/ViewApplication';
import BusinessProviders from '../pages/BusinessProviders';
import WidgetCustomization1Buy from '../pages/WidgetCustomization1Buy.js';
import WidgetOnboardingSteps from '../pages/BusinessDashboard/WidgetOnboardingSteps/index.js';

// import Error404 from "../pages/Utility/Error404";
// import Error500 from "../pages/Utility/Error500";

const authProtectedRoutes = [
  { path: ROUTE_CONST.DASHBOARD, component: Dashboard },
  { path: ROUTE_CONST.ONRAMPS, component: OnRamps },
  { path: ROUTE_CONST.BUSINESS_DASHBOARD, component: BusinessDashboard },
  { path: ROUTE_CONST.BUSINESS_SEARCH, component: BusinessSearch },
  { path: ROUTE_CONST.PROVIDER, component: ProviderDetails },
  { path: ROUTE_CONST.COUNTRIES, component: AllCountries },
  { path: ROUTE_CONST.BUSINESS_PROFILE, component: BusinessProfile },
  { path: ROUTE_CONST.BUSINESS_REFERRALS, component: BusinessReferrals },
  { path: ROUTE_CONST.BUSINESS_NFT, component: BusinessNFT },
  { path: ROUTE_CONST.NOTIFICATIONS, component: SummaryNotifications },
  { path: ROUTE_CONST.NETWORK, component: NetworkDetailsPage },
  { path: ROUTE_CONST.ALL_CRYPTO, component: AllCryptosPage },
  { path: ROUTE_CONST.ADMIN_DASHBOARD, component: AdminDashboard },
  { path: ROUTE_CONST.CRYPTO, component: Crypto },
  { path: ROUTE_CONST.NEWS, component: News },
  { path: ROUTE_CONST.EXCHANGE_GET_APPLICATION, component: ExchangeApplicationList },
  { path: ROUTE_CONST.LISTUS_APPLICATIONS, component: AdminListusApplication },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={ROUTE_CONST.ONRAMPS} />
  }
  // this route should be at the end of all other routes
];

const businessUserRoutes = [
  { path: ROUTE_CONST.BUSINESS_DASHBOARD, component: BusinessDashboard },
  { path: ROUTE_CONST.CREATE_APPLICATION, component: CreateApplication },
  { path: ROUTE_CONST.CREATE_ONRAMP_APPLICATION, component: CreateOnRampApplication },
  { path: ROUTE_CONST.GET_APPLICATION, component: BusinessApplications },
  { path: ROUTE_CONST.WIDGET_SETTINGS, component: BusinessProviders },
  { path: ROUTE_CONST.VIEW_APPLICATION, component: ViewApplication },
  { path: ROUTE_CONST.EDIT_SUBAPPLICATION, component: EditSubApplication },
  { path: ROUTE_CONST.PAY_QUOTE, component: Stripe },
  { path: ROUTE_CONST.SETTING_APPLICATION, component: SettingApplications },
  { path: ROUTE_CONST.CREATE_EXCHANGE_APPLICATION, component: CreateExchangeApplication },
  { path: ROUTE_CONST.VIEW_EXCHANGE_APPLICATION, component: ViewExchnagesApplication },
  { path: ROUTE_CONST.ORDERS, component: Orders },
  { path: ROUTE_CONST.BUSINESS_PROFILE, component: BusinessProfile },
  { path: ROUTE_CONST.BUSINESS_REFERRALS, component: BusinessReferrals },
  { path: ROUTE_CONST.BUSINESS_NFT, component: BusinessNFT },
  { path: ROUTE_CONST.WIDGET_CUSTOMIZE_1_BUY, component: WidgetCustomization1Buy },

  {
    path: '/',
    exact: true,
    component: () => <Redirect to={ROUTE_CONST.BUSINESS_DASHBOARD} />
  }
];

const adminRoutes = [
  { path: ROUTE_CONST.ONRAMPS, component: OnRamps },
  { path: ROUTE_CONST.COUNTRIES, component: AllCountries },
  { path: ROUTE_CONST.ALL_CRYPTO, component: AllCryptosPage },
  { path: ROUTE_CONST.ORDERS, component: Orders },
  { path: ROUTE_CONST.MEMBERS, component: AdminMembers },
  { path: ROUTE_CONST.ADMIN_DASHBOARD, component: AdminDashboard },
  { path: ROUTE_CONST.PROVIDER, component: ProviderDetails },
  { path: ROUTE_CONST.CRYPTO, component: Crypto },
  { path: ROUTE_CONST.LISTUS_APPLICATIONS, component: AdminListusApplication },
  { path: ROUTE_CONST.LISTUS_ONRAMP_APPLICATIONS, component: AdminOnrampApplications },
  { path: ROUTE_CONST.ADMIN_VIEW_APPLICATION, component: AdminViewApplication },

  {
    path: '/',
    exact: true,
    component: () => <Redirect to={ROUTE_CONST.ADMIN_DASHBOARD} />
  }
];

const onRampUserRoutes = [
  { path: ROUTE_CONST.VIEW_APPLICATION, component: OnRampViewApplication },
  { path: ROUTE_CONST.GET_APPLICATION, component: OnRampApplications },
  { path: ROUTE_CONST.SETTING_APPLICATION, component: SettingApplications }
];

const exchangeUserRoutes = [
  { path: ROUTE_CONST.SETTING_APPLICATION, component: SettingApplications },
  { path: ROUTE_CONST.EXCHANGE_GET_APPLICATION, component: ExchangeApplicationList },
  // { path: ROUTE_CONST.VIEW_EXCHANGE_APPLICATION, component: OnRampApplicationSettings },
  { path: ROUTE_CONST.EXCHANGE_APPLICATION_DETAIL, component: ExchangeViewApplication }
];

const reviewerRoutes = [
  { path: ROUTE_CONST.BUSINESS_REFERRALS, component: BusinessReferrals },
  {
    path: '/',
    exact: true,
    component: () => <Redirect to={ROUTE_CONST.BUSINESS_REFERRALS} />
  }
];

const publicRoutes = [
  { path: ROUTE_CONST.REGISTER, component: Register },
  { path: ROUTE_CONST.LOGIN, component: Login },
  { path: ROUTE_CONST.VERIFY_ACCOUNT, component: VerifyAccount },
  { path: ROUTE_CONST.FORGET_PASSWORD, component: ForgetPassword },
  {
    path: ROUTE_CONST.VERIFY_ACCOUNT_PASSWORD,
    component: VerifyAccountPassword
  },
  { path: ROUTE_CONST.CHANGE_PASSWORD, component: ChangePassword },
  { path: ROUTE_CONST.USER_INVITE, component: VerifyUserInvite },
  { path: ROUTE_CONST.BEST_BUY_WIDGET, component: BestBuyWidget },
  { path: ROUTE_CONST.BUY_SELL_WIDGET, component: BuySellWidget },
  { path: ROUTE_CONST.ONBOARDING, component: Onboarding },
  { path: ROUTE_CONST.WIDGET_ONBOARDING, component: WidgetOnboardingSteps }

  // { path: "/pages-404", component: Error404 },
  // { path: "/pages-500", component: Error500 },
];

export {
  authProtectedRoutes,
  publicRoutes,
  businessUserRoutes,
  onRampUserRoutes,
  adminRoutes,
  reviewerRoutes,
  exchangeUserRoutes
};
