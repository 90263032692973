import React, { useState } from 'react';

function ResponseFilters({ setResponsePage, statusSuggestions, setStatusSuggestions }) {
  const [isStatusSectionCollapsed, setIsStatusSectionCollapsed] = useState(false);

  const handleStatusCheckboxChange = (id) => {
    const updatedSuggestions = statusSuggestions.map((suggestion) => {
      if (suggestion.id === id) {
        return { ...suggestion, checked: !suggestion.checked };
      }
      return suggestion;
    });

    setResponsePage(1);
    setStatusSuggestions(updatedSuggestions);
  };

  const toggleStatusSection = () => {
    setIsStatusSectionCollapsed(!isStatusSectionCollapsed);
  };

  return (
    <div className='card'>
      <div className='p-3'>
        <span className='font-size-20'>
          <i className='mdi mdi-filter'></i> Filters
        </span>
        <br />
        <hr />
        <span className='font-size-18 cursor-pointer' onClick={toggleStatusSection}>
          <i
            className={`mdi mdi-chevron-${
              isStatusSectionCollapsed === true ? 'right' : 'down'
            }`}
          />
          Select Status
        </span>
        <div className={`filter-section ${isStatusSectionCollapsed ? 'collapsed' : ''}`}>
          {statusSuggestions.map((suggestion) => (
            <div
              key={suggestion.id}
              className='d-flex align-items-center ml-3'
              style={{ wordBreak: 'break-all' }}>
              <input
                type='checkbox'
                checked={suggestion.checked}
                style={{ height: 16, width: 16 }}
                onChange={() => handleStatusCheckboxChange(suggestion.id)}
              />
              <i
                className={`${suggestion.icon} font-size-14 ml-1`}
                style={{
                  color: `${suggestion.color}`
                }}></i>
              <span className='font-size-14 mr-2 ml-1'>{suggestion.name}</span>
            </div>
          ))}
        </div>
        <hr />
      </div>
    </div>
  );
}

export default ResponseFilters;
