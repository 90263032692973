export const constants = {
  LEGAL_ENTITY: 'Legal Entity Name',
  ENTER_LEGAL_ENTITY: 'Enter Your Company Name',
  ENTER_FULL_NAME: 'Enter Your Full Name',
  PROJECT_NAME: 'Project Name',
  EMAIL: 'Email Address',
  ENTER_EMAIL: 'Enter Your Email Address',
  FULL_NAME: 'Full Name',
  ROLE: 'Role',
  DATE: 'Date',
  ENTER_ROLE: 'Enter Your Role',
  ENTER_PROJECT_NAME: 'Enter Project Name',
  ENTER_PROJECT_WEBSITE: 'Enter Project Website',
  ENTER_DOB: 'Enter Date of Birth',
  ENTER_OWNERSHIP: 'Enter Ownership Percentage',
  ENTER_COUNTRY: 'Enter Country',
  ENTER_REGION: 'Enter Region',
  CATEGORY: 'Business Category',
  REGISTRATION_NO: 'Legal Entity Registration No',
  ENTER_REGISTRATION_NO: 'Enter Legal Entity Registration No',
  WEBSITE: 'Website',
  COUNTRY: 'Country of Incorporation',
  PRODUCTS: 'Products Enabled',
  TOKEN: 'Token Address if exists',
  DESCRIPTION: 'Product Description',
  ENTER_DESCRIPTION: 'Write some additional information here',
  NOTES: 'Additional Notes',
  BUY: 'Buy',
  SELL: 'Sell',
  YES: 'Yes',
  NO: 'No',
  NA: 'N/A',
  PRODUCT_CUSTODIAL_OR_NOT: 'Is the product Non-Custodial or Custodial',
  CUSTODIAL: 'Custodial',
  NON_CUSTODIAL: 'Non-Custodial',
  ACCESS_CONTROL_FUNDS: 'Do users maintain complete control over their funds?',
  NETWORK: 'Network',
  TICKER: 'Ticker',
  NAME: 'Name',
  ICON: 'Upload an Icon',
  OPTIONAL: 'optional',

  // Exchange Application
  APPLICANT_FIRST_NAME: 'Applicant First Name',
  APPLICANT_LAST_NAME: 'Applicant Last Name',
  APPLICANT_EMAIL: 'Applicant Email',
  APPLICANT_TELEGRAM: 'Applicant Telegram',
  TOKEN_NAME: 'Token Name',
  TOKEN_TICKER: 'Token Ticker',
  TICKER_NAME: 'Ticker Name',
  PROJECT_WEBSITE: 'Project Website',
  PROJECT_DESCRIPTION: 'Project Description',
  TWITTER: 'Twitter',
  DISCORD: 'Discord',
  LINKEDIN: 'Linkedin',
  TOKEN_ADDRESS: 'Token Address',
  FACEBOOK: 'Facebook',
  INSTAGRAM: 'Instagram',
  TIKTOK: 'Tiktok',
  NO_OF_FOLLOWERS: 'No. of Followers',
  COINMARKETCAPURL: 'CoinMarketCap URL',
  COINGECKOURL: 'CoinGecko URL',
  LOOKING_TO_GET_LISTED: 'Looking To Get Listed',
  OTHER_NETWORK: 'Other Network',
  NUMBEROFACTIVEUSERS: 'Number of Active Users',
  EXISTING_EXCHANGES_LISTED: 'Existing Exchanges Listed',
  MARKETMAKERS: 'Market Makers',
  MARKETINGPARTNERS: 'Marketing Partners',
  EXCHANGELAUNCHMARKETINGCAMPAIGN: 'Exchange Launch Marketing Campaign',
  MARKETINGBUDGETALLOCATEDFORLISTING: 'Marketing Budget Allocated For Listing',
  LISTING_DATE: 'Listing Date',
  LISTING_PRICE: 'Listing Price',
  ROADMAP: 'Roadmap',
  UNIQUESELLINGPOINT: 'Unique Selling Point',
  USE_CASES_OF_TOKENS: 'Use cases of tokens',
  ROUND: 'Round',
  TOKEN_SIZE: 'Add Total Amount at the Stage',
  TOKEN_PRICE: 'Add Token Price at the Stage',
  ADD_INVESTORS: 'Add Investors',
  TARGETGEO: 'TargetGeo',
  DEMO_LINK: 'Demo Link',
  VIDEO_LINK: 'Video Link',
  EXTRA_INFORMATION: 'Extra Information',
  ENTER_APPLICANT_FIRST_NAME: 'Enter Your First Name',
  ENTER_APPLICANT_LAST_NAME: 'Enter Your Last Name',
  ENTER_APPLICANT_EMAIL: 'Enter Your Email',
  ENTER_APPLICANT_TELEGRAM_NAME: 'Enter Your Telegram name',
  ENTER_TOKEN_NAME: 'Enter Token Name',
  ENTER_TICKER: 'Enter Ticker',
  ENTER_TICKER_NAME: 'Enter Ticker Name',
  ENTER_TWITTER_NAME: 'Enter Twitter Username',
  ENTER_DISCORD_NAME: 'Enter Discord Username',
  ENTER_LINKEDIN_NAME: 'Enter Linkedin Username',
  ENTER_TOKEN_ADDRESS_NAME: 'Enter Token Address',
  ENTER_FACEBOOK_NAME: 'Enter Facebook Username',
  ENTER_INSTAGRAM_NAME: 'Enter Instagram Username',
  ENTER_TIKTOK_NAME: 'Enter Tiktok Username',
  ENTER_BLOCKCHAIN: 'Enter Blockchain Network',
  ENTER_NUMBER_OF_ACTIVE_USERS: 'Enter Number of Active users',
  ENTER_OTHER_NETWORK: 'Enter Other Network',
  ENTER_URL_PLACEHOLDER: 'https://example.com',
  ENTER_LOOKING_GET_LISTED: 'Looking to get listed',
  ENTER_VALID_URL: 'Enter Valid URL',
  BUSINESS_NAME: 'Business Name',
  BUSINESS_TICKER: 'Business Ticker',
  BUSINESS_DESCRIPTION: 'Business Description',
  NOTIFICATION: 'Notification',
  ON_STATUSES: 'On Statuses',
  ACCESS_LEVEL: 'Access Level'
};
