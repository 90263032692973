import React, { useContext, useEffect, useState } from 'react';
import { Col, FormGroup, Input, Label, Modal, ModalHeader, Row } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import {
  ChangeApplicationStatus,
  GetRequestCurrencyList
} from '../../../../services/Onramp/Application';
import { ApplicationContext } from '../applicationContext';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import _ from 'lodash';

function QuotePrice(props) {
  const applicationId = useContext(ApplicationContext);
  const [cryptoList, setCryptoList] = useState([]);
  // const [fiatCurrenciesList, setFiatCurrenciesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    GetRequestCurrencyList()
      .then((res) => {
        const cryptoCurrencies = res.data.filter((currency) => currency.network);
        // const fiatCurrencies = res.data.filter((currency) => !currency.network);
        setCryptoList(_.orderBy(cryptoCurrencies, 'id', 'asc'));
        props.GetApplicationResponseFunc(props.applicationId);
        // setFiatCurrenciesList(_.orderBy(fiatCurrencies, 'id', 'asc'));
      })
      .catch((err) => {});
  }, []);

  const formik = useFormik({
    initialValues: {
      selectedCurrency: 'Crypto Currencies',
      fiatCurrency: '',
      cryptoCurrency: '',
      paymentAddress: '',
      amount: ''
    },
    validationSchema: Yup.object().shape({
      selectedCurrency: Yup.string().required('Please choose a currency'),
      fiatCurrency: Yup.string().when('selectedCurrency', {
        is: 'Fiat Currencies',
        then: Yup.string().required('Please choose a fiat currency')
      }),
      cryptoCurrency: Yup.string().when('selectedCurrency', {
        is: 'Crypto Currencies',
        then: Yup.string().required('Please choose a crypto currency')
      }),
      paymentAddress: Yup.string().when('selectedCurrency', {
        is: 'Crypto Currencies',
        then: Yup.string().required('Please enter a Payment Address')
      }),
      amount: Yup.number().required('Please enter the amount')
    }),
    onSubmit: (values) => {
      const data = {
        status: 'QUOTE',
        amount: Number(values.amount),
        payment_address: values.paymentAddress
      };

      if (values.selectedCurrency === 'Fiat Currencies') {
        data.currency = values.fiatCurrency;
      } else {
        data.crypto_currency = values.cryptoCurrency;
      }

      if (applicationId) {
        setIsLoading(true);
        ChangeApplicationStatus(applicationId, data)
          .then((res) => {
            setIsLoading(false);
            notifySuccess('Quote Given successfully');
            props.StreamSystemMessageFunc(
              `${applicationId}-${props.applicationAll.business_user_id}`,
              'Exchange has requested for Qoute'
            );
            props.setUpdatePage(!props.updatePage);
            props.handleOpenModal();
          })
          .catch((error) => {
            setIsLoading(false);
            notifyError(error?.data?.error?.message);
          });
      }
    }
  });

  return (
    <div>
      <Modal
        isOpen={props.modal}
        toggle={props.handleOpenModal}
        aria-labelledby='contained-modal-title-vcenter'
        centered
        width='50%'
        style={{ borderRadius: '8px' }}
        className='w-100'>
        <ModalHeader toggle={props.handleOpenModal} className=''>
          <div className='col-12 col-sm-auto font-size-18 text-center'>
            <span className='text-black'>Quote Price</span>
          </div>
        </ModalHeader>
        <div className='font-size-12'>
          <form className='p-4' onSubmit={formik.handleSubmit}>
            {formik.values.selectedCurrency === 'Crypto Currencies' && (
              <>
                <FormGroup className='mb-2 mt-1'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <Label
                        htmlFor='description'
                        className='text-black font-weight-normal mt-1 font-size-12'>
                        {formik.values.selectedCurrency} :
                      </Label>
                    </Col>
                    <Col lg={12} md={12} sm={12}>
                      <Input
                        type='select'
                        name='cryptoCurrency'
                        className='font-size-12 border-dark'
                        value={formik.values.cryptoCurrency}
                        onChange={formik.handleChange}>
                        <option hidden>Choose Currencies</option>
                        {cryptoList.length > 0 &&
                          cryptoList.map((crypto) => (
                            <option key={crypto.id} value={crypto.id}>
                              {crypto.symbol}
                              {crypto.network ? `-${crypto.network.toUpperCase()}` : ''}
                            </option>
                          ))}
                      </Input>
                    </Col>
                  </Row>

                  {formik.errors.cryptoCurrency && formik.touched.cryptoCurrency && (
                    <div className='error font-size-12'>
                      {formik.errors.cryptoCurrency}
                    </div>
                  )}
                </FormGroup>

                <FormGroup className='mb-2 mt-1'>
                  <Row>
                    <Col lg={12} md={12} sm={12}>
                      <Label
                        htmlFor='description'
                        className='text-black font-weight-normal mt-1 font-size-12'>
                        {'Payment Address'} :
                      </Label>
                    </Col>
                    <Col lg={12} md={12} sm={12}>
                      <Input
                        type='text'
                        className='font-size-12 border-dark'
                        name='paymentAddress'
                        placeholder='Enter Payment Address'
                        value={formik.values.paymentAddress}
                        onChange={formik.handleChange}></Input>
                    </Col>
                  </Row>

                  {formik.errors.paymentAddress && formik.touched.paymentAddress && (
                    <div className='error font-size-12'>
                      {formik.errors.paymentAddress}
                    </div>
                  )}
                </FormGroup>
              </>
            )}
            <FormGroup className='mb-2 mt-1'>
              <Row>
                <Col lg={12} md={12} sm={12}>
                  <Label
                    htmlFor='description'
                    className='text-black font-weight-normal mt-1 font-size-12'>
                    Amount
                  </Label>
                </Col>
                <Col lg={12} md={12} sm={12}>
                  <Input
                    type='number'
                    name='amount'
                    placeholder='Enter amount'
                    value={formik.values.amount}
                    onChange={formik.handleChange}
                    className={'border-dark font-size-12'}
                  />
                </Col>
              </Row>
              {formik.errors.amount && formik.touched.amount && (
                <div className='error font-size-12'>{formik.errors.amount}</div>
              )}
            </FormGroup>
            <div className='d-flex justify-content-center mt-4 mb-2'>
              <button
                className='btn btn-auth'
                type='submit'
                style={{ width: 140 }}
                disabled={isLoading || formik.isSubmitting}>
                Next
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
}

export default QuotePrice;
