import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { Card, Col, FormGroup, Input, Label, Row } from 'reactstrap';
import {
  GenerateAPIKey,
  GenerateRefreshedAccessToken,
  GetExpiredDateAPI
} from '../../services/Business/APIKey';
import { notifyError, notifySuccess } from '../../Custom/notification';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { constants as SUCCESS_CONST } from '../../constants/successMessage';
import * as copy from 'copy-to-clipboard';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './apiKey.css';
import { cloneBusinessAccountToProd } from '../../services/Auth';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../Custom/AuthInput';
import { GetWebhookDataAPI, SetWebhookDataAPI } from '../../services/Business/Dashboard';

function APICredential({ userData, getProfileDataFunction }) {
  const [apiKey] = useState(userData.api_key ? userData.api_key : '');
  const [secretKey] = useState(userData.secret_key ? userData.secret_key : '');
  const [refreshedToken, setRefreshedToken] = useState({});
  const [webhookData, setWebhookData] = useState({});

  const initialValues = {
    apiKey: (userData.api_status === 1 && userData.api_key) || '',
    secretKey: (userData.api_status === 1 && userData.secret_key) || '',
    expirationDays: '7'
  };

  const initialValueWebhook = {
    webhook_url: webhookData && webhookData.webhook_url ? webhookData.webhook_url : ''
  };

  const GetWebhookDataFunction = () => {
    GetWebhookDataAPI()
      .then((res) => {
        setWebhookData(res.data.data.data);
      })
      .catch(() => {});
  };

  useEffect(() => {
    GetWebhookDataFunction();
  }, []);

  const validationSchema = Yup.object({
    apiKey: Yup.string().required('API Key is required'),
    secretKey: Yup.string().required('Secret Key is required'),
    expirationDays: Yup.string()
      .oneOf(['7', '30', '180', '365', 'infinite'], 'Invalid expiration days')
      .required('Expiration days are required')
  });

  const validationSchemaForWebhookURL = Yup.object({
    webhook_url: Yup.string()
      .matches(
        /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
        'Enter correct url!'
      )
      .required('Please enter webhook URL')
  });

  const defaultWidgetCode = `<html>\n
                              .\n 
                              .\n 
                              .\n</html>`;

  const generatedWidget = `<iframe   
                            \ntitle="Buy or sell"
                            \nallow="accelerometer; autoplay; camera; encrypted-media; gyroscope; payment"
                            \nsrc="https://widget${
                              process.env.REACT_APP_ENVIRONMENT === 'development'
                                ? '-dev'
                                : ''
                            }.rampnalysis.com/widget/?accessToken={{GENERATE_ACCESS_TOKEN}}"
                            \nwidth='450px'
                            \nheight='650px'
                            \nframeBorder="0">
                            \n</iframe>`;

  const [widgetCode, setWidgetCode] = useState(
    (userData.api_key || apiKey) && (userData.secret_key || secretKey)
      ? generatedWidget
      : defaultWidgetCode
  );

  useEffect(() => {
    setWidgetCode(
      (userData.api_key || apiKey) && (userData.secret_key || secretKey)
        ? generatedWidget
        : defaultWidgetCode
    );
  }, [
    apiKey,
    defaultWidgetCode,
    generatedWidget,
    secretKey,
    userData.api_key,
    userData.secret_key
  ]);

  const generateAPIKeyFunction = () => {
    GenerateAPIKey()
      .then((res) => {
        getProfileDataFunction();
        notifySuccess('API request is sent');
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const generateRefreshedAccessTokenFunc = (values) => {
    const headers = { 'api-key': values.apiKey, 'secret-key': values.secretKey };
    const data = { expirationDays: values.expirationDays };
    GenerateRefreshedAccessToken(data, headers)
      .then((res) => {
        const data = res && res.data && res.data.data;

        setRefreshedToken(data);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const handleSetWebhook = (values) => {
    const data = { webhook_url: values.webhook_url };
    SetWebhookDataAPI(data)
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setWebhookData(data);
        notifySuccess('Webhook set successfully');
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const generateWidgetCode = () => {
    GetExpiredDateAPI(
      `?api_key=${userData.api_key || ''}&secret_key=${userData.secret_key}`
    )
      .then((res) => {
        const generatedWidget = `<iframe   
        \ntitle="Buy or sell"
      
        \nallow="accelerometer; autoplay; camera; encrypted-media; gyroscope; payment"
        \nsrc="https://widget${
          process.env.REACT_APP_ENVIRONMENT === 'development' ? '-dev' : ''
        }.rampnalysis.com/widget/?accessToken=${
          res?.data?.data?.accessToken || '{{GENERATE_ACCESS_TOKEN}}'
        }"
        \nframeBorder="0"
        \nwidth='450px'
        \nheight='650px'
        >
        \n</iframe>`;
        setWidgetCode(
          (userData.api_key || apiKey) && (userData.secret_key || secretKey)
            ? generatedWidget
            : defaultWidgetCode
        );
      })
      .catch(() => {
        setWidgetCode(
          (userData.api_key || apiKey) && (userData.secret_key || secretKey)
            ? generatedWidget
            : defaultWidgetCode
        );
      });
  };

  const cloneDevToProd = () => {
    cloneBusinessAccountToProd()
      .then((res) => {
        window.open(process.env.REACT_APP_PROD_FRONTEND || 'https://app.rampnalysis.com');
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <Card body>
      <Row>
        <Col lg={6}>
          <Col>
            <span className='d-none d-sm-block font-size-16 text-black mb-2'>
              {userData.api_status === 0 ? (
                <span className='font-size-16 badge badge-pill badge-danger'>
                  Inactive
                </span>
              ) : userData.api_status === 1 ? (
                <span className='font-size-16 badge badge-pill badge-success'>
                  Active
                </span>
              ) : userData.api_status === 2 ? (
                <span className='font-size-16 badge badge-pill badge-secondary'>
                  Pending
                </span>
              ) : (
                ''
              )}
            </span>

            <span className='d-none d-sm-block font-size-14 text-black'>API Key</span>
            <span>
              An{' '}
              <Link to='#' style={{ color: '#218FEB' }}>
                API KEY
              </Link>{' '}
              uniquely identifies as a partner.
            </span>
            <br />
            {((userData && userData.api_key && userData.api_status === 1) || apiKey) && (
              <div className='d-flex align-items-center'>
                <span style={{ color: '#218FEB' }}>
                  {userData.api_key ? userData.api_key : apiKey}
                </span>
                <i
                  className='ri-file-copy-line ml-2 font-size-14 cursor-pointer'
                  onClick={() => {
                    copy(userData.api_key ? userData.api_key : apiKey);
                    notifySuccess(SUCCESS_CONST.API_KEY_COPIED);
                  }}
                />
              </div>
            )}
          </Col>
          <Col className='mt-4'>
            <span className='d-none d-sm-block font-size-14 text-black'>API Secret</span>
            <span>
              An{' '}
              <Link to='#' style={{ color: '#218FEB' }}>
                API Secret
              </Link>{' '}
              is used to refresh your API{' '}
              <Link to='#' style={{ color: '#218FEB' }}>
                {' '}
                Access Token
              </Link>{' '}
            </span>
            <br />
            {((userData && userData.secret_key && userData.api_status === 1) ||
              secretKey) && (
              <div className='d-flex align-items-center'>
                <span style={{ color: '#218FEB', wordBreak: 'break-all' }}>
                  {' '}
                  {userData.secret_key ? userData.secret_key : secretKey}
                </span>
                <i
                  className='ri-file-copy-line ml-2 font-size-14 cursor-pointer'
                  onClick={() => {
                    copy(userData.secret_key ? userData.secret_key : secretKey);
                    notifySuccess(SUCCESS_CONST.SECRET_KEY_COPIED);
                  }}
                />
              </div>
            )}
            <br />

            <Col lg={6}>
              <div className='mt-3 text-center waves-effect waves-light'>
                {(userData && userData.api_key && userData.api_status === 1) || apiKey ? (
                  <button
                    type='button'
                    name='btn'
                    className={`btn btn-auth`}
                    style={{ width: 150 }}
                    onClick={generateAPIKeyFunction}>
                    Refresh
                  </button>
                ) : userData.api_status === 0 ? (
                  <button
                    type='button'
                    name='btn'
                    style={{ width: 150 }}
                    className={`btn btn-auth`}
                    onClick={() => {
                      generateAPIKeyFunction();
                      if (process.env.REACT_APP_ENVIRONMENT === 'production') {
                        window.open('https://view.forms.app/techiedev/request-api-keys');
                      }
                    }}>
                    Request Access
                  </button>
                ) : (
                  <button
                    type='button'
                    name='btn'
                    style={{ width: 150 }}
                    className={`btn btn-auth`}
                    disabled>
                    Requested
                  </button>
                )}
              </div>
            </Col>
          </Col>
          <Col className='mt-3' lg={11}>
            <span className='d-none d-sm-block font-size-14 text-black'>Widget Code</span>
            <ReactMarkdown
              className='run-flow-response rounded-2 p-2'
              children={widgetCode}
              remarkPlugins={[remarkGfm]}
            />
            <Col lg={2}>
              <div className='mt-3 text-center waves-effect waves-light'>
                <button
                  type='button'
                  name='btn'
                  className={`btn btn-auth`}
                  style={{ width: 150, marginLeft: 0 }}
                  onClick={generateWidgetCode}>
                  Generate
                </button>
              </div>
            </Col>
          </Col>
          <Col className='mt-3' lg={11}>
            <span className='d-none d-sm-block font-size-14 text-black'>Webhook</span>
            <Formik
              initialValues={initialValueWebhook}
              validationSchema={validationSchemaForWebhookURL}
              enableReinitialize
              onSubmit={handleSetWebhook}>
              {({ isSubmitting, values, errors, touched, handleChange }) => (
                <Form>
                  <FormGroup>
                    <Label htmlFor='secretKey' className='text-black font-size-12'>
                      Webhook URL
                    </Label>
                    <AuthInput
                      type='text'
                      values={values}
                      placeholder={'Enter Webhook URL'}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      className={'font-size-12'}
                      name='webhook_url'
                    />
                  </FormGroup>
                  <Col lg={2}>
                    <div className='mt-3 text-center waves-effect waves-light'>
                      <button
                        type='submit'
                        name='btn'
                        className={`btn btn-auth`}
                        style={{ width: 150, marginLeft: 0 }}
                        onSubmit={handleSetWebhook}>
                        Set Webhook
                      </button>
                    </div>
                  </Col>
                </Form>
              )}
            </Formik>
          </Col>
        </Col>

        <Col lg={6}>
          <span className='font-size-14'>Generate Access Token</span>
          <Formik
            enableReinitialize
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={generateRefreshedAccessTokenFunc}>
            {({ isSubmitting, values, errors, touched, handleChange }) => (
              <Form>
                <div className='mb-3'>
                  <FormGroup>
                    <Label htmlFor='apiKey' className='text-black font-size-12'>
                      API Key
                    </Label>
                    <AuthInput
                      type='text'
                      values={values}
                      placeholder={'Enter API key'}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      name='apiKey'
                      className={'font-size-12'}
                    />
                  </FormGroup>
                </div>
                <div className='mb-3'>
                  <FormGroup>
                    <Label htmlFor='secretKey' className='text-black font-size-12'>
                      Secret Key
                    </Label>
                    <AuthInput
                      type='text'
                      values={values}
                      placeholder={'Enter Secret key'}
                      handleChange={handleChange}
                      errors={errors}
                      touched={touched}
                      className={'font-size-12'}
                      name='secretKey'
                    />
                  </FormGroup>
                </div>
                <div className='mb-3'>
                  <FormGroup>
                    <Label htmlFor='expirationDays' className='text-black font-size-12'>
                      Expiration Days
                    </Label>

                    <Input
                      type='select'
                      name='expirationDays'
                      className={
                        errors.expirationDays
                          ? 'border-danger font-size-12'
                          : 'border-dark font-size-12'
                      }
                      value={values.expirationDays}
                      onChange={(e) => handleChange(e)}>
                      <option value='7'>7 Days</option>
                      <option value='30'>30 Days</option>
                      <option value='180'>180 Days</option>
                      <option value='365'>365 Days</option>
                      <option value='infinite'>Infinite</option>
                    </Input>
                  </FormGroup>
                </div>

                <div className='mb-3'>
                  <button
                    type='submit'
                    name='btn'
                    className={`btn btn-auth`}
                    style={{ width: 150, marginLeft: 0 }}
                    onSubmit={generateRefreshedAccessTokenFunc}>
                    Generate Token
                  </button>
                </div>

                <span className='font-size-12'>
                  {process.env.REACT_APP_ENVIRONMENT === 'development' && (
                    <>
                      Clone your "Staging" register details to "Production" Environment{' '}
                      <span
                        style={{ color: 'blue' }}
                        onClick={cloneDevToProd}
                        className='cursor-pointer'>
                        <button className='btn btn-dark py-1 px-2'>Click Here</button>
                      </span>
                    </>
                  )}
                </span>
                <br />
                <span className='font-size-12'>
                  Take a look at our documentation{' '}
                  <a
                    style={{ color: 'white' }}
                    href='https://rampnalysis.gitbook.io/rampnalysis/'
                    className='cursor-pointer'
                    target='_blank'
                    rel='noreferrer'>
                    <button className='btn btn-dark py-1 px-2'>Click Here</button>
                  </a>
                </span>
              </Form>
            )}
          </Formik>
          {refreshedToken && Object.keys(refreshedToken).length > 0 && (
            <pre className='mt-4' style={{ overflowX: 'scroll' }}>
              <code>{JSON.stringify(refreshedToken, null, 2)}</code>
            </pre>
          )}
        </Col>
      </Row>
    </Card>
  );
}

export default APICredential;
