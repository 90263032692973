export const DevURL = `${process.env.REACT_APP_BACKEND_RNALYS_HOST}/api/`;
// export const DevURL = 'http://localhost:3005/api/';

const BUSINESS = 'business';
const ADMIN = 'admin';
const ONRAMP = 'onramp';
const CRYPTO_ASSETS = 'crypto_assets';
const NETWORK = 'network';
const BUSINESS_APPLICATION = 'business/application';
export const NEWS = 'news';
export const MERCURYO_WIDGET = 'mercuryo_widget';
export const GET_ETH = 'get_eth';
export const NETWORK_API = NETWORK;
// ? News
export const CRYPTO_NEWS = NEWS + '/crypto';

// ? Network
export const NETWORK_DETAIL = NETWORK + '/provider_assets';
export const BEST_ASSETS_BUY = 'best_assets/buy';

// ? Crypto Assets
export const TOTAL_COVERAGE = CRYPTO_ASSETS + '/total_coverage';
export const AVAILABLE_ON_RAMP = CRYPTO_ASSETS + '/available_on_ramps';
export const SUMMARY_NOTIFICATIONS = CRYPTO_ASSETS + '/summary_notification';
export const BEST_ASSETS_GLOBAL = CRYPTO_ASSETS + '/get_global_best_assets';
export const ALL_FIATS = CRYPTO_ASSETS + '/fiats';
export const ALL_COINS = CRYPTO_ASSETS + '/get_all_coins';
export const ALL_COUNTRIES = CRYPTO_ASSETS + '/widget/countries';
export const GET_SWING_TOKENS = CRYPTO_ASSETS + '/get_swing_tokens';
export const PAYMENT_OPTIONS = CRYPTO_ASSETS + '/payment_options';
export const PRICE_COMPARE_BUY = CRYPTO_ASSETS + '/price_compare';
export const PRICE_COMPARE_SELL = CRYPTO_ASSETS + '/sell_price_compare';
export const PROVIDERS = CRYPTO_ASSETS + '/business_providers';

// ? Business
export const ALL_SEARCH = BUSINESS + '/all_search';
export const WIDGET_IMAGES = BUSINESS + '/widget_images';
export const WIDGET_APPLICATION = BUSINESS + '/application/widget_application';
export const GET_WIDGET_IMAGES = '/rampnalysis/widget_images';
export const PROVIDER_NETWORK = BUSINESS + '/provider/network';
export const COUNTRY = BUSINESS + '/provider/countries';
export const COUNTRY_PROVIDER_PAYMENT = BUSINESS + '/country/provider_payment';
export const ASSETS = BUSINESS + '/provider/crypto_assets';
export const PROVIDER_PAYMENT_OPTIONS = BUSINESS + '/provider/payment_methods';
export const EXCLUDE_EXCHANGE = BUSINESS + '/excluded/exchanges';
export const EXISTED_EXCHANGE_LIST = BUSINESS + '/all/exchanges';

// Application
export const LISTUS_APPLICATIONS = ADMIN + '/applications';
export const APPLICATIONS = BUSINESS + '/application';
export const CREATE_APPLICATION = BUSINESS_APPLICATION + '/create_application';
export const GET_APPLICATION = BUSINESS_APPLICATION + '/get_application';
export const EDIT_APPLICATION = BUSINESS_APPLICATION + '/edit_application';
export const DELETE_APPLICATION = BUSINESS_APPLICATION + '/delete_application';
export const EDIT_EXCHANGE_APPLICATION =
  BUSINESS_APPLICATION + '/edit_exchange_application';
export const EDIT_USER = BUSINESS_APPLICATION + '/edit_application/edit_user';
export const DELETE_USER = BUSINESS_APPLICATION + '/edit_application/delete_user';
export const UPLOAD_DOCS = BUSINESS_APPLICATION + '/upload_docs';
export const APPLICATION_RESPONSE = BUSINESS_APPLICATION + '/application_response';
export const APPLICATION_NOTIFICATION =
  BUSINESS_APPLICATION + '/application_notification';
export const EDIT_ONRAMP_APPLICATION = BUSINESS_APPLICATION + '/edit_onramp_application';
export const PAY_QUOTE = BUSINESS_APPLICATION + '/pay_quote';
export const REQUEST_ACTION = BUSINESS_APPLICATION + '/request_action';
export const ANS_QUESTIONS = BUSINESS_APPLICATION + '/add_questions';
export const CONFIRM_PAYMENT = BUSINESS_APPLICATION + '/confirm_payment';
export const DELETE_NOTIFICATION = BUSINESS_APPLICATION + '/notification_read';
export const APPLICATION_SETTINGS = 'application/settings';
export const BUSINESS_DASHBOARD_ORDER_COUNT = BUSINESS + '/dashboard/total_orders';
export const BUSINESS_DASHBOARD_REVENUE = BUSINESS + '/dashboard/revenue';

// ? Onramp
export const TRIAL = ONRAMP + '/trial';

// ? OnRamp Application

export const ONRAMP_APPLICATION = ONRAMP + '/application';
export const ALL_APPLICATION = ONRAMP_APPLICATION + '/all';
export const PROCESS_APPLICATION = ONRAMP_APPLICATION + '/process_application';
export const MASS_PROCESS_APPLICATION = ONRAMP_APPLICATION + '/mass_process_application';
export const CHANGE_STATUS = ONRAMP_APPLICATION + '/application_status';
export const ON_RAMP_APPLICATION_RESPONSE = ONRAMP_APPLICATION + '/application_response';
export const ADD_QUESTIONS = ONRAMP_APPLICATION + '/add_questions';
export const TOKEN_ANALYTICS = ONRAMP_APPLICATION + '/token_analytics';
export const TEAM_ANALYTICS = ONRAMP_APPLICATION + '/team_analytics';
export const TWITTER_ANALYTICS = ONRAMP_APPLICATION + '/twitter_analytics';

export const CONNECT_ACCOUNT = ONRAMP + '/stripe/create';
export const GET_ACCOUNT = ONRAMP + '/stripe/account';

export const ADMIN_BUY_ORDERS = ADMIN + '/orders/buy';
export const ADMIN_BUY_ORDERS_SUGGETIONS = ADMIN + '/orders/suggestions';
export const BUSINESS_BUY_ORDERS = BUSINESS + '/orders/buy';
export const USERS_LIST = ADMIN + '/users/all';
export const APPROVE_API_KEY = ADMIN + '/users/activate_api';
export const DELETE_USER_ACCOUNT = ADMIN + '/users/delete_user';
export const DASHBOARD_ORDER_COUNT = ADMIN + '/dashboard/total_orders';
export const DASHBOARD_REVENUE = ADMIN + '/dashboard/revenue';

export const REFRESHED_ACCESS_TOKEN = `rampnalysis/refresh/access_token`;
export const SET_WEBHOOK = BUSINESS + '/rampnalysis/webhook';

export const URL = DevURL;
