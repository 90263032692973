import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row } from 'reactstrap';
import get from 'lodash/get';
import {
  BusinessDashboardTotalRevenueAPI,
  businessDashboardOrderCountAPI
} from '../../services/Business/Dashboard';
import { GetMyProfileAPI } from '../../services/Auth';
import { notificationMessageFunction } from '../../constants/notificationConst';
import { notifyError } from '../../Custom/notification';

function BusinessDashboard() {
  const [pendingCount, setPendingCount] = useState(0);
  const [processingCount, setProcessingCount] = useState(0);
  const [completedCount, setCompletedCount] = useState(0);
  const [failedCount, setFailedCount] = useState(0);
  const [allOrdersCount, setAllOrdersCount] = useState(0);
  const [completed30dRevenueCount, setCompleted30dRevenueCount] = useState(0);
  const [completedRevenueCount, setCompletedRevenueCount] = useState(0);
  const [userData, setUserData] = useState(null);

  const getProfileDataFunction = () => {
    GetMyProfileAPI()
      .then((res) => {
        const data = res && res.data && res.data.data && res.data.data.data;
        setUserData(data);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };
  useEffect(() => {
    getProfileDataFunction();
  }, []);

  const ordersFunc = ({ status }) => {
    businessDashboardOrderCountAPI(status)
      .then((res) => {
        const count = get(res, 'data.data.count');
        if (status === 'PENDING') {
          setPendingCount(count);
        } else if (status === 'PROCESSING') {
          setProcessingCount(count);
        } else if (status === 'COMPLETED') {
          setCompletedCount(count);
        } else if (status === 'FAILED') {
          setFailedCount(count);
        } else {
          setAllOrdersCount(count);
        }
      })
      .catch(() => {});
  };

  const dashboardRevenueFunc = ({ status, days }) => {
    BusinessDashboardTotalRevenueAPI({ status, days })
      .then((res) => {
        const totalUSDAmount = get(res, 'data.data.totalUSDAmount', []);
        if (status === 'COMPLETED' && days === '30d') {
          setCompleted30dRevenueCount(Number(totalUSDAmount).toFixed(2));
        } else if (status === 'COMPLETED') {
          setCompletedRevenueCount(Number(totalUSDAmount).toFixed(2));
        }
      })
      .catch(() => {});
  };

  useEffect(() => {
    dashboardRevenueFunc({ status: 'COMPLETED' });
    dashboardRevenueFunc({ status: 'COMPLETED', days: '30d' });
  }, []);

  useEffect(() => {
    ordersFunc({ status: 'PENDING' });
    ordersFunc({ status: 'PROCESSING' });
    ordersFunc({ status: 'COMPLETED' });
    ordersFunc({ status: 'FAILED' });
    ordersFunc({ status: 'ALL' });
  }, []);

  return (
    <React.Fragment>
      <div className='page-content'>
        <Container fluid>
          <div>
            <h1>Orders</h1>
            <Row>
              <CommonCardFunction counts={allOrdersCount} cardDetail={'Total Orders'} />
              <CommonCardFunction
                counts={completedCount}
                cardDetail={'Orders Completed'}
              />
              <CommonCardFunction counts={failedCount} cardDetail={'Orders Failed'} />
              <CommonCardFunction
                counts={processingCount}
                cardDetail={'Orders Processing'}
              />
              <CommonCardFunction counts={pendingCount} cardDetail={'Orders Pending'} />
            </Row>
            {userData &&
            userData.revenue_percentage &&
            userData.revenue_percentage > 0 ? (
              <div>
                <h1>Revenue</h1>
                <Row>
                  <CommonCardFunction
                    counts={completedRevenueCount}
                    cardDetail={'Total Revenue'}
                    usd={true}
                  />
                  <CommonCardFunction
                    counts={completed30dRevenueCount}
                    cardDetail={'30d Revenue'}
                    usd={true}
                  />
                </Row>
              </div>
            ) : (
              ''
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default BusinessDashboard;

export const CommonCardFunction = ({ counts, cardDetail, color, usd = false }) => {
  return (
    <Col md={4}>
      <Card style={{ background: color }}>
        <div
          className='d-flex justify-content-between align-items-center m-3'
          style={{ height: '50px' }}>
          <h4>{cardDetail}</h4>
          <span className='d-flex'>
            <h2>{counts}</h2> {usd && 'USD'}
          </span>
        </div>
      </Card>
    </Col>
  );
};
