import React from 'react';

import { Row, Col, Container, FormGroup, Button, Label } from 'reactstrap';
import * as Yup from 'yup';
import { Formik, Form } from 'formik';
import AuthInput from '../../Custom/AuthInput';
import { constants as PLACE_CONST } from '../../constants/placeholder';
import { constants as CONST } from '../../constants/constant';
import { constants as ROUTE_CONST } from '../../constants/routes';
import { constants as BUTTON_CONST } from '../../constants/button';
import { constants as ERROR_CONST } from '../../constants/errorMessage';
import { notificationMessageFunction } from '../../constants/notificationConst';
import RightLogo from './RightLogo';
import { NotificationToastContainer, notifyError } from '../../Custom/notification';
import { userResetPassword } from '../../services/Auth';

function ChangePassword(props) {
  const ChangePasswordSchema = Yup.object().shape({
    password: Yup.string()
      .required(ERROR_CONST.PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
    confirm_password: Yup.string()
      .required(ERROR_CONST.CONFIRM_PASSWORD_REQUIRED)
      .min(8, ERROR_CONST.PASSWORD_LENGTH)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED)
  });

  const handleSubmit = (values) => {
    const data = {
      email: localStorage.getItem(CONST.EMAIL),
      otp: localStorage.getItem(CONST.OTP),
      password: values.password,
      confirm_password: values.confirm_password
    };
    userResetPassword(data)
      .then((res) => {
        localStorage.setItem(CONST.EMAIL, data.email);
        props.history.push({ pathname: ROUTE_CONST.LOGIN });
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className='p-0'>
          <Row className='no-gutters'>
            <Col lg={4}>
              <div className='authentication-page-content p-4 d-flex align-items-top min-vh-100'>
                <div className='w-100'>
                  <Row className='justify-content-center'>
                    <Col lg={9}>
                      <div>
                        <div className='text-left'>
                          <h4 className='font-size-32 mt-4 text-black'>
                            {CONST.CREATE_NEW_PASSWORD}
                          </h4>
                          <p className='text-muted'>
                            {CONST.CREATE_NEW_PASSWORD_DESCRIPTION}
                          </p>
                        </div>

                        <div className='p-2 mt-5'>
                          <Formik
                            initialValues={{
                              password: '',
                              confirm_password: ''
                            }}
                            validationSchema={ChangePasswordSchema}
                            onSubmit={handleSubmit}>
                            {({ errors, touched, values, handleChange }) => (
                              <Form>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='password' className='text-black'>
                                      {CONST.PASSWORD}
                                    </Label>
                                    <AuthInput
                                      type='password'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_PASSWORD}
                                      handleChange={handleChange}
                                      isPassword
                                      errors={errors}
                                      touched={touched}
                                      name='password'
                                    />
                                  </FormGroup>
                                </div>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label
                                      htmlFor='confirm_password'
                                      className='text-black'>
                                      {CONST.CONFIRM_PASSWORD}
                                    </Label>
                                    <AuthInput
                                      type='password'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_CONFIRM_PASSWORD}
                                      handleChange={handleChange}
                                      isPassword
                                      errors={errors}
                                      touched={touched}
                                      name='confirm_password'
                                    />
                                  </FormGroup>
                                </div>

                                <div className='mt-3 text-center w-100 waves-effect waves-light'>
                                  <Button
                                    type='submit'
                                    name='btn'
                                    className={`btn btn-auth`}
                                    onSubmit={(values) => handleSubmit(values)}>
                                    {BUTTON_CONST.SAVE_PASSWORD}
                                  </Button>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <RightLogo />
            </Col>
          </Row>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default ChangePassword;
