import React from 'react';

import { Row, Col, Container, FormGroup, Button, Label } from 'reactstrap';
import { Formik, Form } from 'formik';
import AuthInput from '../../Custom/AuthInput';
import { constants as PLACE_CONST } from '../../constants/placeholder';
import { constants as CONST } from '../../constants/constant';
import { constants as ROUTE_CONST } from '../../constants/routes';
import { constants as BUTTON_CONST } from '../../constants/button';
import { constants as ERROR_CONST } from '../../constants/errorMessage';
import { notificationMessageFunction } from '../../constants/notificationConst';
import * as Yup from 'yup';

import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../Custom/notification';
import { OTPCheckAPI, resendVerificationEmail } from '../../services/Auth';
import RightLogo from './RightLogo';

function VerifyAccountPassword(props) {
  const VerificationSchema = Yup.object().shape({
    otp: Yup.string().required(ERROR_CONST.OTP)
  });

  const handleSubmit = (values) => {
    let data = {
      email: localStorage.getItem(CONST.EMAIL),
      otp: values.otp
    };
    OTPCheckAPI(data)
      .then((res) => {
        props.history.push(ROUTE_CONST.CHANGE_PASSWORD);
        localStorage.setItem(CONST.OTP, values.otp);
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const resendVerificationFunc = () => {
    resendVerificationEmail({ email: localStorage.getItem(CONST.EMAIL) })
      .then((res) => {
        notifySuccess('Verification code sent successfully');
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        notifyError(notificationMessageFunction(errorMessage));
      });
  };

  return (
    <React.Fragment>
      <div>
        <Container fluid className='p-0'>
          <Row className='no-gutters'>
            <Col lg={4}>
              <div className='authentication-page-content p-4 d-flex align-items-top min-vh-100'>
                <div className='w-100'>
                  <Row className='justify-content-center'>
                    <Col lg={9}>
                      <div>
                        <div className='text-left'>
                          <h4 className='font-size-32 mt-4 text-black'>
                            {CONST.VERIFICATION_REQUIRED}
                          </h4>
                          <p className='text-muted'>
                            {CONST.VERIFICATION_DESCRIPTION.replace(
                              'EMAIL_ADDRESS',
                              localStorage.getItem(CONST.EMAIL)
                            )}
                          </p>
                        </div>

                        <div className='p-2 mt-5'>
                          <Formik
                            initialValues={{ otp: '' }}
                            validationSchema={VerificationSchema}
                            onSubmit={handleSubmit}>
                            {({ errors, touched, values, handleChange }) => (
                              <Form>
                                <div className='mb-3'>
                                  <FormGroup>
                                    <Label htmlFor='otp' className='text-black'>
                                      {CONST.ENTER_OTP}
                                    </Label>
                                    <AuthInput
                                      type='text'
                                      values={values}
                                      placeholder={PLACE_CONST.ENTER_OTP}
                                      handleChange={handleChange}
                                      errors={errors}
                                      touched={touched}
                                      name='otp'
                                    />
                                  </FormGroup>
                                </div>

                                <div className='mt-3 text-center w-100 waves-effect waves-light'>
                                  <Button
                                    type='submit'
                                    name='btn'
                                    className={`btn btn-auth`}
                                    onSubmit={(values) => handleSubmit(values)}>
                                    {BUTTON_CONST.VERIFY_OTP}
                                  </Button>
                                </div>
                                <div className='mt-4 text-left'>
                                  <span className='text-black'>
                                    {CONST.DID_NOT_RECEIVE}{' '}
                                    <span
                                      className='text-black cursor-pointer'
                                      onClick={resendVerificationFunc}>
                                      <u>{CONST.RESEND_OTP}</u>
                                    </span>
                                  </span>
                                </div>
                              </Form>
                            )}
                          </Formik>
                        </div>
                      </div>
                    </Col>
                  </Row>
                </div>
              </div>
            </Col>
            <Col lg={8}>
              <RightLogo />
            </Col>
          </Row>
        </Container>
        <NotificationToastContainer />
      </div>
    </React.Fragment>
  );
}

export default VerifyAccountPassword;
