import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const GetSummaryNotifications = (days = 1) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.SUMMARY_NOTIFICATIONS + `?days=${days}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
