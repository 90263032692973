import React, { useEffect, useMemo, useState } from 'react';
import { Col, FormGroup, Input, Label, Row, Table } from 'reactstrap';
import { ExchangeListAPI } from '../../../services/Auth';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Formik, Form, Field } from 'formik';
import AuthInput from '../../../Custom/AuthInput';
import { constants as CONST } from '../../../constants/application';
import 'react-tooltip/dist/react-tooltip.css';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import documentUploadIcon from '../../../assets/images/documentupload.png';
import documentUploadedIcon from '../../../assets/images/documentuploaded.png';
import {
  GetAllNetwork,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import { useHistory } from 'react-router-dom';
import Select from 'react-select';
import { TagsInput } from 'react-tag-input-component';
import ReactTags from 'react-tag-autocomplete';
import { allCountries } from 'country-region-data';
import { regions } from '../../../constants/regionConst';

function EditExchangeSubApplication(props) {
  const history = useHistory();
  const applicationdata = props.applicationTypeData.exchange_application;
  const completedfieldsdata = props.completedfields;
  const changefields = props.fields;
  const { responseId, applicationId } = props;
  const keyToFind = 'exchange_name';
  const keyExists = Object.keys(changefields).some((key) =>
    key.includes(`${keyToFind}_`)
  );
  const CheckboxValues = [
    { id: 1, label: 'Direct Listing', value: 'direct_listing', isChecked: false },
    { id: 2, label: 'Launchpad / IEO', value: 'launchpad', isChecked: false },
    { id: 3, label: 'Launchpool', value: 'launchpool', isChecked: false },
    { id: 4, label: 'All Listing Programs', value: 'all_programs', isChecked: false }
  ];
  const [checkBoxesData, setCheckBoxesData] = useState(CheckboxValues);
  const [selectNetworkList, setSelectnetworkList] = useState([]);
  const [displayinvestorListData, setDisplayinvestorListData] = useState([]);
  const [showRound, setShowRound] = useState(false);
  const [selected, setSelected] = useState([]);
  const [fileUrls, setFileUrls] = useState({});
  const [allExchangeList, setAllExchangeList] = useState([]);
  const [exchangeList, setExchangeList] = useState([]);
  const [filterExchangeList, setFilterExchangeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [optionalFile, setOptionalFile] = useState({});
  const [displayotherNetwork, setDisplayotherNetwork] = useState(false);
  const [allNetworks, setAllNetworks] = useState([]);
  const options = [
    { value: 'n/a', label: 'N/A' },
    { value: 'seed', label: 'Seed' },
    { value: 'series_a', label: 'Series A' },
    { value: 'series_b', label: 'Series B' },
    { value: 'series_c', label: 'Series C' },
    { value: 'series_d', label: 'Series D' },
    { value: 'series_e', label: 'Series E' },
    { value: 'late_stage_round', label: 'Late Stage Round' },
    { value: 'ipo', label: 'IPO' }
  ];
  const [allInventoryOption, setAllInventoryOption] = useState(options);
  const [tokenPrice, setTokenPrice] = useState('');
  const [tokenSize, setTokenSize] = useState('');
  const [selectedRound, setselectedRound] = useState('');
  const [displayinvestorData, setDisplayinvestorData] = useState(false);
  const allRegionOptions = useMemo(() => {
    return allCountries.reduce((acc, [countryName, countryCode, regions]) => {
      const countryRegions = regions.map(([region]) => ({
        country: countryName,
        countryCode,
        region,
        value: region.toLowerCase(),
        label: region
      }));
      return [...acc, ...countryRegions];
    }, []);
  }, []);
  const suggestionsRegion = [...allRegionOptions, ...regions].map(({ value, label }) => ({
    id: value,
    name: label
  }));
  const [tags, setTags] = useState([]);

  const onDelete = (index) => {
    setTags(tags.filter((t, i) => i !== index));
  };

  const onAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const INITIAL_STATE = {
    applicant_first_name: '',
    applicant_last_name: '',
    applicant_email: '',
    applicant_telegram: '',
    project_name: '',
    token_name: '',
    token_ticker: '',
    project_website: '',
    project_description: '',
    twitter: '',
    discord: '',
    linkedin: '',
    facebook: '',
    instagram: '',
    tiktok: '',
    coin_market_cap_url: '',
    coin_gecko_url: '',
    looking_to_get_listed: '',
    blockchain_network: '',
    token_address: '',
    other_network: '',
    number_active_users: '',
    existing_exchanges_listed: '',
    market_makers: '',
    marketing_partners: '',
    exchange_launch_marketing_campaign: '',
    marketing_budget_allocated_for_listing: '',
    listing_program_at_bybit: '',
    listing_date: '',
    listing_price: '1',
    interested_in_audit: false,
    token_use_case: '',
    road_map: '',
    unique_selling_point: '',
    target_geo: [],
    demo_link: '',
    video_link: '',
    extra_information: ''
  };

  const [initialValue, setInitialValue] = useState(INITIAL_STATE);

  useEffect(() => {
    let updatedList = checkBoxesData.map((program) => {
      if (applicationdata.listing_program.includes(program.value)) {
        return { ...program, isChecked: true };
      } else {
        return program;
      }
    });
    setInitialValue({
      applicant_first_name: applicationdata.applicant_first_name,
      applicant_last_name: applicationdata.applicant_last_name,
      applicant_email: applicationdata.applicant_email,
      applicant_telegram: applicationdata.applicant_telegram,
      project_name: applicationdata.project_name,
      token_name: applicationdata.token_name,
      project_website: applicationdata.project_website,
      project_description: applicationdata.project_description,
      coin_market_cap_url: applicationdata.coin_market_cap_url,
      coin_gecko_url: applicationdata.coin_gecko_url,
      looking_to_get_listed: applicationdata.looking_to_get_listed,
      blockchain_network: applicationdata.blockchain_network || '',
      token_address: applicationdata.token_address || '',
      other_network: applicationdata.other_network,
      number_active_users: applicationdata.number_of_active_users,
      marketing_partners: applicationdata.marketing_partners,
      exchange_launch_marketing_campaign:
        applicationdata.exchange_launch_marketing_campaign,
      marketing_budget_allocated_for_listing:
        applicationdata.marketing_budget_allocated_for_listing,
      listing_date: formatDateForInput(applicationdata.listing_date),
      listing_price: applicationdata.listing_price,
      interested_in_audit: applicationdata.interested_in_audit,
      token_use_case: applicationdata.token_use_case,
      road_map: applicationdata.road_map,
      unique_selling_point: applicationdata.unique_selling_point,
      target_geo: applicationdata.target_geo,
      demo_link: applicationdata.demo_link,
      video_link: applicationdata.video_link,
      extra_information: applicationdata.extra_information,
      listing_program_at_bybit: updatedList
    });
    setSelectnetworkList(applicationdata.blockchain_network);
    if (applicationdata.blockchain_network === 'Other') {
      setDisplayotherNetwork(true);
    }
    applicationdata.social_media.forEach((social_media) => {
      setInitialValue((prevValues) => ({
        ...prevValues,
        [social_media.name]: social_media.account_id
      }));
    });

    if (applicationdata.target_geo && applicationdata.target_geo.length > 0) {
      const initTags = suggestionsRegion.filter(({ name }) =>
        applicationdata.target_geo.includes(name)
      );
      setTags(initTags);
    }
    setFileUrls({
      ticker_logo: applicationdata.ticker_logo,
      whitepapers_doc: applicationdata.whitepapers_doc,
      pitch_decks_doc: applicationdata.pitch_decks_doc,
      audit_doc: applicationdata.audit_doc,
      tokenomics_doc: applicationdata.tokenomics_doc,
      token_distribution_doc: applicationdata.token_distribution_doc
    });
    exchangeListApiFunction(applicationdata);
    setSelected(applicationdata.market_makers);
    setShowRound(true);
    setDisplayinvestorListData(applicationdata.investment_stages);

    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));
        setFinalData.push({ label: 'Other', value: 'Other' });
        setAllNetworks(setFinalData);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        if (errorMessage !== undefined)
          notifyError(notificationMessageFunction(errorMessage));
      });
  }, []);

  const changeHandlerSelect = (selectValues) => {
    if (selectValues.label === 'Other') {
      setDisplayotherNetwork(true);
    } else {
      setDisplayotherNetwork(false);
    }
    setSelectnetworkList(selectValues.value);
  };

  const changeHandlerExchange = (selectValues) => {
    const filteredData = allExchangeList.filter((item) =>
      selectValues.some((dataItem) => dataItem.label === item.exchange_name)
    );
    setSelectedOptions(selectValues);
    setFilterExchangeList(filteredData);
  };

  const exchangeListApiFunction = (applicationDetails) => {
    ExchangeListAPI()
      .then((res) => {
        const GetExchangeData = get(res, 'data.data.data', []);
        setAllExchangeList(GetExchangeData);
        const newData = res.data.data.data.map((item) => {
          return { label: item.exchange_name, value: item.exchange_name };
        });
        setExchangeList(newData);
        if (applicationDetails.existing_exchanges_listed) {
          const selectedList = applicationDetails.existing_exchanges_listed.map(
            (exchange) => ({
              label: exchange.exchange_name,
              value: exchange.exchange_name
            })
          );
          setSelectedOptions(selectedList);
          const filteredData = GetExchangeData.filter((item) =>
            selectedList.some((dataItem) => dataItem.label === item.exchange_name)
          );
          const updatedArrayList2 = filteredData.map((items) => {
            const matchedItem = applicationDetails.existing_exchanges_listed.find(
              (item) =>
                item.exchange_name.toLowerCase() === items.exchange_name.toLowerCase()
            );

            if (matchedItem) {
              return {
                ...items,
                hour24_trading_volume: matchedItem.hour24_trading_volume
              };
            }
            return items;
          });
          setFilterExchangeList(updatedArrayList2);
        }
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;
        if (errorMessage !== undefined)
          notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const ValidateTable = ({ index, item, changefields }) => {
    let valueToDisplay;

    Object.keys(changefields).some((key) => {
      if (key.includes(`${keyToFind}_`)) {
        const startIndex = key.indexOf(`${keyToFind}_`) + `${keyToFind}_`.length;
        valueToDisplay = key.substring(startIndex);
        return true; // Stop the iteration since we found the key
      }
    });
    let newFilterExchangeList = [...filterExchangeList];
    const handleChangeTrading = (value) => {
      newFilterExchangeList.forEach((i) => {
        if (i._id === item._id) {
          i.hour24_trading_volume = value;
        }
      });
    };
    if (valueToDisplay === item.exchange_name) {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.exchange_name}</td>
          <td>
            <label style={{ float: 'left' }} className='pt-3 pr-2'>
              $
            </label>
            <Input
              type='number'
              name={item.exchange_name}
              style={{
                borderRadius: '8px',
                width: '80%',
                float: 'left'
              }}
              value={item.hour24_trading_volume}
              onChange={(e) => handleChangeTrading(e.target.value)}
              onBlur={() => setFilterExchangeList(newFilterExchangeList)}
            />
            {!item.hour24_trading_volume && (
              <label className='pt-3 pl-3 text-danger'>
                Please enter 24 hour trading volume
              </label>
            )}
          </td>
        </tr>
      );
    } else {
      return (
        <tr key={index}>
          <td>{index + 1}</td>
          <td>{item.exchange_name}</td>
          <td>
            <label style={{ float: 'left' }} className='pt-3 pr-2'>
              $
            </label>
            <Input
              type='number'
              name={item.exchange_name}
              style={{
                borderRadius: '8px',
                width: '80%',
                float: 'left'
              }}
              value={item.hour24_trading_volume}
              onChange={(e) => handleChangeTrading(e.target.value)}
              onBlur={() => setFilterExchangeList(newFilterExchangeList)}
            />
            {!item.hour24_trading_volume && (
              <label className='pt-3 pl-3 text-danger'>
                Please enter 24 hour trading volume
              </label>
            )}
          </td>
        </tr>
      );
    }
  };
  function formatDateForInput(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const changeHandler = (event) => {
    if (event.target.files[0])
      setOptionalFile({ ...optionalFile, [event.target.name]: event.target.files[0] });
  };

  const handleChangeOpt = (selected) => {
    setselectedRound(selected.value);
    setDisplayinvestorData(true);
    const newOptions = allInventoryOption.filter(
      (option) => option.value !== selected.value
    );
    setAllInventoryOption(newOptions);
  };

  const generateFieldComparison = (values) => {
    return Object.keys(initialValue).reduce((acc, field) => {
      if (initialValue[field] !== values[field]) {
        acc[field] = values[field];
      }
      return acc;
    }, {});
  };

  const [inputs, setInputs] = useState([{ name: '', amount: '', lead: false }]);

  const handleAddInput = () => {
    const newInput = { name: '', amount: '', lead: false };
    setInputs([...inputs, newInput]);
  };

  const handleTextNameChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index].name = value;
    setInputs(newInputs);
  };

  const handleTextAmountChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index].amount = value;
    setInputs(newInputs);
  };

  const handleLeadChange = (index, value) => {
    const newInputs = [...inputs];
    newInputs[index].lead = value;
    setInputs(newInputs);
  };
  const handleRemoveInput = (index) => {
    if (index === 0) {
      return;
    }
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
  };
  if (!applicationId) {
    history.push(`/applications`);
  }

  const handleSubmitRound = () => {
    let RoundData = {
      round: selectedRound,
      token_price: tokenPrice,
      token_size: tokenSize,
      all_investors: inputs
    };
    setShowRound(true);
    setDisplayinvestorListData([...displayinvestorListData, RoundData]);
    // Clear the form inputs
    setselectedRound('');
    setTokenPrice('');
    setTokenSize('');
    setInputs([{ name: '', amount: '', lead: false }]);
    setDisplayinvestorData(false);
  };
  const buildPayloadData = (optionalFile) => {
    const payloadData = new FormData();
    Object.keys(optionalFile).forEach((key) => {
      payloadData.append(key, optionalFile[key]);
    });
    return payloadData;
  };
  const handleSubmit = async (values) => {
    try {
      // if (filterExchangeList.length > 0) {
      //   const formData = filterExchangeList.map((item) => {
      //     return {
      //       exchange_name: item.exchange_name,
      //       hour24_trading_volume: item.hour24_trading_volume
      //     };
      //   });
      //   console.log("formData :",formData);
      // }
      const data = generateFieldComparison(values);
      const payloadData = buildPayloadData(optionalFile);

      if (applicationId && applicationId !== '') {
        await uploadAndUpdateApplication(data, payloadData, responseId);
        navigateToViewApplication();
      }
    } catch (err) {
      const errorMessage = get(err, 'data.error.message');
      notifyError(notificationMessageFunction(errorMessage));
    }
  };
  const navigateToViewApplication = () => {
    history.push(
      `/application/viewExchange?application_id=${
        props.applicationTypeData && props.applicationTypeData.business_application_id
      }`
    );
  };
  const uploadAndUpdateApplication = async (data, payloadData, responseId) => {
    try {
      const finalPayload = new FormData();
      if (Object.keys(data).length > 0) {
        for (const [key, value] of Object.entries(data)) {
          if (key === 'target_geo') {
            finalPayload.append(key, JSON.stringify(value));
          } else {
            finalPayload.append(key, value);
          }
        }
      }
      for (const [key, value] of payloadData.entries()) {
        finalPayload.append(key, value);
      }
      await Promise.all([
        UpdateExchangeApplication(
          {
            sub_application_id: applicationId,
            onramp_response_id: responseId.onramp_response_id
          },
          finalPayload
        )
      ]);
      console.log('==============');
      history.push(
        `/application/viewExchange?application_id=${
          props.applicationTypeData && props.applicationTypeData.business_application_id
        }`
      );
    } catch ([editError]) {
      console.log('editError=============', editError);
      notifyError(notificationMessageFunction(get(editError, 'data.error.message')));
    }
  };

  return (
    <React.Fragment>
      <Formik
        enableReinitialize={true}
        initialValues={initialValue}
        onSubmit={handleSubmit}>
        {({ errors, touched, values, handleChange, setFieldValue }) => (
          <Form>
            {completedfieldsdata &&
              ((!completedfieldsdata.includes('applicant_first_name') &&
                changefields.hasOwnProperty('applicant_first_name')) ||
                (!completedfieldsdata.includes('applicant_last_name') &&
                  changefields.hasOwnProperty('applicant_last_name')) ||
                (!completedfieldsdata.includes('applicant_email') &&
                  changefields.hasOwnProperty('applicant_email')) ||
                (!completedfieldsdata.includes('applicant_telegram') &&
                  changefields.hasOwnProperty('applicant_telegram'))) && (
                <>
                  <CustomHeaders headerName='Applicant Background' />
                  <div className='mt-4 ml-2'>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('applicant_first_name') &&
                        changefields.hasOwnProperty('applicant_first_name') && (
                          <Col lg={6} md={12} sm={12}>
                            <FormGroup className='mb-6'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='applicant_first_name'
                                  className='text-black font-weight-normal'>
                                  {CONST.APPLICANT_FIRST_NAME}
                                </Label>
                              </div>

                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='applicant_first_name'
                                  values={values}
                                  handleChange={handleChange}
                                  placeholder={CONST.ENTER_APPLICANT_FIRST_NAME}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('applicant_last_name') &&
                        changefields.hasOwnProperty('applicant_last_name') && (
                          <Col lg={6} md={12} sm={12}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='applicant_last_name'
                                  className='text-black font-weight-normal'>
                                  {CONST.APPLICANT_LAST_NAME}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='applicant_last_name'
                                  placeholder={CONST.ENTER_APPLICANT_LAST_NAME}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('applicant_email') &&
                        changefields.hasOwnProperty('applicant_email') && (
                          <Col lg={6} md={12} sm={12}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='applicant_email'
                                  className='text-black font-weight-normal'>
                                  {CONST.APPLICANT_EMAIL}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='applicant_email'
                                  placeholder={CONST.ENTER_APPLICANT_EMAIL}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('applicant_telegram') &&
                        changefields.hasOwnProperty('applicant_telegram') && (
                          <Col lg={6} md={12} sm={12}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='applicant_telegram'
                                  className='text-black font-weight-normal'>
                                  {CONST.APPLICANT_TELEGRAM}({CONST.OPTIONAL})
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='applicant_telegram'
                                  placeholder={CONST.ENTER_APPLICANT_TELEGRAM_NAME}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              ((!completedfieldsdata.includes('project_name') &&
                changefields.hasOwnProperty('project_name')) ||
                (!completedfieldsdata.includes('token_name') &&
                  changefields.hasOwnProperty('token_name')) ||
                (!completedfieldsdata.includes('token_ticker') &&
                  changefields.hasOwnProperty('token_ticker')) ||
                (!completedfieldsdata.includes('project_website') &&
                  changefields.hasOwnProperty('project_website')) ||
                (!completedfieldsdata.includes('project_description') &&
                  changefields.hasOwnProperty('project_description'))) && (
                <>
                  <CustomHeaders headerName='Project Background' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('project_name') &&
                        changefields.hasOwnProperty('project_name') && (
                          <Col lg={6}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='project_name'
                                  className='text-black font-weight-normal'>
                                  {CONST.PROJECT_NAME}
                                </Label>
                              </div>

                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='project_name'
                                  values={values}
                                  handleChange={handleChange}
                                  placeholder={CONST.ENTER_PROJECT_NAME}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}

                      {completedfieldsdata &&
                        !completedfieldsdata.includes('token_name') &&
                        changefields.hasOwnProperty('token_name') && (
                          <Col lg={6}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='token_name'
                                  className='text-black font-weight-normal'>
                                  {CONST.TOKEN_NAME}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='token_name'
                                  placeholder={CONST.ENTER_TOKEN_NAME}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('token_ticker') &&
                        changefields.hasOwnProperty('token_ticker') && (
                          <Col lg={6}>
                            <FormGroup>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='description'
                                  className='text-black font-weight-normal'>
                                  Token Ticker
                                </Label>
                              </div>
                              <div
                                style={{
                                  borderRadius: '8px',
                                  border: '1px solid',
                                  height: 47
                                }}
                                className='d-flex direction-row justify-content-between align-items-center pl-1'>
                                <div>
                                  <Label htmlFor='token_ticker' id='ticker_logo'>
                                    <span
                                      className='p-1 mb-2 rounded-circle'
                                      style={{
                                        color: '#134a87',
                                        fontSize: 15,
                                        cursor: 'pointer'
                                      }}>
                                      <img
                                        src={
                                          optionalFile['ticker_logo']
                                            ? documentUploadedIcon
                                            : documentUploadIcon
                                        }
                                        alt='token_ticker'></img>
                                    </span>
                                    <span className='font-size-12'>{CONST.ICON}</span>
                                  </Label>
                                  {fileUrls.ticker_logo && (
                                    <a
                                      href={fileUrls.ticker_logo}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='pl-2 font-size-12 font-weight-bold text-wrap text-primary'>
                                      View File
                                    </a>
                                  )}
                                  <Input
                                    type='file'
                                    id='token_ticker'
                                    name='ticker_logo'
                                    onChange={changeHandler}
                                    style={{ display: 'none' }}
                                  />
                                  {errors.ticker_logo && (
                                    <div
                                      style={{ fontSize: 14 }}
                                      className='text-left mt-1 text-danger'>
                                      {errors.ticker_logo}
                                    </div>
                                  )}
                                  <ReactTooltip
                                    anchorId='ticker_logo'
                                    place='bottom'
                                    content={
                                      optionalFile['ticker_logo'] &&
                                      optionalFile['ticker_logo'].name
                                    }
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('project_website') &&
                        changefields.hasOwnProperty('project_website') && (
                          <Col lg={6}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='project_website'
                                  className='text-black font-weight-normal'>
                                  {CONST.PROJECT_WEBSITE}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='project_website'
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('project_description') &&
                        changefields.hasOwnProperty('project_description') && (
                          <Col lg={12}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='project_description'
                                  className='text-black font-weight-normal'>
                                  {CONST.PROJECT_DESCRIPTION}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  type='textarea'
                                  name='project_description'
                                  rows='6'
                                  className={'border-dark font-size-12'}
                                  placeholder={CONST.ENTER_DESCRIPTION}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              ((!completedfieldsdata.includes('twitter') &&
                changefields.hasOwnProperty('twitter')) ||
                (!completedfieldsdata.includes('discord') &&
                  changefields.hasOwnProperty('discord')) ||
                (!completedfieldsdata.includes('linkedin') &&
                  changefields.hasOwnProperty('linkedin')) ||
                (!completedfieldsdata.includes('facebook') &&
                  changefields.hasOwnProperty('facebook')) ||
                (!completedfieldsdata.includes('instagram') &&
                  changefields.hasOwnProperty('instagram')) ||
                (!completedfieldsdata.includes('tiktok') &&
                  changefields.hasOwnProperty('tiktok'))) && (
                <>
                  <CustomHeaders headerName='Link of Social Medias + No. of Followers' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('twitter') &&
                        changefields.hasOwnProperty('twitter') && (
                          <Col lg={6}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='twitter'
                                  className='text-black font-weight-normal'>
                                  {CONST.TWITTER}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='twitter'
                                  values={values}
                                  handleChange={handleChange}
                                  placeholder={CONST.ENTER_TWITTER_NAME}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('discord') &&
                        changefields.hasOwnProperty('discord') && (
                          <Col lg={6}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='discord'
                                  className='text-black font-weight-normal'>
                                  {CONST.DISCORD}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='discord'
                                  placeholder={CONST.ENTER_DISCORD_NAME}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                    <Row className='mb-2'>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('linkedin') &&
                        changefields.hasOwnProperty('linkedin') && (
                          <Col lg={6}>
                            <FormGroup>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='linkedin'
                                  className='text-black font-weight-normal'>
                                  {CONST.LINKEDIN}({CONST.OPTIONAL})
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='linkedin'
                                  placeholder={CONST.ENTER_LINKEDIN_NAME}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('facebook') &&
                        changefields.hasOwnProperty('facebook') && (
                          <Col lg={6}>
                            <FormGroup>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='facebook'
                                  className='text-black font-weight-normal'>
                                  {CONST.FACEBOOK}({CONST.OPTIONAL})
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='facebook'
                                  placeholder={CONST.ENTER_FACEBOOK_NAME}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('instagram') &&
                        changefields.hasOwnProperty('instagram') && (
                          <Col lg={6}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='instagram'
                                  className='text-black font-weight-normal'>
                                  {CONST.INSTAGRAM}({CONST.OPTIONAL})
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='instagram'
                                  placeholder={CONST.ENTER_INSTAGRAM_NAME}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('tiktok') &&
                        changefields.hasOwnProperty('tiktok') && (
                          <Col lg={6}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='tiktok'
                                  className='text-black font-weight-normal'>
                                  {CONST.TIKTOK}({CONST.OPTIONAL})
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='tiktok'
                                  placeholder={CONST.ENTER_TIKTOK_NAME}
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              ((!completedfieldsdata.includes('coin_market_cap_url') &&
                changefields.hasOwnProperty('coin_market_cap_url')) ||
                (!completedfieldsdata.includes('coin_gecko_url') &&
                  changefields.hasOwnProperty('coin_gecko_url')) ||
                (!completedfieldsdata.includes('looking_to_get_listed') &&
                  changefields.hasOwnProperty('looking_to_get_listed'))) && (
                <>
                  <CustomHeaders headerName='Link to CoinMarketCap/CoinGecko' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('coin_market_cap_url') &&
                        changefields.hasOwnProperty('coin_market_cap_url') && (
                          <Col lg={4}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='coin_market_cap_url'
                                  className='text-black font-weight-normal'>
                                  {CONST.COINMARKETCAPURL}({CONST.OPTIONAL})
                                </Label>
                              </div>

                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='coin_market_cap_url'
                                  values={values}
                                  handleChange={handleChange}
                                  placeholder='https://example.com'
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('coin_gecko_url') &&
                        changefields.hasOwnProperty('coin_gecko_url') && (
                          <Col lg={4}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='coin_gecko_url'
                                  className='text-black font-weight-normal'>
                                  {CONST.COINGECKOURL}({CONST.OPTIONAL})
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='coin_gecko_url'
                                  placeholder='https://example.com'
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('looking_to_get_listed') &&
                        changefields.hasOwnProperty('looking_to_get_listed') && (
                          <Col lg={4}>
                            <FormGroup>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='looking_to_get_listed'
                                  className='text-black font-weight-normal'>
                                  {CONST.LOOKING_TO_GET_LISTED}({CONST.OPTIONAL})
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='looking_to_get_listed'
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              (changefields.hasOwnProperty('token_address') ||
                changefields.hasOwnProperty('blockchain_network')) && (
                <>
                  <CustomHeaders headerName='Chains Supported' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      {changefields.hasOwnProperty('blockchain_network') &&
                        !completedfieldsdata.includes('blockchain_network') && (
                          <Col lg={6}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='blockchain_network'
                                  className='text-black font-weight-normal'>
                                  {CONST.NETWORK}
                                </Label>
                              </div>
                              <div>
                                <Select
                                  className='select-input border-dark'
                                  style={{
                                    paddingRight: '30px',
                                    borderRadius: '8px'
                                  }}
                                  options={allNetworks}
                                  value={allNetworks.find(
                                    (c) => c.value === selectNetworkList
                                  )}
                                  onChange={(val) => {
                                    changeHandlerSelect(val);
                                    setFieldValue('blockchain_network', val.value);
                                  }}
                                  name='blockchain_network'
                                />
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {displayotherNetwork && (
                        <Col lg={6}>
                          <FormGroup className='mb-4'>
                            <div className='font-size-12'>
                              <Label
                                htmlFor='other_network'
                                className='text-black font-weight-normal'>
                                {CONST.OTHER_NETWORK}
                              </Label>
                            </div>
                            <div>
                              <AuthInput
                                className='font-size-12'
                                type='text'
                                name='other_network'
                                values={values}
                                handleChange={handleChange}
                                errors={errors}
                                touched={touched}></AuthInput>
                            </div>
                          </FormGroup>
                        </Col>
                      )}
                      {changefields.hasOwnProperty('token_address') &&
                        !completedfieldsdata.includes('token_address') && (
                          <Col lg={6}>
                            <FormGroup className='mb-4'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='token_address'
                                  className='text-black font-weight-normal'>
                                  {CONST.TOKEN_ADDRESS}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='token_address'
                                  values={values}
                                  handleChange={handleChange}
                                  placeholder={CONST.ENTER_TOKEN_ADDRESS_NAME}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('number_active_users') &&
              changefields.hasOwnProperty('number_active_users') && (
                <>
                  <CustomHeaders headerName='Number of Active Users in the Product' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='number_active_users'
                              className='text-black font-weight-normal'>
                              {CONST.NUMBEROFACTIVEUSERS}
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              className='font-size-12'
                              type='text'
                              name='number_active_users'
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes(keyToFind) &&
              keyExists && (
                <>
                  <CustomHeaders headerName='Existing Exchanges List' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='existing_exchanges_listed'
                              className='text-black font-weight-normal'>
                              {CONST.EXISTING_EXCHANGES_LISTED}({CONST.OPTIONAL})
                            </Label>
                          </div>
                          <div>
                            <Select
                              className='select-input border-dark'
                              isMulti
                              style={{
                                paddingRight: '30px',
                                borderRadius: '8px'
                              }}
                              options={exchangeList}
                              onChange={(val) => changeHandlerExchange(val)}
                              name='existing_exchanges_listed'
                              value={selectedOptions}
                              isDisabled
                            />
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                    <Row>
                      <Col lg={6}>
                        {filterExchangeList.length > 0 ? (
                          <Table responsive>
                            <thead>
                              <th>#</th>
                              <th>Exchange Name</th>
                              <th>24 hour trading volume</th>
                            </thead>
                            <tbody>
                              {filterExchangeList.map((item, index) => {
                                return (
                                  <ValidateTable
                                    item={item}
                                    index={index}
                                    changefields={changefields}
                                  />
                                );
                              })}
                            </tbody>
                          </Table>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('market_makers') &&
              changefields.hasOwnProperty('market_makers') && (
                <>
                  <CustomHeaders headerName='Name of Your Market Maker' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='market_makers'
                              className='text-black font-weight-normal'>
                              {CONST.MARKETMAKERS}
                            </Label>
                          </div>
                          <div>
                            <TagsInput
                              value={selected}
                              onChange={setSelected}
                              name='market_makers'
                              placeHolder='Enter Name of your market maker'
                            />
                            <em>press enter to add new tag</em>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('marketing_partners') &&
              changefields.hasOwnProperty('marketing_partners') && (
                <>
                  <CustomHeaders headerName='Major Project Influencers and Marketing Partners for Project Promotion' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='marketing_partners'
                              className='text-black font-weight-normal'>
                              {CONST.MARKETINGPARTNERS}
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              className='font-size-12'
                              type='textarea'
                              name='marketing_partners'
                              placeholder={CONST.ENTER_DESCRIPTION}
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('exchange_launch_marketing_campaign') &&
              changefields.hasOwnProperty('exchange_launch_marketing_campaign') && (
                <>
                  <CustomHeaders headerName='Strategies for Driving User Adoption After Listing a Token on an Exchange' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='exchange_launch_marketing_campaign'
                              className='text-black font-weight-normal'>
                              {CONST.EXCHANGELAUNCHMARKETINGCAMPAIGN}
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              className='font-size-12'
                              type='text'
                              name='exchange_launch_marketing_campaign'
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('marketing_budget_allocated_for_listing') &&
              changefields.hasOwnProperty('marketing_budget_allocated_for_listing') && (
                <>
                  <CustomHeaders headerName='Optimizing Listing Success: Allocating an Effective Exchange Marketing Budget' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='marketing_budget_allocated_for_listing'
                              className='text-black font-weight-normal'>
                              {CONST.MARKETINGBUDGETALLOCATEDFORLISTING}
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              className='font-size-12'
                              type='text'
                              placeholder={CONST.ENTER_DESCRIPTION}
                              name='marketing_budget_allocated_for_listing'
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              ((!completedfieldsdata.includes('whitepapers_doc') &&
                changefields.hasOwnProperty('whitepapers_doc')) ||
                (!completedfieldsdata.includes('pitch_decks_doc') &&
                  changefields.hasOwnProperty('pitch_decks_doc'))) && (
                <>
                  <CustomHeaders headerName="Project's Whitepaper + Pitch deck" />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col
                        lg={6}
                        style={{
                          background: '#DDDADA',
                          borderRadius: '20px',
                          height: '90px'
                        }}
                        className='p-3'>
                        <FormGroup>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='description'
                              className='text-black font-weight-normal'>
                              Project's Documents
                            </Label>
                          </div>
                          <div className='d-flex direction-row'>
                            {completedfieldsdata &&
                              !completedfieldsdata.includes('whitepapers_doc') &&
                              changefields.hasOwnProperty('whitepapers_doc') && (
                                <div>
                                  <Label htmlFor='whitepapers' id='whitepapers_doc'>
                                    <span
                                      className='p-1 mb-2 rounded-circle'
                                      style={{
                                        color: '#134a87',
                                        fontSize: 15,
                                        cursor: 'pointer'
                                      }}>
                                      <img
                                        src={
                                          optionalFile['whitepapers_doc']
                                            ? documentUploadedIcon
                                            : documentUploadIcon
                                        }
                                        alt='Whitepapers'></img>
                                    </span>
                                    Whitepapers
                                  </Label>
                                  {fileUrls.whitepapers_doc && (
                                    <a
                                      href={fileUrls.whitepapers_doc}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                      View File
                                    </a>
                                  )}
                                  <Input
                                    type='file'
                                    id='whitepapers'
                                    name='whitepapers_doc'
                                    onChange={changeHandler}
                                    style={{ display: 'none' }}
                                  />
                                  <ReactTooltip
                                    anchorId='whitepapers_doc'
                                    place='bottom'
                                    content={
                                      optionalFile['whitepapers_doc'] &&
                                      optionalFile['whitepapers_doc'].name
                                    }
                                  />
                                </div>
                              )}
                            {completedfieldsdata &&
                              !completedfieldsdata.includes('pitch_decks_doc') &&
                              changefields.hasOwnProperty('pitch_decks_doc') && (
                                <div className='ml-5 pl-5'>
                                  <Label htmlFor='pitch_deck' id='pitch_decks_doc'>
                                    <span
                                      className='p-1 mb-2 rounded-circle'
                                      style={{
                                        color: '#134a87',
                                        fontSize: 15,
                                        cursor: 'pointer'
                                      }}>
                                      <img
                                        src={
                                          optionalFile['pitch_decks_doc']
                                            ? documentUploadedIcon
                                            : documentUploadIcon
                                        }
                                        alt='IntegrationFlows'></img>
                                    </span>
                                    Pitch deck
                                  </Label>
                                  {fileUrls.pitch_decks_doc && (
                                    <a
                                      href={fileUrls.pitch_decks_doc}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                      View File
                                    </a>
                                  )}
                                  <Input
                                    type='file'
                                    id='pitch_deck'
                                    name='pitch_decks_doc'
                                    onChange={changeHandler}
                                    style={{ display: 'none' }}
                                  />
                                  <ReactTooltip
                                    anchorId='pitch_decks_doc'
                                    place='bottom'
                                    content={
                                      optionalFile['pitch_decks_doc'] &&
                                      optionalFile['pitch_decks_doc'].name
                                    }
                                  />
                                </div>
                              )}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('listing_program_at_bybit') &&
              changefields.hasOwnProperty('listing_program_at_bybit') && (
                <>
                  <CustomHeaders headerName='Programs of Interest' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='listing_program_at_bybit'
                              className='text-black font-weight-normal'>
                              Checkboxes
                            </Label>
                          </div>
                          <div>
                            {values.listing_program_at_bybit?.map((program, index) => (
                              <FormGroup check key={index}>
                                <Label check>
                                  <Field
                                    type='checkbox'
                                    name={`listing_program_at_bybit[${index}].isChecked`}
                                    as={Input}
                                    onChange={(event) => {
                                      const { name, checked } = event.target;
                                      if (
                                        name === 'listing_program_at_bybit[3].isChecked'
                                      ) {
                                        const updatedPrograms =
                                          initialValue.listing_program_at_bybit.map(
                                            (program, index) => {
                                              return {
                                                ...program,
                                                isChecked: checked
                                              };
                                            }
                                          );

                                        setFieldValue(
                                          'listing_program_at_bybit',
                                          updatedPrograms
                                        );
                                      } else {
                                        handleChange(event);
                                      }
                                    }}
                                  />{' '}
                                  {program.label}
                                </Label>
                              </FormGroup>
                            ))}
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('listing_date') &&
              changefields.hasOwnProperty('listing_date') && (
                <>
                  <CustomHeaders headerName='Estimated Listing Date at Exchange' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-4'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='listing_date'
                              className='text-black font-weight-normal'>
                              {CONST.LISTING_DATE}
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              className='font-size-12'
                              type='datetime-local'
                              name='listing_date'
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('listing_price') &&
              changefields.hasOwnProperty('listing_price') && (
                <>
                  <CustomHeaders headerName='Potential Listing Price at Exchange' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='listing_price'
                              className='text-black font-weight-normal'>
                              {CONST.LISTING_PRICE}
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              className='font-size-12'
                              type='text'
                              name='listing_price'
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              ((!completedfieldsdata.includes('interested_in_audit') &&
                changefields.hasOwnProperty('interested_in_audit')) ||
                (!completedfieldsdata.includes('audit_doc') &&
                  changefields.hasOwnProperty('audit_doc'))) && (
                <>
                  <CustomHeaders headerName='Auditing Report' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('interested_in_audit') &&
                        changefields.hasOwnProperty('interested_in_audit') && (
                          <Col lg={6}>
                            <FormGroup check>
                              <Label check>
                                <Input
                                  style={{ paddingRight: '30px', borderRadius: '8px' }}
                                  value={values}
                                  rows='6'
                                  name='interested_in_audit'
                                  type='checkbox'
                                  onChange={handleChange}
                                  checked={values.interested_in_audit}
                                />{' '}
                                Are you interested in receiving a Smart Contract audit.
                              </Label>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('audit_doc') &&
                        changefields.hasOwnProperty('audit_doc') && (
                          <Col
                            lg={6}
                            className='pt-3'
                            style={{ background: '#DDDADA', borderRadius: '20px' }}>
                            <FormGroup>
                              <div className='d-flex direction-row'>
                                <div>
                                  <Label htmlFor='audit' id='audit_doc'>
                                    <span
                                      className='p-1 mb-2 rounded-circle'
                                      style={{
                                        color: '#134a87',
                                        fontSize: 15,
                                        cursor: 'pointer'
                                      }}>
                                      <img
                                        src={
                                          optionalFile['audit_doc']
                                            ? documentUploadedIcon
                                            : documentUploadIcon
                                        }
                                        alt='audit'></img>
                                    </span>
                                    <span>Upload your Audit</span>
                                  </Label>
                                  {fileUrls.audit_doc && (
                                    <a
                                      href={fileUrls.audit_doc}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                      View File
                                    </a>
                                  )}
                                  <Input
                                    type='file'
                                    id='audit'
                                    name='audit_doc'
                                    onChange={changeHandler}
                                    style={{ display: 'none' }}
                                  />
                                  <ReactTooltip
                                    anchorId='audit_doc'
                                    place='bottom'
                                    content={
                                      optionalFile['audit_doc'] &&
                                      optionalFile['audit_doc'].name
                                    }
                                  />
                                </div>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              ((!completedfieldsdata.includes('tokenomics_doc') &&
                changefields.hasOwnProperty('tokenomics_doc')) ||
                (!completedfieldsdata.includes('token_distribution_doc') &&
                  changefields.hasOwnProperty('token_distribution_doc')) ||
                (!completedfieldsdata.includes('token_use_case') &&
                  changefields.hasOwnProperty('token_use_case'))) && (
                <>
                  <CustomHeaders headerName='Tokenomics' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col
                        lg={6}
                        style={{
                          background: '#DDDADA',
                          borderRadius: '20px',
                          height: '90px'
                        }}
                        className='p-3'>
                        <FormGroup>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='description'
                              className='text-black font-weight-normal'>
                              Tokenomics
                            </Label>
                          </div>
                          <div className='d-flex direction-row'>
                            {completedfieldsdata &&
                              !completedfieldsdata.includes('tokenomics_doc') &&
                              changefields.hasOwnProperty('tokenomics_doc') && (
                                <div>
                                  <Label htmlFor='tokenomics' id='tokenomics_doc'>
                                    <span
                                      className='p-1 mb-2 rounded-circle'
                                      style={{
                                        color: '#134a87',
                                        fontSize: 15,
                                        cursor: 'pointer'
                                      }}>
                                      <img
                                        src={
                                          optionalFile['tokenomics_doc']
                                            ? documentUploadedIcon
                                            : documentUploadIcon
                                        }
                                        alt='tokenomics'></img>
                                    </span>
                                    Tokenomics
                                  </Label>
                                  {fileUrls.tokenomics_doc && (
                                    <a
                                      href={fileUrls.tokenomics_doc}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                      View File
                                    </a>
                                  )}
                                  <Input
                                    type='file'
                                    id='tokenomics'
                                    name='tokenomics_doc'
                                    onChange={changeHandler}
                                    style={{ display: 'none' }}
                                  />
                                  <ReactTooltip
                                    anchorId='tokenomics_doc'
                                    place='bottom'
                                    content={
                                      optionalFile['tokenomics_doc'] &&
                                      optionalFile['tokenomics_doc'].name
                                    }
                                  />
                                </div>
                              )}
                            {completedfieldsdata &&
                              !completedfieldsdata.includes('token_distribution_doc') &&
                              changefields.hasOwnProperty('token_distribution_doc') && (
                                <div className='ml-5 pl-5'>
                                  <Label
                                    htmlFor='token_distribution'
                                    id='token_distribution_doc'>
                                    <span
                                      className='p-1 mb-2 rounded-circle'
                                      style={{
                                        color: '#134a87',
                                        fontSize: 15,
                                        cursor: 'pointer'
                                      }}>
                                      <img
                                        src={
                                          optionalFile['token_distribution_doc']
                                            ? documentUploadedIcon
                                            : documentUploadIcon
                                        }
                                        alt='IntegrationFlows'></img>
                                    </span>
                                    Token Distribution
                                  </Label>
                                  {fileUrls.token_distribution_doc && (
                                    <a
                                      href={fileUrls.token_distribution_doc}
                                      target='_blank'
                                      rel='noopener noreferrer'
                                      className='pl-2 font-size-14 font-weight-bold text-wrap text-primary'>
                                      View File
                                    </a>
                                  )}
                                  <Input
                                    type='file'
                                    id='token_distribution'
                                    name='token_distribution_doc'
                                    onChange={changeHandler}
                                    style={{ display: 'none' }}
                                  />
                                  <ReactTooltip
                                    anchorId='token_distribution_doc'
                                    place='bottom'
                                    content={
                                      optionalFile['token_distribution_doc'] &&
                                      optionalFile['token_distribution_doc'].name
                                    }
                                  />
                                </div>
                              )}
                          </div>
                        </FormGroup>
                      </Col>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('token_use_case') &&
                        changefields.hasOwnProperty('token_use_case') && (
                          <Col lg={6}>
                            <FormGroup className='mb-6'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='token_use_case'
                                  className='text-black font-weight-normal'>
                                  {CONST.USE_CASES_OF_TOKENS}
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='textarea'
                                  placeholder={CONST.ENTER_DESCRIPTION}
                                  name='token_use_case'
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                  </div>
                </>
              )}

            {completedfieldsdata &&
              !completedfieldsdata.includes('investment_data') &&
              changefields.hasOwnProperty('investment_data') && (
                <>
                  <CustomHeaders headerName='Investment History' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={3}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='round'
                              className='text-black font-weight-normal'>
                              {CONST.ROUND}
                            </Label>
                          </div>
                          <div>
                            <Select
                              options={allInventoryOption}
                              onChange={handleChangeOpt}
                            />
                          </div>
                        </FormGroup>
                      </Col>
                      <Col lg={8}>
                        {displayinvestorData ? (
                          <>
                            <div className='ml-5 pt-3 pl-4 pb-5 investor_box'>
                              <div className='d-flex'>
                                <FormGroup className='mr-4 pr-4'>
                                  <div className='font-size-12'>
                                    <Label
                                      htmlFor='token_size'
                                      className='text-black font-weight-normal'>
                                      {CONST.TOKEN_SIZE}
                                    </Label>
                                  </div>
                                  <div>
                                    <Input
                                      type='number'
                                      name='token_size'
                                      value={tokenSize}
                                      onChange={(e) =>
                                        setTokenSize(e.target.value)
                                      }></Input>
                                  </div>
                                </FormGroup>
                                <FormGroup>
                                  <div className='font-size-12'>
                                    <Label
                                      htmlFor='token_price'
                                      className='text-black font-weight-normal'>
                                      {CONST.TOKEN_PRICE}
                                    </Label>
                                  </div>
                                  <div>
                                    <Input
                                      type='number'
                                      name='token_price'
                                      value={tokenPrice}
                                      onChange={(e) =>
                                        setTokenPrice(e.target.value)
                                      }></Input>
                                  </div>
                                </FormGroup>
                              </div>
                              <div>
                                <div className='font-size-12'>
                                  <Label className='text-black font-weight-normal'>
                                    {CONST.ADD_INVESTORS}
                                  </Label>
                                </div>
                                <div className='d-flex'>
                                  <Row>
                                    {inputs.map((input, index) => (
                                      <>
                                        <Col lg={4}>
                                          <FormGroup>
                                            <div className='font-size-12'>
                                              <Label
                                                htmlFor='investors_name'
                                                className='text-black font-weight-normal'>
                                                Name
                                              </Label>
                                            </div>
                                            <div>
                                              <Input
                                                type='text'
                                                value={input.name}
                                                onChange={(e) =>
                                                  handleTextNameChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </FormGroup>
                                        </Col>
                                        <Col lg={4}>
                                          <FormGroup>
                                            <div className='font-size-12'>
                                              <Label
                                                htmlFor='investors_amount'
                                                className='text-black font-weight-normal'>
                                                Amount
                                              </Label>
                                            </div>
                                            <div>
                                              <Input
                                                type='number'
                                                value={input.amount}
                                                onChange={(e) =>
                                                  handleTextAmountChange(
                                                    index,
                                                    e.target.value
                                                  )
                                                }
                                              />
                                            </div>
                                          </FormGroup>
                                        </Col>
                                        <Col lg={1}>
                                          <FormGroup>
                                            <div className='font-size-12'>
                                              <Label
                                                htmlFor='investors_amount'
                                                className='text-black font-weight-normal'>
                                                Lead
                                              </Label>
                                            </div>
                                            <div className='ml-3 mt-2'>
                                              <input
                                                type='checkbox'
                                                value={input.lead}
                                                onChange={(e) =>
                                                  handleLeadChange(
                                                    index,
                                                    e.target.checked
                                                  )
                                                }
                                              />
                                            </div>
                                          </FormGroup>
                                        </Col>
                                        <Col lg={3}>
                                          <FormGroup className='mt-4 pt-2 '>
                                            {index === 0 ? null : (
                                              <button
                                                onClick={() => handleRemoveInput(index)}
                                                className='btn btn-auth'
                                                style={{ width: 113, height: 40 }}>
                                                Remove
                                              </button>
                                            )}
                                          </FormGroup>
                                        </Col>
                                      </>
                                    ))}
                                  </Row>
                                </div>
                                <FormGroup className='mt-3 ml-3'>
                                  <button
                                    type='button'
                                    onClick={handleAddInput}
                                    className='btn btn-auth'
                                    style={{ width: 163, height: 40 }}>
                                    Add New Investor
                                  </button>
                                </FormGroup>
                              </div>
                              <button
                                type='button'
                                onClick={handleSubmitRound}
                                className='btn btn-auth'
                                style={{ width: 113, height: 40 }}>
                                Add Round
                              </button>
                            </div>
                          </>
                        ) : (
                          ''
                        )}
                      </Col>
                    </Row>
                  </div>
                  {showRound && (
                    <div className='pt-3 pb-5 mt-4'>
                      <Row>
                        {displayinvestorListData.map((round, index) => (
                          <Col lg={4} key={index} className='p-2 mt-3'>
                            <div className='round-info'>
                              <h3>{round.round} Round</h3>
                              <p className='total-price'>{round.token_size} total</p>
                              <p className='token-price'>{round.token_price} per token</p>
                              <div className='investor-list'>
                                <h4>Investors</h4>
                                <Table striped bordered hover responsive>
                                  <thead>
                                    <tr>
                                      <th>Investor Name</th>
                                      <th>Investment Amount</th>
                                      <th>Lead Investor?</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {round.all_investors.map((input, idx) => (
                                      <tr key={idx}>
                                        <td>{input.name}</td>
                                        <td>{input.amount}</td>
                                        <td>{input.lead ? 'True' : 'False'}</td>
                                      </tr>
                                    ))}
                                  </tbody>
                                </Table>
                              </div>
                            </div>
                          </Col>
                        ))}
                      </Row>
                    </div>
                  )}
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('road_map') &&
              changefields.hasOwnProperty('road_map') && (
                <>
                  <CustomHeaders headerName='Road Map and Upcoming Events/Updates' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='road_map'
                              className='text-black font-weight-normal'>
                              {CONST.ROADMAP}
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              className='font-size-12'
                              type='textarea'
                              placeholder={CONST.ENTER_DESCRIPTION}
                              name='road_map'
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('unique_selling_point') &&
              changefields.hasOwnProperty('unique_selling_point') && (
                <>
                  <CustomHeaders headerName='What sets your project apart from competitors?' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='unique_selling_point'
                              className='text-black font-weight-normal'>
                              {CONST.UNIQUESELLINGPOINT}
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              className='font-size-12'
                              type='textarea'
                              placeholder={CONST.ENTER_DESCRIPTION}
                              name='unique_selling_point'
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('target_geo') &&
              changefields.hasOwnProperty('target_geo') && (
                <>
                  <CustomHeaders headerName='Primary focal areas or principal user base regions' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='target_geo'
                              className='text-black font-weight-normal'>
                              {CONST.TARGETGEO}
                            </Label>
                          </div>
                          <div>
                            <ReactTags
                              tags={tags}
                              classNames={{
                                root: 'font-size-12 border-dark custom-react-tags'
                              }}
                              name='target_geo'
                              placeholderText='Select Region'
                              suggestions={suggestionsRegion}
                              onDelete={(index) => {
                                onDelete(index);
                                const newTags = tags.filter((t, i) => i !== index);
                                setFieldValue(
                                  'target_geo',
                                  newTags.map((tag) => tag.name)
                                );
                              }}
                              onAddition={(tag) => {
                                onAddition(tag);
                                // Update the Formik value
                                const newTags = [...tags, tag];
                                setFieldValue(
                                  'target_geo',
                                  newTags.map((tag) => tag.name)
                                );
                              }}
                            />
                          </div>
                          {errors.target_geo && touched.target_geo ? (
                            <div className='text-danger'>{errors.target_geo}</div>
                          ) : null}
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              ((!completedfieldsdata.includes('demo_link') &&
                changefields.hasOwnProperty('demo_link')) ||
                (!completedfieldsdata.includes('video_link') &&
                  changefields.hasOwnProperty('video_link'))) && (
                <>
                  <CustomHeaders headerName='If the product is not live yet, could you kindly provide a link to a demo?' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('demo_link') &&
                        changefields.hasOwnProperty('demo_link') && (
                          <Col lg={4}>
                            <FormGroup className='mb-6'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='demo_link'
                                  className='text-black font-weight-normal'>
                                  {CONST.DEMO_LINK}({CONST.OPTIONAL})
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='demo_link'
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                      {completedfieldsdata &&
                        !completedfieldsdata.includes('video_link') &&
                        changefields.hasOwnProperty('video_link') && (
                          <Col lg={4}>
                            <FormGroup className='mb-6'>
                              <div className='font-size-12'>
                                <Label
                                  htmlFor='video_link'
                                  className='text-black font-weight-normal'>
                                  {CONST.VIDEO_LINK}({CONST.OPTIONAL})
                                </Label>
                              </div>
                              <div>
                                <AuthInput
                                  className='font-size-12'
                                  type='text'
                                  name='video_link'
                                  values={values}
                                  handleChange={handleChange}
                                  errors={errors}
                                  touched={touched}></AuthInput>
                              </div>
                            </FormGroup>
                          </Col>
                        )}
                    </Row>
                  </div>
                </>
              )}
            {completedfieldsdata &&
              !completedfieldsdata.includes('extra_information') &&
              changefields.hasOwnProperty('extra_information') && (
                <>
                  <CustomHeaders headerName='Additional significant details that could assist us in assessing the project' />

                  <div className='mt-2 ml-2'>
                    <Row>
                      <Col lg={6}>
                        <FormGroup className='mb-6'>
                          <div className='font-size-12'>
                            <Label
                              htmlFor='extra_information'
                              className='text-black font-weight-normal'>
                              {CONST.EXTRA_INFORMATION}({CONST.OPTIONAL})
                            </Label>
                          </div>
                          <div>
                            <AuthInput
                              className='font-size-12'
                              type='textarea'
                              placeholder={CONST.ENTER_DESCRIPTION}
                              name='extra_information'
                              values={values}
                              handleChange={handleChange}
                              errors={errors}
                              touched={touched}></AuthInput>
                          </div>
                        </FormGroup>
                      </Col>
                    </Row>
                  </div>
                </>
              )}
            <div className='d-flex justify-content-end'>
              <button
                className='btn btn-auth'
                type='submit'
                style={{ width: 150, height: 40 }}
                onSubmit={(values) => handleSubmit(values)}>
                Update
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </React.Fragment>
  );
}

export default EditExchangeSubApplication;

const CustomHeaders = ({ headerName }) => {
  return (
    <div className='col-12 col-sm-auto font-size-18 pb-2 pb-sm-0'>
      <span className='text-black'>
        <u>{headerName}</u>
      </span>
    </div>
  );
};
