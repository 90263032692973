import React, { useEffect, useState } from 'react';
import { Col, Container, FormGroup, Label, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { constants as CONST } from '../../../constants/application';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import AuthInput from '../../../Custom/AuthInput';
import {
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import get from 'lodash/get';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import { notifyError } from '../../../Custom/notification';
import { Progress } from 'reactstrap';
import { StepsCalculator } from './StepsCalculator';

function StepFour(props) {
  const { setExchangeCompleteStep, setIsLoading } = props;

  const CapMarketSchema = Yup.object().shape({
    coin_market_cap_url: Yup.string().url(CONST.ENTER_VALID_URL),
    coin_gecko_url: Yup.string().url(CONST.ENTER_VALID_URL),
    looking_to_get_listed: Yup.string()
  });
  const [initialValue, setInitialValue] = useState({
    coin_market_cap_url: '',
    coin_gecko_url: '',
    looking_to_get_listed: ''
  });
  const step = 4;
  const [completeStep, setCompleteStep] = useState(0);

  useEffect(() => {
    if (props.applicationId !== '') {
      GetApplication({ application_id: props.applicationId }).then((res) => {
        const application = get(res, 'data.data.data[0]', '');
        if (application.exchange_application.coin_market_cap_url !== '') {
          setInitialValue({
            coin_market_cap_url: application.exchange_application.coin_market_cap_url,
            coin_gecko_url: application.exchange_application.coin_gecko_url,
            looking_to_get_listed: application.exchange_application.looking_to_get_listed
          });
        }
        setCompleteStep(application.exchange_application.percentage_complete);
      });
    }
  }, []);

  const handleSubmit = (values) => {
    const { nextPage, applicationId } = props;
    let stepsRequired = Math.floor(StepsCalculator(2, completeStep, 34));
    const data = {
      coin_market_cap_url: values.coin_market_cap_url,
      coin_gecko_url: values.coin_gecko_url,
      looking_to_get_listed: values.looking_to_get_listed,
      percentage_complete: stepsRequired
    };
    setIsLoading(true);
    UpdateExchangeApplication({ application_id: applicationId }, data)
      .then((res) => {
        const application = get(res, 'data.data.data', '');
        props.setApplicationData(application);
        nextPage();
        setIsLoading(false);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');
        notifyError(notificationMessageFunction(errorMessage));
      });
    setExchangeCompleteStep([0, 1, 2, 3, 4]);
  };
  const calculateProgress = () => {
    return completeStep;
  };

  return (
    <React.Fragment>
      <div className='col-12 font-size-20 pb-2'>
        <span className='text-black'>Link to CoinMarketCap/CoinGecko</span>
      </div>
      <Container className='col-12 col-sm-auto pt-4'>
        <div>
          <Formik
            initialValues={initialValue}
            enableReinitialize={true}
            validationSchema={CapMarketSchema}
            onSubmit={handleSubmit}>
            {({ errors, touched, values, handleChange }) => (
              <Form>
                <div>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.COINMARKETCAPURL}
                        htmlFor='coin_market_cap_url'
                        name='coin_market_cap_url'
                        placeholder={CONST.ENTER_URL_PLACEHOLDER}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        extraLabel={`(${CONST.OPTIONAL})`}
                      />
                    </Col>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.COINGECKOURL}
                        htmlFor='coin_gecko_url'
                        name='coin_gecko_url'
                        placeholder={CONST.ENTER_URL_PLACEHOLDER}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        extraLabel={`(${CONST.OPTIONAL})`}
                      />
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={6} md={12} sm={12}>
                      <CustomInput
                        label={CONST.LOOKING_TO_GET_LISTED}
                        htmlFor='looking_to_get_listed'
                        name='looking_to_get_listed'
                        placeholder={CONST.ENTER_URL_PLACEHOLDER}
                        values={values}
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        extraLabel={`(${CONST.OPTIONAL})`}
                      />
                    </Col>
                  </Row>
                </div>
                <div className='mb-4 mt-4'>
                  <Progress value={calculateProgress()} color='success'>
                    {calculateProgress()}%
                  </Progress>
                </div>
                <div className='d-flex justify-content-between'>
                  <button
                    className='btn btn-auth'
                    style={{ width: 150, height: 40 }}
                    onClick={props.prevPage}>
                    Previous
                  </button>
                  <button
                    className='btn btn-auth'
                    type='submit'
                    style={{ width: 150, height: 40 }}
                    onSubmit={(values) => handleSubmit(values)}>
                    Next
                  </button>
                </div>
              </Form>
            )}
          </Formik>
        </div>
      </Container>
    </React.Fragment>
  );
}

export default StepFour;

const CustomInput = ({
  label,
  htmlFor,
  setFieldTouched,
  name,
  placeholder,
  values,
  handleChange,
  errors,
  touched,
  type = 'text',
  extraLabel
}) => (
  <FormGroup className='mb-2'>
    <div className='font-size-12'>
      <Label htmlFor={htmlFor} className='text-black font-weight-normal'>
        {label} {extraLabel}
      </Label>
    </div>
    <div>
      <AuthInput
        setFieldTouched={setFieldTouched}
        withTouchError={true}
        type={type}
        name={name}
        className={'font-size-12'}
        placeholder={placeholder}
        values={values}
        handleChange={handleChange}
        errors={errors}
        touched={touched}
      />
    </div>
  </FormGroup>
);
