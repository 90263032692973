import api from '../../api/ramp2Api';
import * as businessURL from '../../api/ramp2URL';

export const adminOrderListAPI = ({ order, page, limit, tags }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.ADMIN_BUY_ORDERS +
          `?page=${page}` +
          (limit ? `&limit=${limit}` : '') +
          (order ? `&order=${order}` : '') +
          (tags ? `&tags=${tags}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const orderSuggestionAPI = () => {
  return new Promise(async (resolve, reject) => {
    return api
      .get(businessURL.ADMIN_BUY_ORDERS_SUGGETIONS)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const businessOrderListAPI = ({ order, page, limit, tags }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.BUSINESS_BUY_ORDERS +
          `?page=${page}` +
          (limit ? `&limit=${limit}` : '') +
          (order ? `&order=${order}` : '') +
          (tags ? `&tags=${tags}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const dashboardOrderCountAPI = (status) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(businessURL.DASHBOARD_ORDER_COUNT + `?status=${status}`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const dashboardTotalRevenueAPI = ({ days, status }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        businessURL.DASHBOARD_REVENUE +
          `?` +
          (days ? `&days=${days}` : '') +
          (status ? `&status=${status}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
