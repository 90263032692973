import React, { useState } from 'react';
import get from 'lodash/get';
import { Link, useHistory } from 'react-router-dom';
import { stringify } from 'query-string';
import ReactTags from 'react-tag-autocomplete';
import { ReactComponent as InfoCircleIcon } from '../../assets/images/icons/info-circle.svg';
import { AddOnRampTrial } from '../../services/Business/Crypto';

function TotalCoverage({ providers = [], network = {} }) {
  let history = useHistory();

  const suggestions = providers.map(({ provider, provider_name }) => ({
    id: provider,
    name: provider_name
  }));
  const [tags, setTags] = useState(suggestions.slice(0, 4));

  const onDelete = (index) => {
    setTags(tags.filter((t, i) => i !== index));
  };

  const onAddition = (tag) => {
    setTags([...tags, tag]);
  };

  const cryptos = {};
  (Array.isArray(providers) ? providers : []).forEach((provider) => {
    const { crypto_assets = [] } = provider;
    (Array.isArray(crypto_assets) ? crypto_assets : []).forEach((asset) => {
      const { _id } = asset;
      if (!cryptos[_id]) {
        cryptos[_id] = {
          ...asset
        };
      }
      cryptos[_id][provider.provider] = true;
    });
  });

  return (
    <>
      <div className="text-dark font-size-24 font-weight-medium mb-1">
        Total Coverage Table
      </div>
      <div className="mb-3">
        <ReactTags
          placeholderText="Add new"
          tags={tags}
          suggestions={suggestions}
          onDelete={onDelete}
          onAddition={onAddition}
        />
      </div>
      <div className="card">
        <div className="table-responsive">
          <table className="table table-centered">
            <thead>
              <tr className="font-size-16 text-muted">
                <th className="pl-4 pt-3 pb-4 font-weight-normal border-0 text-capitalize">
                  {network.network_name} Assets
                </th>
                {tags.map((tag, i) => (
                  <th
                    key={i}
                    className={
                      'pt-3 pb-4 text-center font-weight-normal border-0 ' +
                      (i + 1 >= tags.length ? 'pr-4' : '')
                    }>
                    <span className="mr-1">{tag.name}</span>
                    <InfoCircleIcon alt="" className="ml-2" height={25} />
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {Object.values(cryptos).map((crypto, key) => (
                <tr key={key} className="font-size-16">
                  <td className="pl-4 text-uppercase">
                    <Link
                      onClick={() => {
                        // AddOnRampTrial({ crypto_id: crypto.coin_id })
                        //   .then((res) => {
                        history.push(
                          `/crypto?${stringify({
                            crypto_id: (crypto.cryptoExist && crypto.coin_id) || null,
                            coin_gecko_id: crypto.coin_gecko_id,
                            coin_name: crypto.news_ticker
                          })}`
                        );
                        // })
                        // .catch((err) => {});
                      }}>
                      {crypto.crypto_name}
                    </Link>
                  </td>
                  {tags.map((tag, i) => {
                    const isOk = crypto[tag.id];
                    return (
                      <td
                        key={i}
                        className={
                          'text-center font-size-16 ' +
                          (i + 1 >= tags.length ? 'pr-4' : '')
                        }>
                        <div
                          className="text-white rounded-circle d-inline-flex align-items-center justify-content-center"
                          style={{
                            width: 40,
                            height: 40,
                            backgroundColor: isOk ? '#0BB07E' : '#EB5757'
                          }}>
                          <i className={'fas fa-' + (isOk ? 'check' : 'times')}></i>
                        </div>
                      </td>
                    );
                  })}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
}

export default TotalCoverage;
