import React, { useEffect, useState } from 'react';
import { MarketMakerInputName } from './helper';
import { Card, CardBody, Col, Collapse, FormGroup, Label, Row } from 'reactstrap';
import { constants as CONST } from '../../../../constants/application';
import { UpdateExchangeApplication } from '../../../../services/Business/Application';
import { get } from 'lodash';
import { notifyError, notifySuccess } from '../../../../Custom/notification';
import { notificationMessageFunction } from '../../../../constants/notificationConst';
import { TagsInput } from 'react-tag-input-component';

function MarketMakersApp({
  allResponses = [],
  errors = {},
  values,
  touched = {},
  isSectionCollapsed,
  toggleSection,
  setInitialValuesFunc,
  applicationId,
  handleTagsChange,
  handleChange
}) {
  const [errorShow, setErrorShow] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [responseCount, setResponseCount] = useState(0);

  useEffect(() => {
    const res = Object.keys(errors).find((i) => MarketMakerInputName.includes(i));
    setErrorShow(!!res);
  }, [errors]);

  useEffect(() => {
    const kybFiltered = allResponses.filter(
      (response) => response && response.applicationResponse.status === 'KYB'
    );
    let count = kybFiltered.reduce((accumulator, current) => {
      if (
        current.applicationResponse &&
        current.applicationResponse.field &&
        Object.keys(current.applicationResponse.field).length > 0
      ) {
        const existData = Object.keys(current.applicationResponse.field).filter((res) =>
          MarketMakerInputName.includes(res)
        );
        let completedFileds = 0;
        if (current.applicationResponse && current.applicationResponse.completed_field) {
          completedFileds = current.applicationResponse.completed_field.length;
        }
        return accumulator + existData.length - completedFileds;
      } else {
        return accumulator;
      }
    }, 0);

    setResponseCount(count);
  }, []);

  const handleSubmit = () => {
    if (
      !MarketMakerInputName.includes(
        Object.keys(errors).find((i) => MarketMakerInputName.includes(i))
      )
    ) {
      setIsLoading(true);
      const data = {
        market_makers: JSON.stringify(values.market_makers)
      };
      JSON.stringify(data);

      UpdateExchangeApplication({ application_id: applicationId }, data)
        .then((res) => {
          const application = get(res, 'data.data.data', '');
          notifySuccess(`Market Makers updated successfully`);

          setIsLoading(false);
          setInitialValuesFunc(application.exchange_application);
        })
        .catch((err) => {
          const errorMessage = get(err, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }
  };

  return (
    <Card
      style={{
        border: `1px solid ${errorShow ? '#ff3333' : 'rgb(128 128 128 / 30%)'} `,
        cursor: 'pointer'
      }}>
      <CardBody onClick={isSectionCollapsed ? toggleSection : undefined}>
        <div className='d-flex align-items-center' onClick={toggleSection}>
          <span className='font-size-14'>Name of Your Market Maker</span>
          {errorShow && (
            <div style={{ position: 'absolute', right: 50 }}>
              <div className='d-flex justify-content-center'>
                <lord-icon
                  src='https://cdn.lordicon.com/vyukcgvf.json'
                  trigger='hover'
                  target='#create-exchange-app'
                  colors='outline:#121331,primary:#ffc738,secondary:#92140c'
                  style={{ width: '35px', height: '35px' }}></lord-icon>
              </div>
            </div>
          )}
          <div style={{ position: 'absolute', right: 20 }}>
            <label style={{ fontSize: '25px', cursor: 'pointer' }} className='pl-3 mt-1'>
              <i
                className={`mdi mdi-chevron-${
                  isSectionCollapsed === true ? 'up' : 'down'
                }`}
              />
            </label>
          </div>
          {responseCount > 0 && (
            <div style={{ position: 'absolute', right: 5, top: 0 }}>
              <label className='badge badge-pill badge-warning font-size-12 mt-1'>
                {responseCount}
              </label>
            </div>
          )}
        </div>
        <div>
          {isLoading ? (
            <div className='h-100 d-flex align-items-center justify-content-center py-4'>
              <i
                className='fas fa-circle-notch fa-spin text-muted'
                style={{ fontSize: 48 }}></i>
            </div>
          ) : (
            <Collapse isOpen={!isSectionCollapsed}>
              <hr />
              <Row>
                <Col lg={6}>
                  <FormGroup className='mb-4'>
                    <div className='font-size-12'>
                      <Label
                        htmlFor='market_makers'
                        className='text-black font-weight-normal'>
                        {CONST.MARKETMAKERS}
                      </Label>
                    </div>

                    <div className='font-size-12'>
                      <TagsInput
                        value={values.market_makers}
                        onChange={handleTagsChange}
                        name='market_makers'
                        className='font-size-12'
                        placeHolder='Enter Name of your market maker'
                        handleChange={handleChange}
                        errors={errors}
                        touched={touched}
                        required
                      />
                      <em className='font-size-12'>press enter to add new tag</em>
                      {errors && (
                        <div className='error font-size-12'>{errors.market_makers}</div>
                      )}
                    </div>
                  </FormGroup>
                </Col>
              </Row>
              <div className='d-flex justify-content-center mt-4'>
                <button
                  className='btn btn-auth'
                  type='submit'
                  style={{ width: 150, height: 40 }}
                  onClick={handleSubmit}>
                  Continue
                </button>
              </div>
            </Collapse>
          )}
        </div>
      </CardBody>
    </Card>
  );
}

export default MarketMakersApp;
