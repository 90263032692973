import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Col, Container, Row } from 'reactstrap';
import 'react-tooltip/dist/react-tooltip.css';
import { useQueryParam, StringParam } from 'use-query-params';
import { constants as CONST } from '../../../constants/application';
import { constants as ERROR_CONST } from '../../../constants/errorMessage';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import {
  GetAllNetwork,
  GetApplication,
  UpdateExchangeApplication
} from '../../../services/Business/Application';
import { notificationMessageFunction } from '../../../constants/notificationConst';
import {
  NotificationToastContainer,
  notifyError,
  notifySuccess
} from '../../../Custom/notification';
import SimpleBar from 'simplebar-react';
import get from 'lodash/get';
import { ExchangeListAPI } from '../../../services/Auth';
import { investorsListAPI } from '../../../services/Admin/investors';
import {
  ApplicantBackgroundInputNames,
  DemoVideoLinkInputName,
  ExchangeLaunchMarketingCampaignInputName,
  ExtraInformationInputName,
  LinkOfSocialMediasFollowersInputNames,
  LinkToCoinMarketCapCoinGeckoInputName,
  ListingDateInputName,
  MarketingBudgetInputName,
  NumberOfActiveProductInputName,
  PotentialListingInputName,
  ProjectInfluencersInputName,
  RoadMapInputName,
  TargetGeoInputName,
  UniqueSellingPointInputName
} from './ApplicationPages/helper';
import VerticalStepperSection from '../../../Custom/VerticalStepperSection';
import TextAreaExchangeApplication from './ApplicationPages/TextAreaExchangeApplication';
import ProjectBackgroundApplication from './ApplicationPages/ProjectBackgroundApplication';
import ChainSupport from './ApplicationPages/ChainSupport';
import ExistingExchangesListApp from './ApplicationPages/ExistingExchangesListApp';
import MarketMakersApp from './ApplicationPages/MarketMakersApp';
import ProjectsWhitepaperAndPitchDeckApp from './ApplicationPages/ProjectsWhitepaperAndPitchDeckApp';
import ListingProgramApp from './ApplicationPages/ListingProgramApp';
import AuditingReportApp from './ApplicationPages/AuditingReportApp';
import TokenomicsApp from './ApplicationPages/TokenomicsApp';
import TargetGeoApp from './ApplicationPages/TargetGeoApp';
import { allCountries } from 'country-region-data';
import InvestorHistoryApp from './ApplicationPages/InvestorHistoryApp';
import moment from 'moment';
import TeamMembers from './ApplicationPages/TeamMembers';
import { regions } from '../../../constants/regionConst';

function ViewExchangeApplication({
  history,
  allResponses,
  GetApplicationResponseFunc,
  applicationDetails,
  setApplicationDetails,
  setApplicationAll
}) {
  const [applicationId] = useQueryParam('application_id', StringParam);
  const [allExchangeList, setAllExchangeList] = useState([]);
  const [exchangeList, setExchangeList] = useState([]);
  const [filterExchangeList, setFilterExchangeList] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [selectNetworkList, setSelectnetworkList] = useState('');
  const [selected, setSelected] = useState([]);
  const [displayinvestorData, setDisplayinvestorData] = useState(false);
  const [selectedRound, setselectedRound] = useState('');
  const [displayinvestorListData, setDisplayinvestorListData] = useState([]);
  const [showRound, setShowRound] = useState(false);
  const [investorsList, setInvestorsList] = useState([]);
  const [applicationStatus, setApplicationStatus] = useState('');
  const [fileUrls, setFileUrls] = useState({});
  const [isLoaded, setIsLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [allNetworks, setAllNetworks] = useState([]);
  const options = [
    { value: 'n/a', label: 'N/A' },
    { value: 'seed', label: 'Seed' },
    { value: 'series_a', label: 'Series A' },
    { value: 'series_b', label: 'Series B' },
    { value: 'series_c', label: 'Series C' },
    { value: 'series_d', label: 'Series D' },
    { value: 'series_e', label: 'Series E' },
    { value: 'late_stage_round', label: 'Late Stage Round' },
    { value: 'ipo', label: 'IPO' }
  ];
  const [allInventoryOption, setAllInventoryOption] = useState(options);
  const [tokenPrice, setTokenPrice] = useState('');
  const [tokenSize, setTokenSize] = useState('');
  const CheckboxValues = [
    { id: 1, label: 'Direct Listing', value: 'direct_listing', isChecked: false },
    { id: 2, label: 'Launchpad / IEO', value: 'launchpad', isChecked: false },
    { id: 3, label: 'Launchpool', value: 'launchpool', isChecked: false },
    { id: 4, label: 'All Listing Programs', value: 'all_programs', isChecked: false }
  ];
  const [checkBoxesData, setCheckBoxesData] = useState(CheckboxValues);
  const [inputs, setInputs] = useState([{ name: '', amount: '', lead: false }]);
  const [step, setStep] = useState(0);
  const [exchangeCompleteStep, setExchangeCompleteStep] = useState([
    0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 2
  ]);
  const [shouldSubmit, setShouldSubmit] = useState(false);

  const ExchangeFormSchema = Yup.object().shape({
    applicant_first_name: Yup.string().required(ERROR_CONST.APPLICANT_FIRST_NAME),
    applicant_last_name: Yup.string().required(ERROR_CONST.APPLICANT_LAST_NAME),
    applicant_email: Yup.string()
      .required(ERROR_CONST.APPLICANT_EMAIL)
      .email(ERROR_CONST.INVALID_EMAIL),
    applicant_telegram: Yup.string(),
    project_name: Yup.string()
      .required(ERROR_CONST.PROJECT_NAME)
      .matches(/^\S*$/, ERROR_CONST.SPACE_NOT_ALLOWED),
    token_name: Yup.string().required(ERROR_CONST.TOKEN_NAME),
    ticker_logo: Yup.mixed().required(ERROR_CONST.ICON),
    project_website: Yup.string().required(ERROR_CONST.PROJECT_WEBSITE),
    project_description: Yup.string().required(ERROR_CONST.PROJECT_DESCRIPTION),
    twitter: Yup.string().required(ERROR_CONST.ROADMAP_REQUIRED),
    discord: Yup.string().required(ERROR_CONST.ROADMAP_REQUIRED),
    linkedin: Yup.string(),
    facebook: Yup.string(),
    instagram: Yup.string(),
    tiktok: Yup.string(),
    coin_market_cap_url: Yup.string().url(CONST.ENTER_VALID_URL),
    coin_gecko_url: Yup.string().url(CONST.ENTER_VALID_URL),
    looking_to_get_listed: Yup.string(),
    blockchain_network: Yup.string().required(ERROR_CONST.NETWORK),
    other_network: Yup.string().when('blockchain_network', {
      is: (value) => value === 'Other',
      then: Yup.string().required(ERROR_CONST.NETWORK)
    }),
    token_address: Yup.string().required(ERROR_CONST.TOKEN_ADDRESS),
    number_of_active_users: Yup.string().required(ERROR_CONST.NUMBEROFACTIVEUSERS),
    marketing_partners: Yup.string().required('Market partners is required'),
    exchange_launch_marketing_campaign: Yup.string().required(
      'Exchange launch marketing campaign is required'
    ),
    marketing_budget_allocated_for_listing: Yup.string().required(
      'Marketing budget allocated for listing is required'
    ),
    whitepapers_doc: Yup.mixed().test(
      'whitepapers_doc',
      'Please upload whitepapers',
      function (value) {
        const { whitepapers_doc_url } = this.parent;
        return Boolean(value) || Boolean(whitepapers_doc_url);
      }
    ),
    whitepapers_doc_url: Yup.string().test(
      'whitepapers_doc_url',
      'Please provide the whitepapers URL',
      function (value) {
        const { whitepapers_doc } = this.parent;
        return Boolean(value) || Boolean(whitepapers_doc);
      }
    ),
    pitch_decks_doc: Yup.mixed().test(
      'pitch_decks_doc',
      'Please upload latest decks',
      function (value) {
        const { pitch_decks_doc_url } = this.parent;
        return Boolean(value) || Boolean(pitch_decks_doc_url);
      }
    ),
    pitch_decks_doc_url: Yup.string().test(
      'pitch_decks_doc_url',
      'Please provide the latest decks URL',
      function (value) {
        const { pitch_decks_doc } = this.parent;
        return Boolean(value) || Boolean(pitch_decks_doc);
      }
    ),

    listing_program_at_bybit: Yup.array().test(
      'listing_program_at_bybit',
      ERROR_CONST.SELECT_CHECKBOX,
      (value) => {
        return value.some((i) => i.isChecked === true);
      }
    ),
    listing_date: Yup.string().required(ERROR_CONST.LISTING_DATE_REQUIRED),
    listing_price: Yup.string()
      .required(ERROR_CONST.LISTING_PRICE_REQUIRED)
      .min(1, ERROR_CONST.MIN_LISTING_PRICE_REQUIRED),
    interested_in_audit: Yup.boolean(),
    audit_doc: Yup.mixed(),
    token_use_case: Yup.string().required(ERROR_CONST.TOKEN_USE_CASE_REQUIRED),
    tokenomics_doc: Yup.mixed().required(ERROR_CONST.TOKENOMICS_DOC),
    token_distribution_doc: Yup.mixed().required(ERROR_CONST.TOKEN_TICKER_DOC),
    // selectRound: Yup.object().when('dynamicFields', {
    //   is: (value) => value.length === 0,
    //   then: Yup.object().required('Select at least one round.')
    // }),
    tokenPriceStage: Yup.string().when('selectRound', {
      is: (value) => !!value,
      then: Yup.string().required('Stage Total Amount is required')
    }),
    totalAmountStage: Yup.string().when('selectRound', {
      is: (value) => !!value,
      then: Yup.string().required('Stage Token Price is required')
    }),
    // dynamicFields: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required('Name is required'),
    //     amount: Yup.string().required('Amount is required'),
    //     lead: Yup.bool(),
    //     tier: Yup.string()
    //   })
    // ),
    road_map: Yup.string().required(ERROR_CONST.ROADMAP_REQUIRED),
    unique_selling_point: Yup.string().required(ERROR_CONST.ROADMAP_REQUIRED),
    target_geo: Yup.array()
      .min(1, ERROR_CONST.TARGETGEO_REQUIRED)
      .required(ERROR_CONST.TARGETGEO_REQUIRED),
    demo_link: Yup.string().url(CONST.ENTER_VALID_URL),
    video_link: Yup.string().url(CONST.ENTER_VALID_URL)
  });

  useEffect(() => {
    GetApplicationResponseFunc(applicationId);
  }, []);

  const [initialValue, setInitialValue] = useState({
    applicant_first_name: '',
    applicant_last_name: '',
    applicant_email: '',
    applicant_telegram: '',
    project_name: '',
    token_name: '',
    token_ticker: '',
    project_website: '',
    project_description: '',
    twitter: '',
    discord: '',
    linkedin: '',
    facebook: '',
    instagram: '',
    tiktok: '',
    coin_market_cap_url: '',
    coin_gecko_url: '',
    looking_to_get_listed: '',
    blockchain_network: '',
    token_address: '',
    other_network: '',
    number_of_active_users: '',
    existing_exchnage_listed: '',
    market_makers: [],
    ticker_logo: '',
    whitepapers_doc: '',
    pitch_decks_doc: '',
    whitepapers_doc_url: '',
    pitch_decks_doc_url: '',
    marketing_partners: '',
    exchange_launch_marketing_campaign: '',
    marketing_budget_allocated_for_listing: '',
    listing_program_at_bybit: [],
    listing_date: '',
    listing_price: '1',
    interested_in_audit: false,
    token_use_case: '',
    selectRound: '',
    totalAmountStage: '',
    tokenPriceStage: '',
    dynamicFields: [{ name: '', amount: '', lead: false, tier: '' }],
    road_map: '',
    unique_selling_point: '',
    target_geo: [],
    demo_link: '',
    video_link: '',
    extra_information: '',
    team_members: {}
  });

  const handleChangeInvestor = (selected, index, values, setFieldValue) => {
    let arr = { ...values };
    arr.dynamicFields[index].name = selected.label;
    arr.dynamicFields[index].tier = selected.tier;
    setFieldValue(arr);
  };

  const handleTextAmountChange = (index, value, values, setFieldValue) => {
    let arr = { ...values };
    arr.dynamicFields[index].amount =
      '$' + Number(value.replace(/[^0-9]/g, '')).toLocaleString();
    setFieldValue(arr);
  };

  const handleAddInput = (values, setFieldValue) => {
    if (
      values.dynamicFields[values.dynamicFields.length - 1].amount !== '' &&
      values.dynamicFields[values.dynamicFields.length - 1].name !== ''
    ) {
      let arr = { ...values };
      arr.dynamicFields.push({ name: '', amount: '', lead: false, tier: '' });
      setFieldValue(arr);
      const newInput = { name: '', amount: '', lead: false, tier: '' };
      setInputs([...inputs, newInput]);
    }
  };

  const handleLeadChange = (index, value, values, setFieldValue) => {
    let arr = { ...values };
    arr.dynamicFields[index].lead = value;
    setFieldValue(arr);
  };

  const handleRemoveInput = (index, setFieldValue) => {
    if (index === 0) {
      return;
    }
    const newInputs = [...inputs];
    newInputs.splice(index, 1);
    setInputs(newInputs);
    setFieldValue(`dynamicFields`, newInputs);
  };

  if (!applicationId) {
    history.push(`/applications`);
  }

  function formatDateForInput(dateString) {
    const date = new Date(dateString);
    const year = date.getFullYear();
    const month = `${date.getMonth() + 1}`.padStart(2, '0');
    const day = `${date.getDate()}`.padStart(2, '0');
    const hours = `${date.getHours()}`.padStart(2, '0');
    const minutes = `${date.getMinutes()}`.padStart(2, '0');

    return `${year}-${month}-${day}T${hours}:${minutes}`;
  }

  const allRegionOptions = useMemo(() => {
    return allCountries.reduce((acc, [countryName, countryCode, regions]) => {
      const countryRegions = regions.map(([region]) => ({
        country: countryName,
        countryCode,
        region,
        value: region.toLowerCase(),
        label: region
      }));
      return [...acc, ...countryRegions];
    }, []);
  }, []);
  const suggestionsRegion = [...allRegionOptions, ...regions].map(({ value, label }) => ({
    id: value,
    name: label
  }));

  const generateInitialValues = (teamMembers) => {
    const temaMemberArr = [];
    for (const member of teamMembers) {
      const teamInitialValues = {};
      teamInitialValues[`first_name`] = member.first_name || '';
      teamInitialValues[`last_name`] = member.last_name || '';
      teamInitialValues[`email`] = member.email || '';
      teamInitialValues[`linkedin`] = member.linkedin || '';
      teamInitialValues[`position`] = member.position || '';
      teamInitialValues[`start_date`] =
        member &&
        member.previous_experience &&
        member.previous_experience.length > 0 &&
        member.previous_experience[0].start_date
          ? moment(member.previous_experience[0].start_date).format('YYYY-MM-DD')
          : '';

      teamInitialValues[`end_date`] =
        member &&
        member.previous_experience &&
        member.previous_experience.length > 0 &&
        member.previous_experience[0].end_date
          ? moment(member.previous_experience[0].end_date).format('YYYY-MM-DD')
          : '';

      temaMemberArr.push(teamInitialValues);
    }
    // teamMembers.forEach((member, index) => {
    //   teamInitialValues[`first_name_${index}`] = member.first_name || '';
    //   teamInitialValues[`last_name_${index}`] = member.last_name || '';
    //   teamInitialValues[`email_${index}`] = member.email || '';
    //   teamInitialValues[`linkedin_${index}`] = member.linkedin || '';
    //   teamInitialValues[`position_${index}`] = member.position || '';
    //   teamInitialValues[`start_date_${index}`] =
    //     member && member.previous_experience
    //       ? moment(member.previous_experience[0].start_date).format('YYYY-MM-DD')
    //       : '';

    //   teamInitialValues[`end_date_${index}`] =
    //     member && member.previous_experience
    //       ? moment(member.previous_experience[0].end_date).format('YYYY-MM-DD')
    //       : '';
    // });
    return temaMemberArr;
  };
  const setInitialValuesFunc = (applicationData) => {
    let updatedList = checkBoxesData.map((program) => {
      if (applicationData.listing_program.includes(program.value)) {
        return { ...program, isChecked: true };
      } else {
        return program;
      }
    });
    let teamInitialValues = generateInitialValues(applicationData?.team_members);
    setInitialValue({
      applicant_first_name: applicationData?.applicant_first_name || '',
      applicant_last_name: applicationData?.applicant_last_name || '',
      applicant_email: applicationData.applicant_email,
      applicant_telegram: applicationData.applicant_telegram,
      project_name: applicationData?.project_name || '',
      token_name: applicationData?.token_name || '',
      project_website: applicationData?.project_website || '',
      project_description: applicationData?.project_description || '',
      coin_market_cap_url: applicationData?.coin_market_cap_url || '',
      coin_gecko_url: applicationData?.coin_gecko_url || '',
      looking_to_get_listed: applicationData?.looking_to_get_listed || '',
      blockchain_network: applicationData?.blockchain_network || '',
      token_address: applicationData?.token_address || '',
      other_network: applicationData.other_network,
      number_of_active_users: Number(
        applicationData.number_of_active_users + ''.replace(/[^0-9]/g, '')
      ).toLocaleString(),
      marketing_partners: applicationData.marketing_partners,
      exchange_launch_marketing_campaign:
        applicationData.exchange_launch_marketing_campaign,
      marketing_budget_allocated_for_listing:
        applicationData.marketing_budget_allocated_for_listing,
      listing_date: !!applicationData.listing_date
        ? formatDateForInput(applicationData.listing_date)
        : '',
      listing_price: applicationData?.listing_price || '',
      interested_in_audit: applicationData?.interested_in_audit || false,
      selectRound: applicationData?.selectRound || '',
      totalAmountStage: applicationData?.totalAmountStage || '',
      tokenPriceStage: applicationData?.tokenPriceStage || '',
      dynamicFields: applicationData?.investment_stages || [
        { name: '', amount: '', lead: false, tier: '' }
      ],
      twitter: applicationData?.social_media[0]?.twitter || '',
      discord: applicationData?.social_media[0]?.discord || '',
      linkedin: applicationData?.social_media[0]?.linkedin || '',
      facebook: applicationData?.social_media[0]?.facebook || '',
      instagram: applicationData?.social_media[0]?.instagram || '',
      tiktok: applicationData?.social_media[0]?.tiktok || '',
      token_use_case: applicationData.token_use_case,
      road_map: applicationData.road_map,
      unique_selling_point: applicationData.unique_selling_point,
      target_geo: suggestionsRegion.filter(({ name }) =>
        applicationData.target_geo.includes(name)
      ),
      demo_link: applicationData?.demo_link || '',
      video_link: applicationData?.video_link || '',
      extra_information: applicationData?.extra_information || '',
      listing_program_at_bybit: updatedList,
      ticker_logo: applicationData.ticker_logo,
      whitepapers_doc: applicationData.whitepapers_doc,
      pitch_decks_doc: applicationData.pitch_decks_doc,
      whitepapers_doc_url: applicationData.whitepapers_doc_url || '',
      pitch_decks_doc_url: applicationData.pitch_decks_doc_url || '',
      audit_doc: applicationData.audit_doc,
      tokenomics_doc: applicationData.tokenomics_doc,
      token_distribution_doc: applicationData.token_distribution_doc,
      market_makers: applicationData.market_makers || [],
      team_members: teamInitialValues
    });
    setSelectnetworkList(applicationData.blockchain_network);
    applicationData.social_media.forEach((social_media) => {
      setInitialValue((prevValues) => ({
        ...prevValues,
        [social_media.name]: social_media.account_id
      }));
    });
    setFileUrls({
      ticker_logo: applicationData.ticker_logo,
      whitepapers_doc: applicationData.whitepapers_doc,
      pitch_decks_doc: applicationData.pitch_decks_doc,
      audit_doc: applicationData.audit_doc,
      tokenomics_doc: applicationData.tokenomics_doc,
      token_distribution_doc: applicationData.token_distribution_doc
    });
  };

  useEffect(() => {
    let applicationData;
    if (applicationId !== '') {
      GetApplication({ application_id: applicationId })
        .then((res) => {
          const application = get(res, 'data.data.data[0]', '');
          setApplicationAll(application);
          applicationData = application.exchange_application;
          setApplicationStatus(application.application_status);
          setInitialValuesFunc(applicationData);
          exchangeListApiFunction(applicationData);
          setApplicationDetails(applicationData);
          setSelected(applicationData.market_makers);
          setShowRound(true);
          setDisplayinvestorListData(applicationData.investment_stages);
          setIsLoaded(true);
        })
        .catch((error) => {
          setIsLoaded(true);
          const errorMessage = get(error, 'data.error.message');
          notifyError(notificationMessageFunction(errorMessage));
        });
    }

    GetAllNetwork()
      .then((res) => {
        const GetData = get(res, 'data.data.data', []);
        const setFinalData = GetData.map((item) => ({
          label: item.network_symbol,
          value: item.network_symbol
        }));
        setFinalData.push({ label: 'Other', value: 'Other' });
        setAllNetworks(setFinalData);
      })
      .catch((err) => {
        const errorMessage = get(err, 'data.error.message');

        if (errorMessage !== undefined)
          notifyError(notificationMessageFunction(errorMessage));
      });
    exchangeListApiFunction();

    investorsListAPI()
      .then((res) => {
        let list = [];
        res.data.data.data.map((e, i) => {
          let obj = {
            label: e.name,
            value: e.name,
            tier: e.tier
          };
          list.push(obj);
        });
        setInvestorsList(list);
      })
      .catch((err) => {});
  }, []);

  const handleChangeOpt = (selectedOption, setFieldValue) => {
    setFieldValue('selectRound', selectedOption);
    setselectedRound(selectedOption.value);
    setDisplayinvestorData(true);
  };

  const exchangeListApiFunction = (applicationDetails) => {
    ExchangeListAPI()
      .then((res) => {
        const GetExchangeData = get(res, 'data.data.data', []);
        setAllExchangeList(GetExchangeData);
        const newData = res.data.data.data.map((item) => {
          return { label: item.exchange_name, value: item.exchange_name };
        });
        setExchangeList(newData);
        if (applicationDetails && applicationDetails.existing_exchanges_listed) {
          const selectedList = applicationDetails.existing_exchanges_listed.map(
            (exchange) => ({
              label: exchange.exchange_name,
              value: exchange.exchange_name
            })
          );
          setSelectedOptions(selectedList);
          const filteredData = GetExchangeData.filter((item) =>
            selectedList.some((dataItem) => dataItem.label === item.exchange_name)
          );
          const updatedArrayList2 = filteredData.map((items) => {
            const matchedItem = applicationDetails.existing_exchanges_listed.find(
              (item) =>
                item.exchange_name.toLowerCase() === items.exchange_name.toLowerCase()
            );

            if (matchedItem) {
              return {
                ...items,
                hour24_trading_volume: matchedItem.hour24_trading_volume
              };
            }
            return items;
          });
          setFilterExchangeList(updatedArrayList2);
        }
      })
      .catch((err) => {
        const errorMessage = err && err.data && err.data.error && err.data.error.message;

        if (errorMessage !== undefined)
          notifyError(notificationMessageFunction(errorMessage));
      });
  };

  const changeHandlerExchange = (selectValues) => {
    const filteredData = allExchangeList.filter((item) =>
      selectValues.some((dataItem) => dataItem.label === item.exchange_name)
    );
    const updatedArrayList2 = filteredData.map((items) => {
      const matchedItem = applicationDetails.existing_exchanges_listed.find(
        (item) => item.exchange_name.toLowerCase() === items.exchange_name.toLowerCase()
      );

      if (matchedItem) {
        return {
          ...items,
          hour24_trading_volume: matchedItem.hour24_trading_volume
        };
      }
      return items;
    });
    setSelectedOptions(selectValues);
    setFilterExchangeList([...updatedArrayList2]);
  };

  const handleSubmitRound = (values, setFieldValue) => {
    let val = values.dynamicFields[values.dynamicFields.length - 1];
    if (val.name !== '' && val.amount !== '') {
      let RoundData = {
        round: selectedRound,
        token_price: values.tokenPriceStage,
        token_size: values.totalAmountStage,
        all_investors: values.dynamicFields
      };
      setShowRound(true);
      setDisplayinvestorListData([...displayinvestorListData, RoundData]);
      setselectedRound('');
      let arr = { ...values };
      arr.dynamicFields = [{ name: '', amount: '', lead: false, tier: '' }];
      arr.tokenPriceStage = '';
      arr.totalAmountStage = '';
      setFieldValue(arr);
      setDisplayinvestorData(false);
    }
  };

  const handleSubmitApp = (values, isSubmit = false) => {
    if (shouldSubmit || isSubmit) {
      setIsLoading(true);
      const exchangeTradEmpty = filterExchangeList.filter(
        (item) => item.error || !item.hour24_trading_volume
      );
      if (exchangeTradEmpty.length > 0)
        exchangeTradEmpty.forEach((i) => (i.error = true));
      if (exchangeTradEmpty.length > 0) return;

      const formCompleteData = filterExchangeList.map((item) => {
        const inputElement = document.getElementsByName(item.exchange_name)[0];
        const volume = inputElement.value;
        return {
          exchange_name: item.exchange_name,
          hour24_trading_volume: volume
        };
      });

      const stringWithoutCommas = values.number_of_active_users.split(',').join('');
      const numberOfActiveUsers = parseInt(stringWithoutCommas, 10);

      const payloadData = new FormData();
      payloadData.append(
        'applicant_first_name',
        values.applicant_first_name.toLowerCase()
      );
      payloadData.append('applicant_last_name', values.applicant_last_name);
      payloadData.append('applicant_email', values.applicant_email);
      payloadData.append('applicant_telegram', values.applicant_telegram);
      payloadData.append('project_name', values.project_name);
      payloadData.append('token_name', values.token_name);
      payloadData.append('project_website', values.project_website);
      payloadData.append('project_description', values.project_description);
      payloadData.append('coin_market_cap_url', values.coin_market_cap_url);
      payloadData.append('coin_gecko_url', values.coin_gecko_url);
      payloadData.append('looking_to_get_listed', values.looking_to_get_listed);
      payloadData.append('blockchain_network', selectNetworkList);
      payloadData.append('token_address', values.token_address);
      payloadData.append('other_network', values.other_network);
      payloadData.append('audit_doc', values.audit_doc);
      payloadData.append('whitepapers_doc', values.whitepapers_doc);
      payloadData.append('pitch_decks_doc', values.pitch_decks_doc);
      payloadData.append('ticker_logo', values.ticker_logo);
      payloadData.append('number_of_active_users', Number(numberOfActiveUsers));
      payloadData.append('existing_exchanges_listed', JSON.stringify(formCompleteData));
      payloadData.append('market_makers', JSON.stringify(selected));
      payloadData.append('marketing_partners', values.marketing_partners);
      payloadData.append(
        'exchange_launch_marketing_campaign',
        values.exchange_launch_marketing_campaign
      );
      payloadData.append(
        'marketing_budget_allocated_for_listing',
        values.marketing_budget_allocated_for_listing
      );
      payloadData.append(
        'listing_program',
        JSON.stringify(
          values.listing_program_at_bybit
            .filter((list) => list.isChecked === true && list.value !== 'all_programs')
            .map((list) => list.value)
        )
      );
      payloadData.append('listing_price', values.listing_price);
      payloadData.append(
        'social_media',
        JSON.stringify([
          { name: 'twitter', account_id: values.twitter },
          { name: 'discord', account_id: values.discord },
          { name: 'tiktok', account_id: values.tiktok },
          { name: 'linkedin', account_id: values.linkedin },
          { name: 'facebook', account_id: values.facebook },
          { name: 'instagram', account_id: values.instagram }
        ])
      );
      payloadData.append('listing_date', values.listing_date);
      payloadData.append('interested_in_audit', values.interested_in_audit);
      payloadData.append('token_use_case', values.token_use_case);
      payloadData.append('tokenomics_doc', values.tokenomics_doc);
      payloadData.append('token_distribution_doc', values.token_distribution_doc);
      payloadData.append('investment_stages', JSON.stringify(displayinvestorListData));
      payloadData.append('road_map', values.road_map);
      payloadData.append('unique_selling_point', values.unique_selling_point);
      payloadData.append(
        'target_geo',
        JSON.stringify(values.target_geo.map((tag) => tag.name))
      );
      payloadData.append('demo_link', values.demo_link);
      payloadData.append('video_link', values.video_link);
      payloadData.append('extra_information', values.extra_information);
      payloadData.append('application_status', 'COMPLETE');

      Object.keys(fileUrls).forEach((key) => {
        payloadData.append(key, fileUrls[key]);
      });
      setIsLoading(true);

      if (applicationId && applicationId !== '') {
        UpdateExchangeApplication({ application_id: applicationId }, payloadData)
          .then((res) => {
            setIsLoading(false);

            //update success
            notifySuccess('Application updated Successfully');
            setShouldSubmit(false);
            // setInterval(() => {
            //   window.location.reload();
            // }, 5000);
          })
          .catch((err) => {
            const errorMessage = get(err, 'data.error.message');
            notifyError(notificationMessageFunction(errorMessage));
          });
      }
    }
  };

  const stepsContent = [
    'Application Background',
    'Project Background',
    'Team Members',
    'Social Media',
    'Coin Marketcap',
    'Chains',
    'Active Users',
    'Trading Volume',
    'Market Makers',
    'Influencers or Marketing Partners',
    'Promotional Strategies',
    'Exchange Marketing Budget',
    'Project Docs',
    'Programs of Interest',
    'Listing Date',
    'Listing Price',
    'Auditing report',
    'Tokenomics',
    'Investment History',
    'Roadmap',
    'Project Uniqueness',
    'TargetGeo',
    'Demo',
    'Finish'
  ];
  const sectionRefs = useRef([]);

  const [openedSection, setOpenedSection] = useState(null);

  const toggleSection = (index) => {
    if (openedSection === index) {
      setOpenedSection(null);
    } else {
      setOpenedSection(index);
    }
  };
  const handleTagsChange = (newTags) => {
    setInitialValue({ ...initialValue, market_makers: newTags });
  };
  return (
    <React.Fragment>
      <Row>
        <Col lg={3} md={3} sm={12} className='pt-4'>
          <div
            className='card p-3 ml-2 mr-2'
            style={{
              border: `1px solid rgb(128 128 128 / 30%)`,
              cursor: 'pointer'
            }}>
            <SimpleBar>
              <VerticalStepperSection
                steps={stepsContent}
                currentStep={step}
                completedSteps={exchangeCompleteStep}
                onSelectStep={setStep}
                sectionRefs={sectionRefs}
                toggleSection={toggleSection}
              />
            </SimpleBar>
          </div>
        </Col>
        <Col lg={9} md={9} sm={12}>
          <div className='pt-4 pr-4'>
            {!isLoaded ? (
              <div className='h-100 d-flex align-items-center justify-content-center py-4'>
                <i
                  className='fas fa-circle-notch fa-spin text-muted'
                  style={{ fontSize: 48 }}></i>
              </div>
            ) : (
              <Container className=''>
                <div>
                  <Formik
                    enableReinitialize={true}
                    validateOnBlur={false}
                    validateOnChange={false}
                    initialValues={initialValue}
                    validationSchema={ExchangeFormSchema}
                    onSubmit={handleSubmitApp}>
                    {({
                      errors,
                      touched,
                      values,
                      handleChange,
                      setFieldValue,
                      setFieldTouched,
                      setFieldError,
                      setErrors
                    }) => (
                      <Form>
                        <div ref={(el) => (sectionRefs.current[0] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={ApplicantBackgroundInputNames.map((i) => i.name)}
                            title={'Applicant Background'}
                            filed={ApplicantBackgroundInputNames}
                            isSectionCollapsed={openedSection !== 0}
                            toggleSection={() => toggleSection(0)}
                          />
                        </div>

                        <div ref={(el) => (sectionRefs.current[1] = el)}>
                          <ProjectBackgroundApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            setInitialValuesFunc={setInitialValuesFunc}
                            applicationId={applicationId}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            fileUrls={fileUrls}
                            isSectionCollapsed={openedSection !== 1}
                            toggleSection={() => toggleSection(1)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[2] = el)}>
                          <TeamMembers
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            setInitialValuesFunc={setInitialValuesFunc}
                            applicationId={applicationId}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            fileUrls={fileUrls}
                            isSectionCollapsed={openedSection !== 2}
                            toggleSection={() => toggleSection(2)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[3] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={LinkOfSocialMediasFollowersInputNames.map(
                              (i) => i.name
                            )}
                            title={'Link of Social Medias + No. of Followers'}
                            filed={LinkOfSocialMediasFollowersInputNames}
                            isSectionCollapsed={openedSection !== 3}
                            toggleSection={() => toggleSection(3)}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[4] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={LinkToCoinMarketCapCoinGeckoInputName.map(
                              (i) => i.name
                            )}
                            title={'Link to CoinMarketCap/CoinGecko'}
                            filed={LinkToCoinMarketCapCoinGeckoInputName}
                            isSectionCollapsed={openedSection !== 4}
                            toggleSection={() => toggleSection(4)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[5] = el)}>
                          <ChainSupport
                            allResponses={allResponses}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            allNetworks={allNetworks}
                            setSelectnetworkList={setSelectnetworkList}
                            setFieldValue={setFieldValue}
                            selectNetworkList={selectNetworkList}
                            isSectionCollapsed={openedSection !== 5}
                            toggleSection={() => toggleSection(5)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[6] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={NumberOfActiveProductInputName}
                            title={'Number of Active Users in the Product'}
                            name={'number_of_active_users'}
                            label={CONST.NUMBEROFACTIVEUSERS}
                            placeholder={`${CONST.ENTER_NUMBER_OF_ACTIVE_USERS}`}
                            type='text'
                            isSectionCollapsed={openedSection !== 6}
                            toggleSection={() => toggleSection(6)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[7] = el)}>
                          <ExistingExchangesListApp
                            allResponses={allResponses}
                            errors={errors}
                            exchangeList={exchangeList}
                            filterExchangeList={filterExchangeList}
                            isSectionCollapsed={openedSection !== 7}
                            toggleSection={() => toggleSection(7)}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            setFilterExchangeList={setFilterExchangeList}
                            changeHandlerExchange={changeHandlerExchange}
                            selectedOptions={selectedOptions}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[8] = el)}>
                          <MarketMakersApp
                            allResponses={allResponses}
                            touched={touched}
                            errors={errors}
                            values={values}
                            isSectionCollapsed={openedSection !== 8}
                            toggleSection={() => toggleSection(8)}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            handleTagsChange={handleTagsChange}
                            handleChange={handleChange}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[9] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={ProjectInfluencersInputName}
                            title={
                              'Major Project Influencers and Marketing Partners for Project Promotion'
                            }
                            name={'marketing_partners'}
                            label={CONST.MARKETINGPARTNERS}
                            isFullSize={true}
                            isSectionCollapsed={openedSection !== 9}
                            toggleSection={() => toggleSection(9)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[10] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={ExchangeLaunchMarketingCampaignInputName}
                            title={
                              'Strategies for Driving User Adoption After Listing a Token on an Exchange'
                            }
                            name={'exchange_launch_marketing_campaign'}
                            label={`${CONST.EXCHANGELAUNCHMARKETINGCAMPAIGN}`}
                            type='textarea'
                            isFullSize={true}
                            isSectionCollapsed={openedSection !== 10}
                            toggleSection={() => toggleSection(10)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[11] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            type='text'
                            setFieldTouched={setFieldTouched}
                            inputName={MarketingBudgetInputName}
                            title={
                              'Optimizing Listing Success: Allocating an Effective Exchange Marketing Budget'
                            }
                            name={'marketing_budget_allocated_for_listing'}
                            label={`${CONST.MARKETINGBUDGETALLOCATEDFORLISTING}`}
                            isFullSize={true}
                            isSectionCollapsed={openedSection !== 11}
                            toggleSection={() => toggleSection(11)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[12] = el)}>
                          <ProjectsWhitepaperAndPitchDeckApp
                            allResponses={allResponses}
                            values={values}
                            fileUrls={fileUrls}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            isSectionCollapsed={openedSection !== 12}
                            toggleSection={() => toggleSection(12)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[13] = el)}>
                          <ListingProgramApp
                            allResponses={allResponses}
                            errors={errors}
                            values={values}
                            initialValue={initialValue}
                            setFieldValue={setFieldValue}
                            handleChange={handleChange}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            isSectionCollapsed={openedSection !== 13}
                            toggleSection={() => toggleSection(13)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[14] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={ListingDateInputName}
                            title={'Estimated Listing Date at Exchange'}
                            name={'listing_date'}
                            label={`${CONST.LISTING_DATE}`}
                            type='datetime-local'
                            placeholder=''
                            isSectionCollapsed={openedSection !== 14}
                            toggleSection={() => toggleSection(14)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[15] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={PotentialListingInputName}
                            title={'Potential Listing Price at Exchange'}
                            name={'listing_price'}
                            label={`${CONST.LISTING_PRICE}`}
                            type='text'
                            placeholder={CONST.LISTING_PRICE}
                            isSectionCollapsed={openedSection !== 15}
                            toggleSection={() => toggleSection(15)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[16] = el)}>
                          <AuditingReportApp
                            allResponses={allResponses}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            values={values}
                            fileUrls={fileUrls}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            isSectionCollapsed={openedSection !== 16}
                            toggleSection={() => toggleSection(16)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[17] = el)}>
                          <TokenomicsApp
                            allResponses={allResponses}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            values={values}
                            fileUrls={fileUrls}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            setFieldTouched={setFieldTouched}
                            handleChange={handleChange}
                            isSectionCollapsed={openedSection !== 17}
                            toggleSection={() => toggleSection(17)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[18] = el)}>
                          <InvestorHistoryApp
                            allResponses={allResponses}
                            handleChange={handleChange}
                            applicationId={applicationId}
                            touched={touched}
                            errors={errors}
                            handleTextAmountChange={handleTextAmountChange}
                            values={values}
                            handleChangeInvestor={handleChangeInvestor}
                            handleAddInput={handleAddInput}
                            handleRemoveInput={handleRemoveInput}
                            handleSubmitRound={handleSubmitRound}
                            handleLeadChange={handleLeadChange}
                            handleChangeOpt={handleChangeOpt}
                            displayinvestorData={displayinvestorData}
                            setDisplayinvestorData={setDisplayinvestorData}
                            setFieldValue={setFieldValue}
                            setTokenSize={setTokenSize}
                            setTokenPrice={setTokenPrice}
                            allInventoryOption={allInventoryOption}
                            investorsList={investorsList}
                            setInvestorsList={setInvestorsList}
                            inputs={inputs}
                            displayinvestorListData={displayinvestorListData}
                            setDisplayinvestorListData={setDisplayinvestorListData}
                            setShowRound={setShowRound}
                            showRound={showRound}
                            setselectedRound={setselectedRound}
                            isSectionCollapsed={openedSection !== 18}
                            setInitialValuesFunc={setInitialValuesFunc}
                            toggleSection={() => toggleSection(18)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[19] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={RoadMapInputName}
                            title={'Road Map and Upcoming Events/Updates'}
                            name={'road_map'}
                            label={`${CONST.ROADMAP}`}
                            placeholder={CONST.ENTER_DESCRIPTION}
                            isFullSize={true}
                            isSectionCollapsed={openedSection !== 19}
                            toggleSection={() => toggleSection(19)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[20] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={UniqueSellingPointInputName}
                            title={'What sets your project apart from competitors?'}
                            name={'unique_selling_point'}
                            label={`${CONST.UNIQUESELLINGPOINT}`}
                            isFullSize={true}
                            isSectionCollapsed={openedSection !== 20}
                            toggleSection={() => toggleSection(20)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[21] = el)}>
                          <TargetGeoApp
                            allResponses={allResponses}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            setFieldValue={setFieldValue}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={TargetGeoInputName}
                            title={'Primary focal areas or principal user base regions'}
                            name={'target_geo'}
                            suggestionsRegion={suggestionsRegion}
                            label={`${CONST.TARGETGEO}`}
                            isSectionCollapsed={openedSection !== 21}
                            toggleSection={() => toggleSection(21)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[22] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={DemoVideoLinkInputName.map((i) => i.name)}
                            title={
                              'If the product is not live yet, could you kindly provide a link to a demo?'
                            }
                            filed={DemoVideoLinkInputName}
                            placeholder={CONST.ENTER_URL_PLACEHOLDER}
                            isSectionCollapsed={openedSection !== 22}
                            toggleSection={() => toggleSection(22)}
                          />
                        </div>
                        <div ref={(el) => (sectionRefs.current[23] = el)}>
                          <TextAreaExchangeApplication
                            allResponses={allResponses}
                            setFieldValue={setFieldValue}
                            applicationId={applicationId}
                            setInitialValuesFunc={setInitialValuesFunc}
                            touched={touched}
                            errors={errors}
                            handleChange={handleChange}
                            values={values}
                            setFieldTouched={setFieldTouched}
                            inputName={ExtraInformationInputName}
                            title={
                              'Additional significant details that could assist us in assessing the project'
                            }
                            name={'extra_information'}
                            label={`${CONST.EXTRA_INFORMATION}(${CONST.OPTIONAL})`}
                            isFullSize={true}
                            isSectionCollapsed={openedSection !== 23}
                            toggleSection={() => toggleSection(23)}
                          />
                        </div>
                        {isLoaded && (
                          <div className='d-flex justify-content-end mb-4'>
                            <button
                              className='btn btn-auth'
                              type='submit'
                              style={{ width: 150, height: 40 }}
                              onSubmit={(values) => {
                                setShouldSubmit(true);
                                handleSubmitApp(values, true);
                              }}>
                              {isLoading ? 'Loading...' : 'Update'}
                            </button>
                          </div>
                        )}
                      </Form>
                    )}
                  </Formik>
                </div>
              </Container>
            )}
          </div>
        </Col>
      </Row>

      <NotificationToastContainer />
    </React.Fragment>
  );
}

export default ViewExchangeApplication;
