import axios from 'axios';
import api from '../../api/ramp2Api';
import * as onRampURL from '../../api/ramp2URL';

export const GetAllApplications = (params, paramsData) => {
  return new Promise(async (resolve, reject) => {
    const query =
      '?' +
      (paramsData
        ? (paramsData.page ? `page=${paramsData.page}` : '') +
          (paramsData.order ? `&order=${paramsData.order}` : '') +
          (paramsData.search ? `&search=${paramsData.search}` : '') +
          (paramsData.limit ? `&limit=${paramsData.limit}` : '') +
          (paramsData.tags
            ? `&investor_tier=${paramsData.tags.map((item) => item).join(',')}`
            : '') +
          (paramsData.status
            ? `&appStatus=${paramsData.status.map((item) => item).join(',')}`
            : '') +
          (paramsData.priority
            ? `&priority=${paramsData.priority.map((item) => item).join(',')}`
            : '') +
          Object.keys(params)
            .map((key) => `&${key}=${params[key]}`)
            .join('&')
        : '');
    return api
      .getWithToken(onRampURL.ALL_APPLICATION + query)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetApplication = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(onRampURL.ONRAMP_APPLICATION + '?application_id=' + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ProcessApplication = (id, payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(onRampURL.PROCESS_APPLICATION + '?application_id=' + id, payload)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const MassProcessApplication = (payload) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(onRampURL.MASS_PROCESS_APPLICATION, payload)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const ChangeApplicationStatus = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(onRampURL.CHANGE_STATUS + '?sub_application_id=' + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetApplicationResponse = ({ applicationId, page, limit, order, status }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(
        onRampURL.ON_RAMP_APPLICATION_RESPONSE +
          '?sub_application_id=' +
          applicationId +
          (page ? `&page=${page}` : '') +
          (limit ? `&limit=${limit}` : '') +
          (order ? `&order=${order}` : '') +
          (status ? `&status=${status.map((item) => item).join(',')}` : '')
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const DeleteApplicationResponse = ({ onramp_response_id }) => {
  return new Promise(async (resolve, reject) => {
    return api
      .deleteWithToken(
        onRampURL.ON_RAMP_APPLICATION_RESPONSE +
          '?onramp_response_id=' +
          onramp_response_id
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const setAddQuestions = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(onRampURL.ADD_QUESTIONS + '?sub_application_id=' + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetRequestCurrencyList = () => {
  return new Promise(async (resolve, reject) => {
    return axios
      .get(`https://api.request.finance/currency/list/invoicing`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const GetGoPlusChainsList = () => {
  return new Promise(async (resolve, reject) => {
    return axios
      .get(`https://api.gopluslabs.io/api/v1/supported_chains`)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getTokenAnalytics = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(onRampURL.TOKEN_ANALYTICS + '?sub_application_id=' + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getTokenMarketData = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(onRampURL.TOKEN_ANALYTICS + '/price?sub_application_id=' + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getCoinGeckoTerminalNetworks = (id) => {
  return new Promise(async (resolve, reject) => {
    return api
      .getWithToken(onRampURL.TOKEN_ANALYTICS + '/networks?sub_application_id=' + id)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getCoinGeckoTerminalPools = (id, network, token_address) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(onRampURL.TOKEN_ANALYTICS + '/pools?sub_application_id=' + id, {
        network,
        token_address
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getCoinGeckoTerminalToken = (id, network, token_address) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(onRampURL.TOKEN_ANALYTICS + '/token?sub_application_id=' + id, {
        network,
        token_address
      })
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getTeamAnalytics = (id, humans) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(
        onRampURL.TOKEN_ANALYTICS + '/team_analytics?sub_application_id=' + id,
        {
          humans
        }
      )
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};

export const getTwitterScore = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return api
      .postWithToken(onRampURL.TWITTER_ANALYTICS + '?sub_application_id=' + id, data)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        console.log('err', err);
        reject(err.response);
      });
  });
};

export const getTwitterExistance = (id, data) => {
  return new Promise(async (resolve, reject) => {
    return axios
      .get('https://twitter.com/users/username_available?username=' + data.twitterHandle)
      .then((response) => {
        if (response) {
          resolve(response);
        }
      })
      .catch((err) => {
        reject(err.response);
      });
  });
};
