import React, { useEffect, useState } from 'react';
import { useQueryParam, StringParam } from 'use-query-params';
import { providersEnum as PROVIDER_ENUM } from '../../constants/providers';

import BuySell from './BuySell';
import AlchemyPayWidget from './Widgets/AlchemyPayWidget';
import MoonpayWidget from './Widgets/MoonpayWidget';
import TransakWidget from './Widgets/TransakWidget';
import XanpoolWidget from './Widgets/XanpoolWidget';
import GateFiWidget from './Widgets/GateFiWidget';
import MunzenWidget from './Widgets/MunzenWidget';
import SwipeluxWidget from './Widgets/SwipeluxWidget';
import MercuryoWidget from './Widgets/MercuryoWidget';
import RampWidget from './Widgets/RampWidget';

function BuySellWidget(props) {
  useEffect(() => {
    document.body.classList.add('bg-transparent');
    document.body.style.overflow = 'hidden';
  }, [props.location.search]);
  const [buyData, setBuyData] = useState({
    cryptoCurrency: 'BCH',
    network: 'BSC',
    fiat_currency: 'USD',
    payment_method: '',
    fiat_value: 1000
  });
  const [showMoonpayWidget, setShowMoonpayWidget] = useState(false);
  const [showXanpoolWidget, setShowXanpoolWidget] = useState(false);
  const [showTransakWidget, setShowTransakWidget] = useState(false);
  const [showAlchemyPayWidget, setShowAlchemyPayWidget] = useState(false);
  const [showRampWidget, setShowRampWidget] = useState(false);
  const [showGateFiWidget, setShowGateFiWidget] = useState(false);
  const [showMunzenWidget, setShowMunzenWidget] = useState(false);
  const [showSwipeluxWidget, setShowSwipeluxWidget] = useState(false);
  const [showMercuryoWidget, setShowMercuryoWidget] = useState(false);
  const [defaultCoinId] = useQueryParam('crypto', StringParam);
  const [compareMode] = useState('fiat-crypto');
  const getSelectedData = (data) => {
    let paymentMethod = '';
    let network = '';
    let cryptoCurrency = '';

    if (data?.selectedRate?.provider?.provider === PROVIDER_ENUM.MOONPAY) {
      paymentMethod = data?.selectedRate?.payment_method_common_name;
      network = data?.coin[PROVIDER_ENUM.MOONPAY].network;
      cryptoCurrency = data?.coin.crypto_symbol;
    }
    if (data?.selectedRate?.provider?.provider === PROVIDER_ENUM.XANPOOL) {
      paymentMethod = data?.selectedRate?.payment_method_object.payment_method_id;
      network = data?.coin[PROVIDER_ENUM.XANPOOL].network;
      cryptoCurrency = data?.coin[PROVIDER_ENUM.XANPOOL].crypto_name;
    }
    if (data?.selectedRate?.provider?.provider === PROVIDER_ENUM.ALCHEMY) {
      paymentMethod = data?.selectedRate?.payment_method_object.payment_method_id;
      network = data?.coin[PROVIDER_ENUM.ALCHEMY].network;
      cryptoCurrency = data?.coin[PROVIDER_ENUM.ALCHEMY].crypto_name;
    }
    if (data?.selectedRate?.provider?.provider === PROVIDER_ENUM.TRANSAK) {
      paymentMethod = data?.selectedRate?.payment_method_object.payment_method_id;
      network = data?.coin[PROVIDER_ENUM.TRANSAK].network;
      cryptoCurrency = data?.coin[PROVIDER_ENUM.TRANSAK].crypto_name;
    }
    if (data?.selectedRate?.provider?.provider === PROVIDER_ENUM.RAMP) {
      paymentMethod = data?.selectedRate?.payment_method_object.payment_method_id;
      network = data?.coin[PROVIDER_ENUM.RAMP].network;
      cryptoCurrency = data?.coin[PROVIDER_ENUM.RAMP].cryptoAssetSymbol;
    }
    if (data?.selectedRate?.provider?.provider === PROVIDER_ENUM.UNLIMIT) {
      paymentMethod = data?.selectedRate?.payment_method_object.payment_method_id;
      network = data?.coin[PROVIDER_ENUM.UNLIMIT].network;
      cryptoCurrency = data?.coin[PROVIDER_ENUM.UNLIMIT].crypto_name;
    }
    if (data?.selectedRate?.provider?.provider === PROVIDER_ENUM.MUNZEN) {
      paymentMethod = data?.selectedRate?.payment_method_object.payment_method_id;
      network = data?.coin[PROVIDER_ENUM.MUNZEN].network;
      cryptoCurrency = data?.coin[PROVIDER_ENUM.MUNZEN].crypto_name;
    }
    if (data?.selectedRate?.provider?.provider === PROVIDER_ENUM.SWIPELUX) {
      paymentMethod = data?.selectedRate?.payment_method_object.payment_method_id;
      network = data?.coin[PROVIDER_ENUM.SWIPELUX].network;
      cryptoCurrency = data?.coin[PROVIDER_ENUM.SWIPELUX].crypto_name;
    }
    if (data?.selectedRate?.provider?.provider === PROVIDER_ENUM.MERCURYO) {
      paymentMethod = data?.selectedRate?.payment_method_object.payment_method_id;
      network = data?.coin[PROVIDER_ENUM.MERCURYO].network;
      cryptoCurrency = data?.coin[PROVIDER_ENUM.MERCURYO].crypto_name;
    }
    setBuyData({
      cryptoCurrency: cryptoCurrency,
      fiat_currency: data.fiat.currency,
      payment_method: paymentMethod,
      network,
      fiat_value: data.amountPay
    });
  };

  const backToWidget = () => {
    setShowMoonpayWidget(false);
    setShowXanpoolWidget(false);
    setShowRampWidget(false);
    setShowTransakWidget(false);
    setShowAlchemyPayWidget(false);
    setShowGateFiWidget(false);
    setShowMunzenWidget(false);
    setShowSwipeluxWidget(false);
    setShowMercuryoWidget(false);
  };
  return (
    <React.Fragment>
      <div className='p-3'>
        <div className=''>
          {!showMoonpayWidget &&
            !showXanpoolWidget &&
            !showTransakWidget &&
            !showAlchemyPayWidget &&
            !showGateFiWidget &&
            !showMunzenWidget &&
            !showSwipeluxWidget &&
            !showRampWidget &&
            !showMercuryoWidget && (
              <BuySell
                isEmbeddable={true}
                defaultCoinId={defaultCoinId}
                getSelectedData={getSelectedData}
                setShowMoonpayWidget={setShowMoonpayWidget}
                setShowAlchemyPayWidget={setShowAlchemyPayWidget}
                setShowXanpoolWidget={setShowXanpoolWidget}
                setShowTransakWidget={setShowTransakWidget}
                setShowGateFiWidget={setShowGateFiWidget}
                setShowMunzenWidget={setShowMunzenWidget}
                setShowSwipeluxWidget={setShowSwipeluxWidget}
                setShowMercuryoWidget={setShowMercuryoWidget}
                setShowRampWidget={setShowRampWidget}
                compareMode={compareMode}
              />
            )}
          {showMoonpayWidget && (
            <MoonpayWidget backToWidget={backToWidget} buyData={buyData} />
          )}
          {showAlchemyPayWidget && (
            <AlchemyPayWidget backToWidget={backToWidget} buyData={buyData} />
          )}
          {showXanpoolWidget && (
            <XanpoolWidget backToWidget={backToWidget} buyData={buyData} />
          )}
          {showTransakWidget && (
            <TransakWidget backToWidget={backToWidget} buyData={buyData} />
          )}
          {showRampWidget && <RampWidget backToWidget={backToWidget} buyData={buyData} />}
          {showGateFiWidget && (
            <GateFiWidget backToWidget={backToWidget} buyData={buyData} />
          )}
          {showMunzenWidget && (
            <MunzenWidget backToWidget={backToWidget} buyData={buyData} />
          )}
          {showSwipeluxWidget && (
            <SwipeluxWidget backToWidget={backToWidget} buyData={buyData} />
          )}
          {showMercuryoWidget && (
            <MercuryoWidget backToWidget={backToWidget} buyData={buyData} />
          )}
          {/* <XanpoolWidget /> */}
          {/* <MoonpayWidget /> */}
        </div>
      </div>
    </React.Fragment>
  );
}

export default BuySellWidget;
