import get from 'lodash/get';
import React, { useState } from 'react';
import SimpleBar from 'simplebar-react';
import { Collapse } from 'reactstrap';
import { ReactComponent as ArrowLeftIcon } from '../../assets/images/icons/arrow-left.svg';
import { ReactComponent as RadioOffIcon } from '../../assets/images/icons/radio-off.svg';
import { ReactComponent as RadioOnIcon } from '../../assets/images/icons/radio-on.svg';
import { ReactComponent as ArrowDownIcon } from '../../assets/images/icons/arrow-down.svg';
import { ReactComponent as ChevronUpIcon } from '../../assets/images/icons/chevron-up.svg';
import { ReactComponent as ChevronDownIcon } from '../../assets/images/icons/chevron-down.svg';

function RateItem({
  onClick,
  provider,
  rate,
  isProviderSelected,
  selectedRate,
  coin,
  isFirst,
  tab,
  fiat
}) {
  const percent = 100 * ((rate.value - selectedRate.value) / selectedRate.value);
  const isSelected =
    isProviderSelected && get(selectedRate, 'payment_method') === rate.payment_method;
  return (
    <div
      className="d-flex align-items-center text-truncate cursor-pointer py-2"
      onClick={onClick}>
      {isSelected ? (
        <RadioOnIcon width={16} className="mr-3" />
      ) : (
        <RadioOffIcon width={16} className="mr-3" />
      )}

      <img
        src={provider.logo}
        alt=""
        width={36}
        style={{ maxHeight: '36px' }}
        className="mr-2"
      />
      <div className="w-25 mr-1">
        <div className="font-size-14 font-weight-medium text-capitalize d-flex align-items-center">
          <span>{provider.provider}</span>
          {isFirst &&
            get(provider, 'priceOutput.length', 0) > 1 &&
            (isProviderSelected ? (
              <ChevronUpIcon width={16} height={16} />
            ) : (
              <ChevronDownIcon width={16} height={16} />
            ))}
        </div>
        {isFirst && <div className="font-size-11 font-weight-light opacity-60">Fast</div>}
      </div>
      <img
        src={rate.logo}
        alt=""
        title={rate.payment_method}
        width={32}
        style={{ maxHeight: '32px' }}
        className="ml-auto mr-3"
      />
      <div className="w-35 text-right">
        <div className="font-size-12 font-weight-light">
          {isSelected ? (
            <span className="opacity-60">You Receive</span>
          ) : (
            <div
              className="d-flex align-items-center justify-content-end"
              style={{
                color: percent > 0 ? '#21BF73' : '#EB5757'
              }}>
              <span>{Math.abs(percent.toFixed(2))}%</span>
              {percent > 0 ? (
                <ArrowDownIcon
                  height={12}
                  width={12}
                  style={{
                    fill: '#21BF73',
                    transform: 'rotate(180deg)'
                  }}
                />
              ) : (
                <ArrowDownIcon
                  height={12}
                  width={12}
                  style={{
                    fill: '#EB5757'
                  }}
                />
              )}
            </div>
          )}
        </div>
        <div className="font-size-14 text-uppercase text-truncate">
          {tab === 'sell' ? <>{get(fiat, 'currency')}</> : <>{get(coin, 'coin_name')}</>}{' '}
          {Number(get(rate, 'value'))}
        </div>
      </div>
    </div>
  );
}

function RateSelect({
  tab = '',
  fiat = {},
  className = '',
  coin = {},
  value = {},
  allRates = [],
  onClose = () => {},
  onSelect = () => {}
}) {
  const [selectedRate, setSelectedRate] = useState(value);

  return (
    <div className={'py-3 ' + className}>
      <div className="px-3">
        <button
          type="button"
          className="btn btn-light p-1 mb-2"
          style={{ height: 36, width: 36, backgroundColor: '#F0F1F266' }}
          onClick={onClose}>
          <ArrowLeftIcon width={18} height={18} />
        </button>
      </div>
      <SimpleBar style={{ maxHeight: 'calc(80vh - 155px)' }}>
        {(Array.isArray(allRates) ? allRates : []).map((provider, i) => {
          if (!get(provider, 'priceOutput.length')) return null;
          const isProviderSelected =
            get(selectedRate, 'provider.provider') === provider.provider;
          const bestRate = get(provider, 'priceOutput[0]');
          return (
            <div
              key={i}
              className="border-style-solid border-width-1 p-2 rounded mb-2 mx-3"
              style={{
                borderColor: isProviderSelected ? '#181B3099' : '#E1E1EF99'
              }}>
              <RateItem
                onClick={() =>
                  setSelectedRate({
                    provider,
                    ...bestRate
                  })
                }
                isProviderSelected={isProviderSelected}
                isFirst={true}
                coin={coin}
                rate={bestRate}
                provider={provider}
                selectedRate={selectedRate}
                tab={tab}
                fiat={fiat}
              />
              <Collapse isOpen={isProviderSelected}>
                {get(provider, 'priceOutput', [])
                  .slice(1)
                  .map((rate, j) => (
                    <RateItem
                      key={j}
                      onClick={() =>
                        setSelectedRate({
                          provider,
                          ...rate
                        })
                      }
                      isProviderSelected={isProviderSelected}
                      isFirst={false}
                      coin={coin}
                      rate={rate}
                      provider={provider}
                      selectedRate={selectedRate}
                      tab={tab}
                      fiat={fiat}
                    />
                  ))}
              </Collapse>
            </div>
          );
        })}
      </SimpleBar>
      <div className="pt-3 px-3">
        <button
          type="button"
          className="btn btn-dark btn-block btn-lg text-capitalize"
          disabled={!selectedRate}
          onClick={() => onSelect(selectedRate)}>
          <div className="text-white font-size-18 my-2">Choose Onramp</div>
        </button>
      </div>
    </div>
  );
}

export default RateSelect;
